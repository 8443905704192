<div class="label" [ngClass]="[appendClass, size]">
  <mat-icon
    *ngIf="iconName"
    [ngClass]="[appendClass]"
    [svgIcon]="iconName"
    class="icon-s-size label-icon"
  ></mat-icon>

  <span class="content">
    <span *ngIf="text">
      {{ text }}
    </span>

    <ng-content></ng-content>
  </span>
</div>

