import { ElementRef, NgZone } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { OverlayRef, RepositionScrollStrategyConfig, ScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

/** Equivalent of `DOMRect` without some of the properties we don't care about. */
type Dimensions = Omit<DOMRect, 'x' | 'y' | 'toJSON'>;

function isElementClippedByScrolling(element: Dimensions, scrollContainers: DOMRect[]) {
  return scrollContainers.some((scrollContainerRect) => {
    const clippedAbove = element.top < scrollContainerRect.top;
    const clippedBelow = element.bottom > scrollContainerRect.bottom;
    const clippedLeft = element.left < scrollContainerRect.left;
    const clippedRight = element.right > scrollContainerRect.right;

    return clippedAbove || clippedBelow || clippedLeft || clippedRight;
  });
}

/**
 * Strategy that will update the element position as the user is scrolling.
 * It is similar to RepositionScrollStrategy except it has custom enable() with advanced logic for autoClose config option.
 */
export class CloseClippedRepositionScrollStrategy implements ScrollStrategy {
  private _scrollSubscription: Subscription | null = null;
  private _overlayRef: OverlayRef;

  constructor(
    public _scrollDispatcher: ScrollDispatcher,
    private _ngZone: NgZone,
    private _elementRef: ElementRef,
    private _config?: RepositionScrollStrategyConfig,
  ) { }

  attach(overlayRef: OverlayRef) {
    if (this._overlayRef) {
      throw new Error('Overlay is already attached');
    }

    this._overlayRef = overlayRef;
  }

  enable() {
    if (!this._scrollSubscription) {
      const throttle = this._config ? this._config.scrollThrottle : 0;

      this._scrollSubscription = this._scrollDispatcher.scrolled(throttle).subscribe(() => {
        this._overlayRef.updatePosition();

        if (this._config && this._config.autoClose) {
          const overlayRect = this._overlayRef.overlayElement.getBoundingClientRect();

          const scrollContainers = this._scrollDispatcher
            .getAncestorScrollContainers(this._elementRef)
            .map((sc) => sc.getElementRef().nativeElement.getBoundingClientRect());

          if (isElementClippedByScrolling(overlayRect, scrollContainers)) {
            this.disable();
            this._ngZone.run(() => this._overlayRef.detach());
          }
        }
      });
    }
  }

  disable() {
    if (this._scrollSubscription) {
      this._scrollSubscription.unsubscribe();
      this._scrollSubscription = null;
    }
  }

  detach() {
    this.disable();
    this._overlayRef.detach();
  }
}
