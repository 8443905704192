<!-- for web view -->
<app-web-header *ngIf="!isMobile">
  <ng-container *ngTemplateOutlet="leftNavContentTemplate" leftNavContent></ng-container>
  <ng-container *ngTemplateOutlet="rightNavContentTemplate" rightNavContent></ng-container>
</app-web-header>
<!-- for mobile view -->
<app-mobile-header *ngIf="isMobile">
  <ng-container *ngTemplateOutlet="leftNavContentTemplate" leftNavContent></ng-container>
  <ng-container *ngTemplateOutlet="rightNavContentTemplate" rightNavContent></ng-container>
</app-mobile-header>

<ng-template #leftNavContentTemplate>
  <ng-content select="[leftNavContent]"></ng-content>
</ng-template>

<ng-template #rightNavContentTemplate>
  <ng-content select="[rightNavContent]"></ng-content>
</ng-template>
