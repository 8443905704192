import { StringifiedGuid } from '@t/common/stringified-guid.type';

export interface TradingLogTradesFilterFormModel {
  readonly symbol: string;
  readonly accounts: ReadonlyArray<StringifiedGuid>;
  readonly strategies: ReadonlyArray<StringifiedGuid>;
}

export const defaultTLTradesFilterValue: TradingLogTradesFilterFormModel = {
  symbol: '',
  accounts: [],
  strategies: []
};
