<div>
  <div mat-dialog-title></div>

  <div mat-dialog-content>
    <div class="content">
      <div class="spinner-wrapper">
        <div class="spinner-container">
          <app-loading-animation></app-loading-animation>
        </div>
      </div>

      <div class="description">
        <h2 class="header">
          {{ data.header }}
        </h2>
        <p class="text">
          {{ data.subText }}
        </p>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="actions"></div>
</div>


