import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IMagicBallOption } from '@c/trading-hub/magic-ball/magic-ball.model';
import { magicBallOptions } from '@c/trading-hub/magic-ball/magic-ball.data';

@Component({
  standalone: true,
  selector: 'app-magic-ball',
  templateUrl: './magic-ball.component.html',
  styleUrls: ['./magic-ball.component.scss'],
  imports: [CommonModule, MatButtonModule],
})
export class MagicBallComponent implements OnInit {
  public resultText = 'Shake';
  public actionDescription = 'Concentrate on your question and press “Shake”';
  public isShaking = false;
  public shakingAvailable = true;
  public anotherShaking = false;
  public options: IMagicBallOption[] = magicBallOptions;

  @ViewChild('imageWrapper') public imageWrapperRef: ElementRef<HTMLDivElement>;
  @ViewChild('image') public imageRef: ElementRef<HTMLImageElement>;
  @ViewChild('imageResult') public imageResult: ElementRef;

  private lastMousePosition = { x: 0, y: 0 };

  constructor() {}

  ngOnInit() {
    const randomNumbersArray = new Uint32Array(magicBallOptions.length);
    crypto.getRandomValues(randomNumbersArray);

    this.options = [...magicBallOptions]
      .map((item, i) => ({ item, index: randomNumbersArray[i] }))
      .sort((a, b) => a.index - b.index)
      .map((element) => element.item);
  }

  public shake(): void {
    this.resultText = '';
    this.isShaking = true;
    this.shakingAvailable = false;
    const imageResult = this.imageResult.nativeElement;
    const option = this.getRandomOption(this.options);

    this.updateCursorStyleForBall(this.lastMousePosition.x, this.lastMousePosition.y);

    setTimeout(() => {
      this.isShaking = false;

      imageResult.classList.remove('gradient');
      imageResult.classList.remove('danger');
      imageResult.classList.remove('info');
      imageResult.classList.remove('success');
      imageResult.classList.add(option.class);

      this.resultText = option.title;
      this.actionDescription = 'Have more questions?';
    }, 2500);

    // show ask-more button after additional css animation
    setTimeout(() => {
      this.anotherShaking = true;
      this.updateCursorStyleForBall(this.lastMousePosition.x, this.lastMousePosition.y);
    }, 4050);
  }

  public reShake(): void {
    this.resultText = 'Shake';
    this.actionDescription = 'Concentrate on your question and press “Shake”';
    this.isShaking = false;
    this.anotherShaking = false;
    this.shakingAvailable = true;

    const imageResult = this.imageResult.nativeElement;
    imageResult.classList.remove('danger');
    imageResult.classList.remove('info');
    imageResult.classList.remove('success');
    imageResult.classList.add('gradient');
  }

  public onImageWrapperClick(): void {
    if (this.shakingAvailable) {
      this.shake();
    }

    if (this.anotherShaking) {
      this.reShake();
    }
  }

  public updateCursorStyleForBall(x: number, y: number): void {
    if (!this.shakingAvailable && !this.anotherShaking) {
      this.imageWrapperRef?.nativeElement?.classList.remove('grab-cursor');
      return;
    }

    const element = this.imageRef.nativeElement;

    if (!element) {
      return;
    }

    const clientRect = element.getClientRects()[0];
    const centerX = clientRect.x + (clientRect.width / 2);
    const centerY = clientRect.y + (clientRect.height / 2);
    const radius = clientRect.width / 2;
    const distance = Math.sqrt( Math.pow((centerX - x), 2) + Math.pow((centerY - y), 2) );

    if (distance < radius) {
      this.imageWrapperRef?.nativeElement?.classList.add('grab-cursor');
    } else {
      this.imageWrapperRef?.nativeElement?.classList.remove('grab-cursor');
    }
  }

  public onCircleMouseOut(): void {
    this.imageWrapperRef?.nativeElement?.classList.remove('grab-cursor');
  }

  public setMousePosition(event: MouseEvent): void {
    this.lastMousePosition = {
      x: event.clientX,
      y: event.clientY,
    };
  }

  private getRandomOption<T>(options: T[]): T {
    const randomNumbersArray = new Uint32Array(1);
    crypto.getRandomValues(randomNumbersArray);
    const randomNumber = randomNumbersArray[0];
    const index = randomNumber % options.length;

    return options[index];
  }
}
