<div mat-dialog-content>
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="center"
    disableRipple
    class="tab_date-style"
    [(selectedIndex)]="tabIndex"
  >
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <h2 class="custom-tab-title">Holidays</h2>
      </ng-template>
    </mat-tab>

    <mat-tab *ngFor="let tab of tabs" [label]="tab.label">
      <div class="country-radio-group">
        <p class="title">Show Holidays:</p>
        <mat-radio-group
          color="primary"
          class="o-radio"
          [formControl]="countryFormControl"
          [value]="country"
          (change)="onCountryChange($event)"
        >
          <mat-radio-button [value]="countries.USA">
            <div class="label-content">
              <p>USA</p>
              <div class="US-icon"></div>
            </div>
          </mat-radio-button>

          <mat-radio-button [value]="countries.Canada">
            <div class="label-content">
              <p>Canada</p>
              <div class="CA-icon"></div>
            </div>
          </mat-radio-button>

          <mat-radio-button value="both">
            <div class="label-content">
              <p>Both</p>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <table
        mat-table
        [dataSource]="groupedHolidays[tab.label]"
        class="table-list"
      >
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <td mat-cell *matCellDef="let element">
            <div
              class="date-style"
              [class]="{
                'date-style_1': element.isExpectedOrToday && !element.isWeekend,
                'date-style_4': element.isExpectedOrToday && element.isWeekend
              }"
            >
              {{ element.date | date: 'E, MMM d' }}
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <td mat-cell *matCellDef="let element" class="table-list-name">
            {{ element.name }}
          </td>
        </ng-container>

        <!-- flag Column -->
        <ng-container matColumnDef="flag">
          <td mat-cell *matCellDef="let element">
            <div class="table-list-flag">
              <div *ngFor="let flag of element.flags" [class]="flag"></div>
            </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>

    <mat-tab disabled>
      <ng-template mat-tab-label>
        <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1">
          <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon">
          </mat-icon>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
