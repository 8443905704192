import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '@env/environment';
import { AppLoaderService } from '@s/app-loader.service';
import { UserDataService } from '@s/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class AppVersionBannerService {
  private socket: any = null;

  constructor(
    private _appLoaderService: AppLoaderService,
    private _userDataService: UserDataService,
  ) { }

  subscribe() {
    const token = this._userDataService.getAuthToken();
    this.socket = io(environment.WsMultiInstanceUrl, {
      transports: ['websocket'],
      auth: { token }
    });

    this.socket.on('banner-socket', async () => {
      await this._appLoaderService.initializeApplicationVersion();
    });
  }

  unsubscribe() {
    this.socket.close();
  }
}
