<div class="content">
  <div class="picture"></div>
  <h3 class="header text-center">
    {{ header }}
  </h3>
  <span class="sub-header text-center">
    {{ subHeader }}
  </span>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
