<div class="mat-typography feelings-modal-content scroll-style">
  <div class="feelings-modal-part feelings-part-decision">
    <h3 class="feelings-modal-title">Reason</h3>
    <div class="decision-items-container">
      <mat-radio-group color="primary" [formControl]="decisionIdFormControl" class="feelings-modal-options">
        <mat-radio-button [value]="null">Undefined</mat-radio-button>
        <ng-container *ngFor="let item of decisionOptions">
          <mat-radio-button [value]="item.id" [disabled]="item.isDisabled">
            {{item.name}}<span *ngIf="item.shortName" [style]="{color: (item.color || '#FFFFFF')}">
              ({{item.shortName}})</span>
          </mat-radio-button>
          <div class="feelings-natp-options scroll-style" *ngIf="item.items">
            <mat-radio-group class="feelings-modal-options" [formControl]="decisionSubItemsFormControl"
              (change)="selectDecisionOption($event.value, item.id)">
              <table>
                <thead class="hide-on-max-767">
                  <th></th>
                  <th class="text-left feelings-table-header">Example</th>
                </thead>
                <tbody>
                  <tr *ngFor="let subItem of item.items">
                    <td>
                      <mat-radio-button [value]="subItem.id"
                        [disabled]="subItem.isDisabled">{{subItem.name}}</mat-radio-button>
                      <p class="feelings-natp-options-description hide-on-min-767">{{subItem.description}}</p>
                    </td>
                    <td class="hide-on-max-767">
                      <p class="feelings-natp-options-description">{{subItem.description}}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mat-radio-group>
          </div>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>
  <div class="feelings-modal-part-divider"></div>
  <div class="feelings-modal-part feelings-part-confidence">
    <h3 class="feelings-modal-title">Confidence</h3>
    <mat-radio-group color="primary" [formControl]="confidenceIdFormControl" class="feelings-modal-options">
      <mat-radio-button [value]="null">Undefined</mat-radio-button>
      <mat-radio-button *ngFor="let item of confidenceOptions" [value]="item.id" [disabled]="item.isDisabled">
        {{item.name}}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
