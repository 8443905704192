import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabelModule } from '@c/common/label/label.module';
import { AskRockyChatButtonComponent } from '../ask-rocky-chat-button/ask-rocky-chat-button.component';
import { HideShowIconModule } from '../hide-show-icon/hide-show-icon.module';
import { ExpectedMoveComponent } from './expected-move.component';

@NgModule({
  declarations: [ExpectedMoveComponent],
  imports: [CommonModule, LabelModule, MatIconModule, HideShowIconModule, MatTooltipModule, AskRockyChatButtonComponent],
  exports: [ExpectedMoveComponent],
})
export class ExpectedMoveModule {}
