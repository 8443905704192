import { SortDirection } from '@angular/material/sort';
import { IPortfolioSettings, PortfolioViewModes } from '@c/portfolio/portfolio.model';

export const ALL_TRADES_GROUP_ID = 'all-trades';
export const SCROLL_TO_ROW_DELAY_MS = 100;
export const SAVE_SETTINGS_DEBOUNCE_TIME_MS = 1000;
export const DEFAULT_SETTINGS: IPortfolioSettings = {
  filters: { accounts: [], strategies: [] },
  sort: { column: 'symbol', direction: 'asc' as SortDirection },
  viewMode: PortfolioViewModes.Groups,
  groupedTrades: [],
};

// size of included arrays should be the same
export const barColors = [
  ['#f9c0d4', '#f597b9', '#f273a0', '#ef5089', '#bf406e', '#8e3052', '#722641'],
  ['#f9c1c0', '#f59897', '#f27573', '#ef5350', '#bf4240', '#8e3130', '#722826'],
  ['#ffd4b7', '#ffb988', '#ffa05f', '#ff8937', '#cc6e2c', '#985221', '#7a411a'],
  ['#fde6a3', '#fcd667', '#fbc933', '#fabb00', '#c89600', '#956f00', '#785900'],
  ['#f2eda3', '#e9e167', '#e2d733', '#fabb00', '#afa400', '#827a00', '#696200'],
  ['#dce8b3', '#c4d981', '#b1cc56', '#9dbf2c', '#7e9923', '#5e721a', '#4b5b15'],
  ['#b3e8b6', '#81d987', '#56cc5e', '#2cbf36', '#23992b', '#1a7220', '#155b1a'],
  ['#b1dfcb', '#7ecaa9', '#51b88c', '#26a66f', '#1e8559', '#176342', '#124f35'],
  ['#b1dfdb', '#7ecac3', '#51b8ae', '#26a69a', '#1e857b', '#17635c', '#124f4a'],
  ['#a4d4e2', '#69b7d0', '#359fc0', '#0387b0', '#026c8d', '#025069', '#014154'],
  ['#afd9fb', '#7bc0f8', '#4dabf5', '#2196f3', '#1a78c2', '#145991', '#104874'],
  ['#afc8fb', '#7ba5f8', '#4d86f5', '#2168f3', '#1a53c2', '#143e91', '#103274'],
  ['#afaffb', '#7b7bf8', '#4d4df5', '#2121f3', '#1a1ac2', '#141491', '#101074'],
  ['#ceadfd', '#ae78fc', '#934afb', '#781dfa', '#6819da', '#5715b4', '#43108b'],
  ['#dda3f8', '#d183f6', '#b331f0', '#a100ed', '#8b00cc', '#7400ab', '#620091'],
];

// order for each row/column is taken from SPEC
// { [index-from-order]: index-in-colors }
export const colorsRowsMap = {
  0: 0,
  5: 1,
  10: 2,
  1: 3,
  6: 4,
  11: 5,
  2: 6,
  7: 7,
  12: 8,
  3: 9,
  8: 10,
  13: 11,
  4: 12,
  9: 13,
  14: 14,
};

// { [index-from-order]: index-in-colors }
export const colorsColumnsMap = {
  5: 0,
  3: 1,
  1: 2,
  0: 3,
  2: 4,
  4: 5,
  6: 6,
};
