import { Injectable } from '@angular/core';
import { DialogsService } from '@s/common';
import { ObservableService } from './observable.service';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';

@Injectable({
  providedIn: 'root'
})
export class TradingPanelService {

  constructor(
    private _observableService: ObservableService,
    private _dialogsService: DialogsService,
  ) { 
  }

  public showOrderModal(orderInput: TradingOrderInput) {
    const isPinned = this._observableService.tradingPanelOrderModalPinned.getValue();

    if (isPinned) {
      this._observableService.tradingPanelOrderInput.next(orderInput);
    } else {
      this._dialogsService.openTradingPanelOrderDialog(orderInput);
    }
  }
}
