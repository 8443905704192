import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { from, Subject, Subscriber } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { DialogsService } from '@s/common';
import { TradingHubService } from '@s/trading-hub.service';
import {
  ITask,
  TaskStatuses,
  TradingHubEventEventNames,
} from '@c/trading-hub/trading-hub.model';

@Component({
  selector: 'app-to-do-list',
  templateUrl: './to-do-list.component.html',
  styleUrls: ['./to-do-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToDoListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tasks: ITask[] = [];

  @Output() updateTasks = new EventEmitter<ITask[]>();

  public maxTasksNumber = 50;
  public maxTaskContentLength = 250;

  public taskStatuses = TaskStatuses;
  public tasksList: ITask[] = [];
  public localTasksList: ITask[] = [];

  public activeTask: ITask | null = null;
  public hoveredTask: ITask | null = null;

  private saveTasksOnTextEditMode$ = new Subject<ITask[]>();
  private subscriber = new Subscriber();

  constructor(
    private renderer: Renderer2,
    private dialogsService: DialogsService,
    private tradingHubService: TradingHubService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.subscriber.add(
      this.saveTasksOnTextEditMode$
        .pipe(debounceTime(0))
        .subscribe((tasks) => {
          this.updateTasks.emit(tasks);
        })
    );

    this.subscriber.add(
      this.tradingHubService.preTradingChecklistEvents$
        .pipe(
          filter((command) => command.event === TradingHubEventEventNames.addTask),
        )
        .subscribe((command) => {
          if (command.event !== TradingHubEventEventNames.addTask) {
            return;
          }

          this.onAddTask();
        }),
    );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tasks && !this.activeTask) {
      this.tasksList = changes.tasks.currentValue
        .map((item) => ({ ...item, content: item.content.trim() }))
        .sort((a, b) => a.order - b.order);

      this.changeDetectorRef.markForCheck();
    }
  }

  public onDragStart(): void {
    this.activeTask = null;
  }

  public onTextAreaFocusOut(): void {
    this.activeTask = null;
  }

  public drop(dropEvent: CdkDragDrop<ITask[]>): void {
    if (dropEvent.previousIndex === dropEvent.currentIndex) {
      return;
    }

    moveItemInArray(dropEvent.container.data, dropEvent.previousIndex, dropEvent.currentIndex);
    const updatedTasks = dropEvent.container.data
      .map((task, index) => ({ ...task, order: index }));

    this.updateTasks.emit(updatedTasks);
  }

  public deleteTask(task: ITask): void {
    this.activeTask = null;

    // update order for tasks
    const updatedTasks = Array.from(this.tasksList)
      .filter((item) => item.order !== task.order)
      .sort((a, b) => a.order - b.order)
      .map((item, index) => ({ ...item, order: index }));

    this.updateTasks.emit(updatedTasks);
  }

  public onAddTask(): void {
    if (this.tasksList.length >= this.maxTasksNumber) {
      this.showMaxAllowedTasksInfoMessage();
      return;
    }

    this.activeTask = null;

    const newOrder = this.tasksList.length > 0
      ? Math.max(...this.tasksList.map((item) => item.order)) + 1
      : 0;

    const newTask = {
      content: '',
      order: newOrder,
      status: TaskStatuses.notCompleted,
    };
    const updatedTasks = [...this.tasksList, newTask];

    this.updateTasks.emit(updatedTasks);
    this.tasksList = updatedTasks;

    this.activeTask = newTask;
    this.setEditModeForTask(newTask);

    // scroll to "add-task" button after list re-rendering (with new task)
    setTimeout(() => {
      this.scrollToElement('add-task-action');
    }, 200);
  }

  public onChangeStatus(task: ITask, checked: boolean): void {
    const updatedTasks = Array.from(this.tasksList)
      .map((item) => {
        if (item.order === task.order) {
          return {
            ...item,
            status: checked ? TaskStatuses.completed : TaskStatuses.notCompleted,
          };
        }

        return item;
      });

    this.tasksList = updatedTasks;
    this.updateTasks.emit(updatedTasks);
  }

  public editTaskContent(task: ITask | null): void {
    // update tasksList after focus-out
    if (!task) {
      this.activeTask = null;
      return;
    }

    if (this.activeTask && this.activeTask.order === task.order) {
      return;
    }

    this.tasksList = this.tasksList
      .map((item) => ({ ...item, content: item.content.trim() }))
      .sort((a, b) => a.order - b.order);

    this.changeDetectorRef.markForCheck();

    this.activeTask = task;
    this.setEditModeForTask(task);
  }

  public onPaste(task: ITask, event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');
    const currentTaskTextAreaElement = event.target as HTMLTextAreaElement;
    const currentTaskValue = currentTaskTextAreaElement.value;
    const selectionStart = currentTaskTextAreaElement.selectionStart;
    const selectionEnd = currentTaskTextAreaElement.selectionEnd;

    const newRawContent = currentTaskValue.slice(0, selectionStart)
     + clipboardData
     + currentTaskValue.slice(selectionEnd);

    const parsedData = newRawContent
      .trim()
      .split(new RegExp('\r\n|\r|\n', 'g'))
      .map((item) => item.trim())
      .filter((item) => item !== '');

    const insertIntoCurrentTask = parsedData[0];
    let tasksToInsert = Array.from(parsedData)
      .splice(1, parsedData.length)
      .map((content, index) => ({
        content: content.slice(0, this.maxTaskContentLength),
        order: task.order + 1 + index,
        status: TaskStatuses.notCompleted,
      }));

    // keep tasks number equal or less than maxTasksNumber
    if ((this.tasksList.length + tasksToInsert.length) > this.maxTasksNumber) {
      this.showMaxAllowedTasksInfoMessage();
      tasksToInsert = Array.from(tasksToInsert).splice(0, this.maxTasksNumber - this.tasksList.length);
    }

    const tasksWithShiftedOrder = Array.from(this.tasksList)
      .map((item) => {
        if (item.order === task.order) {
          return {
            ...item,
            content: insertIntoCurrentTask.slice(0, this.maxTaskContentLength),
          };
        }

        if (item.order > task.order) {
          return {
            ...item,
            order: item.order + tasksToInsert.length,
          };
        }

        return item;
      });

    const updatedTasks = [...tasksWithShiftedOrder, ...tasksToInsert]
      .sort((a, b) => a.order - b.order);

    this.tasksList = updatedTasks;
    this.updateTasks.emit(updatedTasks);

    if (tasksToInsert.length > 0) {
      const nextTaskToEdit = tasksToInsert[tasksToInsert.length - 1];
      // set cursor into the end of last inserted fragment of clipboardData
      // in case it was ended up with ENTER symbol - to the start of last inserted task (part after selectionEnd)
      // exclude spaces at start of this task
      const cursorPosition = nextTaskToEdit.content.length - currentTaskValue.slice(selectionEnd).trim().length;
      this.setEditModeForTask(nextTaskToEdit, cursorPosition);
    } else {
      const cursorPosition = selectionStart + clipboardData.length;
      this.setEditModeForTask(task, cursorPosition);
    }
  }

  public onKeyDown(task: ITask, key: 'Backspace' | 'Enter', event: KeyboardEvent): void {
    if (key === 'Enter' && this.tasksList.length >= this.maxTasksNumber) {
      event.preventDefault();
      this.showMaxAllowedTasksInfoMessage();
      return;
    }

    const element = document.getElementById(`task-textarea_${task.order}`) as HTMLTextAreaElement | null;

    if (!element) {
      event.preventDefault();
      return;
    }

    if (key === 'Enter') {
      event.preventDefault();

      // prevent the cases when ENTER symbol is left in content
      const currentTaskContent = element.value
        .slice(0, element.selectionEnd)
        .trim()
        .split(new RegExp('\r\n|\r|\n', 'g'))[0];

      const newTaskContent = element.value.slice(element.selectionEnd, element.value.length).trim();

      const newTask = {
        content: newTaskContent,
        order: task.order + 1,
        status: TaskStatuses.notCompleted,
      };

      const tasksWithShiftedOrder = Array.from(this.tasksList)
        .map((item) => {
          if (item.order === task.order) {
            return {
              ...item,
              content: currentTaskContent,
            };
          }

          if (item.order > task.order) {
            return {
              ...item,
              order: item.order + 1,
            };
          }

          return item;
        });

      const updatedTasks = [...tasksWithShiftedOrder, newTask]
        .sort((a, b) => a.order - b.order);

      this.updateTasks.emit(updatedTasks);
      this.tasksList = updatedTasks;
      this.activeTask = null;

      this.setEditModeForTask(newTask, 0);
    }

    if (key === 'Backspace' && element.selectionEnd === 0) {
      event.preventDefault();

      const tasksBefore = this.tasksList
        .filter((item) => item.order < task.order)
        .sort((a, b) => b.order - a.order);

      if (tasksBefore.length === 0) {
        return;
      }

      const previousTask = tasksBefore[0];

      const updatedTasks = Array.from(this.tasksList)
        .filter((item) => item.order !== task.order)
        .map((item) => {
          if (item.order === previousTask.order) {
            return {
              ...item,
              content: (item.content + element.value).slice(0, this.maxTaskContentLength),
            };
          }

          return item;
        });

      this.updateTasks.emit(updatedTasks);
      this.tasksList = updatedTasks;
      this.activeTask = null;

      this.setEditModeForTask(previousTask, previousTask.content.length);
    }
  }

  public updateTask(task: ITask, event: InputEvent): void {
    const element = event.currentTarget as HTMLTextAreaElement;
    const updatedTasks = Array.from(this.tasksList)
      .map((item) => {
        if (item.order === task.order) {
          return {
            ...item,
            content: element.value,
          };
        }

        return item;
      });

    this.tasksList = updatedTasks;
    this.saveTasksOnTextEditMode$.next(updatedTasks);
  }

  private setEditModeForTask(task: ITask, cursorPosition?: number): void {
    this.activeTask = task;
    this.hoveredTask = null;

    setTimeout(() => {
      const element = document.getElementById(`task-textarea_${task.order}`) as HTMLTextAreaElement | null;

      if (element) {
        element.focus();

        if (cursorPosition !== undefined && cursorPosition !== null) {
          element.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    }, 100);
  }

  public onHoverTask(task: ITask | null): void {
    if (this.hoveredTask?.order !== task?.order) {
      this.hoveredTask = task;
    }
  }

  private scrollToElement(id: string): void {
    const element = document.querySelector(`#${id}`);

    if (element) {
      this.renderer
        .selectRootElement(`#${id}`, true)
        .scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  private showMaxAllowedTasksInfoMessage(): void {
    // Do not remove empty line, it's important for UI
    const message = `Maximum allowed tasks is ${this.maxTasksNumber}.

      Please revise your list and combine or remove some tasks.`;

    from(
      this.dialogsService.customConfirm({
        showCancel: false,
        header: 'Adding a new task',
        confirmationText: message,
        subText: '',
        icon: 'info',
      })
    ).subscribe();
  }

  public trackByFn(index: number, item: ITask): string | number {
    return item.order;
  }
}
