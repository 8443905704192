import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map } from 'rxjs';
import { UserListRequestModel, UserListResponseDataModel, UserSupportListRequestModel } from '@mod/admin';
import { RequestResponseModel } from '@mod/common';
import { Response } from '@core1/interface/response';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private _http: HttpClient
  ) { }

  addLicenceAgreement(): Observable<Response> {
    return this._http.post<Response>('/v2/users/addLicenceAgreement', {}).pipe(
      map((res) => (res?.result))
    );
  }

  addJoinCommunity = async (): Promise<Response> => {
    return await firstValueFrom(this._http.post<Response>('/v2/users/addJoinCommunity', {}));
  }

  addWatchPxoTutorials = async (): Promise<Response> => {
    return await firstValueFrom(this._http.post<Response>('/v2/users/addWatchPxoTutorials', {}));
  }

  getList(model: UserListRequestModel): Observable<UserListResponseDataModel> {
    return this._http.post<RequestResponseModel<UserListResponseDataModel>>(`/v2/users/list`,
      model
    ).pipe(
      map((res) => (res?.result))
    );
  }

  getSupportList(model: UserSupportListRequestModel): Observable<UserListResponseDataModel> {
    return this._http.post<RequestResponseModel<UserListResponseDataModel>>(`/v2/users/support_list`,
      model
    ).pipe(
      map((res) => (res?.result))
    );
  }

  async add(user_id_v2: number, support_user_ids: Array<number>): Promise<void> {
    await firstValueFrom(this._http.post<Response>('/v2/users/add_support_users', {
      user_id_v2,
      support_user_ids
    }));
  }

  async remove(user_id_v2: number, support_user_ids: Array<number>): Promise<void> {
    await firstValueFrom(this._http.post<Response>('/v2/users/delete_support_users', {
      user_id_v2,
      support_user_ids
    }));
  }
}
