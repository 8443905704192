<div (keydown.escape)="$event.preventDefault(); $event.stopPropagation(); onClose();">
  <div mat-dialog-title class="header">
    <div class="icon-wrapper negative">
      <mat-icon class="icon" [svgIcon]="'dislike'"></mat-icon>
    </div>

    <div class="title">
      Provide additional feedback
    </div>

    <button mat-button (click)="onClose()" class="popup-close-btn" tabindex="-1">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon"></mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div class="feedback-content">
      <div class="options" *ngIf="config.availableFeedbackOptions.length > 0">
        <mat-radio-group
          class="radio-group"
          [(ngModel)]="currentFeedbackOption"
        >
          <mat-radio-button
            class="radio-button"
            *ngFor="let item of config.availableFeedbackOptions"
            [value]="item"
          >
            {{ item }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="input-wrapper">
        <mat-form-field class="chat-input" floatLabel="never">
          <textarea
            id="feedback-input"
            matInput
            cdkTextareaAutosize
            autofocus
            cdkAutosizeMinRows="3"
            type="text"
            autocomplete="off"
            spellcheck="false"
            placeholder="Please provide the ideal answer or clarifying information"
            class="pxo-style feedback-input scroll-style"
            [maxlength]="maxContentLength"
            [formControl]="feedbackFormControl"
            (paste)="onPasteFeedback($event)"
            (keydown.enter)="$event.stopPropagation()"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="actions">
      <button
        mat-button
        class="main-bg button small-btn"
        (click)="onSubmit()"
      >
        Submit feedback
      </button>
    </div>
  </div>
</div>



