import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '@env/environment';
import { UserDataService } from '@s/user-data.service';
import { IDataChannelCommand, IDataChannelCommandLocal, IDataChannelResult } from '@mod/data/data-channel.model';
import { Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class DataChannelService {
  private _socket: Socket = null;
  private _isConnected = false;
  private _subscriptions = new Map<string, IDataChannelCommandLocal>();

  public get isConnected(): boolean {
    return this._isConnected;
  }

  constructor(
    private _userDataService: UserDataService
  ) {
    this.initConnection();
  }

  subscribe(command: IDataChannelCommand, removeAfterResponse = false) {
    this._subscriptions.set(command.subscriptionId, {
      ...command,
      removeAfterResponse
    });

    if (this._isConnected) {
      this._socket.emit('pxo_data_channel_subscribe', command);
    }
  }

  execute(command: IDataChannelCommand, removeAfterResponse = true): void {
    this._subscriptions.set(command.subscriptionId, {
      ...command,
      removeAfterResponse
    });

    if (this._isConnected) {
      this._socket.emit('pxo_data_channel_execute', command);
    }
  }

  unsubscribe(command: IDataChannelCommand) {
    this._subscriptions.delete(command.subscriptionId);

    this._socket.emit('pxo_data_channel_unsubscribe', command);
  }

  public initConnection() {
    const token = this._userDataService.getAuthToken();

    this._socket = io(environment.WsBaseUrl, {
      transports: ['websocket'],
      auth: { token }
    });

    this._socket.on('connect', () => {
      this._isConnected = true;
      this._subscriptions.forEach((command) => {
        if (!command.removeAfterResponse) {
          this._socket.emit('pxo_data_channel_subscribe', command);
        }
      });
    });

    this._socket.on('disconnect', (reason) => {
      this._isConnected = false;
      console.warn(`Data channel socket disconnected (${reason})`);
    });

    this._socket.on('pxo_data_channel_result', async (result: IDataChannelResult) => {
      const { subscriptionId, data } = result;
      const command = this._subscriptions.get(subscriptionId);

      if (command) {
        command.handler(data);

        if (command.removeAfterResponse) {
          this._subscriptions.delete(command.subscriptionId);
        }
      }
    });
  }

  public destroyConnection() {
    this._socket.disconnect();
    this._subscriptions.clear();
    this._isConnected = false;
    this._socket = null;
  }
}
