import { WpApiOrderParamsModel, WpApiPaginationParamsModel } from '@mod/video-hub/wp-api-request.model';

export const VideoHubTabNames = {
  VideoHubCategory: 'video-hub-category',
  VideoHubHome: 'video-hub-home',
  VideoHubTag: 'video-hub-tag',
  VideoHubPlayer: 'video-hub-player',
};

export const VideoHubUrlKey = 'video-hub-url';

export const videoHubHomeUrl = '/video-hub/home';
export const videoHubPlayerUrl = '/video-hub/player';
export const videoHubCategoryUrl = '/video-hub/category';
export const videoHubTagUrl = '/video-hub/tag';

export const videoHubResetDiffSeconds = 10;

export const defaultRecordsPerPage = 20;
export const videoHubInfinitiScrollTreshold = 700;
export const defaultEncryptEditionShift = 4;

export const defaultWpApiPaginationParams: WpApiPaginationParamsModel = {
  per_page: defaultRecordsPerPage,
  page: 1,
  disablePagination: false,
  ignoreChild: false,
};

export const defaultWpApiOrderParams: WpApiOrderParamsModel = {
  orderby: 'menu_order',
  order: 'asc',
};
