import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  allExchangeCountryCodes,
  CAExchangesForGoogleSearch,
  EasternTimeZoneName,
  ExchangeCountries,
  ExchangeCountriesCodes,
  getTradePositionName,
  TabNames,
  Themes,
  TradePositions,
  UserSettings,
  USExchangesForGoogleSearch,
  WatchlistType,
} from '@const';
import { EarningsService } from '@s/earnings.service';
import { JobDataService } from '@s/job-data.service';
import { ObservableService } from '@s/observable.service';
import { SearchPopupService } from '@s/search-popup.service';
import { SecurityDataDetailsService } from '@s/security-data-details.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { WatchlistDataService } from '@s/watchlist-data.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DataWindowUpdateSource } from '@t/wheel/wheel.types';
import { WheelService } from '@m1/wheel/wheel.service';
import { NavigationService } from '@s/navigation.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent implements OnInit, OnDestroy {
  selectedSymbol: ISymbol = null;
  selectedSymbolDetails: any = null;
  tradePosition: any = '';
  lastUpdated: any = '';
  nextEarnings: any = null;
  showSecurityDetailsTooltip: boolean = false;
  public isShowNewsButton = false;
  public exchangeCodeForGoogleSearch;
  private subscriptions = new Subscription();

  TradePositions = TradePositions;
  getTradePositionName = getTradePositionName;
  ExchangeCountries = ExchangeCountries;

  @Input() isPowerX: boolean;

  get updateTime() {
    if (!this.lastUpdated?.updated_date) return 'N/A';
    return moment(this.lastUpdated?.updated_date).tz('America/New_York').format('MMM D, YYYY h:mm A [ET]');
  }

  get nextEarningsInDays() {
    return this.nextEarnings?.earnings_in_days;
  }

  get nextEarningsDate() {
    if (!this.nextEarnings) return null;
    const formatedDate = moment(this.nextEarnings.report_date).tz(EasternTimeZoneName).format('MMM D, YYYY');
    switch (this.nextEarnings.earnings_in_days) {
      case 0:
        return `Earnings Today on ${formatedDate} ET`;
      case 1:
        return `Earnings in 1 day on ${formatedDate} ET`;
      default:
        return `Earnings in ${this.nextEarnings.earnings_in_days} days on ${formatedDate} ET`;
    }
  }

  get addSvg() {
    const theme = this.observableService.theme.value;
    const baseUrl = '../../../../assets/img/';
    return baseUrl + (theme === Themes.Dark ? 'Add-white.svg' : 'Add.svg');
  }

  constructor(
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private observableService: ObservableService,
    private jobDataService: JobDataService,
    private searchPopupService: SearchPopupService,
    private securityDataDetailsService: SecurityDataDetailsService,
    private watchlistDataService: WatchlistDataService,
    private earningsService: EarningsService,
    private wheelService: WheelService,
    private navigationService: NavigationService,
  ) { }

  async ngOnInit(): Promise<void> {
    const symbol = this.isPowerX ? this.observableService.symbol.getValue() : this.observableService.wheelSymbol.getValue();

    if (this.isPowerX) {
      this.subscriptions.add(this.observableService.symbol.subscribe(async (newSymbol) => await this.loadData(newSymbol)));
      this.subscriptions.add(this.observableService.tradePosition.subscribe((tradePosition) => (this.tradePosition = tradePosition)));
    } else {
      this.subscriptions.add(this.observableService.wheelSymbol.subscribe(async (newSymbol) => await this.loadData(newSymbol)));
    }

    await this.loadData(symbol);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  securityDetailsTooltip(show) {
    this.showSecurityDetailsTooltip = show;
  }

  async loadData(symbol) {
    const [selectedSymbol, selectedSymbolDetails, lastUpdated, nextEarnings] = await Promise.all([
      this.symbolsService.getById(symbol),
      this.securityDataDetailsService.get(symbol),
      this.jobDataService.getRecentSecurity(symbol),
      this.earningsService.getNext(symbol),
    ]);

    this.selectedSymbol = selectedSymbol;
    this.selectedSymbolDetails = selectedSymbolDetails;
    this.lastUpdated = lastUpdated;
    this.nextEarnings = nextEarnings;
    this.showNewsButton();
    this.getExchangeForGoogleSearch();
  }

  async addToWatchlist() {
    if (!this.isPowerX) {
      return;
    }

    await this.watchlistDataService.insert(this.selectedSymbol.security_id, WatchlistType.PowerX);
    await this.userDataService.set(UserSettings.SelectScannerTab, 0);
    this.observableService.watchlistUpdated.next(true);
  }

  openSymbolSearch() {
    const { symbol } = this.selectedSymbol || {};
    this.searchPopupService.openPopup(
      symbol || '',
      this.isPowerX,
      true,
      false,
      this.isPowerX ? allExchangeCountryCodes : [ExchangeCountriesCodes.US],
      (selectedSymbol) => {
        if (!this.isPowerX) {
          this.wheelService.updateDataWindowSymbol$.next({
            symbol: { id: selectedSymbol.security_id, name: selectedSymbol.symbol },
            source: DataWindowUpdateSource.ChartSearchBar,
          });
        }
      },
    );
  }

  async updatePosition(tradePosition) {
    if (!this.isPowerX) {
      return;
    }

    await this.userDataService.set(UserSettings.TradePosition, tradePosition);
  }

  openNews(): void {
    window.open(`https://www.google.com/finance/quote/${this.selectedSymbol?.symbol}:${this.exchangeCodeForGoogleSearch}`, '_blank');
  }

  showNewsButton(): void {
    this.isShowNewsButton = this.isPowerX
      && this.selectedSymbol?.country_code === ExchangeCountriesCodes.US
      || this.selectedSymbol?.country_code === ExchangeCountriesCodes.CA
      && !!CAExchangesForGoogleSearch[this.selectedSymbol?.exchange_code];
  }

  getExchangeForGoogleSearch(): void {
    if (this.selectedSymbol?.country_code === ExchangeCountriesCodes.US) {
      this.exchangeCodeForGoogleSearch = USExchangesForGoogleSearch[this.selectedSymbol?.exchange_code];
    }
    if (this.selectedSymbol?.country_code === ExchangeCountriesCodes.CA) {
      this.exchangeCodeForGoogleSearch = CAExchangesForGoogleSearch[this.selectedSymbol?.exchange_code];
    }
  }

  public setEarningsCalendarSymbol(selectedSymbol): void {
    this.observableService.earningsCalendarSymbol.next(selectedSymbol.security_id);
    this.navigationService.redirectToTab(TabNames.EarningsAnalysis);
  }
}
