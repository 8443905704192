import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RestRequestorService } from './rest-requestor.service';
import { StorageKeys, round } from '@const';
import { ExpectedModel, IExpectedMove } from '@mod/data/expected-move.model';

@Injectable({
  providedIn: 'root'
})
export class ExpectedMoveService {

  constructor(private http: HttpClient, private restRequestorService: RestRequestorService) { }

  public async get(security_id: number): Promise<IExpectedMove[]> {
    const storageKey = `${StorageKeys.ExpectedMove}_${security_id}`;

    try {
      const { result } = await this.restRequestorService.makeRequest(
        storageKey,
        () => firstValueFrom(this.http.get<ExpectedModel>(`/v2/expectedMove`, { params: { security_id } }))
      );

      const expectedMoves = result.map((item) => ({
        symbol: item.symbol,
        securityId: item.security_id,
        expirationDate: item.expiration_date,
        expectedMove: item.expected_move,
        index: item.index,
        expectedPercent: item.expected_move / item.close * 100,
        priceUp: round(item.close + item.expected_move, 2),
        priceDown: round(item.close - item.expected_move, 2),
      }));

      return expectedMoves;
    } catch (error) {
      return [];
    }
  }
}
