import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, input } from '@angular/core';
import { Subject, Subscriber, from } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { UserDataService } from '@s/user-data.service';
import { ObservableService } from '@s/observable.service';
import { TradingHubService } from '@s/trading-hub.service';
import { ServerDataService } from '@s/server-data.service';
import { EditionsService } from '@s/editions.service';
import {
  Features,
  TradingHubTabs,
  saveTabStateDebounceTime,
  UserSettings,
} from '@const';
import { TradingHubMode } from './trading-hub.model';

@Component({
  selector: 'app-trading-hub',
  templateUrl: './trading-hub.component.html',
  styleUrls: ['./trading-hub.component.scss'],
})
export class TradingHubComponent implements OnInit, OnDestroy {
  @Output() closeModal = new EventEmitter<void>();

  protected currentMode = input(TradingHubMode.Default);

  public progress$ = this.tradingHubService.preTradingProgress$;
  public currentTabIndex = 0;
  public features = Features;
  public tradingHubTabs = TradingHubTabs;

  protected readonly TradingHubMode = TradingHubMode;

  // order of tabs is the same as in UI
  private allTabs = [
    TradingHubTabs.Checklist,
    TradingHubTabs.TradingPlan,
    // TradingHubTabs.IBot, // currently hidden tab, keep it commented
    TradingHubTabs.IBot3,
    TradingHubTabs.MagicBall,
  ];
  private saveTab$ = new Subject<TradingHubTabs>();
  private subscriber = new Subscriber();

  constructor(
    private serverDataService: ServerDataService,
    private tradingHubService: TradingHubService,
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private editionsService: EditionsService,
  ) { }

  ngOnInit() {
    // save last-open-time
    from(this.userDataService.set(UserSettings.TradingHubLastOpenDate, Date.now()))
      .subscribe();

    this.subscriber.add(
      this.saveTab$
        .pipe(
          debounceTime(saveTabStateDebounceTime),
          distinctUntilChanged(),
          switchMap((tabIndex) => this.userDataService.set(UserSettings.TradingHubTab, tabIndex)),
        )
        .subscribe()
    );

    // restore saved settings on component open
    this.subscriber.add(
      this.observableService.tradingHubTab
        .pipe(
          take(1),
          tap((tradingHubTabLabel) => {
            this.currentTabIndex = this.getTabIndexByLabel(tradingHubTabLabel);
          }),
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  public onChangeTab(tabIndex: number, tabLabel: string): void {
    this.currentTabIndex = tabIndex;

    if (this.isTabExist(tabLabel)) {
      this.saveTab$.next(tabLabel as TradingHubTabs);
    }
  }

  private getTabIndexByLabel(tabLabel: TradingHubTabs): number {
    if (!this.isTabExist(tabLabel)) {
      return 0;
    }

    const availableTabs = this.editionsService.isFeatureAvailable(Features.IBot)
      ? this.allTabs
      : this.allTabs.filter((item) => item !== TradingHubTabs.IBot);

    return availableTabs.findIndex((item) => item === tabLabel);
  }

  private isTabExist(tabLabel: TradingHubTabs | string): boolean {
    if (!tabLabel) {
      return false;
    }

    return Object.values(this.tradingHubTabs).includes(tabLabel as TradingHubTabs);
  }

  public handleCloseModal(): void {
    this.closeModal.emit();
  }
}
