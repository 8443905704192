import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-content',
  templateUrl: './no-data-content.component.html',
  styleUrls: ['./no-data-content.component.scss'],
})
export class NoDataContentComponent implements OnInit {
  @Input() header = '';
  @Input() subHeader = '';

  constructor() { }

  ngOnInit() { }
}
