import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectFeelingsModalComponent, SelectFeelingsModalInputModel, SelectFeelingsModalOutputModel } from '@mdl/select-feelings-modal';
import { TradingLogQuotesOptionsModalComponent } from '@mdl/trading-log-quotes-options-modal/trading-log-quotes-options-modal.component';
import { TradingLogQuotesOptionsModel } from '@mod/trading-log/trading-log-quotes-options.model';
import { DialogsService } from '@s/common';
import { EditionsService } from '@s/editions.service';

@Injectable()
export class TradingLogDialogsService extends DialogsService {

  constructor(
    dialog: MatDialog,
    editionsService: EditionsService,
  ) {
    super(dialog, editionsService);
  }

  public openSelectFeelingsModal(model: SelectFeelingsModalInputModel): Promise<string> {
    return this.openDialog<SelectFeelingsModalComponent,
      SelectFeelingsModalInputModel,
      SelectFeelingsModalOutputModel,
      string>(SelectFeelingsModalComponent, 'feelings', model, (m) => m?.selectedFeelingsId,
        400,
        100,
        '100vw',
        true,
        false,
      );
  }

  public openTradingLogQuotesModal(
    quotesOptions: TradingLogQuotesOptionsModel
  ): Promise<void> {
    return this.openDialog<
      TradingLogQuotesOptionsModalComponent,
      TradingLogQuotesOptionsModel,
      void,
      void
    >(
      TradingLogQuotesOptionsModalComponent,
      'trading-log-quotes-modal',
      { ...quotesOptions },
      (_) => _,
      400,
      100,
      '100vw',
      true,
      true
    );
  }
}
