export const FILTER_VOLUME_HINT = `
  The Volume filter is used exclusively for New and Open signals, and is not utilized for Exit signals.\n
  When a volume parameter is set, it shows symbols whose trading volume falls within the specified range on the last closed trading day.\n
  (1T = 1,000B = 1,000,000M = 1,000,000,000K = 1,000,000,000,0000)
  (1B = 1,000M = 1,000,000K = 1,000,000,000)
  (1M = 1,000K = 1,000,000)
  (1K = 1,000)
`;

export const FILTER_MARKET_CAP_HINT = `
  Nano - below $50M
  Micro - between $50M and $300M
  Small - between $300M and $2B
  Medium - between $2B and $10B
  Large - between $10B and $200B
  Very Large - above $200B\n
  (1T = 1,000B = 1,000,000M = 1,000,000,000K = 1,000,000,000,0000)
  (1B = 1,000M = 1,000,000K = 1,000,000,000)
  (1M = 1,000K = 1,000,000)
  (1K = 1,000)
`;
