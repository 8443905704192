import { UserAccessLevels } from '@const';

export enum QuickLinksRecordType {
  UserLink = 'userLink',
  PxoLink = 'pxoLink',
}

export interface NewQuickLink {
  title: string;
  url: string;
}

export interface QuickLink extends NewQuickLink {
  id: number;
}

export interface PxoQuickLink extends QuickLink {
  editions: UserAccessLevels[];
}

export interface QuickLinksRecord {
  title: string;
  items: QuickLink[] | PxoQuickLink[];
  settings: QuickLinksRecordSettings;
}

export interface QuickLinksRecordSettings {
  recordType: QuickLinksRecordType;
  order: number;
  isExpanded: boolean;
}
