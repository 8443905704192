<div mat-dialog-content>
  <div class="text-center">
    <ng-container *ngIf="importing; else importStatus">
      <div class="status-loader">
        <app-loading-animation></app-loading-animation>
      </div>
      <h3 class="status-title">Importing trades</h3>
      <p class="status-text">Please wait, it may take a few minutes.</p>
      <p class="status-text sub-text">Leave this page open and use Trading Log only after import is completed.</p>
    </ng-container>
    <ng-template #importStatus>
      <div class="status-loader">
        <mat-icon [svgIcon]="'complete-icon'"></mat-icon>
      </div>
      <h3 class="status-title">Import has been completed!</h3>
      <p class="status-text" *ngIf="actuiallyImported !== 0">
        {{actuiallyImported}} of {{data.importedTradesCount}} new {{actuiallyImported === 1 ? 'trade was' : 'trades were'}} added into Trading Log.
      </p>
      <p class="status-text" *ngIf="actuiallyImported === 0">Operation failed. No trades were added into Trading log.</p>
      <p class="status-text" *ngIf="actuiallyImported === 0">Please try again later.</p>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions>
  <div class="action-buttons" *ngIf="!importing">
    <button *ngIf="actuiallyImported !== 0"
      mat-stroked-button
      class="secondary-btn default-bg secondary"
      (click)="close('viewInTradingLog')">
      View Trading Log
    </button>
    <button *ngIf="actuiallyImported !== 0"
      mat-button
      class="main-bg button"
      (click)="close('continueImporting')">
      Continue importing
    </button>
    <button *ngIf="actuiallyImported === 0"
      mat-button
      class="main-bg button"
      (click)="close()">
      Ok
    </button>
  </div>
</div>
