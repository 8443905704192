import { registerLocaleData } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ObservableService } from '@s/observable.service';
import { ScannerResultsService } from '@s/scanner-results.service';
import { UserDataService } from '@s/user-data.service';
import {
  ExchangeCountries,
  ExchangeCountriesCodes,
  formatDecimal,
  formatNumber,
  ScannerResultTypes,
  TradePositions,
  UserSettings,
} from '@const';
import { arrayEquals, isNullOrUndefinedOrEmpty } from '@u/utils';

@Component({
  selector: 'app-scanner-setting-popup',
  templateUrl: './scanner-setting-popup.component.html',
  styleUrls: ['./scanner-setting-popup.component.scss'],
})
export class ScannerSettingPopupComponent implements OnInit {
  protected readonly formatNumber = formatNumber;
  protected readonly formatDecimal = formatDecimal;

  protected readonly TradePositions = TradePositions;
  protected readonly ExchangeCountries = ExchangeCountries;
  protected readonly exchangeCountriesKeys = Object.keys(ExchangeCountries);

  protected readonly scannerSettingTooltip = `
    % Return On Investment - This is the return for the year based on the last position size.\n
    Min Win % - This is the number of wins in the last 24 months divided by total number of trades.\n
    Profit Factor - This is the gross profit for all signals over the last 24 months divided by gross losses. The ratio is the "profit" for every dollar risked based on historical signals. For example, a PF of 3.5 means that in the base $3.50 was made for every $1 risked.\n
    Min # of Trades - This is the minimum number of trades for the last 24 months.
  `;

  protected scannerForm = this.formBuilder.group({
    signal_type: [null, []],
    min_roi: [null, [Validators.required]],
    min_profit_factor: [null, [Validators.required]],
    min_win_percent: [null, [Validators.required]],
    min_trades_count: [null, [Validators.required]],
    min_close_price: [null, [Validators.required]],
    max_close_price: [null, [Validators.required]],
    min_volume: [null, [Validators.required]],
    strategy_id: [null, []],
    country_codes: [null, []],
  });

  protected tradingStrategyId;
  protected tradingStrategies;
  protected userScannerSettings;

  protected showLoader = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialog,
    private userDataService: UserDataService,
    private scannerResultsService: ScannerResultsService,
    private observableService: ObservableService
  ) { }

  async ngOnInit(): Promise<void> {
    registerLocaleData('us');

    this.tradingStrategyId = this.data.runScannerTradingStrategyId;

    this.tradingStrategies = this.data.tradingStrategies.map((st) => ({
      strategy_id: st.id,
      ...st,
      scanner_settings: JSON.parse(st.scanner_settings),
    }));

    this.userScannerSettings = await this.getUserScannerSettings(this.tradingStrategies);
    this.updateForm();
  }

  getUserSettingsStrategyKey(strategyId: number | string) {
    return `${UserSettings.ScannerResultsSettings}_${strategyId}`;
  }

  async getUserScannerSettings(tradingStrategies) {
    const keys = tradingStrategies.map(({ strategy_id }) => this.getUserSettingsStrategyKey(strategy_id));
    const userScannerResults = await this.userDataService.getMultiple(keys);

    return userScannerResults
      .map((s) => {
        const strategyId = parseInt(s.key.replace(`${UserSettings.ScannerResultsSettings}_`, ''), 10);

        if (!isNaN(strategyId)) {
          const scannerSettings = JSON.parse(s.value);
          return {
            id: s.id,
            strategy_id: strategyId,
            scanner_settings: {
              country_codes: [ExchangeCountriesCodes.US],
              ...scannerSettings,
            },
          };
        }
      })
      .filter((res) => res);
  }

  getScannerSettings() {
    const userSpecificSettings = this.userScannerSettings.find((s) => s.strategy_id === this.tradingStrategyId);

    if (userSpecificSettings) {
      return userSpecificSettings;
    }

    return this.tradingStrategies.find((s) => s.strategy_id === this.tradingStrategyId);
  }

  changeStrategy() {
    this.tradingStrategyId = this.scannerForm.controls.strategy_id.value;
    this.updateForm();
  }

  updateForm() {
    const { scanner_settings } = this.getScannerSettings();

    this.scannerForm.patchValue({
      signal_type: scanner_settings.signal_type,
      min_roi: scanner_settings.min_roi,
      min_profit_factor: scanner_settings.min_profit_factor,
      min_win_percent: scanner_settings.min_win_percent,
      min_trades_count: scanner_settings.min_trades_count,
      min_close_price: scanner_settings.min_close_price,
      max_close_price: scanner_settings.max_close_price,
      min_volume: scanner_settings.min_volume,
      strategy_id: this.tradingStrategyId,
      country_codes: scanner_settings.country_codes,
    });
  }

  inputBlur(input: string): void {
    const { scanner_settings } = this.getScannerSettings();
    const inputValue = this.scannerForm.value[input];

    if (isNullOrUndefinedOrEmpty(inputValue)) {
      this.scannerForm.controls[input].setValue(scanner_settings[input]);
    }
  }

  async changeSetting() {
    if (!this.scannerForm.valid) {
      return;
    }

    this.showLoader = true;

    const [, , , scannerResults] = await Promise.all([
      this.userDataService.set(UserSettings.TradePosition, this.scannerForm.controls.signal_type.value),
      this.updateUserSettingsIfNeeded(),
      this.scannerResultsService.removeAll(ScannerResultTypes.Pxo),
      this.scannerResultsService.run(this.getCurrentSettings()),
    ]);

    if (scannerResults.length) {
      await this.scannerResultsService.insert(
        scannerResults.map((r) => r.security_id),
        this.tradingStrategyId,
        ScannerResultTypes.Pxo
      );
    }

    this.observableService.scannerResultUpdated.next(true);
    await this.userDataService.set(UserSettings.SelectScannerTab, 0);
    this.showLoader = false;
    this.dialogRef.closeAll();
  }

  async resetSetting() {
    const userSpecificSettings = this.userScannerSettings.find((s) => s.strategy_id === this.tradingStrategyId);

    if (userSpecificSettings) {
      await this.userDataService.remove([userSpecificSettings.id]);
      this.userScannerSettings = this.userScannerSettings.filter((s) => s.strategy_id !== this.tradingStrategyId);
    }

    this.updateForm();
  }

  async updateUserSettingsIfNeeded() {
    const userSpecificSettings = this.userScannerSettings.find((s) => s.strategy_id === this.tradingStrategyId);
    const { scanner_settings } = this.tradingStrategies.find((s) => s.strategy_id === this.tradingStrategyId);
    const currentSettings = this.getCurrentSettings();

    if (userSpecificSettings && userSpecificSettings.scanner_settings) {
      if (this.settingsAreEqual(currentSettings, scanner_settings)
        || !this.settingsAreEqual(userSpecificSettings.scanner_settings, currentSettings)
      ) {
        await this.userDataService.remove(userSpecificSettings.id);
      }
    }

    const promises = [];

    if (!this.settingsAreEqual(currentSettings, scanner_settings)) {
      promises.push(this.userDataService.set(this.getUserSettingsStrategyKey(this.tradingStrategyId), currentSettings));
    }

    promises.push(this.userDataService.set(UserSettings.RunScannerTradingStrategyId, this.tradingStrategyId));

    await Promise.all(promises);
  }

  getCurrentSettings() {
    return {
      signal_type: this.scannerForm.value.signal_type,
      min_roi: this.scannerForm.value.min_roi,
      min_profit_factor: this.scannerForm.value.min_profit_factor,
      min_win_percent: this.scannerForm.value.min_win_percent,
      min_trades_count: this.scannerForm.value.min_trades_count,
      min_close_price: this.scannerForm.value.min_close_price,
      max_close_price: this.scannerForm.value.max_close_price,
      min_volume: this.scannerForm.value.min_volume,
      strategy_id: this.tradingStrategyId,
      country_codes: this.scannerForm.value.country_codes,
    };
  }

  settingsAreEqual(first, second) {
    const properties = [
      'signal_type',
      'min_roi',
      'min_profit_factor',
      'min_win_percent',
      'min_trades_count',
      'min_close_price',
      'max_close_price',
      'min_volume',
      'country_codes',
    ];

    for (const property of properties) {
      if (Array.isArray(first[property]) && !arrayEquals(first[property].sort(), second[property].sort())) {
        return false;
      }

      if (first[property] !== second[property]) {
        return false;
      }
    }

    return true;
  }

  setCountryCode(code: string) {
    const index = this.scannerForm.value.country_codes?.indexOf(code);
    const countryCodes = index === -1
      ? [...this.scannerForm.value.country_codes].concat(code)
      : this.scannerForm.value.country_codes.filter((c) => c !== code);

    this.scannerForm.patchValue({
      country_codes: countryCodes,
    });
  }
}
