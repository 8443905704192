import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';

import { ObservableService as ObservableServiceV1 } from '@core1/directives/observable.service';
import { DataChannelService } from '@s/data-channel.service';
import { ObservableService } from '@s/observable.service';
import { SmileyDataService } from '@s/smiley-data.service';
import { TradingLogStreamingService } from '@s/trading-log/trading-log-streaming.service';
import { UserDataService } from '@s/user-data.service';
import { EditionsService } from '@s/editions.service';
import { IMySettings, UserSettingsService } from '@s/user-settings.service';
import {
  DataChannelCommands,
  DefaultRockyAlwaysVisible,
  defaultTradingLogInputDateFormats,
  Features,
  Themes,
  TradingAccountCalculationMethods,
  UserSettings,
  WheelROIDefaultRange
} from '@const';
import { SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { TLUpdateAccountCommand, TradingLogAccountModel } from '@mod/trading-log';
import { IWheelROIRange } from '@t/wheel/wheel.types';
import { IFeaturesDetails } from '@mod/editions/editions.model';
import {
  LoginNavigationControlValueEnum,
  LoginRedirectPageModel
} from '@c/my-settings-modal/my-settings-modal.model';
import { DefaultLoginNavigationControlValue, LoginRedirectPageList } from '@c/my-settings-modal/my-settings-modal.data';

@Component({
  selector: 'app-my-settings-modal',
  templateUrl: './my-settings-modal.component.html',
  styleUrls: ['./my-settings-modal.component.scss'],
  providers: []
})
export class MySettingsModalComponent implements OnInit, OnDestroy {
  protected resetPowerXFlagsLoading = false;
  protected resetWheelFlagsLoading = false;
  protected resetWtfFlagsLoading = false;
  protected resetStockScreenersFlagsLoading = false;
  protected resetDividendsStrategyFlagsLoading = false;
  protected resetShortSellingStocksFlagsLoading = false;
  protected resetShortingStocksScannerFlagsLoading = false;

  protected powerxSuccess = false;
  protected powerxError = false;
  protected wheelSuccess = false;
  protected wheelError = false;
  protected wtfSuccess = false;
  protected wtfError = false;
  protected stockScreenersSuccess = false;
  protected stockScreenersError = false;
  protected dividendsStrategySuccess = false;
  protected dividendsStrategyError = false;
  protected shortSellingStocksSuccess = false;
  protected shortSellingStocksError = false;
  protected shortingStocksScannerSuccess = false;
  protected shortingStocksScannerError = false;

  protected userId = JSON.parse(localStorage.getItem('user')).userId;

  protected timerIdPowerX: NodeJS.Timeout | null = null;
  protected timerIdWheel:  NodeJS.Timeout | null = null;
  protected timerIdWtf:  NodeJS.Timeout | null = null;
  protected timerIdStockScreeners:  NodeJS.Timeout | null = null;
  protected timerIdDividendsStrategy:  NodeJS.Timeout | null = null;
  protected timerIdShortSellingStocks:  NodeJS.Timeout | null = null;
  protected timerIdShortingStocksScanner:  NodeJS.Timeout | null = null;

  protected wheelAnalyzerOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every Saturday (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];
  protected powerxAnalyzerOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every 30 days (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];
  protected wtfAnalyzerOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every Saturday (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];
  protected stockScreenersOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every Saturday (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];
  protected dividendsStrategyOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every Saturday (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];
  protected shortSellingStocksOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every Saturday (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];
  protected shortingStocksScannerOptions: { name: string, value: number }[] = [
    {
      name: 'Reset the flags, except Black Flag, every Saturday (Recommended)',
      value: 1
    },
    {
      name: 'Do not reset the flags. Keep them forever',
      value: 0
    }
  ];

  protected mySettingsFlags: {
    reset_flag_pxo: 0 | 1,
    reset_flag_wheel: 0 | 1,
    reset_flag_wtf: 0 | 1,
    reset_flag_stock_screener: 0 | 1,
    reset_flag_dividends: 0 | 1,
    reset_flag_short_selling_stocks: 0 | 1,
    reset_flag_shorting_stocks_scanner: 0 | 1,
  } = {
    reset_flag_pxo: null,
    reset_flag_wheel: null,
    reset_flag_wtf: null,
    reset_flag_stock_screener: null,
    reset_flag_dividends: null,
    reset_flag_short_selling_stocks: null,
    reset_flag_shorting_stocks_scanner: null,
  };
  protected tradierFlags: { tradier_flag_pxo: number, tradier_flag_ifs: number } = { tradier_flag_pxo: null, tradier_flag_ifs: null };
  protected wheelROIRange: IWheelROIRange = { lower: WheelROIDefaultRange.Lower, upper: WheelROIDefaultRange.Upper };
  protected wheelROIRangeInputs: IWheelROIRange = { lower: WheelROIDefaultRange.Lower, upper: WheelROIDefaultRange.Upper };

  public isPlayingOpenMarketSound = false;
  public playOpenMarketSound = false;
  public playCloseMarketSound = false;
  public playOpenMarketSoundSaveTimer: NodeJS.Timeout | null = null;
  public playCloseMarketSoundSaveTimer: NodeJS.Timeout | null = null;

  protected showStockScreener = Boolean(this.observableService.showStockScreener.getValue());
  protected showSmileyStatistic = true;
  protected readonly features = Features;

  protected tabIndex = 0;
  protected user;
  protected showUpdatingOverlay = false;

  protected readonly tradingAccountCalculationMethods = TradingAccountCalculationMethods;
  protected readonly loginNavigationControlValueEnum = LoginNavigationControlValueEnum;

  protected tradingLogAccountsDisplayColumns: string[] = ['name', 'calculation_method'];
  protected tradingLogAccountsDataSource = new MatTableDataSource<TradingLogAccountModel>();

  protected readonly mostUsedPageList = LoginRedirectPageList
    .filter((p) => p.displayOption && this.editionsService.isFeatureAvailable(p.feature));

  protected loginNavigationControl: FormControl<LoginNavigationControlValueEnum>;
  protected loginNavigationPageSelectControl: FormControl<LoginRedirectPageModel>;
  protected showRockyAlways = this.observableService.showRockyAlways;

  private subscriptions = new Subscription();

  get crossSvg(): string {
    const theme = this.observableService.theme.value;
    return `assets/img/newImg/Close-p${theme === Themes.Dark ? '-white' : ''}.svg`;
  }

  constructor(
    private userSettingsService: UserSettingsService,
    private smileyDataService: SmileyDataService,
    private observableService: ObservableService,
    private observableServiceV1: ObservableServiceV1,
    private userDataService: UserDataService,
    private tradingLogStreamingService: TradingLogStreamingService,
    private dataChannelService: DataChannelService,
    private editionsService: EditionsService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loginNavigationControl = new FormControl(
      this.observableService.loginNavigationSettings.getValue().controlValue || DefaultLoginNavigationControlValue
    );

    const selectedPage: IFeaturesDetails | null = this.observableService.loginNavigationSettings.getValue().selectControlValue;
    const selectedMostUsedPage = this.mostUsedPageList.find((p) => p.tabName === selectedPage?.tabName);

    if (selectedPage && selectedMostUsedPage) {
      this.loginNavigationPageSelectControl = new FormControl(selectedMostUsedPage);
    } else {
      this.loginNavigationPageSelectControl = new FormControl(this.mostUsedPageList[0]);

      await this.userDataService.set(UserSettings.LoginNavigationSettings, {
        controlValue: this.loginNavigationControl.value,
        selectControlValue: this.loginNavigationPageSelectControl.value
      });
    }

    this.loginNavigationControl.valueChanges.subscribe((value) => {
      this.userDataService.set(UserSettings.LoginNavigationSettings, {
        controlValue: value,
        selectControlValue: this.loginNavigationPageSelectControl.value
      });
    });

    this.loginNavigationPageSelectControl.valueChanges.subscribe((value) => {
      this.loginNavigationControl.setValue(LoginNavigationControlValueEnum.SelectedPage, { emitEvent: false });

      this.userDataService.set(UserSettings.LoginNavigationSettings, {
        controlValue: this.loginNavigationControl.value,
        selectControlValue: value
      });
    });

    this.user = this.userDataService.getUser();
    const mySettings = this.observableService.mySettings.getValue();

    this.updateMySettings(mySettings);

    this.playOpenMarketSound = this.observableService.playOpenMarketSound.getValue();
    this.playCloseMarketSound = this.observableService.playCloseMarketSound.getValue();
    this.showSmileyStatistic = this.observableService.showSmileyStatistics.getValue();

    this.wheelROIRangeInputs = this.observableService.wheelRoiRange.getValue();
    this.observableService.mySettings.subscribe((newSettings) => this.updateMySettings(newSettings));

    this.subscriptions.add(
      this.tradingLogStreamingService.accounts$.subscribe((accounts) => {
        this.tradingLogAccountsDataSource = new MatTableDataSource<TradingLogAccountModel>(accounts.slice());
        this.showUpdatingOverlay = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateMySettings(mySettings: IMySettings): void {
    const {
      reset_flag_pxo,
      reset_flag_wheel,
      reset_flag_wtf,
      reset_flag_stock_screener,
      reset_flag_dividends,
      reset_flag_short_selling_stocks,
      reset_flag_shorting_stocks_scanner,
      tradier_flag_pxo,
      tradier_flag_ifs
    } = mySettings;

    this.mySettingsFlags = {
      reset_flag_pxo,
      reset_flag_wheel,
      reset_flag_wtf,
      reset_flag_stock_screener,
      reset_flag_dividends,
      reset_flag_short_selling_stocks,
      reset_flag_shorting_stocks_scanner,
    };

    this.tradierFlags = {
      tradier_flag_pxo,
      tradier_flag_ifs,
    };
  }

  async resetFlagsPowerx(): Promise<void> {
    this.powerxSuccess = false;
    this.powerxError = false;
    this.resetPowerXFlagsLoading = true;
    clearTimeout(this.timerIdPowerX);

    try {
      await this.removeUserFlagsByList(SmileyListType.PowerX);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.PowerX);

      this.observableService.scannerResultUpdated.next(true);
      this.resetPowerXFlagsLoading = false;
      this.powerxSuccess = true;
      this.timerIdPowerX = setTimeout(async () => {
        this.powerxSuccess = false;
      }, 3000);
    } catch {
      this.resetPowerXFlagsLoading = false;
      this.powerxError = true;
      this.timerIdPowerX = setTimeout(async () => {
        this.powerxError = false;
      }, 3000);
    }
  }

  async resetFlagsWheel(): Promise<void> {
    this.wheelSuccess = false;
    this.wheelError = false;
    this.resetWheelFlagsLoading = true;
    clearTimeout(this.timerIdWheel);

    try {
      await this.removeUserFlagsByList(SmileyListType.Wheel);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.Wheel);

      this.resetWheelFlagsLoading = false;
      this.wheelSuccess = true;
      this.timerIdWheel = setTimeout(async () => {
        this.wheelSuccess = false;
      }, 3000);
    } catch {
      this.resetWheelFlagsLoading = false;
      this.wheelError = true;
      this.timerIdWheel = setTimeout(async () => {
        this.wheelError = false;
      }, 3000);
    }
  }

  async resetFlagsWtf(): Promise<void> {
    this.wtfSuccess = false;
    this.wtfError = false;
    this.resetWtfFlagsLoading = true;
    clearTimeout(this.timerIdWtf);

    try {
      await this.removeUserFlagsByList(SmileyListType.Wtf);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.Wtf);

      this.resetWtfFlagsLoading = false;
      this.wtfSuccess = true;
      this.timerIdWtf = setTimeout(async () => {
        this.wtfSuccess = false;
      }, 3000);
    } catch {
      this.resetWtfFlagsLoading = false;
      this.wtfError = true;
      this.timerIdWtf = setTimeout(async () => {
        this.wtfError = false;
      }, 3000);
    }
  }

  async resetFlagsStockScreeners(): Promise<void> {
    this.stockScreenersSuccess = false;
    this.stockScreenersError = false;
    this.resetStockScreenersFlagsLoading = true;
    clearTimeout(this.timerIdStockScreeners);

    try {
      await this.removeUserFlagsByList(SmileyListType.StockScreener);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.StockScreener);

      this.resetStockScreenersFlagsLoading = false;
      this.stockScreenersSuccess = true;
      this.timerIdStockScreeners = setTimeout(async () => {
        this.stockScreenersSuccess = false;
      }, 3000);
    } catch {
      this.resetStockScreenersFlagsLoading = false;
      this.stockScreenersError = true;
      this.timerIdStockScreeners = setTimeout(async () => {
        this.stockScreenersError = false;
      }, 3000);
    }
  }

  async resetFlagsDividendsStrategy(): Promise<void> {
    this.dividendsStrategySuccess = false;
    this.dividendsStrategyError = false;
    this.resetDividendsStrategyFlagsLoading = true;
    clearTimeout(this.timerIdDividendsStrategy);

    try {
      await this.removeUserFlagsByList(SmileyListType.DividendsStrategy);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.DividendsStrategy);

      this.resetDividendsStrategyFlagsLoading = false;
      this.dividendsStrategySuccess = true;
      this.timerIdDividendsStrategy = setTimeout(async () => {
        this.dividendsStrategySuccess = false;
      }, 3000);
    } catch {
      this.resetDividendsStrategyFlagsLoading = false;
      this.dividendsStrategyError = true;
      this.timerIdDividendsStrategy = setTimeout(async () => {
        this.dividendsStrategyError = false;
      }, 3000);
    }
  }

  async resetFlagsShortSellingStocks(): Promise<void> {
    this.shortSellingStocksSuccess = false;
    this.shortSellingStocksError = false;
    this.resetShortSellingStocksFlagsLoading = true;
    clearTimeout(this.timerIdShortSellingStocks);

    try {
      await this.removeUserFlagsByList(SmileyListType.ShortSellingStocks);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.ShortSellingStocks);

      this.resetShortSellingStocksFlagsLoading = false;
      this.shortSellingStocksSuccess = true;
      this.timerIdShortSellingStocks = setTimeout(async () => {
        this.shortSellingStocksSuccess = false;
      }, 3000);
    } catch {
      this.resetShortSellingStocksFlagsLoading = false;
      this.shortSellingStocksError = true;
      this.timerIdShortSellingStocks = setTimeout(async () => {
        this.shortSellingStocksError = false;
      }, 3000);
    }
  }

  async resetFlagsShortingStocksScanner(): Promise<void> {
    this.shortingStocksScannerSuccess = false;
    this.shortSellingStocksError = false;
    this.resetShortingStocksScannerFlagsLoading = true;
    clearTimeout(this.timerIdShortingStocksScanner);

    try {
      await this.removeUserFlagsByList(SmileyListType.ShortingStocksScanner);
      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.ShortingStocksScanner);

      this.resetShortingStocksScannerFlagsLoading = false;
      this.shortingStocksScannerSuccess = true;
      this.timerIdShortingStocksScanner = setTimeout(async () => {
        this.shortingStocksScannerSuccess = false;
      }, 3000);
    } catch {
      this.resetShortingStocksScannerFlagsLoading = false;
      this.shortingStocksScannerError = true;
      this.timerIdShortingStocksScanner = setTimeout(async () => {
        this.shortingStocksScannerError = false;
      }, 3000);
    }
  }

  async onChangeSmileyStatistic(event: { checked: boolean }): Promise<void> {
    this.showSmileyStatistic = event.checked;
    await this.userDataService.set(UserSettings.ShowSmileyStatistics, event.checked);
  }

  async resetFlagPowerX(event): Promise<void> {
    this.mySettingsFlags.reset_flag_pxo = event.value;
    await this.saveAll();
  }

  async resetFlagWheel(event): Promise<void> {
    this.mySettingsFlags.reset_flag_wheel = event.value;
    await this.saveAll();
  }

  async resetFlagWtf(event): Promise<void> {
    this.mySettingsFlags.reset_flag_wtf = event.value;
    await this.saveAll();
  }

  async resetFlagStockScreeners(event): Promise<void> {
    this.mySettingsFlags.reset_flag_stock_screener = event.value;
    await this.saveAll();
  }

  async resetFlagDividendsStrategy(event): Promise<void> {
    this.mySettingsFlags.reset_flag_dividends = event.value;
    await this.saveAll();
  }

  async resetFlagShortSellingStocks(event): Promise<void> {
    this.mySettingsFlags.reset_flag_short_selling_stocks = event.value;
    await this.saveAll();
  }

  async resetFlagShortingStocksScanner(event): Promise<void> {
    this.mySettingsFlags.reset_flag_shorting_stocks_scanner = event.value;
    await this.saveAll();
  }

  async tradierFlagPowerX(event): Promise<void> {
    this.tradierFlags.tradier_flag_pxo = Number(event.checked);
    await this.saveAll();
  }

  async saveAll(): Promise<void> {
    const newTradierFlag = this.tradierFlags.tradier_flag_pxo ? 1 : 0;
    const {
      reset_flag_pxo,
      reset_flag_wheel,
      reset_flag_wtf,
      reset_flag_stock_screener,
      reset_flag_dividends,
      reset_flag_short_selling_stocks,
      reset_flag_shorting_stocks_scanner,
    } = this.mySettingsFlags;
    await this.userSettingsService.update(
      reset_flag_pxo,
      reset_flag_wheel,
      reset_flag_wtf,
      reset_flag_stock_screener,
      reset_flag_dividends,
      reset_flag_short_selling_stocks,
      reset_flag_shorting_stocks_scanner,
      newTradierFlag,
    );
    const mySettings = this.observableService.mySettings.getValue();
    this.observableService.mySettings.next({
      ...mySettings,
      reset_flag_pxo,
      reset_flag_wheel,
      reset_flag_wtf,
      reset_flag_stock_screener,
      reset_flag_dividends,
      reset_flag_short_selling_stocks,
      reset_flag_shorting_stocks_scanner,
      tradier_flag_pxo: newTradierFlag
    });
    this.observableServiceV1.tradierFlags.next({ ...this.tradierFlags, tradier_flag_pxo: newTradierFlag });
  }

  async onWheelROIRange(event): Promise<void> {
    this.wheelROIRange.lower = event.value;
    this.wheelROIRange.upper = event.highValue;
    await this.userDataService.setMultiple([
      { key: UserSettings.WheelROILowerBound, value: this.wheelROIRange.lower },
      { key: UserSettings.WheelROIUpperBound, value: this.wheelROIRange.upper }
    ]);
    this.wheelROIRangeInputs.lower = event.value;
    this.wheelROIRangeInputs.upper = event.highValue;
    this.observableServiceV1.wheelRoiRange.next({ lower: this.wheelROIRange.lower, upper: this.wheelROIRange.upper });
  }

  async resetDisplaySetting(): Promise<void> {
    this.onWheelROIRange({ value: WheelROIDefaultRange.Lower, highValue: WheelROIDefaultRange.Upper });
    this.loginNavigationControl.setValue(DefaultLoginNavigationControlValue, { emitEvent: false });
    this.loginNavigationPageSelectControl.setValue(this.mostUsedPageList[0], { emitEvent: false });

    await this.userDataService.set(UserSettings.ShowRockyAlways, DefaultRockyAlwaysVisible);
    await this.userDataService.set(UserSettings.LoginNavigationSettings, {
      controlValue: this.loginNavigationControl.value,
      selectControlValue: this.loginNavigationPageSelectControl.value
    });
    await this.userDataService.set(UserSettings.TradingLogSelectedInputDateFormat, defaultTradingLogInputDateFormats);
  }

  public onChangePlayMarketSound(checked: boolean, type: 'open' | 'close'): void {
    if (type === 'open') {
      this.playOpenMarketSound = checked;
      clearTimeout(this.playOpenMarketSoundSaveTimer);

      this.playOpenMarketSoundSaveTimer = setTimeout(async () => {
        await this.userDataService.set(UserSettings.PlayOpenMarketSound, checked);
      }, 1000);
    }

    if (type === 'close') {
      this.playCloseMarketSound = checked;
      clearTimeout(this.playCloseMarketSoundSaveTimer);

      this.playCloseMarketSoundSaveTimer = setTimeout(async () => {
        await this.userDataService.set(UserSettings.PlayCloseMarketSound, checked);
      }, 1000);
    }
  }

  public async playOpenCloseMarketSound(): Promise<void> {
    if (this.isPlayingOpenMarketSound) {
      return;
    }

    const audioElement = new Audio('../../../assets/sounds/stock_market_bell.mp3');
    audioElement.autoplay = false;
    audioElement.muted = false;

    try {
      this.isPlayingOpenMarketSound = true;
      await audioElement.play();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      this.isPlayingOpenMarketSound = false;
    }

    audioElement.addEventListener('ended', () => {
      this.isPlayingOpenMarketSound = false;
    });
  }

  protected changeCalculationMethod(account: TradingLogAccountModel, tab: MatButtonToggleChange): void {
    this.showUpdatingOverlay = true;

    const updatedAccount = {
      ...account,
      calculation_method: tab.value
    };

    this.tradingLogStreamingService.sendCommand(new TLUpdateAccountCommand(updatedAccount));
  }

  protected async rockyVisibilityChange(event: MatSlideToggleChange): Promise<void> {
    await this.userDataService.set(UserSettings.ShowRockyAlways, Number(event.checked));
  }

  private async removeUserFlagsByList(list: SmileyListType): Promise<void> {
    const dataChannelExecuteCommands: Record<SmileyListType, DataChannelCommands> = {
      [SmileyListType.Wtf]: DataChannelCommands.WtfSmileyExecute,
      [SmileyListType.Wheel]: DataChannelCommands.WheelSmileyExecute,
      [SmileyListType.PowerX]: DataChannelCommands.PxoSmileyExecute,
      [SmileyListType.StockScreener]: DataChannelCommands.StockScreenerSmileyExecute,
      [SmileyListType.ShortSellingStocks]: DataChannelCommands.ShortSellingStocksSmileyExecute,
      [SmileyListType.ShortingStocksScanner]: DataChannelCommands.ShortingStocksScannerSmileyExecute,
      [SmileyListType.DividendsStrategy]: DataChannelCommands.DividendsStrategySmileyExecute,
    };

    return new Promise<void>((resolve, reject) => {
      const dataChannelCommand = {
        subscriptionId: uuidV4(),
        name: dataChannelExecuteCommands[list],
        data: { is_remove_all: true },
        handler: ({ success }: { success: boolean }): void => {
          if (success) {
            // clear the last received statistics and flags state
            this.smileyDataService.resetFlagsState(list);
            this.smileyDataService.updateSymbolsSmileySignal$.next(list);

            resolve();
          }

          reject(null);
        },
      };

      this.dataChannelService.execute(dataChannelCommand);
    });
  }
}
