import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject, combineLatest, from, of, ReplaySubject, Subscriber } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, switchMap, take, tap, } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ServerDataService } from '@s/server-data.service';
import { TradingHubService } from '@s/trading-hub.service';
import { UserDataService } from '@s/user-data.service';
import { ObservableService } from '@s/observable.service';
import { DialogsService } from '@s/common';
import { PrintOptions, saveTradingHubSettingsDebounceTime, ServerSettings, UserSettings, } from '@const';
import { ITradingPlan, ITradingPlanTemplate, PlanModes, SaveStatuses, } from '@c/trading-hub/trading-plan/trading-plan.model';
import { blankTradingPlanTemplate, } from '@c/trading-hub/trading-plan/trading-plan.data';

@Component({
  selector: 'app-trading-plan',
  templateUrl: './trading-plan.component.html',
  styleUrls: ['./trading-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradingPlanComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public maxPlanTitleLength = 20;
  public maxPlansNumber = 20;

  public selectedTabIndex = 0;
  public tradingPlans: ITradingPlan[] = [];

  public activePlanID: string | null = null; // edit mode
  public prevActivePlanState: ITradingPlan | null = null; // keep prev-state in process of editing
  public saveStatus$ = new BehaviorSubject<SaveStatuses>(SaveStatuses.Success);

  public savePlans$ = new ReplaySubject<ITradingPlan[]>(1);
  public savePlanTab$ = new ReplaySubject<number>(1);

  // for drop-down add-plan menu
  public blankTradingPlanTemplate = blankTradingPlanTemplate;
  public availableTradingPlanTemplates: ITradingPlanTemplate[] = [];

  public isMobileOS = this.getIsMobileOS(this.platform);

  private subscriber = new Subscriber();

  constructor(
    private serverDataService: ServerDataService,
    private tradingHubService: TradingHubService,
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private dialogsService: DialogsService,
    private changeDetectorRef: ChangeDetectorRef,
    private platform: Platform,
  ) {
  }

  ngOnInit() {
    this.subscriber.add(
      combineLatest([
        this.observableService.tradingHubPlans,
        this.observableService.tradingHubPlansTab,
        from(this.serverDataService.getAsObject<ITradingPlanTemplate[]>(ServerSettings.TradingHubDefaultPlans)),
      ])
        .pipe(
          take(1),
          tap(([plans, tab, planTemplates]) => {
            this.tradingPlans = plans ?? [];

            const userSettings = this.observableService.mySettings.getValue();

            if (planTemplates && Array.isArray(planTemplates) && planTemplates.length > 0) {
              this.availableTradingPlanTemplates = planTemplates.filter((plan) => {
                if (!plan.requiredAccess || plan.requiredAccess.length === 0) {
                  return true;
                }

                return plan.requiredAccess.every((settingName) => Boolean(userSettings[settingName]));
              });
            }

            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          }),
          delay(400),
          tap(([plans, tab, planTemplates]) => {
            this.selectedTabIndex = tab ?? 0;
            this.changeDetectorRef.markForCheck();
          }),
        )
        .subscribe()
    );

    this.subscriber.add(
      this.savePlanTab$
        .pipe(
          debounceTime(saveTradingHubSettingsDebounceTime),
          distinctUntilChanged(),
          switchMap((tab) => this.userDataService.set(UserSettings.TradingHubPlansTab, tab)),
        )
        .subscribe()
    );

    this.subscriber.add(
      this.savePlans$
        .pipe(
          tap((plans) => {
            this.observableService.tradingHubPlans.next(plans);
          }),
          tap(() => {
            this.saveStatus$.next(SaveStatuses.InProgress);
          }),
          debounceTime(saveTradingHubSettingsDebounceTime),
          switchMap((plans) => {
            // important: keep "catchError" inside switchMap to keep it working after the first error
            return from(this.userDataService.set(UserSettings.TradingHubPlans, JSON.stringify(plans)))
              .pipe(catchError(() => of({ isError: true })));
          }),
        )
        .subscribe((result) => {
          if (result && result.isError) {
            this.saveStatus$.next(SaveStatuses.Error);
            return;
          }

          this.saveStatus$.next(SaveStatuses.Success);
        })
    );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();

    // finish saving settings
    this.savePlanTab$
      .pipe(
        take(1),
        switchMap((tab) => this.userDataService.set(UserSettings.TradingHubPlansTab, tab)),
      )
      .subscribe();

    this.savePlans$
      .pipe(
        take(1),
        switchMap((tasks) => this.userDataService.set(UserSettings.TradingHubPlans, tasks)),
      )
      .subscribe();
  }

  public onChangePlanTab(tabIndex): void {
    this.selectedTabIndex = tabIndex;

    this.savePlanTab$.next(tabIndex);
  }

  public onPlanTabDrop(dropEvent: CdkDragDrop<ITradingPlan[]>): void {
    if (dropEvent.previousIndex === dropEvent.currentIndex) {
      return;
    }

    const prevSelectedPlan = this.tradingPlans[this.selectedTabIndex];

    moveItemInArray(dropEvent.container.data, dropEvent.previousIndex, dropEvent.currentIndex);
    this.tradingPlans = dropEvent.container.data
      .map((item, index) => ({ ...item, order: index }))
      .sort((a, b) => a.order - b.order);

    const newSelectedPlanIndex = this.tradingPlans.findIndex((plan) => plan.id === prevSelectedPlan.id);
    this.onChangePlanTab(newSelectedPlanIndex);

    this.savePlans$.next(this.tradingPlans);
    this.changeDetectorRef.markForCheck();
  }

  public onSetEditModeForPlanTitle(plan: ITradingPlan, cursorPosition?: number): void {
    this.activePlanID = plan.id;
    this.prevActivePlanState = plan;

    setTimeout(() => {
      const element = document.getElementById(`plan-title-textarea_${plan.id}`) as HTMLTextAreaElement | null;

      if (element) {
        element.focus();

        if (cursorPosition !== undefined && cursorPosition !== null) {
          element.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    }, 100);
  }

  public onTextAreaFocusOut(plan: ITradingPlan, event: FocusEvent): void {
    const element = event.target as HTMLTextAreaElement;
    const value = element.value.trim().length > 0
      ? element.value.trim()
      : this.prevActivePlanState.title;

    this.tradingPlans = this.tradingPlans.map((item) => {
      if (item.id === plan.id) {
        return {
          ...item,
          title: value,
          updatedDate: Date.now(),
        };
      }

      return item;
    });

    this.savePlans$.next(this.tradingPlans);

    this.activePlanID = null;
    this.prevActivePlanState = null;
  }

  public onPaste(plan: ITradingPlan, event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');
    const textAreaElement = event.target as HTMLTextAreaElement;
    const currentTaskValue = textAreaElement.value;
    const selectionStart = textAreaElement.selectionStart;
    const selectionEnd = textAreaElement.selectionEnd;

    const newRawContent = currentTaskValue.slice(0, selectionStart)
      + clipboardData
      + currentTaskValue.slice(selectionEnd);

    const formattedContent = newRawContent
      .trim()
      .replace(new RegExp('\r\n|\r|\n', 'g'), '')
      .slice(0, this.maxPlanTitleLength);

    this.tradingPlans = this.tradingPlans.map((item) => {
      return item.id === plan.id
        ? { ...plan, title: formattedContent, updatedDate: Date.now() }
        : item;
    });

    const contentBeforeCursor = currentTaskValue.slice(0, selectionStart) + clipboardData;
    const cursorPosition = contentBeforeCursor.slice(0, this.maxPlanTitleLength).length;

    this.onSetEditModeForPlanTitle(plan, cursorPosition);

    this.savePlans$.next(this.tradingPlans);
  }

  public updatePlanTitle(plan: ITradingPlan, event: KeyboardEvent): void {
    event.stopPropagation();
    const element = event.currentTarget as HTMLTextAreaElement;

    if (element.value.length >= this.maxPlanTitleLength) {
      return;
    }

    this.tradingPlans = this.tradingPlans.map((item) => {
      if (item.id === plan.id) {
        return {
          ...item,
          title: element.value,
          updatedDate: Date.now(),
        };
      }

      return item;
    });

    // save trading-plan with prev title if current title is empty
    let tradingPlansToSave = this.tradingPlans;

    if (element.value.trim().length === 0) {
      tradingPlansToSave = tradingPlansToSave.map((item) => {
        if (item.id === plan.id) {
          return { ...item, title: this.prevActivePlanState.title };
        }

        return item;
      });
    }

    this.savePlans$.next(tradingPlansToSave);
  }

  public onAddPlan(planTemplate: ITradingPlanTemplate): void {
    if (this.tradingPlans.length >= this.maxPlansNumber) {
      this.showMaxAllowedPlansInfoMessage();
      return;
    }

    const newPlanOrder = this.tradingPlans.length > 0
      ? Math.max(...this.tradingPlans.map((item) => item.order)) + 1
      : 0;

    const planTitleRegExp = new RegExp(`^(${planTemplate.title}` + '( \\d+)?)$');
    const plansWithSameName = this.tradingPlans
      .map((plan) => plan.title)
      .filter((title) => planTitleRegExp.test(title));

    let newPlanTitle = '';

    if (plansWithSameName.length > 0) {
      const planIndexes = plansWithSameName
        .map((title) => Number(title.split(`${planTemplate.title}`)[1]));
      const maxPlanIndex = Math.max(...planIndexes);

      newPlanTitle = `${planTemplate.title} ${maxPlanIndex + 1}`;
    } else {
      newPlanTitle = planTemplate.title;
    }

    const newPlan = {
      id: uuidV4(),
      mode: PlanModes.Write,
      title: newPlanTitle,
      order: newPlanOrder,
      createdDate: Date.now(),
      updatedDate: null,
      sections: planTemplate.sections.map((item) => ({
        ...item,
        id: uuidV4(),
        isExpanded: true,
      })),
    };

    this.tradingPlans = [...this.tradingPlans, newPlan];
    this.onChangePlanTab(newPlan.order);

    this.savePlans$.next(this.tradingPlans);
  }

  public onUpdatePlan(plan: ITradingPlan): void {
    this.tradingPlans = this.tradingPlans.map((item) => {
      return item.id === plan.id
        ? { ...plan, updatedDate: Date.now() }
        : item;
    });

    this.savePlans$.next(this.tradingPlans);
  }

  public onPrintPlan(plan: ITradingPlan) {
    const previousPrintOptions = this.observableService.printOptions$.getValue();
    this.observableService.tradingHubPlanToPrint.next(plan);
    this.observableService.printOptions$.next([PrintOptions.TradingPlan]);
    this.observableService.isNeedPrint$.next(true);

    window.onafterprint = () => this.observableService.printOptions$.next(previousPrintOptions);
  }

  public onDeletePlan(plan: ITradingPlan): void {
    // Do not remove empty line, it's important for UI
    const message = `Do you really want to delete '${plan.title}' plan?

      This action can’t be undone.`;

    from(
      this.dialogsService.customConfirm({
        header: 'Delete plan',
        confirmationText: message,
        subText: '',
        icon: 'info',
        okText: 'Yes',
        cancelText: 'No',
        showCancel: true,
      })
    ).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.tradingPlans = this.tradingPlans.filter((item) => item.id !== plan.id);
        this.savePlans$.next(this.tradingPlans);

        // update selectedTabIndex ?

        this.changeDetectorRef.markForCheck();
      }
    });
  }

  // special case for plan tabs - it should take into account changing order by drag&drop
  public trackByFn(index: number, item?: ITradingPlan): string | number {
    return item ? `${item.id}_${item.order}` : index;
  }

  private showMaxAllowedPlansInfoMessage(): void {
    const message = `Maximum allowed trading plans is ${this.maxPlansNumber}.`;

    from(
      this.dialogsService.customConfirm({
        showCancel: false,
        header: 'Adding a new plan',
        confirmationText: message,
        subText: '',
        icon: 'info',
      })
    ).subscribe();
  }

  private getIsMobileOS(platform: Platform): boolean {
    const isAndroid = platform.ANDROID;
    const isIOS = platform.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    return isAndroid || isIOS;
  }
}
