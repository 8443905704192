import { Component, HostBinding, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
// import { MatCarouselModule } from '@thouet/material-carousel'; TODO: should be removed
import { MatButtonModule } from '@angular/material/button';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

import { QuoteOfDayDisplayImagesCount, QuoteOfDayImagesCount } from '@const';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';

@Component({
  selector: 'app-quote-of-day',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule,
    // MatCarouselModule, // TODO: should be removed
    MatButtonModule],
  templateUrl: './quote-of-day.component.html',
  styleUrls: ['./quote-of-day.component.scss']
})
export class QuoteOfDayComponent implements OnInit {
  protected isExpanded = false;
  protected areQuotesFresh = true;
  protected quoteOfDayImagesSrc: string[] = [];

  private _isNoImages = false;

  constructor(private observableService: ObservableService, private userDataService: UserDataService) { }

  @HostBinding('class.isNoImages') get isNoImages() { return this._isNoImages; }

  ngOnInit(): void {
    const currentDayOfYear = moment(new Date()).dayOfYear();
    const todayQuoteIndex = currentDayOfYear % QuoteOfDayImagesCount;

    this.observableService.tradingHubQuotesLastExpandedDate.asObservable()
      .pipe(take(1))
      .subscribe((lastDateTimestamp) => {
        if (lastDateTimestamp && moment(lastDateTimestamp).dayOfYear() === currentDayOfYear) {
          this.areQuotesFresh = false;
        }
      });

    this.observableService.tradingHubQuotesExpandedState
      .pipe(take(1))
      .subscribe((isExpanded) => {
        this.handleExpandedStateChange(!!isExpanded);
      });

    this.quoteOfDayImagesSrc = new Array(QuoteOfDayDisplayImagesCount).fill('').map((q, i) => {
      const currentQuoteIndex = todayQuoteIndex + i;
      if (currentQuoteIndex > QuoteOfDayImagesCount) {
        return `./assets/img/quote-of-day/${currentQuoteIndex - QuoteOfDayImagesCount}.jpg`;
      }

      return `./assets/img/quote-of-day/${currentQuoteIndex}.jpg`;
    });

    this._isNoImages = this.quoteOfDayImagesSrc.length === 0;
  }

  protected async handleExpandedStateChange(state: boolean): Promise<void> {
    if (this.isExpanded !== state) {
      this.isExpanded = state;
      await this.userDataService.set('tradingHubQuotesExpandedState', state);
    }

    if (state) {
      await this.updateLastExpandedDate(new Date().getTime());
    }
  }

  private async updateLastExpandedDate(timestamp: number): Promise<void> {
    await this.userDataService.set('tradingHubQuotesLastExpandedDate', timestamp);
  }
}
