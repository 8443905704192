<div *ngIf="isOption" class="from-group-row">
  <div class="from-group expiration">
    <label class="label-title">Expiration:</label>
    <mat-form-field class="option-details-input">
      <input matInput class="option-details-disabled-input" type="text" value="{{orderInput.option.expirationDate}}" disabled="true">
    </mat-form-field>
  </div>
  <div class="from-group strike">
    <label class="label-title">Strike:</label>
    <mat-form-field class="option-details-input">
      <input matInput class="option-details-disabled-input" type="text" value="{{orderInput.option.strike}}" disabled="true">
    </mat-form-field>
  </div>
  <div class="from-group type">
    <label class="label-title">Type:</label>
    <mat-form-field class="option-details-input">
      <input matInput class="option-details-disabled-input" type="text" value="{{orderInput.option.type.toUpperCase()}}" disabled="true">
    </mat-form-field>
  </div>
</div>
