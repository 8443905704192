import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Response } from '@core1/interface';
import { LocalStorageService } from './local-storage.service';
import { RestRequestorService } from './rest-requestor.service';
import { StorageKeys } from '@const';

export interface SecurityDetails {
  as_of_date: string;
  industry_name: string;
  long_description: string;
  sector_name: string;
  security_id: number;
  symbol: string;
  market_cap: number;
  pe_ratio: number;
  earnings_share: number;
  dividend_yield: number;
  percent_insiders: number;
  percent_institutions: number;
}

@Injectable({
  providedIn: 'root'
})
export class SecurityDataDetailsService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private restRequesterService: RestRequestorService,
  ) { }

  get = async (securityId: number): Promise<SecurityDetails> => {
    const storageKey = `${StorageKeys.SecurityDataDetails}_${securityId}`;
    let securityDataDetails = this.localStorageService.getFromMemory(storageKey);

    if (!securityDataDetails) {
      const { result } = await this.restRequesterService.makeRequest(
        storageKey,
        () => firstValueFrom(this.http.get<Response>(`/v2/securityDetails?security_id=${securityId}`)),
      );

      if (result && result.length) {
        securityDataDetails = result[0];
        this.localStorageService.setToMemory(storageKey, securityDataDetails);
      }
    }

    return securityDataDetails;
  }
}
