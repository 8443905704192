<div class="wheel-tbl scroll-data visible-scroll" id="iwheel-calc">
  <table cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="tableDrop($event)"
    [class]="{'collapse-first-column': collapseApply}">
    <thead>
      <tr class="droArea">
        <th>
          <div class="save-print">
            <button type="button" mat-button class="save-btn" (click)="onUpdate()">SAVE</button>
            <button type="button" mat-button class="collapse-btn" (click)="collapseColumn()">
              <img class="light-theme-img" src="assets/img/newImg/Collapse.svg">
              <img class="black-theme-img" src="assets/img/newImg/Collapse-white.svg">
            </button>
          </div>
        </th>
        <ng-container *ngFor="let header of stocks; let i = index">
          <th [ngClass]="{'disabled-icon': put_headers.length === 1}" class="put-content-column" cdkDrag cdkDragLockAxis="x"
            cdkDragBoundary="droArea">
            <div class="input-btn-header">
              <img src="assets/img/newImg/Move.svg" cdkDragHandle class="w-print">
              <input class="align-center" name="{{header.headerId}}" [value]="'Stock ' + header.headerId" readonly
                spellcheck="true">

              <div class="w-delete" [matTooltip]="'Delete column'"
                [matTooltipShowDelay]="0"
                [matTooltipHideDelay]="0"
                [matTooltipPosition]="'below'"
                (click)="onDelete(header.headerId)"></div>

              <div class="w-add" [ngClass]="{'visibility-w-add-icon': header.headerId == put_headers.length}" >
                <img src="assets/img/newImg/Add.svg" (click)="onAdd(i)">
              </div>
            </div>
          </th>
          <th [id]="'live-data-column'+i" class="app-live-data-column" *ngIf="showLiveDataColumn(header.headerId)">
            <app-live-data-header (closeEvent)="unsubscribeAllLiveData()"></app-live-data-header>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr class="selling-puts marker-row" [class]="showPutRow ? 'showBelow' : ''">
        <th class="heading-text p-0" (click)="selling('puts')">
          <div class="th-div bg-maroon">#1: Selling Puts</div>
        </th>
        <ng-container *ngFor="let header of put_headers;let i = index">
          <td class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">

            <div class="td-ticker">
              <div class="pre-d-input i-100">
                <input [id]="'put_stock_symbol'+i" tabindex="1{{i}}" name="{{header.name}}" placeholder="Enter Ticker"
                  (keydown.arrowDown)="$event.stopPropagation();"
                  (keydown.arrowUp)="$event.stopPropagation();"
                  #input (click)="$event.target.select()" (focus)="addBorderColor($event); onFocusIn(i, 'put')"
                  (focusout)="removeBorderColor($event); onFocusOut(i, header.header_id, 'put')" (keypress)="isInput($event)"
                  (keydown.enter)="focusNext(i, $event)" (input)="input.value=$event.target.value.toUpperCase()"
                  class="align-center" [(ngModel)]="header.value" spellcheck="false" autocomplete="off" type="text"
                  maxLength="5"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
              </div>
              <div [matTooltip]="getLiveDataTooltip(header.header_id, true)"
                [matTooltipHideDelay]="0"
                [matTooltipPosition]="'below'"
                [matTooltipShowDelay]="0">
                <button mat-icon-button class="square-small-btn refresh-btn not-allowed-when-disabled"
                   [disabled]="!canSubscribeToLiveData(header.header_id, true) || isLiveDataSubscriptionInProgress(true)"
                  (click)="subscribeLiveData(header.header_id, true)" [appFeatureAvailability]="features.WheelCalculatorLiveData">
                  <mat-icon class="watch-icon refresh-icon" [svgIcon]="'refresh-update-icon'"></mat-icon>
                </button>
              </div>
              <div class="w-clear"
                [matTooltip]="'Clear section data'"
                [matTooltipShowDelay]="0"
                [matTooltipHideDelay]="0"
                [matTooltipPosition]="'below'"
                (click)="onClearPutOrCall(header.header_id, true)">
              </div>

              <ng-container *appHideForUnavailableFeature="features.TradingLog">
                <mat-menu #stocksMenu="matMenu" yPosition="below" xPosition="before" class="stocks-dropdown-menu">
                  <app-add-trading-log-trade
                    *ngIf="openedAddTradeMenuFor && openedAddTradeMenuFor.index === i && openedAddTradeMenuFor.type === 'put'"
                    [availableTransactionTypes]="[tlTransactionTypes.SellPut, tlTransactionTypes.BuyPut]"
                    [specificValuesForAvailableTransactionTypes]="openedAddTradeMenuFor.specificValuesForAvailableTransactionTypes"
                    [transactionDetails]="openedAddTradeMenuFor.data"
                    (closeMenu)="trigger.closeMenu()"
                    (contentResized)="onMenuResized(trigger)"
                    class="add-trading-log-trade-component"
                  ></app-add-trading-log-trade>
                </mat-menu>
                <button
                  mat-icon-button
                  class="stocks-menu-btn-trigger"
                  #trigger="matMenuTrigger"
                  [ngClass]="{ 'active': trigger.menuOpen }"
                  [matMenuTriggerFor]="stocksMenu"
                  (click)="$event.stopPropagation(); onOpenAddTradeMenu(i, 'put')"
                  (menuClosed)="openedAddTradeMenuFor = null"
                  aria-label="Stock's menu dropdown"
                >
                  <mat-icon
                    [svgIcon]="'dashes-icon'"
                    class="dashes-menu-icon"
                    [ngClass]="{ 'active': trigger.menuOpen }"
                  ></mat-icon>
                </button>
              </ng-container>
            </div>
          </td>
          <td *ngIf="showLiveDataColumn(header.header_id)" rowspan="26"  class="real-time-data-cell" [ngClass]="{'collapsed-call-row':!showCallRow}">
            <app-window
              [roiLowerBound]="roiLowerBound"
              [roiUpperBound]="roiUpperBound"
              [putSubscription]="putSubscription"
              [putOptionsChain]="putOptionsChain"
              [callSubscription]="callSubscription"
              [callOptionsChain]="callOptionsChain"
              [collapsedCallRow]="!showCallRow"
              [collapsedPutRow]="!showPutRow"
              [tradierFlags]='tradierFlags'
              [isPutTimeout]="isPutSubscriptionTimeoutTriggered"
              [isCallTimeout]="isCallSubscriptionTimeoutTriggered"
              [isPutUpdateOverlay]="isPutUpdateOverlay"
              [isCallUpdateOverlay]="isCallUpdateOverlay"
              (putClickEvent)="liveDataPutClick($event)"
              (callClickEvent)="liveDataCallClick($event)"
              (putRetryEvent)="liveDataPutRetry()"
              (callRetryEvent)="liveDataCallRetry()"
              >
            </app-window>
          </td>
        </ng-container>
      </tr>
      <tr class="s-puts marker-row">
        <th>
          <div class="th-div">Stock Price</div>
        </th>
        <td *ngFor="let stock of put_stock_price; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign">$</div>
            <input [id]="'put_stock_price'+i" type="text" tabindex="1{{i}}" [inputMask]="stockPriceInputMask" min="0"
              placeholder="0.00" maxlength="10" autocomplete="off" (focus)="addBorderColor($event); onFocusIn(i, 'put')"
              (focusout)="removeBorderColor($event)" (click)="$event.target.select()"
              (keyup)="onKeyUp($event, stock.header_id,'put_stock_price', i)" (keydown.enter)="focusNext(i, $event)"
              (keydown)="onKeyDown($event, 'put_stock_price', i, '0.01', '999999')" name="{{stock.name}}"
              [(ngModel)]="stock.value">
          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row">
        <th>
          <div class="th-div">Expiration</div>
        </th>
        <td *ngFor="let stock of put_dte; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <mat-form-field class="date-select-field" appearance="outline" subscriptSizing="dynamic">
            <mat-select class="date-mdc-select" id="{{'tabIndexs1'+i}}" autocomplete="off"
              (click)="$event.stopPropagation()" (focus)="addBorderColor($event); onFocusIn(i, 'put')"
              (keydown.arrowDown)="$event.stopPropagation();"
              (keydown.arrowUp)="$event.stopPropagation();"
              (focusout)="removeBorderColor($event)"
              (selectionChange)="onKeyUp($event, stock.header_id, 'put_dte', i); focusNext(i, $event)"
              name="{{stock.name}}" (selected)="onKeyUp($event, stock.header_id, 'put_dte', i)"
              [(ngModel)]="stock.expiration" panelClass="date-mdc-select-options">
              <mat-option [value]="date" *ngFor="let date of dates">{{date | date: 'MMM d, y'}}</mat-option>
            </mat-select>
          </mat-form-field>

          <p class="s-content underline" [ngStyle]="{'visibility': !checkIfEmpty('expiration', true, i)? '': 'hidden'}">
            DTE: {{stock.dte }} Day{{stock.dte<=1 ? "" : "s" }}
          </p>
          <div class="min-premium">
            <div class="table-container scroll-style" *ngIf="put_min_premiums[i]?.length">
              <table>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>DTE, days</th>
                    <th>Min Premium (30% ROI)</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor=" let min_premium of put_min_premiums[i] ">
                    <tr>
                      <td>
                        {{ min_premium.day }}
                      </td>
                      <td>
                        {{ min_premium.dte }}
                      </td>
                      <td>
                        {{ min_premium.min_roi }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <app-no-data-message *ngIf="!put_min_premiums[i]?.length"
                                 [message]="'Please specify Strike Price and Expiration and try again.'"
                                 [additionalMessage]="' '"
            ></app-no-data-message>
          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row">
        <th>
          <div class="th-div">Strike Price</div>
        </th>
        <td *ngFor="let stock of put_strike_price; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign">$</div>
            <input [id]="'put_strike_price'+i" type="text" tabindex="1{{i}}" autocomplete="off"
              (keyup)="onKeyUp($event, stock.header_id,'put_strike_price', i)" [inputMask]="strikePriceInputMask"
              placeholder="0.00" min="0" maxlength="10" step="0.5" name="{{stock.name}}"
              (click)="$event.target.select()" (keydown.enter)="focusNext(i, $event)"
              (keydown)="onKeyDown($event, 'put_strike_price', i, '0.5', '999999')"
              (focus)="addBorderColor($event); onFocusIn(i, 'put')" (focusout)="removeBorderColor($event)"
              [(ngModel)]="stock.value">
          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row">
        <th>
          <div class="th-div">Premium</div>
        </th>
        <td *ngFor="let stock of put_premium; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign">$</div>
            <input [id]="'put_premium'+i" type="text" tabindex="1{{i}}" step="0.01" min="0" autocomplete="off"
              [inputMask]="currencyInputMask" placeholder="0.00" (click)="$event.target.select()"
              (keyup)="onKeyUp($event, stock.header_id,'put_premium', i)"
              (keydown)="onKeyDown($event, 'put_premium', i, '0.01', '999')"
              (focus)="addBorderColor($event); onFocusIn(i, 'put')" (focusout)="removeBorderColor($event)"
              (keydown.enter)="focusNext(i, $event)" maxlength="6" name="{{stock.name}}" [(ngModel)]="stock.value">
          </div>
          <div class="bottom-min-max"
            [ngStyle]="{'visibility': !checkIfEmpty('strike', true, i) && !checkIfEmpty('expiration', true, i) && (isFinit(stock.value))  ? '': 'hidden'}">
            <p class="red underline" [ngStyle]="{'visibility': (stock.min !== null && isFinit(stock.min))  ? '': 'hidden'}">
              Min:
              <span>${{stock.min |number:'1.2-2'}}</span>
            </p>
            <p class="green" [ngStyle]="{'visibility': (stock.max !== null && isFinit(stock.max))  ? '': 'hidden'}">

              Max:
              <span>${{stock.max | number:'1.2-2'}}</span>
            </p>

            <div class="min-premium">
              <div class="table-container scroll-style" *ngIf="put_min_premiums[i]?.length">
                <table>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>DTE, days</th>
                      <th>Min Premium (30% ROI)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor=" let min_premium of put_min_premiums[i] ">
                      <tr>
                        <td>
                          {{ min_premium.day }}
                        </td>
                        <td>
                          {{ min_premium.dte }}
                        </td>
                        <td>
                          {{ min_premium.min_roi }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <app-no-data-message *ngIf="!put_min_premiums[i]?.length"
                                   [message]="'Please specify Strike Price and Expiration and try again.'"
                                   [additionalMessage]="' '"
              ></app-no-data-message>
            </div>

          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row">
        <th>
          <div class="th-div"># Option to Trade</div>
        </th>
        <td *ngFor="let stock of put_option_to_trade; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="left-i-right-c"
            [class]="(showTradeLink() && !isValueZero(i, ['strike'], true)) ? 'with-trade-btn' : ''">
            <div class="whiteDiv w-100"
              [ngStyle]="{'visibility' : !checkIfEmpty('strike', true, i) && (stock.value !== null) && (isFinit(stock.value)) ? '' : 'hidden'}">
              <div class="row w-100 round-div text-left">
                <div class="round-div-item">
                  {{isFinit(stock.value) ? stock.value : "" }}
                </div>
                <div *ngIf="showTradeLink() && !isValueZero(i, ['strike'], true)"
                  [class]="(tradeLinkTradingPanelDisable(i, ['expiration', 'ticker' , 'premium'], true)) ? 'disabled' : ''"
                  class="trade-btn"
                  [matTooltip]="tradeLinkTooltip"
                  [matTooltipDisabled]="!tradeLinkTooltipEnabled"
                  [matTooltipPosition]="'below'"
                  [matTooltipHideDelay]="0"
                  >
                  <a *ngIf="tradeLinkTradingPanelDisable(i, ['expiration', 'ticker' , 'premium'], true); else elseTradeLinkP">
                    Place Order
                  </a>
                  <ng-template #elseTradeLinkP>
                    <a (click)="tradeLinkTradingPanel(i, stock, 'P')">Place Order</a>
                  </ng-template>
                </div>
                <p
                  [ngStyle]="{'visibility': !checkIfEmpty('strike', true, i)&& (stock.need_to_buy !== null) && (isFinit(stock.need_to_buy)) ? '' :'hidden' }">
                  Position value <br *ngIf="!showTradeLink()"> ${{stock.need_to_buy | number:'1.0-0'}}
                </p>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row pb-0">
        <th>
          <div class="th-div">Drop In</div>
        </th>
        <td *ngFor="let stock of drop; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="wh-100 all-center drop-in" [ngStyle]="{'visibility': (!checkIfEmpty('stock', true, i) && !checkIfEmpty('strike', true, i))
            && (stock.value !== null) && (isFinit(stock.value)) ? '' : 'hidden'}" class="{{getBGLightColor(i, true)}}">
            <div class="down-arrow"></div>
            {{stock.value |number:'1.0-0'}}%
          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row ptb-0">
        <th>
          <div class="th-div">Premium Per Day. <span>Total</span></div>
        </th>
        <td *ngFor="let stock of put_premium_per_day;let i =index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="whiteDiv premium-per-day" class="{{getBGLightColor(i, true)}}" [ngStyle]="{'visibility' : (!checkIfEmpty('premium', true, i)) && (!checkIfEmpty('strike', true, i)) &&
            ((stock.premium_per_day !== null) && isFinit(stock.premium_per_day)) ? '' : 'hidden'}">
            <div
              [ngStyle]="{'visibility':(!checkIfEmpty('premium', true, i)) && (!checkIfEmpty('strike', true, i)) &&
            (!checkIfEmpty('expiration', true, i) && (stock.premium_per_day !== null) && isFinit(stock.premium_per_day)) ? '' : 'hidden'}">
              ${{stock.premium_per_day | number:'1.0-0' }}
            </div>
            <div class="blur-rr"
              [ngStyle]="{'visibility' : (!checkIfEmpty('premium', true, i)) && (!checkIfEmpty('strike', true, i)) && (isFinit(stock.premium_collected)) ? '' : 'hidden' }">
              ${{stock.premium_collected | number:'1.0-0' }}
            </div>
          </div>
        </td>
      </tr>
      <tr class="s-puts marker-row pt-0">
        <th>
          <div class="th-div">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                 [matTooltip]="roiLegend"
                 [matTooltipPosition]="'below'"
                 [matTooltipHideDelay]="0"
                 class="help">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>Premium Annualized
          </div>
        </th>
        <td *ngFor="let stock of put_premium_annualarized;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="bg-yellow" *ngIf="stock.value > roiUpperBound"
            [ngStyle]="{'visibility' :!checkIfEmpty('expiration', true, i) && !checkIfEmpty('premium', true, i) && (stock.value !== null) && (isFinit(stock.value)) ? '' : 'hidden'}">
            {{stock.value |number:'1.0-0'}}%
          </div>
          <div class="bg-red" *ngIf="(stock.value < roiLowerBound) || (!stock.value)"
            [ngStyle]="{'visibility' :!checkIfEmpty('expiration', true, i) && !checkIfEmpty('premium', true, i) && (stock.value !== null) && (isFinit(stock.value))? '' : 'hidden'}">
            {{stock.value |number:'1.0-0'}}%
          </div>
          <div class="bg-green" *ngIf="stock.value >= roiLowerBound && stock.value <= roiUpperBound"
            [ngStyle]="{'visibility' :!checkIfEmpty('expiration', true, i) && !checkIfEmpty('premium', true, i) && (stock.value !== null) && (isFinit(stock.value))? '' : 'hidden'}">
            {{stock.value |number:'1.0-0'}}%
          </div>
        </td>
      </tr>

      <tr class="s-puts marker-row">
        <th>
          <div class="th-div">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
              [matTooltip]="putProfit90Tooltip"
              [matTooltipPosition]="'below'"
              [matTooltipHideDelay]="0"
              class="help">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
            90% Profit.
            <ul>
              <li>Target</li>
              <li>Value</li>
            </ul>
          </div>
        </th>
        <td *ngFor="let stock of put_profit;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="whiteDiv value-center-dotted">
            <div>
              <div *ngIf="showTradeLink() && profitLinkShow(i, true) && !isValueZero(i, ['strike'], true); else noLinkValues">
                <div class="wheel-profit-link"
                  [class]="(tradeLinkTradingPanelDisable(i, ['expiration', 'ticker'], true)) ? 'disabled' : ''">
                  <a *ngIf="tradeLinkTradingPanelDisable(i, ['expiration', 'ticker'], true); else stockTakingLinkP"
                    [matTooltip]="tradeLinkTooltip"
                    [matTooltipDisabled]="!tradeLinkTooltipEnabled"
                    [matTooltipPosition]="'below'">
                    ${{stock.taking | number:'1.2-2'}}
                  </a>
                  <ng-template #stockTakingLinkP>
                    <a (click)="profitLinkTradingPanel(i, 'P', stock.taking)">
                      ${{stock.taking | number:'1.2-2'}}
                    </a>
                  </ng-template>
                </div>
              </div>
            </div>
            <ng-template #noLinkValues>
              <div
                [ngStyle]="{'visibility' : !checkIfEmpty('premium', true, i) && (stock.taking !== null) && (isFinit(stock.taking)) ? '' : 'hidden'}">
                ${{stock.taking | number:'1.2-2'}}
              </div>
            </ng-template>
            <div
              [ngStyle]="{'visibility' : !checkIfEmpty('strike', true, i) && !checkIfEmpty('premium', true, i) && (stock.value !== null) && (isFinit(stock.value))? '' : 'hidden'}">
              ${{stock.value | number:'1.2-2'}}
            </div>
          </div>
        </td>
      </tr>

      <tr class="selling-calls marker-row" [class]="showCallRow ? 'showBelow' : ''">
        <th class="heading-text p-0" (click)="selling('calls')">
          <div class="th-div bg-maroon">#2: Selling Calls</div>
        </th>

        <td *ngFor="let header of call_symbol;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="td-ticker">
            <div class="pre-d-input i-100">
              <input [id]="'call_stock_symbol'+i" name="{{header.name}}" tabindex="1{{i}}" spellcheck="true"
                autocomplete="off" placeholder="Enter Ticker" #input (click)="$event.target.select()"
                (keydown.arrowDown)="$event.stopPropagation();"
                (keydown.arrowUp)="$event.stopPropagation();"
                (input)="input.value=$event.target.value.toUpperCase()" class="align-center"
                (keydown.enter)="focusNext(i, $event)" (focus)="addBorderColor($event);
                    onFocusIn(i, 'call')" (keypress)="isInput($event)" (focusout)="removeBorderColor($event);
                    onFocusOut(i, header.header_id, 'call')" [(ngModel)]="header.value" spellcheck="false" type="text" maxlength="5"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
            </div>
            <div [matTooltip]="getLiveDataTooltip(header.header_id, false)"
                [matTooltipHideDelay]="0"
                [matTooltipShowDelay]="0"
                [matTooltipPosition]="'below'"
                >
              <button mat-icon-button class="square-small-btn refresh-btn not-allowed-when-disabled"
                [disabled]="!canSubscribeToLiveData(header.header_id, false) || isLiveDataSubscriptionInProgress(false)"
                (click)="subscribeLiveData(header.header_id, false)" [appFeatureAvailability]="features.WheelCalculatorLiveData">
                <mat-icon class="watch-icon refresh-icon" [svgIcon]="'refresh-update-icon'"></mat-icon>
              </button>
            </div>
            <div class="w-clear"
              [matTooltip]="'Clear section data'"
              [matTooltipShowDelay]="0"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'below'"
              (click)="onClearPutOrCall(header.header_id, false)">
            </div>

            <ng-container *appHideForUnavailableFeature="features.TradingLog">
              <mat-menu #stocksMenu="matMenu" yPosition="below" xPosition="before" class="stocks-dropdown-menu">
                <app-add-trading-log-trade
                  *ngIf="openedAddTradeMenuFor && openedAddTradeMenuFor.index === i && openedAddTradeMenuFor.type === 'call'"
                  [availableTransactionTypes]="[tlTransactionTypes.SellCall, tlTransactionTypes.BuyCall]"
                  [specificValuesForAvailableTransactionTypes]="openedAddTradeMenuFor.specificValuesForAvailableTransactionTypes"
                  [transactionDetails]="openedAddTradeMenuFor.data"
                  (closeMenu)="trigger.closeMenu()"
                  (contentResized)="onMenuResized(trigger); trigger.updatePosition()"
                  class="add-trading-log-trade-component"
                ></app-add-trading-log-trade>
              </mat-menu>
              <button
                mat-icon-button
                class="stocks-menu-btn-trigger"
                #trigger="matMenuTrigger"
                [ngClass]="{ 'active': trigger.menuOpen }"
                [matMenuTriggerFor]="stocksMenu"
                (click)="$event.stopPropagation(); onOpenAddTradeMenu(i, 'call')"
                (menuClosed)="openedAddTradeMenuFor = null"
                aria-label="Stock's menu dropdown"
              >
                <mat-icon
                  [svgIcon]="'dashes-icon'"
                  class="dashes-menu-icon"
                  [ngClass]="{ 'active': trigger.menuOpen }"
                ></mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div">Stock Purchase Price</div>
        </th>
        <td *ngFor="let stock of call_stock_price;let i = index;" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign">$</div>
            <input [id]="'call_stock_price'+i" type="text" tabindex="1{{i}}" autocomplete="off"
              [inputMask]="stockPurchasePriceInputMask" min="0" step="0.01" maxlength="10"
              (click)="$event.target.select()" placeholder="0.00"
              (keyup)="onKeyUp($event, stock.header_id,'call_stock_price', i)"
              (keydown)="onKeyDown($event, 'call_stock_price', i, '0.01', '999999')"
              (keydown.enter)="focusNext(i, $event)" name="{{stock.name}}"
              (focus)="addBorderColor($event); onFocusIn(i, 'call')"
              (focusout)="onAddDecimals('call_stock_price', i);removeBorderColor($event)" [(ngModel)]="stock.value">
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div">Expiration</div>
        </th>
        <td *ngFor="let stock of call_expiration; let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <mat-form-field class="date-select-field" appearance="outline" subscriptSizing="dynamic">
            <mat-select class="date-mdc-select" id="{{'tabIndexs2'+i}}" autocomplete="off"
              (click)="$event.stopPropagation()"
              (keydown.arrowDown)="$event.stopPropagation();"
              (keydown.arrowUp)="$event.stopPropagation();"
              (selectionChange)="onKeyUp($event, stock.header_id, 'call_dte', i); focusNext(i, $event)"
              name="{{stock.name}}" [(ngModel)]="stock.expiration"
              (selected)="onKeyUp($event, stock.header_id, 'call_dte', i)" panelClass="date-mdc-select-options"
              (focus)="addBorderColor($event); onFocusIn(i, 'call')" (focusout)="removeBorderColor($event)">
              <mat-option [value]="date" *ngFor="let date of dates">{{date | date: 'MMM d, y'}}</mat-option>
            </mat-select>
          </mat-form-field>

          <p class="s-content underline" [ngStyle]="{'visibility' : !checkIfEmpty('expiration', false, i) ? '' : 'hidden' }">DTE:
            {{stock.dte }} Day{{stock.dte <=1 ? "" : "s" }} </p>
          <div class="min-premium">
            <div class="table-container scroll-style" *ngIf="call_min_premiums[i]?.length">
              <table>
                <thead>
                <tr>
                  <th>Day</th>
                  <th>DTE, days</th>
                  <th>Min Premium (30% ROI)</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor=" let min_premium of call_min_premiums[i] ">
                  <tr>
                    <td>
                      {{ min_premium.day }}
                    </td>
                    <td>
                      {{ min_premium.dte }}
                    </td>
                    <td>
                      {{ min_premium.min_roi }}
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <app-no-data-message *ngIf="!call_min_premiums[i]?.length"
                                 [message]="'Please specify Stock Purchase Price and Expiration and try again.'"
                                 [additionalMessage]="' '"
            ></app-no-data-message>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div">Shares</div>
        </th>
        <td *ngFor="let stock of call_no_of_shares;let i = index;" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign v-hidden">$</div>
            <input [id]="'call_no_of_shares'+i" type="text" tabindex="1{{i}}" [inputMask]="sharesInputMask" min="0"
              maxlength="7" placeholder="0" autocomplete="off" name="{{stock.name}}" (click)="$event.target.select()"
              (focus)="addBorderColor($event); onFocusIn(i, 'call')" (focusout)="removeBorderColor($event)"
              (keyup)="onKeyUp($event, stock.header_id,'call_no_of_shares', i)" (keydown.enter)="focusNext(i, $event)"
              (keydown)="onKeyDown($event, 'call_no_of_shares', i, '100', '999999')" [(ngModel)]="stock.value">
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div">Strike Price</div>
        </th>
        <td *ngFor="let stock of call_strike_price;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign">$</div>
            <input [id]="'call_strike_price'+i" type="text" tabindex="1{{i}}" [inputMask]="strikePriceInputMask" min="0"
              maxlength="10" step="0.5" placeholder="0.00" autocomplete="off" name="{{stock.name}}"
              (click)="$event.target.select()" (focus)="addBorderColor($event); onFocusIn(i, 'call')"
              (focusout)="removeBorderColor($event)" (keyup)="onKeyUp($event, stock.header_id,'call_strike_price', i)"
              (keydown.enter)="focusNext(i, $event)"
              (keydown)="onKeyDown($event, 'call_strike_price', i, '0.5', '999999')" [(ngModel)]="stock.value">
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div">Premium</div>
        </th>
        <td *ngFor="let stock of call_option_premium; let i =index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="pre-d-input">
            <div class="pre-sign">$</div>
            <input [id]="'call_option_premium'+i" type="text" tabindex="1{{i}}" min="0" autocomplete="off" step="0.01"
              [inputMask]="currencyInputMask" maxlength="6" (click)="$event.target.select()"
              (keyup)="onKeyUp($event, stock.header_id,'call_option_premium', i)" (keydown.enter)="focusNext(i, $event)"
              (keydown)="onKeyDown($event, 'call_option_premium', i, '0.01', '999')" placeholder="0.00"
              (focus)="addBorderColor($event); onFocusIn(i, 'call')" (focusout)="removeBorderColor($event)"
              [(ngModel)]="stock.value">
          </div>
          <div class="bottom-min-max">
            <p class="red underline"
              [ngStyle]="{'visibility' : !checkIfEmpty('stock', false, i) && !checkIfEmpty('expiration', false, i) && (stock.min !== null) && (isFinit(stock.min)) ? '' : 'hidden'}">
              Min: ${{stock.min | number:'1.2-2'}}</p>
            <div class="min-premium">
              <div class="table-container scroll-style" *ngIf="call_min_premiums[i]?.length">
                <table>
                  <thead>
                  <tr>
                    <th>Day</th>
                    <th>DTE, days</th>
                    <th>Min Premium (30% ROI)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor=" let min_premium of call_min_premiums[i] ">
                    <tr>
                      <td>
                        {{ min_premium.day }}
                      </td>
                      <td>
                        {{ min_premium.dte }}
                      </td>
                      <td>
                        {{ min_premium.min_roi }}
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
              <app-no-data-message *ngIf="!call_min_premiums[i]?.length"
                                   [message]="'Please specify Stock Purchase Price and Expiration and try again.'"
                                   [additionalMessage]="' '"
              ></app-no-data-message>
            </div>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div"># Option to Trade</div>
        </th>
        <td *ngFor="let stock of call_option_to_trade;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">

          <div class="left-i-right-c"
            [class]="(showTradeLink() && !isValueZero(i, ['share'], false)) ? 'with-trade-btn' : ''">
            <div class="whiteDiv w-100">
              <div class="row w-100 round-div text-left">
                <div class="round-div-item"
                  [ngStyle]="{'visibility' :!checkIfEmpty('share', false, i) && (isFinit(stock.value)) ? '' : 'hidden' }">
                  {{stock.value }}
                </div>
                <div *ngIf="showTradeLink() && !isValueZero(i, ['share'], false)"
                  [class]="(tradeLinkTradingPanelDisable(i, ['expiration', 'ticker', 'premium', 'strike'], false)) ? 'disabled' : ''"
                  class="trade-btn"
                  [matTooltip]="tradeLinkTooltip"
                  [matTooltipDisabled]="!tradeLinkTooltipEnabled"
                  [matTooltipPosition]="'below'"
                  [matTooltipHideDelay]="0"
                  >
                  <a *ngIf="tradeLinkTradingPanelDisable(i, ['expiration', 'ticker', 'premium', 'strike'], false); else elseTradeLinkC">
                    Place Order
                  </a>
                  <ng-template #elseTradeLinkC>
                    <a (click)="tradeLinkTradingPanel(i, stock, 'C')">Place Order</a>
                  </ng-template>
              </div>
                <p [ngStyle]="{'visibility' :
                    (!checkIfEmpty('share', false, i))
                    && (!checkIfEmpty('stock', false, i))
                    && ((stock.position_value !== null)
                    && (isFinit(stock.position_value)))  ? '' : 'hidden'}">
                  Position value <br *ngIf="!showTradeLink()"> ${{stock.position_value | number:'1.0-0'}}</p>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row pb-0">
        <th>
          <div class="th-div">Rise In</div>
        </th>
        <td *ngFor="let stock of rise;let i =index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="wh-100 all-center drop-in" class="{{getBGLightColor(i, false)}}"
            [ngStyle]="{'visibility': ((stock.value !== null) && isFinit(stock.value) && (!checkIfEmpty('strike', false, i))) ? '' : 'hidden'}">
            <ng-template [ngIf]="(stock.value !== null) && (stock.value !== 'null') && (!isNaNValue(stock.value))">
              <div class="up-arrow"></div>
            </ng-template>{{stock.value | number:'1.0-0'}}<ng-template
              [ngIf]="(stock.value !== null) && (stock.value !== 'null')&& (!isNaNValue(stock.value))">%
            </ng-template>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row ptb-0">
        <th>
          <div class="th-div">Premium Per Day. <span>Total</span></div>
        </th>
        <td *ngFor="let stock of call_premium_per_day;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="whiteDiv premium-per-day" class="{{getBGLightColor(i, false)}}" [ngStyle]="{'visibility' : (!checkIfEmpty('premium', false, i)) && (!checkIfEmpty('share', false, i)) &&
            ((stock.premium_per_day !== null) && (isFinit(stock.premium_per_day))) ? '' : 'hidden'}">
            <div
              [ngStyle]="{'visibility' : (!checkIfEmpty('premium', false, i)) && (!checkIfEmpty('strike', false, i)) &&
            (!checkIfEmpty('expiration', false, i) && (stock.premium_per_day !== null) && (isFinit(stock.premium_per_day))) ? '' : 'hidden'}">
              ${{stock.premium_per_day | number:'1.0-0' }}
            </div>
            <div class="blur-rr"
              [ngStyle]="{'visibility' :  (!checkIfEmpty('premium', false, i)) && (!checkIfEmpty('share', false, i) && (stock.premium_collected !== null) && (isFinit(stock.premium_collected))) ? '' : 'hidden'}">
              ${{ (stock.premium_collected| number:'1.0-0') }}
            </div>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row pt-0">
        <th>
          <div class="th-div">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                 [matTooltip]="roiLegendCall"
                 [matTooltipPosition]="'below'"
                 [matTooltipHideDelay]="0"
                 class="help">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
            Premium Annualized
          </div>
        </th>
        <td *ngFor="let stock of call_premium_annularized;let i = index;" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="bg-yellow" *ngIf="stock.value > roiUpperBound"
            [ngStyle]="{'visibility' : (!checkIfEmpty('expiration', false, i)) && !checkIfEmpty('premium', false, i) && (stock.value !== null) && (isFinit(stock.value)) ? '' : 'hidden'}">
            {{stock.value |number:'1.0-0'}}%
          </div>
          <div class=" bg-red" *ngIf="(stock.value < roiLowerBound) || (!stock.value)"
            [ngStyle]="{'visibility' : (!checkIfEmpty('expiration', false, i)) && !checkIfEmpty('premium', false, i) && (stock.value !== null) && (isFinit(stock.value))? '' : 'hidden'}">
            {{stock.value |number:'1.0-0'}}%
          </div>
          <div class="bg-green" *ngIf="stock.value >= roiLowerBound && stock.value <= roiUpperBound"
            [ngStyle]="{'visibility' : (!checkIfEmpty('expiration', false, i)) && !checkIfEmpty('premium', false, i) && (stock.value !== null) && (isFinit(stock.value))? '' : 'hidden'}">
            {{stock.value |number:'1.0-0'}}%
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row">
        <th>
          <div class="th-div">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
              [matTooltip]="callProfit90Tooltip"
              [matTooltipPosition]="'below'"
              [matTooltipHideDelay]="0" class="help">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
            90% Profit.
            <ul>
              <li>Target</li>
              <li>Value</li>
            </ul>
          </div>
        </th>
        <td *ngFor="let stock of call_profit;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="whiteDiv value-center-dotted">
            <div>
              <div *ngIf="showTradeLink() && profitLinkShow(i, false) && !isValueZero(i, ['share'], false); else noLinkValuesCall">
                <div class="wheel-profit-link"
                  [class]="(tradeLinkTradingPanelDisable(i, ['expiration', 'ticker', 'premium', 'strike'], false)) ? 'disabled' : ''">
                  <a *ngIf="tradeLinkTradingPanelDisable(i, ['expiration', 'ticker', 'premium', 'strike'], false); else stockTakingLinkC"
                    [matTooltip]="tradeLinkTooltip" [matTooltipDisabled]="!tradeLinkTooltipEnabled" [matTooltipPosition]="'below'">
                    ${{stock.taking | number:'1.2-2'}}
                  </a>
                  <ng-template #stockTakingLinkC>
                    <a (click)="profitLinkTradingPanel(i, 'C', stock.taking)">
                      ${{stock.taking | number:'1.2-2'}}
                    </a>
                  </ng-template>
                </div>
              </div>
            </div>
            <ng-template #noLinkValuesCall>
              <div [ngStyle]="{'visibility' : !checkIfEmpty('premium', false, i) ? '' : 'hidden'}">
                ${{stock.taking | number:'1.2-2'}}
              </div>
            </ng-template>
            <div
              [ngStyle]="{'visibility' : (!checkIfEmpty('strike', false, i)) && (!checkIfEmpty('premium', false, i)&& (stock.value !== null) && (isFinit(stock.value))) ? '' : 'hidden' }">
              ${{stock.value | number:'1.2-2'}}
            </div>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row pb-0">
        <th>
          <div class="th-div dark-rm"><strong>Profit Potential</strong></div>
        </th>
        <td class="t-middle" *ngFor="let stock of call_expiration;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="t-line"></div>
        </td>
      </tr>
      <tr class="s-calls marker-row pb-0 pt-0">
        <th>
          <div class="th-div">Total Gain</div>
        </th>
        <td *ngFor="let stock of call_total_gain;let i = index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="total-tbl"
            [ngStyle]="{'visibility' : (!checkIfEmpty('strike', false, i)) && (!checkIfEmpty('premium', false, i)) && (!checkIfEmpty('share', false, i)&& (stock.value !== null) && (isFinit(stock.value))) ? '' : 'hidden'}">
            ${{stock.value | number: '1.2-2'}}
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row pb-0 pt-0">
        <th>
          <div class="th-div">
            Stock Gain.
            <ul>
              <li>Taking</li>
              <li>Value</li>
            </ul>
          </div>
        </th>
        <td *ngFor="let stock of call_stock_gain;let i =index" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="whiteDiv value-center-dotted">
            <div class="blur-rm"
              [ngStyle]="{'visibility': (!checkIfEmpty('strike', false, i)) && (!checkIfEmpty('stock', false, i)) && (!checkIfEmpty('share', false, i)&& (stock.excercised !== null) && (isFinit(stock.excercised))) ? '' : 'hidden'}">
              ${{stock.excercised | number:'1.2-2'}}</div>
            <div class="blur-rm"
              [ngStyle]="{'visibility': (!checkIfEmpty('strike', false, i)) && (!checkIfEmpty('stock', false, i)&& (stock.holding !== null) && (isFinit(stock.holding))) ? '' : 'hidden' }">
              {{stock.holding |
              number: '1.0-0'}}%</div>
          </div>
        </td>
      </tr>
      <tr class="s-calls marker-row pt-0">
        <th>
          <div class="th-div">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
              [matTooltip]="callMaxProfit90Tooltip"
              [matTooltipPosition]="'right'"
              [matTooltipHideDelay]="0" class="help">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
            90% Max Profit.
            <ul>
              <li>Target</li>
              <li>Value</li>
            </ul>
          </div>
        </th>
        <td *ngFor="let stock of call_max_profit;let i = index;" class="stock" [ngClass]="{'active-col': (indexOfActiveCol - 1) == i}">
          <div class="whiteDiv value-center-dotted">
            <div class="blur-rm"
              [ngStyle]="{'visibility': (!checkIfEmpty('strike', false, i)) && (!checkIfEmpty('premium', false, i)) && (!checkIfEmpty('stock', false, i)&& (stock.taking !== null) && (isFinit(stock.taking))) ? '' : 'hidden'}">
              ${{stock.taking
              | number: '1.2-2' }}</div>
            <div class="blur-rm" [ngStyle]="{'visibility': (!checkIfEmpty('strike', false, i)) &&
            (!checkIfEmpty('premium', false, i)) && (!checkIfEmpty('stock', false, i)) &&
            (!checkIfEmpty('share', false, i)&& (stock.value !== null) && (isFinit(stock.value))) ? '' : 'hidden' }">
              ${{stock.value
              | number: '1.2-2'}}</div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
