import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Renderer2,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { UserSettings } from '@const';
import {
  NewQuickLink,
  PxoQuickLink,
  QuickLink,
  QuickLinksRecord,
  QuickLinksRecordSettings,
  QuickLinksRecordType,
} from '@mod/link-organiser/link-organiser.model';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';

@Component({
  selector: 'app-links-organiser',
  templateUrl: './links-organiser.component.html',
  styleUrls: ['./links-organiser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinksOrganiserComponent implements OnInit {
  @Input() readonly mode: 'WEB' | 'MOBILE' = 'MOBILE';
  @Input() set isVisible(value) {
    if (!value) {
      this.isShowEditPane = false;
      this.selectedLink = null;
    }
  }
  protected isShowEditPane = false;
  protected selectedLink: QuickLink | null = null;
  protected linkRecordType = QuickLinksRecordType;
  protected quickLinksWrapper: WritableSignal<QuickLinksRecord[]> = signal([]);
  protected isShowHeaderAddLinkButton = computed(() => {
    const userLinkRecord = this.quickLinksWrapper().find(
      (record) => record.settings.recordType === this.linkRecordType.UserLink,
    );

    return !!userLinkRecord?.items.length;
  });

  private quickLinksRecordSettings = this.observableService.quickLinksSettings.getValue();
  private userQuickLinks = this.observableService.quickLinks.getValue();
  private pxoQuickLinks = this.observableService.pxoQuickLinks.getValue();

  constructor(
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.updateQuickLinksWrapperObject();
  }

  protected async onAddLinkClick(): Promise<void> {
    const userLinkRecord = this.quickLinksWrapper().find(
      (record) => record.settings.recordType === this.linkRecordType.UserLink,
    );

    if (!userLinkRecord) {
      return;
    }

    this.isShowEditPane = true;
    this.selectedLink = null;

    await this.onExpandedChange(userLinkRecord, true);
    setTimeout(() => {
      this.scrollTo('#add-container');
    }, 100);
  }

  protected selectLink(link): void {
    this.selectedLink = link;
    this.isShowEditPane = true;
  }

  protected cancelLinkEdit(): void {
    this.selectedLink = null;
    this.isShowEditPane = false;
  }

  protected async onExpandedChange(record: QuickLinksRecord, isExpanded: boolean): Promise<void> {
    this.quickLinksRecordSettings[record.settings.recordType].isExpanded = isExpanded;
    this.updateQuickLinksWrapperObject();

    await this.userDataService.set(UserSettings.QuickLinksSettings, this.quickLinksRecordSettings);
  }

  protected openLink(url: string): void {
    const externalUrl = url.match(/^(https?:\/\/|\/)/) ? url : `//${url}`;

    window.open(externalUrl, '_blank');
  }

  protected retrieveIndentString(linkTitle: string): string {
    const indentMatch = linkTitle.match(/^\s+/);

    return indentMatch ? indentMatch[0] : '';
  }

  protected async addLink(newLink: NewQuickLink): Promise<void> {
    this.userQuickLinks.push({ id: this.userQuickLinks.length + 1, ...newLink });
    this.isShowEditPane = !this.isShowEditPane;
    this.updateQuickLinksWrapperObject();
    await this.userDataService.set(UserSettings.QuickLinks, this.userQuickLinks);
  }

  protected async updateLink(updatedLink: QuickLink, index: number): Promise<void> {
    this.userQuickLinks.splice(index, 1, updatedLink);
    this.selectedLink = null;
    this.isShowEditPane = !this.isShowEditPane;
    this.updateQuickLinksWrapperObject();
    await this.userDataService.set(UserSettings.QuickLinks, this.userQuickLinks);
  }

  protected async removeLink(index: number): Promise<void> {
    this.userQuickLinks.splice(index, 1);
    this.updateQuickLinksWrapperObject();
    await this.userDataService.set(UserSettings.QuickLinks, this.userQuickLinks);
  }

  protected async drop(event: CdkDragDrop<string[]>): Promise<void> {
    moveItemInArray(this.userQuickLinks, event.previousIndex, event.currentIndex);
    this.updateQuickLinksWrapperObject();
    await this.userDataService.set(UserSettings.QuickLinks, this.userQuickLinks);
  }

  protected async dropCommonList(event: CdkDragDrop<string[]>): Promise<void> {
    const quickLinksArray = Object.values(this.quickLinksRecordSettings).sort((a, b) => a.order - b.order);

    const [movedItem] = quickLinksArray.splice(event.previousIndex, 1);
    quickLinksArray.splice(event.currentIndex, 0, movedItem);

    this.quickLinksRecordSettings = quickLinksArray.reduce(
      (acc, item, index) => {
        item.order = index;
        acc[item.recordType] = item;
        return acc;
      },
      {} as Record<QuickLinksRecordType, QuickLinksRecordSettings>,
    );

    this.updateQuickLinksWrapperObject();

    await this.userDataService.set(UserSettings.QuickLinksSettings, this.quickLinksRecordSettings);
  }

  private updateQuickLinksWrapperObject(): void {
    this.quickLinksWrapper.set(
      [
        {
          title: 'my links:',
          items: [...this.userQuickLinks],
          settings: this.quickLinksRecordSettings.userLink,
        },
        {
          title: 'pxo links:',
          items: ((): PxoQuickLink[] => {
            const userAccessLevel = this.observableService.accessLevel.getValue();

            return this.pxoQuickLinks.filter((link) =>
              link.editions && link.editions.length ? link.editions.includes(userAccessLevel) : true,
            );
          })(),
          settings: this.quickLinksRecordSettings.pxoLink,
        },
      ].sort((a, b) => a.settings.order - b.settings.order),
    );
  }

  private scrollTo(selector: string): void {
    const element = this.document.querySelector(selector);
    if (element) {
      this.renderer.selectRootElement(selector, true).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }
}
