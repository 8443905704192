<div class="updating-overlay" [class.is-active]="showUpdatingOverlay">
    <div class="overlay-content">
        <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>

        <h3 class="overlay-title">{{ title }}</h3>
        <p class="overlay-text">{{ text }}</p>
    </div>
</div>
