import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ObservableService } from '@s/observable.service';
import { LocalStorageService } from '@s/local-storage.service';
import { LowestCloseLineIndicatorOptions, Themes } from '@const';
import { DeepWriteable, RawStudyMetaInfoId, StudyPlotInformation } from '@chart/charting_library';

@Injectable()
export class LowestCloseLine {
  constructor(
    private getLowestOption: Function,
    private observableService: ObservableService,
    private localStorageService: LocalStorageService
  ) {
  }

  ploatLowestCloseLine(PineJS, getHistoricalDataStorageKey) {
    const getLowestOption = this.getLowestOption;
    const observableService = this.observableService;
    const localStorageService = this.localStorageService;

    return {
      name: 'Lowest Close Line indicator',
      metainfo: {
        _metainfoVersion: 40,
        id: 'LowestCloseLine@tv-basicstudies-1' as RawStudyMetaInfoId,
        scriptIdPart: '',
        name: 'Lowest Close Line indicator',
        format: {
          type: 'price' as DeepWriteable<'price'>,
          precision: 2
        },

        // This description will be displayed in the Indicators window
        // It is also used as a "name" argument when calling the createStudy method
        description: 'Lowest Close Line indicator',

        // This description will be displayed on the chart
        shortDescription: 'Lowest Close Line',

        is_hidden_study: true,
        is_price_study: true,
        isCustomIndicator: true,

        plots: [{
          id: 'plot_0',
          type: 'line'
        }, {
          id: 'plot_1',
          type: 'colorer',
          target: 'plot_0',
          palette: 'paletteId1',
        }] as DeepWriteable<Readonly<StudyPlotInformation>>[],
        palettes: {
          paletteId1: {
            colors: {
              0: {
                name: 'Light theme color',
              },
              1: {
                name: 'Dark theme color',
              },
            },
          },
        },
        defaults: {
          palettes: {
            paletteId1: {
              colors: {
                0: {
                  color: '#0000FF',
                  width: 2,
                  style: 0,
                },
                1: {
                  color: '#FFD27B',
                  width: 2,
                  style: 0,
                },
              },
            },
          },
          styles: {
            plot_0: {
              linestyle: 0,
              visible: true,
              linewidth: 2,
              plottype: 2,
              // Show price line?
              trackPrice: false,
              // Plot transparency, in percent.
              transparency: 40
            }
          },

          // Precision of the study's output values
          // (quantity of digits after the decimal separator).
          precision: 2,

          inputs: {}
        },
        styles: {
          plot_0: {
            // Output name will be displayed in the Style window
            title: '-- output name --',
            histogramBase: 0,
          }
        },
        inputs: [],
      },

      constructor: function() {
        this.init = function(context, inputCallback) {
          const historicalData = localStorageService.getFromMemory(getHistoricalDataStorageKey());

          const theme: Themes = observableService.theme.getValue();
          this.colorIndex = theme === Themes.Light ? 0 : 1;

          this.mostRecentLowestClose = null;
          this.processedData = {};
          for (const item of historicalData) {
            this.processedData[moment.utc(item.date).format('YYYY-MM-DD')] = item;
            this.mostRecentLowestClose = item.close_40_min;
          }
        };

        this.main = function(context, inputCallback) {
          const barDate = moment(PineJS.Std.time(context)).tz('America/New_York').format('YYYY-MM-DD');
          const item = this.processedData[barDate];
          const selectedOption = getLowestOption();

          if (item) {
            if (selectedOption === LowestCloseLineIndicatorOptions.LowestLine) {
              return [item.close_40_min, this.colorIndex];
            } else if (selectedOption === LowestCloseLineIndicatorOptions.None) {
              return [null, null];
            }
          }

          return [];
        };
      }
    };
  }
}
