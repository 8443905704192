import { ApexOptions, ApexXAxis, ApexYAxis } from 'ng-apexcharts/lib/model/apex-types';

import { formatNumberValue } from '@u/utils';

export const defaultXAxis: ApexXAxis = {
  categories: [],
  labels: {
    style: {
      fontSize: '10px',
      colors: 'var(--main-font-color)',
    }
  }
};

export const defaultYAxis: ApexYAxis = {
  tickAmount: 8,
  forceNiceScale: true,
  decimalsInFloat: 2,
  opposite: true,
  labels: {
    formatter: (value) => {
      return formatNumberValue(value);
    },
    style: {
      fontSize: '10px',
      colors: 'var(--main-font-color)',
    },
  }
};

export const defaultChartOptions: ApexOptions = {
  chart: {
    id: 'income-statement-chart',
    type: 'bar',
    animations: {
      enabled: false,
      easing: 'easeinout',
      speed: 0,
      animateGradually: {
        enabled: false,
        delay: 0,
      },
      dynamicAnimation: {
        enabled: false,
        speed: 0,
      }
    },
    zoom: {
      enabled: false,
      type: 'x',
    },
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
      },
    },
  },
  annotations: {
    yaxis: [
      {
        y: 0,
        offsetY: 0.5,
        strokeDashArray: 0,
        borderWidth: 1,
        fillColor: '#AFAFC0',
        borderColor: '#AFAFC0',
      }
    ],
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'center',
    fontSize: '14px',
    itemMargin: {
      vertical: 10,
      horizontal: 16,
    },
    labels: {
      colors: 'var(--main-font-color)',
    },
    markers: {
      width: 32,
      height: 16,
      offsetX: -5,
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
    lineCap: 'round',
  },
  plotOptions: {
    bar: {
      columnWidth: '60%',
      borderRadius: 2,
      dataLabels: {
        maxItems: 5,
        position: 'top',
      },
    }
  },
  dataLabels: {
    enabled: false,
    offsetY: -20,
  },
  grid: {
    show: true,
    borderColor: 'var(--table-border-color)',
    strokeDashArray: 0,
    position: 'back',
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
};
