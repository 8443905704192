export const ExchangeCodes = {
  Nyse: 'N',
  Nasdaq: 'Q',
  Amex: 'A',
  Arca: 'P',
  Bats: 'Z',
  Neo: 'NEO',
  Tsx: 'V',
  TorontoExchange: 'TO',
  CanadianSecuritiesExchange: 'CN',
  Cryptocurrencies: 'CC',
};
