<div class="wheel-chart-legend">
  <button mat-button [disableRipple]="true" [matMenuTriggerFor]="legend" (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()">
    <div class="chart-indicators-legend" [ngClass]="{'opened': isMenuOpened}">
      <div class="legend-item legend-item-marker blue-marker">
        <span class="legend-item-title">SS</span>
      </div>
      <div class="legend-item legend-item-marker orange-marker">
        <span class="legend-item-title">WS</span>
      </div>
      <div class="legend-item legend-item-marker purple-marker">
        <span class="legend-item-title">DT</span>
      </div>
      <mat-icon [svgIcon]="'arrow-down_icon'" class="middle-icon"></mat-icon>
    </div>
  </button>

  <mat-menu #legend="matMenu" xPosition="before" backdropClass="wheel-chart-legend-menu">
    <div class="legend-menu-item">
      <p class="legend-menu-title">
        Lowest/highest close line:
      </p>
    </div>
    <div class="legend-menu-item">
      <div class="legend-item-marker blue-marker"></div>
      <span class="legend-item-marker-title">Strong support</span>
    </div>
    <div class="legend-menu-item">
      <div class="legend-item-marker orange-marker"></div>
      <span class="legend-item-marker-title">Weak support</span>
    </div>
    <div class="legend-menu-item">
      <div class="legend-item-marker purple-marker"></div>
      <span class="legend-item-marker-title">Don't trade</span>
    </div>
  </mat-menu>
</div>
