import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { asyncScheduler, fromEvent, of, scheduled, Subject } from 'rxjs';
import { catchError, debounceTime, filter, mergeAll, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ObservableService as ObservableServiceV1 } from '@core1/directives/observable.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { CookieService } from '@s/cookie.service';
import { LocalStorageService } from '@s/local-storage.service';
import { RestRequestorService } from '@s/rest-requestor.service';
import { UserDataService } from '@s/user-data.service';
import { ServerNotificationsService } from '@s/server-notifications/server-notifications.service';
import {
  cookiesToBeClearedOnLogoutList,
  localStorageKeysTobeClearedOnRefresh,
  localStorageKeysToKeepOnLogout,
  loginUrl,
  UserAccessLevels,
  userAgreementUrl,
} from '@const';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private _observableServiceV1: ObservableServiceV1,
    private _restRequestorService: RestRequestorService,
    private _http: HttpClient,
    private _userDataService: UserDataService,
    private _cookieService: CookieService,
    private _localStorageService: LocalStorageService,
    private observableServiceV2: ObservableServiceV2,
    private serverNotificationsService: ServerNotificationsService,
  ) { }

  private _activityTimerLastRequestDate = moment();
  private _stopActivityTimer$: Subject<boolean> = new Subject();
  private _isPingRequestInProgress = false;
  public isTokenValid(token: string = null): boolean {
    const tokenExpirationDateStr = token
      ? token
      : this._userDataService.getAuthTokenExpirationDate();

    return (
      !!tokenExpirationDateStr &&
      moment(tokenExpirationDateStr)
        .add(-1 * environment.TokenExpirationCheckSafeTimePeriodSec, 'seconds')
        .isAfter(moment())
    );
  }

  public logout = (sessionExpired: boolean = false, concurrentLogin: boolean = false): void => {

    this.clearStorageOnLogout();
    this.clearCookies();
    this.stopWatchUserActivity();

    this.serverNotificationsService.unsubscribeFormNotifications();

    this.observableServiceV2.onUserLogout.next();
    this._observableServiceV1.userSetting.next({});
    this._restRequestorService.clear();

    if (window.location.href.indexOf(loginUrl) === -1
      && window.location.href.indexOf(userAgreementUrl) === -1
    ) {
      let logoutReason = '';

      if (sessionExpired) {
        logoutReason = '?s=session-expired';
      } else if (concurrentLogin) {
        logoutReason = '?s=concurrent-login';
      }

      const url = window.location.port !== '80' && window.location.port !== '443'
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/#/login${logoutReason}`
        : `${window.location.protocol}//${window.location.hostname}/#/login${logoutReason}`;

      window.location.href = url;
      window.location.reload();
    }
  }
  /**
   * Start to watch User activity to avoid session expiration when User is active on site but with no API calls.
   * Events are used: `mousemove` (with `debounce` 100 ms), `touchstart` and `keydown`. All events merged with `debounce` 100 ms.
   *
   * If activity is present send GET request to '/v2/ping' to update the token.
   *
   * If there is no token stored in `UserDataService.getAuthToken()` the activity ignored.
   *
   * If ping request is already in progress, the activity ignored.
   *
   * Every time the method called the events subscription is reset.
   *
   * @param delaySec  The request will be send not often then once in this amount of seconds.
   */
  public watchUserActivity(delaySec: number): void {
    this._activityTimerLastRequestDate = moment();
    this.stopWatchUserActivity();
    this._stopActivityTimer$ = new Subject();
    this._isPingRequestInProgress = false;
    scheduled(
      [fromEvent(document, 'mousemove').pipe(debounceTime(100)), fromEvent(document, 'touchstart'), fromEvent(document, 'keydown')],
      asyncScheduler
    )
      .pipe(
        mergeAll(),
        debounceTime(100),
        filter((_) => !!this._userDataService.getAuthToken()),
        filter((_) => this._activityTimerLastRequestDate.isBefore(moment().add(-1 * delaySec, 'seconds'))),
        filter((_) => !this._isPingRequestInProgress),
        tap((_) => (this._isPingRequestInProgress = true)),
        switchMap((_) => this._http.post<boolean>('/v2/ping', null).pipe(catchError(() => of(false)))),
        tap((_) => (this._isPingRequestInProgress = false)),
        tap((_) => (this._activityTimerLastRequestDate = moment())),
        takeUntil(this._stopActivityTimer$)
      )
      .subscribe();
  }
  /**
   * Stop to watch User activity
   */
  public stopWatchUserActivity(): void {
    this._stopActivityTimer$.next(true);
    this._stopActivityTimer$.complete();
  }

  public clearStorageOnRefresh(): void {
    localStorageKeysTobeClearedOnRefresh.forEach((key) => this._localStorageService.removeByFullKey(key));
  }

  private clearStorageOnLogout(): void {
    const keys = this._localStorageService.getFullKeys();

    keys
      .filter((key) => !localStorageKeysToKeepOnLogout.find((keepKey) => keepKey === key))
      .forEach((key) => this._localStorageService.removeByFullKey(key));
  }

  private clearCookies() {
    cookiesToBeClearedOnLogoutList.forEach((cookieName) => this._cookieService.remove(cookieName));
  }
}
