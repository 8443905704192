<div id="income-statement-container" class="income-statement container" appResizeDirective
  (resize)="onContainerResize($event)">
  <mat-expansion-panel [expanded]="isExpanded" (expandedChange)="onExpandChange($event)" class="income-statement-panel"
    hideToggle>
    <mat-expansion-panel-header class="income-statement-header" [collapsedHeight]="'28px'" [expandedHeight]="'28px'">
      <mat-panel-title class="income-statement-title">
        <button mat-icon-button class="toggle-button close-open-item-btn component-btn secondary-bg">
          <div class="arrow-wrapper">
            <div class="close-open-arrow"></div>
          </div>
        </button>
        Income Statement
      </mat-panel-title>
      @if (showRockyDataWindow) {
      <div class="rocky-icon-wrapper">
        <app-ask-rocky-chat-button (click)="openTradingHubModal($event)"></app-ask-rocky-chat-button>
      </div>
      }
    </mat-expansion-panel-header>

    <div class="income-statement-tabs-container">
      <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="onChangeTab($event)"
        class="income-statement-group" mat-stretch-tabs animationDuration="0">
        <mat-tab label="Quarterly" #quarterlyTab>
          <div class="chart-wrapper">
            <ng-container *ngIf="!isLoading; else: circularProgress">
              <div *ngIf="quarterlyChartDetails.dataSource.length > 0; else: noData">
                <div class="chart-content" [ngStyle]="{ 'width.px': chartWidth, 'height.px': chartHeight }">
                  <apx-chart *ngIf="quarterlyTab.isActive" [series]="quarterlyChartDetails.series"
                    [chart]="chartOptions.chart"
                    [annotations]="quarterlyChartDetails.isShowZeroLine ? chartOptions.annotations : undefined"
                    [legend]="chartOptions.legend" [stroke]="chartOptions.stroke"
                    [plotOptions]="chartOptions.plotOptions" [tooltip]="chartOptions.tooltip"
                    tooltipClass="tooltip-hidden" [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid"
                    [xaxis]="quarterlyChartDetails.xAxis" [yaxis]="quarterlyChartDetails.yAxis"
                    [title]="chartOptions.title"></apx-chart>
                </div>
                <ng-container *ngTemplateOutlet="chartLegend"></ng-container>
              </div>
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab label="Annual" #annualTab>
          <div class="chart-wrapper">
            <ng-container *ngIf="!isLoading; else: circularProgress">
              <div *ngIf="annualChartDetails.dataSource.length > 0; else: noData">
                <div class="chart-content" [ngStyle]="{ 'width.px': chartWidth, 'height.px': chartHeight }">
                  <apx-chart *ngIf="annualTab.isActive" [series]="annualChartDetails.series"
                    [chart]="chartOptions.chart"
                    [annotations]="annualChartDetails.isShowZeroLine ? chartOptions.annotations : undefined"
                    [legend]="chartOptions.legend" [stroke]="chartOptions.stroke"
                    [plotOptions]="chartOptions.plotOptions" [tooltip]="chartOptions.tooltip"
                    [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [xaxis]="annualChartDetails.xAxis"
                    [yaxis]="annualChartDetails.yAxis" [title]="chartOptions.title"></apx-chart>
                </div>
                <ng-container *ngTemplateOutlet="chartLegend"></ng-container>
              </div>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-expansion-panel>

  <ng-template #chartLegend>
    <div class="income-statement-legend-wrapper">
      <div class="income-statement-legend">
        <span class="income-statement-legend-item revenue">Revenue</span>
        <span class="income-statement-legend-item income">Net Income</span>
      </div>
    </div>
  </ng-template>

  <ng-template #noData>
    <div class="no-data-wrapper">
      <div class="no-data-label">No data found.</div>
      <div class="no-data-image"></div>
    </div>
  </ng-template>

  <ng-template #circularProgress>
    <div class="spinner-container">
      <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</div>