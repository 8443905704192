<!-- <app-simple-modal-header [titleText]="'Add to existing group'"></app-simple-modal-header> -->
<h2 mat-dialog-title>
  Add to the existing group
  <button mat-button mat-dialog-close (click)="close()" class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon">
    </mat-icon>
  </button>
</h2>
<div mat-dialog-content>
  <p class="header-text" *ngIf="!dataSource.length">Select group to which all trades will be added</p>

  <div class="header-table" *ngIf="dataSource.length">
    <p class="header-text">You are about to add trade below to group:</p>
    <div class="table-container table-main-style table-styles scroll-style">
      <table mat-table [dataSource]="dataSource" class="extra-low-table classic-hover-styles">
        <!-- Trade Column -->
        <ng-container matColumnDef="trade">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Trade</th>
          <td class="text-left" mat-cell *matCellDef="let element">{{element.trade}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Symbol</th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <span class="symbol">{{element.symbol}}</span>
          </td>
        </ng-container>

        <!-- Side Column -->
        <ng-container matColumnDef="side">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Side</th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <span [class.buy]="element.side === 'Buy'" [class.sell]="element.side === 'Sell'">{{element.side}}</span>
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Price</th>
          <td class="text-left" mat-cell *matCellDef="let element">
            <span class="price">{{element.priceDisplay}}</span>
          </td>
        </ng-container>

        <!-- QTY Column -->
        <ng-container matColumnDef="qty">
          <th class="text-center" mat-header-cell *matHeaderCellDef>Qty</th>
          <td class="text-center" mat-cell *matCellDef="let element">{{element.qtyDisplay}}</td>
        </ng-container>

        <!-- Commission Column -->
        <ng-container matColumnDef="commission">
          <th class="text-left" mat-header-cell *matHeaderCellDef>Commission</th>
          <td class="text-left" mat-cell *matCellDef="let element">{{element.commissionDisplay}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <app-groups-list-filter-form></app-groups-list-filter-form>

  <div class="trading-groups-wrapper">
    <div class="trading-groups scroll-style">
      <div
        class="top-indicator"
        inViewport
        [inViewportOptions]="{ threshold: [0], partial: true }"
        (inViewportAction)="onChangeVisibility($event, 'top')"
      ></div>

      <ng-container *ngIf="!(activeGroups$ | async)?.length && !(archivedGroups$ | async)?.length">
        <div class="no-data-wrapper">
          <div class="no-data-image"></div>
          <div class="no-data-title">We couldn’t find any results</div>
          <div class="no-data-text">Change your search terms and try again</div>
        </div>
      </ng-container>

      <ng-container *ngIf="(activeGroups$ | async)?.length">
        <span class="label" [class.shadow-top]="!isGroupsListTopVisible" (click)="isActiveOpened = !isActiveOpened">
          <mat-icon [svgIcon]="'arrow-right'" class="group-list" [class.opened]="isActiveOpened"></mat-icon>
          Active
          <span class="count" *ngIf="(activeGroups$ | async)">{{(activeGroups$ | async)?.length}}</span>
        </span>
        <div class="trading-group" [class.hide]="!isActiveOpened">
          <div class="trading-group-content">
            <mat-radio-group
              aria-labelledby="trading-group-label"
              class="trading-group-buttons"
              [(ngModel)]="selectedGroupId">
              <mat-radio-button *ngFor="let group of activeGroups$ | async" [value]="group.group_id">
                <app-group-info-panel [data]="group" [attr.id]="'group-'+group.group_id"></app-group-info-panel>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="(archivedGroups$ | async)?.length">
        <span class="label" (click)="isArchivedOpened = !isArchivedOpened">
          <mat-icon [svgIcon]="'arrow-right'" class="group-list" [class.opened]="isArchivedOpened"></mat-icon>
          Archived
          <span class="count" *ngIf="(archivedGroups$ | async)">{{(archivedGroups$ | async)?.length}}</span>
        </span>
        <div class="trading-group" [class.hide]="!isArchivedOpened">
          <div class="trading-group-content">
            <mat-radio-group
              aria-labelledby="trading-group-label"
              class="trading-group-buttons"
              [(ngModel)]="selectedGroupId">
              <mat-radio-button *ngFor="let group of archivedGroups$ | async" [value]="group.group_id">
                <app-group-info-panel [data]="group" [attr.id]="'group-'+group.group_id"></app-group-info-panel>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>

      <div
        class="bottom-indicator"
        inViewport
        [inViewportOptions]="{ threshold: [0], partial: true }"
        (inViewportAction)="onChangeVisibility($event, 'bottom')"
      ></div>

    </div>
    <div *ngIf="!isGroupsListBottomVisible" class="shadow-bottom"></div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="main-bg button action-btn" (click)="select()">Apply</button>
</div>
