import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../core/interface/response';
import { LocalStorageService } from './local-storage.service';
import { RestRequestorService } from './rest-requestor.service';
import { Signals, StorageKeys, TradePositions } from '../constants';
import * as moment from 'moment';

export interface TradingStrategySignal extends Object {
  has_enough_data: 0 | 1;
  signal: Signals;
  signal_entry_price: number;
  signal_profit_loss_multiplier: number;
  signal_shares_count: number;
  signal_stop_loss: number;
  signal_stop_loss_amount: number;
  signal_target_profit: number;
  signal_target_profit_amount: number;
  created_date: Date;
  date: Date;
  entry_price: number;
  exit_price: number | null;
  id: number;
  position: TradePositions;
  position_comment: string;
  processed_id: number;
  profit_loss_multiplier: number;
  shares_count: number;
  signal_comment: string | null;
  stop_loss: number;
  stop_loss_amount: number;
  strategy_id: number;
  symbol: string;
  target_profit: number;
  target_profit_amount: number;
}

export interface IPastPerformanceData {
  date: Date,
  avg_trade_days: number;
  avg_trade_days_long: number;
  avg_trade_days_short: number;
  created_date: Date;
  id: number;
  longest_trade_days: number;
  longest_trade_days_long: number;
  longest_trade_days_short: number;
  loss_avg: number;
  loss_avg_long: number;
  loss_avg_short: number;
  losses: number;
  losses_long: number;
  losses_short: number;
  profit_factor: number;
  profit_factor_long: number;
  profit_factor_short: number;
  reward_ratio: number;
  risk_ratio: number;
  roi: number;
  roi_long: number;
  roi_short: number;
  signal_id: number;
  strategy_id: number;
  strategy_name: string;
  total_profit: number;
  total_profit_long: number;
  total_profit_short: number;
  win_avg: number;
  win_avg_long: number;
  win_avg_short: number;
  win_percent: number;
  win_percent_long: number;
  win_percent_short: number;
  wins: number;
  wins_long: number;
  wins_short: number;
}

export type IPastPerformance = IPastPerformanceData & TradingStrategySignal;

interface IPastPerformanceService {
  get: (security_id: number, strategy_id: number) => Promise<IPastPerformance>;
}
@Injectable({
  providedIn: 'root'
})
export class PastPerformanceService implements IPastPerformanceService {

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private restRequestorService: RestRequestorService) { }

  get = async (security_id, strategy_id) => {
    const strategyResults = await this.compareStrategies(security_id);

    return strategyResults.find(x => x.strategy_id === strategy_id);
  }

  compareStrategies: (security_id: number) => Promise<IPastPerformance[]> = async (security_id) => {
    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.PastPerformanceStrategyComparison}_${security_id}_${date}`;
    let comparison = this.localStorageService.getFromMemory(storageKey);

    if(!comparison) {
      const { result } = await this.restRequestorService.makeRequest(storageKey, 
        () => this.http.get<Response>(`/v2/pastPerformance/compare?security_id=${security_id}&date=${date}`).toPromise());

      comparison = result;
      this.localStorageService.setToMemory(storageKey, comparison);
    }

    return comparison;
  };
}
