import { HistoricalDataService } from '@s/historical-data.service';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, WritableSignal, signal } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';

import { DialogsService } from '@s/common';
import { EarningsService } from '@s/earnings.service';
import { ExpectedMoveService } from '@s/expected-move.service';
import { StreamingService } from '@s/streaming.service';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { SymbolsService } from '@s/symbols.service';
import {
  ExchangeCountriesCodes,
  MomentDateTimeFormats,
  round,
  TradingHubTabs,
  UserSettings
} from '@const';
import { convertToEasternTime } from '@u/utils';
import { IExpectedMove, IExpectedMoveSymbol } from '@mod/data/expected-move.model';
import { TradingHubMode } from '@c/trading-hub/trading-hub.model';

interface IStockSubscription {
  subscriptionId: string;
  symbol: {
    symbol: string;
    country_code: ExchangeCountriesCodes;
  };
}

const HiddenClassName = 'hidden';

@Component({
  selector: 'app-expected-move',
  templateUrl: './expected-move.component.html',
  styleUrls: ['./expected-move.component.scss'],
})
export class ExpectedMoveComponent implements OnInit, AfterViewInit, OnDestroy {
  private expectedMoveContainerDefaultHeight = 34;

  @Input() showExpectedMoveOneKey: string = UserSettings.ShowExpectedMoveOneOnWheel;
  @Input() showExpectedMoveTwoKey: string = UserSettings.ShowExpectedMoveTwoOnWheel;
  @Input() valuesInPercentExpectedMoveKey: string = UserSettings.ValuesInPercentExpectedMove;

  @Input() modelView: 'short' | 'single' | 'default' = 'default';

  @Input() set securityId(value: number | null) {
    this.initialize(value);
  }

  @Input() checkNextEarning = false;
  @Input() showWheelChartSettings = false;
  @Input() liveDataIntervalSeconds: number | null = null;

  // temp solution, to hide rocky icon for expected-move in earnings-analysis, remove this input when it's not needed
  @Input() hideRockyIcon = false; // show by default

  @ViewChild('expected') expected: ElementRef<HTMLDivElement>;

  protected lastTradePrice: number | null = null;
  protected showRockyDataWindow = this.observableService.showRockyDataWindow.value;
  protected showRockyIconAlways$ = this.observableService.showRockyAlways;

  private subscriptions = new Subscription();
  private resizeObserver: any;
  private currentSymbol: IExpectedMoveSymbol = null;
  private subscribedStock: IStockSubscription = null;
  private nextRunTimestamp: number = null;

  public wrapperClass: string = HiddenClassName;
  public expectedMoves: IExpectedMove[];
  public showExpectedMoveOne: boolean;
  public showExpectedMoveTwo: boolean;
  public valuesInPercentExpectedMove: boolean;

  constructor(
    private earningsService: EarningsService,
    private expectedMoveService: ExpectedMoveService,
    private streamingService: StreamingService,
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private dialogsService: DialogsService,
    private historicalDataService: HistoricalDataService
  ) {
  }

  ngOnInit(): void {
    this.nextRunTimestamp = moment().unix();

    if (this.observableService[this.showExpectedMoveOneKey]
      && this.observableService[this.showExpectedMoveTwoKey]
    ) {
      this.showExpectedMoveOne = Boolean(this.observableService[this.showExpectedMoveOneKey].getValue());
      this.showExpectedMoveTwo = Boolean(this.observableService[this.showExpectedMoveTwoKey].getValue());

      this.subscriptions.add(
        this.observableService[this.showExpectedMoveOneKey].subscribe((value) => {
          this.showExpectedMoveOne = Boolean(value);
        })
      );

      this.subscriptions.add(
        this.observableService[this.showExpectedMoveTwoKey].subscribe((value) => {
          this.showExpectedMoveTwo = Boolean(value);
        })
      );
    }

    if (this.observableService[this.valuesInPercentExpectedMoveKey]) {
      this.valuesInPercentExpectedMove = this.observableService[this.valuesInPercentExpectedMoveKey].getValue() > 0;

      this.subscriptions.add(
        this.observableService[this.valuesInPercentExpectedMoveKey].subscribe((value) => {
          this.valuesInPercentExpectedMove = Boolean(value);
        })
      );
    }
  }

  ngAfterViewInit() {
    this.resizeObserver = new (window as any).ResizeObserver((entries) => {
      if (entries[0].contentRect.height > this.expectedMoveContainerDefaultHeight) {
        this.expected.nativeElement.classList.add('wrap');
      } else {
        this.expected.nativeElement.classList.remove('wrap');
      }
    });

    this.resizeObserver.observe(this.expected.nativeElement);
  }

  ngOnDestroy() {
    if (this.subscribedStock) {
      this.streamingService.unsubscribe(
        this.subscribedStock.subscriptionId,
        this.subscribedStock.symbol,
      );
    }

    this.subscriptions.unsubscribe();
    this.resizeObserver?.disconnect();
  }

  public getWrapperClass(): string {
    if (!this.expectedMoves.length) {
      return 'hidden';
    }

    return this.modelView;
  }

  public async onExpectedMoveShowClick(index: number): Promise<void> {
    if (!this.showWheelChartSettings) {
      return;
    }

    if (!this.observableService[this.showExpectedMoveOneKey]
      || !this.observableService[this.showExpectedMoveTwoKey]
    ) {
      return;
    }

    const value = index === 1
      ? this.showExpectedMoveOne ? 0 : 1
      : this.showExpectedMoveTwo ? 0 : 1;

    const setting = index === 1
      ? this.showExpectedMoveOneKey
      : this.showExpectedMoveTwoKey;

    this.observableService[setting].next(value);
    await this.userDataService.set(setting, value);
  }

  public async onExpectedMoveValueClick(): Promise<void> {
    if (this.valuesInPercentExpectedMoveKey && this.observableService[this.valuesInPercentExpectedMoveKey]) {
      this.observableService[this.valuesInPercentExpectedMoveKey].next(this.valuesInPercentExpectedMove ? 1 : 0);
      await this.userDataService.set(this.valuesInPercentExpectedMoveKey, this.valuesInPercentExpectedMove ? 0 : 1);
    }
  }

  protected openTradingHubModal(): void {
    if (!this.expectedMoves) {
      return;
    }

    this.userDataService.set(UserSettings.TradingHubTab, TradingHubTabs.IBot3);
    this.observableService.tradingHubTab.next(TradingHubTabs.IBot3);
    let prompt;

    if (this.expectedMoves.length === 0) {
      prompt = 'Act as a stock and options trading expert. Explain the <b>\'Expected Move\'</b> of a stock.';
    } else {
      prompt = `Act as a stock and options trading expert.<br>`
        + `1. Explain the concept of the "expected move", especially considering that there's a 68% probability that prices are within the "zone" by the specified date.<br>`
        + `2. Explain that there's a 16% probability that prices will be above the "zone" by the specified date and a 16% probability that they will be below.<br>`
        + `3. Use the following data to explain the concept: Stock Price: $${this.lastTradePrice}; ${this.generateExtendedPrompt()}`;
    }

    this.dialogsService.openTradingHubModal(TradingHubMode.Help, prompt);
  }

  private async initialize(securityId: number): Promise<void> {
    if (this.currentSymbol && this.currentSymbol.security_id === securityId) {
      return;
    }

    const symbol = await this.symbolsService.getById(securityId);
    const historicalData = await this.historicalDataService.get(securityId);
    this.lastTradePrice = historicalData.length
      ? historicalData[historicalData.length - 1].close
      : null;

    if (!symbol) {
      return;
    }


    this.currentSymbol = { security_id: securityId, symbol: symbol.symbol };
    this.expectedMoves = [];
    this.nextRunTimestamp = moment().unix();
    this.observableService.expectedMoveHasData.next(false);

    if (this.subscribedStock) {
      this.streamingService.unsubscribe(
        this.subscribedStock.subscriptionId,
        this.subscribedStock.symbol,
      );
    }

    if (symbol) {
      this.expectedMoves = await this.expectedMoveService.get(symbol.security_id);

      if (this.checkNextEarning) {
        const nextEarning = await this.earningsService.getNext(symbol.security_id, false);

        if (nextEarning) {
          const minDate = convertToEasternTime(nextEarning.report_date).format(MomentDateTimeFormats.ServerDate);
          const specificExpectedMove = this.expectedMoves.find((d) => d.expirationDate >= minDate);
          this.expectedMoves = specificExpectedMove
            ? [specificExpectedMove]
            : [];
        }
      }

      this.observableService.expectedMoveHasData.next(this.expectedMoves.length > 0);

      this.subscribedStock = {
        subscriptionId: uuidV4(),
        symbol: {
          symbol: symbol.symbol,
          country_code: ExchangeCountriesCodes.US,
        }
      };

      this.streamingService.subscribe(
        this.subscribedStock.subscriptionId,
        this.subscribedStock.symbol,
        {},
        this.handleLiveDataCallback.bind(this),
      );
    }

    this.wrapperClass = this.expectedMoves.length
      ? this.modelView
      : HiddenClassName;
  }

  private handleLiveDataCallback(data): void {
    if (data
      && !data.isPreMarket
      && !data.isPostMarket
      && this.expectedMoves?.length
      && this.expectedMoves[0].symbol === data.symbol
    ) {
      if (data?.close) {
        this.lastTradePrice = data.close;
      }

      if (moment().unix() < this.nextRunTimestamp) {
        return;
      }
      this.nextRunTimestamp = moment().add(this.liveDataIntervalSeconds || 0, 'seconds').unix();
      this.observableService.expectedMoveLiveData.next(data);

      this.expectedMoves.forEach((move) => {
        move.priceUp = round(data.close + move.expectedMove, 2);
        move.priceDown = round(data.close - move.expectedMove, 2);
      });
    }
  }

  private generateExtendedPrompt(): string {
    return this.expectedMoves.reduce((acc, expectedMove) => {
      const date = moment(expectedMove.expirationDate).format('MMM D, YYYY');
      const value = this.valuesInPercentExpectedMove
        ? `+/-${expectedMove.expectedPercent.toFixed(2)}%`
        : `+/-$${expectedMove.expectedMove.toFixed(2)}`;

      return acc + (acc ? ' ' : '') + `Expected Move for ${date}: ${value};`;
    }, '');
  }
}
