import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  selector: 'app-wheel-chart-legend',
  templateUrl: './wheel-chart-legend.component.html',
  styleUrls: ['./wheel-chart-legend.component.scss'],
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule],
})
export class WheelChartLegendComponent implements OnInit {

  protected isMenuOpened = false;

  constructor() {
  }

  ngOnInit(): void { }

  protected menuOpened(): void {
    this.isMenuOpened = true;
  }

  protected menuClosed(): void {
    this.isMenuOpened = false;
  }
}
