import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImportTradesStatusModalInputModel, ImportTradesStatusModalOutputModel, ImportTradesStatusModalResultType } from './models';

@Component({
  selector: 'app-import-trades-status-modal',
  templateUrl: './import-trades-status-modal.component.html',
  styleUrls: ['./import-trades-status-modal.component.scss']
})
export class ImportTradesStatusModalComponent implements OnInit {

  protected importing = true;
  protected actuiallyImported = 0;

  constructor(
    private _dialogRef: MatDialogRef<ImportTradesStatusModalComponent, ImportTradesStatusModalOutputModel>,
    @Inject(MAT_DIALOG_DATA) protected data: ImportTradesStatusModalInputModel,
  ) { }

  ngOnInit(): void {
    this.showStatusIcon();
  }

  protected close(action?: ImportTradesStatusModalResultType): void {
    this._dialogRef.close(action ? {
      success: true,
      action: action
    } : null);
  }

  private async showStatusIcon(): Promise<void> {
    this.actuiallyImported = await this.data.savingProcess;
    this.importing = false;
  }
}
