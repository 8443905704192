import { Injectable } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';

import { MarketTimeService } from '@s/market-time.service';
import { ObservableService } from '@s/observable.service';
import { EasternTimeZoneName } from '@const';

@Injectable({
  providedIn: 'root'
})
export class ScheduledEventsService {
  private timeComparingAccuracyInSeconds = 5;

  constructor(
    private observableService: ObservableService,
    private marketTimeService: MarketTimeService,
  ) { }

  public init(): void {
    this.marketTimeService.marketStateChanged$
      .pipe(
        withLatestFrom(this.observableService.playOpenMarketSound, this.observableService.playCloseMarketSound)
      )
      .subscribe(([{ prevMarketState, newMarketState }, playOpen, playClose]) => {
        // Only react to market open state change
        if (prevMarketState.isMarketOpened === newMarketState.isMarketOpened) {
          return;
        }

        const currentETTime = moment().tz(EasternTimeZoneName);
        const currentTimeDetails = {
          hours: currentETTime.hours(),
          minutes: currentETTime.minutes(),
          seconds: currentETTime.seconds(),
        };

        const { marketOpenTime, marketCloseTime } = this.marketTimeService.getMarketHoursDetails();

        // handle case when scripts are paused in inactive/suspended tab
        // check current ET time to be sure that sound will be played exactly at event time (current open/close time),
        // not when inactive tab is opened or later
        const isMarketOpenTime = currentTimeDetails.hours === marketOpenTime.hours
          && currentTimeDetails.minutes === marketOpenTime.minutes
          && Math.abs(currentTimeDetails.seconds - marketOpenTime.seconds) < this.timeComparingAccuracyInSeconds;
        const isMarketCloseTime = currentTimeDetails.hours === marketCloseTime.hours
          && currentTimeDetails.minutes === marketCloseTime.minutes
          && Math.abs(currentTimeDetails.seconds - marketCloseTime.seconds) < this.timeComparingAccuracyInSeconds;

        // for testing on DEV, to remove before moving to STAGE
        console.log({
          event: 'Market open/close sound',
          timeComparingAccuracyInSeconds: this.timeComparingAccuracyInSeconds,
          currentTimeDetails,
          isMarketOpenTime,
          isMarketCloseTime,
          marketOpenTime,
          marketCloseTime,
        });

        if (newMarketState.isMarketOpened && playOpen && isMarketOpenTime) {
          this.playOpenCloseMarketSound();
        }

        if (!newMarketState.isMarketOpened && playClose && isMarketCloseTime) {
          this.playOpenCloseMarketSound();
        }
      });
  }

  public async playOpenCloseMarketSound(): Promise<void> {
    try {
      const audioElement = new Audio('../../../assets/sounds/stock_market_bell.mp3');
      audioElement.autoplay = false;
      audioElement.muted = false;
      await audioElement.play();
    } catch (error) {
      console.warn('Error while playing open/close sound:', error);
    }
  }
}
