<div class="info">
  <div class="info-row">
    <div class="info-column type" *ngIf="groupTypeDisplay">
      <span class="info-header accented">
        {{groupTypeDisplay}}
      </span>
    </div>
    <div class="info-column date">
      <span class="info-header accented">
        {{model.minDateDisplay}}
      </span>
      <span class="info-header accented">
        {{model.maxDateDisplay}}
      </span>
    </div>
    <div class="info-column account">
      <span class="info-header overflow-ellipsis">
        {{model.accountName}}
      </span>
      <span class="info-header overflow-ellipsis">
        {{model.strategyName}}
      </span>
    </div>
    <div class="info-column show-button" (click)="costBasisButtonClick($event)">
      <mat-icon *ngIf="allowButtonsEvents" [svgIcon]="costBasisButtonState ? 'eye' : 'close-eye'"
        class="cost-basis column-button" [class]="costBasisButtonState ? 'eye-icon' : 'close-eye'">
      </mat-icon>
      <span class="column-content">
        <span class="info-header">Cost Basis</span>
        <span class="info-value" [ngClass]="model.costAvgClass">{{model.costAvg}}</span>
      </span>
    </div>
    <div class="info-column show-button" (click)="breakEvenButtonClick($event)">
      <mat-icon *ngIf="allowButtonsEvents" [svgIcon]="breakEvenButtonState ? 'eye' : 'close-eye'"
        class="break-even column-button" [class]="breakEvenButtonState ? 'eye-icon' : 'close-eye'">
      </mat-icon>
      <span class="column-content">
        <span class="info-header">Break Even</span>
        <span class="info-value" [ngClass]="model.breakEvenClass">{{model.breakEven}}</span>
      </span>
    </div>
    <div class="info-column shares">
      <span class="info-header">Shares</span>
      <span class="info-value" [ngClass]="model.sharesClass">{{model.shares}}</span>
    </div>
    <div class="info-column">
      <span class="info-header">Contracts</span>
      <span class="cell-table-style">
        <span class="info-value" [ngClass]="model.sellContractsClass">{{model.sellContracts}}</span>
        /
        <span class="info-value" [ngClass]="model.buyContractsClass">{{model.buyContracts}}</span>
      </span>
    </div>
    <div class="info-column">
      <span class="info-header">Fees & Comm</span>
      <span class="info-value" [ngClass]="model.feesAndCommClass">{{model.feesAndComm}}</span>
    </div>
    <div class="info-column">
      <span class="info-header">Premium</span>
      <span class="info-value" [ngClass]="model.premiumClass">{{model.premium}}</span>
    </div>
    <div class="info-column">
      <span class="info-header">Dividends</span>
      <span class="info-value" [ngClass]="model.dividendsClass">{{model.dividends}}</span>
    </div>
    <div class="info-column">
      <span class="info-header">Buying Power</span>
      <span class="info-value">{{model.buyingPower}}</span>
    </div>
    <div class="info-column">
      <span class="info-header">Realized P&L</span>
      <span class="info-value" [ngClass]="model.profitClass">{{model.profit}}</span>
    </div>
    <div class="info-column checkbox" *ngIf="groupTypeDisplay == 'Trades'">
      <mat-checkbox class="large-checkbox" (click)="$event.stopPropagation()">Do not show trades</mat-checkbox>
    </div>
  </div>
</div>
