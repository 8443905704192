import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatMenu } from '@angular/material/menu';
import { BehaviorSubject, combineLatest, startWith, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';
import * as _ from 'lodash';

import { DataChannelService } from '@s/data-channel.service';
import { SmileyDataService } from '@s/smiley-data.service';
import { convertArrayToRecord, round } from '@u/utils';
import { DataChannelCommands } from '@const';
import {
  Flags,
  ISmileyStatisticSubscriptionResponse,
  ISymbolStatisticItemRaw,
  SmileyListType
} from '@mod/symbol-smiley/symbol-smiley.model';
import { ISymbolFlagMenuItem, ISymbolStatistic, } from '@c/shared/symbol-flag/symbol-flag.model';
import { IDataChannelCommand } from '@mod/data/data-channel.model';
import { defaultSymbolStatistic, defaultSymbolStatisticRaw, flagIcons } from '@c/shared/symbol-flag/symbol-flag.data';

@Component({
  selector: 'app-symbol-flag',
  templateUrl: './symbol-flag.component.html',
  styleUrls: ['./symbol-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe],
})
export class SymbolFlagComponent implements OnInit, OnDestroy {
  // change subscription when it changes (for symbol-details-panel)
  @Input() set securityId(value: number) {
    this.securityId$.next(value);
  }

  @Input() set smileyListType(value: SmileyListType) {
    this.listType$.next(value);
  }

  @Input() sendUpdateSignalOnChange = false;

  // special parameter for symbol-details-panel:
  // ignore open/visible events and subscribe always on init (it's always visible) and show hint
  @Input() set keepSubscriptionAndShowVotesHint(value: boolean) {
    this.currentKeepSubscriptionAndShowVotesHint = value;
    this.keepSubscriptionAndShowVotesHint$.next(value);
  }

  // in case of conflict of values or flickering remove this input and get currentFlag only from "lastRelevantFlag$"
  @Input() set flag(value: Flags) {
    // set only available flags, handle null/undefined situations
    if (!this.flagIcons[value]) {
      this.currentFlag = Flags.None;
      this.changeDetectorRef.markForCheck();

      return;
    }

    this.currentFlag = value;
    this.changeDetectorRef.markForCheck();
  }

  @Input() showStatisticsWhenVisible = false;

  @Input() rootClass = '';
  @Input() showNoneFlagOnlyOnHover = false;

  @Output() flagChanged = new EventEmitter<Flags>();
  @Output() menuOpened = new EventEmitter<void>();
  @Output() menuClosed = new EventEmitter<Flags>();

  @ViewChild('smileyButton') smileyButton: ElementRef<HTMLElement>;
  @ViewChild(MatMenu, { read: ElementRef }) menu: MatMenu;

  protected readonly flags = Flags;

  protected readonly isInViewPort$ = new BehaviorSubject(false);
  protected readonly isMenuOpened$ = new BehaviorSubject(false);

  protected readonly securityId$ = new BehaviorSubject<number | null>(null);
  protected readonly listType$ = new BehaviorSubject<SmileyListType | null>(null);
  protected readonly keepSubscriptionAndShowVotesHint$ = new BehaviorSubject<boolean>(false);

  protected readonly lastReceivedStatisticAndFlag$ = combineLatest([
    this.smileyDataService.showStatisticSettingAndHasAccess$.pipe(distinctUntilChanged()),
    this.smileyDataService.lastReceivedSmileyStatistics$.pipe(distinctUntilChanged(_.isEqual)),
    this.smileyDataService.lastRelevantFlags$.pipe(distinctUntilChanged(_.isEqual)),
    this.listType$.pipe(filter((value) => !!value)),
    this.securityId$.pipe(filter((value) => !!value)),
  ])
    .pipe(
      map(([showStatistic, statistic, lastRelevantFlags, listType, securityId]) => {
        if (!listType || !securityId) {
          return {
            showStatistic: false,
            currentFlag: Flags.None,
            statistic: defaultSymbolStatistic,
          };
        }

        const currentFlag = lastRelevantFlags[listType][securityId] ?? Flags.None;

        return {
          showStatistic: showStatistic && currentFlag !== Flags.None,
          currentFlag,
          statistic: statistic[listType][securityId] ?? defaultSymbolStatistic,
        };
      }),
      startWith({
        showStatistic: false,
        currentFlag: Flags.None,
        statistic: defaultSymbolStatistic,
      })
    );


  protected readonly votesHint$ = this.lastReceivedStatisticAndFlag$
    .pipe(
      map((statisticAndFlag) => {
        if (!statisticAndFlag.statistic || !statisticAndFlag.statistic) {
          return '';
        }

        return `
          Votes:
          No - ${statisticAndFlag.statistic[this.flags.No].formattedCount}
          Maybe - ${statisticAndFlag.statistic[this.flags.Maybe].formattedCount}
          Yes - ${statisticAndFlag.statistic[this.flags.Yes].formattedCount}
          Never - ${statisticAndFlag.statistic[this.flags.Never].formattedCount}
        `;
      })
    );

  protected menuClass = '';
  protected notWrappedMenuWidthPx = 388;

  protected readonly flagIcons = flagIcons;

  protected readonly symbolFlags: ISymbolFlagMenuItem[] = [
    { id: Flags.No, flag: Flags.No, icon: this.flagIcons[Flags.No], label: 'No' },
    { id: Flags.Maybe, flag: Flags.Maybe, icon: this.flagIcons[Flags.Maybe], label: 'Maybe' },
    { id: Flags.Yes, flag: Flags.Yes, icon: this.flagIcons[Flags.Yes], label: 'Yes' },
    { id: Flags.Never, flag: Flags.Never, icon: this.flagIcons[Flags.Never], label: 'Never' },
    { id: Flags.None, flag: Flags.None, icon: this.flagIcons[Flags.None], label: 'None' },
  ];

  protected currentFlag: Flags = Flags.None;
  protected currentStatisticRaw: ISymbolStatisticItemRaw[] = defaultSymbolStatisticRaw;
  protected currentStatistic: ISymbolStatistic = defaultSymbolStatistic;

  protected showStatisticSettingAndHasAccess$ = this.smileyDataService.showStatisticSettingAndHasAccess$;
  protected showStatisticSettingAndHasAccess = false;
  protected currentKeepSubscriptionAndShowVotesHint = false;

  protected dataChannelSubscribeCommands: Record<SmileyListType, DataChannelCommands> = {
    [SmileyListType.Wtf]: DataChannelCommands.WtfSmileySubscribe,
    [SmileyListType.Wheel]: DataChannelCommands.WheelSmileySubscribe,
    [SmileyListType.PowerX]: DataChannelCommands.PxoSmileySubscribe,
    [SmileyListType.StockScreener]: DataChannelCommands.StockScreenerSmileySubscribe,
    [SmileyListType.ShortSellingStocks]: DataChannelCommands.ShortSellingStocksSmileySubscribe,
    [SmileyListType.ShortingStocksScanner]: DataChannelCommands.ShortingStocksScannerSmileySubscribe,
    [SmileyListType.DividendsStrategy]: DataChannelCommands.DividendsStrategySmileySubscribe,
  };
  protected dataChannelExecuteCommands: Record<SmileyListType, DataChannelCommands> = {
    [SmileyListType.Wtf]: DataChannelCommands.WtfSmileyExecute,
    [SmileyListType.Wheel]: DataChannelCommands.WheelSmileyExecute,
    [SmileyListType.PowerX]: DataChannelCommands.PxoSmileyExecute,
    [SmileyListType.StockScreener]: DataChannelCommands.StockScreenerSmileyExecute,
    [SmileyListType.ShortSellingStocks]: DataChannelCommands.ShortSellingStocksSmileyExecute,
    [SmileyListType.ShortingStocksScanner]: DataChannelCommands.ShortingStocksScannerSmileyExecute,
    [SmileyListType.DividendsStrategy]: DataChannelCommands.DividendsStrategySmileyExecute,
  };

  private dataChannelSubscribeCommand: IDataChannelCommand | null = null;
  private subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dataChannelService: DataChannelService,
    private smileyDataService: SmileyDataService,
    private ngZone: NgZone,
    private decimalPipe: DecimalPipe,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.lastReceivedStatisticAndFlag$
        .subscribe((statistic) => {
          this.currentStatistic = statistic.statistic;
          this.changeDetectorRef.markForCheck();
        })
    );

    // update statistic when menu is opened
    // (-) change existing subscription if securityId is changed (in data-window)
    this.subscriptions.add(
      combineLatest([
        this.isMenuOpened$.pipe(distinctUntilChanged()),
        this.isInViewPort$.pipe(debounceTime(300), distinctUntilChanged()),
        this.smileyDataService.showStatisticSettingAndHasAccess$.pipe(distinctUntilChanged()),
        this.securityId$.pipe(filter((value) => !!value)),
        this.lastReceivedStatisticAndFlag$.pipe(distinctUntilChanged(_.isEqual)),
        this.keepSubscriptionAndShowVotesHint$.pipe(distinctUntilChanged()),
      ])
        .subscribe(([
          menuOpened,
          inViewport,
          showStatistics,
          securityId,
          statisticAndFlag,
          keepSubscriptionAndShowVotesHint
        ]) => {
          this.showStatisticSettingAndHasAccess = showStatistics;
          this.currentFlag = statisticAndFlag.currentFlag;

          this.changeDetectorRef.markForCheck();

          if (menuOpened) {
            this.menuOpened.emit();
          }

          if (!showStatistics) {
            this.unsubscribeFromStatisticUpdates();

            this.changeDetectorRef.detectChanges();
            return;
          }

          if (this.dataChannelSubscribeCommand && this.dataChannelSubscribeCommand.data.security_id !== securityId) {
            this.unsubscribeFromStatisticUpdates();
          }

          // get cached statistic for new security_id
          const lastReceivedSmileyStatistics = this.smileyDataService.lastReceivedSmileyStatistics$.getValue();
          this.currentStatistic = (lastReceivedSmileyStatistics[this.listType$.getValue()] ?? {})[this.securityId$.getValue()]
            ?? defaultSymbolStatistic;
          this.onCurrentStatisticUpdated();

          this.changeDetectorRef.markForCheck();

          if (keepSubscriptionAndShowVotesHint) {
            this.subscribeToStatisticUpdates();

            return;
          }

          if (inViewport && statisticAndFlag.currentFlag !== Flags.None) {
            this.subscribeToStatisticUpdates();

            return;
          }

          if (menuOpened) {
            this.subscribeToStatisticUpdates();

            return;
          }

          // unsubscribe if there is no reason to keep getting updates
          this.unsubscribeFromStatisticUpdates();
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeFromStatisticUpdates();
    this.subscriptions.unsubscribe();
  }

  protected onSelectSmiley(flagMenuItem: ISymbolFlagMenuItem, matMenuRef: MatMenu): void {
    // update local statistic state based on last received currentStatisticRaw (from data-channel)
    // update local currentStatistic state and push changes into lastReceivedSmileyStatistic$

    const previousFlag = this.currentFlag;
    let newFlag = flagMenuItem.flag;

    if (this.currentFlag === flagMenuItem.flag) {
      newFlag = Flags.None;
    }

    this.smileyDataService.updateLastRelevantFlags(
      this.listType$.getValue(),
      { [this.securityId$.getValue()]: newFlag }
    );

    if (this.showStatisticSettingAndHasAccess) {
      // update statistic to show quick result in the component
      this.currentStatisticRaw = this.currentStatisticRaw.map((item) => {
        if (item.flag === previousFlag) {
          return { ...item, count: item.count > 0 ? item.count - 1 : 0 };
        }

        if (item.flag === newFlag) {
          return { ...item, count: item.count + 1 };
        }

        return item;
      });

      this.currentStatistic = this.calculateStatistic(this.currentStatisticRaw);
      this.onCurrentStatisticUpdated();
    }

    this.currentFlag = newFlag;
    this.flagChanged.emit(newFlag);

    this.changeDetectorRef.markForCheck();

    // do not await the result, check it in parallel
    this.sendDatachannelExecuteCommand(newFlag)
      .catch(() => {
        // optional: handle update smiley error (error-message, popup, etc)
      })
      .finally(() => {
        if (this.sendUpdateSignalOnChange) {
          this.smileyDataService.updateSymbolsSmileySignal$.next(this.listType$.getValue());
        }

        this.changeDetectorRef.detectChanges();
      });

    // close after all changes and for users without access to statistics
    matMenuRef.closed.emit();
    this.changeDetectorRef.markForCheck();
  }

  protected onOpenMenu(): void {
    const clientRect = this.smileyButton.nativeElement.getClientRects().item(0);
    const availableSpaceForMenu = window.innerWidth - (clientRect.x + clientRect.width);
    this.menuClass = availableSpaceForMenu <= this.notWrappedMenuWidthPx ? 'smiley-items-wrapped' : '';
    this.changeDetectorRef.detectChanges();
  }

  protected onCloseMenu(): void {
    this.menuClosed.emit(this.currentFlag);
    this.isMenuOpened$.next(false);
  }

  protected onChangeIsInViewPort(event: { target: HTMLElement, visible: boolean }): void {
    this.isInViewPort$.next(event.visible);
  }

  private async sendDatachannelExecuteCommand(newFlag: Flags | null): Promise<void> {
    if (!this.listType$.getValue() || !this.securityId$.getValue()) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      const dataChannelExecuteCommand = {
        subscriptionId: uuidV4(),
        name: this.dataChannelExecuteCommands[this.listType$.getValue()],
        data: { security_id: this.securityId$.getValue(), flag: newFlag },
        handler: ({ success }: { success: boolean }): void => {
          if (success) {
            resolve();
          }

          reject(null);
        },
      };

      this.dataChannelService.execute(dataChannelExecuteCommand);
    });
  }

  private handleSmileyStatisticResponse(data: ISmileyStatisticSubscriptionResponse): void {
    if (data.security_id !== this.dataChannelSubscribeCommand.data.security_id || !data.statistics) {
      return;
    }

    // check if this make difference for performance when there are ~70 or more actively updating symbols
    this.ngZone.onStable
      .pipe(take(1))
      .subscribe(() => {
        const normalizedStatistics = convertArrayToRecord(data.statistics, 'flag');
        this.currentStatisticRaw = defaultSymbolStatisticRaw.map((item) => normalizedStatistics[item.flag] ?? item);
        this.currentStatistic = this.calculateStatistic(this.currentStatisticRaw);

        this.onCurrentStatisticUpdated();
        this.changeDetectorRef.markForCheck();
      });
  }

  private calculateStatistic(rawStatistic: ISymbolStatisticItemRaw[]): ISymbolStatistic {
    if (!rawStatistic || !Array.isArray(rawStatistic)) {
      return defaultSymbolStatistic;
    }

    const statistic = defaultSymbolStatistic;
    const sum = rawStatistic.reduce((acc, item) => {
      const defaultNumberFormat = '1.0-0';

      const formattedCount = this.decimalPipe.transform(item.count, defaultNumberFormat);
      const formattedShortCount = item.count < 1000
        ? item.count
        : this.formatCount(item.count);

      statistic[item.flag] = {
        count: item.count,
        percent: 0,
        formattedCount,
        formattedShortCount
      };

      return acc + item.count;
    }, 0);

    if (sum === 0) {
      return statistic;
    }

    this.symbolFlags.forEach((item) => {
      const percent = round((statistic[item.flag].count / sum) * 100, 2);

      statistic[item.flag] = {
        count: statistic[item.flag].count,
        formattedCount: statistic[item.flag].formattedCount,
        formattedShortCount: statistic[item.flag].formattedShortCount,
        percent,
      };
    });

    return statistic;
  }

  private onCurrentStatisticUpdated(): void {
    this.smileyDataService.updateLastReceivedSmileyStatistics(
      this.listType$.getValue(),
      this.securityId$.getValue(),
      { ...this.currentStatistic }
    );
  }

  private subscribeToStatisticUpdates(): void {
    this.ngZone.onStable
      .pipe(take(1))
      .subscribe(() => {
        if (this.dataChannelSubscribeCommand) {
          return;
        }

        this.dataChannelSubscribeCommand = {
          subscriptionId: uuidV4(),
          name: this.dataChannelSubscribeCommands[this.listType$.getValue()],
          data: { security_id: this.securityId$.getValue() },
          handler: this.handleSmileyStatisticResponse.bind(this),
        };

        this.dataChannelService.subscribe(this.dataChannelSubscribeCommand);
      });
  }

  private unsubscribeFromStatisticUpdates(): void {
    if (this.dataChannelSubscribeCommand) {
      this.dataChannelService.unsubscribe(this.dataChannelSubscribeCommand);
      this.dataChannelSubscribeCommand = null;
    }
  }

  private formatCount(rawValue: number): string {
    if (rawValue === null || rawValue === undefined) {
      return '';
    }

    if (rawValue === 0) {
      return '0';
    }

    const numberLength = Math.round(Math.abs(rawValue)).toString().length;

    // examples: [2090 -> "2k"], [2390 -> "2.3k"]

    if (numberLength > 12) {
      return Math.floor(rawValue / Math.pow(10, 11)) / 10 + 'T';
    }

    if (numberLength > 9) {
      return Math.floor(rawValue / Math.pow(10, 8)) / 10 + 'B';
    }

    if (numberLength > 6) {
      return Math.floor(rawValue / Math.pow(10, 5)) / 10 + 'M';
    }

    if (numberLength > 3) {
      return Math.floor(rawValue / Math.pow(10, 2)) / 10 + 'k';
    }

    return rawValue.toString();
  }
}
