<div class="form-container" [formGroup]="form">
  <div class="symbol-settings">
    <div class="form-group">
      <mat-form-field>
        <input matInput autocomplete="off" type="text" placeholder="Enter symbol" formControlName="symbol">
        <mat-icon matPrefix [svgIcon]="'search-tool_icon'"></mat-icon>
        <mat-icon *ngIf="field('symbol').value" matSuffix (click)="clearSymbol()" class="close-icon"
        [svgIcon]="'close-menu'"></mat-icon>

      </mat-form-field>
    </div>
  </div>
</div>
