import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddEarningsFormComponent } from '@c/admin-config/earnings/add-earnings-form/add-earnings-form.component';
import { AddEarningsFormModel } from '@c/admin-config/earnings/add-earnings-form/add-earnings-form.model';
import { EarningModel } from '@mod/admin/earning.model';
import { EditModalResultModel } from '@mod/common/edit-modal-result.model';
import { DialogsService } from '@s/common/dialogs.service';
import { EarningsService } from '@s/earnings.service';
import * as moment from 'moment';

import { AddEarningsDialogInputDataModel, toEarningsModel } from './add-earnings-modal.model';

@Component({
  selector: 'app-add-earnings-modal',
  templateUrl: './add-earnings-modal.component.html',
  styleUrls: ['./add-earnings-modal.component.scss'],
})
export class AddEarningsModalComponent implements OnInit, AfterViewInit {
  @ViewChild('addEarningsForm') addEarningsForm: AddEarningsFormComponent;

  private _data: Partial<AddEarningsDialogInputDataModel>;
  constructor(
    private _earningsService: EarningsService,
    private _dialogsService: DialogsService,
    private _dialogRef: MatDialogRef<AddEarningsModalComponent, EditModalResultModel<EarningModel>>,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data: Partial<AddEarningsDialogInputDataModel>
  ) {
    this._data = data;
  }
  ngAfterViewInit(): void {
    if (this._data.symbol && this._data.security_id) {
      this.addEarningsForm.value = {
        symbol: this._data.symbol || '',
        report_date: null,
        security_id: this._data.security_id,
        before_after_market: null,
      };

      this._changeDetectorRef.detectChanges();
    }
  }

  ngOnInit() {}

  addDate(model: AddEarningsFormModel) {
    if (model === null) {
      return;
    }
    this.addEarningsForm.disableForm();
    this._earningsService
      .add({
        security_id: model.security_id,
        date: moment(model.report_date).format('YYYY-MM-DD'),
        before_after_market: model.before_after_market,
      })
      .subscribe(
        (result) => {
          this.addEarningsForm.enableFrom();
          this._dialogRef.close({ isCanceled: false, entity: toEarningsModel(model) });
        },
        (error) => {
          this.addEarningsForm.enableFrom();
          this._dialogsService.warning(error?.error?.description || 'Operation failed. Please try again later', 'Error');
        }
      );
  }

  cancel() {
    this._dialogRef.close({ isCanceled: true });
  }
}
