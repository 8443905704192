<div class="root" [ngClass]="rootClass">
  <div
    #smileyButton
    (click)="menuTrigger.openMenu(); isMenuOpened$.next(true); onOpenMenu();"
    (contextmenu)="$event.preventDefault()"
    class="menu-trigger"
    role="button"
    [class.show-none-only-on-hover]="showNoneFlagOnlyOnHover"
    [class.active-menu]="isMenuOpened$ | async"
    inViewport
    [inViewportOptions]="{ threshold: [0], partial: true }"
    (inViewportAction)="onChangeIsInViewPort($event)"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
  >
    <!-- smiley icon -->
    <mat-icon
      (contextmenu)="$event.preventDefault()"
      [svgIcon]="flagIcons[currentFlag]?.name"
      [ngClass]="flagIcons[currentFlag]?.class"
      class="icon"
      [matTooltip]="votesHint$ | async"
      [matTooltipDisabled]="!showStatisticSettingAndHasAccess || (currentFlag === flags.None) || !currentKeepSubscriptionAndShowVotesHint || menuTrigger.menuOpen"
      [matTooltipHideDelay]="0"
      [matTooltipPosition]="'below'"
    ></mat-icon>

    <!--  content for badge with current statistic value (for selected flag)  -->
    @if (showStatisticsWhenVisible && (showStatisticSettingAndHasAccess$ | async) && (currentFlag !== flags.None) && (lastReceivedStatisticAndFlag$ | async); as lastData) {
      <div
        class="smiley-button-badge current-flag-statistic-count"
        [matTooltip]="lastData.statistic[currentFlag].formattedCount + ' vote(s)'"
        [matTooltipDisabled]="lastData.statistic[currentFlag].count < 1000"
        [matTooltipHideDelay]="0"
      >
        {{ lastData.statistic[currentFlag].formattedShortCount }}
      </div>
    }
  </div>

  <mat-menu #menu="matMenu" class="smiley-menu symbol-flag-menu" (closed)="onCloseMenu()">
    <div class="smiley-menu-inner" [ngClass]="menuClass">
      @for (flagMenuItem of symbolFlags; track flagMenuItem.id) {
        <div
          class="smiley-menu-item"
          [class.active]="flagIcons[currentFlag]?.name === flagMenuItem.icon.name"
          (click)="$event.stopPropagation(); onSelectSmiley(flagMenuItem, menu)"
        >
          <div class="smiley-img-with-text">
            <button mat-menu-item class="smiley-menu-item-btn">
              <mat-icon
                [svgIcon]="flagMenuItem.icon.name"
                [ngClass]="flagMenuItem.icon.class"
                class="icon"
              ></mat-icon>
            </button>
            <div class="smiley-menu-title">{{ flagMenuItem.label }}</div>
          </div>
        </div>
      }
    </div>
  </mat-menu>
</div>
