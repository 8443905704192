  <div class="card p-b-0">
      <div *ngIf="!hasAnySignalOrPosition" class="only-none-div">
          NONE
      </div>
        <div *ngIf="hasAnySignalOrPosition && !showChangeDirection" [class]="singalBoxColorClass">
          <div class="p-l-14 text-center">
              <div class="f-12 c-color buy-sell-call">
                  <div *ngIf="!hasSignal">
                      NONE
                  </div>
                  <div *ngIf="hasSignal" class="display-grid">
                      <span class="bold">{{signalName}}</span>
                      <span class="f-11">{{signalComment}}</span>
                      <span class="bold" *ngIf="signalPrice">{{signalPrice}}</span>
                  </div>
              </div>
          </div>
          <div class="p-r-14 w-50 text-center pt-4 share-txt">
              <div class="d-rtl font-RB f-14">{{signalSharesCount}}</div>
              <div class="d-rtl f-12">Shares</div>
          </div>
          <div class="row">
              <div class="buying-power w-100 text-center font-10">
                  Buying Power: {{buyingPower}}
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="placeOrder"></ng-container>
        </div>
          <div *ngIf="hasAnySignalOrPosition && showChangeDirection" class="price-range change-bs-box" [ngClass]="changeDirectionClass">
            <span class="f-11">change in direction</span>
            <div class="f-12 c-color buy-sell-call">
              <div class="display-grid" *ngIf="changeDirectionTextArr as textArr">
                <span class="bold">{{textArr[0]}}</span>
                <span class="f-11">{{textArr[1]}}</span>
                <span class="f-11">(at market open)</span>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
              [matTooltip]="'Exit the trade as soon as possible - preferably right after the markets open'"
              [matTooltipShowDelay]="0"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'below'">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
            <ng-container [ngTemplateOutlet]="placeOrder"></ng-container>
          </div>
  <div class="stop-loss-target">
      <div class="text-center">
          <div class="font-10">Stop Loss</div>
          <div  class="t-red font-12 profit-loss-value">{{stopLoss}}</div>
      </div>
      <div class="tooltips" *ngIf="showProfitLossTooltip">
        <img src="assets/img/Icons/question-mark.svg" [matTooltip]="tProfitLoss()"
             [matTooltipShowDelay]="0"
             [matTooltipHideDelay]="0"
             [matTooltipPosition]="'below'">
      </div>
      <div class="text-center">
          <div class="d-rtl font-10">Profit Target</div>
          <div class="d-rtl t-green font-12 profit-loss-value" >{{targetProfit}}</div>
      </div>
  </div>
  <div class="risk-reward-small" [class]="riskRewardClass">
      <div>
          <p>{{riskRatio}}</p>
          <span>risk</span>
      </div>
      <div>
          <p>{{rewardRatio}}</p>
          <span>reward</span>
      </div>
  </div>
</div>


<ng-template #placeOrder>
  <div class="row trade-btn-block">
    <button *ngIf="showPlaceOrder && !isPrint"
      mat-button
      class="trade-btn"
      (click)="onPlaceOrderClick()"
    >
      Place Order
    </button>

    <button *ngIf="showOCOOrderButton"
       mat-button
       class="trade-btn oco-order"
       (click)="connectToTradier()"
    >Place OCO Order</button>
  </div>
</ng-template>


