import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Countries, Themes, UserSettings } from '@const';
import { DialogsService } from '@s/common/dialogs.service';
import { HolidaysService } from '@s/holidays.service';
import { UserDataService } from '@s/user-data.service';
import * as moment from 'moment-timezone';
import { Subscriber } from 'rxjs';
import { take } from 'rxjs/operators';
import { ObservableService } from '@s/observable.service';
import { IHoliday, IRawHoliday } from '@mod/data/holidays.model';

export interface IHolidayTableRow {
  id: string;
  date: string;
  name: string;
  flags: string[];
  isExpectedOrToday: boolean;
  isWeekend: boolean;
}

@Component({
  selector: 'app-holidays-modal',
  templateUrl: './holidays-modal.component.html',
  styleUrls: ['./holidays-modal.component.scss'],
})
export class HolidaysModalComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public displayedColumns: string[] = ['date', 'name', 'flag'];
  public countries = Countries;
  public countryFormControl = new FormControl<Countries | 'both'>('both');

  // take into account disabled tab with modal-title (index 0)
  public tabIndex = 2;
  public country: Countries | 'both' = 'both';

  public currentDateEST = moment().tz('America/New_York');
  public currentCleanDate = this.currentDateEST.startOf('day').format('YYYY-MM-DD');
  public currentYearEST = this.currentDateEST.year();

  public tabs = [
    { label: String(this.currentYearEST - 1), value: this.currentYearEST - 1 },
    { label: String(this.currentYearEST), value: this.currentYearEST },
    { label: String(this.currentYearEST + 1), value: this.currentYearEST + 1 },
  ];

  public rawHolidays: IRawHoliday[] = [];
  public groupedHolidays: Record<string, IHolidayTableRow[]> = {
    [this.tabs[0].label]: [],
    [this.tabs[1].label]: [],
    [this.tabs[2].label]: [],
  };

  private countriesData: Record<Countries, { icon: string, order: number }> = {
    [Countries.USA]: { icon: 'US-icon', order: 0 },
    [Countries.Canada]: { icon: 'CA-icon', order: 1 },
  };

  private subscriber = new Subscriber();

  constructor(
    private dialogRef: MatDialog,
    private dialogsService: DialogsService,
    private observableService: ObservableService,
    private holidaysService: HolidaysService,
    private userDataService: UserDataService,
  ) { }

  get crossSvg() {
    const theme = this.observableService.theme.value;
    return `assets/img/newImg/Close-p${theme === Themes.Dark ? '-white' : ''}.svg`;
  }

  ngOnInit() {
    const from = this.currentDateEST.clone().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
    const to = this.currentDateEST.clone().add(1, 'year').endOf('year').format('YYYY-MM-DD');

    this.subscriber.add(
      this.observableService.holidaysCountry
        .pipe(take(1))
        .subscribe((country) => {
          if (this.country !== country) {
            this.country = country;
            this.countryFormControl.setValue(country);

            const rawHolidaysToShow = this.filterRawHolidaysByCountry(this.rawHolidays, this.country);
            this.groupedHolidays = this.groupHolidays(rawHolidaysToShow);
          }
        })
    );

    this.subscriber.add(
      this.holidaysService.getWithWorkingHours({ from, to }).subscribe(
        (rawHolidays) => {
          this.rawHolidays = rawHolidays;
          const rawHolidaysToShow = this.filterRawHolidaysByCountry(this.rawHolidays, this.country);
          this.groupedHolidays = this.groupHolidays(rawHolidaysToShow);
          this.isLoading = false;
        },
        (error) => {
          this.dialogsService.warning(error?.error?.description || 'Request failed. Please try again later', 'Error');
        })
    );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  public async onCountryChange(event: MatRadioChange): Promise<void> {
    this.country = event.value;

    const rawHolidaysToShow = this.filterRawHolidaysByCountry(this.rawHolidays, this.country);
    this.groupedHolidays = this.groupHolidays(rawHolidaysToShow);

    await this.userDataService.set(UserSettings.HolidaysCountry, this.country);
  }

  private filterRawHolidaysByCountry(
    rawHolidays: IRawHoliday[],
    country: Countries | 'both',
  ): IRawHoliday[] {
    return this.country === 'both'
      ? rawHolidays
      : Array.from(rawHolidays).filter((holiday) => holiday.country === country);
  }

  private groupHolidays(holidays: IRawHoliday[]): Record<string, IHolidayTableRow[]> {
    const groupedHolidays: Record<string, IHolidayTableRow[]> = {
      [this.tabs[0].label]: [],
      [this.tabs[1].label]: [],
      [this.tabs[2].label]: [],
    };

    const uniqueHolidaysMap: Record<string, IHoliday & { nameFragments: string[] }> = {};

    Array.from(holidays)
      .sort((a, b) => this.countriesData[a.country].order - this.countriesData[b.country].order)
      .forEach((holiday) => {
        const key = holiday.date;

        if (uniqueHolidaysMap[key]) {
          uniqueHolidaysMap[key].countries = Array.from(new Set([...uniqueHolidaysMap[key].countries, holiday.country]));
          uniqueHolidaysMap[key].nameFragments = Array.from(new Set([...uniqueHolidaysMap[key].nameFragments, holiday.holiday]));

          return;
        }

        const cleanHolidayDate = moment(holiday.date).utc(false).format('YYYY-MM-DD');

        uniqueHolidaysMap[key] = {
          ...holiday,
          id: key,
          date: cleanHolidayDate,
          name: holiday.holiday,
          nameFragments: [holiday.holiday],
          countries: [holiday.country],
          isExpectedOrToday: !moment(cleanHolidayDate).isBefore(this.currentCleanDate),
          isWeekend: moment(cleanHolidayDate).isoWeekday() > 5,
        };
      });

    Object.values(uniqueHolidaysMap)
      .sort((a, b) => moment(a.date).isBefore(b.date) ? -1 : 1)
      .forEach((holiday) => {
        const year = moment(holiday.date).year();

        if (Math.abs(this.currentYearEST - year) <= 1) {
          groupedHolidays[year].push({
            id: holiday.id,
            date: holiday.date,
            name: holiday.nameFragments.join(' / '),
            flags: holiday.countries.map((country) => this.countriesData[country].icon),
            isExpectedOrToday: holiday.isExpectedOrToday,
            isWeekend: holiday.isWeekend,
          });
        }
      });

    return groupedHolidays;
  }
}
