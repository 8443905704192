import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, Subscriber } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { ObservableService } from '@s/observable.service';
import { SearchPopupService } from '@s/search-popup.service';
import { UserDataService } from '@s/user-data.service';
import { SmileyDataService } from '@s/smiley-data.service';
import {
  ImportExportSymbolsToWatchlistModalComponent,
} from './import-export-symbols-to-watchlist-modal/import-export-symbols-to-watchlist-modal.component';
import {
  GroupedWatchlistWrapperComponent
} from '@m/powerx/scanner-watchlist-window/grouped-watchlist-wrapper/grouped-watchlist-wrapper.component';
import { allExchangeCountryCodes, saveTabStateDebounceTime, Themes, UserSettings } from '@const';
import { SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';

@Component({
  selector: 'app-scanner-watchlist-panel',
  templateUrl: './scanner-watchlist-panel.component.html',
  styleUrls: ['./scanner-watchlist-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScannerWatchlistPanelComponent implements OnInit, OnDestroy {
  protected selectedTabIndex = 1;
  protected showDropDown = false;

  private saveTabState$ = new Subject<number>();
  private subscriber = new Subscriber();

  get arrowSvg(): string {
    const theme = this.observableService.theme.value;
    return `assets/img/newImg/Arrow${theme === Themes.Dark ? '-white' : ''}.svg`;
  }
  get importSvg(): string {
    const theme = this.observableService.theme.value;
    return `assets/img/Import${theme === Themes.Dark ? '-white' : ''}.svg`;
  }
  get exportSvg(): string {
    const theme = this.observableService.theme.value;
    return `assets/img/Export${theme === Themes.Dark ? '-white' : ''}.svg`;
  }
  get addSvg(): string {
    const theme = this.observableService.theme.value;
    return `assets/img/Add${theme === Themes.Dark ? '-white' : ''}.svg`;
  }

  @ViewChild('groupedWatchlistWrapper') groupedWatchlistWrapper: GroupedWatchlistWrapperComponent;

  constructor(
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private searchPopupService: SearchPopupService,
    private smileyDataService: SmileyDataService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // request smiley-data for power-x on init
    this.smileyDataService.get(SmileyListType.PowerX).subscribe((res) => {
      // no actions
    });

    this.observableService.selectScannerTab
      .subscribe((tabIndex) => {
        if (tabIndex >= 0 && tabIndex <= 2) {
          this.selectedTabIndex = tabIndex;
        }
      });

    this.subscriber.add(
      this.saveTabState$
        .pipe(debounceTime(saveTabStateDebounceTime))
        .subscribe(async (tabIndex) => {
          this.observableService.selectScannerTab.next(tabIndex);
          await this.userDataService.set(UserSettings.SelectScannerTab, tabIndex);
        })
    );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.saveTabState$.next(tabChangeEvent.index);
  }

  dropDownControl() {
    this.showDropDown = !this.showDropDown;
  }

  addWatchListSymbol() {
    this.searchPopupService.openPopup('', true, false, true, allExchangeCountryCodes);
  }

  importExportSymbolFile(impExpStatus, windowName = '') {
    const importExportStatus = {
      status: impExpStatus,
      windowName,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['import-export-symbol', 'modals'];
    dialogConfig.data = importExportStatus;
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(ImportExportSymbolsToWatchlistModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (impExpStatus === 'import' && result === 'success') {
        this.groupedWatchlistWrapper.removeAllGroups();
      }
    });
  }

  protected addSectionIntoGroupedWatchlist(): void {
    this.groupedWatchlistWrapper.addNewGroup();
  }
}
