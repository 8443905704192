import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ObservableService } from '@s/observable.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor(private _observableService: ObservableService) {
    Sentry.setTag('stage', environment.name);
  }

  log(error: Error, context?: object): void {
    const user = this._observableService?.mySettings?.value;
    Sentry.setUser({
      id: user?.user_id,
      username: `${user?.first_name} ${user?.last_name}`,
    });

    Sentry.setContext('User Settings', user);
    if (context) {
      Sentry.setContext('Additional Context', context);
    }

    Sentry.captureException(error);
  }

}
