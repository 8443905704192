import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../core/interface/response';

@Injectable({
  providedIn: 'root'
})
export class InfusionSoftService {

  constructor(private http: HttpClient) { }

  set = async (flag: string) => {
    const { result } = await this.http.post<Response>('/v2/infusionSoft', {
      flag
    }).toPromise();

    return result;
  };
}
