import { Injectable } from '@angular/core';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService } from '@s/observable.service';
import { StorageKeys } from '@const';
import * as moment from 'moment';
import { DeepWriteable, RawStudyMetaInfoId, StudyPlotInformation } from '@chart/charting_library';

@Injectable()
export class RSIValue {
  constructor() {
  }

  createRsiIndicator(PineJS, observableService: ObservableService, localStorageService: LocalStorageService) {
    return {
      name: 'RsiValueIndicator',
      metainfo: {
        _metainfoVersion: 40,
        id: 'RsiValueIndicator@tv-basicstudies-1' as RawStudyMetaInfoId,
        format: {
          type: 'price' as DeepWriteable<'price'>,
          precision: 2
        },
        scriptIdPart: '',
        name: 'RsiValueIndicator',
        description: 'RsiValueIndicator',
        shortDescription: 'RSI',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        'plots': [{ 'id': 'plot_0', 'type': 'line' }] as DeepWriteable<Readonly<StudyPlotInformation>>[],
        'defaults': {
          'styles': {
            'plot_0': {
              'linestyle': 0,
              'visible': true,
              'linewidth': 2,
              'plottype': 2,
              // Show price line?
              'trackPrice': false,
              // Plot transparency, in percent.
              'transparency': 40,
              'color': '#A100ED'
            }
          },

          // Precision of the study's output values
          // (quantity of digits after the decimal separator).
          'precision': 2,

          'inputs': {}
        },
        'styles': {
          'plot_0': {
            // Output name will be displayed in the Style window
            'title': '-- output name --',
            'histogramBase': 0,
          }
        },
        'inputs': [],
      },

      constructor: function () {
        this.init = async function (context, inputCallback) {
          const security_id = observableService.symbol.getValue();
          const storageKey = `${StorageKeys.ProcessedData}_${security_id}`;
          this.processedData = localStorageService.getFromMemory(storageKey);
        },

          this.main = function (context, inputCallback) {
            const barDate = moment(PineJS.Std.time(context)).tz('America/New_York').format('YYYY-MM-DD');

            const item = this.processedData[barDate];
            if (isNaN(parseFloat(item?.rsi))) {
              return [];
            } else {
              return [item.rsi];
            }
          }
      }
    };
  }
}
