import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';

import { CookieService } from '@s/cookie.service';
import { ObservableService } from '@s/observable.service';
import { BannerCookieExpirationDays } from '@const';
import { ApplicationVersionModel } from '@mod/admin/application-version.model';

@Component({
  selector: 'app-version-change-popup',
  templateUrl: './version-change-popup.component.html',
  standalone: true,
  imports: [
    NgIf,
  ]
})
export class VersionChangePopupComponent implements OnInit, OnDestroy {
  protected showBanner = false;

  private _subscriptions = new Subscription();

  constructor(
    private _observableService: ObservableService,
    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this._setShowBanner(this._observableService.applicationVersion.getValue());

    this._subscriptions.add(
      this._observableService.applicationVersion.subscribe((appVersion) => this._setShowBanner(appVersion))
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  closeBanner() {
    const appVersion = this._observableService.applicationVersion.getValue();
    this._cookieService.set(this._getCookieName(appVersion), '1', BannerCookieExpirationDays);
    this._setShowBanner(appVersion);
  }

  refreshPage() {
    this.closeBanner();
    window.location.reload();
  }

  _getCookieName(appVersion: ApplicationVersionModel) {
    if (!appVersion) {
      return;
    }

    return `application_${appVersion.version}_${appVersion.counter}`;
  }

  _setShowBanner(appVersion: ApplicationVersionModel) {
    if (!appVersion) {
      return;
    }

    const applicationVersionCookie = this._cookieService.get(this._getCookieName(appVersion));
    this.showBanner = !applicationVersionCookie;
  }
}
