import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ObservableService } from '@s/observable.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  count = 0;
  private noLoaderArray = ['ohlc', 'earning', 'profit-loss', 'bar-colour'];

  private loadingMap: Map<string, boolean> = new Map<string, boolean>();
  private isShowingLoader: boolean = false;

  constructor(private observableService: ObservableService) {}

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      return;
    }

    if (loading === true) {
      this.loadingMap.set(url, loading);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }

    if (this.loadingMap.size === 0 && this.isShowingLoader) {
      this.isShowingLoader = false;
      this.observableService.isLoading.next(false);
    } else if (this.loadingMap.size > 0 && !this.isShowingLoader) {
      this.isShowingLoader = true;
      this.observableService.isLoading.next(true);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const filtered = this.noLoaderArray.find((x) => req.url.indexOf(x) !== -1) || req.url.indexOf('/v2/') !== -1;
    const showLoader =
      !filtered && !req.url.endsWith('/wheel') && req.method !== 'POST' && (!req.url.startsWith('wss') || !req.url.startsWith('ws'))
      && !this.observableService.showMaintenance;

    if (showLoader) {
      this.setLoading(true, req.url);
    }

    return next
      .handle(req)
      .pipe(
        catchError((err) => {
          if (showLoader) {
            this.setLoading(false, req.url);
          }
          return throwError(err);
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (showLoader && evt instanceof HttpResponse) {
            this.setLoading(false, req.url);
          }
          return evt;
        })
      );
  }
}
