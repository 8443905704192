<div class="trading-panel-order-wrapper" [ngClass]="{'pinned-panel': isPanelOrderPinned}">
  <div mat-dialog-title class="oco-order-modal-heading">
    <h2 class="modal-title">
      {{orderInput.symbol?.symbol || orderInput.option?.underlyingSymbol}}<span *ngIf="orderInput.id">, order {{orderInput.id}}</span>
    </h2>

    <div class="modal-heading-buttons">
      <button *ngIf="isUnpinEnabled" mat-icon-button class="modal-heading-btn" (click)="unpinOrderModal()"
        [matTooltip]="'Undock'"
        [matTooltipPosition]="'above'">
        <mat-icon [svgIcon]="'pin-icon'" class="modal-heading-icon pin-icon pinned">
        </mat-icon>
      </button>
      <button *ngIf="showResetToDefault" mat-icon-button class="modal-heading-btn" (click)="resetToDefault()"
        [matTooltip]="'Reset to default'"
        [matTooltipPosition]="'above'">
        <mat-icon [svgIcon]="'reset-icon'" class="modal-heading-icon">
        </mat-icon>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-icon-button class="popup-close-btn" tabindex="-1" (click)="close()">
        <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon">
        </mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content class="oco-order-content">
    <app-trading-panel-order [orderInput]="orderInput"></app-trading-panel-order>
  </div>
</div>
