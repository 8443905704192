<div class="timer-container">
  <svg id="svg" width="250" height="250" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle r="110" cx="125" cy="125" fill="transparent" stroke-dasharray="690.8" stroke-dashoffset="0"></circle>
    <circle id="bar" r="110" cx="125" cy="125" fill="transparent" stroke-dasharray="690.8" stroke-dashoffset="690.8"></circle>
  </svg>
  <div class="timer">
    <div>
      <p class="unit">HOURS</p>
      <p class="value">{{ hoursToFinish || 0 }}</p>
    </div>
    <div class="divider">
      <p>:</p>
    </div>
    <div>
      <p class="unit">MINUTES</p>
      <p class="value">{{ minutesToFinish || 0 }}</p>
    </div>
    <div class="divider">
      <p>:</p>
    </div>
    <div>
      <p class="unit">SECONDS</p>
      <p class="value">{{ secondsToFinish || 0 }}</p>
    </div>
  </div>
</div>
