<app-maintenance></app-maintenance>
<div *ngIf="!showMaintenance" class="powerx-main">
    <div class="left-side"
         [ngStyle]="{'--custom-width-with-pinned-order-panel': showOrderPanel ? '370px' : '0px', '--search-bar-height': searchBar.offsetHeight + 'px'}"
    >
        <div #searchBar>
          <app-search-bar [isPowerX]="true"></app-search-bar>
        </div>
        <app-chart-menu></app-chart-menu>
    </div>
    <app-trading-panel-order-panel *ngIf="showOrderPanel"></app-trading-panel-order-panel>
    <div class="right-side">
        <as-split direction="horizontal" class="all-section" gutterSize="4">
            <as-split-area>
                <as-split direction="vertical" gutterSize="4">
                    <as-split-area [size]="35" minSize="25" class="right-one">
                        <app-scanner-watchlist-panel></app-scanner-watchlist-panel>
                    </as-split-area>
                    <as-split-area [size]="65" minSize="25" class="right-two" gutterSize="11" class="h-less-991">
                        <app-data-window [isPrint]="false"></app-data-window>
                    </as-split-area>
                </as-split>
            </as-split-area>
        </as-split>
    </div>
</div>
