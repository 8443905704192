import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, signal } from '@angular/core';
import { tradingLogQuotesOverlayPositions } from '@constants/trading-log.constants';
import { TradingLogQuotesOptionsModalComponent } from '@mdl/trading-log-quotes-options-modal/trading-log-quotes-options-modal.component';
import { TradingLogQuotesOptionsModel } from '@mod/trading-log';
import { Unsubscriber } from '@u/unsubscriber';
import { Subscription, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradingLogQuotesOptionService extends Unsubscriber {
  public isQuotesLoading = signal(false);

  private quoutesOptionsOverlayRef: OverlayRef;

  constructor(
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _overlay: Overlay,
  ) {
    super();
  }

  public async showQuotesTooltip(element: HTMLElement, quotesOptions: TradingLogQuotesOptionsModel): Promise<void> {
    this.disposeOverlayRef();
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(element)
      .withPositions(tradingLogQuotesOverlayPositions);
    this.quoutesOptionsOverlayRef = this._overlay.create({
      positionStrategy,
      hasBackdrop: true,
      panelClass: 'trading-log-quotes-pane',
      backdropClass: 'trading-log-quotes-backdrop',
    });

    if (this.quoutesOptionsOverlayRef && !this.quoutesOptionsOverlayRef.hasAttached()) {
      const heatmapTooltipRef = this.quoutesOptionsOverlayRef.attach(
        new ComponentPortal(TradingLogQuotesOptionsModalComponent),
      );
      this.isQuotesLoading.set(true);
      await heatmapTooltipRef.instance.subscribeLiveData(
        quotesOptions.symbol,
        quotesOptions.expiration,
        quotesOptions.isPut,
      );
      this.hideQuotesTooltip();
      this.quoutesOptionsOverlayRef
        .backdropClick()
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.disposeOverlayRef());

      heatmapTooltipRef.instance.initialDataLoadedEvent
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
            // needed to update overlay position on ios/safari
            const overlayBoundingRect = this.quoutesOptionsOverlayRef.overlayElement.getBoundingClientRect();
            this.quoutesOptionsOverlayRef.updateSize({
              width: overlayBoundingRect.width,
              height: overlayBoundingRect.height
            });
            this.quoutesOptionsOverlayRef.updatePosition();
            this.visibleQuotesTooltip();
            this.isQuotesLoading.set(false);
        });

      heatmapTooltipRef.instance.onRetryClickEvent
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this.hideQuotesTooltip();
          this.isQuotesLoading.set(true);
        });

      heatmapTooltipRef.instance.closeClickEvent
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this.disposeOverlayRef();
          this.isQuotesLoading.set(false);
        });
    }
  }

  public disposeOverlayRef(): void {
    this.quoutesOptionsOverlayRef?.detach();
    this.quoutesOptionsOverlayRef?.dispose();
    this._destroy$.next(true);
  }

  private hideQuotesTooltip(): void {
    this.quoutesOptionsOverlayRef.backdropElement.style.visibility = 'hidden';
    this.quoutesOptionsOverlayRef.hostElement.style.visibility = 'hidden';
  }

  private visibleQuotesTooltip(): void {
    this.quoutesOptionsOverlayRef.backdropElement.style.visibility = 'visible';
    this.quoutesOptionsOverlayRef.hostElement.style.visibility = 'visible';
  }
}
