import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { ITradingPlan } from '@c/trading-hub/trading-plan/trading-plan.model';
import { ObservableService } from '@s/observable.service';

@Component({
  selector: 'app-print-trading-plan',
  templateUrl: './print-trading-plan.component.html',
  styleUrls: ['./print-trading-plan.component.scss'],
})
export class PrintTradingPlanComponent implements OnInit {
  public planToPrint: ITradingPlan;

  constructor(private observableService: ObservableService) { }

  ngOnInit(): void {
    this.observableService.tradingHubPlanToPrint
      .pipe(take(1))
      .subscribe((planToPrint) => (this.planToPrint = planToPrint));
  }
}
