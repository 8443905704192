import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddEarningServiceModel, DeleteEarningServiceModel, EarningModel, UpdateEarningServiceModel } from '@mod/admin/earning.model';
import { GlobalIdentificator } from '@t/common/global-identificator.type';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EarningsTimeOfDay, EasternTimeZoneName, StorageKeys } from '@const';
import { Response } from '../core/interface/response';
import { RestRequestorService } from '@s/rest-requestor.service';
import { convertToEasternTime } from '@u/utils';

@Injectable({
  providedIn: 'root',
})
export class EarningsService {
  constructor(
    private http: HttpClient,
    private restRequestorService: RestRequestorService
  ) {
  }

  get = async (security_id: GlobalIdentificator, ignoreCache: boolean = false): Promise<ReadonlyArray<EarningModel>> => {
    const date = moment().utc().toISOString();
    const storageKey = `${StorageKeys.Earnings}_${security_id}_${moment().utc().startOf('day').format('YYYY-MM-DD')}`;

    const { result } = await this.restRequestorService.makeRequest(
      storageKey,
      () => this.http.get<Response<EarningModel[]>>(`/v2/earnings?security_id=${security_id}&date=${date}`).toPromise(),
      ignoreCache
    );

    return result || [];
  };

  // TODO: to check, it doesn't return declared interface
  // missed fields: "description", "exchange_id", "exchange_name", "exchange_code"
  // existing, but not-declared fields: "earnings_in_days"
  getNext = async (security_id: number, skip_before_market: boolean = true): Promise<EarningModel> => {
    const earnings = await this.get(security_id);
    const todayStartOfDay = moment.tz(EasternTimeZoneName).startOf('day').unix();

    if (earnings && earnings.length) {
      for (let i = 0; i < earnings.length; i++) {
        const reportDate = convertToEasternTime(earnings[i].report_date).startOf('day').unix();
        const isMatch = (reportDate > todayStartOfDay)
          || (reportDate === todayStartOfDay && (skip_before_market ? earnings[i].before_after_market !== EarningsTimeOfDay.BeforeMarket : true));

        if (isMatch) {
          return earnings[i];
        }
      }
    }

    return null;
  };

  add = (model: AddEarningServiceModel): Observable<boolean> => {
    return this.http.post<any>(`/v2/earnings`, model).pipe(map((_) => true));
  };

  update = (model: UpdateEarningServiceModel): Observable<boolean> => {
    return this.http.put<any>(`/v2/earnings`, model).pipe(map((_) => true));
  };

  remove = (model: DeleteEarningServiceModel): Observable<boolean> => {
    return this.http.post<any>(`/v2/earnings/remove`, model).pipe(map((_) => true));
  };
}
