<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <div
    [ngClass]="{ 'hidden': tasks.length === 0 }"
    class="control-panel"
  >
    <div class="section start">
      <span class="control-panel-title">To do</span>
      <!--   Do not remove, uncomment for undo/redo functionality   -->
      <!--              <button-->
      <!--                mat-button-->
      <!--                class="icon-button custom-icon-btn trading-hub-btn"-->
      <!--                aria-label="Undo changes"-->
      <!--                [matTooltip]="'Undo changes'"-->
      <!--                [trigger]="'hover'"-->
      <!--                (click)='undoChanges()'-->
      <!--              >-->
      <!--                <mat-icon [svgIcon]="'prev-arrow-icon'" class="icon-s-size reset"></mat-icon>-->
      <!--              </button>-->

      <!--              <button-->
      <!--                mat-button-->
      <!--                class="icon-button custom-icon-btn trading-hub-btn"-->
      <!--                aria-label="Redo changes"-->
      <!--                [matTooltip]="'Redo changes'"-->
      <!--                [trigger]="'hover'"-->
      <!--                (click)="redoChanges()"-->
      <!--              >-->
      <!--                <mat-icon [svgIcon]="'next-arrow-icon'" class="icon-s-size reset"></mat-icon>-->
      <!--              </button>-->
    </div>

    <div class="section end">
      <!-- button is temporary hidden using "display: none;" css settings -->
      <button
        mat-button
        *ngIf="isThereNotCompletedTasks"
        (click)="markAllAsCompleted()"
        class="recommended-btn mark-all-as-done-btn"
      >
        Mark all as done
      </button>

      <button
        mat-icon-button
        *ngIf="isThereCompletedTasks"
        (click)="markAllAsNotCompleted()"
        class="icon-button custom-icon-btn reset-button trading-hub-btn trading-hub-reset-btn"
        aria-label="Mark all tasks as not completed"
        matTooltip="Mark all tasks as not completed"
        trigger="hover"
      >
        <mat-icon [svgIcon]="'reset'" class="middle-icon reset"></mat-icon>
      </button>
    </div>
  </div>

  <app-no-data-content
    header="Create your own checklist"
    subHeader="Add tasks that you are doing daily in order not to forget anything important"
    *ngIf="tasks.length === 0"
  >
    <button mat-button type="submit" class="fill-btn" (click)="addTask()">
      Add Task
    </button>
  </app-no-data-content>

  <app-to-do-list
    [ngClass]="{ 'hidden': tasks.length === 0 }"
    [tasks]="tasks"
    (updateTasks)="updateTasks($event)"
    class="app-to-do-list scroll-style"
  ></app-to-do-list>

  <div class="task-list-footer">
    <div class="check-list-view-control">
      <mat-slide-toggle
        color="primary"
        hideIcon
        class="large-slide-toggle"
        [checked]="isOpenOnStart"
        (change)="onChangeIsOpenOnStar($event.checked)"
      ></mat-slide-toggle>
      <div class="toggle-alert">
        <h6>Automatically show pre-trading checklist every day</h6>
        <span class="alert-subheader">
          The checklist will show up on a page once your new work-day started
        </span>
      </div>
    </div>

    <div class="reset-tasks">
      <button
        mat-button
        class="recommended-btn"
        (click)="resetToDefault()"
      >
        Reset to Default
      </button>
      <span class="alert-subheader">
        Replace your current checklist with our recommended checklist
      </span>
    </div>
  </div>
</ng-container>
