<div class="filters-presets-menu shared-component">
  <button
    [matMenuTriggerFor]="menu"
    [class.active]="isMenuOpened"
    (click)="$event.stopPropagation(); isMenuOpened = true"
    mat-stroked-button
    color="primary"
    class="selected-filter-button"
  >
    <span class="selected-filter-text">
      {{ selectedPreset?.title ?? '(Select preset)' }}
    </span>

    <mat-icon
      [svgIcon]="'arrow-down_icon'"
      iconPositionEnd
      class="middle-icon presets-arrow-down-icon"
    ></mat-icon>
  </button>

  <!-- do not remove, it's for the next release -->
  <!-- <button
    mat-icon-button
    class="btn-standard primary-bg primary save-filter-btn"
    (click)="savePresetSettings()"
    [matTooltip]="'Save filter changes for the selected preset'"
    [matTooltipPosition]="'above'"
    [matTooltipHideDelay]="0"
  >
    <mat-icon [svgIcon]="'save_icon'" class="middle-icon"></mat-icon>
  </button> -->

  <mat-menu
    (closed)="resetEditMode(); isMenuOpened = false"
    #menu="matMenu"
    class="filters-presets-settings-menu"
  >
    <!-- DEFAULT PRESETS SECTION -->
    <p class="filters-list-title">PXO PRESETS:</p>
    <div
      [cdkDropListDisabled]="currentPredefinedFiltersList.length < 2"
      [class.drop-disabled]="currentPredefinedFiltersList.length < 2"
      (cdkDropListDropped)="onDropPredefined($event)"
      cdkDropList
      class="filters-list-default filters-drop-list default-presets draggable scroll-style"
    >
      @for (filter of currentPredefinedFiltersList; track filter.id) {
        <button
          [class.selected]="selectedPreset && selectedPreset.id === filter.id"
          (click)="selectPreset(filter)"
          (contextmenu)="$event.preventDefault()"
          mat-menu-item
          class="filters-list-item"
          disableRipple
          cdkDragBoundary=".filters-list-default"
          cdkDragLockAxis="y"
          cdkDrag
        >
          <mat-icon
            [svgIcon]="'drag-handler'"
            cdkDragHandle
            class="drag-handler"
          ></mat-icon>

          {{ filter.title }}

          <!-- CURRENTLY THESE ACTIONS ARE DISABLED FOR PREDEFINED PRESETS, CAN TO BE CHANGED IN THE NEXT VERSION -->
          <div class="drop-list-actions">
            <button class="filters-presets-action-btn filters-presets-edit">
              <mat-icon
                [svgIcon]="'edit_icon'"
                class="monochrome-color-icon monochrome-color-icon-hover"
              ></mat-icon>
            </button>
            <button class="filters-presets-action-btn filters-presets-remove">
              <mat-icon
                [svgIcon]="'trash_icon'"
                class="monochrome-color-icon monochrome-color-icon-hover"
              ></mat-icon>
            </button>
          </div>
        </button>
      }
    </div>

    <mat-divider class="divider"></mat-divider>

    <!--MY PRESETS SECTION-->
    <p class="filters-list-title">MY PRESETS:</p>

    <div
      [cdkDropListDisabled]="currentCustomFiltersList.length < 2"
      [class.drop-disabled]="currentCustomFiltersList.length < 2"
      (cdkDropListDropped)="onDropCustom($event)"
      cdkDropList
      class="filters-list-custom filters-drop-list custom-presets draggable scroll-style"
    >
      @for (filter of currentCustomFiltersList; track filter.id) {
        @if (filter.id === editedItemId) {
          <ng-container *ngTemplateOutlet="editField"></ng-container>
        }

        @if (filter.id !== editedItemId) {
          <button
            [class.selected]="selectedPreset && selectedPreset.id === filter.id"
            (click)="selectPreset(filter)"
            (contextmenu)="$event.preventDefault()"
            mat-menu-item
            class="filters-list-item"
            disableRipple
            cdkDragBoundary=".filters-list-custom"
            cdkDragLockAxis="y"
            cdkDrag
          >
            <mat-icon
              [svgIcon]="'drag-handler'"
              cdkDragHandle
              class="drag-handler"
            ></mat-icon>
            {{ filter.title }}
            <ng-container
              [ngTemplateOutlet]="customPresetMenu"
              [ngTemplateOutletContext]="{ filter: filter }"
            ></ng-container>
          </button>
        }
      }
    </div>

    <!-- ADD FILTER BUTTON -->

    @if (editedItemId !== newPresetTempId) {
      <button
        (click)="$event.stopPropagation(); setEditModeForNewPreset()"
        mat-button
        color="primary"
        class="add-filter-btn full-width"
      >
        <mat-icon [svgIcon]="'plus_large'"></mat-icon>
        <span class="label right-side-title">Save Filter as...</span>
      </button>
    }

    <ng-container *ngIf="editedItemId === newPresetTempId">
      <ng-container *ngTemplateOutlet="editField"></ng-container>
    </ng-container>
  </mat-menu>
</div>

<!-- REUSABLE TEMPLATE | CUSTOM PRESET MENU -->
<ng-template
  #customPresetMenu
  let-filter="filter"
>
  <div class="drop-list-actions">
    <button class="filters-presets-action-btn filters-presets-edit">
      <mat-icon
        [svgIcon]="'edit_icon'"
        (click)="$event.preventDefault(); $event.stopPropagation(); setEditMode(filter)"
        class="monochrome-color-icon monochrome-color-icon-hover"
      ></mat-icon>
    </button>
    <button class="filters-presets-action-btn filters-presets-remove">
      <mat-icon
        [svgIcon]="'trash_icon'"
        (click)="$event.preventDefault(); $event.stopPropagation(); removeItem(filter.id)"
        class="monochrome-color-icon monochrome-color-icon-hover"
      ></mat-icon>
    </button>
  </div>
</ng-template>

<!-- REUSABLE TEMPLATE | EDITING FILTER PANEL -->
<ng-template #editField>
  <div
    (click)="$event.stopPropagation()"
    class="new-filter-wrapper"
  >
    <div class="new-filter-input">
      <div class="form-group full-width">
        <mat-form-field
          class="new-filter-name-field w-100"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            [(ngModel)]="newFilterName"
            [maxlength]="maxPresetTitleLength"
            (keydown)="$event.stopPropagation()"
            (keyup)="$event.stopPropagation()"
            (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); savePresetTitle()"
            (keydown.escape)="$event.stopPropagation(); $event.preventDefault(); resetEditMode()"
            (keydown.shift.enter)="$event.stopPropagation(); $event.preventDefault()"
            id="edit-preset-name-input"
            matInput
            type="text"
            placeholder="Preset name"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>

    <div
      (click)="$event.stopPropagation()"
      class="new-filter-actions"
    >
      <button
        (click)="$event.stopPropagation(); $event.preventDefault(); savePresetTitle()"
        mat-icon-button
        class="successful-bg btn-standard"
        aria-label="add"
      >
        <mat-icon
          [svgIcon]="'select_icon'"
          class="middle-icon successful icon-add"
        ></mat-icon>
      </button>

      <button
        (click)="$event.stopPropagation(); $event.preventDefault(); resetEditMode()"
        mat-icon-button
        class="danger-bg btn-standard primary-bg"
        aria-label="cancel"
      >
        <mat-icon
          [svgIcon]="'plus'"
          class="middle-icon danger icon-cancel"
        ></mat-icon>
      </button>
    </div>
  </div>
</ng-template>
