import { DateRangeOptionType } from '@c/shared/date-contols/models/date-range-type.enum';

export interface DateRangeFilterModel {
  from: Date;
  to: Date;
  daysCount: number;
  type: DateRangeOptionType;
}

export const getDefaultDateRangeFilterValue: () => DateRangeFilterModel = () => ({
  from: new Date(),
  to: new Date(),
  daysCount: 1,
  type: DateRangeOptionType.Lifetime
});
