import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { StorageKeys } from '@const';
import { Response } from '@core1/interface/response';
import * as utils from '@u/utils';

import { RestRequestorService } from './rest-requestor.service';

export interface ProcessingScreenConfiguration {
  startTime: string;
  timeZone: string;
  processingMinutes: number;
  processingDays: number[];
}

@Injectable({
  providedIn: 'root',
})
export class ServerDataService {
  constructor(private http: HttpClient, private restRequestorService: RestRequestorService) {}

  _get = async (key: string, ignoreCache: boolean) => {
    const { result } = ignoreCache
      ? await firstValueFrom(this.http.get<Response>(`/v2/serverData?key=${key}&ts=${Date.now()}`))
      : await this.restRequestorService.makeRequest(`${StorageKeys.ServerData}_${key}`, () =>
        firstValueFrom(this.http.get<Response>(`/v2/serverData?key=${key}`))
      );

    if (result && result.length) {
      return result[0].value;
    }

    return null;
  };

  getAsInt = async (key: string, ignoreCache: boolean = false): Promise<number> => utils.toObject(await this._get(key, ignoreCache));

  getAsObject = async <T = any>(key: string, ignoreCache: boolean = false): Promise<T> => utils.toObject(await this._get(key, ignoreCache));

  set = async (key: string, value: any) => {
    const valueToSave = typeof value === 'object' && value !== null
      ? JSON.stringify(value)
      : value;

    await firstValueFrom(this.http.post<Response>('/v2/serverData', {
        key,
        value: valueToSave,
    }));
  };
}
