import * as moment from 'moment';

import { EasternTimeZoneName, Features, TabNames } from '@const';
import { EditionsService } from '@s/editions.service';

export const toInt = (value: string): number | null => {
  if (isNullOrUndefinedOrEmpty(value)) {
    return null;
  }

  const result = parseInt(value, 10);

  if (!isNaN(result)) {
    return result;
  }
};

export const toFloat = (value: string): number | null => {
  if (isNullOrUndefinedOrEmpty(value)) {
    return null;
  }

  const result = parseFloat(value);

  if (!isNaN(result)) {
    return result;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const toObject = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToEasternTime = (date: any): moment.Moment => {
  if (date) {
    const dateISOString =
      Object.prototype.toString.call(date) === '[object Date]'
        ? date.toISOString()
        : typeof date === 'number'
          ? new Date(date * 1000).toISOString()
          : new Date(date).toISOString();

    return moment(dateISOString).parseZone().tz(EasternTimeZoneName, true);
  }

  return null;
};

export const arrayEquals = <T>(a: T[], b: T[]): boolean => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const round = (n: number, digits: number): number => {
  const k = Math.pow(10, digits);
  return Math.round(n * k) / k;
};

export const average = (numbers: number[]): number => {
  return numbers.reduce((acc, item) => acc + item, 0) / numbers.length;
};

export const convertArrayToRecord = <T>(
  items: T[],
  key: keyof T extends string | number ? keyof T : never,
): Record<string, T> => {
  if (!items || items.length === 0) {
    return {} as Record<string, T>;
  }

  return items.reduce(
    (acc, item) => {
      acc[String(item[key])] = item;
      return acc;
    },
    {} as Record<string, T>,
  );
};

export const convertArrayToMap = <T>(
  items: T[] | ReadonlyArray<T>,
  key: keyof T extends string | number ? keyof T : never,
): Map<string, T> => {
  const map = new Map<string, T>();

  items.forEach((item) => {
    map.set(String(item[key]), item);
  });

  return map;
};

export const groupArrayByKey = <T>(items: T[], key: string | number): Record<string, T[]> => {
  if (!items || items.length === 0) {
    return {} as Record<string, T[]>;
  }

  return items.reduce(
    (acc, item) => {
      const keyValue = String(item[key]);

      if (acc[keyValue]) {
        acc[keyValue] = [...acc[keyValue], item];
      } else {
        acc[keyValue] = [item];
      }

      return acc;
    },
    {} as Record<string, T[]>,
  );
};

export const formatNumberValue = (rawValue: number, fractionDigits: number = 1): string | null => {
  if (rawValue === null || Number.isNaN(Number(rawValue))) {
    return '';
  }

  if (rawValue === 0) {
    return '0';
  }

  const numberLength = Math.round(Math.abs(rawValue)).toString().length;

  if (numberLength > 12) {
    return round(rawValue / Math.pow(10, 12), fractionDigits) + 'T';
  }

  if (numberLength > 9) {
    return round(rawValue / Math.pow(10, 9), fractionDigits) + 'B';
  }

  if (numberLength > 6) {
    return round(rawValue / Math.pow(10, 6), fractionDigits) + 'M';
  }

  if (numberLength > 3) {
    return round(rawValue / Math.pow(10, 3), fractionDigits) + 'K';
  }

  return round(rawValue, 3).toString();
};

// special case for volume: values < 1000 should be with "K": "345" => "0.345K"
export const formatVolumeValue = (value: number): string => {
  return value > 0 && value < 1000 ? round(value / 1000, 3) + 'K' : formatNumberValue(value, 3);
};

export const isNullOrUndefinedOrEmpty = (value: unknown): boolean => {
  return value === null || value === undefined || value === '';
};

export const isNullOrUndefined = (value: unknown): boolean => {
  return value === null || value === undefined;
};

export const removeComma = (value: string): string => {
  if (isNullOrUndefinedOrEmpty(value)) {
    return value;
  }

  return value.replace(/,/g, '');
};

export const removeFormattedNumberShortCut = (value: string): string => {
  if (!value) {
    return '';
  }

  const cleanValue = removeComma(value);
  let shortcutValue;

  switch (cleanValue.charAt(cleanValue.length - 1)) {
    case 'K':
      shortcutValue = Math.pow(10, 3);
      break;
    case 'M':
      shortcutValue = Math.pow(10, 6);
      break;
    case 'B':
      shortcutValue = Math.pow(10, 9);
      break;
    case 'T':
      shortcutValue = Math.pow(10, 12);
      break;
    default:
      shortcutValue = 1;
  }

  // WARNING: "/\D/g" regexp also removes dots, so "1.11" will be transformed into 111 (not in 1.11 number)
  return (Number(cleanValue.replace(/\D/g, '')) * shortcutValue).toString();
};

export const transformRatioToPercentDiff = (ratio: number): number | null => {
  if (isNullOrUndefinedOrEmpty(ratio)) {
    return null;
  }

  return (ratio - 1) * 100;
};

export const sanitizeNumberString = (str: string): number | null => {
  if (!str?.length) {
    return null;
  }

  const sanitizedStr = str.replace(/[^0-9.-]+/g, '');
  const num = Number(sanitizedStr);

  return isNaN(num) ? null : num;
};

// should return TabName value depending on URL (first segment)
export const getTabNameByRoute = (urlSegments: string[]): string | null => {
  if (!urlSegments || urlSegments.length === 0) {
    return null;
  }

  // special case for calendars - return specific calendar tab
  if (urlSegments[0] === TabNames.Calendars) {
    const calendarsUrlMap = {
      ['master']: TabNames.MasterCalendar,
      ['economic']: TabNames.EconomicCalendar,
      ['dividends']: TabNames.DividendsCalendar,
      ['earnings']: TabNames.EarningsCalendar,
      ['holidays']: TabNames.HolidaysCalendar,
    };

    if (!urlSegments[1] || !calendarsUrlMap[urlSegments[1]]) {
      return TabNames.MasterCalendar;
    }

    return calendarsUrlMap[urlSegments[1]];
  }

  // currently for all tabs: tab-name value and route are the same
  // structure: url = '/' + tab-name
  // if there are exceptions - add them here
  if (Object.values(TabNames).includes(urlSegments[0])) {
    return urlSegments[0];
  }

  return null;
};

export const getUrlSegmentsByRoute = (url: string): string[] => {
  const currentLocation = url.split('?')[0];
  const urlSegments = currentLocation.split('/').filter((item) => item !== '');

  return urlSegments;
};

export const getFeatureByTabName = (activeTab: string): Features | null => {
  if (!activeTab || activeTab.length === 0) {
    return null;
  }

  const featureDetail = Object.entries(EditionsService.featuresDetails).find(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([key, featureDetail]) => featureDetail.tabName === activeTab,
  );

  return featureDetail && featureDetail.length ? (featureDetail[0] as Features) : null;
};

export const isIntegerNumber = (value: number): boolean =>
  !isNullOrUndefined(value) && !isNaN(value) && Number.isInteger(value);

export const asyncFilter = async <T>(array: T[], predicate): Promise<T[]> => {
  const results = await Promise.all(array.map(predicate));
  return array.filter((_v, index) => results[index]);
};
