import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Themes } from '@const';
import { ObservableService } from '@s/observable.service';

@Component({
  selector: 'app-simple-modal-header',
  templateUrl: './simple-modal-header.component.html',
  styleUrls: ['./simple-modal-header.component.scss']
})
export class SimpleModalHeaderComponent implements OnInit {

  @Input() titleText: string = '';
  @Input() tooltipText: string = '';
  @Output() close = new EventEmitter();

  get crossSvg() {
    const theme = this.observableService.theme.value;
    return `assets/img/newImg/Close-p${theme === Themes.Dark ? '-white' : ''}.svg`;
  }

  constructor(
    private observableService: ObservableService,
  ) { }

  ngOnInit(): void {
  }

}
