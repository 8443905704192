<div class="top-alert" id="top-alert"
     *ngIf="showBanner">
  <div class="flex">
    <i class="material-icons notify-icon">notifications_active</i>
    <div class="top-alert-text">
      <p class="alert-text-bold">A new version of PXO is available.</p>
      <p class="alert-text">Refresh to get the latest version.</p>
    </div>
  </div>
  <div class="alert-btns flex">
    <a class="ver-info-btn fill-btn" href="https://www.rockwelltrading.com/pxo-releases" target="_blank">Check what’s new</a>
    <button class="refresh-btn fill-btn" (click)="refreshPage()">
      <span class="material-icons refresh-icon">refresh</span>
      <span class="refresh-btn-text">Refresh</span>
    </button>
  </div>
  <span class="material-icons close-alert" (click)="closeBanner()">close</span>
</div>
