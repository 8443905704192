import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBaseComponent, FormDefaultsModule } from '@c/shared/forms';
import { searchFieldDebounceTimeMS } from '@const';
import { getGroup } from '@u/forms/form-generics';
import { asyncScheduler, debounceTime, filter, map, mergeAll, scheduled, takeUntil } from 'rxjs';
import { GroupsListFilterFormModel } from './models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-groups-list-filter-form',
  templateUrl: './groups-list-filter-form.component.html',
  styleUrls: ['./groups-list-filter-form.component.scss'],
  standalone: true,
  imports: [CommonModule, FormDefaultsModule]
})
export class GroupsListFilterFormComponent extends FormBaseComponent<GroupsListFilterFormModel> implements OnInit {

  @Output() formModelChanged: EventEmitter<GroupsListFilterFormModel> = new EventEmitter<GroupsListFilterFormModel>()

  @Input() public override set value(v: GroupsListFilterFormModel) {
    if (v) {
      this.startValue = v;
      this.form.setValue(v, { emitEvent: false });
    }
  }

  public get value(): GroupsListFilterFormModel | null {
    return super.value;
  }

  constructor() {
    super(getGroup<GroupsListFilterFormModel>({
      symbol: { v: '' },
    }));
    scheduled([
      this.field('symbol').valueChanges.pipe(
        debounceTime(searchFieldDebounceTimeMS),
      )
    ], asyncScheduler)
      .pipe(
        mergeAll(),
        map(() => this.value),
        filter((value) => value !== null),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (value) => {
          const model: GroupsListFilterFormModel = { ...value, symbol: (value.symbol || '').toUpperCase() }
          this.formModelChanged.emit(model);
        },
      })

  }

  ngOnInit(): void {

  }

  protected clearSymbol(): void {
    this.field('symbol').setValue('');
  }
}
