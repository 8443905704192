import { ConnectedPosition } from "@angular/cdk/overlay";
import { defaultLiveDataColumns } from "./live-data.constants";

export const tradingLogQuotesOverlayPositions: ConnectedPosition[] = [
  // tooltip is under
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 0,
  },
  // tooltip is above
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: 0,
  },
];

export const tradingLogQuotesIconTooltips: Record<string, string> = {
  enabled: "Options premium. Show real-time or last Bid / Ask (if the market is closed).",
  disabled: "Complete the following fields to get premium: \n - Symbol \n - Expiration"
};

export const datesColumnsNames: string[] = [
  'Date',
  'Expiration',
  'Expired Date'
];

export const tradingLogDefaultLiveDataColumns = defaultLiveDataColumns;
export const tradingLogCallLiveDataColumns = ['strike', 'bid', 'ask', 'delta'];
