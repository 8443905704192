import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';
import { WeeksSelectorModule } from '@c/shared/weeks-selector/weeks-selector.module';
import { EconomicCalendarModalComponent } from './economic-calendar-modal.component';
import { LinkInputComponent } from './link-input/link-input.component';
import { AskRockyChatButtonComponent } from '@c/shared/ask-rocky-chat-button/ask-rocky-chat-button.component';


@NgModule({
  declarations: [
    EconomicCalendarModalComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FormDefaultsModule,
    WeeksSelectorModule,
    MatRippleModule,
    MatTooltipModule,
    LinkInputComponent,
    AskRockyChatButtonComponent
  ],
  exports: [EconomicCalendarModalComponent]
})
export class EconomicCalendarModalModule { }
