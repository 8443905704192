import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { PinnedOrderPanelMinWidth, UserSettings } from '@const';
import { TradingPanelOrderComponent } from '@c/trading-panel-order';
import { DialogsService } from '@s/common';
import { TradingPanelClientEvent } from '@mod/trading-panel/trading-panel.model';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';

@Component({
  standalone: true,
  selector: 'app-trading-panel-order-panel',
  templateUrl: './trading-panel-order-panel.component.html',
  styleUrls: ['./trading-panel-order-panel.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    TradingPanelOrderComponent,
    MatDialogModule,
    MatTooltipModule,
  ]
})
export class TradingPanelOrderPanelComponent implements OnInit, OnDestroy {

  protected orderInput: TradingOrderInput;
  protected isUnpinEnabled = true;
  protected showResetToDefault = true;
  protected isPanelOrderPinned = true;

  private _subscriptions = new Subscription();
  private _width: number = window.innerWidth;
  private _initiateOrderModalByResize = false;

  constructor(
    private _dialogsService: DialogsService,
    private _brokerAuthenticationService: BrokerAuthenticationService,
    private _observableService: ObservableService,
    private _userDataService: UserDataService,
  ) { }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this._width = event.target.innerWidth;
    this.handlePinnedOrderModal();
  }

  ngOnInit(): void {
    this.handlePinnedOrderModal();

    this.orderInput = this._observableService.tradingPanelOrderInput.getValue();
    this.showResetToDefault = !!this._brokerAuthenticationService.getTradierAuthenticationData()?.accessToken;

    this._subscriptions.add(
      this._observableService.tradingPanelOrderInput.subscribe((orderInput: TradingOrderInput) => {
        // Publish order input changed event only if order input is not null,
        // because if order input is null then the pinned panel will be closed
        if (!orderInput) {
          return;
        }

        this.orderInput = orderInput;
        this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderInputDataUpdated);
      })
    );

    this._subscriptions.add(
      this._observableService.tradingPanelClientEvent.subscribe((clientEvent: TradingPanelClientEvent) => {
        if (clientEvent === TradingPanelClientEvent.OrderCreated) {
          this.isUnpinEnabled = false;
          this.showResetToDefault = false;
        } else if (clientEvent === TradingPanelClientEvent.OrderInputDataUpdated) {
          this.isUnpinEnabled = true;
          this.showResetToDefault = !!this._brokerAuthenticationService.getTradierAuthenticationData()?.accessToken;
        }
      })
    );

    this._subscriptions.add(
      this._brokerAuthenticationService.TradierAuthenticationData.subscribe(() => {
        const authData = this._brokerAuthenticationService.getTradierAuthenticationData();
        this.showResetToDefault = !!authData.accessToken;
      }));

    this._subscriptions.add(
      this._observableService.mySettings.subscribe((mySettings) => {
        const { tradier_flag_ifs, tradier_flag_pxo } = mySettings;
        const showOrderPanel = !!tradier_flag_ifs && !!tradier_flag_pxo;
        if (!showOrderPanel) {
          this.close();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._observableService.tradingPanelOrderInputState.next(null);
    this._subscriptions.unsubscribe();
  }

  protected async unpinOrderModal(): Promise<void> {
    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderPanelPinChanging);
    await this._userDataService.set(UserSettings.TradingPanelOrderModalPinned, false);
    this.isPanelOrderPinned = false;
    this.close(true);

    this._dialogsService.openTradingPanelOrderDialog(this.orderInput);
  }

  protected resetToDefault() {
    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.ResetOrderToDefault);
  }

  protected close(isPinChange?: boolean) {
    if (!isPinChange) {
      this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderPanelClosing);
    }

    this._observableService.tradingPanelOrderInput.next(null);
  }

  private handlePinnedOrderModal() {
    if (this._width > PinnedOrderPanelMinWidth || this._initiateOrderModalByResize) {
      return;
    }

    this._initiateOrderModalByResize = true;
    this.unpinOrderModal();
  }
}
