import { ComparerFunctionType } from '@t/common/comparer-function.type';

export function getNumberComparer<T>(getField: (o: T) => number, subComparer?: ComparerFunctionType<T>): ComparerFunctionType<T> {
  return (a: T, b: T) => {
    const valA = getField(a);
    const valB = getField(b);
    if (valA !== valB) {
      return valA - valB;
    }
    else{
      return subComparer ? subComparer(a,b) : 0;
    }
  }
}

export function getNumberComparerDesc<T>(getField: (o: T) => number, subComparer?: ComparerFunctionType<T>): ComparerFunctionType<T> {
  return (a: T, b: T) => {
    const valA = getField(a);
    const valB = getField(b);
    if (valA !== valB) {
      return valB - valA;
    }
    else{
      return subComparer ? subComparer(a,b) : 0;
    }
  }
}
