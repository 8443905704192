import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EditionsService } from '@s/editions.service';
import { сaesarEncypt } from '@u/video-hub/video-hub-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoHubInterceptor implements HttpInterceptor {
  constructor(private editionsService: EditionsService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userAccessLevel = this.editionsService.getUserAccessLevel();
    const updatedReq = req.clone({
      url: req.url.replace('${userEdition}', сaesarEncypt(userAccessLevel)),
    });
    return next.handle(updatedReq);
  }
}
