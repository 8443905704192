import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThemesNumValues, UserAccessType } from '@const';
import { Response } from '@core1/interface';

import { UserDataService } from './user-data.service';

type Bit = 0 | 1;

export interface IMySettings {
  first_name: string;
  last_name: string;
  show_mm_trades: Bit;
  theme: ThemesNumValues;
  access_type: UserAccessType;
  tradier_flag_pxo: Bit;
  tradier_flag_ifs: Bit;
  reset_flag_pxo: Bit;
  reset_flag_wheel: Bit;
  reset_flag_wtf: Bit;
  reset_flag_stock_screener: Bit;
  reset_flag_dividends: Bit;
  reset_flag_short_selling_stocks: Bit;
  reset_flag_shorting_stocks_scanner: Bit;
  user_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    private http: HttpClient,
    private userDataService: UserDataService
  ) {
  }

  get: () => Promise<IMySettings> = async () => {
    const {result} = await this.http.get<Response<IMySettings[]>>('/v2/userSettings').toPromise();

    return result && result.length
      ? result[0]
      : null;
  };

  update = async (
    reset_flag_pxo,
    reset_flag_wheel,
    reset_flag_wtf,
    reset_flag_stock_screener,
    reset_flag_dividends,
    reset_flag_short_selling_stocks,
    reset_flag_shorting_stocks_scanner,
    tradier_flag_pxo,
  ) => {
    const { result } = await this.http.post<Response>('/v2/userSettings', {
      reset_flag_pxo,
      reset_flag_wheel,
      reset_flag_wtf,
      reset_flag_stock_screener,
      reset_flag_dividends,
      reset_flag_short_selling_stocks,
      reset_flag_shorting_stocks_scanner,
      tradier_flag_pxo
    }).toPromise();

    return result;
  };

  updateTheme = async (theme: ThemesNumValues) => {
    const {result} = await this.http.post<Response>('/v2/userSettings/updateTheme', {
      theme
    }).toPromise();

    return result;
  };

  updateThemeV1 = async (theme: ThemesNumValues) => {
    const {result} = await this.http.put<Response>('/users/theme-setting', {
      theme
    }).toPromise();

    return result;
  };

  async changePassword({currentPassword, newPassword, confirmNewPassword}) {
    const user = this.userDataService.getUser();
    return await this.http.put<Response>(`/v2/users/${ user.userId }/password`, {
      currentPassword, newPassword, confirmNewPassword
    }).toPromise().catch((e) => {
      if (e.error) {
        return e.error;
      }
    });

  }
}
