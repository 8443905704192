<div class="support-modal">
  <div mat-dialog-title class="modal-title">
    <h2>Grant Access</h2>
    <button mat-icon-button mat-dialog-close (click)="close()" class="popup-close-btn" tabindex="-1">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon">
      </mat-icon>
    </button>
  </div>
  <p class="modal-subtitle">Grant access to PXO website under the following user accounts.</p>

  <div mat-dialog-content class="modal-content">
    <div class="users-container left">
      <p class="users-container-title">Access these members only</p>
      <div class="userListTbl">
        <table class="users-list-table">
          <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody *ngIf="!supportedUsersLoading">
          <ng-container
                  *ngFor=" let user of supportedUsers; let i = index ">
            <tr
                    (click)="selectUserToRemove(user)"
                    [ngClass]="{'selected': isSelected(user, selectedToRemoveUserIds)}"
            >
              <td>
                {{ user?.first_name }}
              </td>
              <td>
                {{ user?.last_name }}
              </td>
              <td>
                {{ user?.email }}
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="supportedUsersLoading">
          <tr>
            <td colspan="8">
              <div class="loading-container">
                <mat-spinner class="blue-spinner" [diameter]="50"></mat-spinner>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="user-controls">
      <button mat-button mat-stroked-button class="secondary-btn secondary-bg default-bg small-btn" type="button" tabindex="-1"
              (click)="add(true)"
              [disabled]="isProcessing || allUsersLoading || supportedUsersLoading || !allUsers.length || !hasAnyUsersToAdd()">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>

      <button mat-button mat-stroked-button class="secondary-btn secondary-bg default-bg small-btn" type="button" tabindex="-1"
              (click)="add(false)"
              [disabled]="isProcessing || allUsersLoading || supportedUsersLoading || !selectedToAddUserIds.length">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <button mat-button mat-stroked-button class="secondary-btn secondary-bg default-bg small-btn" type="button" tabindex="-1"
              (click)="remove(true)"
              [disabled]="isProcessing || allUsersLoading || supportedUsersLoading || !supportedUsers.length">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>

      <button mat-button mat-stroked-button class="secondary-btn secondary-bg default-bg small-btn" type="button" tabindex="-1"
              (click)="remove(false)"
              [disabled]="isProcessing || allUsersLoading || supportedUsersLoading || !selectedToRemoveUserIds.length">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div class="users-container right">
      <p class="users-container-title">All members</p>
      <div class="user-search-bar" [formGroup]="userListForm">
        <div class="search-boxs">
          <input type="text" spellcheck="false" formControlName="filter"
                 placeholder="Search by email, first or last name"/>
          <a href="javascript:void(0)">
            <img class="light-theme-img" src="assets/img/newImg/Seach.svg" title="edit"/>
            <img class="black-theme-img" src="assets/img/newImg/Seach-white.svg" title="edit"/>
          </a>
        </div>
      </div>

      <div class="userListTbl">
        <table class="users-list-table">
          <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
          </tr>
          </thead>
          <tbody *ngIf="!allUsersLoading">
          <ng-container *ngFor=" let user of allUsers | paginate : allUsersPagination; let i = index ">
            <tr
                    (click)="selectUserToAdd(user)"
                    [ngClass]="{'selected': isSelected(user, selectedToAddUserIds)}"
                    [ngClass]="{'supported': isSupported(user)}"
            >
              <td>
                {{ user?.first_name }}
              </td>
              <td>
                {{ user?.last_name }}
              </td>
              <td>
                {{ user?.email }}
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="allUsersLoading">
          <tr>
            <td colspan="8">
              <div class="loading-container">
                <mat-spinner class="blue-spinner" [diameter]="50"></mat-spinner>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="pxo-pagination" *ngIf="allUsersPagination.totalItems">
        <pagination-controls id="allUsersPagination"
                             (pageChange)="allUsersPageNumber$.next($event)"></pagination-controls>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="action-buttons">
    <button mat-raised-button color="primary" (click)="close()" [disabled]="isProcessing">Close</button>
  </div>
</div>
