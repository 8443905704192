import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Response } from '@core1/interface';
import { RestRequestorService } from './rest-requestor.service';
import { Positions, Signals, StorageKeys, WatchlistType } from '@const';

export interface IWatchlistData {
  country_code: string;
  company_name: string;
  created_date: Date;
  id: number;
  position: Positions;
  security_id: number;
  signal: Signals | null;
  strategy_id: number;
  symbol: string;
}

@Injectable({
  providedIn: 'root'
})
export class WatchlistDataService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService
  ) {
  }

  insert = async (security_id: number, type: WatchlistType) => {
    const { result } = await this.http.post<Response>('/v2/watchlistData', {
      security_id,
      type
    }).toPromise();

    return result;
  };

  batchInsert = async (securities) => {
    const { result } = await this.http.post<Response>('/v2/watchlistData/batch_insert', {
      securities
    }).toPromise();

    return result;
  };

  public get = async (type: WatchlistType, ignoreCache: boolean = false): Promise<IWatchlistData[]> => {
    const { result } = await this.restRequesterService.makeRequest(
      StorageKeys.WatchlistData,
      () => firstValueFrom(this.http.get<Response<IWatchlistData[]>>(`/v2/watchlistData/${type}`)),
      ignoreCache
    );

    return result;
  };

  remove = async (id: number) => {
    const { result } = await this.http.delete<Response>(`/v2/watchlistData/${id}`).toPromise();

    return result;
  };
}
