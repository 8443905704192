import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import * as moment from 'moment';

import { ObservableService } from '@s/observable.service';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownTimerComponent implements OnInit {
  protected milliSecondsInASecond = 1000;
  protected hoursInADay = 24;
  protected minutesInAnHour = 60;
  protected SecondsInAMinute = 60;

  protected timeDifference: number | null = null;
  protected secondsToFinish: number | null = null;
  protected minutesToFinish: number | null = null;
  protected hoursToFinish: number | null = null;
  protected daysToFinish: number | null = null;

  private finishTime: number = null;
  private onePercent: number = null;

  private oneSecondIntervalSubscription: Subscription | null  = null;
  private subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private observableService: ObservableService
  ) { }

  ngOnInit() {
    this.finishTime = this.observableService.finishNumberCrunchingTime.getValue();
    this.calculateOnePercent();

    this.subscriptions.add(
      this.observableService.finishNumberCrunchingTime.subscribe((finishTime) => {
        this.finishTime = finishTime;
        this.calculateOnePercent();
      })
    );

    this.oneSecondIntervalSubscription = interval(1000)
      .subscribe(() => this.getTimeDifference(this.onePercent));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.cleanUp();
  }

  private calculateOnePercent(): void {
    this.onePercent = (this.finishTime - this.observableService.startNumberCrunchingTime.getValue()) / 1000 / 100;
  }

  private cleanUp(): void {
    if (this.oneSecondIntervalSubscription) {
      this.oneSecondIntervalSubscription.unsubscribe();
    }
  }

  private getTimeDifference(onePercent: number) {
    this.timeDifference = this.finishTime - moment.utc().toDate().getTime();

    if (this.timeDifference < 0) {
      this.observableService.numberCrunchingCountDownTimerFinished.next(true);
      this.cleanUp();

      return;
    }

    this.allocateTimeUnits(this.timeDifference);
    this.updateCircle(onePercent, this.timeDifference);

    this.changeDetectorRef.detectChanges();
  }

  private allocateTimeUnits(timeDifference: number): void {
    this.secondsToFinish = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToFinish = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToFinish = Math.floor(
      (timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay
    );
    this.daysToFinish = Math.floor(
      (timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay)
    );
  }

  private updateCircle(onePercent: number, timeDifference: number): void {
    const circle = document.getElementById('bar');
    const r: any = circle.getAttribute('r');

    const circleLength = Math.PI * r * 2;
    let val = 100 - (timeDifference / 1000 / onePercent);

    if (val < 0) {
      val = 0;
    }

    if (val > 100) {
      val = 100;
    }

    const pct = ((100 - val) / 100) * circleLength;
    circle.setAttribute('stroke-dashoffset', pct.toString());
  }
}
