<div class="rocky-icon-container">
  <mat-icon
    *appHideForUnavailableFeature="features.IBot"
    [class.always-visible]="showRockyIconAlways$ | async"
    [svgIcon]="'rocky-chat-btn'"
    class="ask-rocky-chat-button rocky-icon default-icon"
    role="button"
    (click)="onClick()"
    [matTooltip]="'Ask Rocky'"
    [matTooltipHideDelay]="0"
  ></mat-icon>
  <mat-icon
    *appHideForUnavailableFeature="features.IBot"
    [class.always-visible]="showRockyIconAlways$ | async"
    [svgIcon]="'cody-chat'"
    class="ask-rocky-chat-button rocky-icon hovered-icon"
    role="button"
    (click)="onClick()"
    [matTooltip]="'Ask Rocky'"
    [matTooltipHideDelay]="0"
  ></mat-icon>
</div>
