<div *ngIf="option?.itm || option?.atm"
    class="col main-col" style="max-width: 100%;">
    <div class="card">
        <div class="risk-reward-header">
            <span>{{tradingStrategy.name}}</span>
        </div>
        <div [ngStyle]="{'min-height':otherOption?.is_frozen ? !option?.atm || !option?.itm  ? '58px' : '42px' : 'auto'}"
            class="option-attention-msg">
            <div id="first-option-attention" *ngIf="option?.is_frozen == 1" class="info-message">
              <div>
                <ng-container [ngTemplateOutlet]="infoIcon"></ng-container>
              </div>
              The suggested entry and option calculations are based on the stock Signal Price.
            </div>
        </div>
        <div [ngStyle]="{'display': !option?.atm || !option?.itm  ? 'inline-block' : 'flex', 'width': !option?.atm || !option?.itm  ? '100%' : ''}"
            class="buy-put-row p-b-16">
            <div [ngStyle]="{'max-width':!option?.atm ? 'unset' : '', 'border-right': !option?.atm ? '0px' : ''}"
                *ngIf="option?.itm" class="col buy">
                <div class="title">
                    <div class="s1">ITM</div>
                </div>
                <div class="price-range">
                    <div class="title">
                        <div class="cap">Strike</div>
                        <div class="s1">{{formatStrike(option?.itm?.strike)}}</div>
                    </div>
                    <div class="contract-maxentry row">
                        <div class="contract col">
                            <div class="cap">Contracts</div>
                            <div class="a-center">
                                <div class="contract-number">
                                    {{getContracts(option?.itm?.risk_amount)}}
                                </div>
                            </div>
                        </div>
                        <div class="max-entry col">
                            <div class="cap">Max Entry</div>
                            <div class="s1 p-0">{{formatPrice(option?.itm?.max_entry_price, 2)}}</div>
                        </div>
                    </div>
                    <ng-container [ngTemplateOutlet]="placeOrder" [ngTemplateOutletContext]="{
                      option: option,
                      entry: option.itm.max_entry_price,
                      stop: option.itm.option_at_stop,
                      riskAmount: option.itm.risk_amount,
                      strike: option.itm.strike }">
                    </ng-container>
                </div>
                <div class="bid-ask row">
                    <div class="bid col">
                        <div class="cap dark">BID</div>
                        <div [ngClass]="option?.itm?.ask - option?.itm?.bid >= 0.5 ? 'red':''" class="rate">
                            {{formatPrice(option?.itm?.bid, 2)}}</div>
                    </div>
                    <div class="ask col">
                        <div class="cap dark">ASK</div>
                        <div [ngClass]="option?.itm?.ask - option?.itm?.bid >= 0.5 ? 'red':''" class="rate">

                            <svg xmlns="http://www.w3.org/2000/svg"
                                *ngIf="option?.itm?.ask - option?.itm?.bid >= 0.5 && !isPrint" class="bid-ask-help"
                                [matTooltip]="'Bid and Ask are red, because price difference is more than $0.50'"
                                [matTooltipPosition]="'below'" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path
                                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                            </svg>
                            {{formatPrice(option?.itm?.ask, 2)}}
                        </div>
                    </div>
                </div>
                <div class="risk-reward-text"
                    [ngClass]="option?.itm?.reward_ratio >= option?.atm?.reward_ratio ? 'l-yellow' : ''">
                    <div>
                        <p>1</p>
                        <span>risk</span>
                    </div>
                    <div>
                        <p>{{formatDecimal(option?.itm?.reward_ratio)}}</p>
                        <span>reward</span>
                    </div>
                </div>
                <div class="risk-reward-amount">
                    <div>
                        <span>{{formatPrice(option?.itm?.risk_amount, 2)}}</span>
                    </div>
                    <div>
                        <span>{{formatPrice(option?.itm?.reward_amount, 2)}}</span>
                    </div>
                </div>
                <div class="risk-reward-ratio row">
                    <div class="risk-reward-ratio-left col">
                        <div class="cap light p-t-16">Option at Stop</div>
                        <div class="cap dark">{{formatPrice(option?.itm?.option_at_stop, 2)}}</div>
                    </div>
                    <div class="risk-reward-ratio-right col">
                        <div class="cap light p-t-16">Option at Target</div>
                        <div class="cap dark">{{formatPrice(option?.itm?.option_at_target, 2)}}</div>
                    </div>
                </div>
                <div *ngIf="isOptionGreeks" class="delta-theta row">
                    <div class="delta col">
                        <div class="cap light">Delta</div>
                        <div class="cap dark">{{formatDecimal(option?.itm?.delta, 4)}}</div>
                    </div>
                    <div class="theta col">
                        <div class="cap light">Theta</div>
                        <div class="cap dark">{{formatDecimal(option?.itm?.theta / 100.0, 4)}}</div>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{'max-width': !option?.itm ? 'unset' : '', 'border-right': !option?.itm ? '0px' : ''}"
                *ngIf="option?.atm?.strike" class="col put">
                <div class="title">
                    <div class="s1">ATM</div>
                </div>
                <div class="price-range">
                    <div class="title">
                        <div class="cap">Strike</div>
                        <div class="s1">{{formatStrike(option?.atm?.strike)}}</div>
                    </div>
                    <div class="contract-maxentry row">
                        <div class="contract col">
                            <div class="cap">Contracts</div>
                            <div class="a-center">
                                <div class="contract-number">
                                    {{getContracts(option?.atm?.risk_amount)}}
                                </div>
                            </div>
                        </div>
                        <div class="max-entry col">
                            <div class="cap">Max Entry</div>
                            <div class="s1 p-0">{{formatPrice(option?.atm?.max_entry_price, 2)}}</div>
                        </div>
                    </div>
                    <ng-container [ngTemplateOutlet]="placeOrder" [ngTemplateOutletContext]="{
                      option: option,
                      entry: option.atm.max_entry_price,
                      stop: option.atm.option_at_stop,
                      riskAmount: option.atm.risk_amount,
                      strike: option.atm.strike }">
                    </ng-container>

                </div>
                <div class="bid-ask row">
                    <div class="bid col">
                        <div class="cap dark">BID</div>
                        <div [ngClass]="option?.atm?.ask - option?.atm?.bid >= 0.5 ? 'red':''" class="rate">
                            {{formatPrice(option?.atm?.bid, 2)}}</div>
                    </div>
                    <div class="ask col">
                        <div class="cap dark">ASK</div>
                        <div [ngClass]="option?.atm?.ask - option?.atm?.bid >= 0.5 ? 'red':''" class="rate">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                *ngIf="option?.atm?.ask - option?.atm?.bid >= 0.5 && !isPrint" class="bid-ask-help"
                                [matTooltip]="'Bid and Ask are red, because price difference is more than $0.50'"
                                [matTooltipPosition]="'below'" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path
                                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                            </svg>
                            {{formatPrice(option?.atm?.ask, 2)}}
                        </div>
                    </div>
                </div>
                <div class="risk-reward-text"
                    [ngClass]="option?.atm?.reward_ratio >= option?.itm?.reward_ratio ? 'l-yellow' : ''">
                    <div>
                        <p>1</p>
                        <span>risk</span>
                    </div>
                    <div>
                        <p>{{formatDecimal(option?.atm?.reward_ratio)}}</p>
                        <span>reward</span>
                    </div>
                </div>
                <div class="risk-reward-amount">
                    <div>
                        <span>{{formatPrice(option?.atm?.risk_amount, 2)}}</span>
                    </div>
                    <div>
                        <span>{{formatPrice(option?.atm?.reward_amount, 2)}}</span>
                    </div>
                </div>
                <div class="risk-reward-ratio row">
                    <div class="risk-reward-ratio-left col">
                        <div class="cap light p-t-16">Option at Stop</div>
                        <div class="cap dark">{{formatPrice(option?.atm?.option_at_stop, 2)}}</div>
                    </div>
                    <div class="risk-reward-ratio-right col">
                        <div class="cap light p-t-16">Option at Target</div>
                        <div class="cap dark">{{formatPrice(option?.atm?.option_at_target, 2)}}</div>
                    </div>
                </div>
                <div *ngIf="isOptionGreeks" class="delta-theta row">
                    <div class="delta col">
                        <div class="cap light">Delta</div>
                        <div class="cap dark">{{formatDecimal(option?.atm?.delta, 4)}}</div>
                    </div>
                    <div class="theta col">
                        <div class="cap light">Theta</div>
                        <div class="cap dark">{{formatDecimal(option?.atm?.theta / 100.0, 4)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #placeOrder let-option="option" let-entry="entry" let-stop="stop" let-riskAmount="riskAmount" let-strike="strike">
  <div class="row trade-btn-block" *ngIf="placeOrderCanBeShown && !isPrint">
      <a class="trade-btn"
        (click)="onPlaceOrderClick(entry, strike, riskAmount)"
        >
        Place Order
    </a>
  </div>
</ng-template>

<ng-template #infoIcon>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="7.5" stroke="var(--info-text-color)"/>
    <path d="M10 5.83325V10.8333" stroke="var(--info-text-color)"/>
    <path d="M10 12.5V13.3333" stroke="var(--info-text-color)"/>
  </svg>
</ng-template>
