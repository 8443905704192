import {Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-error-message',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() message = 'Operation failed.';
  @Input() additionalMessage = 'Please try again later.';
  constructor() { }

  ngOnInit(): void {
  }

}
