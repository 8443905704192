<div
  class="task-list-wrapper"
  (mouseover)="onHoverTask(null)"
  (mouseleave)="onHoverTask(null)"
>
  <div
    cdkDropList
    [cdkDropListData]="tasksList"
    (cdkDropListDropped)="drop($event)"
    (cdkDropListEntered)="onDragStart()"
    class="task-list scroll-style"
  >
    <div
      cdkDrag
      (cdkDragStarted)="onDragStart()"
      cdkDragLockAxis="y"
      class="task-item"
      *ngFor="let task of tasksList; trackBy: trackByFn"
      [ngClass]="{ 'done': task.status === taskStatuses.completed }"
    >
      <mat-icon
        cdkDragHandle
        [svgIcon]="'drag-handler'"
        (mousedown)="onDragStart()"
        class="drag-handler"
      ></mat-icon>
      <mat-checkbox
        color="primary"
        disableRipple
        [checked]="task.status === taskStatuses.completed"
        (change)="onChangeStatus(task, $event.checked)"
        class="middle-checkbox pxo-checkbox mat-checkbox-hover-disabled"
      ></mat-checkbox>

      <div
        class="task-form"
        (click)="editTaskContent(task)"
        [ngClass]="{ active: activeTask?.order === task.order }"
        (mouseover)="$event.stopPropagation(); onHoverTask(task)"
      >
        <!-- task-title includes "white-space: pre-wrap;" style property -->
        <!-- to avoid spaces before and after content - content is placed in one line with tags -->
        <span
          class="task-title"
          *ngIf="activeTask?.order !== task.order && hoveredTask?.order !== task.order"
        >{{ task.content }}</span>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          [id]="'task-textarea_' + task.order"
          *ngIf="activeTask?.order === task.order || hoveredTask?.order === task.order"
          (input)="updateTask(task, $event)"
          (keydown.backspace)="onKeyDown(task, 'Backspace', $event)"
          (keydown.enter)="onKeyDown(task, 'Enter', $event)"
          (keydown.shift.enter)="$event.preventDefault()"
          (focusout)="onTextAreaFocusOut()"
          (paste)="onPaste(task, $event)"
          type="text"
          maxlength="250"
          class="form-field"
          [ngClass]="{ 'hide-placeholder': activeTask?.order !== task.order }"
          placeholder="Start typing your task"
          [value]="task.content"
        ></textarea>
      </div>

      <button
        mat-icon-button
        (click)="deleteTask(task)"
        class="popup-close-btn"
        tabindex="-1"
      >
        <mat-icon [svgIcon]="'close'"></mat-icon>
      </button>
    </div>
  </div>

  <div class="add-task" id="add-task-action">
    <button
      mat-button
      color="primary"
      (click)="onAddTask()"
      class="primary add-item-button content-center"
    >
      <mat-icon [svgIcon]="'plus_large'" class="middle-icon"></mat-icon>
      Add Task
    </button>
  </div>
</div>
