import * as d3 from 'd3';
import { HierarchyNode } from 'd3';
import * as _ from 'lodash';
import { HeatmapModel } from '@mod/data/sp-500.model';
import { D3ChartNode, HeatmapElement, TextElementData } from '@c/stock-heatmap/stock-heatmap.model';
import { getNumberComparerDesc } from '@u/comparers';
import { ConnectedPosition } from '@angular/cdk/overlay';

export const TOP_LEVEL_TREE_NAME = 'market';
export const SECTOR_LABEL_HEIGHT = 18;
export const INDUSTRY_LABEL_HEIGHT = 14;
export const MIN_INDUSTRY_WIDTH = 44;
export const IN_CELL_PADDING = 2;
export const PLOT_BORDER_PADDING = 24;
export const tooltipPositions: ConnectedPosition[] = [
  // tooltip is under
  {
    originX: 'center',
    originY: 'center',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 50,
  },
  // tooltip is on the left
  {
    originX: 'center',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -30,
  },
  // tooltip is on the right
  {
    originX: 'center',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 30,
  }
];

export const chartColorSchema = d3.scaleThreshold<number, string>()
  .domain([-3, -2, -1, -0.5, -0.09, 0.09, 0.5, 1, 2, 3])
  .range([
    'var(--heatmap-chart-extreme-negative)',
    'var(--heatmap-chart-very-strong-negative)',
    'var(--heatmap-chart-strong-negative)',
    'var(--heatmap-chart-slightly-negative)',
    'var(--heatmap-chart-tiny-negative)',
    'var(--heatmap-chart--neutral)',
    'var(--heatmap-chart-tiny-positive)',
    'var(--heatmap-chart-slightly-positive)',
    'var(--heatmap-chart-strong-positive)',
    'var(--heatmap-chart-very-strong-positive)',
    'var(--heatmap-chart-extreme-positive)',
  ]);

export const tooltipColorSchema = d3.scaleThreshold<number, string>()
  .domain([-3, -2, -1, -0.5, -0.09, 0.09, 0.5, 1, 2, 3])
  .range([
    'var(--heatmap-chart-extreme-negative)',
    'var(--heatmap-chart-very-strong-negative)',
    'var(--heatmap-chart-strong-negative)',
    'var(--heatmap-chart-slightly-negative)',
    'var(--heatmap-chart-tiny-negative-tooltip)',
    'var(--heatmap-chart--neutral-tooltip)',
    'var(--heatmap-chart-tiny-positive-tooltip)',
    'var(--heatmap-chart-slightly-positive)',
    'var(--heatmap-chart-strong-positive)',
    'var(--heatmap-chart-very-strong-positive)',
    'var(--heatmap-chart-extreme-positive)',
  ]);

enum SECTORS {
  TECHNOLOGY = 'TECHNOLOGY',
  FINANCIAL_SERVICES = 'FINANCIAL SERVICES',
  COMMUNICATION_SERVICES = 'COMMUNICATION SERVICES',
  HEALTHCARE = 'HEALTHCARE',
  CONSUMER_DEFENSIVE = 'CONSUMER DEFENSIVE',
  INDUSTRIALS = 'INDUSTRIALS',
  CONSUMER_CYCLICAL = 'CONSUMER CYCLICAL',
  REAL_ESTATE = 'REAL ESTATE',
  UTILITIES = 'UTILITIES',
  ENERGY = 'ENERGY',
  BASIC_MATERIALS = 'BASIC MATERIALS'
}

const SECTORS_ORDER = [
  SECTORS.TECHNOLOGY,
  SECTORS.FINANCIAL_SERVICES,
  SECTORS.COMMUNICATION_SERVICES,
  SECTORS.HEALTHCARE,
  SECTORS.CONSUMER_CYCLICAL,
  SECTORS.CONSUMER_DEFENSIVE,
  SECTORS.INDUSTRIALS,
  SECTORS.REAL_ESTATE,
  SECTORS.ENERGY,
  SECTORS.UTILITIES,
  SECTORS.BASIC_MATERIALS
];
const SECTORS_ORDER_COMPACT = [
  SECTORS.TECHNOLOGY,
  SECTORS.FINANCIAL_SERVICES,
  SECTORS.COMMUNICATION_SERVICES,
  SECTORS.HEALTHCARE,
  SECTORS.CONSUMER_DEFENSIVE,
  SECTORS.INDUSTRIALS,
  SECTORS.CONSUMER_CYCLICAL,
  SECTORS.REAL_ESTATE,
  SECTORS.UTILITIES,
  SECTORS.ENERGY,
  SECTORS.BASIC_MATERIALS
];

export function sortSectors(
  sectors: D3ChartNode<D3ChartNode<HeatmapElement>>[],
  compactMode: boolean
): D3ChartNode<D3ChartNode<HeatmapElement>>[] {
  const sortOrder = !compactMode ? SECTORS_ORDER : SECTORS_ORDER_COMPACT;
  return sectors.sort((a, b) =>
    (sortOrder.indexOf(a.name as SECTORS) > sortOrder.indexOf(b.name as SECTORS)) ? 1 : -1
  );
}

export function extractIndustriesFromData(sectorsData: D3ChartNode<D3ChartNode<HeatmapElement>>[]): D3ChartNode<HeatmapElement>[] {
  return sectorsData.map((sector) => {
    const children: HeatmapElement[] = (sector.children as D3ChartNode<HeatmapElement>[]).reduce((acc, industry) => {
      return [...acc, ...industry.children as HeatmapElement[]];
    }, [] as HeatmapElement[]);

    return {
      ...sector,
      children: children.sort(getNumberComparerDesc<HeatmapModel>((symbol) => symbol.market_cap))
    };
  });
}

export function selectAllButToplevel(node): boolean {
  return node.data.name !== TOP_LEVEL_TREE_NAME;
}

export function selectIndustryLevel(isCompactMode: boolean): (node) => boolean {
  return (node) => isCompactMode ? node.depth === 1 : node.depth === 2;
}

export function selectSectorLevel(node): boolean {
  return node.depth === 1;
}

export function selectSymbolLevel(node): boolean {
  return !node.children;
}

export function getPaddingTop(
  isCompactMode: boolean,
  hideSectorHeader: boolean,
  hideIndustryHeader: boolean,
): (node) => number {
  return (node) => {
    if (isCompactMode) {
      if (node.depth === 0) {
        return SECTOR_LABEL_HEIGHT;
      }

      if (node.depth === 1) {
        return INDUSTRY_LABEL_HEIGHT;
      } else {
        return Math.round(node.x1) - Math.round(node.x0) > MIN_INDUSTRY_WIDTH ? INDUSTRY_LABEL_HEIGHT + 1 : 0;
      }
    }

    if (node.depth === 0) {
      if (hideIndustryHeader) {
        return 0;
      }

      return SECTOR_LABEL_HEIGHT;
    }

    if (node.depth === 1) {
      if (hideSectorHeader) {
        return 0;
      }

      return SECTOR_LABEL_HEIGHT;
    }

    if (hideIndustryHeader) {
      return 0;
    }

    return Math.round(node.x1) - Math.round(node.x0) > MIN_INDUSTRY_WIDTH ? INDUSTRY_LABEL_HEIGHT + 1 : 0;
  };
}

export function getPaddingBottom(
  isCompactMode: boolean,
  hideSectorHeader: boolean,
  hideIndustryHeader: boolean,
): (node) => number {
  return (node) => {
    if (isCompactMode) {
      return 0;
    }

    if (node.depth === 0 && hideIndustryHeader) {
      return SECTOR_LABEL_HEIGHT;
    }

    return 0;
  };
}

export function getPaddingInner(isCompactMode: boolean): (node) => number {
  return (node) => {
    if (isCompactMode) {
      return node.depth === 0 ? 2 : 1;
    }

    switch (node.depth) {
      case 0:
        return 4;
      case 1:
        return 2;
      case 2:
        return 1;
      default:
        return 1;
    }
  };
}

export function getDomId(text: string): string {
  return text.replace(/[^A-Z]+/ig, '_');
}

export function generateSectorId(d: HierarchyNode<D3ChartNode<HeatmapElement>>): string {
  return `sector_${getDomId(d.data.name)}`;
}

export function generateIndustryId(d: HierarchyNode<D3ChartNode<HeatmapElement>>): string {
  return `industry_${getDomId(d.data.name)}`;
}

export function HeatmapElementTypeGuard(obj: HeatmapElement | D3ChartNode<HeatmapElement>): obj is HeatmapElement {
  return (obj as HeatmapElement).diff !== undefined;
}

export function D3ChartNodeTypeGuard(obj: HeatmapElement | D3ChartNode<HeatmapElement>): obj is D3ChartNode<HeatmapElement> {
  return (obj as D3ChartNode<HeatmapElement>).id !== undefined && (obj as D3ChartNode<HeatmapElement>).name !== undefined;
}

export function calculateFontSize({ width, height }: DOMRect, text: string, isDiff: boolean, compactMode: boolean): number | null {
  const minFontSize = compactMode ? 4 : 6;
  const maxFontSize = compactMode ? 24 : 32;
  const maxDiffFontSize = compactMode ? 16 : 32;
  // diff text should never be more than symbol, so we have to fit 2 lines of text
  // actual size of text element is slightly bigger than font-size, that's why in compact mode we subtract extra 30%;
  const heightMultiplier = isDiff ? height * 0.5 : height - (height / 3);
  if (heightMultiplier < minFontSize) {
    return null;
  }
  const textLen = !isDiff ? text.length : Math.min(text.length, 5);

  let charSize = Math.min((width / textLen), heightMultiplier);
  if (charSize < minFontSize) {
    return null;
  }

  // if rect is wide, we might want to have extra space to fit 2 lines of text
  if (!compactMode && width / height > 1.3 && charSize > minFontSize * 1.5) {
    charSize *= 0.75;
  }

  if (compactMode && width / height > 1 && height < charSize * 2.5 && charSize > minFontSize * 2) {
    charSize *= 0.5;
  }

  if (isDiff && charSize > maxDiffFontSize) {
    return maxDiffFontSize;
  }

  if (charSize > maxFontSize) {
    return maxFontSize;
  }

  return charSize;
}

export function isChildOverflowParentBbox(parentBBox: DOMRect, childBbox: DOMRect): boolean {
  return (childBbox.x < parentBBox.x
    || childBbox.y < parentBBox.y
    || (childBbox.x + childBbox.width) > (parentBBox.x + parentBBox.width)
    || (childBbox.y + childBbox.height) > (parentBBox.y + parentBBox.height));
}

export function cutIndustryTextForCompactMode(
  d: TextElementData,
  rect: d3.Selection<SVGGraphicsElement, SVGRectElement, any, any>,
  tspan: d3.Selection<SVGGraphicsElement, d3.HierarchyRectangularNode<HeatmapElement>, any, any>
): void {
  tspan.text('');
  let counter = 0;
  let rectBbox = rect.node().getBBox();
  let bbBoxAfterTextAdded;
  do {
    counter++;
    tspan.text(d.text.substr(0, counter));
    bbBoxAfterTextAdded = tspan.node().getBBox();
    rectBbox = rect.node().getBBox();
  }
  while (rectBbox.width >= bbBoxAfterTextAdded.width && counter < d.text.length);
  if (bbBoxAfterTextAdded.width > rectBbox.width) {
    tspan.text(tspan.text().substr(0, tspan.text().length - 1));
  }
}

export function sortIndustries(industries: D3ChartNode<HeatmapElement>[]): D3ChartNode<HeatmapElement>[] {
  const listToSort = industries.map((industry) => ({ ...industry, sum: _.sumBy(industry.children, 'market_cap') }));
  return listToSort.sort((a, b) => b.sum - a.sum).map(({ sum, ...rest }) => rest);
}

export function calculateIndustryColor(symbols: HierarchyNode<HeatmapElement>[]): string {
  if (symbols[0].data.diff === null) {
    // show neutral color if market is closed
    return chartColorSchema(0);
  }
  const diffByMarketCap = symbols.reduce((acc, { data }) => acc += data.diff * data.market_cap, 0);
  return chartColorSchema(diffByMarketCap / _.sumBy(symbols, 'data.market_cap'));
}
