import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabelModule } from '@c/common/label/label.module';
import { SectorIndustryHeatmapDetailsComponent } from '@c/shared/sector-industy-heatmap-details/sector-industry-heatmap-details.component';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import { EarningsCalendarService } from '@s/calendars/earnings-calendar.service';
import { AskRockyChatButtonComponent } from '../ask-rocky-chat-button/ask-rocky-chat-button.component';
import { SymbolDetailsPanelComponent } from './symbol-details-panel.component';

@NgModule({
  declarations: [SymbolDetailsPanelComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    SymbolFlagModule,
    LabelModule,
    EditionsSharedModule,
    SectorIndustryHeatmapDetailsComponent,
    AskRockyChatButtonComponent,
  ],
  providers: [EarningsCalendarService],
  exports: [SymbolDetailsPanelComponent],
})
export class SymbolDetailsPanelModule {}
