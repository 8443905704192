import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopMenuComponent } from '@c/top-menu/top-menu.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TradingHubSharedModule } from '@c/trading-hub/trading-hub-shared.module';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';

@NgModule({
  declarations: [TopMenuComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    FormDefaultsModule,
    MatMenuModule,
    RouterModule,
    TradingHubSharedModule,
    EditionsSharedModule,
  ],
  exports: [TopMenuComponent],
})
export class TopMenuModule { }
