import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureAvailabilityDirective } from '@core/directives/feature-availability/feature-availability.directive';
import { HideForUnavailableFeatureDirective } from '@core/directives/hide-for-unavailable-feature/hide-for-unavailable-feature.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FeatureAvailabilityDirective,
    HideForUnavailableFeatureDirective,
  ],
  exports: [
    FeatureAvailabilityDirective,
    HideForUnavailableFeatureDirective,
  ]
})
export class EditionsSharedModule { }
