import { Component, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  IChatAnswerFeedbackConfig,
  IChatAnswerFeedbackDetails,
} from '@mdl/chat-answer-feedback-modal/chat-answer-feedback-modal.model';

@Component({
  selector: 'app-chat-answer-feedback-modal',
  templateUrl: './chat-answer-feedback-modal.component.html',
  styleUrls: ['./chat-answer-feedback-modal.component.scss'],
})
export class ChatAnswerFeedbackModalComponent implements AfterViewInit {
  protected selectedFeedbackOptions = [];
  protected currentFeedbackOption = '';
  protected feedbackFormControl = new FormControl<string>('');
  protected maxContentLength = 500;

  constructor(
    private dialogRef: MatDialogRef<ChatAnswerFeedbackModalComponent, IChatAnswerFeedbackDetails>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public config: IChatAnswerFeedbackConfig,
  ) { }

  ngAfterViewInit() {
    // setTimeout is used to avoid "ExpressionChangedAfterItHasBeenCheckedError", don't remove it
    setTimeout(() => {
      const textAreaElement = this.document.getElementById('feedback-input') as HTMLTextAreaElement;

      if (textAreaElement) {
        textAreaElement.focus();
        textAreaElement.setSelectionRange(0, 0);
      }
    }, 0);
  }

  // for checkboxes, can be returned instead of radio-buttons
  protected onSetOption(option: string): void {
    if (this.selectedFeedbackOptions.includes(option)) {
      this.selectedFeedbackOptions = [...this.selectedFeedbackOptions].filter((item) => item !== option);
    } else {
      this.selectedFeedbackOptions = [...this.selectedFeedbackOptions, option];
    }
  }

  protected onPasteFeedback(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');
    const textAreaElement = event.target as HTMLTextAreaElement;
    const currentValue = textAreaElement.value;
    const selectionStart = textAreaElement.selectionStart;
    const selectionEnd = textAreaElement.selectionEnd;

    const newRawContent = currentValue.slice(0, selectionStart)
      + clipboardData
      + currentValue.slice(selectionEnd);
    const newContent = newRawContent.slice(0, this.maxContentLength);
    const prevCursorPosition = selectionEnd + clipboardData.length;
    const newCursorPosition = prevCursorPosition < newContent.length ? prevCursorPosition : newContent.length;

    this.feedbackFormControl.setValue(newRawContent.slice(0, this.maxContentLength));
    textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  protected onSubmit(): void {
    this.dialogRef.close({
      isConfirmed: true,
      selectedFeedbackOptions: [this.currentFeedbackOption],
      feedbackText: this.feedbackFormControl.value.trim().slice(0, this.maxContentLength),
    });
  }

  protected onClose(): void {
    this.dialogRef.close({
      isConfirmed: false,
      selectedFeedbackOptions: [],
      feedbackText: '',
    });
  }
}
