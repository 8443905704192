import { createMask } from '@ngneat/input-mask';

export const FILTER_VOLUME_INPUT_MASK = createMask({
  casing: 'upper',
  regex: '0^$|([0-9|,]{1,19}[(kK|mM|bB|tT)]?)',
  placeholder: '',
  showMaskOnHover: false,
  showMaskOnFocus: false,
  rightAlign: true,
  undoOnEscape: false,
});

export const FILTER_MARKET_CAP_INPUT_MASK = createMask({
  casing: 'upper',
  regex: '0^$|([0-9|,]{1,19}[(kK|mM|bB|tT)]?)',
  placeholder: '',
  showMaskOnHover: false,
  showMaskOnFocus: false,
  rightAlign: true,
  undoOnEscape: false,
});
