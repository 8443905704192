import { TradingLogTransactionStatus, TradingLogTransactionType } from '@t/trading-log';
import { TradingLogDashboardReportInfoModel } from '@mod/trading-log';

export interface OptionsSummaryCallPutInputModel {
  prem: number;
  comm: number;
  pl: number;
  transactionType: TradingLogTransactionType;
  transactionStatus: TradingLogTransactionStatus;
  transaction: TradingLogDashboardReportInfoModel;
  assignedValue?: number;
}

export type OptionsSummaryCallPutGroupInputModel = OptionsSummaryCallPutInputModel & {
  trades: number;
  usedTransactions: TradingLogDashboardReportInfoModel[];
};

export interface OptionsSummaryCommonInputModel {
  putPrem: number;
  putComm: number;
  putPl: number;
  putSellTrades: number;
  putAssignedCount: number;
  callPrem: number;
  callComm: number;
  callPl: number;
  daysCount: number;
}

export interface OptionsSummaryTableInputModel {
  common: OptionsSummaryCommonInputModel;
  calls: OptionsSummaryCallPutInputModel[];
  puts: OptionsSummaryCallPutInputModel[];
}

export interface OptionsSummaryBaseTableRowModel {
  type: string;
  premiumPrice: string;
  feesAndCommPrice: string;
  profitAndLossPrice: string;
  avgProfitAndLossPrice: string;
  trades: string;
  assigned: string;
  assignedPercent?: string;
  totalAvgPlPerDayPrice?: string;
}

export interface OptionsSummaryTotalTableRowModel extends OptionsSummaryBaseTableRowModel {
  premiumABS: number;
  feesAndCommABS: number;
  profitAndLossABS: number;
  avgProfitAndLossABS: number;
}

export interface OptionsSummaryItemTableRowModel extends OptionsSummaryBaseTableRowModel {
  premium?: number;
  feesAndComm?: number;
  profitAndLoss?: number;
  avgProfitAndLoss?: number;
  trades: string;
  transactionType?: TradingLogTransactionType;
  transactionStatus?: TradingLogTransactionStatus;
  isTradesGroup: boolean;
  usedTransactions: TradingLogDashboardReportInfoModel[];
  /** @desc Used for displaying colored bars. Feature is hidden for now */
  premiumBarPercentage?: number;
  /** @desc Used for displaying colored bars. Feature is hidden for now */
  feesAndCommBarPercentage?: number;
  /** @desc Used for displaying colored bars. Feature is hidden for now */
  profitAndLossBarPercentage?: number;
  /** @desc Used for displaying colored bars. Feature is hidden for now */
  avgProfitAndLossBarPercentage?: number;
  /** @desc Used for displaying colored bars. Feature is hidden for now */
  tradesBarPercentage?: number;
}

export const emptyOptionsSummaryCommonInputModel: OptionsSummaryCommonInputModel = {
  putPrem: 0,
  putComm: 0,
  putPl: 0,
  putSellTrades: 0,
  putAssignedCount: 0,
  callPrem: 0,
  callComm: 0,
  callPl: 0,
  daysCount: 1,
};

export const emptyTotalOptionsSummaryModel: OptionsSummaryTotalTableRowModel = {
  type: '',
  premiumPrice: '-',
  premiumABS: 0,
  feesAndCommPrice: '-',
  feesAndCommABS: 0,
  profitAndLossPrice: '-',
  profitAndLossABS: 0,
  avgProfitAndLossPrice: '-',
  avgProfitAndLossABS: 0,
  trades: '-',
  assigned: '-',
  assignedPercent: '-',
  totalAvgPlPerDayPrice: '-',
};

export const emptyItemOptionsSummaryModel: OptionsSummaryItemTableRowModel = {
  type: '',
  premiumPrice: '-',
  premium: 0,
  premiumBarPercentage: 0,
  feesAndCommPrice: '-',
  feesAndComm: 0,
  feesAndCommBarPercentage: 0,
  profitAndLossPrice: '-',
  profitAndLoss: 0,
  profitAndLossBarPercentage: 0,
  avgProfitAndLossPrice: '-',
  avgProfitAndLoss: 0,
  avgProfitAndLossBarPercentage: 0,
  trades: '-',
  tradesBarPercentage: 0,
  assigned: '-',
  assignedPercent: '-',
  totalAvgPlPerDayPrice: '-',
  isTradesGroup: false,
  usedTransactions: []
};
