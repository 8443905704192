import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: '[commaSeperator]'
})

export class CommaSeperatorPipe implements PipeTransform {

  transform(value: any, max_length: number, no_need_decimal?: boolean) {
    if (value !== null) {
      return this.localeString(value, max_length, no_need_decimal);
    } else {
      return null;
    }
  }

  private localeString(nStr: any, max_length: number, no_need_decimal: boolean) {
    /**
     * add comma
     * add decimal if already there, if not then add two digit,
     * should only 2 digit decimal
     *  
     */
    if (nStr === "") return "";
    nStr += "";
    let x = nStr.replace(/,/g, '');
    x = x.slice(0, max_length); // returns only max_length of character
    let x1 = x.split('.')[0];
    const haveFullStop = x.indexOf(".") > -1;
    const rgx = /(\d+)(\d{3}| \d{6})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    const prefixx = x1 ? x1 : null;
    if (no_need_decimal) {
      return prefixx ? prefixx : '';
    } else {
      let x2 = x.split('.')[1] && x.split('.')[1] !== '' ? x.split('.')[1] : '';
      const postfixx = (x2 !== '') ? (x2.length > 2 ? parseFloat("." + x2).toFixed(2) : "." + x2) : (haveFullStop ? '.' : '');
      return (prefixx ? prefixx : '') + (postfixx ? "." + postfixx.split('.')[1] : '');
    }
  }
}
