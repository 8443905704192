import { combineLatest, debounceTime, filter, map, startWith, take, tap, Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LiveDataModule } from '@c/shared/live-data/live-data.module';
import {
  ExchangeCountriesCodes,
  isNumberHasValue,
  MomentDateTimeFormats,
  WheelROIDefaultRange,
} from '@const';
import { tradingLogCallLiveDataColumns } from '@constants/trading-log.constants';
import * as calc from '@m1/wheel/wheel-calculator/formula';
import {
  ILiveSubscription,
  IOption,
  IOptionsChain,
} from '@mod/live-data/live-data.model';
import { TradingLogQuotesOptionsModel } from '@mod/trading-log/trading-log-quotes-options.model';
import { LiveWheelCalculatorStreamingService } from '@s/live-wheel-calculator-streaming.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ObservableService } from '@core1/directives/observable.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-trading-log-quotes-options-modal',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, LiveDataModule],
  templateUrl: './trading-log-quotes-options-modal.component.html',
  styleUrl: './trading-log-quotes-options-modal.component.scss',
})
export class TradingLogQuotesOptionsModalComponent implements OnInit, OnDestroy {
  public closeClickEvent = new EventEmitter<void>();
  public initialDataLoadedEvent = new EventEmitter<void>();
  public onRetryClickEvent = new EventEmitter<void>();

  protected roiLowerBound = toSignal(
    this.observableService.wheelRoiRange.pipe(
      startWith(this.observableService2.wheelRoiRange.getValue()),
      map((data) => isNumberHasValue(data.lower) ? data.lower : WheelROIDefaultRange.Lower),
    )
  );
  protected roiUpperBound = toSignal(
    this.observableService.wheelRoiRange.pipe(
      startWith(this.observableService2.wheelRoiRange.getValue()),
      map((data) => isNumberHasValue(data.upper) ? data.upper : WheelROIDefaultRange.Upper)
    )
  )
  protected showCallRow = true;
  protected showPutRow = true;
  protected isPutUpdateOverlay = false;
  protected isCallUpdateOverlay = false;
  protected tradierFlags;
  protected putSubscription: ILiveSubscription = null;
  protected putOptionsChain: WritableSignal<IOptionsChain> = signal(null);
  protected putSubscriptionTimeout: NodeJS.Timeout = null;
  protected isPutSubscriptionTimeoutTriggered: WritableSignal<boolean> = signal(false);
  protected putOverlayTimeout: NodeJS.Timeout = null;
  protected callSubscription: ILiveSubscription = null;
  protected callStockPrice: number = null;
  protected callOptionsChain: WritableSignal<IOptionsChain> = signal(null);
  protected callSubscriptionTimeout: NodeJS.Timeout = null;
  protected isCallSubscriptionTimeoutTriggered: WritableSignal<boolean> = signal(false);
  protected callOverlayTimeout: NodeJS.Timeout = null;
  protected maxPosition = this.userDataService.getUser()?.wMaxPosition;
  protected tradingLogCallLiveDataColumns = tradingLogCallLiveDataColumns;

  private putBuyingPower: number = 10000000;
  private callNumberOfShares: number = 100;
  private readonly subscriptionTimeout = 15 * 1000;
  private subscription = new Subscription();


  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private quotesOptions: TradingLogQuotesOptionsModel,
    @Optional()
    private dialogRef: MatDialogRef<TradingLogQuotesOptionsModalComponent>,
    private symbolsService: SymbolsService,
    private liveWheelCalculatorStreamingService: LiveWheelCalculatorStreamingService,
    private observableService: ObservableService,
    private observableService2: ObservableServiceV2,
    private userDataService: UserDataService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    const { tradier_flag_ifs, tradier_flag_pxo } =
      this.observableService2.mySettings.getValue();
    this.tradierFlags = {
      tradier_flag_ifs,
      tradier_flag_pxo,
    };

    if (this.quotesOptions) {
      this.subscribeLiveData(
        this.quotesOptions.symbol,
        this.quotesOptions.expiration,
        this.quotesOptions.isPut,
      );
    }

    this.subscription.add(
      combineLatest([
        toObservable(this.isPutSubscriptionTimeoutTriggered, { injector: this.injector }),
        toObservable(this.isCallSubscriptionTimeoutTriggered, { injector: this.injector }),
      ])
        .pipe(
          filter(
            ([isPutSubscriptionTimeoutTriggered, isCallSubscriptionTimeoutTriggered]) =>
              isPutSubscriptionTimeoutTriggered || isCallSubscriptionTimeoutTriggered,
          ),
        )
        .subscribe(() => this.initialDataLoadedEvent.emit()),
    );

    this.subscription.add(
      combineLatest([
        toObservable(this.putOptionsChain, { injector: this.injector }),
        toObservable(this.callOptionsChain, { injector: this.injector }),
      ])
        .pipe(
          debounceTime(100),
          filter(([putOptionsChain, callOptionsChain]) => !!(putOptionsChain || callOptionsChain)),
          take(1),
        )
        .subscribe(() => this.initialDataLoadedEvent.emit()),
    );
  }

  public async subscribeLiveData(
    symbol: string,
    expiration: string,
    isPut: boolean,
  ): Promise<void> {
    if (this.isLiveDataSubscriptionInProgress(isPut)) {
      return;
    }

    const expirationMoment = moment(
      expiration,
      MomentDateTimeFormats.ServerDate
    );
    const symbolData = await this.symbolsService.getBySymbol(
      symbol,
      ExchangeCountriesCodes.US
    );

    if (isPut) {
      this.putSubscription = null;
      this.putOptionsChain.set(null);
      this.isPutSubscriptionTimeoutTriggered.set(false);
    } else {
      this.callSubscription = null;
      this.callOptionsChain.set(null);
      this.isCallSubscriptionTimeoutTriggered.set(false);
    }

    const subscription = {
      subscriptionId: uuidv4(),
      symbol: { symbol, country_code: ExchangeCountriesCodes.US },
      isValidSymbol: !!symbolData,
      symbolKey: `${symbol}_${expiration}_${isPut ? 'put' : 'call'}`,
      headerId: null,
      headerIndex: null,
      expiration: expirationMoment,
      isPut,
    };

    this.liveWheelCalculatorStreamingService.subscribe(
      subscription.subscriptionId,
      subscription.symbolKey,
      this.handleLiveDataOptionChainUpdate.bind(this)
    );

    if (isPut) {
      this.putSubscription = subscription;
    } else {
      this.callSubscription = subscription;
    }

    if (isPut) {
      if (!this.showPutRow) {
        this.showPutRow = true;
      }
      this.putSubscriptionTimeout = setTimeout(() => {
        this.isPutSubscriptionTimeoutTriggered.set(true);
      }, this.subscriptionTimeout);
    } else {
      if (!this.showCallRow) {
        this.showCallRow = true;
      }

      this.callSubscriptionTimeout = setTimeout(() => {
        this.isCallSubscriptionTimeoutTriggered.set(true);
      }, this.subscriptionTimeout);
    }
  }

  protected async liveDataPutRetry() {
    if (!this.putSubscription) {
      return;
    }

    const { symbol, expiration } = this.putSubscription;
    this.onRetryClickEvent.emit();
    await this.subscribeLiveData(
      symbol.symbol,
      expiration.format(MomentDateTimeFormats.ServerDate),
      true,
    );
  }

  protected async liveDataCallRetry() {
    if (!this.callSubscription) {
      return;
    }

    const { symbol, expiration } = this.callSubscription;
    this.onRetryClickEvent.emit();
    await this.subscribeLiveData(
      symbol.symbol,
      expiration.format(MomentDateTimeFormats.ServerDate),
      false,
    );
  }

  @HostListener('document:keydown.escape', ['$event'])
  protected onClose(): void {
    this.closeClickEvent.emit();
    this.dialogRef?.close();
  }

  private isLiveDataSubscriptionInProgress(isPut: boolean): boolean {
    return isPut
      ? !!this.putSubscription &&
          !this.putOptionsChain() &&
          !this.isPutSubscriptionTimeoutTriggered()
      : !!this.callSubscription &&
          !this.callOptionsChain() &&
          !this.isCallSubscriptionTimeoutTriggered();
  }

  private handleLiveDataOptionChainUpdate(data: IOptionsChain): void {
    let subscription = null;
    if (this.putSubscription?.symbolKey === data.symbol) {
      subscription = this.putSubscription;
      if (!this.isPutUpdateOverlay) {
        this.isPutUpdateOverlay = true;
      }
    } else if (this.callSubscription?.symbolKey === data.symbol) {
      subscription = this.callSubscription;
      if (!this.isCallUpdateOverlay) {
        this.isCallUpdateOverlay = true;
      }
    }

    if (!subscription) {
      return;
    }

    const isTodayHoliday = data?.isTodayHoliday;
    const dataToProcess = data?.options
      ? data
      : subscription.preProcessedOptionsChain;

    if (!dataToProcess) {
      return;
    }

    if (!subscription.preProcessedOptionsChain && dataToProcess.options) {
      subscription.preProcessedOptionsChain = dataToProcess;
    }

    if (!dataToProcess) {
      return;
    }

    if (
      subscription.isPut &&
      subscription.stockPrice === null &&
      subscription.isValidSymbol &&
      !isTodayHoliday
    ) {
      return;
    }

    if (subscription.isPut && this.putSubscriptionTimeout) {
      clearTimeout(this.putSubscriptionTimeout);
    } else if (this.callSubscriptionTimeout) {
      clearTimeout(this.callSubscriptionTimeout);
    }

    const options = dataToProcess.options.map(({ strike, bid, ask, delta }) => {
      const roi = subscription.isPut
        ? calc.getSellPutsPremiumAnnualarized(
            strike,
            subscription.expiration,
            this.putBuyingPower,
            this.maxPosition,
            bid
          )
        : subscription.stockPrice
        ? calc.getSellCallsOptionPremiumAnnularized(
            subscription.stockPrice,
            bid,
            this.callNumberOfShares,
            subscription.expiration
          )
        : null;

      return {
        strike,
        bid,
        ask,
        delta,
        roi: !isNaN(roi) && roi !== null ? Math.round(roi) : null,
      };
    });

    if (subscription.isPut) {
      options.sort((a: IOption, b: IOption) => b.strike - a.strike);
    }

    const optionsChain = {
      ...dataToProcess,
      options,
    } as IOptionsChain;

    if (subscription.isPut) {
      this.putOptionsChain.set(optionsChain);

      if (this.putOverlayTimeout) {
        clearTimeout(this.putOverlayTimeout);
      }

      this.putOverlayTimeout = setTimeout(() => {
        this.isPutUpdateOverlay = false;
      }, 500);
    } else {
      this.callOptionsChain.set(optionsChain);

      if (this.callOverlayTimeout) {
        clearTimeout(this.callOverlayTimeout);
      }

      this.callOverlayTimeout = setTimeout(() => {
        this.isCallUpdateOverlay = false;
      }, 500);
    }
  }

  ngOnDestroy(): void {
    const subscription = this.putSubscription ? this.putSubscription : this.callSubscription;
    this.liveWheelCalculatorStreamingService.unsubscribe(subscription.subscriptionId, subscription.symbolKey);
    this.subscription.unsubscribe();
  }
}
