import { Injectable } from '@angular/core';
import { Themes, ThemesNumValues } from '../constants';
import { ObservableService } from './observable.service';
import { UserSettingsService } from './user-settings.service';
import { ObservableService as ObservableServiceV1 } from '../core/directives/observable.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    private observableService: ObservableService,
    private userSettingsService: UserSettingsService,
    private observableServiceV1: ObservableServiceV1
  ) { }

  async toggleTheme(theme: ThemesNumValues, isV1: boolean) {
    const themeValue = theme === ThemesNumValues.Dark
      ? Themes.Dark
      : Themes.Light;

    const promises = [this.userSettingsService.updateTheme(theme)];

    // if the request is coming from V1 we don't need to do it
    if (!isV1) {
      if (theme === ThemesNumValues.Dark) {
        document.getElementsByTagName('body')[0].classList.add('black-theme');
      }
      else {
        document.getElementsByTagName('body')[0].classList.remove('black-theme');
      }

      // v1 specifics
      this.observableServiceV1.theme.next(themeValue);
      localStorage.setItem('theme', themeValue);

      promises.push(this.userSettingsService.updateThemeV1(theme));
    }

    await Promise.all(promises);

    this.observableService.mySettings.next(await this.userSettingsService.get());
    this.observableService.theme.next(themeValue);
  }

}
