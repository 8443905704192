import { Component, OnInit, Renderer2, ViewChildren } from '@angular/core';
import * as moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import { DialogsService } from '@s/common/dialogs.service';
import { EconomicCalendarService } from '@s/calendars/economic-calendar.service';
import { ObservableService } from '@s/observable.service';
import { ServerDataService } from '@s/server-data.service';
import { UserDataService } from '@s/user-data.service';
import { convertToEasternTime } from '@u/utils';
import { MomentDateTimeFormats, RockyPromts, TradingHubTabs, UserAccessType, UserSettings } from '@const';
import { TradingHubMode } from '@c/trading-hub/trading-hub.model';
import { EconomicCalendarModel } from '@mod/calendars/economic-calendar.model';
import { CalendarWeek } from '@c/shared/weeks-selector/weeks-selector.model';
import { DayLinkDataModel } from './models/day-link-data.model';

@Component({
  selector: 'app-economic-calendar-modal',
  templateUrl: './economic-calendar-modal.component.html',
  styleUrls: ['./economic-calendar-modal.component.scss'],
})
export class EconomicCalendarModalComponent implements OnInit {
  @ViewChildren('calendarCard') cardsCollection;

  protected economicCalendar = [];
  protected currentDay: string | null = null;
  protected currentWeek: moment.Moment | null = null;
  protected weeksArray: CalendarWeek[] = [];
  protected editMode = false;
  protected isAdmin = this.checkIsAdmin();
  protected showRockyEconomicCalendar = this.observableService.showRockyEconomicCalendar.getValue();

  private eventsList = [];
  private eventsPerDay = {};
  private urlsPerDay: Record<string, DayLinkDataModel> = {};
  private isTimeHasSpecialString = false;
  private readonly economicCalendarKey = 'economic-calendar';

  constructor(
    private dialog: DialogsService,
    private dialogsService: DialogsService,
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private economicCalendarService: EconomicCalendarService,
    private renderer: Renderer2,
    private serverDataService: ServerDataService,
  ) { }

  async ngOnInit(): Promise<void> {
    const currentDate = moment();
    this.currentDay = currentDate.clone().format(MomentDateTimeFormats.ServerDate);
    this.currentWeek = currentDate.clone().startOf('week');

    this.setWeeksArray(currentDate);

    const minDate = currentDate.clone().startOf('month').add(-1, 'month');
    const maxDate = currentDate.clone().startOf('month').add(2, 'month').add(-1, 'day');

    this.urlsPerDay = await this.serverDataService
      .getAsObject<DayLinkDataModel[]>(this.economicCalendarKey, true)
      .then((res) =>
        Array.isArray(res)
          ? res.reduce(
              (prev, curr) => ({
                ...prev,
                [curr.date]: { ...curr },
              }),
              {}
            )
          : {}
      )
      .catch(() => ({}));

    const events = await this.economicCalendarService.get(
      'USD',
      minDate.format(MomentDateTimeFormats.ServerDate),
      maxDate.format(MomentDateTimeFormats.ServerDate)
    );
    const timeRegex = /(am|pm)$/i;

    const rawEvents = events.map((econEvent) => {
      const date = convertToEasternTime(econEvent.date);
      if (econEvent.time) {
        date.add(econEvent.time);
      }

      return {
        ...econEvent,
        date: econEvent.time ? date.local().format() : date.format(),
        time: econEvent.time ? date.local().format('00:HH:mm') : null,
        time_raw: timeRegex.test(econEvent.time_raw) ? date.local().format('h:mma') : econEvent.time_raw
      };
    });

    this.eventsPerDay = {};
    this.eventsList = rawEvents;

    // Subtract and add 1 day to account for different time zones
    const dateRunner = minDate.clone().add(-1, 'day');
    const maxDateRunner = maxDate.clone().add(1, 'day');

    while (dateRunner.unix() <= maxDateRunner.unix()) {
      this.eventsPerDay[dateRunner.format(MomentDateTimeFormats.ServerDate)] = [];
      dateRunner.add(1, 'day');
    }

    this.groupEventsByDate(rawEvents);
    this.groupsEventsByTimeInDates();
    this.formatEventsDataIntoCalendar();
    this.addUrlToEventDate();
    this.filterEventByWeek(this.currentWeek);
  }

  protected updateOrAddLink(dayLinkDataEvent: DayLinkDataModel): void {
    this.eventsList = this.eventsList.map((item) => {
      return item.date === dayLinkDataEvent.date ? ({ ...item, urlData: dayLinkDataEvent }) : item;
    });

    this.urlsPerDay = {
      ...this.urlsPerDay,
      [dayLinkDataEvent.date]: {...dayLinkDataEvent, id: dayLinkDataEvent.id ? dayLinkDataEvent.id : uuidv4()}
    };

    this.serverDataService.set(this.economicCalendarKey, Object.values(this.urlsPerDay));
  }

  protected openLink(url: string): void {
    const externalUrl = url.match(/^(https?:\/\/|\/)/) ? url : `//${url}`;
    window.open(externalUrl, '_blank');
  }

  protected cancel(): void {
    this.dialog.closeAll();
  }

  protected addUrlToEventDate(): void {
    this.eventsList = this.eventsList.map((event) => ({
      ...event,
      urlData: this.urlsPerDay[event.date]
    }));
  }

  protected getSelectedWeekEvents(e: CalendarWeek): void {
    this.currentWeek = moment(e.weekStart, MomentDateTimeFormats.ServerDate);
    this.filterEventByWeek(this.currentWeek);
  }

  protected toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.filterEventByWeek(this.currentWeek);
  }

  protected openTradingHubModal(event: EconomicCalendarModel): void {
    this.userDataService.set(UserSettings.TradingHubTab, TradingHubTabs.IBot3);
    this.observableService.tradingHubTab.next(TradingHubTabs.IBot3);
    let prompt = RockyPromts.EconomicCalendarDefault.replace(
      /{eventName}/g,
      event.name
    );

    if (event.actual || event.forecast || event.previous) {
      prompt += '<br>';
      prompt += RockyPromts.EconomicCalendarFourthLine.replace(/{eventName}/g, event.name);
      prompt += this.configureEventDetails(event);
    }

    this.dialogsService.openTradingHubModal(TradingHubMode.Help, prompt);
  }

  private checkIsAdmin(): boolean {
    const mySettings = this.observableService.mySettings.getValue();
    return mySettings?.access_type === UserAccessType.Admin;
  }

  private setWeeksArray(currentDate: moment.Moment): void {
    const fourWeeksAgo = this.createValueForWeekItem(currentDate, '4 weeks ago ', -4);
    const threeWeeksAgo = this.createValueForWeekItem(currentDate, '3 weeks ago ', -3);
    const twoWeeksAgo = this.createValueForWeekItem(currentDate, '2 weeks ago ', -2);
    const lastWeek = this.createValueForWeekItem(currentDate, 'Last week ', -1);
    const currentWeek = this.createValueForWeekItem(currentDate, 'This week ', 0);
    const nextWeek = this.createValueForWeekItem(currentDate, 'Next week ', 1);
    const thirdWeek = this.createValueForWeekItem(currentDate, '3rd week ', 2);
    const fourthWeek = this.createValueForWeekItem(currentDate, '4th week ', 3);

    this.weeksArray.push(
      fourWeeksAgo,
      threeWeeksAgo,
      twoWeeksAgo,
      lastWeek,
      currentWeek,
      nextWeek,
      thirdWeek,
      fourthWeek
    );
  }

  private createValueForWeekItem(
    currentDate: moment.Moment,
    weekTitle: string,
    weekIndex: number,
  ): CalendarWeek {
    const weekStart = currentDate.clone().add(weekIndex, 'week').startOf('week');
    const weekEnd = currentDate.clone().add(weekIndex, 'week').endOf('week');

    if (weekStart.format('M') < weekEnd.format('M') && weekStart.format('Y') === weekEnd.format('Y')) {
      return {
        index: weekIndex,
        weekName: weekTitle,
        weekValue: `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D, YYYY')}`,
        weekStart,
        weekEnd
      };
    }

    if (weekStart.format('Y') < weekEnd.format('Y')) {
      return {
        index: weekIndex,
        weekName: weekTitle,
        weekValue: `${weekStart.format('MMM D, YYYY')} - ${weekEnd.format('MMM D, YYYY')}`,
        weekStart,
        weekEnd
      };
    }

    return {
      index: weekIndex,
      weekName: weekTitle,
      weekValue: `${weekStart.format('MMM D')} - ${weekEnd.format('D, YYYY')}`,
      weekStart,
      weekEnd
    };
  }

  private filterEventByWeek(week: moment.Moment): void {
    this.economicCalendar = this.eventsList.filter((item) => {
      return item.date >= week.startOf('week').format(MomentDateTimeFormats.ServerDate)
        && item.date <= week.endOf('week').format(MomentDateTimeFormats.ServerDate);
    });

    if (moment().format('w') === this.currentWeek.format('w')) {
      setTimeout(() => {
        this.renderer
          .selectRootElement('#date-' + (this.currentDay), true)
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    } else {
      setTimeout(() => {
        this.renderer
          .selectRootElement('.calendar-card', true)
          .scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 100);
    }
  }

  private groupEventsByDate(events): void {
    events.reduce((eventPerDay, event) => {
      const date = event.date.split('T')[0];
      eventPerDay[date].push(event);
      return eventPerDay;
    }, this.eventsPerDay);
  }

  private groupsEventsByTimeInDates(): void {
    for (const day in this.eventsPerDay) {
      const eventsPerTime = {};
      this.eventsPerDay[day].reduce((eventPerTime, event) => {
        const displayEvent = { time_raw: event.time_raw };
        Object.assign(displayEvent, event);

        if (!eventPerTime[displayEvent.time_raw]) {
          eventPerTime[displayEvent.time_raw] = [];
        }

        eventPerTime[displayEvent.time_raw].push(displayEvent);

        return eventPerTime;
      }, eventsPerTime);

      this.eventsPerDay[day] = eventsPerTime;
    }
  }

  private formatEventsDataIntoCalendar(): void {
    this.eventsList = Object.keys(this.eventsPerDay).map((date) => {
      const timeObjectWithSpecialStrings = [];
      const dateObject = {
        date,
        times: Object.keys(this.eventsPerDay[date]).map((time) => {
          const timeObject: any = {
            time: this.formatTime(time),
            events: this.eventsPerDay[date][time]
          };

          if (this.isTimeHasSpecialString) {
            timeObjectWithSpecialStrings.push(timeObject);
            return {};
          }

          return timeObject;
        }).sort(this.compareTime)
      };

      dateObject.times = timeObjectWithSpecialStrings.concat(dateObject.times.filter((time) => time?.events));

      return dateObject;
    });
  }

  private compareTime(a: any, b: any): -1 | 1 | 0 {
    const firstTime = moment(a.time, ['h:mma']).format('HH:mm');
    const secondTime = moment(b.time, ['h:mma']).format('HH:mm');

    if (firstTime < secondTime) {
      return -1;
    }

    if (firstTime > secondTime) {
      return 1;
    }

    return 0;
  }

  private formatTime(time): string {
    this.isTimeHasSpecialString = false;
    const formattedTime = moment(time, ['h:mma']).format('h:mm A');

    if (formattedTime === 'Invalid date') {
      this.isTimeHasSpecialString = true;
      return time;
    }

    return formattedTime;
  }

  private configureEventDetails(event: EconomicCalendarModel): string {
    const eventProperties = [
      { name: 'Actual', value: event.actual },
      { name: 'Forecast', value: event.forecast },
      { name: 'Previous', value: event.previous },
    ];

    return eventProperties
      .filter((property) => property.value)
      .map((property) => `${property.name} = ${property.value}`)
      .join('; ') + ';';
  }
}
