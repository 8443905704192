<div class="form-container">
  <app-multi-select
    #accountsSelect
    class="multi-select"
    [formControlG]="accountsFormControl"
    (selected)="onChange()"
    title="Account"
  ></app-multi-select>
  <app-multi-select
    #strategiesSelect
    class="multi-select"
    [formControlG]="strategiesFormControl"
    (selected)="onChange()"
    title="Strategy"
  ></app-multi-select>
</div>
