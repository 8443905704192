import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Response } from '@core1/interface';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  recalculateTradingLog = async () => {
    const { result } = await firstValueFrom(this.http.post<Response>('/v2/admin/recalculate_trading_log', null));

    return result;
  };
}
