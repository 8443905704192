import { ConnectedPosition } from "@angular/cdk/overlay";

export const heatmapDetailsTooltipPositoons: ConnectedPosition[] = [
  // tooltip is under
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 5,
  },
  // tooltip is on the left
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -30,
  },
  // tooltip is on the right
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 30,
  }
];
