import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';

import { RunScannerButtonModule } from '@c/run-scanner-button/run-scanner-button.module';
import { HeaderComponent, MobileHeaderComponent, WebHeaderComponent } from './header.component';
import { TopMenuModule } from '@c/top-menu/top-menu.module';
import { TradingHubSharedModule } from '@c/trading-hub/trading-hub-shared.module';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import { LinksOrganiserModule } from '@m/common/links-organiser/links-organiser.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    RouterModule,
    RunScannerButtonModule,
    TopMenuModule,
    TradingHubSharedModule,
    EditionsSharedModule,
    MatFormFieldModule,
    MatInputModule,
    LinksOrganiserModule,
    MatTooltipModule,
    MatExpansionModule
  ],

  exports: [HeaderComponent, MobileHeaderComponent, WebHeaderComponent],
  declarations: [HeaderComponent, MobileHeaderComponent, WebHeaderComponent],
  providers: [],
})
export class HeaderModule { }
