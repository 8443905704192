import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../core/interface/response';
import { RestRequestorService } from './rest-requestor.service';

@Injectable({
  providedIn: 'root'
})
export class JobDataService {

  constructor(private http: HttpClient,
    private restRequestorService: RestRequestorService) { }

  getRecentSecurity = async (security_id) => {
    const { result } = await this.restRequestorService.makeRequest(`recent_security_job_data_${security_id}`, 
      () => this.http.get<Response>(`/v2/jobData/recent_security?security_id=${security_id}`).toPromise());

    if (result && result.length) {
      return result[0];
    }

    return null;
  };

  getExchangesDailySchedulers = async (statuses) => {
    const { result } = await this.http.post<Response>(`/v2/jobData/exchanges_daily_schedulers`, {
      statuses
    }).toPromise();

    return result;
  };
}