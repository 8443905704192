import { OrderDuration } from '@const';
import { TradierOrderSide } from './trading-panel.model';
import { IOption, ISymbol } from '@s/symbols.service';

export enum TradingOrderContentStatus {
  BrokerConnectionMissing = 'brokerConnectionMissing',
  Trading = 'trading',
  OperationStatus = 'operationStatus',
  Error = 'error'
}

export enum TradingOrderClass {
  Equity = 'equity',
  Option = 'option',
  OCO = 'oco'
}

export enum TradingOrderDuration {
  Day = 'day',
  GTC = 'gtc'
}

export enum TradingOrderType {
  Market = 'market',
  Limit = 'limit',
  Stop = 'stop',
  StopLimit = 'stop_limit'
}

export interface TradingOrderInput {
  id?: number;
  accountNumber?: string;
  orderClass: TradingOrderClass;
  orderType: TradingOrderType;
  onTradingPanelNavigateHandler: Function;
  side?: TradierOrderSide;
  price?: number;
  limitPrice?: number;
  stopPrice?: number;
  stopLoss?: number;
  symbol?: ISymbol;
  option?: IOption,
  optionSymbol?: string;
  takeProfit?: number;
  quantity?: number;
  duration?: TradingOrderDuration;
  isQtyNote?: boolean;
  orderPanelTabIndex?: number;
}

export interface TradingPanelOrderModel {
  account: string;
  duration?: OrderDuration;
  limitPrice?: number;
  stopPrice?: number;
  qty: number;
}
