<a
  type="button"
  aria-label="Toggle sidenav"
  mat-button
  class="root"
  [ngClass]="cssClass"
  (click)="openTradingHubModal()"
>
  <div class="progress-container">
    <app-checklist-progress
      [value]="progress$ | async"
      [tasksLength]="tasksLength$ | async"
      [isFirstTimeOpening]="isFirstTimeOpening$ | async"
      size="small"
    ></app-checklist-progress>
  </div>
  <span class="menu-title">Your Trading Hub</span>
</a>
