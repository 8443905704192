import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSort, MatSortHeader, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { catchError, combineLatest, from, fromEvent, of, Subject, Subscriber, switchMap } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as _ from 'lodash';

import { PortfolioFiltersFormModule } from '@c/portfolio/portfolio-filters-form/portfolio-filters-form.module';
import { FormDefaultsModule } from '@c/shared/forms';
import { TradingLogTradesFilterFormModule } from '@c/trading-log/trades/trading-log-trades-container/trading-log-trades-filter-form';
import { MorningStarIndustryService } from '@s/morning-star-industry.service';
import { MorningStarSectorService } from '@s/morning-star-sector.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradingLogStreamingService } from '@s/trading-log/trading-log-streaming.service';
import { UserDataService } from '@s/user-data.service';
import { convertArrayToRecord } from '@u/utils';
import { isOverlayOpen } from '@u/ui-utils';
import {
  createTradesGroupTitle,
  filterTrades,
  getStatistic,
  getUniqueSymbols,
  transformTradingLogGroups
} from '@c/portfolio/portfolio.utils';
import { Direction } from '@t/wheel/wheel.types';
import { MorningStarIndustryMap, MorningStarSectorMap } from '@mod/data/morning-star.model';
import { TradingLogGroupType } from '@t/trading-log';
import {
  TradingLogAccountModel,
  TradingLogGroupModel,
  TradingLogStrategyModel,
} from '@mod/trading-log';
import { IPortfolioFiltersState } from '@c/portfolio/portfolio-filters-form/portfolio-filters-form.model';
import { IPortfolioSettings, IPortfolioTrade, IPortfolioTradesGroup, PortfolioViewModes } from '@c/portfolio/portfolio.model';
import { ALL_TRADES_GROUP_ID, DEFAULT_SETTINGS, SAVE_SETTINGS_DEBOUNCE_TIME_MS, SCROLL_TO_ROW_DELAY_MS } from '@c/portfolio/portfolio.data';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatDividerModule,
    FormDefaultsModule,
    MatButtonToggleModule,
    TradingLogTradesFilterFormModule,
    PortfolioFiltersFormModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
  ],
  providers: [
  ]
})
export class PortfolioComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isActive = false;
  @Input() settingsPrefix = '';

  @Output() symbolSelected = new EventEmitter<{ symbol: string, security_id: number }>();
  @Output() filteredPortfolioListUpdated = new EventEmitter<IPortfolioTrade[]>();

  protected isGroupsLoading = true;
  protected isSettingsLoading = true;
  protected isAccountsStrategiesLoading = true;

  protected viewModes = PortfolioViewModes;
  protected currentViewMode = DEFAULT_SETTINGS.viewMode;
  protected selectedRow: IPortfolioTrade | null = null;
  protected filtersState: IPortfolioFiltersState = { ...DEFAULT_SETTINGS.filters };

  protected filteredTLGroups: Partial<TradingLogGroupModel>[] = [];
  protected allTrades: IPortfolioTrade[] = [];
  protected filteredTrades: IPortfolioTrade[] = [];
  protected groupedTrades: IPortfolioTradesGroup[] = [];

  protected accountsRecord: Record<string, TradingLogAccountModel> = {};
  protected strategiesRecord: Record<string, TradingLogStrategyModel> = {};
  protected sectorsMap: MorningStarSectorMap = new Map();
  protected industriesMap: MorningStarIndustryMap = new Map();
  protected allSymbolsRecord: Record<string, ISymbol> = {};

  // to have stable indexes for sectors/industries and use it for progress-bar colors
  protected sectorsIndexesMap: Map<string, number> = new Map();
  protected industriesIndexesMap: Map<string, number> = new Map();

  private subscriptions = new Subscriber();
  private saveSettings$ = new Subject<void>();
  private lastSavedSettings: IPortfolioSettings = { ...DEFAULT_SETTINGS };

  // for sort-header, table body is below in groups as separate tables
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<{ symbol: string; company: string }>([]);
  public sortState = { ...DEFAULT_SETTINGS.sort };

  constructor(
    private dialog: MatDialog,
    private renderer: Renderer2,
    private userDataService: UserDataService,
    private tradingLogStreamingService: TradingLogStreamingService,
    private symbolsService: SymbolsService,
    private industryService: MorningStarIndustryService,
    private sectorService: MorningStarSectorService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.saveSettings$
        .pipe(
          debounceTime(SAVE_SETTINGS_DEBOUNCE_TIME_MS),
          switchMap(() => this.saveSettings())
        )
        .subscribe()
    );

    this.subscriptions.add(
      combineLatest([
        from(this.userDataService.getAsJSON(this.getSaveSettingsKey(this.settingsPrefix)))
          .pipe(
            catchError(() => of(DEFAULT_SETTINGS)),
            take(1),
            map((savedSettings) => savedSettings ? { ...DEFAULT_SETTINGS, ...savedSettings } : DEFAULT_SETTINGS),
            tap((settings) => {
              this.lastSavedSettings = { ...settings };
              this.applySavedSettings(settings);
              this.applySavedGroupsViewModeState(this.lastSavedSettings); // apply only first time
              this.isSettingsLoading = false;
            })
          ),
        combineLatest([
          this.tradingLogStreamingService.accountsLoaded$,
          this.tradingLogStreamingService.strategiesLoaded$,
        ])
          .pipe(
            filter(([isAccountsLoaded, isStrategiesLoaded]) => isAccountsLoaded && isStrategiesLoaded),
            switchMap(() => combineLatest([
              this.tradingLogStreamingService.accounts$,
              this.tradingLogStreamingService.strategies$,
            ]))
          )
      ])
        .subscribe(([settings, [accounts, strategies]]) => {
          // handle updates from accounts/strategies only after receiving saved settings
          this.accountsRecord = convertArrayToRecord([...accounts], 'id');
          this.strategiesRecord = convertArrayToRecord([...strategies], 'id');

          // remove accounts/strategies if they were removed in trading-log
          this.filtersState = {
            accounts: [...this.filtersState.accounts].filter((item) => item === null || this.accountsRecord[item]),
            strategies: [...this.filtersState.strategies].filter((item) => item === null || this.strategiesRecord[item]),
          };

          this.updatePortfolioState();
          this.isAccountsStrategiesLoading = false;
        })
    );

    this.subscriptions.add(
      combineLatest([
        from(this.symbolsService.getAll()),
        from(this.sectorService.get()),
        from(this.industryService.get()),
      ])
        .pipe(
          take(1),
          tap(([allSymbols, sectors, industries]) => {
            this.allSymbolsRecord = allSymbols.reduce((acc, item) => {
              const key = `${item.symbol}-${item.country_code}`;
              acc[key] = item;
              return acc;
            }, {});

            this.sectorsMap = sectors;
            this.industriesMap = industries;

            Array.from(sectors.values())
              .sort()
              .forEach((item, index) => this.sectorsIndexesMap.set(item, index));
            Array.from(industries.values())
              .sort()
              .forEach((item, index) => this.industriesIndexesMap.set(item, index));
          }),
          switchMap(() => this.tradingLogStreamingService.groupsLoaded$),
          filter((groupsLoaded) => groupsLoaded),
          switchMap(() => this.tradingLogStreamingService.groups$),
          map((groups) => groups.filter((item) => item.symbol && item.type === TradingLogGroupType.Active)),
          tap((groups) => {
            this.filteredTLGroups = [...groups];
            this.updatePortfolioState();
            this.applySavedGroupsViewModeState(this.lastSavedSettings); // apply only first time
            this.isGroupsLoading = false;
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.tradingLogStreamingService.groupValueUpdateEvents$
        .pipe(
          filter((event) => event?.groups !== null),
          map((event) => event.groups.filter((item) => item.symbol && item.type === TradingLogGroupType.Active)),
        )
        .subscribe((groups) => {
          this.filteredTLGroups = [...groups];
          this.updatePortfolioState();
        })
    );

    this.subscriptions.add(
      fromEvent(window, 'keydown')
        .subscribe((event: KeyboardEvent) => {
          const { code } = event;
          const dialogOpen = this.dialog.openDialogs.length;
          const isOverlayOpened = isOverlayOpen();

          if (this.isActive
            && dialogOpen === 0
            && !isOverlayOpened
            && ['ArrowUp', 'ArrowDown'].includes(code)
          ) {
            event.preventDefault();
            event.stopPropagation();

            if (this.selectedRow) {
              this.setFocusForRow(this.selectedRow.id);
            }

            if (!this.selectedRow) {
              if (!this.groupedTrades[0].viewState.isGroupExpanded) {
                this.onGroupExpandChange(this.groupedTrades[0], true);
              }

              const firstEl = this.groupedTrades[0].items[0];
              this.onSelectSymbol(firstEl);
              this.selectedRow = firstEl;

              return;
            }

            if (code === 'ArrowUp') {
              this.arrowUpEvent();
            } else if (code === 'ArrowDown') {
              this.arrowDownEvent();
            }
          }
        })
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected onChangeFilters(
    filtersState: IPortfolioFiltersState,
    triggerSaveSettings: boolean = true,
  ): void {
    this.filtersState = filtersState;
    this.updatePortfolioState();

    if (triggerSaveSettings) {
      this.saveSettings$.next();
    }
  }

  protected onSelectSymbol(symbol: IPortfolioTrade): void {
    this.selectedRow = symbol;
    this.symbolSelected.emit({ symbol: symbol.symbol, security_id: symbol.securityId });
  }

  protected onSortChange(
    sortState: { active: string, direction: SortDirection },
    triggerSaveSettings: boolean = true,
  ): void {
    this.sortState = { column: sortState.active, direction: sortState.direction };
    this.updatePortfolioState();

    if (triggerSaveSettings) {
      this.saveSettings$.next();
    }
  }

  protected onChangeViewMode(
    newViewMode: PortfolioViewModes,
    triggerSaveSettings: boolean = true,
  ): void {
    if (this.currentViewMode === newViewMode) {
      return;
    }

    this.currentViewMode = newViewMode;
    this.updatePortfolioState();

    if (triggerSaveSettings) {
      this.saveSettings$.next();
    }
  }

  protected onGroupExpandChange(
    group: IPortfolioTradesGroup,
    newState: boolean,
    triggerSaveSettings: boolean = true,
  ): void {
    this.groupedTrades = [...this.groupedTrades].map((item) => {
      if (item.id === group.id && item.viewState.isGroupExpanded !== newState) {
        return {
          ...item,
          viewState: { ...item.viewState, isGroupExpanded: newState },
        };
      }

      return item;
    });

    if (triggerSaveSettings) {
      this.saveSettings$.next();
    }
  }

  protected onGroupStatisticExpandChange(group: IPortfolioTradesGroup, newState: boolean): void {
    this.groupedTrades = [...this.groupedTrades].map((item) => {
      if (item.id === group.id && item.viewState.isStatisticExpanded !== newState) {
        return {
          ...item,
          viewState: { ...item.viewState, isStatisticExpanded: newState },
        };
      }

      return item;
    });

    this.saveSettings$.next();
  }

  protected scrollToPanel(id: string): void {
    // scrollTo doesn't work for expansion-panel
    const element = document.getElementById('expansion-panel_' + id);

    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }

  private arrowDownEvent(): void {
    const currentGroup = this.groupedTrades.find((item) => item.items.some((s) => s.id === this.selectedRow.id));
    const groupIndex = this.groupedTrades.findIndex((item) => item.id === currentGroup.id);

    if (!currentGroup.viewState.isGroupExpanded) {
      this.onGroupExpandChange(currentGroup, true);
    }

    const items = currentGroup.items;
    const itemIndex = items.findIndex((el) => el.id === this.selectedRow.id);

    if (itemIndex < (items.length - 1) && items[itemIndex + 1]) {
      this.selectedRow = items[itemIndex + 1];
      this.onSelectSymbol(items[itemIndex + 1]);
      this.scrollToRow(items[itemIndex + 1].id);

      if (!currentGroup.viewState.isGroupExpanded) {
        this.onGroupExpandChange(currentGroup, true);
        setTimeout(() => this.scrollToRow(currentGroup.id), SCROLL_TO_ROW_DELAY_MS);
        return;
      }

      this.scrollToRow(this.selectedRow.id);
      return;
    }

    if (itemIndex >= (items.length - 1) && this.groupedTrades[groupIndex + 1]) {
      const nextGroup = this.groupedTrades[groupIndex + 1];
      const nextElement = nextGroup.items[0];
      this.selectedRow = nextElement;
      this.onSelectSymbol(nextElement);

      if (!nextGroup.viewState.isGroupExpanded) {
        this.onGroupExpandChange(nextGroup, true);
        setTimeout(() => this.scrollToRow(nextElement.id), SCROLL_TO_ROW_DELAY_MS);
        return;
      }

      this.scrollToRow(this.selectedRow.id);
      return;
    }
  }

  private arrowUpEvent(): void {
    const currentGroup = this.groupedTrades.find((item) => item.items.some((s) => s.id === this.selectedRow.id));
    const groupIndex = this.groupedTrades.findIndex((item) => item.id === currentGroup.id);

    if (!currentGroup.viewState.isGroupExpanded) {
      this.onGroupExpandChange(currentGroup, true);
    }

    const items = currentGroup.items;
    const itemIndex = items.findIndex((el) => el.id === this.selectedRow.id);

    if (itemIndex > 0 && items[itemIndex - 1]) {
      this.selectedRow = items[itemIndex - 1];
      this.onSelectSymbol(items[itemIndex - 1]);

      if (!currentGroup.viewState.isGroupExpanded) {
        this.onGroupExpandChange(currentGroup, true);
        setTimeout(() => this.scrollToRow(currentGroup.id), SCROLL_TO_ROW_DELAY_MS);
        return;
      }

      this.scrollToRow(this.selectedRow.id);
      return;
    }

    if (itemIndex <= 0 && this.groupedTrades[groupIndex - 1]) {
      const prevGroup = this.groupedTrades[groupIndex - 1];
      const prevElement = prevGroup.items[prevGroup.items.length - 1];
      this.selectedRow = prevElement;
      this.onSelectSymbol(prevElement);

      if (!prevGroup.viewState.isGroupExpanded) {
        this.onGroupExpandChange(prevGroup, true);
        setTimeout(() => this.scrollToRow(prevElement.id), SCROLL_TO_ROW_DELAY_MS);
        return;
      }

      this.scrollToRow(this.selectedRow.id);
      return;
    }
  }

  private updatePortfolioState(): void {
    // update trades in case of strategies/accounts were updated
    this.allTrades = transformTradingLogGroups(
      this.filteredTLGroups,
      this.allSymbolsRecord,
      this.sectorsMap,
      this.industriesMap,
    );
    this.filteredTrades = filterTrades(this.allTrades, this.filtersState);
    const prevGroupedTradesState = convertArrayToRecord([...this.lastSavedSettings.groupedTrades], 'id');

    if (this.currentViewMode === PortfolioViewModes.Groups) {
      this.groupedTrades = this.groupTradesByAccountAndStrategy(this.filteredTrades).map((trade) => ({
        ...trade,
        items: _.orderBy([...trade.items], [this.sortState.column], [this.sortState.direction]),
        viewState: prevGroupedTradesState[trade.id]?.viewState ?? trade.viewState,
      }));
    }

    if (this.currentViewMode === PortfolioViewModes.List) {
      this.groupedTrades = this.getTradesAsOneGroup(this.filteredTrades).map((trade) => {
        return {
          ...trade,
          items: _.orderBy([...trade.items], [this.sortState.column], [this.sortState.direction]),
          viewState: prevGroupedTradesState[trade.id]?.viewState ?? trade.viewState,
        };
      });
    }

    this.filteredPortfolioListUpdated.emit(this.filteredTrades);
  }

  private applySavedSettings(settings: IPortfolioSettings): void {
    this.onChangeViewMode(settings.viewMode, false);
    this.onChangeFilters(settings.filters, false);

    if (this.sort) {
      this.sort.sort({
        id: this.sortState.column,
        start: this.sortState.direction || Direction.ASC,
        disableClear: true,
      });
    }

    if (this.selectedRow) {
      setTimeout(() => this.scrollToRow(this.selectedRow.id), SCROLL_TO_ROW_DELAY_MS);
    }

    // update direction arrow in sort-header
    if (this.sort && this.sort.sortables.get(this.sortState.column)) {
      (this.sort.sortables.get(this.sortState.column) as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
    }

    this.onSortChange({ active: settings.sort.column, direction: settings.sort.direction }, false);
    this.updatePortfolioState();
  }

  private applySavedGroupsViewModeState({ groupedTrades, viewMode }: IPortfolioSettings): void {
    if (!groupedTrades || groupedTrades.length === 0) {
      return;
    }

    if (this.groupedTrades.length === 0) {
      this.groupedTrades = groupedTrades.map(({ id, viewState }) => ({
        id,
        viewState,
        title: '',
        items: [],
        statistic: { sectors: [], industries: [] },
      }));

      return;
    }

    const prevGroupedTradesState = convertArrayToRecord([...groupedTrades], 'id');
    const groupedTradesWithoutRestoredViewState = viewMode === PortfolioViewModes.Groups
      ? this.groupTradesByAccountAndStrategy(this.filteredTrades)
      : this.getTradesAsOneGroup(this.filteredTrades);

    this.groupedTrades = groupedTradesWithoutRestoredViewState.map((item) => ({
      ...item,
      items: _.orderBy([...item.items], [this.sortState.column], [this.sortState.direction]),
      viewState: prevGroupedTradesState[item.id]?.viewState ?? item.viewState,
    }));
  }

  private async saveSettings(): Promise<void> {
    const key = this.getSaveSettingsKey(this.settingsPrefix);
    const allGroupKeys = this.getTradeGroupKeys(this.allTrades);
    const groupsStateMap = new Map<string, Pick<IPortfolioTradesGroup, 'id' | 'viewState'>>();

    [...this.lastSavedSettings.groupedTrades, ...this.groupedTrades].forEach((item) => {
      if (allGroupKeys.includes(item.id)) {
        groupsStateMap.set(item.id, { id: item.id, viewState: item.viewState });
      }
    });

    const allGroupedTrades = Array.from(groupsStateMap.values());
    const settings: IPortfolioSettings = {
      filters: {
        accounts: [...this.filtersState.accounts],
        strategies: [...this.filtersState.strategies],
      },
      sort: {
        column: this.sortState.column,
        direction: this.sortState.direction,
      },
      viewMode: this.currentViewMode,
      groupedTrades: allGroupedTrades,
    };

    if (_.isEqual(this.lastSavedSettings, settings)) {
      return;
    }

    this.lastSavedSettings = settings;
    await this.userDataService.set(key, settings);
  }

  private getSaveSettingsKey(prefix: string): string {
    return `${prefix}_portfolio-settings`;
  }

  private getTradeGroupKeys(items: IPortfolioTrade[]): string[] {
    if (!items || items.length === 0) {
      return [];
    }

    const groupIDs = items.reduce((acc, item) => {
      const key = `${item.accountId ?? 'not-defined'}-${item.strategyId ?? 'not-defined'}`;

      if (!acc.includes(key)) {
        acc.push(key);
      }

      return acc;
    }, [] as string[]);

    return [...groupIDs, ALL_TRADES_GROUP_ID];
  }

  private groupTradesByAccountAndStrategy(items: IPortfolioTrade[]): IPortfolioTradesGroup[] {
    const groups = items.reduce((acc, item) => {
      const key = `${item.accountId ?? 'not-defined'}-${item.strategyId ?? 'not-defined'}`;

      if (acc[key]) {
        acc[key].push(item);
      } else {
        acc[key] = [item];
      }

      return acc;
    }, {} as Record<string, IPortfolioTrade[]>);

    return Object.keys(groups).map((key) => {
      const uniqueSymbols = getUniqueSymbols(groups[key]);

      return {
        id: key,
        title: createTradesGroupTitle(
          uniqueSymbols[0]?.accountId,
          uniqueSymbols[0]?.strategyId,
          this.accountsRecord,
          this.strategiesRecord,
        ),
        items: uniqueSymbols,
        statistic: {
          sectors: getStatistic(uniqueSymbols.map(({ sector }) => sector), this.sectorsIndexesMap),
          industries: getStatistic(uniqueSymbols.map(({ industry }) => industry), this.industriesIndexesMap),
        },
        viewState: {
          isGroupExpanded: true,
          isStatisticExpanded: true,
        },
      };
    });
  }

  private getTradesAsOneGroup(items: IPortfolioTrade[]): IPortfolioTradesGroup[] {
    const uniqueSymbols = getUniqueSymbols(items);

    return [
      {
        id: ALL_TRADES_GROUP_ID,
        title: 'All',
        items: uniqueSymbols,
        statistic: {
          sectors: getStatistic(uniqueSymbols.map(({ sector }) => sector), this.sectorsIndexesMap),
          industries: getStatistic(uniqueSymbols.map(({ industry }) => industry), this.industriesIndexesMap),
        },
        viewState: {
          isGroupExpanded: true,
          isStatisticExpanded: true,
        },
      }
    ];
  }

  private scrollToRow(id: string): void {
    this.scrollTo('symbol-tr_' + id);
  }

  private setFocusForRow(id: string): void {
    const element = document.getElementById('symbol-tr_' + id);

    if (element) {
      element.focus();
    }
  }

  private scrollTo(id: string): void {
    const element = document.getElementById(id);

    if (this.selectedRow && element) {
      element.focus();

      this.renderer
        .selectRootElement(`#${id}`, true)
        .scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }

  protected trackByFn(index: number, item: { id?: string | number }): string | number {
    return item.id ?? index;
  }
}
