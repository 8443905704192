import { ITradingPlanTemplate } from '@c/trading-hub/trading-plan/trading-plan.model';

export const wheelTradingPlanTemplate: ITradingPlanTemplate = {
  title: 'Wheel/Wheelie',
  requiredAccess: null,
  sections: [
    {
      title: 'What To Trade',
      order: 0,
      content: '<p><strong>Value Stocks</strong></p><ul><li><p>Profitable according to Google Finance</p></li><li><p>Preferably profitable quarters, but profitable years are ok (2020 is an exception)</p></li><li><p>Paying a dividend</p></li><li><p>Market cap of 5B or more</p></li><li><p>Strike Price must be at support according to Lowest Low Indicator +/- one strike</p></li><li><p>Earnings must be after the expiration date of the option</p></li><li><p>Stocks must have a correlation of 0.65 or lower with other stocks / ETFs in the portfolio</p></li><li><p>No leveraged ETFs</p></li><li><p>No Chinese stocks</p></li></ul>',
    },
    {
      title: 'When To Enter',
      order: 1,
      content: '<p><strong>Selling Puts and Calls</strong></p><ul><li><p>Get at least 30% annualized ROI</p></li><li><p>Anytime during the day</p></li><li><p>Mon / Tue: This week\'s expiration</p></li><li><p>Wed: This week\'s and next week\'s expiration</p></li><li><p>Thu / Fri: Next week\'s expiration</p></li><li><p>For Calls: Sell at the assigned price or above but make sure you get at least 30% annualized</p></li></ul>',
    },
    {
      title: 'When To Exit',
      order: 2,
      content: '<p><strong>Closing Puts and Calls</strong></p><ul><li><p>Close at 80% of max profits if possible within 24h / 1 trading day</p></li><li><p>Close at 90% of max profits until the day before expiration</p></li><li><p>Close on expiration day if you need the buying power or don\'t want to get assigned</p></li></ul><p></p><p><strong>Rolling Calls:</strong></p><ul><li><p>Roll calls out by 1 week if you can get at least 60% annualized</p></li></ul>',
    },
    {
      title: 'Money Management / Position Sizing',
      order: 3,
      content: '<ul><li><p>Distribute the buying power over 5 positions</p></li></ul><ul><li><p>Adjust the # contracts to trade so that you would use 1/5 of the BP if you had to buy the stock</p></li></ul><p></p><p><strong>Wheelie</strong></p><ul><li><p>Place the 1st trade with 1/2 position size</p></li></ul><p></p><p><strong>If not assigned</strong>:&nbsp;</p><ul><li><p>Do nothing</p></li></ul><p></p><p><strong>If assigned</strong>: Stock Price &lt; Assigned Price on Monday</p><ul><li><p>Sell Puts for the other 1/2 position at the same strike with this week\'s expiration&nbsp;</p></li></ul><p></p><p><strong>If assigned</strong>: Stock Price &gt; Assigned Price on Monday</p><ul><li><p>Sell Calls on 1/2 position</p></li><li><p>Sell Puts for another 1/2 position at the same strike if you can get at least 30% annualized</p></li></ul>',
    },
  ],
};

export const bullCallSpreadTradingPlanTemplate: ITradingPlanTemplate = {
  title: 'Bull Call Spread',
  requiredAccess: ['show_mm_trades'],
  sections: [
    {
      title: 'What To Trade',
      order: 0,
      content: '<ul><li><p>Buy a Bull Call Spread</p></li><li><p>Choose a strike price for the BUY closest to the current price&nbsp;</p></li><li><p>Try to SELL a call $2, $3, or $5 above the BUY call</p></li><li><p>Don\'t sell a call above the assigned price</p></li><li><p>Choose an expiration with at least 45 DTE</p></li></ul>',
    },
    {
      title: 'When To Enter',
      order: 1,
      content: '<ul><li><p>Consider buying a BCS when the stock closes 10%, 20%, 30% etc. below the assignment price&nbsp;</p></li><li><p>Pay a max of 1/3 of the strike width, e.g. $5 strike width = $1.65 max</p></li></ul>',
    },
    {
      title: 'When To Exit',
      order: 2,
      content: '<ul><li><p>Exit the BCS for 90% of the max profit when possible: EXPLAIN</p></li><li><p>If the stock price is below the lower strike price on expiration day, let it expire</p></li><li><p>If the stock price is between the lower and upper strike price on expiration day, close the spread.&nbsp;</p></li><li><p>If the stock price is above the upper strike price on expiration day, let it get assigned</p></li><li><p>Close the SHORT CALL at 0.05 at anytime</p></li><li><p>When you close the underlying trade, i.e. getting called away, close the BCS as well</p></li></ul>',
    },
    {
      title: 'Money Management / Position Sizing',
      order: 3,
      content: '<ul><li><p>Adjust the # contracts so that you use 2% of the original BP for the premium when buying the BCS</p></li></ul>',
    }
  ],
};

export const defaultTradingPlanTemplate: ITradingPlanTemplate = {
  title: 'Default',
  requiredAccess: null,
  sections: [
    {
      title: 'What To Trade',
      order: 0,
      content: '<p>Enter the exact criteria for the stocks and options you want to trade, e.g. value stocks with weekly options, etc.</p>',
    },
    {
      title: 'When To Enter',
      order: 1,
      content: '<p>What are the exact criteria for entering a trade?</p><p>When exactly do you buy or sell a stock or option?</p>',
    },
    {
      title: 'When To Exit',
      order: 2,
      content: '<p>What are your exit criteria?</p><p>When do you take profits?</p><p>What about a stop loss?</p>',
    },
    {
      title: 'Money Management / Position Sizing',
      order: 3,
      content: '<p>How do you calculate how many stocks or options you should trade?</p><p>How much money will you allocate to each trade?</p><p>Will you trade on margin?</p>',
    },
    {
      title: 'Other Rules',
      order: 4,
      content: '<p>Write down any other rules you might have for your trading, e.g. don\'t trade the day before or after a major holiday like Thanksgiving or Christmas.</p>',
    },
  ],
};

export const blankTradingPlanTemplate: ITradingPlanTemplate = {
  title: 'Blank Plan',
  requiredAccess: null,
  sections: [],
};
