import { ExchangeCodes } from '@constants/exchanges.constants';
import { ShortingStocksScannerFilterModel } from './shorting-stocks-scanner-filter.model';

export const EMIT_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;
export const DEFAULT_IS_SHORTING_STOCKS_SCANNER_FILTERS_HIDDEN = false;

export const INITIAL_SHORTING_STOCKS_SCANNER_FILTERS_STATE: ShortingStocksScannerFilterModel = {
  isEasyToBorrow: false,
  exchangeOptions: [ExchangeCodes.Nasdaq],
  volumeFrom: { filterValue: '500000', visibleValue: '500,000' },
  volumeTo: { filterValue: '', visibleValue: '' },
  priceRangeFrom: '100.00',
  priceRangeTo: '',
  priceJumpFrom: '50.00',
  priceJumpTo: '',
};

export const DEFAULT_SHORTING_STOCKS_SCANNER_FILTERS_STATE: ShortingStocksScannerFilterModel = {
  isEasyToBorrow: false,
  exchangeOptions: [ExchangeCodes.Nasdaq],
  volumeFrom: { filterValue: '500000', visibleValue: '500,000' },
  volumeTo: { filterValue: '', visibleValue: '' },
  priceRangeFrom: '100.00',
  priceRangeTo: '',
  priceJumpFrom: '50.00',
  priceJumpTo: '',
};
