import { IFeaturesDetails } from '@mod/editions/editions.model';
import { Features } from '@const';

export enum LoginNavigationControlValueEnum {
  LastPage = 'LAST_PAGE',
  SelectedPage = 'SELECTED_PAGE'
}

export interface LoginNavigationSettings {
  controlValue: LoginNavigationControlValueEnum;
  selectControlValue: IFeaturesDetails;
}

export interface LoginRedirectPageModel {
  feature: Features | null;
  displayName: string;
  tabName: string;
  displayOption: boolean;
}
