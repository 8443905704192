<div class="trading-panel-order">
  <div class="spinner-container" *ngIf="isAccountsLoading || isProcessing">
    <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
  </div>

  <ng-container *ngIf="!isAccountsLoading && !isProcessing" [ngSwitch]="contentStatus">
    <ng-container *ngSwitchCase="TradingOrderContentStatus.BrokerConnectionMissing" [ngTemplateOutlet]="brokerConnectionMissingTemplate">
    </ng-container>
    <ng-container *ngSwitchCase="TradingOrderContentStatus.Trading" [ngTemplateOutlet]="tradingTemplate">
    </ng-container>
    <ng-container *ngSwitchCase="TradingOrderContentStatus.OperationStatus" [ngTemplateOutlet]="operationStatusTemplate">
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="errorTemplate"></ng-container>
  </ng-container>

  <ng-template #brokerConnectionMissingTemplate>
    <p class="status-text">Connect your broker to PXO to place orders.</p>

    <button (click)="openTradingPanel()" mat-flat-button color="primary" class="main-bg">
      Open Trading Panel
    </button>
  </ng-template>

  <ng-template #tradingTemplate>
    <app-price-ticker [orderInput]="orderInput"></app-price-ticker>

    <mat-tab-group color="primary" mat-stretch-tabs="false" [(selectedIndex)]="tabIndex" class="oco-order-tabs-wrapper">
      <mat-tab label="Market" *ngIf="showMarketTab">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="marketOrderTemplate"></ng-container>
        </ng-template>
      </mat-tab>

      <mat-tab label="Limit" *ngIf="showLimitTab">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="limitOrderTemplate"></ng-container>
        </ng-template>
      </mat-tab>

      <mat-tab label="Stop" *ngIf="showStopTab">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="stopOrderTemplate"></ng-container>
        </ng-template>
      </mat-tab>

      <mat-tab label="One-Cancels-the-Other (OCO)" *ngIf="showOcoTab">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="ocoOrderTemplate"></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <ng-template #marketOrderTemplate>
    <app-option-details [orderInput]="orderInput"></app-option-details>
    <form [formGroup]="marketOrderForm" (ngSubmit)="submitMarketOrder()">
      <div class="from-group">
        <label class="label-title">Qty</label>
        <mat-form-field
          subscriptSizing="dynamic"
          appearance="outline"
          floatLabel="always"
        >
          <input
            matInput
            mask="separator.0"
            thousandSeparator=","
            decimalMarker="."
            separatorLimit="100000"
            type="text"
            placeholder=""
            formControlName="quantity"
            data-ignore-search-popup="true"
            (focus)="onFocus()"
          >
          <mat-error *ngIf="marketOrderForm.controls.quantity.invalid">
            <span *ngIf="marketOrderForm.controls.quantity.errors.required"> This field is mandatory. </span>
            <span *ngIf="marketOrderForm.controls.quantity.errors.min"> This field is mandatory. </span>
            <span *ngIf="marketOrderForm.controls.quantity.errors.max"> Maximum allowed number is 999,999. </span>
          </mat-error>
        </mat-form-field>
        <p *ngIf="orderInput.isQtyNote && !orderInput.option" class="label-sub-title text-light">
          Use shares amount based on your Entry order
        </p>
        <p *ngIf="orderInput.isQtyNote && orderInput.option" class="label-sub-title text-light">
          Use contracts amount based on your Entry order
        </p>
      </div>
      <div class="from-group">
        <label class="label-title">Account</label>
        <mat-form-field
          subscriptSizing="dynamic"
          appearance="outline"
        >
          <mat-select
            hideSingleSelectionIndicator
            class='multiSelect no-bg {{marketOrderForm.controls.accountNumber.invalid ? "red-border" : ""}}'
            formControlName="accountNumber"
            (openedChange)="onFocus()"
          >
            <mat-option
              *ngFor="let account of accounts"
              [value]="account.account_number"
            >
              {{ account.display_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="marketOrderForm.controls.accountNumber.invalid">
            <span> This field is mandatory. </span>
          </mat-error>
        </mat-form-field>
      </div>
      <app-error-message *ngIf="operationError"
        [message]="'Operation failed.'"
        [additionalMessage]="operationError"
      ></app-error-message>
      <app-error-message *ngIf="!hasPrices"
          [message]="'Getting the market data failed. Please try again later.'"
          [additionalMessage]="''"
      ></app-error-message>
      <div class="oco-order-modal-footer">
        <button type="submit" mat-flat-button
          [ngClass]="formClass"
          [disabled]="!((marketOrderForm.valid || marketOrderForm.disabled) && havePricesLoaded && hasPrices)"
          class="full-width"
        >
          <span *ngIf="orderInput.id">
              MODIFY ORDER
          </span>
          <span *ngIf="!orderInput.id && !!orderInput.symbol">
            {{isBuy ? 'BUY' : 'SELL'}} {{marketOrderForm.controls.quantity.invalid ? '' : formatNumber(marketOrderForm.value.quantity)}}
            {{orderInput.symbol.symbol}} MKT
          </span>
          <span *ngIf="!orderInput.id && !!orderInput.option">
            {{isBuy ? 'BUY' : 'SELL'}} {{marketOrderForm.controls.quantity.invalid ? '' : formatNumber(marketOrderForm.value.quantity)}}
            {{orderInput.option.underlyingSymbol}}
            <br>
            {{orderInput.option.expirationDate}} {{formatPrice(orderInput.option.strike)}} {{orderInput.option.type}}
            <br>
            MKT
          </span>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #limitOrderTemplate>
    <app-option-details [orderInput]="orderInput"></app-option-details>
    <form [formGroup]="limitOrderForm" (ngSubmit)="submitLimitOrder()">
      <div class="from-group">
        <label class="label-title">Limit Price</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            placeholder=""
            mask="separator.2"
            thousandSeparator=","
            decimalMarker="."
            separatorLimit="100000"
            formControlName="limitPrice"
            type="text"
            data-ignore-search-popup="true"
            (focus)="onFocus()"
          >
          <mat-error *ngIf="limitOrderForm.controls.limitPrice.invalid">
            <span *ngIf="limitOrderForm.controls.limitPrice.errors.required"> This field is mandatory. </span>
            <span *ngIf="limitOrderForm.controls.limitPrice.errors.min"> This field is mandatory. </span>
            <span *ngIf="limitOrderForm.controls.limitPrice.errors.max"> Maximum allowed number is 999,999.99. </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="from-group">
        <label class="label-title">Qty</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            mask="separator.0"
            thousandSeparator=","
            decimalMarker="."
            separatorLimit="100000"
            type="text"
            placeholder=""
            formControlName="quantity"
            data-ignore-search-popup="true"
            (focus)="onFocus()"
          >
          <mat-error *ngIf="limitOrderForm.controls.quantity.invalid">
            <span *ngIf="limitOrderForm.controls.quantity.errors.required"> This field is mandatory. </span>
            <span *ngIf="limitOrderForm.controls.quantity.errors.min"> This field is mandatory. </span>
            <span *ngIf="limitOrderForm.controls.quantity.errors.max"> Maximum allowed number is 999,999. </span>
          </mat-error>
        </mat-form-field>
        <p *ngIf="orderInput.isQtyNote && !orderInput.option" class="label-sub-title text-light">
          Use shares amount based on your Entry order
        </p>
        <p *ngIf="orderInput.isQtyNote && orderInput.option" class="label-sub-title text-light">
          Use contracts amount based on your Entry order
        </p>
      </div>
      <div class="from-group">
        <label class="label-title">Duration</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-select hideSingleSelectionIndicator class="multiSelect no-bg" formControlName="duration" (openedChange)="onFocus()">
            <mat-option value="{{OrderDuration.Day}}">Day</mat-option>
            <mat-option value="{{OrderDuration.GTC}}">Good-Til-Canceled (GTC)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="from-group">
        <label class="label-title">Account</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-select hideSingleSelectionIndicator
            class='multiSelect no-bg {{limitOrderForm.controls.accountNumber.invalid ? "red-border" : ""}}'
            formControlName="accountNumber"
            (openedChange)="onFocus()"
          >
            <mat-option *ngFor="let account of accounts" [value]="account.account_number">
              {{ account.display_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="limitOrderForm.controls.accountNumber.invalid">
            <span> This field is mandatory. </span>
          </mat-error>
        </mat-form-field>
      </div>
      <app-error-message *ngIf="operationError"
        [message]="'Operation failed.'"
        [additionalMessage]="operationError"
      ></app-error-message>
      <app-error-message *ngIf="!hasPrices"
          [message]="'Getting the market data failed. Please try again later.'"
          [additionalMessage]="''"
      ></app-error-message>
      <div class="oco-order-modal-footer">
        <button type="submit" mat-flat-button
          [ngClass]="formClass"
          [disabled]="!((limitOrderForm.valid || limitOrderForm.disabled) && havePricesLoaded && hasPrices)"
          class="full-width"
        >
          <span *ngIf="orderInput.id">
              MODIFY ORDER
          </span>
          <span *ngIf="!orderInput.id && !!orderInput.symbol">
            {{isBuy ? 'BUY' : 'SELL'}} {{limitOrderForm.controls.quantity.invalid ? '' : formatNumber(limitOrderForm.value.quantity)}}
            {{orderInput.symbol.symbol}} &#64;
            <br/>
            {{limitOrderForm.controls.limitPrice.invalid ? '' : formatPrice(limitOrderForm.value.limitPrice)}} LMT
          </span>
          <span *ngIf="!orderInput.id && !orderInput.symbol">
            {{isBuy ? 'BUY' : 'SELL'}} {{limitOrderForm.controls.quantity.invalid ? '' : formatNumber(limitOrderForm.value.quantity)}}
            {{orderInput.option.underlyingSymbol}}
            <br>
            {{orderInput.option.expirationDate}} {{formatPrice(orderInput.option.strike)}} {{orderInput.option.type}} &#64;
            <br/>
            {{limitOrderForm.controls.limitPrice.invalid ? '' : formatPrice(limitOrderForm.value.limitPrice)}} LMT
          </span>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #stopOrderTemplate>
    <app-option-details [orderInput]="orderInput"></app-option-details>
    <form [formGroup]="stopOrderForm" (ngSubmit)="submitStopOrder()">
      <div class="from-group">
        <label class="label-title">Stop Price</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            placeholder=""
            mask="separator.2"
            thousandSeparator=","
            decimalMarker="."
            separatorLimit="100000"
            formControlName="stopPrice"
            type="text"
            data-ignore-search-popup="true"
            (focus)="onFocus()"
          >
          <mat-error *ngIf="stopOrderForm.controls.stopPrice.invalid">
            <span *ngIf="stopOrderForm.controls.stopPrice.errors.required"> This field is mandatory. </span>
            <span *ngIf="stopOrderForm.controls.stopPrice.errors.min"> This field is mandatory. </span>
            <span *ngIf="stopOrderForm.controls.stopPrice.errors.max"> Maximum allowed number is 999,999.99. </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="from-group">
        <label class="label-title">Qty</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            mask="separator.0"
            thousandSeparator=","
            decimalMarker="."
            separatorLimit="100000"
            type="text"
            placeholder=""
            formControlName="quantity"
            data-ignore-search-popup="true"
            (focus)="onFocus()"
          >
          <mat-error *ngIf="stopOrderForm.controls.quantity.invalid">
            <span *ngIf="stopOrderForm.controls.quantity.errors.required"> This field is mandatory. </span>
            <span *ngIf="stopOrderForm.controls.quantity.errors.min"> This field is mandatory. </span>
            <span *ngIf="stopOrderForm.controls.quantity.errors.max"> Maximum allowed number is 999,999. </span>
          </mat-error>
        </mat-form-field>
        <p *ngIf="orderInput.isQtyNote && !orderInput.option" class="label-sub-title text-light">
          Use shares amount based on your Entry order
        </p>
        <p *ngIf="orderInput.isQtyNote && orderInput.option" class="label-sub-title text-light">
          Use contracts amount based on your Entry order
        </p>
      </div>
      <div class="from-group">
        <label class="label-title">Duration</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-select hideSingleSelectionIndicator class="multiSelect no-bg" formControlName="duration" (openedChange)="onFocus()">
            <mat-option value="{{OrderDuration.Day}}">Day</mat-option>
            <mat-option value="{{OrderDuration.GTC}}">Good-Til-Canceled (GTC)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="from-group">
        <label class="label-title">Account</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-select hideSingleSelectionIndicator class='multiSelect no-bg {{stopOrderForm.controls.accountNumber.invalid ? "red-border" : ""}}'
            formControlName="accountNumber" (openedChange)="onFocus()">
            <mat-option *ngFor="let account of accounts" [value]="account.account_number">
              {{account.display_name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="stopOrderForm.controls.accountNumber.invalid">
            <span> This field is mandatory. </span>
          </mat-error>
        </mat-form-field>
      </div>
      <app-error-message *ngIf="operationError"
        [message]="'Operation failed.'"
        [additionalMessage]="operationError"
      ></app-error-message>
      <app-error-message *ngIf="!hasPrices"
          [message]="'Getting the market data failed. Please try again later.'"
          [additionalMessage]="''"
      ></app-error-message>
      <div class="oco-order-modal-footer">
        <button type="submit" mat-flat-button
          [ngClass]="formClass"
          [disabled]="!((stopOrderForm.valid || stopOrderForm.disabled) && havePricesLoaded && hasPrices)"
          class="full-width"
        >
          <span *ngIf="orderInput.id">
              MODIFY ORDER
          </span>
          <span *ngIf="!orderInput.id">
            {{isBuy ? 'BUY' : 'SELL'}} {{stopOrderForm.controls.quantity.invalid ? '' : formatNumber(stopOrderForm.value.quantity)}}
            {{orderInput.symbol?.symbol || orderInput.option?.underlyingSymbol}} &#64;
            <br/>
            {{stopOrderForm.controls.stopPrice.invalid ? '' : formatPrice(stopOrderForm.value.stopPrice)}} STP
          </span>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #ocoOrderTemplate>
    <app-option-details [orderInput]="orderInput"></app-option-details>
    <form [formGroup]="ocoOrderForm" (ngSubmit)="submitOcoOrder()">
      <div class="from-group">
        <label class="label-title">Qty</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <input
            matInput
            mask="separator.0"
            thousandSeparator=","
            decimalMarker="."
            separatorLimit="100000"
            type="text"
            placeholder=""
            formControlName="quantity"
            data-ignore-search-popup="true"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
            (focus)="onFocus()"
          >
          <mat-error *ngIf="ocoOrderForm.controls.quantity.invalid">
            <span *ngIf="ocoOrderForm.controls.quantity.errors.required"> This field is mandatory. </span>
            <span *ngIf="ocoOrderForm.controls.quantity.errors.min"> This field is mandatory. </span>
            <span *ngIf="ocoOrderForm.controls.quantity.errors.max"> Maximum allowed number is 999,999. </span>
          </mat-error>
        </mat-form-field>
        <p class="label-sub-title text-light">Use shares amount based on your Entry order</p>
      </div>
      <div class="from-group">
        <label class="label-title">Duration</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-select hideSingleSelectionIndicator class="multiSelect no-bg" formControlName="duration" (openedChange)="onFocus()">
            <mat-option value="{{OrderDuration.Day}}">Day</mat-option>
            <mat-option value="{{OrderDuration.GTC}}">Good-Til-Canceled (GTC)</mat-option>
          </mat-select>
        </mat-form-field>
        <p class="label-sub-title text-light">GTC is recommended duration for OCO order</p>
      </div>

      <mat-divider class="divider" role="separator"></mat-divider>

      <div class="from-group-row">
        <div class="from-group">
          <label class="label-title">Take Profit</label>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              placeholder=""
              mask="separator.2"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100000"
              formControlName="takeProfit"
              type="text"
              data-ignore-search-popup="true"
              (focus)="onFocus()"
            >
            <mat-error *ngIf="ocoOrderForm.controls.takeProfit.invalid">
              <span *ngIf="ocoOrderForm.controls.takeProfit.errors.required"> This field is mandatory. </span>
              <span *ngIf="ocoOrderForm.controls.takeProfit.errors.min"> This field is mandatory. </span>
              <span *ngIf="ocoOrderForm.controls.takeProfit.errors.max"> Maximum allowed number is 999,999.99. </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="from-group price">
          <label class="label-title">Price</label>
        </div>
        <div class="from-group">
          <label class="label-title">Stop Loss</label>
          <mat-form-field
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              placeholder=""
              mask="separator.2"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100000"
              formControlName="stopLoss"
              type="text"
              data-ignore-search-popup="true"
              (focus)="onFocus()"
            >
            <mat-error *ngIf="ocoOrderForm.controls.stopLoss.invalid">
              <span *ngIf="ocoOrderForm.controls.stopLoss.errors.required"> This field is mandatory. </span>
              <span *ngIf="ocoOrderForm.controls.stopLoss.errors.min"> This field is mandatory. </span>
              <span *ngIf="ocoOrderForm.controls.stopLoss.errors.max"> Maximum allowed number is 999,999.99. </span>
            </mat-error>
          </mat-form-field>
        </div>
        <p class="label-sub-title text-light">Adjust based on your Entry price</p>
      </div>

      <mat-divider class="divider" role="separator"></mat-divider>

      <div class="from-group">
        <label class="label-title">Account</label>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-select hideSingleSelectionIndicator class='multiSelect no-bg {{ocoOrderForm.controls.accountNumber.invalid ? "red-border" : ""}}'
            formControlName="accountNumber" (openedChange)="onFocus()">
            <mat-option *ngFor="let account of accounts" [value]="account.account_number">
              {{account.display_name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="ocoOrderForm.controls.accountNumber.invalid">
            <span> This field is mandatory. </span>
          </mat-error>
        </mat-form-field>
      </div>
      <app-error-message *ngIf="operationError"
        [message]="'Operation failed.'"
        [additionalMessage]="operationError"
      ></app-error-message>
      <app-error-message *ngIf="!hasPrices"
          [message]="'Getting the market data failed. Please try again later.'"
          [additionalMessage]="''"
      ></app-error-message>
      <div class="oco-order-modal-footer">
        <button type="submit" mat-flat-button
          [ngClass]="formClass"
          [disabled]="!((ocoOrderForm.valid || ocoOrderForm.disabled) && havePricesLoaded && hasPrices)"
          class="full-width"
        >
          <span *ngIf="!!orderInput.symbol">
            {{isBuy ? 'BUY' : 'SELL'}} {{ocoOrderForm.controls.quantity.invalid ? '' : formatNumber(ocoOrderForm.value.quantity)}}
            {{orderInput.symbol.symbol}} &#64;
            <br/>
            {{ocoOrderForm.controls.takeProfit.invalid ? '' : formatPrice(ocoOrderForm.value.takeProfit)}} LMT
            |
            {{ocoOrderForm.controls.stopLoss.invalid ? '' : formatPrice(ocoOrderForm.value.stopLoss)}} STP
          </span>
          <span *ngIf="!orderInput.symbol">
            {{isBuy ? 'BUY' : 'SELL'}} {{ocoOrderForm.controls.quantity.invalid ? '' : formatNumber(ocoOrderForm.value.quantity)}}
            {{orderInput.option.underlyingSymbol}}
            <br>
            {{orderInput.option.expirationDate}} {{formatPrice(orderInput.option.strike)}} {{orderInput.option.type}} &#64;
            <br/>
            {{ocoOrderForm.controls.takeProfit.invalid ? '' : formatPrice(ocoOrderForm.value.takeProfit)}} LMT
            |
            {{ocoOrderForm.controls.stopLoss.invalid ? '' : formatPrice(ocoOrderForm.value.stopLoss)}} STP
          </span>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #errorTemplate>
    <app-error-message></app-error-message>

    <button (click)="openTradingPanel()" mat-flat-button color="primary">Open Trading Panel</button>
  </ng-template>

  <ng-template #operationStatusTemplate>
    <p class="status-text">Order Status: <span [ngClass]="orderStatusCssClass">{{orderStatus}}</span></p>

    <app-error-message *ngIf="operationError"
      [message]="'Operation failed.'"
      [additionalMessage]="operationError"
    ></app-error-message>

    <button *ngIf="!orderInput.id" (click)="openTradingPanel()" mat-flat-button color="primary">
      Open Trading Panel
    </button>

  </ng-template>
</div>
