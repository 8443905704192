export enum TopLevelTabs {
  ScannerAll = 'scanner-all',
  ScannerConservative = 'scanner-conservative',
  ScannerAggressive = 'scanner-aggressive',
  Watchlist = 'watchlist',
  Watchlist2 = 'watchlist2',
  Portfolio = 'portfolio',
}

export interface ITableData {
  id: number;
  security_id: number;
  symbol: string;
  company: string;
  flag: string;
}
