import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, computed, effect, signal, untracked } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InViewportModule } from 'ng-in-viewport';
import * as moment from 'moment/moment';

import { ErrorMessageComponent } from '@m/common/error-message/error-message.component';
import { TradingLogGroupType, TradingLogTransactionStatus, TradingLogTransactionType } from '@t/trading-log';
import { formatDecimalExt, formatNumber, MomentDateTimeFormats, TLDashboardMetricDetailsModalPageSizeOptions } from '@const';
import { MetricDetailsModalData, MetricDetailsModalDisplayData } from '@mdl/metric-details-modal/metric-details-modal.model';
import { TradingLogLogicService } from '@s/trading-log/trading-log-logic.service';
import { TransactionDisplayType } from '@mod/trading-log/metric-details';
import { TradingLogDashboardMetricDetailsGroupModel, TradingLogDashboardReportInfoModel } from '@mod/trading-log';
import _ from 'lodash';
import { StringifiedGuid } from '@t/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FeelingsPanelComponent } from '@c/trading-log/shared/feelings-panel';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { money } from '@u/utils';

@Component({
  standalone: true,
  selector: 'app-metric-details-modal',
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    ErrorMessageComponent,
    MatTooltipModule,
    InViewportModule,
    FeelingsPanelComponent,
    ScrollingModule
  ],
  providers: [TradingLogLogicService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './metric-details-modal.component.html',
  styleUrls: ['./metric-details-modal.component.scss'],
})
export class MetricDetailsModalComponent implements OnInit, AfterViewInit {

  protected money = money;

  protected currentPageTrades: MetricDetailsModalDisplayData;
  protected totalTrades = this.data.sortedTransactions.length;
  protected isDisplayHeaderShadow = false;
  protected isDisplayFooterShadow = false;
  protected showDetailsRow = false;
  protected transactionDisplayNotesState = signal<Record<string, boolean>>({});
  protected showAllNotesToggleState = signal<boolean>(false);

  private isAnyTransactionHasNote = false;
  private _isAllNotesDisaplyed = computed<boolean>(() => {
    const state = this.transactionDisplayNotesState();
    if (!this.isAnyTransactionHasNote) {
      return false;
    }
    const notCheckedTransaction = this.data.sortedTransactions.find((transaction) => (this.isNotesCanBeShownForTransaction(transaction) && !state[transaction.transaction_id]));
    return !notCheckedTransaction;
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: MetricDetailsModalData,
    private _tradingLogLogicService: TradingLogLogicService,
  ) {
    this.isAnyTransactionHasNote = !!this.data.sortedTransactions.find((transaction) => this.isNotesCanBeShownForTransaction(transaction));

    effect(() => {
      const isAllNotesDisaplyed = this._isAllNotesDisaplyed();
      untracked(() => {
        this.showAllNotesToggleState.set(isAllNotesDisaplyed);
      });
    });

    const active = this.retrieveTransactionGroupsByType(
      this.data.sortedGroups,
      this.data.sortedTransactions,
      TradingLogGroupType.Active
    );
    const archived = this.retrieveTransactionGroupsByType(
      this.data.sortedGroups,
      this.data.sortedTransactions,
      TradingLogGroupType.Archived
    );

    this.currentPageTrades = {
      active,
      archived
    };

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  protected getTransactionDisplayType(type: TradingLogTransactionType): TransactionDisplayType {
    return this._tradingLogLogicService.getTransactionDisplayType(type);
  }

  protected getFormattedNumber(value?: number): string {
    return value ? formatNumber(value) : '';
  }

  protected getFormattedDecimalNumber(value: number | null, minDigits: number, maxDigits: number): string {
    return value !== null ? formatDecimalExt(value, minDigits, maxDigits) : '';
  }

  protected getFormattedDate(dateString: MomentDateTimeFormats.ServerDate) {
    return moment(dateString, MomentDateTimeFormats.ServerDate, true).format(MomentDateTimeFormats.ReadableDate);
  }

  protected getFormattedDateByTimestamp(dateTimestamp: MomentDateTimeFormats.ReadableFullTimeWithYear): string {
    return moment(dateTimestamp, MomentDateTimeFormats.ReadableFullTimeWithYear, true).format(MomentDateTimeFormats.ReadableDate);
  }

  protected getFormattedTime(dateTimestamp: MomentDateTimeFormats.ReadableFullTimeWithYear): string {
    const formattedTime = moment(dateTimestamp, MomentDateTimeFormats.ReadableFullTimeWithYear, true)
      .format(MomentDateTimeFormats.ReadableTime);
    return formattedTime === '00:00' ? '' : formattedTime;
  }

  protected getTransactionStatusText(status: TradingLogTransactionStatus): string {
    return this._tradingLogLogicService.getTransactionStatusText(status);
  }

  private retrieveTransactionGroupsByType(
    allGroups: TradingLogDashboardMetricDetailsGroupModel[],
    transactions: TradingLogDashboardReportInfoModel[],
    type: TradingLogGroupType
  ): TradingLogDashboardMetricDetailsGroupModel[] {
    const parsedGroups = allGroups
      .filter((g) => g.type === type)
      .reduce((acc, curr) => {
        const groupWithTransactions = { ...curr, usedTransactions: transactions.filter((t) => t.group_id === curr.id) };

        if (groupWithTransactions.usedTransactions.length) {
          if (groupWithTransactions.min_date === groupWithTransactions.max_date) {
            groupWithTransactions.max_date = null;
          }

          acc.push(groupWithTransactions);
        }

        return acc;
      }, [] as TradingLogDashboardMetricDetailsGroupModel[]);

    return parsedGroups;
  }

  protected toggleDetailsRowVisibility(transactionId: StringifiedGuid) {
    const currentState = { ...this.transactionDisplayNotesState() };
    currentState[transactionId] = !currentState[transactionId]
    this.transactionDisplayNotesState.set(currentState);

  }

  protected isNotesCanBeShownForTransaction(transaction: TradingLogDashboardReportInfoModel): boolean {
    return !!(transaction.decision || transaction.confidence || transaction.note);
  }

  protected changeAllNotesToggleState(): void {
    this.showAllNotesToggleState.set(!this.showAllNotesToggleState());

    const state = this.showAllNotesToggleState();

    const newState: Record<string, boolean> = {};
    if (state) {
      this.data.sortedTransactions.forEach((transaction) => {
        if (this.isNotesCanBeShownForTransaction(transaction)) {
          newState[transaction.transaction_id] = true
        }
      });
    }
    this.transactionDisplayNotesState.set(newState);
  }

  public onChangeVisibility(
    { target, visible }: { target: Element; visible: boolean },
    type: 'top' | 'bottom'
  ): void {
    if (visible) {
      if (type === 'top') {
        this.isDisplayHeaderShadow = false;
      } else {
        this.isDisplayFooterShadow = false;
      }
    } else {
      if (type === 'top') {
        this.isDisplayHeaderShadow = true;
      } else {
        this.isDisplayFooterShadow = true;
      }
    }
  }
}
