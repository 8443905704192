import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../core/interface/response';
import { RestRequestorService } from './rest-requestor.service';
import { StorageKeys } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SplitDataService {

  constructor(private http: HttpClient,
    private restRequestorService: RestRequestorService) { }

  get = async (security_id: number) => {
    const { result } = await this.restRequestorService.makeRequest(`${StorageKeys.Splits}_${security_id}`,
      () => this.http.get<Response>(`/v2/splitData?security_id=${security_id}`).toPromise());

    const splits = (result || []).map((data) => ({
      ...data,
      split: data.split?.split('/').map((ratio) => parseFloat(ratio)).join('/')
    }));

    return splits;
  }
}
