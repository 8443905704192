// avoid using ID to reduce size of data to save
export interface ITask {
  content: string;
  order: number;
  status: TaskStatuses;
}

export enum TaskStatuses {
  notCompleted = 0,
  completed = 1,
}

export enum TradingHubEventEventNames {
  addTask = 'addTask',
}

export interface IAddTaskEvent {
  event: TradingHubEventEventNames.addTask;
  data: null;
}

export enum TradingHubMode {
  Default = 'default',
  Help = 'help',
}

export type ITradingHubEvent = IAddTaskEvent;

