import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, from, Subject, Subscriber } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ServerDataService } from '@s/server-data.service';
import { TradingHubService } from '@s/trading-hub.service';
import { UserDataService } from '@s/user-data.service';
import { ObservableService } from '@s/observable.service';
import { ServerSettings, UserSettings } from '@const';
import { calculateProgress } from '@c/trading-hub/trading-hub.helpers';
import {
  ITask,
  TaskStatuses,
  TradingHubEventEventNames,
} from '@c/trading-hub/trading-hub.model';

@Component({
  selector: 'app-pre-trading-checklist',
  templateUrl: './pre-trading-checklist.component.html',
  styleUrls: ['./pre-trading-checklist.component.scss'],
})
export class PreTradingChecklistComponent implements OnInit, OnDestroy {
  public isLoading = true;

  public progress: number | null = null;
  public isThereCompletedTasks = false;
  public isThereNotCompletedTasks = false;

  public isOpenOnStart = true;
  public tasks: ITask[] = [];

  public defaultTasks: string[] = [];

  private saveTasks$ = new Subject<ITask[]>();
  private saveIsOpenOnStart$ = new Subject<boolean>();
  private subscriber = new Subscriber();

  constructor(
    private serverDataService: ServerDataService,
    private tradingHubService: TradingHubService,
    private userDataService: UserDataService,
    private observableService: ObservableService,
  ) { }

  ngOnInit() {
    this.subscriber.add(
      this.saveIsOpenOnStart$
        .pipe(
          debounceTime(600),
          distinctUntilChanged(),
          switchMap((isOpen) => this.userDataService.set(UserSettings.PreTradingChecklistOpenOnAppStart, isOpen)),
        )
        .subscribe()
    );

    this.subscriber.add(
      this.saveTasks$
        .pipe(
          tap((tasks) => this.observableService.preTradingChecklistTasks.next(tasks)),
          debounceTime(600),
          switchMap((tasks) => this.userDataService.set(UserSettings.PreTradingChecklistTasks, tasks)),
        )
        .subscribe()
    );

    // restore saved settings on component open
    this.subscriber.add(
      combineLatest([
        this.observableService.preTradingChecklistOpenOnAppStart,
        this.observableService.preTradingChecklistTasks,
        from(this.serverDataService.getAsObject<string[]>(ServerSettings.PreTradingHubDefaultChecklist)),
      ])
        .pipe(
          take(1),
          tap(([isOpenOnStart, tasks, defaultTasks]) => {
            this.isOpenOnStart = isOpenOnStart;

            this.defaultTasks = defaultTasks ?? [];
            this.tasks = (Boolean(tasks) && Array.isArray(tasks))
              ? tasks
              : Array.from(this.defaultTasks).map((taskContent, index) => ({
                content: String(taskContent),
                order: index,
                status: TaskStatuses.notCompleted,
              }));

            // update in observableService first time to avoid waiting for user-data saving response
            this.observableService.preTradingChecklistTasks.next(this.tasks);
            this.saveTasks$.next(this.tasks);

            this.updateProgress();
            this.isLoading = false;
          }),
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  public onChangeIsOpenOnStar(isOpen: boolean): void {
    this.isOpenOnStart = isOpen;
    this.saveIsOpenOnStart$.next(isOpen);
  }

  public updateProgress(): void {
    this.progress = calculateProgress(this.tasks);
    this.tradingHubService.preTradingProgress$.next(this.progress);

    this.isThereCompletedTasks = this.tasks.some((item) => item.status === TaskStatuses.completed);
    this.isThereNotCompletedTasks = this.tasks.some((item) => item.status === TaskStatuses.notCompleted);
  }

  public updateTasks(newTasks: ITask[]): void {
    this.tasks = newTasks;
    this.saveTasks$.next(newTasks);
    this.updateProgress();
  }

  public addTask(): void {
    // add task inside to-do-list, it can make it focused
    // to make it possible for user to start typing without click on it
    this.tradingHubService.preTradingChecklistEvents$.next({
      event: TradingHubEventEventNames.addTask,
      data: null,
    });
  }

  public markAllAsCompleted(): void {
    const updatedTasks = this.tasks.map((item) => ({
      ...item,
      status: TaskStatuses.completed,
    }));

    this.updateTasks(updatedTasks);
  }

  public markAllAsNotCompleted(): void {
    const updatedTasks = Array.from(this.tasks).map((item) => ({
      ...item,
      status: TaskStatuses.notCompleted,
    }));

    this.updateTasks(updatedTasks);
  }

  public resetToDefault(): void {
    const updatedTasks = Array.from(this.defaultTasks).map((taskContent, index) => ({
      content: taskContent,
      order: index,
      status: TaskStatuses.notCompleted,
    }));

    this.updateTasks(updatedTasks);
  }

  public undoChanges(): void { }

  public redoChanges(): void { }
}
