import { ISymbolFlagIcon, ISymbolStatistic } from '@c/shared/symbol-flag/symbol-flag.model';
import { Flags, ISymbolStatisticItemRaw } from '@mod/symbol-smiley/symbol-smiley.model';

const DEFAULT_COUNT = 0;
const DEFAULT_PERCENT = 0;

export const defaultSymbolStatisticRaw: ISymbolStatisticItemRaw[] = [
  { flag: Flags.None, count: DEFAULT_COUNT },
  { flag: Flags.No, count: DEFAULT_COUNT },
  { flag: Flags.Yes, count: DEFAULT_COUNT },
  { flag: Flags.Maybe, count: DEFAULT_COUNT },
  { flag: Flags.Never, count: DEFAULT_COUNT },
];

export const defaultSymbolStatistic: ISymbolStatistic = {
  [Flags.None]: { count: DEFAULT_COUNT, formattedCount: DEFAULT_COUNT, formattedShortCount: DEFAULT_COUNT, percent: DEFAULT_PERCENT },
  [Flags.No]: { count: DEFAULT_COUNT, formattedCount: DEFAULT_COUNT, formattedShortCount: DEFAULT_COUNT, percent: DEFAULT_PERCENT },
  [Flags.Yes]: { count: DEFAULT_COUNT, formattedCount: DEFAULT_COUNT, formattedShortCount: DEFAULT_COUNT, percent: DEFAULT_PERCENT },
  [Flags.Maybe]: { count: DEFAULT_COUNT, formattedCount: DEFAULT_COUNT, formattedShortCount: DEFAULT_COUNT, percent: DEFAULT_PERCENT },
  [Flags.Never]: { count: DEFAULT_COUNT, formattedCount: DEFAULT_COUNT, formattedShortCount: DEFAULT_COUNT, percent: DEFAULT_PERCENT },
};

export const flagIcons: Record<Flags, ISymbolFlagIcon> = {
  [Flags.None]: { name: 'emotions-none-icon', class: 'none-icon' },
  [Flags.No]: { name: 'emotions-no-icon', class: 'no-icon' },
  [Flags.Maybe]: { name: 'emotions-maybe-icon', class: 'maybe-icon' },
  [Flags.Yes]: { name: 'emotions-yes-icon', class: 'yes-icon' },
  [Flags.Never]: { name: 'emotions-never-icon', class: 'never-icon' },
};
