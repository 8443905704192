export const DEFAULT_FILTER_SHORTCUTS = 'KTBM';

export const MIN_VOLUME_VALUE = 0;
export const MAX_VOLUME_VALUE = 100000000000000;

export const MIN_MARKET_CAP_VALUE = 0;
export const MAX_MARKET_CAP_VALUE = 100000000000000;

export const MIN_STOCK_PRICE_VALUE = 0;
export const MAX_STOCK_PRICE_VALUE = 999999.99;

export const MIN_PROFIT_FACTOR_VALUE = 0;
export const MAX_PROFIT_FACTOR_VALUE = 99.99;

export const MIN_WIN_PERCENT_VALUE = 0;
export const MAX_WIN_PERCENT_VALUE = 99.99;

export const MIN_CHANGE_PERCENT_VALUE = -999.99;
export const MAX_CHANGE_PERCENT_VALUE = 999.99;

export const MIN_DROP_PERCENT_VALUE = 0;
export const MAX_DROP_PERCENT_VALUE = 999.99;

export const MIN_ROI_VALUE = 0;
export const MAX_ROI_VALUE = 999999.99;

export const MIN_PREMIUM_VALUE = 0;
export const MAX_PREMIUM_VALUE = 99.99;

export const MIN_DIV_PER_SHAPE_VALUE = 0;
export const MAX_DIV_PER_SHAPE_VALUE = 99.99;

export const MIN_DIV_YIELD_PERCENT_VALUE = 0;
export const MAX_DIV_YIELD_PERCENT_VALUE = 99.99;

export const MIN_AVG_BREAK_EVEN_DAYS_VALUE = 0;
export const MAX_AVG_BREAK_EVEN_DAYS_VALUE = 999999.99;

export const MIN_TRADES_COUNT_VALUE = 0;
export const MAX_TRADES_COUNT_VALUE = 999;
