import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriber } from 'rxjs';

import { ObservableService } from '@s/observable.service';
import { PrintOptions, Themes, ThemesNumValues, tradeReportMaxWidthForPrint, UserSettings } from '@const';
import { UserDataService } from '@s/user-data.service';
import { TradingChartService } from '@s/trading-chart.service';

@Component({
  selector: 'app-print-data',
  templateUrl: './print-data.component.html',
  styleUrls: ['./print-data.component.scss']
})
export class PrintDataComponent implements OnInit, OnDestroy {

  constructor(
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private tradingChartService: TradingChartService
  ) {}

  public printOptions = [];
  public printOptionType = PrintOptions;
  public isReportFitOnScreen = true;
  private chartTab;
  private theme;
  private subscription = new Subscriber();

  async ngOnInit() {
    this.printOptions = this.observableService.printOptions$.getValue();
    this.chartTab = await this.userDataService.get(UserSettings.ChartTab);
    this.theme = this.observableService.mySettings.getValue().theme;

    // timeout is needed to wait until img is loaded
    setTimeout(() => {
      if (!this.printOptions.includes(this.printOptionType.TradingPlan)) {
        this.subscription.add(
          this.observableService.isTradingChartInitialize$.subscribe(async (isChartInitialize) => {
            if (isChartInitialize) {
              await this.prepareChartAndPrint();
              this.rollbackChanges();
            }
          })
        );
      } else {
        this.printTradingPlan();
        this.rollbackChanges();
      }
    }, 100);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async prepareChartAndPrint() {
    const tradeReportTableWidth = document.getElementById('tradeReportPrint')?.clientWidth;
    if (tradeReportTableWidth) {
      this.isReportFitOnScreen = tradeReportTableWidth < tradeReportMaxWidthForPrint;
    }

    document.getElementsByTagName('body')[0].classList.remove('black-theme');
    const chart = await this.tradingChartService.takeChartScreenshot();
    const chartPrint = document.getElementById('print-chart');
    if (chartPrint) {
      chartPrint.appendChild(chart);
    }
    if (chart) {
      window.print();
    }
  }

  printTradingPlan() {
    document.getElementsByTagName('body')[0].classList.remove('black-theme');
    window.print();
  }

  rollbackChanges() {
    this.observableService.chartTab.next(this.chartTab);
    if (this.theme === ThemesNumValues.Dark) {
      this.observableService.theme.next(Themes.Dark);
      document.getElementsByTagName('body')[0].classList.add('black-theme');
    }
    this.observableService.isNeedPrint$.next(false);
  }
}
