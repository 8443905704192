import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DefaultInitialPageUrl, DefaultInitialTab, Features, TabNames, UserSettings } from '@const';
import { EditionsService } from '@s/editions.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditionGuard {
  constructor(
    private editionsService: EditionsService,
    private observableServiceV2: ObservableServiceV2,
    private userDataService: UserDataService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const feature = route.data.feature as Features;
    const isDemoPage = route.data.isDemoPage as boolean;
    const hasAccess = this.editionsService.isFeatureAvailable(feature);
    const featureDetails = EditionsService.featuresDetails[feature];

    if (!hasAccess) {
      // disable active tab for header
      this.setActiveTab(TabNames.EditionsDemo);
    }

    // if it's demo-page and current user has access to feature => redirect to feature page
    if (isDemoPage && hasAccess) {
      this.setActiveTab(featureDetails.tabName ?? DefaultInitialTab);

      return this.router.navigate([featureDetails.pageURL ?? DefaultInitialPageUrl], { replaceUrl: true });
    }

    // if it's feature-page and current user has no access to feature => redirect to demo-page
    if (!isDemoPage && !hasAccess) {
      return this.router.navigate([EditionsService.featuresDetails[feature].demoPageURL], { replaceUrl: true });
    }

    return true;
  }

  private setActiveTab(tabName: string): void {
    localStorage.setItem('activeTab', tabName);
    this.observableServiceV2.activeTab.next(tabName);
    this.userDataService.set(UserSettings.ActiveTab, tabName);
  }
}
