import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule, PaginatePipeArgs } from 'ngx-pagination';
import { BehaviorSubject, combineLatest, debounceTime, switchMap, tap } from 'rxjs';
import { UserListItemModel } from '@mod/admin';
import { UserListFormModel } from './models';
import { UsersService } from '@s/users.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-support-access-modal',
  templateUrl: './support-access-modal.component.html',
  styleUrls: ['./support-access-modal.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
  ]
})
export class SupportAccessModalComponent implements OnInit {
  static PAGE_SIZE = 5;

  protected userListForm = this._formBuilder.group<UserListFormModel>({
    filter: ''
  });

  private readonly _allUsersPage = 1;
  protected allUsersPagination: PaginatePipeArgs = {
    id: 'allUsersPagination',
    itemsPerPage: SupportAccessModalComponent.PAGE_SIZE,
    currentPage: this._allUsersPage,
    totalItems: 0
  };

  protected allUsersLoading = false;
  protected allUsersPageNumber$: BehaviorSubject<number> = new BehaviorSubject(this._allUsersPage);
  protected allUsers: Array<UserListItemModel> = [];

  protected supportedUsersLoading = true;
  protected supportedUsers: Array<UserListItemModel> = [];

  protected selectedToRemoveUserIds: Array<number> = [];
  protected selectedToAddUserIds: Array<number> = [];

  protected isProcessing = false;

  private readonly _data: Partial<UserListItemModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Partial<UserListItemModel>,
    private _dialogRef: MatDialogRef<SupportAccessModalComponent>,
    private _formBuilder: FormBuilder,
    private _usersService: UsersService,
  ) {
    this._data = data;
  }

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.userListForm.valueChanges.pipe(debounceTime(500), tap(() => this.allUsersPageNumber$.next(this._allUsersPage))),
      this.allUsersPageNumber$.pipe(tap((pageNumber) => this.allUsersPagination.currentPage = pageNumber))
    ])
      .pipe(
        debounceTime(0),
        tap(() => this.allUsersLoading = true),
        switchMap(([userListModel, pageNumber]) =>
          this._usersService.getList({
            page_number: pageNumber,
            page_size: SupportAccessModalComponent.PAGE_SIZE,
            filter: userListModel.filter ?? '',
            user_access_type: ''
          })),
        tap(() => this.allUsersLoading = false)
      )
      .subscribe((res) => {
        if (res) {
          this.allUsers = res.users as Array<UserListItemModel>;
          this.allUsersPagination.totalItems = res.total_count;
        }
      });

    this.userListForm.updateValueAndValidity({ emitEvent: true });

    await this.loadSupportedUsers();
  }

  private async loadSupportedUsers(): Promise<void> {
    this.supportedUsersLoading = true;

    this._usersService.getSupportList({
      support_user_id_v2: this._data.id,
    }).subscribe((res) => {
      this.supportedUsersLoading = false;
      if (res) {
        this.supportedUsers = res.users as Array<UserListItemModel>;
      }
    });
  }

  protected selectUserToRemove(user: UserListItemModel): void {
    if (this.isSelected(user, this.selectedToRemoveUserIds)) {
      this.selectedToRemoveUserIds = this.selectedToRemoveUserIds.filter((id) => id !== user.id);
    } else {
      this.selectedToRemoveUserIds.push(user.id);
    }
  }

  protected selectUserToAdd(user: UserListItemModel): void {
    if (this.isSupported(user)) {
      return;
    }

    if (this.isSelected(user, this.selectedToAddUserIds)) {
      this.selectedToAddUserIds = this.selectedToAddUserIds.filter((id) => id !== user.id);
    } else {
      this.selectedToAddUserIds.push(user.id);
    }
  }

  protected isSelected(user: UserListItemModel, selectedUserIds: Array<number>): boolean {
    return !!selectedUserIds.some((id) => id === user.id);
  }

  protected isSupported(user: UserListItemModel): boolean {
    return !!this.supportedUsers.some((supportedUser: UserListItemModel) => supportedUser.id === user.id);
  }

  protected hasAnyUsersToAdd(): boolean {
    return this.allUsers.some(({ id: addId }) => !this.supportedUsers.some(({ id: addedId }) => addId === addedId));
  }

  protected async add(isAll: boolean): Promise<void> {
    this.isProcessing = true;

    const users = isAll
      ? this.allUsers.map((user) => ({ ...user }))
      : this.allUsers.filter((user: UserListItemModel) => !!this.selectedToAddUserIds.some((id) => id === user.id));

    await this._usersService.add(this._data.id, users.map(({ id }) => id));

    this.selectedToAddUserIds = [];

    this.isProcessing = false;

    await this.loadSupportedUsers();
  }

  protected async remove(isAll: boolean): Promise<void> {
    this.isProcessing = true;

    const users = isAll
      ? this.supportedUsers.map((user) => ({ ...user }))
      : this.supportedUsers.filter((user: UserListItemModel) => !!this.selectedToRemoveUserIds.some((id) => id === user.id));

    await this._usersService.remove(this._data.id, users.map(({ id }) => id));

    this.selectedToRemoveUserIds = [];

    this.isProcessing = false;

    await this.loadSupportedUsers();
  }

  protected close() {
    this._dialogRef.close({ isCanceled: true });
  }
}
