import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VideoHubCacheService {
  private cache = new Map<string, any>();

  constructor() {}

  public set(key: string, data: any): void {
    this.cache.set(key, data);
  }

  public get(key: string): any {
    return this.cache.get(key);
  }

  public clear(key: string): void {
    this.cache.delete(key);
  }

  public clearAll(): void {
    this.cache.clear();
  }
}
