<section>
  <div class="licence-agreement-page">
    <div class="licence-agreement-section">
      <img src="../../../assets/img/Power-x.svg" alt="powerx-logo" class="header-powerx-logo">
      <div class="user-agreement">
        <div class="m-b-16">
          <span class="main-agreement-heading">Rockwell Trading Software License Agreement</span>
        </div>
        <h4 class="agreement-info">This is legal agreement between you and Rockwell Trading</h4>

        <app-license-agreement-text></app-license-agreement-text>

        <div class="agreement-checkbox">
          <mat-checkbox color="primary" class="example-margin" [(ngModel)]="agreementCheckbox">I have read and agreed to be bound by
            Agreement above. I also confirm that I am of legal age of majority in the jurisdiction in which I reside
            (at least 18 years of age in many countries).
          </mat-checkbox>
        </div>
      </div>
      <div class="agreement-action-btns">
        <button
          mat-flat-button
          color="primary"
          class="i-agree-btn"
          [disabled]="agreementCheckbox?false:true"
          (click)="licenceAgreed()"
        >
          <mat-spinner class="white-spinner" *ngIf="isLoading" diameter="20"></mat-spinner>
          <span *ngIf="!isLoading">I Agree</span>
        </button>
        <button mat-stroked-button (click)="licenceclosed()" class="cancel-btn">Cancel</button>
      </div>
    </div>
  </div>
</section>
