<h2 mat-dialog-title>
  My Settings
  <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1">
    <img mat-dialog-close [src]="crossSvg" class="popup-close-btn-img" alt="Close">
  </button>
</h2>

<div class="my-setting__wrapper">

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" fitInkBarToContent class="my-setting-tabs"
    animationDuration="0ms" [(selectedIndex)]="tabIndex">

    <mat-tab label="Flags">
      <ng-container [ngTemplateOutlet]="tabContainer"></ng-container>
    </mat-tab>

    <mat-tab label="Integration">
      <ng-container [ngTemplateOutlet]="tabContainer"></ng-container>
    </mat-tab>

    <mat-tab label="Display">
      <ng-container [ngTemplateOutlet]="tabContainer"></ng-container>
    </mat-tab>

    <mat-tab label="Account">
      <ng-container [ngTemplateOutlet]="tabContainer"></ng-container>
    </mat-tab>

    <mat-tab label="Sounds">
      <ng-container [ngTemplateOutlet]="tabContainer"></ng-container>
    </mat-tab>

    <mat-tab label="Trading Log">
      <ng-container [ngTemplateOutlet]="tabContainer"></ng-container>
    </mat-tab>

  </mat-tab-group>
</div>

<ng-template #tabContainer>
  <mat-dialog-content class="mat-typography" id="my-settings-content"
    [ngClass]="{ 'account-tab-content': tabIndex === 2 }">
    <ng-container [ngSwitch]="tabIndex">
      <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="flagsTab"></ng-container>
      <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="integrationTab"></ng-container>
      <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="displayTab"></ng-container>
      <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="accountTab"></ng-container>
      <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="soundsTab"></ng-container>
      <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="tradingLogTab"></ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="flagsTab"></ng-container>
    </ng-container>
  </mat-dialog-content>
</ng-template>

<ng-template #flagsTab>
  <div class="my-setting-content">
    <h3>Flag settings</h3>

    <ng-container *appHideForUnavailableFeature="features.SmileyStatistics">
      <mat-slide-toggle class="large-slide-toggle" color="primary" [checked]="showSmileyStatistic"
        (change)="onChangeSmileyStatistic($event)">
        Show flag votes
      </mat-slide-toggle>
      <div class="description">
        Once activated, it shows the votes of other mastermind members.
      </div>
    </ng-container>

    <ng-container *appHideForUnavailableFeature="features.PowerX">
      <label id="powerx-analyzer">PowerX Analyzer</label>
      <mat-radio-group color="primary" aria-labelledby="wheel-analyzer" class="my-setting-radio-group"
        [(ngModel)]="mySettingsFlags.reset_flag_pxo">
        <mat-radio-button disableRipple="true" *ngFor="let item of powerxAnalyzerOptions"
          class="my-setting-radio-button" [value]="item.value" (change)="resetFlagPowerX($event)">
          {{ item.name }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="btns-group">
        <button mat-stroked-button class="border-btn large-btn my-setting-reset" type="button" tabindex="-1"
          (click)="resetFlagsPowerx()" [disabled]="resetPowerXFlagsLoading">
          <mat-spinner class="blue-spinner" *ngIf="resetPowerXFlagsLoading" diameter="20"></mat-spinner>
          <span *ngIf="!resetPowerXFlagsLoading">Reset all flags now</span>
        </button>
        <span *ngIf="powerxSuccess" class="success">Flags were reset successfully</span>
        <span *ngIf="powerxError" class="error">Operation failed. Please try again later.</span>
      </div>
    </ng-container>

    <ng-container *appHideForUnavailableFeature="features.Wheel">
      <label id="wheel-analyzer">The Wheel Analyzer</label>
      <mat-radio-group color="primary" aria-labelledby="wheel-analyzer" class="my-setting-radio-group"
        [(ngModel)]="mySettingsFlags.reset_flag_wheel">
        <mat-radio-button disableRipple="true" *ngFor="let item of wheelAnalyzerOptions" class="my-setting-radio-button"
          [value]="item.value" (change)="resetFlagWheel($event)">
          {{ item.name }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="btns-group">
        <button mat-stroked-button class="border-btn large-btn my-setting-reset" type="button" tabindex="-1"
          (click)="resetFlagsWheel()" [disabled]="resetWheelFlagsLoading">
          <mat-spinner class="blue-spinner" *ngIf="resetWheelFlagsLoading" diameter="20"></mat-spinner>
          <span *ngIf="!resetWheelFlagsLoading">Reset all flags now</span>
        </button>
        <span *ngIf="wheelSuccess" class="success">Flags were reset successfully</span>
        <span *ngIf="wheelError" class="error">Operation failed. Please try again later.</span>
      </div>
    </ng-container>

    <ng-container *appHideForUnavailableFeature="features.Wtf">
      <label id="wtf-analyzer">WTF Analyzer</label>
      <mat-radio-group color="primary" aria-labelledby="wtf-analyzer" class="my-setting-radio-group"
        [(ngModel)]="mySettingsFlags.reset_flag_wtf">
        <mat-radio-button disableRipple="true" *ngFor="let item of wtfAnalyzerOptions" class="my-setting-radio-button"
          [value]="item.value" (change)="resetFlagWtf($event)">
          {{ item.name }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="btns-group">
        <button mat-stroked-button class="border-btn large-btn my-setting-reset" type="button" tabindex="-1"
          (click)="resetFlagsWtf()" [disabled]="resetWtfFlagsLoading">
          <mat-spinner class="blue-spinner" *ngIf="resetWtfFlagsLoading" diameter="20"></mat-spinner>
          <span *ngIf="!resetWtfFlagsLoading">Reset all flags now</span>
        </button>
        <span *ngIf="wtfSuccess" class="success">Flags were reset successfully</span>
        <span *ngIf="wtfError" class="error">Operation failed. Please try again later.</span>
      </div>
    </ng-container>

    <ng-container *ngIf="showStockScreener">
      <ng-container *appHideForUnavailableFeature="features.StockScreeners">
        <label id="stock-screeners">Stock Screeners</label>
        <mat-radio-group color="primary" aria-labelledby="wtf-analyzer" class="my-setting-radio-group"
          [(ngModel)]="mySettingsFlags.reset_flag_stock_screener">
          <mat-radio-button disableRipple="true" *ngFor="let item of stockScreenersOptions"
            class="my-setting-radio-button" [value]="item.value" (change)="resetFlagStockScreeners($event)">
            {{ item.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="btns-group">
          <button mat-stroked-button class="border-btn large-btn my-setting-reset" type="button" tabindex="-1"
            (click)="resetFlagsStockScreeners()" [disabled]="resetStockScreenersFlagsLoading">
            <mat-spinner class="blue-spinner" *ngIf="resetStockScreenersFlagsLoading" diameter="20"></mat-spinner>
            <span *ngIf="!resetStockScreenersFlagsLoading">Reset all flags now</span>
          </button>
          <span *ngIf="stockScreenersSuccess" class="success">Flags were reset successfully</span>
          <span *ngIf="stockScreenersError" class="error">Operation failed. Please try again later.</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *appHideForUnavailableFeature="features.DividendsStrategy">
      <label id="dividends-analyzer">Dividends Strategy</label>
      <mat-radio-group color="primary" aria-labelledby="wtf-analyzer" class="my-setting-radio-group"
        [(ngModel)]="mySettingsFlags.reset_flag_dividends">
        <mat-radio-button disableRipple="true" *ngFor="let item of dividendsStrategyOptions"
          class="my-setting-radio-button" [value]="item.value" (change)="resetFlagDividendsStrategy($event)">
          {{ item.name }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="btns-group">
        <button
          mat-stroked-button
          class="border-btn large-btn my-setting-reset"
          type="button"
          tabindex="-1"
          (click)="resetFlagsDividendsStrategy()"
          [disabled]="resetDividendsStrategyFlagsLoading"
        >
          <mat-spinner class="blue-spinner" *ngIf="resetDividendsStrategyFlagsLoading" diameter="20"></mat-spinner>
          <span *ngIf="!resetDividendsStrategyFlagsLoading">Reset all flags now</span>
        </button>
        <span *ngIf="dividendsStrategySuccess" class="success">Flags were reset successfully</span>
        <span *ngIf="dividendsStrategyError" class="error">Operation failed. Please try again later.</span>
      </div>
    </ng-container>

    <ng-container>
      <ng-container *appHideForUnavailableFeature="features.ShortSellingStocks">
        <label id="short-selling-stocks">SOL Analyzer</label>
        <mat-radio-group color="primary" aria-labelledby="short-selling-stocks" class="my-setting-radio-group"
          [(ngModel)]="mySettingsFlags.reset_flag_short_selling_stocks">
          <mat-radio-button disableRipple="true" *ngFor="let item of shortSellingStocksOptions"
            class="my-setting-radio-button" [value]="item.value" (change)="resetFlagShortSellingStocks($event)">
            {{ item.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="btns-group">
          <button mat-stroked-button class="border-btn large-btn my-setting-reset" type="button" tabindex="-1"
            (click)="resetFlagsShortSellingStocks()" [disabled]="resetShortSellingStocksFlagsLoading">
            <mat-spinner class="blue-spinner" *ngIf="resetShortSellingStocksFlagsLoading" diameter="20"></mat-spinner>
            <span *ngIf="!resetShortSellingStocksFlagsLoading">Reset all flags now</span>
          </button>
          <span *ngIf="shortSellingStocksSuccess" class="success">Flags were reset successfully</span>
          <span *ngIf="shortSellingStocksError" class="error">Operation failed. Please try again later.</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *appHideForUnavailableFeature="features.ShortingStocksScanner">
      <label id="shorting-stocks-scanner">Shorting Scanner</label>
      <mat-radio-group color="primary" aria-labelledby="short-selling-stocks" class="my-setting-radio-group"
        [(ngModel)]="mySettingsFlags.reset_flag_shorting_stocks_scanner">
        <mat-radio-button disableRipple="true" *ngFor="let item of shortingStocksScannerOptions"
          class="my-setting-radio-button" [value]="item.value" (change)="resetFlagShortingStocksScanner($event)">
          {{ item.name }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="btns-group">
        <button mat-stroked-button class="border-btn large-btn my-setting-reset" type="button" tabindex="-1"
          (click)="resetFlagsShortingStocksScanner()" [disabled]="resetShortingStocksScannerFlagsLoading">
          <mat-spinner class="blue-spinner" *ngIf="resetShortingStocksScannerFlagsLoading" diameter="20"></mat-spinner>
          <span *ngIf="!resetShortingStocksScannerFlagsLoading">Reset all flags now</span>
        </button>
        <span *ngIf="shortingStocksScannerSuccess" class="success">Flags were reset successfully</span>
        <span *ngIf="shortingStocksScannerError" class="error">Operation failed. Please try again later.</span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #integrationTab>
  <div class="my-setting-content">
    <h3>Integration</h3>
    <div class="title-desc">List of trading platform to be integrated.</div>
    <div class="title-desc">
      In order to use Tradier Integration please check that: <br>
      1. You have an open account with <a href="https://www.rockwelltrading.com/free/10-95-tradier-broker-launch/"
        target="_blank">Tradier</a> (can be a demo account) <br>
      <div *ngIf="!tradierFlags.tradier_flag_ifs">
        2. You activated the integration via this <a href="https://www.rockwelltrading.com/tradier-integration/"
          target="_blank">simple
          form</a>
      </div>
    </div>
    <mat-slide-toggle class="large-slide-toggle" *ngIf="tradierFlags.tradier_flag_ifs"
      [checked]="tradierFlags.tradier_flag_pxo === 1" (change)="tradierFlagPowerX($event)">
      Tradier
    </mat-slide-toggle>
    <div class="second-description" *ngIf="tradierFlags.tradier_flag_ifs">
      Once activated, it shows buttons for placing pre-filled orders for stock and options.
      Having Tradier account is required.
    </div>
    <div class="not-a-member" *ngIf="!tradierFlags.tradier_flag_ifs">
      Tradier integration is not activated yet for your account. <br>
      Please follow <a href="https://www.rockwelltrading.com/tradier-integration/" target="_blank">this link to activate
        the integration</a>.
    </div>
  </div>
</ng-template>

<ng-template #displayTab>
  <app-range-slider *ngIf="tabIndex === 2" [value]="wheelROIRangeInputs.lower" [highValue]="wheelROIRangeInputs.upper"
    (valueChange)="onWheelROIRange($event)"></app-range-slider>
  <div>
    <div class="display-settings-header">
      Trading Log
    </div>
    <app-select-trading-log-input-date-format-form></app-select-trading-log-input-date-format-form>
    <p class="title-desc">Select preferred format for entering dates in the Trading Log.</p>
  </div>
  <div class="display-login-navigation-section">
    <div class="display-settings-header">
      After Log in
    </div>
    <mat-radio-group [formControl]="loginNavigationControl" color="primary">
      <mat-radio-button disableRipple="true" [value]="loginNavigationControlValueEnum.LastPage">Open the last used
        page</mat-radio-button>
      <div class="radio-button-select-container">
        <mat-radio-button disableRipple="true" [value]="loginNavigationControlValueEnum.SelectedPage">Open this
          page</mat-radio-button>
        <div class="from-group">
          <mat-form-field class="navigation-form-field" appearance="outline" subscriptSizing="dynamic">
            <mat-select class="multiSelect" panelClass="scroll-style" placeholder="Select"
              [formControl]="loginNavigationPageSelectControl">
              <mat-option *ngFor="let page of mostUsedPageList" [value]="page">
                {{ page.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-radio-group>
  </div>
  <div class="display-rocky-section">
    <div class="display-settings-header">
      Rocky
    </div>
    <mat-slide-toggle class="large-slide-toggle" (change)="rockyVisibilityChange($event)"
      [checked]="!!showRockyAlways.getValue()">
      Always show Rocky icon
    </mat-slide-toggle>
    <div class="second-description">
      Once activated, the Rocky icon will always be visible in the data window, economic calendar, and other places;
      otherwise, it will be visible on mouse hover.
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="displayActionButtons"></ng-container>
</ng-template>

<ng-template #accountTab>
  <div class="my-setting-content">
    <h3 class="content-part-label">Personal Information</h3>
    <span class="field-title">NAME</span>
    <p class="change-password-field-text">{{ user.firstName + ' ' + user.lastName }}</p>
    <span class="field-title">EMAIL</span>
    <p class="change-password-field-text">{{ user.email }}</p>
    <h3 class="content-part-label">Security</h3>
    <app-change-password-form *ngIf="tabIndex === 3"></app-change-password-form>
  </div>
</ng-template>

<ng-template #soundsTab>
  <div class="my-setting-content sounds">
    <h3 class="sound-bar-settings-title">Automatically play sound</h3>
    <div class="sound-bar-controls">
      <mat-checkbox color="primary" class="pxo-checkbox middle-checkbox" [checked]="playOpenMarketSound"
        (change)="onChangePlayMarketSound($event.checked, 'open')">
        Stock Market opens
      </mat-checkbox>
      <mat-checkbox color="primary" class="pxo-checkbox middle-checkbox" [checked]="playCloseMarketSound"
        (change)="onChangePlayMarketSound($event.checked, 'close')">
        Stock Market closes
      </mat-checkbox>
    </div>

    <div class="sound-bar" (click)="playOpenCloseMarketSound()" [ngClass]="{ 'disabled': isPlayingOpenMarketSound }">
      <audio #audioElement src="../../../assets/sounds/stock_market_bell.mp3" type="audio/mpeg" preload="auto"></audio>
      <button [disabled]="isPlayingOpenMarketSound" mat-icon-button class="sound-bar-btn" aria-label="Play sound"
        (click)="playOpenCloseMarketSound()">
        <mat-icon [svgIcon]="'play-icon'" class="custom-icon-size my-settings-play-sound-icon"></mat-icon>
      </button>
      <span class="sound-bar-text">Stock Market Bell</span>
      <span id="duration" class="sound-bar-timer">0:04</span>
    </div>

    <div class="info-container">
      <div class="info-container-icon">
        <mat-icon [svgIcon]="'info-icon'" class="info-icon middle-icon"></mat-icon>
      </div>
      <p class="info-container-text">
        In some cases the reason why you can’t hear sound through your browser is because the website
        playing it is automatically muted. Please check in the Internet how to unmute the website
      </p>
    </div>
  </div>
</ng-template>

<ng-template #tradingLogTab>
  <div class="my-setting-content trading-log">
    <h3 class="my-setting-content-title">Realized Profit and Loss calculations</h3>
    <p>
      LIFO, FIFO and Weighted Average are accounting methods used to determine the order that securities are purchased
      and sold:
    </p>
    <ul>
      <li>
        <span class="text-bold">FIFO</span> (first-in, first-out) is the default setting used by brokers and also the
        accounting method used
        by the IRS. FIFO is likely to
        be most appropriate for most traders.
      </li>
      <li>
        On the other hand, <span class="text-bold">LIFO</span> (last-in, first-out) is often requested by
        traders that have purchased shares (assigned) at different prices and want exits to be based on the last price
        acquired rather than
        the first. These settings allow the user to decide what is best for trading log calculations.
      </li>
      <li>
        <span class="text-bold">Weighted Average</span> is an accounting method that averages the price when there are
        multiple entry points
        (most often used with mutual funds).
      </li>
    </ul>

    <div class="table-container table-main-style table-styles scroll-style">
      <table mat-table [dataSource]="tradingLogAccountsDataSource"
        class="extra-low-table classic-hover-styles mat-elevation-z0">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Account</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="calculation_method">
          <th mat-header-cell *matHeaderCellDef>Stocks and Options</th>
          <td mat-cell *matCellDef="let element">
            <div class="text-center">
              <mat-button-toggle-group (change)="changeCalculationMethod(element, $event)" class="view-switcher"
                [value]="element.calculation_method" hideSingleSelectionIndicator="true">
                <mat-button-toggle class="view-switcher-btn" [value]="tradingAccountCalculationMethods.Fifo"
                  aria-label="FIFO">
                  <span class="view-switcher-btn-text">FIFO</span>
                </mat-button-toggle>
                <mat-button-toggle class="view-switcher-btn" [value]="tradingAccountCalculationMethods.Lifo"
                  aria-label="LIFO">
                  <span class="view-switcher-btn-text">LIFO</span>
                </mat-button-toggle>
                <mat-button-toggle class="view-switcher-btn" [value]="tradingAccountCalculationMethods.Average"
                  aria-label="AVG">
                  <span class="view-switcher-btn-text">AVG</span>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="tradingLogAccountsDisplayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tradingLogAccountsDisplayColumns;"></tr>

      </table>
    </div>

    <app-overlay title="Updating trades" [showUpdatingOverlay]="showUpdatingOverlay"></app-overlay>
  </div>
</ng-template>

<ng-template #displayActionButtons>
  <div class="actions display-tab-action" id="display-action-buttons">
    <button mat-stroked-button class="border-btn large-btn my-setting-cancel reset-button"
      (click)="resetDisplaySetting()">
      Reset to Default
    </button>
  </div>
</ng-template>