import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogsService } from '@s/common';
import { TradingHubService } from '@s/trading-hub.service';
import { ObservableService } from '@s/observable.service';

@Component({
  selector: 'app-trading-hub-top-menu-button',
  templateUrl: './trading-hub-top-menu-button.component.html',
  styleUrls: ['./trading-hub-top-menu-button.component.scss'],
})
export class TradingHubTopMenuButtonComponent implements OnInit {
  public progress$ = this.tradingHubService.preTradingProgress$;
  public tasksLength$ = this.observableService.preTradingChecklistTasks
    .pipe(map((tasks) => tasks?.length ?? 0));
  public isFirstTimeOpening$ = this.observableService.tradingHubLastOpenDate
    .pipe(map((lastOpenDate) => !lastOpenDate));

  constructor(
    private dialogsService: DialogsService,
    private tradingHubService: TradingHubService,
    private observableService: ObservableService,
  ) { }

  ngOnInit() { }

  public openTradingHubModal(): void {
    from(this.dialogsService.openTradingHubModal())
      .subscribe();
  }
}
