<div [formGroup]="form" class="form-container">
  <div class="from-group input-group-wrap search-input">
    <app-input-multi-select
      formControlName="symbol"
      [options]="symbolOptions"
      [emptyOptionsDropdownMessage]="'There are no symbols'"
      [emptyFilteredOptionsDropdownMessage]="'No symbols match your criteria'"
      [placeholder]="'Enter symbol(s)'"
    ></app-input-multi-select>
  </div>

  <app-multi-select [formControlG]="field('accounts')" [data]="accountSelectData$ | async"
    [title]="'Account'"></app-multi-select>
  <app-multi-select [formControlG]="field('strategies')" [data]="strategySelectData$ | async"
    [title]="'Strategy'"></app-multi-select>
</div>
