import { AddEarningsFormModel } from '@c/admin-config/earnings/add-earnings-form/add-earnings-form.model';
import { EarningModel } from '@mod/admin/earning.model';
import { GlobalIdentificator } from '@t/common/global-identificator.type';

export interface AddEarningsDialogInputDataModel {
  readonly symbol?: string;
  readonly security_id?: GlobalIdentificator;
}

export interface AddEarningsDialogOutputDataModel {
  readonly earning?: EarningModel;
  readonly canceled: boolean;
}

export const toEarningsModel = (model: AddEarningsFormModel): EarningModel => ({
  ...model,
  original_report_date: model.report_date,
  description: '',
  exchange_code: '',
  exchange_id: 0,
  exchange_name: '',
  before_after_market: model.before_after_market,
});
