// for filters summary
export const getFilterControlRangeDisplayValue = (
  from: string | null,
  to: string | null
): string => {
  const formattedFrom = (from && from.includes('-')) ? `(${from})` : from;
  const formattedTo = (to && to.includes('-')) ? `(${to})` : to;

  if (!from && !to) {
    return 'Any';
  }

  if (from && !to) {
    return `>= ${formattedFrom}`;
  }

  if (!from && to) {
    return `<= ${formattedTo}`;
  }

  return `${formattedFrom} - ${formattedTo}`;
};
