import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ObservableService } from '@s/observable.service';
import { JobStatus } from '@const';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  protected showMaintenance = false;
  protected numberCrunchingStatus: JobStatus = null;

  protected readonly jobStatus = JobStatus;

  private subscriptions = new Subscription();

  constructor(private _observableService: ObservableService) { }

  ngOnInit(): void {
    this.showMaintenance = this._observableService.showMaintenance.getValue();
    this.numberCrunchingStatus = this._observableService.numberCrunchingStatus.getValue();

    this.subscriptions.add(
      this._observableService.showMaintenance.subscribe((showMaintenance) => this.showMaintenance = showMaintenance)
    );

    this.subscriptions.add(
      this._observableService.numberCrunchingStatus.subscribe((numberCrunchingStatus) => this.numberCrunchingStatus = numberCrunchingStatus)
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected reloadPage(): void {
    window.location.reload();
  }
}
