<div
  class="magic-ball-container scroll-style visible-scroll"
  (mousemove)="setMousePosition($event)"
>
  <div class="magic-ball-text-container">
    <div class="title">
      Magic Ball
    </div>

    <p class="description">
      For best accuracy, be sure to concentrate very hard on your question and ask Magic Ball only once. Its first prediction is always the most accurate!
    </p>
  </div>

  <div
    #imageWrapper
    class="magic-ball-image-wrapper"
    [ngClass]="{ 'shaking': isShaking }"
    (click)="onImageWrapperClick()"
    (mousemove)="updateCursorStyleForBall($event.clientX, $event.clientY)"
    (mouseout)="onCircleMouseOut()"
  >
    <img
      #image
      width="300"
      height="300"
      src="./assets/img/magic-ball.svg"
      alt="Magic Ball 8"
      draggable="false"
    >
    <div class="magic-ball-image-inner gradient" [ngClass]="isShaking ? 'fade-out' : 'fade-in'" #imageResult>
      <div class="magic-ball-image-inner-text" [ngClass]="{ 'fade-out': isShaking }">
        {{ resultText }}
      </div>
    </div>
  </div>

  <div class="actions-container">
    <p class="magic-ball-result-text" [ngClass]="isShaking ? 'fade-out' : 'fade-in'">
      {{ actionDescription }}
    </p>

    <button
      *ngIf="!anotherShaking && shakingAvailable"
      mat-button
      class="main-bg button"
      (click)="shake()"
      [ngClass]="isShaking ? 'fade-out' : 'fade-in'"
    >
      Shake
    </button>

    <button
      *ngIf="anotherShaking"
      mat-button
      class="main-bg button fade-out"
      (click)="reShake()"
      [ngClass]="{ 'fade-in': anotherShaking }"
    >
      Ask more
    </button>
  </div>
</div>
