import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NewQuickLink, QuickLink } from '@mod/link-organiser/link-organiser.model';

@Component({
  selector: 'app-links-organiser-edit-pane',
  templateUrl: './links-organiser-edit-pane.component.html',
  styleUrls: ['./links-organiser-edit-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinksOrganiserEditPaneComponent implements OnInit {
  @Input() selectedLink: QuickLink | null;
  @Output() addLink = new EventEmitter<NewQuickLink>();
  @Output() updateLink = new EventEmitter<QuickLink>();
  @Output() cancel = new EventEmitter();
  protected newLink: NewQuickLink = { title: '', url: '' };

  @ViewChild('headlineInput')
  set headlineInput(element: ElementRef<HTMLInputElement>) {
    element.nativeElement.focus();
  }

  ngOnInit() {
    if (this.selectedLink) {
      this.newLink = { ...this.selectedLink };
    }
  }

  protected submit() {
    if (this.selectedLink) {
      if (!this.newLink.title.trim()) {
        this.newLink.title = this.selectedLink.title;
      }

      if (!this.newLink.url.trim()) {
        this.newLink.url = this.selectedLink.url;
      }

      this.newLink.url = this.newLink.url.replace(/\s/g, '');
      this.updateLink.emit({ id: this.selectedLink.id, ...this.newLink });
    } else {
      if (!this.newLink.title.trim()) {
        if (!this.newLink.url.trim()) {
          this.newLink.title = 'Empty link';
        } else {
          this.newLink.title = this.newLink.url.substring(0, 100);
        }
      }

      this.newLink.url = this.newLink.url.replace(/\s/g, '');
      this.addLink.emit(this.newLink);
    }
  }
}
