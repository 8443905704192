<div class="calendar-page">
  <div mat-dialog-title class="calendar-title">
    <div class="title-wrapper">
      <h2 class="title">Economic Calendar</h2>
      <div class="calendar-status" *ngIf="isAdmin">
        <div
          *ngIf="editMode"
          class="calendar-status-item"
          (click)="toggleEditMode()"
          [matTooltip]="'Switch to read mode'"
        >
          <button
            mat-icon-button
            class="btn-standard primary-bg"
          >
            <mat-icon class="lock-icon xl-icon" [svgIcon]="'lock-opened'"></mat-icon>
          </button>
          <span class="mode-title">Edit Mode</span>
        </div>

        <div
          *ngIf="!editMode"
          class="calendar-status-item"
          (click)="toggleEditMode()"
          [matTooltip]="'Switch to edit links mode'"
        >
          <button
            mat-icon-button
            color="primary"
            class="btn-standard primary-bg"
          >
            <mat-icon class="lock-icon xl-icon" [svgIcon]="'lock-closed'"></mat-icon>
          </button>
          <span class="mode-title">Read Mode</span>
        </div>
      </div>
    </div>
    <div class="impact-list">
      <span class="impact-item impact-item-high">High impact</span>
      <span class="impact-item impact-item-med">Med</span>
      <span class="impact-item impact-item-low">Low</span>
      <span class="impact-item impact-item-non">Non-Economic</span>
    </div>
    <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1" (click)="cancel()">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon"></mat-icon>
    </button>
  </div>

  <div class="selector-wrapper">
    <app-weeks-selector
      [weeksArray]='weeksArray'
      (changeValue)="getSelectedWeekEvents($event)"
    ></app-weeks-selector>
  </div>

  <div class="calendar-body">
    <div class="titles">
      <span class="titles-data mobile-invisible">Date</span>
      <span class="titles-time">Time</span>
      <span class="titles-impact">Impact</span>
      <span class="titles-name">Name</span>
      <span class="titles-actual">Actual</span>
      <span class="titles-forecast">Forecast</span>
      <span class="titles-previous">Previous</span>
    </div>
    <mat-dialog-content class="mat-typography">
      <ng-container *ngFor="let date of economicCalendar">
        <div
          class="calendar-card"
          [ngClass]="{ 'selected-day': currentDay === date.date, 'edit-mode': editMode }"
          [id]="'date-'+date.date"
        >
          <div class="calendar-card-data">
            <span class="day">{{ date.date | date:'EEE' }}
              <span class="data-separator">,</span>
            </span>
            <span class="month">{{ date.date | date:'LLL d' }}</span>
          </div>
          <div class="data-wrapper" [ngClass]="{ 'empty-container': date?.times?.length === 0 }">
            <div *ngFor="let time of date.times" class="data-item">
              <div class="calendar-card-info">
                <div class="calendar-card-time">
                  <mat-icon
                    class="mobile-invisible watch-icon"
                    [svgIcon]="'watch'"
                    [ngClass]="{ 'selected-day': currentDay === date.date }"
                  ></mat-icon>

                  <span class="time">{{ time.time }}</span>
                </div>
                <div class="task-wrapper">
                  <div *ngFor="let event of time.events" class="calendar-card-task">
                    <div
                      class="calendar-card-impact-item impact-item"
                      [ngClass]="{
                        'impact-item-high': event.impact === 'high',
                        'impact-item-med': event.impact === 'medium',
                        'impact-item-low': event.impact === 'low',
                        'impact-item-non': event.impact !== 'high' && event.impact !== 'medium' && event.impact !== 'low'
                      }"
                    ></div>
                    <div class="calendar-card-name">
                      <span>{{ event.name }}</span>
                    </div>
                    @if (showRockyEconomicCalendar) {
                      <div class="calendar-card-link-to-rocky">
                        <app-ask-rocky-chat-button
                          (openRockyChat)="openTradingHubModal(event)"
                        ></app-ask-rocky-chat-button>
                      </div>
                    }
                    <div
                      class="calendar-card-actual"
                      [ngClass]="{
                        'actual-alarm': event.is_beat === 0,
                        'actual-profit': event.is_beat === 1
                      }"
                    >
                      <span>{{ event.actual }}</span>
                    </div>
                    <div class="calendar-card-forecast"><span>{{ event.forecast }}</span></div>
                    <div class="calendar-card-previous"><span>{{ event.previous }}</span></div>
                  </div>
                </div>
              </div>
            </div>
            <app-link-input
              *ngIf="editMode"
              [urlData]="date.urlData"
              [date]="date.date"
              (onLinkEnter)="updateOrAddLink($event)"
            ></app-link-input>
          </div>
        </div>
        <div class="yt-link-container" *ngIf="!editMode && date.urlData?.url">
          <span
            mat-ripple
            [matRippleDisabled]="true"
            (click)="openLink(date.urlData.url)"
            class="yt-link"
          >
            <mat-icon [svgIcon]="'yt_logo'"></mat-icon>
            <p class="yt-link-title">Watch "Coffee with Markus And Mark"</p>
          </span>
        </div>
      </ng-container>
    </mat-dialog-content>
  </div>
</div>
