import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import * as utils from '@u/utils';
import * as moment from 'moment';

import { Response } from '@core1/interface/response';
import { ObservableService } from './observable.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  constructor(
    private http: HttpClient,
    private observableService: ObservableService
  ) {
  }

  getAuthToken = () => localStorage.getItem('authToken');

  setAuthToken = (token: string): void => localStorage.setItem('authToken', token);

  getAuthTokenExpirationDate = (): string => localStorage.getItem('TokenExpirationDate');

  setAuthTokenExpirationDate = (date: string): void => localStorage.setItem('TokenExpirationDate', date);

  getUser = () => {
    const userJson = localStorage.getItem('user');

    if (userJson) {
      try {
        return JSON.parse(userJson);
      } catch {
      }
    }

    return null;
  };

  // Be careful! Actual type for this function is "(key: string) => unknown"
  get = async (key: string) => {
    let value = null;
    const timestamp = moment().unix();
    const { result } = await firstValueFrom(this.http.get<Response>(`/v2/userData?key=${key}&timestamp=${timestamp}`));

    if (result && result.length) {
      value = result[0].value;
    }

    return value;
  };

  getMultiple = async (keys) => {
    const { result } = await firstValueFrom(this.http.post<Response>('/v2/userData/multiple', { keys }));

    return result;
  };

  getAsInt = async (key: string) => utils.toInt(await this.get(key));

  getAsFloat = async (key: string) => utils.toFloat(await this.get(key));

  getAsJSON = async (key: string) => utils.toObject(await this.get(key));

  set = async (key: string, value: any) => {
    const valueToSave = typeof value === 'object' && value !== null
      ? JSON.stringify(value)
      : value;

    const { result } = await firstValueFrom(
      this.http.post<Response>('/v2/userData', { key, value: valueToSave })
    );

    if (result) {
      if (this.observableService[key]) {
        this.observableService[key].next(value);
      }
    }
  };

  setMultiple = async (settings: any) => {
    const { result } = await firstValueFrom(
      this.http.post<Response>('/v2/userData/set_multiple', { settings })
    );

    if (result) {
      for (const { key, value } of settings) {
        if (this.observableService[key]) {
          this.observableService[key].next(value);
        }
      }
    }
  };

  remove = async (id) => {
    const { result } = await firstValueFrom(this.http.delete<Response>(`/v2/userData/${id}`));

    return result;
  };
}
