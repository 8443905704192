<div class="trading-hub-component">
  <ng-container [ngTemplateOutlet]="currentMode() === TradingHubMode.Help ? helpMode : defaultMode"></ng-container>
</div>

<ng-template #defaultMode>
  <div class="hub-header">
    <h2>Your Trading Hub</h2>
    <button mat-icon-button mat-dialog-close (click)="handleCloseModal()" class="popup-close-btn" tabindex="-1">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon">
      </mat-icon>
    </button>
  </div>

  <span class="hub-subheader">
    Your personal space for adding notes, checklists and managing your work on a daily basis
  </span>

  <app-quote-of-day></app-quote-of-day>
  <!--TODO: don't remove please, this just a spacer to avoid Safari 17.0 content overlying issue-->
  <div class="min-h-1px"></div>

  <div class="progress-section" *ngIf="currentTabIndex === 0">
    <div class="progress">
      <app-checklist-progress
        [value]="progress$ | async"
        size="large"
      ></app-checklist-progress>
    </div>
    <div class="progress-text-wrapper">
      <h4 class="progress-header">Your Readiness</h4>
      <span class="progress-message">
        <ng-container *ngIf="(progress$ | async) === 100">
          Well done, you are ready to trade!
        </ng-container>
        <ng-container *ngIf="(progress$ | async) < 100">
          You are not ready to trade today. Finish your pre-trading checklist first
        </ng-container>
      </span>
    </div>
  </div>

  <div class="tabs-wrapper trading-hub-tabs">
    <mat-tab-group
      #tabsGroup
      mat-stretch-tabs="false"
      animationDuration="0"
      disableRipple
      class="pxo-tabs s-tabs"
      (selectedTabChange)="onChangeTab($event.index, $event.tab.textLabel)"
      [selectedIndex]="currentTabIndex"
    >
      <mat-tab [label]="tradingHubTabs.Checklist">
        <ng-template mat-tab-label>
          Pre-trading Checklist
        </ng-template>

        <app-pre-trading-checklist></app-pre-trading-checklist>
      </mat-tab>

      <mat-tab [label]="tradingHubTabs.TradingPlan">
        <ng-template mat-tab-label>
          Trading Plan
        </ng-template>

        <app-trading-plan></app-trading-plan>
      </mat-tab>

<!--      important: currently hidden tab, keep it commented  -->
<!--      <mat-tab-->
<!--        #iBotTab-->
<!--        [label]="tradingHubTabs.IBot"-->
<!--        *appHideForUnavailableFeature="features.IBot"-->
<!--      >-->
<!--        <ng-template mat-tab-label>-->
<!--          Rocky-->
<!--        </ng-template>-->

<!--        <app-chat [isActive]="iBotTab.isActive"></app-chat>-->
<!--      </mat-tab>-->

      <mat-tab
        #iBotTab3
        [label]="tradingHubTabs.IBot3"
      >
        <ng-template mat-tab-label>
          Rocky
        </ng-template>

        <app-chat-cody-second [isActive]="iBotTab3.isActive"></app-chat-cody-second>
      </mat-tab>

      <mat-tab
        labelClass="magic-ball-tab-label"
        [label]="tradingHubTabs.MagicBall"
      >
        <ng-template mat-tab-label>
          <mat-icon [svgIcon]="'magic-ball-icon'" class="magic-ball-icon"></mat-icon>
        </ng-template>

        <app-magic-ball class="scroll-style"></app-magic-ball>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #helpMode>
  <app-chat-cody-second [isHelpMode]="true" [isActive]="true"></app-chat-cody-second>
</ng-template>
