import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment/moment';

// Must be imported from full path
import { emptyOptionsSummaryCommonInputModel } from '@c/trading-log/dashboard/tables/options-summary-table/options-summary-table.model';
// Must be imported from full path
import { emptyTradesDurationTableModel } from '@c/trading-log/dashboard/tables/trades-duration-table/trades-duration-table.model';

import {
  ChartContainerModel,
  ConfidenceStatisticModel,
  DaysBarDataModel,
  DecisionStatisticModel,
  DividendsChartModel,
  OptionsChartModel,
  OptionsSummaryCallPutInputModel,
  OptionsSummaryTableInputModel,
  OptionsSymbolsTableInputRowModel,
  StocksSymbolsTableInputRowModel,
  StocksTopSymbolsTableInputModel,
  SymbolStatisticModel,
  TosChartModel,
  TosCumulativeChartModel,
  TradesDurationTableModel,
  TradesStatisticModel,
  WeekdayTradesModel,
} from '@c/trading-log/dashboard';
import {
  emptyChartMonthData,
  emptyTableSymbolData,
  tradingLogDashboardDisabledEvaluationParentMap,
  OpenPositionSymbolsTableItemDataModel,
  OptionsPutCallByDayDataModel,
  TradingLogDashboardChartMonthDataModel,
  TradingLogDashboardSymbolDataModel
} from '@c/trading-log/dashboard/trading-log-dashboard-container/trading-log-dashboard-container.model';


import { TradingLogTransactionStatus, TradingLogTransactionType } from '@t/trading-log';
import { getNumberComparerDesc, getStringComparer } from '@u/comparers';
import {
  TradingLogDashboardMetricDetailsGroupModel,
  TradingLogDashboardOpenPositionsInfoModel,
  TradingLogDashboardReportInfoModel
} from '@mod/trading-log';
import {
  formatDecimalOrZero,
  formatNumber,
  formatPrice,
  MomentDateTimeFormats,
  TradingLogTransactionIsOptionMap,
  TradingLogTransactionIsStockMap
} from '@const';
import { AppliedMap, OptionsStatisticTotalMap, SummaryStatisticMap } from '@t/services/trading-log-dashboard-state-service.types';
import {
  TradingLogDashboardMetricDetailsGroupStatisticModel
} from '@mod/trading-log/dashboard/trading-log-dashboard-metric-details-group.model';
import { TradeGroupsStatisticModel } from '@c/trading-log/dashboard/overall-performance-section/models/trades-statistic.model';
import { DateRangeFilterModel } from '@c/shared/date-contols/components/filters/daterange-filter/daterange-filter.model';
import { OverallPerformanceSectionModeEnum } from '@c/trading-log/dashboard/overall-performance-section/models/overall-performance-section-mode.enum';
import { DateRangeOptionType } from '@c/shared/date-contols/models/date-range-type.enum';

@Injectable({
  providedIn: 'root'
})
export class TradingLogDashboardStateService {
  private readonly _stocksTopTableSymbolsCount = 5;

  // loaders
  public openPositionsUpdateLoadingState$ = new BehaviorSubject(false);
  public symbolUpdateLoadingState$ = new BehaviorSubject(false);
  public dashboardUpdateLoadingState$ = new BehaviorSubject(false);
  public overallPerformanceModeLoadingState$ = new BehaviorSubject(false);

  // loaded data
  private _loadedMetricDetailsGroupsState$ = new BehaviorSubject<TradingLogDashboardMetricDetailsGroupModel[]>([]);
  public loadedMetricDetailsGroupsStateObservable$ = this._loadedMetricDetailsGroupsState$.asObservable();
  private _loadedOverallPerformanceModeState$ = new BehaviorSubject<OverallPerformanceSectionModeEnum>(OverallPerformanceSectionModeEnum.Transactions);
  public loadedOverallPerformanceModeObservableState$ = this._loadedOverallPerformanceModeState$.asObservable();

  // filtered data
  private _availableSymbolControlOptionsState$ = new BehaviorSubject<string[]>([]);
  public availableSymbolControlOptionsStateObservable$ = this._availableSymbolControlOptionsState$.asObservable();

  // data availability
  private _isNoSummaryTabState$ = new BehaviorSubject(false);
  public isNoSummaryTabStateObservable$ = this._isNoSummaryTabState$.asObservable();

  private _isNoProfitChartsState$ = new BehaviorSubject(false);
  public isNoProfitChartsStateObservable$ = this._isNoProfitChartsState$.asObservable();

  private _isNoCommissionsChartsState$ = new BehaviorSubject(false);
  public isNoCommissionsChartsStateObservable$ = this._isNoCommissionsChartsState$.asObservable();

  private _isNoDividendsChartsState$ = new BehaviorSubject(false);
  public isNoDividendsChartsStateObservable$ = this._isNoDividendsChartsState$.asObservable();

  private _isNoTradesChartsState$ = new BehaviorSubject(false);
  public isNoTradesChartsStateObservable$ = this._isNoTradesChartsState$.asObservable();

  private _isNoOptionsTabState$ = new BehaviorSubject(false);
  public isNoOptionsTabStateObservable$ = this._isNoOptionsTabState$.asObservable();

  private _isNoStocksTabState$ = new BehaviorSubject(false);
  public isNoStocksTabStateObservable$ = this._isNoStocksTabState$.asObservable();

  // charts
  private _plChartState$ = new BehaviorSubject<TosChartModel[]>([]);
  public plChartStateObservable$ = this._plChartState$.asObservable();
  private _cumulativeChartState$ = new BehaviorSubject<TosCumulativeChartModel[]>([]);
  public cumulativeChartStateObservable$ = this._cumulativeChartState$.asObservable();
  private _plChartSummaryState$ = new BehaviorSubject<ChartContainerModel[]>([]);
  public plChartSummaryStateObservable$ = this._plChartSummaryState$.asObservable();

  private _comChartState$ = new BehaviorSubject<TosChartModel[]>([]);
  public comChartStateObservable$ = this._comChartState$.asObservable();
  private _comChartSummaryState$ = new BehaviorSubject<ChartContainerModel[]>([]);
  public comChartSummaryStateObservable$ = this._comChartSummaryState$.asObservable();

  private _trChartState$ = new BehaviorSubject<TosChartModel[]>([]);
  public trChartStateObservable$ = this._trChartState$.asObservable();
  private _trChartSummaryState$ = new BehaviorSubject<ChartContainerModel[]>([]);
  public trChartSummaryStateObservable$ = this._trChartSummaryState$.asObservable();

  private _dvChartState$ = new BehaviorSubject<DividendsChartModel[]>([]);
  public dvChartStateObservable$ = this._dvChartState$.asObservable();
  private _dividendsState$ = new BehaviorSubject('');
  public dividendsStateObservable$ = this._dividendsState$.asObservable();
  private _optionsState$ = new BehaviorSubject('');
  public optionsStateObservable$ = this._optionsState$.asObservable();

  private _optsChartState$ = new BehaviorSubject<OptionsChartModel[]>([]);
  public optsChartStateObservable$ = this._optsChartState$.asObservable();

  // tables
  private _optionsStatisticTableState$ = new BehaviorSubject<OptionsSummaryTableInputModel>({
    common: { ...emptyOptionsSummaryCommonInputModel },
    calls: [],
    puts: []
  });
  public optionsStatisticTableStateObservable$ = this._optionsStatisticTableState$.asObservable();

  private _weekdayTradesState$ = new BehaviorSubject<WeekdayTradesModel[]>([]);
  public weekdayTradesStateObservable$ = this._weekdayTradesState$.asObservable();

  private _tradesDurationState$ = new BehaviorSubject<TradesDurationTableModel>(emptyTradesDurationTableModel);
  public tradesDurationStateObservable$ = this._tradesDurationState$.asObservable();

  private _optionSymbolsTableState$ = new BehaviorSubject<OptionsSymbolsTableInputRowModel[]>([]);
  public optionSymbolsTableStateObservable$ = this._optionSymbolsTableState$.asObservable();
  private _stockSymbolsTableState$ = new BehaviorSubject<StocksSymbolsTableInputRowModel[]>([]);
  public stockSymbolsTableStateObservable$ = this._stockSymbolsTableState$.asObservable();

  private _stockTopSymbolsTableState$ = new BehaviorSubject<StocksTopSymbolsTableInputModel[]>([]);
  public stockTopSymbolsTableStateObservable$ = this._stockTopSymbolsTableState$.asObservable();
  private _openPositionSymbolsTableState$ = new BehaviorSubject<OpenPositionSymbolsTableItemDataModel[]>([]);
  public openPositionSymbolsTableStateObservable$ = this._openPositionSymbolsTableState$.asObservable();
  private _openPositionTableLabelState$ = new BehaviorSubject<string>('');
  public openPositionTableLabelStateObservable$ = this._openPositionTableLabelState$.asObservable();

  // statistics by transactions
  private _tradesStatisticState$ = new BehaviorSubject<TradesStatisticModel>(null);
  public tradesStatisticStateObservable$ = this._tradesStatisticState$.asObservable();
  private _avgAndLongestTradeLengthState$ = new BehaviorSubject<DaysBarDataModel>({
    isNoData: true,
    totalValue: 0,
    progressValue: 0,
    maxDaysUsedTransactions: []
  });
  public avgAndLongestTradeLengthStateObservable$ = this._avgAndLongestTradeLengthState$.asObservable();
  private _symbolsStatisticState$ = new BehaviorSubject<ReadonlyArray<SymbolStatisticModel>>([]);
  public symbolsStatisticStateObservable$ = this._symbolsStatisticState$.asObservable();

  private _decisionsStatisticState$ = new BehaviorSubject<ReadonlyArray<DecisionStatisticModel>>([]);
  public decisionsStatisticStateObservable$ = this._decisionsStatisticState$.asObservable();
  private _confidenceStatisticState$ = new BehaviorSubject<ReadonlyArray<ConfidenceStatisticModel>>([]);
  public confidenceStatisticStateObservable$ = this._confidenceStatisticState$.asObservable();
  private _decisionEvaluatedPercentageState$ = new BehaviorSubject<number>(null);
  public decisionEvaluatedPercentageStateObservable$ = this._decisionEvaluatedPercentageState$.asObservable();
  private _confidenceEvaluatedPercentageState$ = new BehaviorSubject<number>(null);
  public confidenceEvaluatedPercentageStateObservable$ = this._confidenceEvaluatedPercentageState$.asObservable();

  // statistic by groups
  private _tradeGroupsStatisticState$ = new BehaviorSubject<TradeGroupsStatisticModel>(null);
  public tradeGroupsStatisticStateObservable$ = this._tradeGroupsStatisticState$.asObservable();

  constructor() { }

  public updateLoadedOverallPerformanceMode(mode: OverallPerformanceSectionModeEnum) {
    this._loadedOverallPerformanceModeState$.next(mode);
  }

  public updateLoadedMetricDetailsGroups(groups: TradingLogDashboardMetricDetailsGroupModel[]): void {
    this._loadedMetricDetailsGroupsState$.next(groups);
  }

  public updateAvailableSymbolControlOptions(
    transactions: ReadonlyArray<TradingLogDashboardReportInfoModel>,
    dateRange: DateRangeFilterModel
  ): void {
    let filteredTransactions: ReadonlyArray<TradingLogDashboardReportInfoModel>;
    if (dateRange.type === DateRangeOptionType.Lifetime) {
      filteredTransactions = transactions.filter((tr) => tr.symbol);
    } else {
      const rangeFrom = moment(dateRange.from, MomentDateTimeFormats.ServerDate).add(-1, 'day').endOf('day');
      const rangeTo = moment(dateRange.to, MomentDateTimeFormats.ServerDate).endOf('day');

      filteredTransactions = transactions.filter((tr) => {
        const transactionStart = moment(tr.timestamp, MomentDateTimeFormats.ServerDate);
        const transactionEnd = moment(tr.close_timestamp, MomentDateTimeFormats.ServerDate);

        const isBetweenRange = transactionStart.isBetween(rangeFrom, rangeTo, 'seconds')
          || (transactionEnd.isValid() && transactionEnd.isBetween(rangeFrom, rangeTo, 'seconds'));

        return tr.symbol && isBetweenRange;
      });
    }

    this._availableSymbolControlOptionsState$.next(
      Array.from(new Set(filteredTransactions.map((tr) => tr.symbol))).sort()
    );
  }

  public updateOpenPositionsStates(openPositions: ReadonlyArray<TradingLogDashboardOpenPositionsInfoModel>): void {
    const mappedOpenPositions = [...openPositions].filter(
      (item) => item.buy_contracts || item.buy_shares || item.sell_contracts || item.sell_shares
    ).sort(getStringComparer((o) => (o.symbol || ''))).map((position) => ({
      symbol: position.symbol,
      buyContracts: position.buy_contracts ? formatDecimalOrZero(position.buy_contracts, 0, 6) : '-',
      sellContracts: position.sell_contracts ? formatDecimalOrZero(position.sell_contracts, 0, 6) : '-',
      sharesSold: position.sell_shares ? formatDecimalOrZero(position.sell_shares, 0, 6) : '-',
      sharesBought: position.buy_shares ? formatDecimalOrZero(position.buy_shares, 0, 6) : '-',
    }));
    const openPositionsLabel = !mappedOpenPositions.length
      ? null
      : mappedOpenPositions.length === 1
        ? '1 current open position'
        : `${mappedOpenPositions.length} current open positions`;

    this._openPositionTableLabelState$.next(openPositionsLabel);
    this._openPositionSymbolsTableState$.next(mappedOpenPositions);
  }

  public updateDashboardStates(
    transactions: ReadonlyArray<TradingLogDashboardReportInfoModel>,
    groups: ReadonlyArray<TradingLogDashboardMetricDetailsGroupModel>,
    dateRange: DateRangeFilterModel
  ): void {
    const appliedMap: AppliedMap = {
      isStocksProfitApplied: false,
      isOptionsProfitApplied: false,
      isDividendsProfitApplied: false,
      isStocksCommissionsApplied: false,
      isOptionsCommissionsApplied: false,
      isDividendsCommissionsApplied: false
    };

    const optionsStatisticTotalMap: OptionsStatisticTotalMap = {
      totalPutClosedProfit: 0,
      totalCallClosedProfit: 0,
      totalPutCommissions: 0,
      totalCallCommissions: 0,
      totalSellPutTrades: 0,
      totalAssignedTrades: 0,
    };

    const tablesData: Record<string, TradingLogDashboardSymbolDataModel> = {};

    let datesSorted: ReadonlyArray<string> = [];
    const chartDataByMonth: Record<string, TradingLogDashboardChartMonthDataModel> = {};

    const startDate = dateRange.from;
    const endDate = dateRange.to;

    const date = moment(startDate);
    while (date.isSameOrBefore(endDate, 'month')) {
      const monthKey = date.format(MomentDateTimeFormats.ReadableNoDayDate);
      datesSorted = [...datesSorted, monthKey];
      chartDataByMonth[monthKey] = { ...emptyChartMonthData };
      date.add(1, 'month');
    }

    const newTradesStatisticState: TradesStatisticModel = {
      totalGainWithCommSum: { count: 0, usedTransactions: [] },
      totalLossWithCommSum: { count: 0, usedTransactions: [] },
      totalWinSum: { count: 0, usedTransactions: [] },
      totalLossSum: { count: 0, usedTransactions: [] },
      totalWinTradesCount: { count: 0, usedTransactions: [] },
      totalLossTradesCount: { count: 0, usedTransactions: [] },
      totalDividendsWinSum: 0,
    };
    const newTradeGroupsStatisticState: TradeGroupsStatisticModel = {
      totalGainWithCommSum: { count: 0, usedGroups: [] },
      totalLossWithCommSum: { count: 0, usedGroups: [] },
      totalWinSum: { count: 0, usedGroups: [] },
      totalLossSum: { count: 0, usedGroups: [] },
      totalWinTradesCount: { count: 0, usedGroups: [] },
      totalLossTradesCount: { count: 0, usedGroups: [] },
    };
    const metricDetailsGroups: TradingLogDashboardMetricDetailsGroupStatisticModel[] = groups.map((g) => {
      return {
        ...g,
        filteredProfit: 0,
        filteredPremium: 0,
        filteredCommissions: 0,
        dividendsPremium: 0,
        usedTransactions: []
      };
    });

    const summaryStatisticMap: SummaryStatisticMap = {
      maxDaysInTrade: 0,
      allAvgTradeDays: 0,
      avgValuesCount: 0,
      transactionsWithProfitCount: 0,
      transactionsWithDecisionSelected: 0,
      transactionsWithConfidenceSelected: 0,
      transactionsWithDisabledEvaluation: 0,
      maxDaysUsedTransactions: []
    };

    const decisionStatisticMap: Record<string, DecisionStatisticModel> = {};
    const confidenceStatisticMap: Record<string, ConfidenceStatisticModel> = {};

    const weekdayTradesMap: OptionsPutCallByDayDataModel = {
      Mon: { call: 0, put: 0, all: 0 },
      Tue: { call: 0, put: 0, all: 0 },
      Wed: { call: 0, put: 0, all: 0 },
      Thu: { call: 0, put: 0, all: 0 },
      Fri: { call: 0, put: 0, all: 0 },
      Sat: { call: 0, put: 0, all: 0 },
      Sun: { call: 0, put: 0, all: 0 }
    };
    const optionsStatisticTableCallTrades: OptionsSummaryCallPutInputModel[] = [];
    const optionsStatisticTablePutTrades: OptionsSummaryCallPutInputModel[] = [];
    const closedCallPutTrades: TradingLogDashboardReportInfoModel[] = [];
    const closedStockTradesWithAssignments: TradingLogDashboardReportInfoModel[] = [];

    transactions.forEach((transaction) => {
      transaction.symbol = transaction.symbol || '';
      const monthKey = moment(transaction.timestamp, MomentDateTimeFormats.ServerDate)?.format(MomentDateTimeFormats.ReadableNoDayDate);
      const monthProfitKey = transaction.close_timestamp
        ? moment(transaction.close_timestamp, MomentDateTimeFormats.ServerDate)?.format(MomentDateTimeFormats.ReadableNoDayDate)
        : null;
      const tradesDayOfWeek = moment(transaction.timestamp).format('ddd');

      tablesData[transaction.symbol] = tablesData[transaction.symbol] ? tablesData[transaction.symbol] : {
        ...emptyTableSymbolData,
        usedTransactions: []
      };

      switch (transaction.transaction_type) {
        case TradingLogTransactionType.BuyStock: {
          tablesData[transaction.symbol].stockTradesCount++;
          tablesData[transaction.symbol].stockSharesBoughtCount += transaction.qty;
          tablesData[transaction.symbol].usedTransactions.push(transaction);

          if (chartDataByMonth[monthKey]) {
            chartDataByMonth[monthKey].stocksCommissionsSum += transaction.commissions;
            chartDataByMonth[monthKey].stocksRecordsCount++;
            tablesData[transaction.symbol].stocksCommissionsSum += transaction.commissions;
            appliedMap.isStocksCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            chartDataByMonth[monthProfitKey].stocksClosedProfitSum += transaction.closed_profit ?? 0;
            tablesData[transaction.symbol].stocksClosedProfitSum += transaction.closed_profit ?? 0;
            tablesData[transaction.symbol].stockTradesWithProfitCount += 1;

            if ((transaction.closed_profit ?? 0) > 0) {
              tablesData[transaction.symbol].stockWinTradesCount++;
            }

            if (transaction.closed_profit !== null && transaction.avg_days_in_trade_assignment !== null) {
              closedStockTradesWithAssignments.push(transaction);
            }

            // this is a temporary solution
            // parent_transaction_id must be replaced with parent_transaction_type in the future
            // there is a case when user can select dates that don't include parent transaction
            if (transaction.parent_transaction_id) {
              const parentTransaction = transactions.find((t) => t.transaction_id === transaction.parent_transaction_id);
              if (parentTransaction && tradingLogDashboardDisabledEvaluationParentMap[parentTransaction.transaction_type]) {
                summaryStatisticMap.transactionsWithDisabledEvaluation++;
              }
            }

            appliedMap.isStocksProfitApplied = true;
          }
          break;
        }
        case TradingLogTransactionType.SellStock: {
          tablesData[transaction.symbol].stockTradesCount++;
          tablesData[transaction.symbol].stockSharesSoldCount += transaction.qty;
          tablesData[transaction.symbol].usedTransactions.push(transaction);

          if (chartDataByMonth[monthKey]) {
            chartDataByMonth[monthKey].stocksCommissionsSum += transaction.commissions;
            chartDataByMonth[monthKey].stocksRecordsCount++;
            tablesData[transaction.symbol].stocksCommissionsSum += transaction.commissions;
            appliedMap.isStocksCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            chartDataByMonth[monthProfitKey].stocksClosedProfitSum += transaction.closed_profit ?? 0;
            tablesData[transaction.symbol].stocksClosedProfitSum += transaction.closed_profit ?? 0;
            tablesData[transaction.symbol].stockTradesWithProfitCount += 1;

            if ((transaction.closed_profit ?? 0) > 0) {
              tablesData[transaction.symbol].stockWinTradesCount++;
            }

            if (transaction.closed_profit !== null && transaction.avg_days_in_trade_assignment !== null) {
              closedStockTradesWithAssignments.push(transaction);
            }

            // this is a temporary solution
            // parent_transaction_id must be replaced with parent_transaction_type in the future
            // there is a case when user can select dates that don't include parent transaction
            if (transaction.parent_transaction_id) {
              const parentTransaction = transactions.find((t) => t.transaction_id === transaction.parent_transaction_id);
              if (parentTransaction && tradingLogDashboardDisabledEvaluationParentMap[parentTransaction.transaction_type]) {
                summaryStatisticMap.transactionsWithDisabledEvaluation++;
              }
            }

            appliedMap.isStocksProfitApplied = true;
          }
          break;
        }
        case TradingLogTransactionType.SellCall: {
          const closedProfit = transaction.closed_profit ?? 0;
          const optionsStatisticTableCall: OptionsSummaryCallPutInputModel = {
            pl: 0,
            prem: 0,
            comm: 0,
            transactionType: transaction.transaction_type,
            transactionStatus: transaction.transaction_status,
            transaction
          };

          tablesData[transaction.symbol].optionTradesCount++;
          tablesData[transaction.symbol].usedTransactions.push(transaction);

          if (chartDataByMonth[monthKey]) {
            weekdayTradesMap[tradesDayOfWeek].call++;
            weekdayTradesMap[tradesDayOfWeek].all++;

            chartDataByMonth[monthKey].optionsCommissionsSum += transaction.commissions;
            chartDataByMonth[monthKey].optionsRecordsCount++;

            optionsStatisticTotalMap.totalCallCommissions += transaction.commissions;
            optionsStatisticTableCall.comm = transaction.commissions;
            tablesData[transaction.symbol].optionsCommissionsCallSum += transaction.commissions;
            appliedMap.isOptionsCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            chartDataByMonth[monthProfitKey].optionsClosedProfitSum += closedProfit;
            optionsStatisticTotalMap.totalCallClosedProfit += closedProfit;
            optionsStatisticTableCall.prem = closedProfit;
            tablesData[transaction.symbol].optionsClosedProfitCallSum += closedProfit;

            if (closedProfit) {
              closedCallPutTrades.push(transaction);
            }

            appliedMap.isOptionsProfitApplied = true;
          }

          optionsStatisticTableCall.pl = optionsStatisticTableCall.prem - optionsStatisticTableCall.comm;
          optionsStatisticTableCallTrades.push(optionsStatisticTableCall);

          break;
        }
        case TradingLogTransactionType.BuyCall: {
          const closedProfit = transaction.closed_profit ?? 0;
          const optionsStatisticTableCall: OptionsSummaryCallPutInputModel = {
            pl: 0,
            prem: 0,
            comm: 0,
            transactionType: transaction.transaction_type,
            transactionStatus: transaction.transaction_status,
            transaction
          };

          tablesData[transaction.symbol].optionTradesCount++;
          tablesData[transaction.symbol].usedTransactions.push(transaction);

          if (chartDataByMonth[monthKey]) {
            weekdayTradesMap[tradesDayOfWeek].call++;
            weekdayTradesMap[tradesDayOfWeek].all++;

            chartDataByMonth[monthKey].optionsCommissionsSum += transaction.commissions;
            chartDataByMonth[monthKey].optionsRecordsCount++;

            optionsStatisticTotalMap.totalCallCommissions += transaction.commissions;
            optionsStatisticTableCall.comm = transaction.commissions;
            tablesData[transaction.symbol].optionsCommissionsCallSum += transaction.commissions;
            appliedMap.isOptionsCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            chartDataByMonth[monthProfitKey].optionsClosedProfitSum += closedProfit;
            optionsStatisticTotalMap.totalCallClosedProfit += closedProfit;
            optionsStatisticTableCall.prem = closedProfit;
            tablesData[transaction.symbol].optionsClosedProfitCallSum += closedProfit;

            if (closedProfit) {
              closedCallPutTrades.push(transaction);
            }

            appliedMap.isOptionsProfitApplied = true;
          }

          optionsStatisticTableCall.pl = optionsStatisticTableCall.prem - optionsStatisticTableCall.comm;
          optionsStatisticTableCallTrades.push(optionsStatisticTableCall);

          break;
        }
        case TradingLogTransactionType.SellPut: {
          const closedProfit = transaction.closed_profit ?? 0;
          const assignedValue = transaction.transaction_status === TradingLogTransactionStatus.Assigned ? 1 : 0;
          const optionsStatisticTablePut: OptionsSummaryCallPutInputModel = {
            pl: 0,
            prem: 0,
            comm: 0,
            transactionType: transaction.transaction_type,
            transactionStatus: transaction.transaction_status,
            transaction,
            assignedValue,
          };

          tablesData[transaction.symbol].optionTradesCount++;
          optionsStatisticTotalMap.totalSellPutTrades++;
          optionsStatisticTotalMap.totalAssignedTrades += assignedValue;
          tablesData[transaction.symbol].optionPutSellTradesCount++;
          tablesData[transaction.symbol].optionsAssignedCount += transaction.transaction_status === TradingLogTransactionStatus.Assigned ?
            1 : 0;
          tablesData[transaction.symbol].usedTransactions.push(transaction);

          if (chartDataByMonth[monthKey]) {
            weekdayTradesMap[tradesDayOfWeek].put++;
            weekdayTradesMap[tradesDayOfWeek].all++;

            chartDataByMonth[monthKey].optionsCommissionsSum += transaction.commissions;
            chartDataByMonth[monthKey].optionsRecordsCount++;

            optionsStatisticTotalMap.totalPutCommissions += transaction.commissions;
            optionsStatisticTablePut.comm = transaction.commissions;
            tablesData[transaction.symbol].optionsCommissionsPutSum += transaction.commissions;
            appliedMap.isOptionsCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            chartDataByMonth[monthProfitKey].optionsClosedProfitSum += closedProfit;
            optionsStatisticTotalMap.totalPutClosedProfit += closedProfit;
            optionsStatisticTablePut.prem = closedProfit;
            tablesData[transaction.symbol].optionsClosedProfitPutSum += closedProfit;

            if (closedProfit) {
              closedCallPutTrades.push(transaction);
            }

            appliedMap.isOptionsProfitApplied = true;
          }

          optionsStatisticTablePut.pl = optionsStatisticTablePut.prem - optionsStatisticTablePut.comm;
          optionsStatisticTablePutTrades.push(optionsStatisticTablePut);

          break;
        }
        case TradingLogTransactionType.BuyPut: {
          const closedProfit = transaction.closed_profit ?? 0;
          const optionsStatisticTablePut: OptionsSummaryCallPutInputModel = {
            pl: 0,
            prem: 0,
            comm: 0,
            transactionType: transaction.transaction_type,
            transactionStatus: transaction.transaction_status,
            transaction
          };

          tablesData[transaction.symbol].optionTradesCount++;
          tablesData[transaction.symbol].usedTransactions.push(transaction);

          if (chartDataByMonth[monthKey]) {
            weekdayTradesMap[tradesDayOfWeek].put++;
            weekdayTradesMap[tradesDayOfWeek].all++;

            chartDataByMonth[monthKey].optionsCommissionsSum += transaction.commissions;
            chartDataByMonth[monthKey].optionsRecordsCount++;

            optionsStatisticTotalMap.totalPutCommissions += transaction.commissions;
            optionsStatisticTablePut.comm = transaction.commissions;
            tablesData[transaction.symbol].optionsCommissionsPutSum += transaction.commissions;
            appliedMap.isOptionsCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            chartDataByMonth[monthProfitKey].optionsClosedProfitSum += closedProfit;
            optionsStatisticTotalMap.totalPutClosedProfit += closedProfit;
            optionsStatisticTablePut.prem = closedProfit;
            tablesData[transaction.symbol].optionsClosedProfitPutSum += closedProfit;

            if (closedProfit) {
              closedCallPutTrades.push(transaction);
            }

            appliedMap.isOptionsProfitApplied = true;
          }

          optionsStatisticTablePut.pl = optionsStatisticTablePut.prem - optionsStatisticTablePut.comm;
          optionsStatisticTablePutTrades.push(optionsStatisticTablePut);

          break;
        }
        case TradingLogTransactionType.Dividends: {
          tablesData[transaction.symbol].dividendsTradesCount++;
          tablesData[transaction.symbol].usedTransactions.push(transaction);
          tablesData[transaction.symbol].dividendsCommissionsSum += (transaction.commissions ?? 0);


          if (chartDataByMonth[monthKey]) {
            chartDataByMonth[monthKey].dividendsRecordsCount++;
            chartDataByMonth[monthKey].dividendsCommissionsSum += (transaction.commissions ?? 0);
            appliedMap.isDividendsCommissionsApplied = true;
          }

          if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
            newTradesStatisticState.totalDividendsWinSum += transaction.closed_profit ?? 0;
            chartDataByMonth[monthProfitKey].dividendsClosedProfitSum += transaction.closed_profit ?? 0;
            tablesData[transaction.symbol].dividendsClosedProfitSum += transaction.closed_profit ?? 0;
            appliedMap.isDividendsProfitApplied = true;

            summaryStatisticMap.transactionsWithDisabledEvaluation++;
          }
          break;
        }
        default: {
          break;
        }
      }

      const metricDetailsGroup = metricDetailsGroups.find((g) => g.id === transaction.group_id);

      if ((chartDataByMonth[monthKey] && transaction.commissions) || chartDataByMonth[monthProfitKey]) {
        metricDetailsGroup.usedTransactions.push(transaction);
      }

      // if monthProfitKey is in the chartDataByMonth object then close_timestamp in the selected date range
      if (monthProfitKey !== null && chartDataByMonth[monthProfitKey]) {
        if (transaction.closed_profit !== null) {
          metricDetailsGroup.filteredPremium += transaction.closed_profit;

          if (transaction.transaction_type === TradingLogTransactionType.Dividends) {
            metricDetailsGroup.dividendsPremium += transaction.closed_profit;
          }

          if (transaction.closed_profit < 0) {
            newTradesStatisticState.totalLossWithCommSum.count += transaction.closed_profit;
            newTradesStatisticState.totalLossWithCommSum.usedTransactions.push(transaction);

            if (transaction.transaction_type !== TradingLogTransactionType.Dividends) {
              newTradesStatisticState.totalLossSum.count += transaction.closed_profit;
              newTradesStatisticState.totalLossSum.usedTransactions.push(transaction);

              newTradesStatisticState.totalLossTradesCount.count++;
              newTradesStatisticState.totalLossTradesCount.usedTransactions.push(transaction);
            }
          } else {
            newTradesStatisticState.totalGainWithCommSum.count += transaction.closed_profit;
            newTradesStatisticState.totalGainWithCommSum.usedTransactions.push(transaction);

            if (transaction.transaction_type !== TradingLogTransactionType.Dividends) {
              newTradesStatisticState.totalWinSum.count += transaction.closed_profit;
              newTradesStatisticState.totalWinSum.usedTransactions.push(transaction);

              newTradesStatisticState.totalWinTradesCount.count++;
              newTradesStatisticState.totalWinTradesCount.usedTransactions.push(transaction);
            }
          }
          summaryStatisticMap.transactionsWithProfitCount++;
          if (transaction.decision) {
            if (decisionStatisticMap[transaction.decision]) {
              decisionStatisticMap[transaction.decision].timesCount++;
              decisionStatisticMap[transaction.decision].profitLossSumm += transaction.closed_profit;
              decisionStatisticMap[transaction.decision].usedTransactions.push(transaction);
            } else {
              decisionStatisticMap[transaction.decision] = {
                decision: transaction.decision,
                profitLossSumm: transaction.closed_profit,
                timesCount: 1,
                usedTransactions: [transaction]
              };
            }
            summaryStatisticMap.transactionsWithDecisionSelected++;
          }

          if (transaction.confidence) {
            if (confidenceStatisticMap[transaction.confidence]) {
              confidenceStatisticMap[transaction.confidence].timesCount++;
              confidenceStatisticMap[transaction.confidence].profitLossSumm += transaction.closed_profit;
              confidenceStatisticMap[transaction.confidence].usedTransactions.push(transaction);
            } else {
              confidenceStatisticMap[transaction.confidence] = {
                confidence: transaction.confidence,
                profitLossSumm: transaction.closed_profit,
                timesCount: 1,
                usedTransactions: [transaction]
              };
            }
            summaryStatisticMap.transactionsWithConfidenceSelected++;
          }
        }
      }

      // if monthKey is in the chartDataByMonth object then timestamp in the selected date range
      if (chartDataByMonth[monthKey] && transaction.commissions) {
        if (transaction.commissions !== null) {
          metricDetailsGroup.filteredCommissions += transaction.commissions;
        }

        if (transaction.commissions < 0) {
          newTradesStatisticState.totalGainWithCommSum.count -= transaction.commissions;

          if (!newTradesStatisticState.totalGainWithCommSum.usedTransactions.find((t) => t.transaction_id === transaction.transaction_id)) {
            newTradesStatisticState.totalGainWithCommSum.usedTransactions.push(transaction);
          }
        } else {
          newTradesStatisticState.totalLossWithCommSum.count -= transaction.commissions;

          if (!newTradesStatisticState.totalLossWithCommSum.usedTransactions.find((t) => t.transaction_id === transaction.transaction_id)) {
            newTradesStatisticState.totalLossWithCommSum.usedTransactions.push(transaction);
          }
        }
      }

      summaryStatisticMap.maxDaysInTrade = Math.max(
        summaryStatisticMap.maxDaysInTrade, Math.max(transaction.max_days_in_trade || 0, transaction.max_days_in_trade_assignment || 0)
      );

      if (transaction.avg_days_in_trade !== null) {
        summaryStatisticMap.allAvgTradeDays += transaction.avg_days_in_trade;
        summaryStatisticMap.avgValuesCount++;
      }
      if (transaction.avg_days_in_trade_assignment !== null) {
        summaryStatisticMap.allAvgTradeDays += transaction.avg_days_in_trade_assignment;
        summaryStatisticMap.avgValuesCount++;
      }
    });

    metricDetailsGroups
      .filter((g) => g.usedTransactions.length)
      .forEach((currentGroup) => {
        currentGroup.filteredProfit = currentGroup.filteredPremium - currentGroup.filteredCommissions;

        if (currentGroup.filteredProfit < 0) {
          newTradeGroupsStatisticState.totalLossWithCommSum.count += currentGroup.filteredProfit;
          newTradeGroupsStatisticState.totalLossWithCommSum.usedGroups.push(currentGroup);
        } else {
          newTradeGroupsStatisticState.totalGainWithCommSum.count += currentGroup.filteredProfit;
          newTradeGroupsStatisticState.totalGainWithCommSum.usedGroups.push(currentGroup);
        }

        const filteredUsedTransactions = currentGroup.usedTransactions.filter((g) => {
          const monthProfitKey = g.close_timestamp
            ? moment(g.close_timestamp, MomentDateTimeFormats.ServerDate)?.format(MomentDateTimeFormats.ReadableNoDayDate)
            : null;

          return g.transaction_type !== TradingLogTransactionType.Dividends && chartDataByMonth[monthProfitKey];
        });
        if (filteredUsedTransactions.length > 0) {
          const filteredGroup = {
            ...currentGroup,
            usedTransactions: filteredUsedTransactions
          };

          if (currentGroup.filteredPremium - currentGroup.dividendsPremium < 0) {
            newTradeGroupsStatisticState.totalLossSum.count += filteredGroup.filteredPremium - currentGroup.dividendsPremium;
            newTradeGroupsStatisticState.totalLossSum.usedGroups.push(filteredGroup);

            newTradeGroupsStatisticState.totalLossTradesCount.count++;
            newTradeGroupsStatisticState.totalLossTradesCount.usedGroups.push(filteredGroup);
          } else {
            newTradeGroupsStatisticState.totalWinSum.count += filteredGroup.filteredPremium - currentGroup.dividendsPremium;
            newTradeGroupsStatisticState.totalWinSum.usedGroups.push(filteredGroup);

            newTradeGroupsStatisticState.totalWinTradesCount.count++;
            newTradeGroupsStatisticState.totalWinTradesCount.usedGroups.push(filteredGroup);
          }
        }
      });

    summaryStatisticMap.maxDaysUsedTransactions = transactions.filter(
      (t) => {
        return (t.max_days_in_trade !== null || t.max_days_in_trade_assignment !== null)
          && Math.max(t.max_days_in_trade, t.max_days_in_trade_assignment) === summaryStatisticMap.maxDaysInTrade;
      }
    );

    this.updateTradesDurationState(closedCallPutTrades, closedStockTradesWithAssignments);
    this.updateWeekdayTradesState(weekdayTradesMap, optionsStatisticTableCallTrades.length, optionsStatisticTablePutTrades.length);
    this.updateChartStates(datesSorted, chartDataByMonth);
    this.updateDataAvailabilityStates(appliedMap, this._trChartState$.getValue(), tablesData);
    this.updateSummaryStatisticStates(
      newTradesStatisticState,
      newTradeGroupsStatisticState,
      summaryStatisticMap,
      decisionStatisticMap,
      confidenceStatisticMap,
      tablesData
    );
    this.updateOptionsStatisticState(
      optionsStatisticTotalMap,
      optionsStatisticTableCallTrades,
      optionsStatisticTablePutTrades,
      dateRange.daysCount
    );
    this.updateTableStates(tablesData);
  }

  private updateTradesDurationState(
    closedCallPutTrades: TradingLogDashboardReportInfoModel[],
    closedStockTradesWithAssignments: TradingLogDashboardReportInfoModel[]
  ): void {
    const newTradesDurationTableState: TradesDurationTableModel = { ...emptyTradesDurationTableModel };

    let calculatedCommonMax = 0;
    if (closedCallPutTrades.length) {
      let avgSummary = 0;
      let max = 0;

      closedCallPutTrades.forEach((trade) => {
        avgSummary += trade.avg_days_in_trade;

        if (max < trade.max_days_in_trade) {
          max = trade.max_days_in_trade;
        }
      });

      if (calculatedCommonMax < max) {
        calculatedCommonMax = max;
      }

      newTradesDurationTableState.withoutAssignments = {
        avg: Math.floor(avgSummary / closedCallPutTrades.length),
        max
      };
      newTradesDurationTableState.withoutAssignmentMaxUsedTransactions = closedCallPutTrades.filter((t) => t.max_days_in_trade === max);
    } else {
      newTradesDurationTableState.withoutAssignments = null;
    }

    if (closedStockTradesWithAssignments.length) {
      let avgSummary = 0;
      let max = 0;

      closedStockTradesWithAssignments.forEach((trade) => {
        avgSummary += trade.avg_days_in_trade_assignment;

        if (max < trade.max_days_in_trade_assignment) {
          max = trade.max_days_in_trade_assignment;
        }
      });

      if (calculatedCommonMax < max) {
        calculatedCommonMax = max;
      }

      newTradesDurationTableState.withAssignments = {
        avg: Math.floor(avgSummary / closedStockTradesWithAssignments.length),
        max
      };
      newTradesDurationTableState.withAssignmentMaxUsedTransactions = closedStockTradesWithAssignments.filter(
        (t) => t.max_days_in_trade_assignment === max
      );
    } else {
      newTradesDurationTableState.withAssignments = null;
    }

    calculatedCommonMax = (newTradesDurationTableState.withAssignments && newTradesDurationTableState.withoutAssignments)
      ? calculatedCommonMax : (!newTradesDurationTableState.withAssignments && newTradesDurationTableState.withoutAssignments)
        ? newTradesDurationTableState.withoutAssignments.max : (newTradesDurationTableState.withAssignments && !newTradesDurationTableState.withoutAssignments)
          ? newTradesDurationTableState.withAssignments.max : 0;

    newTradesDurationTableState.all = (newTradesDurationTableState.withAssignments && newTradesDurationTableState.withoutAssignments)
      ? {
        avg: Math.floor((newTradesDurationTableState.withAssignments.avg + newTradesDurationTableState.withoutAssignments.avg) / 2),
        max: calculatedCommonMax
      } : newTradesDurationTableState.withAssignments || newTradesDurationTableState.withoutAssignments;

    newTradesDurationTableState.allMaxUsedTransactions = [
      ...closedCallPutTrades.filter(
        (t) => t.max_days_in_trade !== null && t.max_days_in_trade === calculatedCommonMax
      ),
      ...closedStockTradesWithAssignments.filter(
        (t) => t.max_days_in_trade_assignment !== null && t.max_days_in_trade_assignment === calculatedCommonMax
      )
    ];

    this._tradesDurationState$.next(newTradesDurationTableState);
  }

  private updateWeekdayTradesState(
    weekdayTradesMap: OptionsPutCallByDayDataModel,
    callTradesCount: number,
    putTradesCount: number
  ): void {
    this._weekdayTradesState$.next(Object.entries(weekdayTradesMap).map(([key, value]) => ({
      weekday: key,
      calls: value.call && +((value.call / callTradesCount) * 100).toFixed(2),
      puts: value.put && +((value.put / putTradesCount) * 100).toFixed(2),
      all: value.all && +((value.all / (callTradesCount + putTradesCount)) * 100).toFixed(2)
    })));
  }

  private updateChartStates(
    datesSorted: ReadonlyArray<string>,
    chartDataByMonth: Record<string, TradingLogDashboardChartMonthDataModel>
  ): void {
    this._plChartState$.next(datesSorted.map((key) => ({
      date: key,
      options: chartDataByMonth[key].optionsClosedProfitSum - chartDataByMonth[key].optionsCommissionsSum,
      stocks: chartDataByMonth[key].stocksClosedProfitSum - chartDataByMonth[key].stocksCommissionsSum,
      dividends: chartDataByMonth[key].dividendsClosedProfitSum - chartDataByMonth[key].dividendsCommissionsSum,
      total: chartDataByMonth[key].optionsClosedProfitSum - chartDataByMonth[key].optionsCommissionsSum
        + chartDataByMonth[key].stocksClosedProfitSum - chartDataByMonth[key].stocksCommissionsSum
        + chartDataByMonth[key].dividendsClosedProfitSum - chartDataByMonth[key].dividendsCommissionsSum,
      avg: 0
    })));

    this._cumulativeChartState$.next(datesSorted.map((key) => ({
      date: key,
      optionsSumm: chartDataByMonth[key].optionsClosedProfitSum - chartDataByMonth[key].optionsCommissionsSum,
      stocksSumm: chartDataByMonth[key].stocksClosedProfitSum - chartDataByMonth[key].stocksCommissionsSum,
      dividendsSumm: chartDataByMonth[key].dividendsClosedProfitSum - chartDataByMonth[key].dividendsCommissionsSum,
      dividendsCount: chartDataByMonth[key].dividendsRecordsCount,
      optionsCount: chartDataByMonth[key].optionsRecordsCount,
      stocksCount: chartDataByMonth[key].stocksRecordsCount
    })));

    this._comChartState$.next(datesSorted.map((key) => ({
      date: key,
      options: chartDataByMonth[key].optionsCommissionsSum,
      stocks: chartDataByMonth[key].stocksCommissionsSum,
      dividends: chartDataByMonth[key].dividendsCommissionsSum,
      total: chartDataByMonth[key].optionsCommissionsSum + chartDataByMonth[key].stocksCommissionsSum + chartDataByMonth[key].dividendsCommissionsSum,
      avg: (chartDataByMonth[key].optionsRecordsCount + chartDataByMonth[key].stocksRecordsCount + chartDataByMonth[key].dividendsRecordsCount) ?
        ((chartDataByMonth[key].optionsCommissionsSum + chartDataByMonth[key].stocksCommissionsSum + chartDataByMonth[key].dividendsCommissionsSum) /
          (chartDataByMonth[key].optionsRecordsCount + chartDataByMonth[key].stocksRecordsCount + chartDataByMonth[key].dividendsRecordsCount)
        ) : null
    })));


    this._trChartState$.next(datesSorted.map((key) => ({
      date: key,
      options: chartDataByMonth[key].optionsRecordsCount,
      stocks: chartDataByMonth[key].stocksRecordsCount,
      dividends: chartDataByMonth[key].dividendsRecordsCount,
      total: chartDataByMonth[key].optionsRecordsCount
        + chartDataByMonth[key].stocksRecordsCount + chartDataByMonth[key].dividendsRecordsCount,
      avg: 0
    })));

    this._dvChartState$.next(datesSorted.map((key) => ({
      date: key,
      value: chartDataByMonth[key].dividendsClosedProfitSum - chartDataByMonth[key].dividendsCommissionsSum,
    })));

    this._optsChartState$.next(datesSorted.map((key) => ({
      date: key,
      profit: chartDataByMonth[key].optionsClosedProfitSum - chartDataByMonth[key].optionsCommissionsSum,
      trades: chartDataByMonth[key].optionsRecordsCount,
    })));

    this._plChartSummaryState$.next([
      {
        title: 'Total',
        value: this.getFormattedChartPriceValue(this._plChartState$.getValue(), 'total')
      },
      {
        title: 'Options',
        value: this.getFormattedChartPriceValue(this._plChartState$.getValue(), 'options')
      },
      {
        title: 'Stocks',
        value: this.getFormattedChartPriceValue(this._plChartState$.getValue(), 'stocks')
      },
      {
        title: 'Dividends',
        value: this.getFormattedChartPriceValue(this._plChartState$.getValue(), 'dividends')
      },
    ]);

    this._comChartSummaryState$.next([
      {
        title: 'Total',
        value: this.getFormattedChartPriceValue(this._comChartState$.getValue(), 'total')
      },
      {
        title: 'Options',
        value: this.getFormattedChartPriceValue(this._comChartState$.getValue(), 'options')
      },
      {
        title: 'Stocks',
        value: this.getFormattedChartPriceValue(this._comChartState$.getValue(), 'stocks')
      },
      {
        title: 'Dividends',
        value: this.getFormattedChartPriceValue(this._comChartState$.getValue(), 'dividends')
      },
      {
        title: 'Avg.',
        value: this.getFormattedChartAvgPriceValue(this._comChartState$.getValue(), 'total', this._trChartState$.getValue(), 'total')
      },
    ]);

    this._trChartSummaryState$.next([
      {
        title: 'Total',
        value: this.getFormattedChartNumberValue(this._trChartState$.getValue(), 'total')
      },
      {
        title: 'Options',
        value: this.getFormattedChartNumberValue(this._trChartState$.getValue(), 'options')
      },
      {
        title: 'Stocks',
        value: this.getFormattedChartNumberValue(this._trChartState$.getValue(), 'stocks')
      },
      {
        title: 'Dividends',
        value: this.getFormattedChartNumberValue(this._trChartState$.getValue(), 'dividends')
      },
    ]);

    this._dividendsState$.next(formatPrice(
      this._dvChartState$.getValue().map((v) => v.value).reduce((previous, current) => (previous + current), 0),
      2
    ));
    this._optionsState$.next(this.formatPriceOrEmpty(
      this._plChartState$.getValue().map((v) => v.options).reduce((previous, current) => (previous + current), 0)
    ));
  }

  private updateDataAvailabilityStates(
    appliedMap: AppliedMap,
    trChartData: ReadonlyArray<TosChartModel>,
    tablesData: Record<string, TradingLogDashboardSymbolDataModel>
  ): void {
    const {
      isOptionsProfitApplied,
      isStocksProfitApplied,
      isDividendsProfitApplied,
      isStocksCommissionsApplied,
      isOptionsCommissionsApplied,
      isDividendsCommissionsApplied
    } = appliedMap;

    this._isNoProfitChartsState$.next(
      !isOptionsProfitApplied
      && !isStocksProfitApplied
      && !isDividendsProfitApplied
      && !isStocksCommissionsApplied
      && !isOptionsCommissionsApplied
      && !isDividendsCommissionsApplied
    );
    this._isNoCommissionsChartsState$.next(!isStocksCommissionsApplied && !isOptionsCommissionsApplied && !isDividendsCommissionsApplied);
    this._isNoDividendsChartsState$.next(!isDividendsProfitApplied && !isDividendsCommissionsApplied);

    this._isNoTradesChartsState$.next(!trChartData.find((val) => val.total));

    this._isNoStocksTabState$.next(!Object.keys(tablesData).find((symbol) => (tablesData[symbol]).stockTradesCount));

    this._isNoSummaryTabState$.next(
      this._isNoCommissionsChartsState$.getValue()
      && this._isNoDividendsChartsState$.getValue()
      && this._isNoTradesChartsState$.getValue()
      && this._isNoProfitChartsState$.getValue()
    );

    this._isNoOptionsTabState$.next(Object.keys(tablesData).filter((symbol) => tablesData[symbol].optionTradesCount > 0).length === 0);
  }

  private updateSummaryStatisticStates(
    newTradesStatisticState: TradesStatisticModel,
    newTradeGroupsStatisticState: TradeGroupsStatisticModel,
    summaryStatisticMap: SummaryStatisticMap,
    decisionStatisticMap: Record<string, DecisionStatisticModel>,
    confidenceStatisticMap: Record<string, ConfidenceStatisticModel>,
    tablesData: Record<string, TradingLogDashboardSymbolDataModel>
  ): void {
    const {
      maxDaysInTrade,
      allAvgTradeDays,
      avgValuesCount,
      transactionsWithProfitCount,
      transactionsWithDecisionSelected,
      transactionsWithConfidenceSelected,
      transactionsWithDisabledEvaluation,
      maxDaysUsedTransactions
    } = summaryStatisticMap;

    this._tradesStatisticState$.next(newTradesStatisticState);
    this._tradeGroupsStatisticState$.next(newTradeGroupsStatisticState);

    this._symbolsStatisticState$.next(
      Object.keys(tablesData)
        .map((symbol) => ({
          symbol,
          usedTransactions: tablesData[symbol].usedTransactions.filter((t) => t.close_timestamp !== null || t.commissions !== null),
          tradesCount: tablesData[symbol].stockTradesCount + tablesData[symbol].optionTradesCount + tablesData[symbol].dividendsTradesCount,
          tradesSum: tablesData[symbol].stocksClosedProfitSum - tablesData[symbol].stocksCommissionsSum
            + tablesData[symbol].optionsClosedProfitPutSum - tablesData[symbol].optionsCommissionsPutSum
            + tablesData[symbol].optionsClosedProfitCallSum - tablesData[symbol].optionsCommissionsCallSum
            + tablesData[symbol].dividendsClosedProfitSum - tablesData[symbol].dividendsCommissionsSum
        }))
        .filter((symbolData) => symbolData.usedTransactions.length)
    );

    this._avgAndLongestTradeLengthState$.next({
      totalValue: maxDaysInTrade,
      maxDaysUsedTransactions,
      progressValue: Math.floor(avgValuesCount ? allAvgTradeDays / avgValuesCount : 0),
      isNoData: !transactionsWithProfitCount
    });

    this._decisionsStatisticState$.next(Object.values(decisionStatisticMap));
    this._confidenceStatisticState$.next(Object.values(confidenceStatisticMap));

    const profitTransactionsWithEnabledEvaluationCount = transactionsWithProfitCount - transactionsWithDisabledEvaluation;
    this._decisionEvaluatedPercentageState$.next(
      !profitTransactionsWithEnabledEvaluationCount
        ? 0 : transactionsWithDecisionSelected / profitTransactionsWithEnabledEvaluationCount * 100
    );
    this._confidenceEvaluatedPercentageState$.next(
      !profitTransactionsWithEnabledEvaluationCount
        ? 0 : transactionsWithConfidenceSelected / profitTransactionsWithEnabledEvaluationCount * 100
    );
  }

  private updateOptionsStatisticState(
    totalMap: OptionsStatisticTotalMap,
    calls: OptionsSummaryCallPutInputModel[],
    puts: OptionsSummaryCallPutInputModel[],
    daysCount: number
  ): void {
    const common = {
      putPl: totalMap.totalPutClosedProfit - totalMap.totalPutCommissions,
      putAssignedCount: totalMap.totalAssignedTrades,
      putSellTrades: totalMap.totalSellPutTrades,
      callPl: totalMap.totalCallClosedProfit - totalMap.totalCallCommissions,
      putPrem: totalMap.totalPutClosedProfit,
      putComm: totalMap.totalPutCommissions,
      callPrem: totalMap.totalCallClosedProfit,
      callComm: totalMap.totalCallCommissions,
      daysCount
    };

    this._optionsStatisticTableState$.next({ common, calls, puts });
  }

  private updateTableStates(tablesData: Record<string, TradingLogDashboardSymbolDataModel>): void {
    this._optionSymbolsTableState$.next(Object.keys(tablesData)
      .filter((symbol) => tablesData[symbol].optionTradesCount > 0)
      .map((symbol) => ({
        symbol,
        usedTransactions: tablesData[symbol].usedTransactions.filter((t) => TradingLogTransactionIsOptionMap[t.transaction_type]),
        totalClosedProfit: tablesData[symbol].optionsClosedProfitCallSum + tablesData[symbol].optionsClosedProfitPutSum,
        feesAndCommissions: tablesData[symbol].optionsCommissionsCallSum + tablesData[symbol].optionsCommissionsPutSum,
        profitAndLoss: tablesData[symbol].optionsClosedProfitCallSum + tablesData[symbol].optionsClosedProfitPutSum
          - tablesData[symbol].optionsCommissionsCallSum - tablesData[symbol].optionsCommissionsPutSum,
        putClosedProfit: tablesData[symbol].optionsClosedProfitPutSum,
        callClosedProfit: tablesData[symbol].optionsClosedProfitCallSum,
        assignedTimes: tablesData[symbol].optionsAssignedCount,
        trades: tablesData[symbol].optionTradesCount,
        putSellTrades: tablesData[symbol].optionPutSellTradesCount
      })));

    this._stockSymbolsTableState$.next(Object.keys(tablesData)
      .filter((symbol) => tablesData[symbol].stockTradesCount > 0)
      .map((symbol) => ({
        symbol,
        usedTransactions: tablesData[symbol].usedTransactions.filter(
          (t) => TradingLogTransactionIsStockMap[t.transaction_type] || t.transaction_type === TradingLogTransactionType.Dividends
        ),
        feesAndCommissions: tablesData[symbol].stocksCommissionsSum + tablesData[symbol].dividendsCommissionsSum,
        dividends: tablesData[symbol].dividendsClosedProfitSum,
        profitAndLoss: tablesData[symbol].stocksClosedProfitSum - tablesData[symbol].stocksCommissionsSum + tablesData[symbol].dividendsClosedProfitSum - tablesData[symbol].dividendsCommissionsSum,
        sharesSold: tablesData[symbol].stockSharesSoldCount,
        sharesBought: tablesData[symbol].stockSharesBoughtCount,
        trades: tablesData[symbol].stockTradesCount,
      })));

    this._stockTopSymbolsTableState$.next(Object.keys(tablesData)
      .filter((symbol) => tablesData[symbol].stockTradesCount > 0)
      .sort(getNumberComparerDesc((symbol) => tablesData[symbol].stockTradesCount))
      .filter((symbol, index) => index < this._stocksTopTableSymbolsCount)
      .map((symbol) => ({
        symbol,
        tradesWithProfitCount: tablesData[symbol].stockTradesWithProfitCount,
        profitSum: tablesData[symbol].stocksClosedProfitSum - tablesData[symbol].stocksCommissionsSum,
        tradesCount: tablesData[symbol].stockTradesCount,
        winTradesCount: tablesData[symbol].stockWinTradesCount
      })));
  }

  private getFormattedChartPriceValue<T>(chartData: T[], fieldName: keyof Omit<T, 'date'>): string {
    return this.formatPriceOrEmpty(
      chartData.map((v) => (v[fieldName] as unknown as number)).reduce((previous, current) => (previous + current), 0)
    );
  }

  private getFormattedChartAvgPriceValue<T>(chartData: T[], field1: keyof Omit<T, 'date'>, chartData2: T[], field2: keyof Omit<T, 'date'>): string {
    const fieldSum1 = chartData.map((v) => (v[field1] as unknown as number)).reduce((previous, current) => (previous + current), 0);
    const fieldSum2 = chartData2.map((v) => (v[field2] as unknown as number)).reduce((previous, current) => (previous + current), 0);
    const avg = fieldSum1 && fieldSum2 ? fieldSum1 / fieldSum2 : 0;
    return this.formatPriceOrEmpty(
      avg
    );
  }

  private formatPriceOrEmpty(value: number): string {
    return value ? formatPrice(value, 2) : '-';
  }

  private getFormattedChartNumberValue<T>(chartData: T[], fieldName: keyof Omit<T, 'date'>): string {
    return this.formatNumberOrEmpty(
      chartData.map((v) => (v[fieldName] as unknown as number)).reduce((previous, current) => (previous + current), 0)
    );
  }

  private formatNumberOrEmpty(value: number): string {
    return value ? formatNumber(value) : '-';
  }
}
