import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { SolSignalAlertComponent } from '@c/sol-signal-alert/sol-signal-alert.component';
import { SolSignalAlertDataModel } from '@c/sol-signal-alert/sol-signal-alert.model';
import { DataChannelCommands, Features, MomentDateTimeFormats } from '@const';
import { IDataChannelCommand } from '@mod/data/data-channel.model';
import { ServerNotificationDataModel, ServerNotificationTypes, SolServerNotificationDataModel } from '@mod/server-notifications';
import { DataChannelService } from '@s/data-channel.service';
import { EditionsService } from '@s/editions.service';
import { ServerNotificationsSettingsService } from '@s/server-notifications/server-notifications-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ServerNotificationsService implements OnDestroy {
  private dataChannelSubscribeCommand: IDataChannelCommand | null = null;

  constructor(
    private dataChannelService: DataChannelService,
    private editionsService: EditionsService,
    private serverNotificationsSettingsService: ServerNotificationsSettingsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnDestroy(): void {
    if (this.dataChannelSubscribeCommand) {
      this.dataChannelService.unsubscribe(this.dataChannelSubscribeCommand);
      this.dataChannelSubscribeCommand = null;
    }

    this.unsubscribeFormNotifications();
  }

  public init(): void {
    // important: make sure that user's notification settings are loaded
    // currently it should be done in app-loader service

    this.dataChannelSubscribeCommand = {
      subscriptionId: uuidV4(),
      name: DataChannelCommands.WebsitePopupNotificationsSubscribe,
      data: {},
      handler: this.handleNotifications.bind(this),
    };

    this.dataChannelService.subscribe(this.dataChannelSubscribeCommand);
  }

  public unsubscribeFormNotifications(): void {
    if (this.dataChannelSubscribeCommand) {
      this.dataChannelService.unsubscribe(this.dataChannelSubscribeCommand);
      this.dataChannelSubscribeCommand = null;
    }
  }

  private async handleNotifications(notifications: ServerNotificationDataModel[]): Promise<void> {
    // currently - all notifications are enabled by default, if user disabled some of them - we should ignore them
    const settings = this.serverNotificationsSettingsService.currentServerNotificationsSettings$.getValue();
    const disabledNotificationTypes = settings
      .filter((setting) => setting.is_active === 0)
      .map((setting) => setting.type);

    // TODO: discuss behaviour for multiple notification types in one WS event - it this is possible

    const filteredSolNotifications = [...notifications].filter((notification) => {
      return notification.strategy === ServerNotificationTypes.SOL
        && !disabledNotificationTypes.includes(notification.strategy);
    });

    if (filteredSolNotifications.length > 0) {
      await this.showSolNotifications(filteredSolNotifications);
    }
  }

  // note: snackbars/modals for specific notification-types can be moved to separate services (be type)
  // in this case feature availability check should be done in the service
  private async showSolNotifications(data: SolServerNotificationDataModel[]): Promise<void> {
    const isSolAvailable = this.editionsService.isFeatureAvailable(Features.ShortSellingStocks);

    if (!isSolAvailable) {
      return;
    }

    // show only unique items (by symbol and time)
    // if there are 2 types - "new" and "open" - show only "open"
    const uniqueNotifications = data.reduce((acc, notification) => {
      const key = `${notification.symbol}_${notification.time}`;

      if (!acc[key]) {
        acc[key] = notification;
      } else if (notification.type === 'open') {
        acc[key] = notification;
      }

      return acc;
    }, []);

    const notificationsContent = Object.values(uniqueNotifications).map((notification) => {
      const localTime = moment
        .utc(notification.time, MomentDateTimeFormats.ReadableFullTimeWithYear)
        .tz(moment.tz.guess(true))
        .format(MomentDateTimeFormats.FullTimeAmPm);
      let message = notification.message;

      if (notification.type === 'new') {
        message = `has a NEW signal.`;
      }

      if (notification.type === 'open') {
        message = `has an OPEN signal.`;
      }

      return {
        icon: 'trend-alert',
        symbol: notification.symbol,
        securityId: notification.security_id,
        message,
        time: localTime,
      };
    });

    const snackBarConfig = new MatSnackBarConfig<SolSignalAlertDataModel>();

    snackBarConfig.horizontalPosition = 'start';
    snackBarConfig.verticalPosition = 'bottom';
    snackBarConfig.duration = null;
    snackBarConfig.data = { notifications: notificationsContent };

    const openedSnackBarRef = this.snackBar.openFromComponent(SolSignalAlertComponent, snackBarConfig);

    openedSnackBarRef
      .onAction()
      .pipe(take(1))
      .subscribe(() => {
        openedSnackBarRef.dismiss();
      });

    try {
      const audioElement = new Audio('../../../assets/sounds/sol-alert.mp3');
      audioElement.autoplay = false;
      audioElement.muted = false;
      await audioElement.play();
    } catch (error) {
      console.warn('Error while playing SOL alert sound:', error);
    }

    // play sound only ones (there can de several notifications in one event), ignore promise
    // await this.playSolAlertSound();
  }

  private async playSolAlertSound(): Promise<void> {
    try {
      const audioElement = new Audio('../../../assets/sounds/sol-alert.mp3');
      audioElement.autoplay = false;
      audioElement.muted = false;
      await audioElement.play();
    } catch (error) {
      console.warn('Error while playing SOL alert sound:', error);
    }
  }
}
