import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, EMPTY, from, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ExchangeCountriesCodes, Features, UserSettings, USExchangesForGoogleSearch, WatchlistType } from '@const';
import { roiPartLegendForTooltip } from '@constants/tooltips';
import { ObservableService } from '@core1/directives/observable.service';
import { Flags, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { DialogsService } from '@s/common/dialogs.service';
import { EditionsService } from '@s/editions.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { NavigationService } from '@s/navigation.service';
import { ObservableService as ObservableService2 } from '@s/observable.service';
import { SecurityDataDetailsService } from '@s/security-data-details.service';
import { StreamingService } from '@s/streaming.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import {
  DataWindowUpdateSource,
  IDataWindowUpdateCommand,
  ISymbolDetailsV2WithSource,
  IWheelFilter,
  IWheelStockOption,
  IWheelStockOptionRaw,
} from '@t/wheel/wheel.types';
import { convertToEasternTime } from '@u/utils';
import { MaxPopupComponent } from '../wheel-calculator/max-popup/max-popup.component';
import { WheelCalculatorService } from '../wheel-calculator/wheel-calculator.service';
import { WheelScannerService } from '../wheel-scanner-tab/wheel-scanner.service';
import { WheelService } from '../wheel.service';
import { CustomTable, IStockSubscription } from './wheel-data-window.model';

@Component({
  selector: 'app-wheel-datawindow',
  templateUrl: './wheel-datawindow.component.html',
  styleUrls: ['./wheel-datawindow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WheelDatawindowComponent implements OnInit, OnDestroy {
  public sources: Array<'A' | 'C' | null> = [];
  public strikePrice: CustomTable[] = [];
  public premium: CustomTable[] = [];
  public expiration: CustomTable[] = [];
  public drop: CustomTable[] = [];
  public dte: CustomTable[] = [];
  public buyingPower: CustomTable[] = [];
  public annularized: CustomTable[] = [];
  public delta: CustomTable[] = [];

  public wheelCalc$: Subscription;
  public colGroupsByDate: number[] = [];
  public scannerResults: IWheelStockOptionRaw[] = [];

  public subscribedStock: IStockSubscription = null;

  public securityDetails$: Observable<ISymbolDetailsV2WithSource | null> = combineLatest([
    this.wheelService.lastReceivedSecurityDetails$,
    this.wheelService.updateDataWindowSymbol$,
  ]).pipe(
    switchMap(([securityDetailsMap, symbolUpdate]) => {
      if (!securityDetailsMap || !symbolUpdate.symbol.id || !securityDetailsMap[symbolUpdate.symbol.id]) {
        return combineLatest([
          from(this.symbolsService.getById(symbolUpdate.symbol.id)),
          from(this.securityDataDetailsService.get(symbolUpdate.symbol.id)),
          from(this.historicalDataService.get(symbolUpdate.symbol.id)),
        ]).pipe(
          map(([symbol, security, historicalData]) => {
            const lastTradePrice = historicalData.length ? historicalData[historicalData.length - 1].close : null;

            return {
              atr: undefined,
              close: null,
              close_40_min: null,
              company: symbol.description,
              created_date: symbol.last_updated.toString(),
              dividends_count: null,
              drop_ratio: null,
              exchange: symbol.exchange_name,
              exchange_code: symbol.exchange_code,
              country_code: symbol.country_code,
              industry: security.industry_name,
              is_scanner: false,
              last_traded_price: lastTradePrice,
              long_description: security.long_description,
              maxAggressivePrice: null,
              maxConservativePrice: null,
              rsi: null,
              sector: security.sector_name,
              security_id: security.security_id,
              symbol: security.symbol,
              isFromScanner: false, // add source marker
              market_cap: security.market_cap,
              pe_ratio: security.pe_ratio,
              dividend_yield: security.dividend_yield,
              earnings_share: security.earnings_share,
              percent_insiders: security.percent_insiders,
              percent_institutions: security.percent_institutions,
            };
          }),
        );
      }

      // send request to historical-data, take only latest with debounce-time

      return of({
        ...securityDetailsMap[symbolUpdate.symbol.id],
        isFromScanner: true, // add source marker
      });
    }),
  );

  public selectedSymbolName: string = null;
  public selectedSecurityId: number = null;
  public lastTradePrice = 0;

  public sourcesTooltipText = { A: 'Aggressive', C: 'Conservative' };

  public showTooltip: boolean;
  public tooltipWidth: string;
  public roiLowerBound = 30;
  public roiUpperBound = 40;

  public existedFilters: IWheelFilter;

  protected readonly features = Features;
  public exchangeForGoogleSearch = USExchangesForGoogleSearch;
  public newsIconSrc: string;

  private subscriber = new Subscription();
  private buttonClicked$ = new Subject<number>();
  private timeoutSub: Subscription;
  private saveDataWindowSource$ = new Subject<DataWindowUpdateSource>();
  private wheelROIRangeSubscription: Subscription = null;

  // for symbol-details-panel
  public currentSymbol$ = this.securityDetails$.pipe(map((details) => (details ? details.security_id : null)));
  public securityDetailsMap$ = this.wheelService.lastReceivedSecurityDetails$;
  public readonly watchlistTypes = WatchlistType;
  public readonly smileyListType = SmileyListType;

  protected roiLegend = `
    The annualized ROI for the credit received.
    ${roiPartLegendForTooltip}
  `;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private securityDataDetailsService: SecurityDataDetailsService,
    private symbolsService: SymbolsService,
    private scannerService: WheelScannerService,
    private dialog: MatDialog,
    private wheelCalculatorService: WheelCalculatorService,
    private wheelService: WheelService,
    private observableService: ObservableService,
    private observableService2: ObservableService2,
    private userDataService: UserDataService,
    private streamingService: StreamingService,
    private historicalDataService: HistoricalDataService,
    private navigationService: NavigationService,
    private dialogsService: DialogsService,
    private editionsService: EditionsService,
  ) {
    this.observableService.wheelFilters.subscribe((res) => {
      this.existedFilters = res;
    });
    this.wheelROIRangeSubscription = this.observableService.wheelRoiRange.subscribe((res) => {
      this.roiLowerBound = res.lower;
      this.roiUpperBound = res.upper;
    });
  }

  async ngOnInit(): Promise<void> {
    this.timeoutSub = this.buttonClicked$
      .pipe(debounceTime(1000))
      .subscribe((data: number) => this.handleClickEvent(data));

    try {
      this.existedFilters = JSON.parse(localStorage.getItem('wheelFilter'));
    } catch (error) {
      console.error(`WHEEL :: Something went wrong on restoring wheel filters, error: ${error}`);
    }

    this.subscriber.add(
      this.securityDetails$
        .pipe(
          distinctUntilChanged((v1, v2) => v1?.security_id === v2?.security_id),
          switchMap((securityDetails) => (securityDetails ? from(this.updateSymbolData(securityDetails)) : EMPTY)),
        )
        .subscribe(),
    );

    this.subscriber.add(
      combineLatest([this.wheelService.updateDataWindowFromScanner$, this.observableService.wheelFilters])
        .pipe(withLatestFrom(this.wheelService.updateDataWindowSymbol$, this.scannerService.lastReceivedScannerData$))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .subscribe(([[updateSignal, filters], symbolData, scannerData]) => {
          this.handleReceivedSymbolData(symbolData, scannerData, filters);
        }),
    );

    this.wheelService.updateStockList$.next(); // activate combineLatest

    this.subscriber.add(
      this.wheelService.updateDataWindowSymbol$
        .pipe(
          withLatestFrom(
            this.observableService.wheelFilters,
            this.scannerService.smileyTrigger$,
            this.scannerService.lastReceivedScannerData$,
          ),
        )
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .subscribe(async ([symbolData, filters, smiley, scannerData]) => {
          this.handleReceivedSymbolData(symbolData, scannerData, filters);
          this.saveDataWindowSource$.next(symbolData.source);
        }),
    );

    // should be preloaded: wheelDWSUpdateSource, wheelFilters
    // receive last used source of updating
    this.observableService2.wheelDataWindowUpdateSource.pipe(take(1)).subscribe(async (wheelDWSUpdateSource) => {
      const symbolId = this.observableService2.wheelSymbol.getValue();

      if (
        wheelDWSUpdateSource === DataWindowUpdateSource.ScannerConservative ||
        wheelDWSUpdateSource === DataWindowUpdateSource.ScannerAggressive
      ) {
        this.scannerService.firstScannerResponseReceived
          .pipe(
            switchMap(() => this.scannerService.lastReceivedScannerData$.asObservable()),
            take(1),
            withLatestFrom(this.observableService.wheelFilters),
          )
          .subscribe(async ([data, filters]) => {
            const riskType =
              wheelDWSUpdateSource === DataWindowUpdateSource.ScannerConservative ? 'conservative' : 'aggressive';

            const symbolOptionsFromScanner = this.scannerService.filterStockOptions(
              data.filter((item) => item.security_id === symbolId && item.atr_type === riskType),
              filters,
              this.scannerService.uniqueListOfExpiration$.getValue(),
            );

            if (symbolOptionsFromScanner.length === 0) {
              this.wheelService.updateDataWindowSymbol$.next({
                source: DataWindowUpdateSource.ChartSearchBar,
                symbol: { id: symbolId, name: '' },
              });

              return;
            }

            this.wheelService.updateDataWindowSymbol$.next({
              source: wheelDWSUpdateSource,
              symbol: { id: symbolId, name: '', options: data },
            });
          });
      } else {
        this.wheelService.updateDataWindowSymbol$.next({
          source: DataWindowUpdateSource.ChartSearchBar,
          symbol: { id: symbolId, name: '' },
        });
      }
    });

    this.subscriber.add(
      this.saveDataWindowSource$.pipe(distinctUntilChanged()).subscribe(async (source) => {
        await this.userDataService.set(UserSettings.WheelDataWindowUpdateSource, source);
      }),
    );

    const wheelROIRanges = this.observableService2.wheelRoiRange.getValue();
    this.roiLowerBound = wheelROIRanges.lower;
    this.roiUpperBound = wheelROIRanges.upper;
  }

  ngOnDestroy(): void {
    if (this.wheelCalc$) {
      this.wheelCalc$.unsubscribe();
    }

    if (this.timeoutSub) {
      this.timeoutSub.unsubscribe();
    }

    if (this.subscribedStock) {
      this.streamingService.unsubscribe(this.subscribedStock.subscriptionId, this.subscribedStock.symbol);
    }

    if (this.wheelROIRangeSubscription) {
      this.wheelROIRangeSubscription.unsubscribe();
    }

    this.subscriber.unsubscribe();
  }

  public onSelectSymbol(index: number): void {
    this.buttonClicked$.next(index);
  }

  protected onSelectSymbolFromDataWindow(symbol: ISymbol): void {
    if (symbol.country_code !== ExchangeCountriesCodes.US) {
      return;
    }

    // apply for data-window
    this.wheelService.updateDataWindowSymbol$.next({
      symbol: { id: symbol.security_id, name: symbol.symbol },
      source: DataWindowUpdateSource.ChartSearchBar,
    });

    this.observableService2.wheelSymbol.next(symbol.security_id);
    this.userDataService.set(UserSettings.WheelSymbol, symbol.security_id);
  }

  protected redirectToExpectedMoveDemoPage(): void {
    this.editionsService.redirectToDemoPage(Features.ExpectedMove);
  }

  private handleReceivedSymbolData(
    symbolData: IDataWindowUpdateCommand,
    scannerData: Array<IWheelStockOption>,
    filters: IWheelFilter,
  ): void {
    if (symbolData.source === DataWindowUpdateSource.ScannerAggressive) {
      const filteredOptions = this.scannerService.filterStockOptions(
        scannerData.filter(
          (item) => item.security_id === symbolData.symbol.id && item.atr_type === 'aggressive' && item.is_scanner,
        ),
        filters,
        this.scannerService.uniqueListOfExpiration$.getValue(),
      );

      this.updateOptionsTable(filteredOptions); // use received options from scanner
      this.wheelService.optionsForMinStrike$.next(filteredOptions);
    } else if (symbolData.source === DataWindowUpdateSource.ScannerConservative) {
      const filteredOptions = this.scannerService.filterStockOptions(
        scannerData.filter(
          (item) => item.security_id === symbolData.symbol.id && item.atr_type === 'conservative' && item.is_scanner,
        ),
        filters,
        this.scannerService.uniqueListOfExpiration$.getValue(),
      );

      this.wheelService.optionsForMinStrike$.next(filteredOptions);
      this.updateOptionsTable(filteredOptions); // use received options from scanner
    } else {
      const filteredOptions = this.scannerService.filterStockOptions(
        scannerData.filter((item) => item.security_id === symbolData.symbol.id),
        filters,
        this.scannerService.uniqueListOfExpiration$.getValue(),
      );

      // apply filter by earn-before-exp for data for symbol selected from search-bar
      const fromSearch = filteredOptions.map((item) => ({
        ...item,
        atr_type:
          item.is_scanner && item.atr_type
            ? item.atr_type // show A/C for results from scanner
            : null,
      }));

      this.wheelService.optionsForMinStrike$.next(fromSearch);
      this.updateOptionsTable(fromSearch);
    }
  }

  private updateOptionsTable(options: Array<IWheelStockOptionRaw>): void {
    const result = options ? this.addFlagToDataObs(options) : [];
    const sortedResult = _.sortBy(result, ['expiration', 'strike_price']);

    this.scannerResults = Object.assign([], sortedResult);
    this.sources = [];
    this.strikePrice = [];
    this.premium = [];
    this.expiration = [];
    this.drop = [];
    this.dte = [];
    this.buyingPower = [];
    this.annularized = [];
    this.delta = [];

    if (this.scannerResults && this.scannerResults.length > 0) {
      this.scannerResults.forEach((d) => {
        if (d.atr_type === 'aggressive') {
          this.sources.push('A');
        } else if (d.atr_type === 'conservative') {
          this.sources.push('C');
        } else {
          this.sources.push(null);
        }

        this.strikePrice.push(new CustomTable(d.strike_price));
        this.delta.push(new CustomTable(d.delta));
        this.premium.push(new CustomTable(d.bid_price, d.min));
        this.dte.push(new CustomTable(d.dte));
        this.buyingPower.push(new CustomTable(d.strike_price * 100));
        this.annularized.push(new CustomTable(d.annularized));
        this.expiration.push(new CustomTable(moment(d.expiration).format('MMM D')));
        this.drop.push(new CustomTable(d.drop_percentage));
      });

      this.colGroupsByDate = this.getColGroups(this.expiration.map((exp) => exp.value));
    }
  }

  private async updateSymbolData(securityDetails: ISymbolDetailsV2WithSource): Promise<void> {
    if (!securityDetails) {
      return;
    }

    // set available data (last_traded_price and earnings) or set it to null
    this.lastTradePrice = securityDetails.last_traded_price;

    if (this.subscribedStock) {
      this.streamingService.unsubscribe(this.subscribedStock.subscriptionId, this.subscribedStock.symbol);
    }

    this.subscribedStock = {
      subscriptionId: uuidV4(),
      symbol: {
        symbol: securityDetails.symbol,
        country_code: ExchangeCountriesCodes.US,
      },
    };

    this.streamingService.subscribe(
      this.subscribedStock.subscriptionId,
      this.subscribedStock.symbol,
      {},
      this.handleLiveDataCallback.bind(this),
    );

    // update last-traded-price from historical-data if it's from scannerV2
    if (securityDetails.isFromScanner) {
      try {
        const historicalData = await this.historicalDataService.get(securityDetails.security_id);

        this.lastTradePrice = historicalData?.length ? historicalData[historicalData.length - 1].close : null;
      } catch (error) {
        console.error(
          `WHEEL :: Something went wrong on fetching historical-data for security_id: ${securityDetails.security_id}, error: ${error}`,
        );
        this.lastTradePrice = null;
      }
    }
  }

  private handleLiveDataCallback(data): void {
    this.securityDetails$.pipe(take(1)).subscribe((securityDetails) => {
      if (data && !data.isPreMarket && !data.isPostMarket && data.symbol === securityDetails.symbol) {
        this.lastTradePrice = data.close;
      }
    });
  }

  private handleClickEvent(index: number): void {
    this.wheelService.onTriggerToSaveCalc$(true);

    this.securityDetails$.pipe(take(1)).subscribe((securityDetails) => {
      if (!securityDetails) {
        return;
      }
      const strikePrice = this.strikePrice[index].value;
      const premium = this.premium[index].value;
      const expiration = this.scannerResults[index].expiration;
      const stockPrice = this.lastTradePrice;
      const symbol = securityDetails.symbol;
      const uId = JSON.parse(localStorage.getItem('user')).userId;
      const delta = this.delta[index].value;
      const min = this.premium[index].value1;

      setTimeout(() => {
        this.save([uId, symbol, stockPrice, premium, strikePrice, expiration, delta, min, []]);
      }, 200);
    });
  }

  public getColGroups(list: string[]): number[] {
    const groups = list.reduce(
      (acc, value) => ({
        ...acc,
        [value]: (acc[value] || 0) + 1,
      }),
      {},
    );

    return Object.values(groups);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private save(data: any[]): void {
    this.wheelCalculatorService
      .saveStocksTODB({
        userId: data[0],
        symbol: data[1],
        last_traded_price: data[2],
        option_premium: data[3],
        strike_price: data[4],
        expiration: convertToEasternTime(data[5]).unix(),
        delta: data[6],
        min: data[7],
        data: data[8],
      })
      .subscribe(
        async (res) => {
          this.wheelService.setWheelCalcData(null);
          this.wheelService.setWheelDataWindow(null);
          this.scannerService.onSelectChart$.next(true);
          this.wheelCalculatorService.setRefreshStock$(res.result);

          if (!this.observableService2.isWheelCalculator.getValue()) {
            await this.userDataService.set(UserSettings.IsWheelCalculator, true);
          }
        },
        (error) => {
          if (error.error.description === 'Your wheel-calculate limit cross.') {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = ['max-popup', 'modals'];
            dialogConfig.disableClose = true;
            this.dialog.open(MaxPopupComponent, dialogConfig);

            const modals = this.document.getElementsByClassName('modals');

            if (modals.length > 0) {
              this.document.getElementsByClassName('modals')[0].scrollTo(0, 0);
            }
          }
        },
      );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private addFlagToDataObs(data) {
    let result;
    this.scannerService.smileyTrigger$.pipe(take(1)).subscribe((smiley) => {
      result = data.map((d) => {
        const dd = smiley.find((ddd) => ddd.security_id === d.security_id);

        return { flag: dd?.flag ?? Flags.None, ...d };
      });
    });
    return result;
  }

  private showNoDataForSymbolMessage(symbolName?: string): Promise<boolean> {
    const message =
      symbolName && symbolName.length > 0
        ? `Data not found for '${symbolName}' symbol.`
        : 'Data not found for last selected symbol.';

    return this.dialogsService.customConfirm({
      showCancel: false,
      header: 'Operation failed',
      confirmationText: message,
      subText: '',
      icon: 'info',
    });
  }

  public onChangeVisibility(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { target, visible }: { target: Element; visible: boolean },
    type: 'top' | 'bottom',
  ): void {
    const dataWindow = document.getElementById('data-window-container');

    if (!dataWindow) {
      return;
    }

    const className = type === 'top' ? 'shadow-top' : 'shadow-bottom';

    if (visible) {
      dataWindow.classList.remove(className);
    } else {
      dataWindow.classList.add(className);
    }
  }
}
