import { Component, OnInit, Input } from '@angular/core';
import { IOption } from '@s/option-data.service';
import { ITradingStrategy } from '@s/trading-strategies.service';
import { Signals, formatPrice, formatStrike, formatNumber, formatDecimal, getSharesCount, getContractsCount } from '@const';
import { TradierOrderSide } from '@mod/trading-panel/trading-panel.model';
import { TradingOrderClass, TradingOrderDuration, TradingOrderType } from '@mod/trading-panel/trading-panel-order.model';
import { TradingPanelService } from '@s/trading-panel.service';

@Component({
  selector: 'app-option-record',
  templateUrl: './option-record.component.html',
  styleUrls: ['./option-record.component.scss']
})
export class OptionRecordComponent implements OnInit {
  @Input() option: IOption;
  @Input() otherOption: IOption;
  @Input() tradingStrategy: ITradingStrategy;
  @Input() isOptionGreeks: boolean = false;
  @Input() accountRiskAmount: number = NaN;

  @Input() placeOrderCanBeShown: boolean = null;  // should be recieved from cache service

  @Input() symbol: string = null;
  @Input() isPrint: boolean;

  formatPrice = formatPrice;
  formatNumber = formatNumber;
  formatDecimal = formatDecimal;
  formatStrike = formatStrike;
  getSharesCount = getSharesCount;

  get isClose() {
    const { signal } = this.option;
    if (!signal) return;
    if ([Signals.BTC, Signals.STC].includes(signal)) {
      return true;
    }
    return false;
  }

  get tradeLinkTooltip() {
    return `ATTENTION! Place a trade only after a stock triggers a position.
      \n Clicking this will open the Tradier platform and pre-fill an order.
      \n You MUST have an account with Tradier in order to use this functionality.
      \n If you want to open an account with Tradier please visit their website: <b>https://rockwelltrading.com/broker</b>`;
  }

  constructor(
    private _tradingPanelService: TradingPanelService,
  ) {
  }

  ngOnInit(): void {
  }

  getContracts(riskAmount: number) {
    return formatNumber(getContractsCount(this.accountRiskAmount, riskAmount));
  }

  getOptType(option: IOption): string {
    if (option.type === 'call') return 'C';
    if (option.type === 'put') return 'P';
    return '';
  }

  pad(price) {
    const number = String(price).split('.');
    const left = `00000${number[0]}`;
    const right = `${number[1] ? number[1] : ''}000`;
    return `${left.substring(number[0].length)}${right.substring(0, 3)}`;
  }

  onPlaceOrderClick(entry: number, strike: number, riskAmount: number) {
    const callOrPut = this.getOptType(this.option);
    const strikePrice = this.pad(strike);

    const optionSymbol = `${this.symbol}${this.option.expiration_year}${this.option.expiration_month}${this.option.expiration_day}${callOrPut}${strikePrice}`;

    const side = this.isClose
      ? TradierOrderSide.SellToClose
      : TradierOrderSide.BuyToOpen;
    const orderType = this.isClose
      ? TradingOrderType.Market
      : TradingOrderType.Limit;
    const quantity = this.isClose
      ? null
      : Math.trunc(getSharesCount(this.accountRiskAmount, riskAmount) / 100);
    const price = this.isClose
      ? null
      : entry;

    this._tradingPanelService.showOrderModal({
      option: {
        underlyingSymbol: this.symbol,
        symbol: optionSymbol,
        expirationDate: this.option.expiration_date,
        strike,
        type: this.option.type
      },
      orderClass: TradingOrderClass.Option,
      orderType,
      side,
      quantity,
      price,
      duration: TradingOrderDuration.Day,
      isQtyNote: this.isClose,
      onTradingPanelNavigateHandler: null
    });
  }
}
