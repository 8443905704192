<mat-button-toggle-group name="priceTicker" class="price-ticker" aria-label="Price Ticker" hideSingleSelectionIndicator="true">
  <div *ngIf="isLoading; then circularProgress; else lastTradedPrice"></div>
  <mat-button-toggle class="price-btn sell" value="Sell" [disableRipple]="true" [checked]="!isBuy">
    <div class="btn-content">
      <span class="btn-title">Sell</span>
      <span class="btn-value">{{bid}}</span>
    </div>
  </mat-button-toggle>
  <mat-button-toggle class="price-btn buy" value="Buy" [disableRipple]="true" [checked]="isBuy">
    <div class="btn-content">
      <span class="btn-title">Buy</span>
      <span class="btn-value">{{ask}}</span>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>

<ng-template #lastTradedPrice>
  <div *ngIf="last"
    class="price-change"
    [matTooltip]="'Last traded price'"
    [matTooltipHideDelay]="0"
  >{{last}}</div>
</ng-template>

<ng-template #circularProgress>
  <div class="spinner-container">
    <mat-spinner class="blue-spinner" diameter="25"></mat-spinner>
  </div>
</ng-template>
