import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import * as moment from 'moment/moment';

import { RestRequestorService } from '@s/rest-requestor.service';
import { Response } from '@core1/interface';
import { MomentDateTimeFormats, StorageKeys } from '@const';
import {
  ICodyBotsResponse,
  ICodyConversationResponse,
  ICodyGetMessagesListResponse,
  ICodyPostMessageResponse,
} from '@mod/data/chat-cody.model';
import { IChatAnswerFeedbackRequestPayload } from '@mod/data/chat.model';

@Injectable({
  providedIn: 'root'
})
export class ChatCodyService {
  public rockyPrompt$ = new BehaviorSubject<string | null>(null);
  private apiURL = '/v2/chatBotCody';

  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService,
  ) { }

  public async getBots() {
    const storageKey = `${StorageKeys.CodyChatBotsList}_${moment().utc().startOf('day').format(MomentDateTimeFormats.ServerDate)}`;

    return await this.restRequesterService.makeRequest(
      storageKey,
      () => firstValueFrom(this.http.get<Response<ICodyBotsResponse>>(this.apiURL + '/bots')),
    );
  }

  public createConversation(botId: string, name: string) {
    return this.http.post<Response<ICodyConversationResponse>>(
      this.apiURL + '/conversations',
      { bot_id: botId, name }
    );
  }

  public getConversationById(id: number | string) {
    return this.http.get<Response<ICodyConversationResponse>>(this.apiURL + '/conversations/' + id);
  }

  public deleteConversationById(id: number | string) {
    return this.http.delete(this.apiURL + '/conversations/' + id);
  }

  public getMessagesByConversationId(
    conversationId: number | string,
    params: Record<string, string | number> = {},
  ) {
    return this.http.get<Response<ICodyGetMessagesListResponse>>(
      this.apiURL + '/messages',
      { params: { conversation_id: conversationId, ...params } },
    );
  }

  public postMessageByConversationId(conversationId: number | string, content: string) {
    return this.http.post<Response<ICodyPostMessageResponse>>(
      this.apiURL + '/messages',
      { conversation_id: conversationId, content },
    );
  }

  public async addAnswerFeedback(payload: IChatAnswerFeedbackRequestPayload): Promise<void> {
    return firstValueFrom(this.http.post<void>(`/v2/chatbot/feedback`, payload));
  }
}
