import { FormGroup } from '@angular/forms';

import { markFormControlsTouchedHelper } from '../utility/helpers/mark-from-controls.helper';

/*
  Decorator used for base form component to auto mark fields as touched on submit when form is invalid
*/
export const SaveForm =
  () => (target: any, propertyName: string, descriptor: TypedPropertyDescriptor<(form: FormGroup) => void>) => {
    const method = descriptor.value;

  // eslint-disable-next-line space-before-function-paren
    descriptor.value = function () {
    const form: FormGroup = arguments[0];

    if (form.invalid) {
      markFormControlsTouchedHelper(form);
      return false;
    }

    return method.apply(this, arguments);
  };
};
