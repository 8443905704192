<div
  *ngIf="showMaintenance"
  class="maintenance"
>
  <div
    *ngIf="numberCrunchingStatus === jobStatus.InProgress"
    class="content timer"
  >
    <p class="description">We're busy crunching numbers</p>
    <p class="bold">Data should be available in</p>
    <app-countdown-timer></app-countdown-timer>
    <p class="description">
      Due to market volatility and the increased volume the data processing takes a little bit longer.
    </p>
  </div>
  <div
    *ngIf="numberCrunchingStatus === jobStatus.Overtime"
    class="content timer"
  >
    <p class="description">We are still busy crunching numbers.</p>
    <p class="bold">Please wait...</p>
    <img src="../../../assets/img/process-background.png" />
    <p class="description">We will update this page automatically when process finishes.</p>
  </div>
  <div
    *ngIf="numberCrunchingStatus === jobStatus.Done || numberCrunchingStatus === jobStatus.Failed"
    class="content timer"
  >
    <mat-icon
      class="refresh-data-bg"
      svgIcon="refresh-data"
    ></mat-icon>
    <p class="bold">Please refresh the page to get the updated data.</p>
    <button
      (click)="reloadPage()"
      mat-flat-button
      color="primary"
      class="fill-btn"
    >
      Refresh
    </button>
  </div>
</div>
