import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';
import { RestRequestorService } from '@s/rest-requestor.service';
import { Response } from '@core1/interface';
import { StorageKeys } from '@const';

export enum DividendsPeriodType {
  SemiAnnual = 'SemiAnnual',
  Annual = 'Annual',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  None = 'None',
  Unknown = 'Unknown',
  Other = 'Other',
}

export enum DividendsCurrency {
  USD = 'USD',
  CAD = 'CAD',
}

export interface DividendsItemResponseModel {
  security_id: number; // 12340
  symbol: string; // "SWAN"
  company_name: string; // "Amplify ETF Trust Amplify BlackSwan Growth & Treasury Core ETF"
  id: number; // 38300
  created_date: string; // "2021-08-28T23:23:22.000Z"
  date: string; // "2021-03-29T04:00:00.000Z"
  declaration_date: string; // "2021-01-18T00:00:00.000Z"
  payment_date: string; // "2021-03-31T00:00:00.000Z"
  record_date: string; // "2021-03-30T00:00:00.000Z"
  currency: DividendsCurrency; // "USD"
  period: DividendsPeriodType | null; // "Quarterly"
  unadjusted_value: number; // 0.0072
  value: number; // 0.0072
}

export interface DividendsScannerItemResponseModel {
  s_id: number; // security_id
  s: Array<{
    i_n: number; // interval_months (12, 24, 36, 48, 60)
    e_d_pra: number; // ex_date_price_reaction_avg
    b_e_adc: number; // break_evens_avg_days_count
  }>; // 5 items - for 5 years
  c_n: string; // company_name // "Agilent Technologies Inc",
  e_s: number; // earnings_share
  d_y: number; // dividend_yield
  c: number; // close
  m_d_d: string; // date, max_dividend_date // "2023-12-29T05:00:00.000Z",
  e_d_d: number; // ex_date_days
}

export interface DividendsScannerPerformanceMinifiedResponseModel {
  s_id: number; // security_id
  v: number; // value
  d: string; // date // "2020-10-01"
  p_d: string; // payment_date // "2020-10-05"
  b_e_dc: number; // break_even_days_count
  e_d_7_pr: number; // ex_date_plus_7_price_reaction
  e_d_7_prp: number; // ex_date_plus_7_price_reaction_percent
  e_d_14_pr: number; // ex_date_plus_14_price_reaction
  e_d_14_prp: number; // ex_date_plus_14_price_reaction_percent
  e_d_30_pr: number; // ex_date_plus_30_price_reaction
  e_d_30_prp: number; // ex_date_plus_30_price_reaction_percent
  e_d_pr: number; // ex_date_price_reaction
  e_d_prp: number; // ex_date_price_reaction_percent
}

export interface DividendsScannerPerformanceResponseModel {
  security_id: number;
  value: number;
  date: string;
  payment_date: string;
  break_even_days_count: number;
  ex_date_plus_7_price_reaction: number;
  ex_date_plus_7_price_reaction_percent: number;
  ex_date_plus_14_price_reaction: number;
  ex_date_plus_14_price_reaction_percent: number;
  ex_date_plus_30_price_reaction: number;
  ex_date_plus_30_price_reaction_percent: number;
  ex_date_price_reaction: number;
  ex_date_price_reaction_percent: number;
}

export interface DividendsScannerStatisticsMinifiedResponseModel {
  i_m: number; // interval_months
  s_id: number; // security_id
  t_d_c: number; // total_dividends_count
  b_e_adc: number; // break_evens_avg_days_count
  b_e_madc: number; // break_evens_max_days_count
  b_e_midc: number; // break_evens_min_days_count
  c_b_e_c: number; // closed_break_evens_count
  e_d_p7_pra: number; // ex_date_plus_7_price_reaction_avg
  e_d_p7_prma: number; // ex_date_plus_7_price_reaction_max
  e_d_p7_prmi: number; // ex_date_plus_7_price_reaction_min
  e_d_p7_prpa: number; // ex_date_plus_7_price_reaction_percent_avg
  e_d_p7_prpma: number; // ex_date_plus_7_price_reaction_percent_max
  e_d_p7_prpmi: number; // ex_date_plus_7_price_reaction_percent_min
  e_d_p14_pra: number; // ex_date_plus_14_price_reaction_avg
  e_d_p14_prma: number; // ex_date_plus_14_price_reaction_max
  e_d_p14_prmi: number; // ex_date_plus_14_price_reaction_min
  e_d_p14_prpa: number; // ex_date_plus_14_price_reaction_percent_avg
  e_d_p14_prpma: number; // ex_date_plus_14_price_reaction_percent_max
  e_d_p14_prpmi: number; // ex_date_plus_14_price_reaction_percent_min
  e_d_p30_pra: number; // ex_date_plus_30_price_reaction_avg
  e_d_p30_prma: number; // ex_date_plus_30_price_reaction_max
  e_d_p30_prmi: number; // ex_date_plus_30_price_reaction_min
  e_d_p30_prpa: number; // ex_date_plus_30_price_reaction_percent_avg
  e_d_p30_prpma: number; // ex_date_plus_30_price_reaction_percent_max
  e_d_p30_prpmi: number; // ex_date_plus_30_price_reaction_percent_min
  e_d_pra: number; // ex_date_price_reaction_avg
  e_d_prma: number; // ex_date_price_reaction_max
  e_d_prmi: number; // ex_date_price_reaction_min
  e_d_prpa: number; // ex_date_price_reaction_percent_avg
  e_d_prpma: number; // ex_date_price_reaction_percent_max
  e_d_prpmi: number; // ex_date_price_reaction_percent_min
  t_p: number; // total_profit
  roi: number; // roi
}

export interface DividendsScannerStatisticsResponseModel {
  interval_months: number;
  security_id: number;
  total_dividends_count: number;
  break_evens_avg_days_count: number;
  break_evens_max_days_count: number;
  break_evens_min_days_count: number;
  closed_break_evens_count: number;
  ex_date_plus_7_price_reaction_avg: number;
  ex_date_plus_7_price_reaction_max: number;
  ex_date_plus_7_price_reaction_min: number;
  ex_date_plus_7_price_reaction_percent_avg: number;
  ex_date_plus_7_price_reaction_percent_max: number;
  ex_date_plus_7_price_reaction_percent_min: number;
  ex_date_plus_14_price_reaction_avg: number;
  ex_date_plus_14_price_reaction_max: number;
  ex_date_plus_14_price_reaction_min: number;
  ex_date_plus_14_price_reaction_percent_avg: number;
  ex_date_plus_14_price_reaction_percent_max: number;
  ex_date_plus_14_price_reaction_percent_min: number;
  ex_date_plus_30_price_reaction_avg: number;
  ex_date_plus_30_price_reaction_max: number;
  ex_date_plus_30_price_reaction_min: number;
  ex_date_plus_30_price_reaction_percent_avg: number;
  ex_date_plus_30_price_reaction_percent_max: number;
  ex_date_plus_30_price_reaction_percent_min: number;
  ex_date_price_reaction_avg: number;
  ex_date_price_reaction_max: number;
  ex_date_price_reaction_min: number;
  ex_date_price_reaction_percent_avg: number;
  ex_date_price_reaction_percent_max: number;
  ex_date_price_reaction_percent_min: number;
  total_profit: number;
  roi: number;
}

@Injectable({
  providedIn: 'root'
})
export class DividendsService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService,
  ) { }

  get = async (securityId: number): Promise<DividendsItemResponseModel[]> => {
    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.Dividends}_${securityId}_${date}`;

    const { result } = await this.restRequesterService.makeRequest(
      storageKey,
      () => firstValueFrom(this.http.get<Response>(`/v2/dividends?security_id=${securityId}&date=${date}`))
    );

    return result;
  }

  getScannerData = async (): Promise<DividendsScannerItemResponseModel[]> => {
    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.DividendsScanner}_${date}`;

    const { result } = await this.restRequesterService.makeRequest(
      storageKey,
      () => firstValueFrom(this.http.get<Response<DividendsScannerItemResponseModel[]>>(`/v2/dividendsDetails`))
    );

    return result;
  }

  getPerformance = async (securityId: number): Promise<DividendsScannerPerformanceResponseModel[]> => {
    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.DividendsScannerPerformance}_${date}_${securityId}`;

    const { result } = await this.restRequesterService.makeRequest(
      storageKey,
      () => firstValueFrom(
        this.http.get<Response<DividendsScannerPerformanceMinifiedResponseModel[]>>(
        `/v2/dividendsDetails/performance/${securityId}`
        )
      )
    );

    if (result && result.length) {
      return result.map((item) => {
        return {
          security_id: item.s_id,
          value: item.v,
          date: item.d,
          payment_date: item.p_d,
          break_even_days_count: item.b_e_dc,
          ex_date_plus_7_price_reaction: item.e_d_7_pr,
          ex_date_plus_7_price_reaction_percent: item.e_d_7_prp,
          ex_date_plus_14_price_reaction: item.e_d_14_pr,
          ex_date_plus_14_price_reaction_percent: item.e_d_14_prp,
          ex_date_plus_30_price_reaction: item.e_d_30_pr,
          ex_date_plus_30_price_reaction_percent: item.e_d_30_prp,
          ex_date_price_reaction: item.e_d_pr,
          ex_date_price_reaction_percent: item.e_d_prp,
        };
      });
    }
  }

  getStatistics = async (securityId: number): Promise<DividendsScannerStatisticsResponseModel[]> => {
    const date = moment().utc().format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.DividendsScannerStatistics}_${date}_${securityId}`;

    const { result } = await this.restRequesterService.makeRequest(
      storageKey,
      () => firstValueFrom(
        this.http.get<Response<DividendsScannerStatisticsMinifiedResponseModel[]>>(
        `/v2/dividendsDetails/statistics/${securityId}`
        )
      )
    );

    return result.map((item) => {
      return {
        interval_months: item.i_m,
        security_id: item.s_id,
        total_dividends_count: item.t_d_c,
        break_evens_avg_days_count: item.b_e_adc,
        break_evens_max_days_count: item.b_e_madc,
        break_evens_min_days_count: item.b_e_midc,
        closed_break_evens_count: item.c_b_e_c,
        ex_date_plus_7_price_reaction_avg: item.e_d_p7_pra,
        ex_date_plus_7_price_reaction_max: item.e_d_p7_prma,
        ex_date_plus_7_price_reaction_min: item.e_d_p7_prmi,
        ex_date_plus_7_price_reaction_percent_avg: item.e_d_p7_prpa,
        ex_date_plus_7_price_reaction_percent_max: item.e_d_p7_prpma,
        ex_date_plus_7_price_reaction_percent_min: item.e_d_p7_prpmi,
        ex_date_plus_14_price_reaction_avg: item.e_d_p14_pra,
        ex_date_plus_14_price_reaction_max: item.e_d_p14_prma,
        ex_date_plus_14_price_reaction_min: item.e_d_p14_prmi,
        ex_date_plus_14_price_reaction_percent_avg: item.e_d_p14_prpa,
        ex_date_plus_14_price_reaction_percent_max: item.e_d_p14_prpma,
        ex_date_plus_14_price_reaction_percent_min: item.e_d_p14_prpmi,
        ex_date_plus_30_price_reaction_avg: item.e_d_p30_pra,
        ex_date_plus_30_price_reaction_max: item.e_d_p30_prma,
        ex_date_plus_30_price_reaction_min: item.e_d_p30_prmi,
        ex_date_plus_30_price_reaction_percent_avg: item.e_d_p30_prpa,
        ex_date_plus_30_price_reaction_percent_max: item.e_d_p30_prpma,
        ex_date_plus_30_price_reaction_percent_min: item.e_d_p30_prpmi,
        ex_date_price_reaction_avg: item.e_d_pra,
        ex_date_price_reaction_max: item.e_d_prma,
        ex_date_price_reaction_min: item.e_d_prmi,
        ex_date_price_reaction_percent_avg: item.e_d_prpa,
        ex_date_price_reaction_percent_max: item.e_d_prpma,
        ex_date_price_reaction_percent_min: item.e_d_prpmi,
        total_profit: item.t_p,
        roi: item.roi,
      };
    });
  }
}
