<section
  *ngIf="mode === 'MOBILE'"
  class="quick-links MOBILE"
>
  <button
    mat-icon-button
    mat-dialog-close
    class="popup-close-btn"
    tabindex="-1"
  >
    <mat-icon
      [svgIcon]="'close-modal-p'"
      class="close-modal-icon"
    >
    </mat-icon>
  </button>
  <div class="quick-links-heading">
    <div class="quick-links-heading-title">
      <mat-icon
        [svgIcon]="'pin-icon'"
        class="heading-icon pin-icon"
      ></mat-icon>
      <span>Links Organizer</span>
    </div>
    <button
      (click)="onAddLinkClick()"
      *ngIf="isShowHeaderAddLinkButton()"
      mat-button
      color="primary"
    >
      <mat-icon [svgIcon]="'plus_large'"></mat-icon>
      Add Link
    </button>
  </div>

  <div
    (cdkDropListDropped)="dropCommonList($event)"
    class="quick-links-container scroll-style"
    cdkDropList
  >
    @if (quickLinksWrapper(); as linksObject) {
      @for (linkObject of linksObject; track linkObject.title) {
        @if (linkObject.items.length || linkObject.settings.recordType === linkRecordType.UserLink) {
          <div
            class="quick-links-section"
            cdkDrag
            cdkDragLockAxis="y"
            cdkDragBoundary=".quick-links-container"
          >
            <mat-expansion-panel
              [expanded]="linkObject.settings.isExpanded"
              (expandedChange)="onExpandedChange(linkObject, $event)"
              class="expansion-panel"
              hideToggle
            >
              <mat-expansion-panel-header
                [collapsedHeight]="'24px'"
                [expandedHeight]="'24px'"
                cdkDragHandle
                class="expansion-panel-header"
              >
                <mat-panel-title>
                  <button
                    mat-icon-button
                    class="close-open-item-btn component-btn"
                  >
                    <span
                      [class.close-item]="false"
                      class="arrow-wrapper"
                    >
                      <div class="close-open-arrow"></div>
                    </span>
                  </button>
                  {{ linkObject.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div
                (cdkDropListDropped)="drop($event)"
                cdkDropList
                class="quick-links-section-list drag-list-section scroll-style"
              >
                @for (link of linkObject.items; track link.title) {
                  <div
                    [cdkDragDisabled]="isShowEditPane || linkObject.settings.recordType === linkRecordType.PxoLink"
                    class="quick-link-wrapper"
                    cdkDrag
                    cdkDragLockAxis="y"
                    cdkDragBoundary=".drag-list-section"
                  >
                    @if (linkObject.settings.recordType === linkRecordType.UserLink) {
                      <mat-icon
                        [svgIcon]="'drag-handler'"
                        *ngIf="!isShowEditPane"
                        cdkDragHandle
                        class="drag-handler"
                      ></mat-icon>
                    }
                    <div class="content-wrapper">
                      <ng-container *ngIf="selectedLink !== link">
                        <span
                          [class.margin-left]="
                            isShowEditPane || linkObject.settings.recordType === linkRecordType.PxoLink
                          "
                          class="quick-link-indent"
                          >{{ retrieveIndentString(link.title) }}</span
                        >
                        <span
                          [class.empty]="!link.url"
                          (click)="openLink(link.url)"
                          class="quick-link"
                        >
                          {{ link.title.trim() }}
                        </span>
                      </ng-container>

                      @if (
                        linkObject.settings.recordType === linkRecordType.UserLink &&
                        isShowEditPane &&
                        selectedLink === link
                      ) {
                        <app-links-organiser-edit-pane
                          [selectedLink]="selectedLink"
                          (addLink)="addLink($event)"
                          (updateLink)="updateLink($event, $index)"
                          (cancel)="cancelLinkEdit()"
                        ></app-links-organiser-edit-pane>
                      }

                      @if (linkObject.settings.recordType === linkRecordType.UserLink && selectedLink !== link) {
                        <div class="quick-link-actions">
                          <button
                            (click)="selectLink(link)"
                            class="quick-link-action-btn quick-link-edit"
                          >
                            <mat-icon
                              [svgIcon]="'edit_icon'"
                              class="monochrome-color-icon monochrome-color-icon-hover"
                            ></mat-icon>
                          </button>
                          <button
                            (click)="removeLink($index)"
                            class="quick-link-action-btn quick-link-remove"
                          >
                            <mat-icon
                              [svgIcon]="'trash_icon'"
                              class="monochrome-color-icon monochrome-color-icon-hover"
                            ></mat-icon>
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                }

                @if (linkObject.settings.recordType === linkRecordType.UserLink) {
                  <app-links-organiser-edit-pane
                    [selectedLink]="selectedLink"
                    (addLink)="addLink($event)"
                    (cancel)="cancelLinkEdit()"
                    *ngIf="isShowEditPane && !selectedLink"
                    id="add-container"
                  ></app-links-organiser-edit-pane>
                }

                @if (
                  linkObject.settings.recordType === linkRecordType.UserLink &&
                  !linkObject.items.length &&
                  !isShowEditPane
                ) {
                  <ng-container>
                    <div class="no-links">
                      <div class="no-links-image"></div>
                      <div class="no-links-content">
                        <p class="no-links-description">No added links for now</p>
                        <button
                          (click)="isShowEditPane = true"
                          mat-button
                          color="primary"
                        >
                          <mat-icon [svgIcon]="'plus_large'"></mat-icon>
                          Add Link
                        </button>
                      </div>
                    </div>
                  </ng-container>
                }
              </div>
            </mat-expansion-panel>
          </div>
        }
      }
    }
  </div>
</section>

<section
  *ngIf="mode === 'WEB'"
  class="quick-links WEB"
>
  <div class="quick-links-heading">
    <div class="quick-links-heading-title">
      <mat-icon
        [svgIcon]="'pin-icon'"
        class="heading-icon pin-icon"
      ></mat-icon>
      <span>Links Organizer</span>
    </div>
    <button
      (click)="onAddLinkClick()"
      *ngIf="isShowHeaderAddLinkButton()"
      mat-button
      color="primary"
    >
      <mat-icon
        [svgIcon]="'plus_large'"
        class="middle-icon"
      ></mat-icon>
      Add Link
    </button>
  </div>

  <div
    (cdkDropListDropped)="dropCommonList($event)"
    class="quick-links-container scroll-style"
    cdkDropList
  >
    @if (quickLinksWrapper(); as linksObject) {
      @for (linkObject of linksObject; track linkObject.title) {
        @if (linkObject.items.length || linkObject.settings.recordType === linkRecordType.UserLink) {
          <div
            class="quick-links-section"
            cdkDrag
            cdkDragLockAxis="y"
            cdkDragBoundary=".quick-links-container"
          >
            <mat-expansion-panel
              [expanded]="linkObject.settings.isExpanded"
              (expandedChange)="onExpandedChange(linkObject, $event)"
              class="expansion-panel"
              hideToggle
            >
              <mat-expansion-panel-header
                [collapsedHeight]="'24px'"
                [expandedHeight]="'24px'"
                cdkDragHandle
                class="expansion-panel-header"
              >
                <mat-panel-title>
                  <button
                    mat-icon-button
                    class="close-open-item-btn component-btn"
                  >
                    <span
                      [class.close-item]="false"
                      class="arrow-wrapper"
                    >
                      <div class="close-open-arrow"></div>
                    </span>
                  </button>
                  {{ linkObject.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div
                (cdkDropListDropped)="drop($event)"
                cdkDropList
                class="quick-links-section-list drag-list-section scroll-style"
              >
                @for (link of linkObject.items; track link.title) {
                  <div
                    [cdkDragDisabled]="isShowEditPane || linkObject.settings.recordType === linkRecordType.PxoLink"
                    class="quick-link-wrapper"
                    cdkDrag
                    cdkDragLockAxis="y"
                    cdkDragBoundary=".drag-list-section"
                  >
                    @if (linkObject.settings.recordType === linkRecordType.UserLink) {
                      <mat-icon
                        [svgIcon]="'drag-handler'"
                        *ngIf="!isShowEditPane"
                        cdkDragHandle
                        class="drag-handler"
                      ></mat-icon>
                    }
                    <div class="content-wrapper">
                      <ng-container *ngIf="selectedLink !== link">
                        <span
                          [class.margin-left]="
                            isShowEditPane || linkObject.settings.recordType === linkRecordType.PxoLink
                          "
                          class="quick-link-indent"
                          >{{ retrieveIndentString(link.title) }}</span
                        >
                        <span
                          [class.empty]="!link.url"
                          (click)="openLink(link.url)"
                          class="quick-link"
                        >
                          {{ link.title.trim() }}
                        </span>
                      </ng-container>

                      @if (
                        linkObject.settings.recordType === linkRecordType.UserLink &&
                        isShowEditPane &&
                        selectedLink === link
                      ) {
                        <app-links-organiser-edit-pane
                          [selectedLink]="selectedLink"
                          (addLink)="addLink($event)"
                          (updateLink)="updateLink($event, $index)"
                          (cancel)="cancelLinkEdit()"
                        ></app-links-organiser-edit-pane>
                      }

                      @if (linkObject.settings.recordType === linkRecordType.UserLink && selectedLink !== link) {
                        <div class="quick-link-actions">
                          <button
                            (click)="selectLink(link)"
                            class="quick-link-action-btn quick-link-edit"
                          >
                            <mat-icon
                              [svgIcon]="'edit_icon'"
                              class="monochrome-color-icon monochrome-color-icon-hover"
                            ></mat-icon>
                          </button>
                          <button
                            (click)="removeLink($index)"
                            class="quick-link-action-btn quick-link-remove"
                          >
                            <mat-icon
                              [svgIcon]="'trash_icon'"
                              class="monochrome-color-icon monochrome-color-icon-hover"
                            ></mat-icon>
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                }

                @if (linkObject.settings.recordType === linkRecordType.UserLink) {
                  <app-links-organiser-edit-pane
                    [selectedLink]="selectedLink"
                    (addLink)="addLink($event)"
                    (cancel)="cancelLinkEdit()"
                    *ngIf="isShowEditPane && !selectedLink"
                    id="add-container"
                  ></app-links-organiser-edit-pane>
                }

                @if (
                  linkObject.settings.recordType === linkRecordType.UserLink &&
                  !linkObject.items.length &&
                  !isShowEditPane
                ) {
                  <ng-container>
                    <div class="no-links">
                      <div class="no-links-image"></div>
                      <div class="no-links-content">
                        <p class="no-links-description">No added links for now</p>
                        <button
                          (click)="isShowEditPane = true"
                          mat-button
                          color="primary"
                        >
                          <mat-icon [svgIcon]="'plus_large'"></mat-icon>
                          Add Link
                        </button>
                      </div>
                    </div>
                  </ng-container>
                }
              </div>
            </mat-expansion-panel>
          </div>
        }
      }
    }
  </div>
</section>
