<table>
  <thead>
  <tr>
    <th class="text-center">#</th>
    <th>
      <div class="start-end-date-name">
        <p>Entry Date</p>
      </div>
    </th>
    <th class="compare-profit-th">
      <div class="profit-center">
        {{firstTradingStrategy.name + ' profit'}}
      </div>
    </th>
    <th class="compare-profit-th">
      <div class="profit-center">
        {{secondTradingStrategy.name + ' profit'}}
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let key of compareProfitsEntry; let i = index">
    <td class="text-center">{{i+1}}</td>
    <td>
      <div class="start-end-date-value">
        <p>{{key}}</p>
      </div>
    </td>
    <td>
      <div *ngIf="firstStrategyTrades[key]?.total_profit != null"
           class="profit-bar" [class]="firstStrategyTrades[key].display_total_profit_class">
        <p>{{firstStrategyTrades[key].display_total_profit}}</p>
        <div class="progress-space">
          <div class="profit-progress">
            <div class="profit-line" [style.width.%]="firstStrategyTrades[key].display_total_profit_progress"></div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div *ngIf="secondStrategyTrades[key]?.total_profit != null"
           class="profit-bar" [class]="secondStrategyTrades[key].display_total_profit_class">
        <p>{{secondStrategyTrades[key].display_total_profit}}</p>
        <div class="progress-space">
          <div class="profit-progress">
            <div class="profit-line" [style.width.%]="secondStrategyTrades[key].display_total_profit_progress"></div>
          </div>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td></td>
    <td></td>
    <td *ngFor="let value of totalProfits">
      <div *ngIf="showTotalProfit"
        class="profit-bar {{value && value[0] === '-' ? value.length === 1 ? '' : ColorClasses.Red : ColorClasses.Green}}">
        <span class="total-profit-text">TOTAL: </span>
        <p><b>{{value}}</b></p>
        <div class="progress-space"></div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
