<app-simple-modal-header titleText="Compare Risks/Rewards" (close)="closeModalNoChange()"></app-simple-modal-header>

<mat-dialog-content class="mat-typography position-compare-dialog-content">
  <div class="position-table">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th id="pin" class="pin-on-screen" mat-header-cell *matHeaderCellDef>Pin on Screen</th>
        <td mat-cell class="pin-on-screen" *matCellDef="let element">
          <button mat-icon-button class="pin-button" (click)="selectionChange(element)">
            <mat-icon [svgIcon]="'pin-icon'" [class]="selection.isSelected(element) ? 'pinned-icon' : 'unpinned-icon'"></mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th id="riskReward" class="risk-reward-column" mat-header-cell *matHeaderCellDef>Risk/<br />Reward</th>
        <td mat-cell class="risk-reward-column" *matCellDef="let element">
          <p class="mb4">{{ formatDecimal(element.risk_ratio, 1) }} / {{ formatDecimal(element.reward_ratio, 1) }}</p>
          <div class="text-78">{{ element.strategy_name }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="signal">
        <th id="signal" class="signal" mat-header-cell *matHeaderCellDef>Signal</th>
        <td
          mat-cell
          class="signal"
          *matCellDef="let element"
          [class]="
            element.current_signal.substring(0, 3) == 'BUY' ? 'greenText' : element.current_signal.substring(0, 3) == 'SEL' ? 'redText' : ''
          "
        >
          {{ element.current_signal }}
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th id="position" class="position" mat-header-cell *matHeaderCellDef>Position</th>
        <td
          mat-cell
          class="position"
          *matCellDef="let element"
          [ngClass]="
            element.display_position !== 'NONE' ? (element.display_position.substring(0, 4) === 'LONG' ? 'greenUp' : 'redDown') : ''
          "
        >
          <div>{{ element.display_position }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="shares">
        <th id="share" class="shares" mat-header-cell *matHeaderCellDef>Shares/<br />Buying Power</th>
        <td mat-cell class="shares" *matCellDef="let element">
          <p class="mb4">{{ element.display_shares }}</p>
          <div class="text-78" *ngIf="element.display_buying_power">{{ element.display_buying_power }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="stopLoss">
        <th id="stopLoss" class="stop-loss" mat-header-cell *matHeaderCellDef>Stop Loss</th>
        <td
          mat-cell
          class="stop-loss"
          *matCellDef="let element"
          [matTooltip]="element.display_stop_loss_description"
          [matTooltipHideDelay]="0"
          [matTooltipDisabled]="element.display_stop_loss_description === ''"
        >
          {{ element.display_stop_loss }}
        </td>
      </ng-container>

      <ng-container matColumnDef="profitTarget">
        <th id="profitTarget" class="profit-target divider-right-border" mat-header-cell *matHeaderCellDef>Profit Target</th>
        <td
          mat-cell
          class="profit-target divider-right-border"
          *matCellDef="let element"
          [matTooltip]="element.display_target_profit_description"
          [matTooltipHideDelay]="0"
          [matTooltipDisabled]="element.display_target_profit_description === ''"
        >
          {{ element.display_target_profit }}
        </td>
      </ng-container>

      <ng-container matColumnDef="profitAndLoss">
        <th class="profit-and-loss" mat-header-cell *matHeaderCellDef>Profit & Loss</th>
        <td mat-cell class="profit-and-loss" *matCellDef="let element">
          <app-profit-and-loss-chart [profits$]="element.profits$"></app-profit-and-loss-chart>
        </td>
      </ng-container>

      <ng-container matColumnDef="total_profit">
        <th mat-header-cell class="total-profit" *matHeaderCellDef>Total Profit</th>
        <td mat-cell class="total-profit" *matCellDef="let element">
          <ng-container
            [ngTemplateOutlet]="progressOnText"
            [ngTemplateOutletContext]="{
              content: element.display_total_profit,
              width: progressWidth(element[totalProfitKey], maxTotalProfit),
              background: getProgressColor(element[totalProfitKey])
            }"
          >
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="roi">
        <th mat-header-cell class="roi" *matHeaderCellDef>ROI</th>
        <td mat-cell class="roi" *matCellDef="let element">
          <ng-container
            [ngTemplateOutlet]="progressOnText"
            [ngTemplateOutletContext]="{
              content: element.display_roi,
              width: progressWidth(element[roiKey], maxRoi),
              background: getProgressColor(element[roiKey])
            }"
          >
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="lossesWins">
        <th mat-header-cell class="wins-losses" *matHeaderCellDef>Wins/Losses</th>
        <td mat-cell class="wins-losses" *matCellDef="let element">
          <div class="gap" *ngIf="element.display_wins === null && element.display_losses === null; else loseWinBar">-</div>
          <ng-template #loseWinBar>
            <div class="lose-win-bar">
              <div class="win" [style.width.%]="(element.wins * 100) / (element.losses + element.wins)">
                {{ element.display_wins }}
              </div>
              <div class="lose" [style.width.%]="(element.losses * 100) / (element.losses + element.wins)">
                {{ element.display_losses }}
              </div>
            </div>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="winPercent">
        <th mat-header-cell class="win-percent" *matHeaderCellDef>Win%</th>
        <td mat-cell class="win-percent" *matCellDef="let element">
          <ng-container
            [ngTemplateOutlet]="progressOnText"
            [ngTemplateOutletContext]="{
              content: element.display_wins_percent,
              width: progressWidth(element[winPercentKey], maxWinPercent),
              background: getProgressColor(element[winPercentKey])
            }"
          >
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="profitFactor">
        <th mat-header-cell class="profit-factor" *matHeaderCellDef>Profit Factor</th>
        <td mat-cell class="profit-factor" *matCellDef="let element">
          <ng-container
            [ngTemplateOutlet]="progressOnText"
            [ngTemplateOutletContext]="{
              content: element.display_profit_factor,
              width: progressWidth(element[profitFactorKey], maxProfitFactor),
              background: 'var(--light-bg-13)'
            }"
          >
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="longest">
        <th mat-header-cell class="longest" *matHeaderCellDef>Avg & Longest Trade Length</th>
        <td mat-cell class="longest" *matCellDef="let element">
          <div
            class="gap"
            *ngIf="element.display_avg_trade_days === null && element.display_longest_trade_days === null; else dateProgress"
          >
            -
          </div>
          <ng-template #dateProgress>
            <div class="date-progress" [style.width.%]="(element.longest_trade_days * 100) / maxLongestTrade">
              <div class="date-value">
                <p>{{ element.display_avg_trade_days }}d</p>
                <p>{{ element.display_longest_trade_days }}d</p>
              </div>
              <div class="pogress-value" [style.width.%]="(element.avg_trade_days * 100) / element.longest_trade_days"></div>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="pre-header-signal">
        <th mat-header-cell *matHeaderCellDef colspan="6" class="divider-right-border">Signal</th>
      </ng-container>

      <ng-container matColumnDef="pre-header-perfomance">
        <th mat-header-cell *matHeaderCellDef colspan="7">Past Performance</th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky" class="hidden-header"></tr>
      <tr mat-header-row class="pre-header" *matHeaderRowDef="['pre-header-signal', 'pre-header-perfomance']; sticky: true"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="tableHeaderRow" #tableHeaderRow></tr>
      <tr
        mat-row
        class="positions-table-row"
        *matRowDef="let row; columns: displayedColumns"
        (change)="selectionChange(row)"
        [ngClass]="{ highlighted: row.highlighted }"
      ></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-flat-button color="primary" class="small-btn" (click)="closeModal()">Set</button>
</mat-dialog-actions>

<ng-template let-content="content" let-width="width" let-background="background" #progressOnText>
  <div class="gap" *ngIf="content === null; else progress">-</div>
  <ng-template #progress>
    <div class="text-progress">
      <div [style.background]="background" [style.width.%]="width">
        {{ content }}
      </div>
    </div>
  </ng-template>
</ng-template>
