import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { EasternTimeZoneName, OptionValueType, Positions, Signals, StorageKeys } from '../constants';
import { Response } from '../core/interface/response';
import { HistoricalDataService } from './historical-data.service';
import { LocalStorageService } from './local-storage.service';
import { RestRequestorService } from './rest-requestor.service';

interface IOptionVariant {
  ask: number;
  bid: number;
  contracts: number;
  created_date: Date;
  date: Date;
  delta: number;
  dte: number;
  expiration_date: string;
  expiration_year: string;
  expiration_month: string;
  expiration_day: string;
  has_enough_data: 0 | 1;
  id: number;
  max_entry_price: number;
  option_at_stop: number;
  option_at_target: number;
  position: Positions;
  reward_amount: number;
  reward_ratio: number;
  risk_amount: number;
  signal: Signals;
  signal_id: number;
  strike: number;
  theta: number;
  type: 'call';
  value_type: 'atm' | 'itm';
}

export interface IOption {
  atm?: IOptionVariant;
  itm?: IOptionVariant;
  dte: number;
  expiration_date: string;
  expiration_year: string;
  expiration_month: string;
  expiration_day: string;
  has_enough_data: 0 | 1;
  is_frozen: boolean;
  type: 'call' | 'put';
  signal: Signals;
  position: Positions;
}

@Injectable({
  providedIn: 'root',
})
export class OptionDataService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private historicalDataService: HistoricalDataService,
    private restRequestorService: RestRequestorService
  ) {
  }

  get: (security_id: number, strategy_id: number) => Promise<IOption> = async (security_id, strategy_id) => {
    const historicalData = await this.historicalDataService.get(security_id);
    if (!historicalData.length) {
      return null;
    }
    const date = moment(historicalData[historicalData.length - 1].date)
      .tz(EasternTimeZoneName)
      .format('YYYY-MM-DD');
    const storageKey = `${StorageKeys.OptionData}_${security_id}_${strategy_id}_${date}`;
    let optionData: IOption = this.localStorageService.getFromMemory(storageKey);

    if (!optionData) {
      const { result } = await this.restRequestorService.makeRequest(storageKey, () =>
        this.http
          .get<Response<IOptionVariant[]>>(`/v2/optionData?security_id=${security_id}&strategy_id=${strategy_id}&date=${date}`)
          .toPromise()
      );

      const has_enough_data = result.length ? result[0].has_enough_data : 0;

      const itm = result.find((r) => r.value_type === OptionValueType.ITM);
      const atm = result.find((r) => r.value_type === OptionValueType.ATM);
      const hasData = !!(itm || atm);

      optionData = {
        has_enough_data,
        signal: itm?.signal || atm?.signal || null,
        type: hasData ? (itm || atm).type : null,
        is_frozen: hasData ? !!(itm || atm).position : null,
        expiration_date: hasData ? (itm || atm).expiration_date : null,
        expiration_year: hasData ? (itm || atm).expiration_year : null,
        expiration_month: hasData ? (itm || atm).expiration_month : null,
        expiration_day: hasData ? (itm || atm).expiration_day : null,
        dte: hasData
          ? moment.tz((itm || atm).expiration_date, 'MMM DD, YYYY', EasternTimeZoneName).diff(moment.tz(EasternTimeZoneName), 'days') + 1
          : null,
        itm,
        atm,
        position: itm?.position || atm?.position || null,
      };

      this.localStorageService.setToMemory(storageKey, optionData);
    }

    return optionData;
  };
}
