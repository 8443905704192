export interface ITradingPlan {
  id: string;
  mode: PlanModes;
  title: string;
  order: number;
  createdDate: number;
  updatedDate: number | null;
  sections: ITradingPlanSection[];
}

export interface ITradingPlanSection {
  id: string;
  title: string;
  isExpanded: boolean;
  order: number;
  content: string;
}

export enum PlanModes {
  Read = 0,
  Write = 1,
}

export enum SaveStatuses {
  Success = 0,
  InProgress = 1,
  Error = 2,
}

export interface ITradingPlanSectionTemplate extends Omit<ITradingPlanSection, 'id' | 'isExpanded'> {}

export interface ITradingPlanTemplate extends Pick<ITradingPlan, 'title'> {
  // section content: HTML with the next tags: "p", "ul", "li", "strong" (bold), "em" (italic), "u" (underline)
  // for empty line: "<p></p>"
  sections: ITradingPlanSectionTemplate[];
  // keys from "this.observableService.mySettings" object (IMySettings)
  requiredAccess: null | string[];
}
