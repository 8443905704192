import {
  IWtfScannerFilter,
  Wtf200MAOptionsWtf200MAOptions,
  WtfSymbolsOptions,
} from '@c/wtf-content/wtf-scanner-filter/wtf-scanner-filter.model';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';

export const EMIT_WTF_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;
export const DEFAULT_IS_FILTERS_HIDDEN = false;

export const DEFAULT_WTF_FILTERS_STATE: IWtfScannerFilter = {
  flags: [Flags.No, Flags.Maybe, Flags.Yes, Flags.Never, Flags.None],
  symbolOptions: [WtfSymbolsOptions.SP100, WtfSymbolsOptions.Nasdaq],
  stockPriceFrom: '5.00',
  stockPriceTo: '250.00',
  ma200: Wtf200MAOptionsWtf200MAOptions.above200MADay,
  profitFactorFrom: '',
  profitFactorTo: '',
  winPercentFrom: '',
  winPercentTo: '',
  volumeFrom: {
    filterValue: '',
    visibleValue: '',
  },
  volumeTo: {
    filterValue: '',
    visibleValue: '',
  },
  tradesCountFrom: '',
  tradesCountTo: '',
};

export const RECOMMENDED_WTF_FILTERS_STATE: IWtfScannerFilter = {
  flags: [Flags.No, Flags.Maybe, Flags.Yes, Flags.Never, Flags.None],
  symbolOptions: [WtfSymbolsOptions.SP100, WtfSymbolsOptions.Nasdaq],
  stockPriceFrom: '5.00',
  stockPriceTo: '250.00',
  ma200: Wtf200MAOptionsWtf200MAOptions.above200MADay,
  profitFactorFrom: '',
  profitFactorTo: '',
  winPercentFrom: '',
  winPercentTo: '',
  volumeFrom: {
    filterValue: '',
    visibleValue: '',
  },
  volumeTo: {
    filterValue: '',
    visibleValue: '',
  },
  tradesCountFrom: '',
  tradesCountTo: '',
};
