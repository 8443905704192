import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth';
import { ErrorInterceptor } from './error';
import { HttpsInterceptor } from './https';
import { LoaderInterceptor } from './loader';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
];
