import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-video-player-placeholder',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './video-player-placeholder.component.html',
  styleUrl: './video-player-placeholder.component.scss',
  animations: [
    trigger('enterLeaveAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms ease-in-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class VideoPlayerPlaceholderComponent { }
