import { Component, HostListener, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UserSettingsService } from '@s/user-settings.service';
import { FormBaseComponent } from '@c/shared/forms/form-base.component';
import { ChangePasswordFormModel } from './change-password-form.model';
import { getGroup } from '@u/forms/form-generics';
import { mustMatch } from '@u/validators/must-match.validator';
import { mustNotMatch } from '@u/validators/must-not-match.validator';
import { ChangePasswordLabels, CurrentPasswordErrors } from '@const';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent extends FormBaseComponent<ChangePasswordFormModel> implements OnInit {
  public passwordsStates = {
    currentPassword: { isHidden: true, showError: false },
    newPassword: { isHidden: true, showError: false },
    confirmNewPassword: { isHidden: true, showError: false },
  };
  public passwordFields = Object.keys(this.passwordsStates);
  public passwordInputType = 'password';
  public isLoading = false;
  public isChangePasswordVisible = false;
  public isOperationFailed = false;
  public operationStatus = {
    showStatus: false,
    isFailed: false
  };
  public changePasswordLabels = ChangePasswordLabels;
  public changePasswordErrors = {
    currentPassword: 'Enter your current password',
    newPassword: 'Minimum 6 characters required',
    confirmNewPassword: 'Passwords must match'
  };

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.isChangePasswordVisible) {
      event.preventDefault();
      this.changePassword();
    }
  }
  constructor(private userSettingsService: UserSettingsService
  ) {
    super(
      getGroup<ChangePasswordFormModel>(
        {
          currentPassword: { vldtr: Validators.required },
          newPassword: { vldtr: [Validators.required, Validators.minLength(6)] },
          confirmNewPassword: { vldtr: Validators.required }
        },
        [
          mustMatch('newPassword', 'confirmNewPassword', false),
          mustNotMatch('currentPassword', 'newPassword', true)
        ],
      )
    );
  }

  ngOnInit(): void {
  }

  async changePassword(): Promise<void> {
    this.isLoading = true;
    this.changePasswordErrors.currentPassword = CurrentPasswordErrors.Required;
    this.operationStatus.showStatus = false;
    this.operationStatus.isFailed = false;
    if (!this.value) {
      this.checkIsErrorsExist();
      this.isLoading = false;
      return;
    }
    const changePasswordResult = await this.userSettingsService.changePassword(this.form.value);
    this.operationStatus.showStatus = true;
    if (changePasswordResult?.type && changePasswordResult?.description !== CurrentPasswordErrors.DoesNotMatch) {
      this.operationStatus.isFailed = true;
    } else if (changePasswordResult?.description === CurrentPasswordErrors.DoesNotMatch) {
      this.showInvalidErrorForCurrentPassword();
      this.isLoading = false;
      return;
    } else {
      this.isChangePasswordVisible = false;
    }
    this.form.reset();
    this.setErrorAndEyeVisibility(true);
    this.isLoading = false;
  }

  showInvalidErrorForCurrentPassword(): void {
    this.changePasswordErrors.currentPassword = CurrentPasswordErrors.IsInvalid;
    this.passwordsStates.currentPassword.showError = true;
    this.operationStatus.showStatus = false;
  }

  hideError(field): void {
    this.passwordsStates[field].showError = false;
    this.operationStatus.showStatus = false;
  }

  setErrorAndEyeVisibility(isResetHidden = false): void {
    Object.values(this.passwordsStates).forEach((value) => {
      value.showError = false;
      value.isHidden = isResetHidden ? true : value.isHidden;
    });
  }

  changePasswordFieldsVisibility(isVisible): void {
    this.isChangePasswordVisible = isVisible;
    this.form.reset();
    this.operationStatus.showStatus = false;
    this.setErrorAndEyeVisibility(true);
  }

  changePasswordValueVisibility(passwordField): void {
    passwordField.isHidden = !passwordField.isHidden;
  }

  checkIsErrorsExist(): void {
    if (this.isError('newPassword', 'mustNotMatch')
      && !this.form.controls.currentPassword.errors
      && !this.form.controls.confirmNewPassword.errors) {
      this.isChangePasswordVisible = false;
      this.form.reset();
      this.setErrorAndEyeVisibility(true);
      return;
    }
    this.passwordsStates.currentPassword.showError = this.isError('currentPassword', 'required');
    this.passwordsStates.newPassword.showError =
      this.isError('newPassword', 'minlength')
      || this.isError('newPassword', 'required');
    this.passwordsStates.confirmNewPassword.showError =
      this.isError('confirmNewPassword', 'mustMatch') || this.isError('confirmNewPassword', 'required');
  }

}
