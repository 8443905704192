<ng-template #spinnerContainer>
  <div class="spinner-container">
    <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
  </div>
</ng-template>

<div class="portfolio" *ngIf="!isGroupsLoading && !isAccountsStrategiesLoading && !isSettingsLoading; else: spinnerContainer">
  <div class="portfolio-controls">
    <!--  it's only for sort-header, no table-body  -->
    <div class="table-sort-header">
      <table
        class="wheel-conservative-aggressive-table table-sort-header"
        mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="sortState.column"
        [matSortDirection]="sortState.direction"
        (matSortChange)="onSortChange($event)"
      >
        <ng-container matColumnDef="symbol">
          <th class="table-sort-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Symbol
          </th>
          <mat-cell mat-cell *matCellDef="let element; let i = index">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="company">
          <th class="table-sort-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Company
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['symbol', 'company']" class="table-header-row"></tr>
      </table>
    </div>

    <div class="portfolio-controls-part">
      <app-portfolio-filters-form
        [filtersState]="filtersState"
        (filtersChanged)="onChangeFilters($event)"
      ></app-portfolio-filters-form>

      <div class="portfolio-control text-right">
        <mat-button-toggle-group
          hideSingleSelectionIndicator="true"
          class="view-switcher"
          name="switcher"
          aria-label="View Switcher"
          (change)="onChangeViewMode($event.value)"
        >
          <mat-button-toggle
            class="view-switcher-btn"
            [value]="viewModes.Groups"
            [checked]="currentViewMode === viewModes.Groups"
            [matTooltip]="'Show trades grouped by account/strategy'"
            [matTooltipPosition]="'right'"
            [matTooltipHideDelay]="0"
          >
            <mat-icon [svgIcon]="'tree-icon'" class="small-icon view-switcher-icon"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle
            class="view-switcher-btn"
            [value]="viewModes.List"
            [checked]="currentViewMode === viewModes.List"
            [matTooltip]="'Show trades grouped by symbol'"
            [matTooltipPosition]="'right'"
            [matTooltipHideDelay]="0"
          >
            <mat-icon [svgIcon]="'list-icon'" class="small-icon view-switcher-icon"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>

  <mat-accordion
    *ngIf="currentViewMode === viewModes.Groups"
    class="portfolio-accordion"
    multi
  >
    <mat-expansion-panel
      *ngFor="let group of groupedTrades; trackBy: trackByFn"
      class="portfolio-accordion-item portfolio-panel"
      [id]="'expansion-panel_' + group.id"
      [expanded]="group.viewState.isGroupExpanded"
      (expandedChange)="onGroupExpandChange(group, $event)"
      (afterExpand)="scrollToPanel(group.id)"
      hideToggle
    >
      <mat-expansion-panel-header
        collapsedHeight="40px"
        expandedHeight="40px"
        class="portfolio-header"
        tabindex="-1"
        (focusin)="$event?.target?.blur()"
      >
        <mat-panel-title tabindex="-1">
          <button mat-icon-button class="toggle-button close-open-item-btn component-btn secondary-bg">
            <span class="arrow-wrapper">
              <div class="close-open-arrow"></div>
            </span>
          </button>
          <p class="portfolio-header-title">{{ group.title }}</p>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <mat-expansion-panel
          class="additional-panel"
          [expanded]="group.viewState.isStatisticExpanded"
          (expandedChange)="onGroupStatisticExpandChange(group, $event)"
          hideToggle
        >
          <mat-expansion-panel-header
            class="additional-panel-header"
            tabindex="-1"
            (focusin)="$event?.target?.blur()"
          >
            <mat-panel-title class="additional-panel-header-title" tabindex="-1">
              <p class="additional-panel-header-part">
                {{ group.statistic.sectors.length }} {{ group.statistic.sectors.length === 1 ? 'sector' : 'sectors' }}
              </p>
              <p class="additional-panel-header-part">
                {{ group.statistic.industries.length }} {{ group.statistic.industries.length === 1 ? 'industry' : 'industries' }}
              </p>
              <span class="additional-panel-toggle-icon"></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="additional-panel-body scroll-style">
            <div class="additional-panel-table">
              <table>
                <tbody>
                  <tr *ngFor="let sectorsStatisticItem of group.statistic.sectors; trackBy: trackByFn">
                    <td>
                      <div class="additional-panel-item-title">{{ sectorsStatisticItem.title }}</div>
                      <div
                        class="additional-panel-item-progressbar hide-on-min-500"
                        [ngStyle]="{ '--additional-item-width': + sectorsStatisticItem.percentage + '%', '--additional-item-color' : sectorsStatisticItem.color }"
                      ></div>
                    </td>
                    <td>
                      <div
                        class="additional-panel-item-progressbar hide-on-max-500"
                        [ngStyle]="{ '--additional-item-width': + sectorsStatisticItem.percentage + '%', '--additional-item-color' : sectorsStatisticItem.color }"
                      ></div>
                    </td>
                    <td class="counter">
                      <div class="additional-panel-item-counter">{{ sectorsStatisticItem.total }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="additional-panel-table">
              <table>
                <tbody>
                  <tr *ngFor="let industryStatisticItem of group.statistic.industries; trackBy: trackByFn">
                    <td>
                      <div class="additional-panel-item-title">{{ industryStatisticItem.title }}</div>
                      <div
                        class="additional-panel-item-progressbar hide-on-min-500"
                        [ngStyle]="{ '--additional-item-width': + industryStatisticItem.percentage + '%', '--additional-item-color' : industryStatisticItem.color }"
                      ></div>
                    </td>
                    <td>
                      <div
                        class="additional-panel-item-progressbar hide-on-max-500"
                        [ngStyle]="{ '--additional-item-width': + industryStatisticItem.percentage + '%', '--additional-item-color' : industryStatisticItem.color }"
                      ></div>
                    </td>
                    <td class="counter">
                      <div class="additional-panel-item-counter">{{ industryStatisticItem.total }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-expansion-panel>

        <div class="portfolio-item-symbols">
          <table>
            <tbody>
              <tr
                class="symbol-row"
                *ngFor="let symbol of group.items; trackBy: trackByFn"
                [id]="'symbol-tr_' + symbol.id"
                [ngClass]="{ 'selected-row': symbol.id === selectedRow?.id }"
                (click)="onSelectSymbol(symbol)"
              >
                <td>
                  <p class="cell-inner symbol-title">{{ symbol.symbol ?? '-' }}</p>
                </td>
                <td>
                  <p class="cell-inner symbol-description">{{ symbol.company ?? '-' }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <div
    *ngIf="currentViewMode === viewModes.List && groupedTrades[0]"
    class="portfolio-accordion-item portfolio-panel"
  >
    <!--  TODO: use separate component for portfolio-panel (group content) -->
    <mat-expansion-panel
      class="additional-panel"
      [expanded]="groupedTrades[0].viewState.isStatisticExpanded"
      (expandedChange)="onGroupStatisticExpandChange(groupedTrades[0], $event)"
      hideToggle
    >
      <mat-expansion-panel-header class="additional-panel-header">
        <mat-panel-title class="additional-panel-header-title">
          <p class="additional-panel-header-part">
            {{ groupedTrades[0].statistic.sectors.length }} {{ groupedTrades[0].statistic.sectors.length === 1 ? 'sector' : 'sectors' }}
          </p>
          <p class="additional-panel-header-part">
            {{ groupedTrades[0].statistic.industries.length }} {{ groupedTrades[0].statistic.industries.length === 1 ? 'industry' : 'industries' }}
          </p>
          <span class="additional-panel-toggle-icon"></span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="additional-panel-body scroll-style">
        <div class="additional-panel-table">
          <table>
            <tbody>
            <tr *ngFor="let sectorsStatisticItem of groupedTrades[0].statistic.sectors; trackBy: trackByFn">
              <td>
                <div class="additional-panel-item-title">{{ sectorsStatisticItem.title }}</div>
                <div
                  class="additional-panel-item-progressbar hide-on-min-500"
                  [ngStyle]="{ '--additional-item-width': + sectorsStatisticItem.percentage + '%', '--additional-item-color' : sectorsStatisticItem.color }"
                ></div>
              </td>
              <td>
                <div
                  class="additional-panel-item-progressbar hide-on-max-500"
                  [ngStyle]="{ '--additional-item-width': + sectorsStatisticItem.percentage + '%', '--additional-item-color' : sectorsStatisticItem.color }"
                ></div>
              </td>
              <td class="counter">
                <div class="additional-panel-item-counter">{{ sectorsStatisticItem.total }}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="additional-panel-table">
          <table>
            <tbody>
            <tr *ngFor="let industryStatisticItem of groupedTrades[0].statistic.industries; trackBy: trackByFn">
              <td>
                <div class="additional-panel-item-title">{{ industryStatisticItem.title }}</div>
                <div
                  class="additional-panel-item-progressbar hide-on-min-500"
                  [ngStyle]="{ '--additional-item-width': + industryStatisticItem.percentage + '%', '--additional-item-color' : industryStatisticItem.color }"
                ></div>
              </td>
              <td>
                <div
                  class="additional-panel-item-progressbar hide-on-max-500"
                  [ngStyle]="{ '--additional-item-width': + industryStatisticItem.percentage + '%', '--additional-item-color' : industryStatisticItem.color }"
                ></div>
              </td>
              <td class="counter">
                <div class="additional-panel-item-counter">{{ industryStatisticItem.total }}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-expansion-panel>

    <div class="portfolio-item-symbols">
      <table>
        <tbody>
          <tr
            class="symbol-row"
            *ngFor="let symbol of groupedTrades[0].items; trackBy: trackByFn"
            [id]="'symbol-tr_' + symbol.id"
            [ngClass]="{ 'selected-row': symbol.id === selectedRow?.id }"
            (click)="onSelectSymbol(symbol)"
          >
            <td>
              <p class="cell-inner symbol-title">{{ symbol.symbol ?? '-' }}</p>
            </td>
            <td>
              <p class="cell-inner symbol-description">{{ symbol.company ?? '-' }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
