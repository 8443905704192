export const entryIndicatorsOptions = {
  Show: 'Show',
  None: 'None'
} as const;

export const dividendsStrategyShowProfitLossOptions = {
  Show: 'Show',
  None: 'None'
} as const;

export const dividendsStrategyShowTradesOptions = {
  Show: 'Show',
  None: 'None'
} as const;
