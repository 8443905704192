import { TradingCommandType } from '@const';
import { TradingLogTransactionType } from '@t/trading-log';
import { Moment } from 'moment';

export enum TradingPanelClientEvent {
  ResetOrderToDefault = 'resetTradingOrderToDefault',
  OrderCreated = 'OrderCreated',
  OrderInputDataUpdated = 'OrderInputDataUpdated',
  OrderPanelPinChanging = 'OrderPanelPinChanging',
  OrderPanelClosing = 'OrderPanelClosing',
  BrokerLogout = 'BrokerLogout'
}

export interface Command {
  accessToken?: string;
  id: string;
  type: TradingCommandType;
  updateIntervalMs?: number;
  data?: CommandInput;
  handler?: Function;
  handleError?: boolean;
}

export interface CommandInput {
  accountNumber?: string;
  accountNickname?: string;
}

export interface CommandResult {
  id: string;
  success: boolean;
  accessToken?: string;
  description?: string;
  data?: CommandData;
}

export interface TradingSymbolDetails {
  symbol: string;
  description?: string;
  isOption: boolean;
  optionExpirationDate?: Moment
  optionType?: string;
  optionStrike?: number;
}

export enum TradierAccountStatus {
  Active = 'active'
}

export interface CommandData {
  profile?: UserProfile,
  positions?: TradierPositions,
  orders?: TradierOrders,
  history?: AccountHistoryEvents,
}

export interface UserProfile {
  account: Array<TradierAccount>;
  id: string;
  name: string;
}

export interface TradierAccount {
  account_number: string;
  classification: string;
  date_created: string;
  day_trader: boolean;
  option_level: number;
  status: TradierAccountStatus;
  type: string;
  last_update_date: string;
  nickname: string;
  display_name: string;
}

export interface TradierPositions {
  position: Array<TradierPosition>;
}

export interface TradierPosition {
  cost_basis: number;
  date_acquired: string;
  id: number;
  quantity: number;
  symbol: string;
  last_price?: number;
}

export interface PositionViewModel {
  symbol: string;
  description?: string;
  side: string;
  sideClass: string;
  quantity: number;
  avgFillPrice: number;
  avgFillPriceFormatted: string;
  lastPrice?: number;
  lastPriceFormatted: string;
  profit?: number;
  profitFormatted: string;
  profitClass: string;
}

export enum TradierOrderType {
  Market = 'market',
  Limit = 'limit',
  Stop = 'stop',
  StopLimit = 'stop_limit',
  Debit = 'debit',
  Credit = 'credit',
  Even = 'even'
}

export enum TradierOrderSide {
  Buy = 'buy',
  BuyToCover = 'buy_to_cover',
  Sell = 'sell',
  SellShort = 'sell_short',
  BuyToOpen = 'buy_to_open',
  BuyToClose = 'buy_to_close',
  SellToOpen = 'sell_to_open',
  SellToClose = 'sell_to_close'
}

export enum TradierOrderStatus {
  Open = 'open',
  PartiallyFilled = 'partially_filled',
  Filled = 'filled',
  Expired = 'expired',
  Canceled = 'canceled',
  Pending = 'pending',
  Rejected = 'rejected',
  Error = 'error'
}

export enum TradierOrderDuration {
  Day = 'day',
  Pre = 'pre',
  Post = 'post',
  Gtc = 'gtc'
}

export enum TradierOrderClass {
  Equity = 'equity',
  Option = 'option',
  Combo = 'combo',
  Multileg = 'multileg'
}

export interface TradierOrders {
  order: Array<TradierOrder>;
}

export interface TradierOrder {
  id: number;
  accountNumber: string;
  type: TradierOrderType;
  symbol: string;
  side: TradierOrderSide;
  quantity: number;
  status: TradierOrderStatus;
  duration: TradierOrderDuration;
  price: number;
  avg_fill_price: number;
  exec_quantity: number;
  last_fill_price: number;
  last_fill_quantity: number;
  remaining_quantity: number;
  create_date: string;
  transaction_date: string;
  class: TradierOrderClass;
  option_symbol?: string;
  stop_price?: number;
  description?: string;
  tag?: string;
  strategy?: string;
  num_legs?: number;
  leg?: Array<TradierOrder>;
}

export interface OrderViewModel {
  id: number;
  class: TradierOrderClass;
  symbol: string;
  symbolClass: string;
  description?: string;
  note?: string;
  side: string;
  sideClass: string;
  type: string;
  filledQty: string;
  price: string;
  stopPrice: string;
  fillPrice: string;
  duration: string;
  status: string;
  statusClass: string;
  placingTime: string;
  placingTimeUnix: number;
  isLeg: boolean,
  leg?: TradierOrder[],
  canBeModified: boolean,
  canBeCanceled: boolean
}

export enum OrderFilterStatus {
  All = 'all',
  Open = 'open',
  Filled = 'filled',
  Pending = 'pending',
  Canceled = 'canceled',
  Rejected = 'rejected',
}

export interface TradingTableColumnSorting {
  column: string;
  direction: 'asc' | 'desc';
}

export interface TradierHistoryTransaction {
  symbol: string,
  type: TradingLogTransactionType,
  expiration: string,
  strike: number,
  qty: number,
  price: number,
  date: string,
  time: string,
  commission: number,
  note: string,
  import_note: string,
  imported_date: string
};

export interface AccountHistoryEvents {
  event: ReadonlyArray<TradierHistoryTransaction>
};
