import { isNullOrUndefined } from '@const';

const countDecimals = (value: number): number => {
  if (isNullOrUndefined(value)) {
      return 0;
  }

  if (Math.floor(value) === value) {
      return 0;
  }

  return value.toString().split(".")[1].length || 0;
};

const moveDecimalPointRight = (value: number, power: number): number => {
  if (!value || !power) {
      return value;
  }

  if (power < 0) {
      throw Error(`Only positive values are supported for power (${power} provided)`);
  }

  const isNegative = value < 0;
  const valueToProcess = isNegative
      ? `${value * -1}`
      : `${value}`;

  const indexOfDot = valueToProcess.indexOf('.') > -1
      ? valueToProcess.indexOf('.')
      : valueToProcess.length;
  const paddedValue = `${valueToProcess.replace('.', '')}${''.padEnd(power, '0')}`;

  const newDotIndex = indexOfDot + power;
  const result = Number(`${paddedValue.substring(0, newDotIndex)}.${paddedValue.substring(newDotIndex)}`);

  return isNegative ? (result * -1) : result;
};

const moveDecimalPointLeft = (value: number, power: number): number => {
  if (!value || !power) {
      return value;
  }

  if (power < 0) {
      throw Error(`Only positive values are supported for power (${power} provided)`);
  }

  const isNegative = value < 0;
  const valueToProcess = isNegative
      ? `${value * -1}`
      : `${value}`;

  const indexOfDot = valueToProcess.indexOf('.') > -1
      ? valueToProcess.indexOf('.')
      : valueToProcess.length;
  const paddedValue = `${''.padEnd(power, '0')}${valueToProcess.replace('.', '')}`;

  const result = Number(`${paddedValue.substring(0, indexOfDot)}.${paddedValue.substring(indexOfDot)}`);

  return isNegative ? (result * -1) : result;
};


const add = (a: number, b: number): number | null => {
  if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
    return null;
  }

  const numDecimals = countDecimals(a) >= countDecimals(b)
    ? countDecimals(a)
    : countDecimals(b);

  const result = moveDecimalPointRight(a, numDecimals) + moveDecimalPointRight(b, numDecimals);

  return moveDecimalPointLeft(result, numDecimals);
};

const subtract = (a: number, b: number): number | null => {
  return add(a, -1 * b);
};

export const money = {
  add,
  subtract
};