import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';

import { AuthService } from '@s/common/auth.service';
import { UserDataService } from '@s/user-data.service';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { LocalStorageService } from '@s/local-storage.service';
import { UserSettingsLocal } from '@const';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ConcurrentLoginService {
  private socket: any = null;

  constructor(
    private _authService: AuthService,
    private _brokerAuthenticationService: BrokerAuthenticationService,
    private _userDataService: UserDataService,
    private _localStorageService: LocalStorageService
  ) {
  }

  subscribe() {
    const token = this._userDataService.getAuthToken();
    this.socket = io(environment.WsMultiInstanceUrl, {
      transports: ['websocket'],
      auth: { token }
    });

    this.socket.on('connect', () => {
      this.socket.emit('pxo_live_concurrent_login_subscribe');
    });

    this.socket.on('pxo_live_concurrent_login_update', (data) => {
      const loginId = this._localStorageService.get(UserSettingsLocal.LoginId);

      if (!loginId || !data.find((x: string) => x === loginId)) {
        this._brokerAuthenticationService.logout();
        this._authService.logout(false, true);
      }
    });
  }

  unsubscribe() {
    this.socket.close();
  }
}
