import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GroupInfoPanelInputModel, GroupInfoPanelModel } from './models';
import { MomentDateTimeFormats, formatDecimalOrZero, formatPrice } from '@const';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-group-info-panel',
  templateUrl: './group-info-panel.component.html',
  styleUrls: ['./group-info-panel.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule]
})
export class GroupInfoPanelComponent implements OnInit {


  @Input() set data(val: GroupInfoPanelInputModel) {

    const feesAndComm = (val?.fees || 0) + (val?.commission || 0);
    const minDateDisplay = val?.minDate?.format(MomentDateTimeFormats.ReadableDate) ?? '';
    const maxDateDisplay = val?.maxDate?.format(MomentDateTimeFormats.ReadableDate) ?? '';
    this.model = {
      symbol: val.symbol,
      accountName: val?.accountName,
      strategyName: val?.strategyName,
      minDateDisplay,
      maxDateDisplay: minDateDisplay !== maxDateDisplay ? maxDateDisplay : '',
      costAvg: val?.cost_average ? (Math.round(val.cost_average) === val.cost_average ? formatPrice(val?.cost_average, 0) : formatPrice(val?.cost_average, 2)) : '-',
      costAvgClass: this.getClassByValue(val?.cost_average),
      breakEven: val?.break_even ? (Math.round(val.break_even) === val.break_even ? formatPrice(val?.break_even, 0) : formatPrice(val?.break_even, 2)) : '-',
      breakEvenClass: this.getClassByValue(val?.break_even),
      shares: val?.shares ? formatDecimalOrZero(val?.shares, 0, 6) : '0',
      sharesClass: this.getClassByValue(val?.shares),
      buyContracts: val?.buy_contracts ? formatDecimalOrZero(val?.buy_contracts, 0, 6) : '-',
      buyContractsClass: this.getClassByValue(val?.buy_contracts),
      sellContracts: val?.sell_contracts ? formatDecimalOrZero(val?.sell_contracts, 0, 6) : '-',
      sellContractsClass: this.getClassByValue(val?.sell_contracts),
      feesAndComm: feesAndComm ? formatPrice(feesAndComm, 0) : '0',
      feesAndCommClass: this.getClassByValue(feesAndComm * -1),
      premium: val?.premium ? formatPrice(val?.premium, 0) : '0',
      premiumClass: this.getClassByValue(val?.premium),
      dividends: val?.dividends ? formatPrice(val?.dividends, 0) : '0',
      dividendsClass: this.getClassByValue(val?.dividends),
      buyingPower: val?.buying_power ? formatPrice(val?.buying_power, 0) : '0',
      profit: val?.profit ? formatPrice(val?.profit, 0) : '0',
      profitClass: this.getClassByValue(val?.profit),
    };
  }

  protected model: GroupInfoPanelModel = null;

  constructor() { }

  ngOnInit() {
  }

  private getClassByValue(value: number | undefined): string {
    return !value ? '' : value > 0 ? 'profit' : 'lose';
  }

}
