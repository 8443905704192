<div class="total-profit card">
  <div class="row font-12 {{ getPastPerformanceHighlightClass('roi') }}">
    {{ ((pastPerformance && pastPerformance[getPropertyName('roi')]) === null) ? '-'  : getPastPerformanceMetric('roi', 2) + '%' }}
  </div>
  <div class="progress-bar-with-txt">
      <ng-container *ngIf="pastPerformance && pastPerformance[getPropertyName('total_profit')] === null else profit">
        -
      </ng-container>
  </div>
</div>

<ng-template #profit>
  <div class="progress-bar-parent" [style.width]="totalProfitProgressBarWidth">
    <div [class]="getPastPerformanceBackgroundClass('roi')"
      style="width: 100% !important;"></div>
  </div>
  <div #totalProfit class="txt p-l-6 font-12 {{ getPastPerformanceHighlightClass('total_profit') }}">
      {{getPastPerformanceMetricMoney('total_profit')}}
  </div>
</ng-template>
