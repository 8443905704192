<div
  class="form-group"
  #trigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  appResizeDirective
  (resize)="calculateOverlayWidth(trigger)"
>
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    color="primary"
    [class.focused]="isShowOptions"
  >
    <input
      matInput
      autocomplete="off"
      type="text"
      [maxLength]="maxLength"
      [value]="value"
      [placeholder]="placeholder"
      [class.uppercase]="isUpperCase"
      #symbolInput
      (keydown)="$event.stopPropagation()"
      (keydown.enter)="onKeydownEnterHandler($event)"
      (input)="$event.stopPropagation(); onInput($event)"
      (keyup)="$event.stopPropagation(); filterOptions($event)"
      (click)="$event.stopPropagation(); filterOptions($event)"
    />

    <mat-icon class="search-icon" matPrefix [svgIcon]="'search-tool_icon'"></mat-icon>
    <mat-icon
      *ngIf="value.length"
      matSuffix
      class="reset-input-value"
      (click)="clear()"
      [svgIcon]="'close-menu'"
    ></mat-icon>
  </mat-form-field>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isShowOptions"
  [cdkConnectedOverlayPanelClass]="'input-multi-select-overlay-pane'"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  (overlayOutsideClick)="hideOptions($event)"
  (attach)="calculateOverlayWidth(trigger)"
  (detach)="isShowOptions = false; symbolInput.blur()"
>
  <div class="dropdown">
    <div class="options-container scroll-style">
      <ng-container *ngIf="options?.length && optionsToShow.length">
        <div
          *ngFor="let option of optionsToShow"
          (click)="selectOption(option, symbolInput)"
          class="option"
        >
          {{ option }}
        </div>
      </ng-container>
      <div *ngIf="!options?.length" class="empty-options-message">
        {{ emptyOptionsDropdownMessage }}
      </div>
      <div *ngIf="options?.length && !optionsToShow.length" class="empty-options-message">
        {{ emptyFilteredOptionsDropdownMessage }}
      </div>
    </div>
  </div>
</ng-template>
