// IDs of indicator's inputs
// for recover saved inputs that were saved as array of values without id (key) ()
export const oldIndicatorInputsIDs = {
  'Bollinger Bands': ['in_0', 'in_1'],
  'Average Price': [],
  'Arnaud Legoux Moving Average': ['in_0', 'in_1', 'in_2'],
  'Accumulation/Distribution': [],
  'Accumulative Swing Index': ['in_0'],
  'Advance/Decline': ['in_0'],
  Aroon: ['in_0'],
  'Average Directional Index': ['in_0', 'in_1'],
  'Average True Range': ['in_0'],
  'Awesome Oscillator': [],
  'Balance of Power': [],
  'Bollinger Bands %B': ['in_0', 'in_1'],
  'Bollinger Bands Width': ['in_0', 'in_1'],
  'Chaikin Money Flow': ['in_0'],
  'Chande Kroll Stop': ['in_0', 'in_1', 'in_2'],
  'Chaikin Oscillator': ['in_0', 'in_1'],
  'Chaikin Volatility': ['periods', 'rocLookback'],
  'Chande Momentum Oscillator': ['in_0'],
  'Chop Zone': [],
  'Choppiness Index': ['in_0'],
  'Commodity Channel Index': ['in_0', 'smoothingLine', 'smoothingLength'],
  'Connors RSI': ['in_0', 'in_1', 'in_2'],
  'Coppock Curve': ['in_0', 'in_1', 'in_2'],
  'Correlation - Log': ['instrument', 'instrument2', 'periods'],
  'Moving Average Weighted': ['in_0', 'in_1', 'in_2'],
  'Moving Average Triple': ['firstPeriods', 'secondPeriods', 'thirdPeriods', 'method'],
  'Moving Average Multiple': ['firstPeriods', 'secondPeriods', 'thirdPeriods', 'fourthPeriods', 'fifthPeriods', 'sixthPeriods', 'method'],
  'Moving Average Hamming': ['periods'],
  'Moving Average Exponential': ['length', 'source', 'offset', 'smoothingLine', 'smoothingLength'],
  'Moving Average Double': ['firstPeriods', 'secondPeriods', 'method'],
  'Moving Average Channel': ['in_0', 'in_1', 'in_2', 'in_3'],
  'Moving Average Adaptive': ['periods'],
  'Moving Average': ['length', 'source', 'offset', 'smoothingLine', 'smoothingLength'],
  'Median Price': [],
  'McGinley Dynamic': ['in_0'],
  'MA with EMA Cross': ['in_0', 'in_1'],
  'MA Cross': ['in_0', 'in_1'],
  'Linear Regression Curve': ['in_0'],
  'Least Squares Moving Average': ['in_0', 'in_1'],
  'Keltner Channels': ['in_0', 'in_1', 'in_2'],
  'Ichimoku Cloud': ['in_0', 'in_1', 'in_2', 'in_3'],
  'Hull Moving Average': ['in_0'],
  'Guppy Multiple Moving Average': [
    'traderEMA1Length',
    'traderEMA2Length',
    'traderEMA3Length',
    'traderEMA4Length',
    'traderEMA5Length',
    'traderEMA6Length',
    'investorEMA1Length',
    'investorEMA2Length',
    'investorEMA3Length',
    'investorEMA4Length',
    'investorEMA5Length',
    'investorEMA6Length'
  ],
  Envelopes: ['in_0', 'in_1', 'in_2', 'in_3', 'in_4'],
  'EMA Cross': ['in_0', 'in_1'],
  'Double EMA': ['in_0'],
  'Donchian Channels': ['in_0'],
  'Correlation Coefficient': ['in_0', 'in_1'],
  'Detrended Price Oscillator': ['in_0', 'in_1'],
  'Directional Movement': ['in_0', 'in_1'],
  'Ease Of Movement': ['in_0', 'in_1'],
  'Elder\'s Force Index': ['in_0'],
  'Fisher Transform': ['in_0'],
  'Historical Volatility': ['in_0'],
  'Klinger Oscillator': [],
  'Know Sure Thing': ['in_0', 'in_1', 'in_2', 'in_3', 'in_4', 'in_5', 'in_6', 'in_7', 'in_8'],
  'Linear Regression Slope': ['periods'],
  MACD: ['in_0', 'in_1', 'in_3', 'in_2'],
  'Majority Rule': ['rollingPeriod'],
  'Mass Index': ['in_0'],
  Momentum: ['in_0', 'in_1'],
  'Money Flow Index': ['in_0'],
  'Net Volume': [],
  'On Balance Volume': ['smoothingLine', 'smoothingLength'],
  'Zig Zag': ['in_0', 'in_1'],
  'Williams Fractal': ['in_0'],
  'Williams Alligator': ['in_0', 'in_1', 'in_2', 'in_3', 'in_4', 'in_5'],
  VWMA: ['in_0'],
  VWAP: [],
  'Volume Profile Visible Range': ['rowsLayout', 'rows', 'volume', 'first_visible_bar_time', 'last_visible_bar_time', 'vaVolume'],
  Volume: ['showMA', 'length', 'col_prev_close'],
  'Volatility Index': ['periods', 'atrMult', 'method'],
  'Typical Price': [],
  'Triple EMA': ['in_0'],
  SuperTrend: ['in_0', 'in_1'],
  'Standard Error Bands': ['periods', 'errors', 'method', 'averagePeriods'],
  'Smoothed Moving Average': ['in_0', 'in_1'],
  'Price Channel': ['in_0', 'in_1'],
  'Pivot Points Standard': ['kind', 'showHistoricalPivots', 'pivTimeFrame', 'lookBack'],
  'Parabolic SAR': ['in_0', 'in_1', 'in_2'],
  'Price Oscillator': ['in_0', 'in_1'],
  'Price Volume Trend': [],
  'Rate Of Change': ['in_0'],
  Ratio: ['source', 'symbol2'],
  'Relative Strength Index': ['length', 'smoothingLine', 'smoothingLength'],
  'Relative Vigor Index': ['in_0'],
  'Relative Volatility Index': ['in_0'],
  'SMI Ergodic Indicator/Oscillator': ['in_0', 'in_1', 'in_2'],
  Spread: ['source', 'symbol2'],
  'Standard Deviation': ['periods', 'deviations'],
  'Standard Error': ['length'],
  Stochastic: ['in_0', 'in_1', 'in_2'],
  'Stochastic RSI': ['in_0', 'in_1', 'in_2', 'in_3'],
  'Trend Strength Index': ['periods'],
  TRIX: ['in_0'],
  'True Strength Indicator': ['in_0', 'in_1', 'in_2'],
  'Ultimate Oscillator': ['in_0', 'in_1', 'in_2'],
  'Volatility Close-to-Close': ['periods', 'daysPerYear'],
  'Volatility O-H-L-C': ['periods', 'marketClosedPercentage', 'daysPerYear'],
  'Volatility Zero Trend Close-to-Close': ['periods', 'daysPerYear'],
  'Volume Oscillator': ['in_0', 'in_1'],
  'Vortex Indicator': ['in_0'],
  'Williams %R': ['in_0']
};
