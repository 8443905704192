import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { createMask } from '@ngneat/input-mask';

import { CommaSeperatorPipe } from 'src/app/core/directives/comma-seperator.directive';
import { WheelCalculatorService } from '@m1/wheel/wheel-calculator/wheel-calculator.service';
import { SettingService } from '../common-service/setting.service';
import { isNullOrUndefinedOrEmpty } from '@u/utils';

@Component({
  selector: 'app-wheel-header-popup',
  templateUrl: './wheel-header-popup.component.html',
  styleUrls: ['./wheel-header-popup.component.scss']
})
export class WheelHeaderPopupComponent {
  protected userSetting = JSON.parse(localStorage.getItem('user'));

  protected buyingPower: number | null = null;
  protected position: number | null = null;

  protected positionSettingForm = this.formBuilder.group({
    buyingPower: [
      this.commaSeparatorPipe.transform(this.userSetting.wBuyingPower.toString().replace(/,/g, ''), 8, true),
      [Validators.required, Validators.maxLength(8)]
    ],
    position: [
      this.userSetting.wMaxPosition,
      [Validators.required, Validators.min(1), Validators.max(99)]
    ]
  });

  protected readonly buyingPositionTooltip = `
    Buying Power - Use available cash for IRA account. Use Buying Power for Margin account.\n
    Positions - Specify how many position to keep. System will use [Buying Power]/[Positions] as amount available per trade.
  `;

  protected buyingPowerInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    digits: 0,
  });

  protected positionInputMask = createMask({
    alias: 'numeric',
    max: 99,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    digits: 0,
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private settingService: SettingService,
    private dialogRef: MatDialog,
    private wheelService: WheelCalculatorService,
    private commaSeparatorPipe: CommaSeperatorPipe
  ) { }

  updateSetting() {
    const buyingPower = this.positionSettingForm.value.buyingPower.toString().replace(/,/g, '');
    const position = this.positionSettingForm.value.position;

    this.userSetting.wBuyingPower = buyingPower;
    this.userSetting.wMaxPosition = position;

    this.settingService.updateWheelUserSetting(this.userSetting.userId, buyingPower, position, this.userSetting.wColumnSize).subscribe(
      (res) => {
        localStorage.setItem('user', JSON.stringify(this.userSetting));
        this.wheelService.updateUserSetting$.next(this.userSetting);
        this.dialogRef.closeAll();
      },
      (err) => () => { }
    );
  }

  protected inputBlur(input: 'buyingPower' | 'position') {
    const inputValue = this.positionSettingForm.value[input];

    if (isNullOrUndefinedOrEmpty(inputValue)) {
      if (input === 'buyingPower') {
        this.positionSettingForm.controls[input].setValue('20,000');
      } else if (input === 'position') {
        this.positionSettingForm.controls[input].setValue(5);
      }
    }
  }
}
