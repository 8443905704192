// value can be a number or a number-like string (with comma separator for 1000+) or an empty string
export const isValidNumberLikeString = (
  value: string | number,
  minValue: number,
  maxValue: number,
  shouldBeInteger: boolean,
): boolean => {
  if (value === null || value === undefined) {
    return false;
  }

  const isEmptyString = value === '';

  if (isEmptyString) {
    return true;
  }

  const numberValue = typeof value === 'string' ? Number(value.replace(/,/g, '')) : value;
  const isNumber = !isNaN(numberValue);
  const isFinite = Number.isFinite(numberValue);
  const isMoreThanMinValue = numberValue >= minValue;
  const isLessThanMaxValue = numberValue <= maxValue;
  const isInteger = Number.isInteger(numberValue);
  const isNumberTypeCorrect = shouldBeInteger ? isInteger : isNumber;
  const isValidNumberLike = isNumber && isFinite && isMoreThanMinValue && isLessThanMaxValue;

  return isValidNumberLike && isNumberTypeCorrect;
};
