import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';
import { PortfolioFiltersFormComponent } from './portfolio-filters-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormDefaultsModule,
    MatTooltipModule,
  ],
  declarations: [PortfolioFiltersFormComponent],
  exports: [PortfolioFiltersFormComponent],
})
export class PortfolioFiltersFormModule { }
