import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { ProfitAndLossChartDataItemModel } from './profit-and-loss-chart.model';

@Component({
  selector: 'app-profit-and-loss-chart',
  templateUrl: './profit-and-loss-chart.component.html',
  styleUrls: ['./profit-and-loss-chart.component.scss'],
})
export class ProfitAndLossChartComponent implements OnInit {
  @Input() profits$: Observable<ReadonlyArray<number>>;
  @Input() chartHeightPx = 40;

  public chartBars$: Observable<ReadonlyArray<ProfitAndLossChartDataItemModel>>;

  ngOnInit() {
    this.chartBars$ = this.profits$.pipe(
      map((profits) => {
        let maxNegativeProfitSize = 0,
          maxPositiveProfitSize = 0,
          currentProfitValue = 0;

        let items: ReadonlyArray<ProfitAndLossChartDataItemModel> = profits.map((profit) => {
          currentProfitValue += profit;
          maxNegativeProfitSize = currentProfitValue < maxNegativeProfitSize ? currentProfitValue : maxNegativeProfitSize;
          maxPositiveProfitSize = currentProfitValue > maxPositiveProfitSize ? currentProfitValue : maxPositiveProfitSize;
          return {
            profit: currentProfitValue,
          };
        });
        const profitRange =
          (maxNegativeProfitSize > 0 ? 0 : -maxNegativeProfitSize) + (maxPositiveProfitSize < 0 ? 0 : maxPositiveProfitSize);
        const fullNegativeBarSizePercent = maxNegativeProfitSize > 0 ? 0 : (-maxNegativeProfitSize / profitRange) * 100;
        const fullPositivebarSizePercent = maxPositiveProfitSize < 0 ? 0 : (maxPositiveProfitSize / profitRange) * 100;

        items = items.map((item) => ({
          ...item,
          negativeEmptySpaceBarSizePercent:
            item.profit > 0 ? fullNegativeBarSizePercent : fullNegativeBarSizePercent - (-item.profit / profitRange) * 100,
          profitBarSizePercent: (Math.abs(item.profit) / profitRange) * 100,
          positiveEmptySpaceBarSizePercent:
            item.profit < 0 ? fullPositivebarSizePercent : fullPositivebarSizePercent - (item.profit / profitRange) * 100,
        }));

        return items;
      }),
      share()
    );
  }
}
