import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentNames } from '@mod/common/environment.model';
import { SentryService } from '@s/common/sentry.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private _sentryService: SentryService) {
    super();
  }

  override handleError(error): void {

    if (environment.name === EnvironmentNames.Prod) {
      this._sentryService.log(error);
    }

    if (environment.name === EnvironmentNames.Local
      || environment.name === EnvironmentNames.Dev
      || environment.name === EnvironmentNames.Staging) {
      console.error('Error', error);
    }
  }
}
