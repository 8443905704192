<div (keydown.escape)="$event.preventDefault(); $event.stopPropagation(); onClose();">
  <button mat-button mat-dialog-close (click)="onClose()" class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon"></mat-icon>
  </button>

  <div mat-dialog-content>
    <div class="text-center">
      <ng-container *ngIf="currentStatus === 'progress'; else importStatus">
        <div class="status-loader">
          <app-loading-animation></app-loading-animation>
        </div>
        <h3 class="status-title">Adding trade</h3>
        <p class="status-text">Please wait...</p>
      </ng-container>
      <ng-template #importStatus>
        <div class="status-loader">
          <mat-icon [svgIcon]="'complete-icon'"></mat-icon>
        </div>
        <h3 class="status-title">New trade has been added successfully</h3>
      </ng-template>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="action-buttons" *ngIf="currentStatus === 'success'">
      <button
        mat-stroked-button
        class="secondary-btn default-bg secondary"
        (click)="openTradingLogPage()"
      >
        View Trading Log
      </button>
    </div>
  </div>
</div>
