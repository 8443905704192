import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { TradingLogLogicService } from '@s/trading-log/trading-log-logic.service';
import { groupArrayByKey } from '@u/utils';
import { StringifiedGuid } from '@t/common';
import { MomentDateTimeFormats } from '@const';
import {
  TradingLogTransactionModel,
  TradingLogGroupSummaryModel,
  TradingLogGroupModel,
  TradingLogAccountModel,
  TradingLogStrategyModel,
} from '@mod/trading-log';
import { TradingLogFeelingsDataModel } from '@mod/trading-log/feelings';
import { getDateComparer } from '@u/comparers/date.comparer';

@Injectable()
export class TradingLogExportTransactionsService {
  constructor(
    private tradingLogLogicService: TradingLogLogicService,
  ) {}

  public transformExportTransactions(
    transactions: ReadonlyArray<Partial<TradingLogTransactionModel>>,
    groups: ReadonlyArray<Partial<TradingLogGroupModel>>,
    groupsSummaryMap: Map<StringifiedGuid, TradingLogGroupSummaryModel>,
    accountsMap: Map<StringifiedGuid, TradingLogAccountModel>,
    strategiesMap: Map<StringifiedGuid, TradingLogStrategyModel>,
    tradingLogFeelingsData: TradingLogFeelingsDataModel,
  ): Array<Record<string, string | number>> {
    // important: there are all transactions and filtered groups
    const groupsIDs = groups.map((group) => group.id);
    const filteredTransactions = transactions.filter((item) => groupsIDs.includes(item.group_id));

    if (filteredTransactions.length === 0 || groups.length === 0) {
      return [
        {
          'Group ID': '',
          Symbol: '',
          Account: '',
          Strategy: '',
          Child: '',
          Type: '',
          Date: '',
          Time: '',
          Expiration: '',
          Strike: '',
          'Shares/Contr': '',
          'Price/Prem': '',
          Fees: '',
          Commissions: '',
          Reason: '',
          Confidence: '',
          Notes: '',
          Action: '',
          'Expired Contracts': '',
          'Expired Date': '',
          'Expired Time': '',
          'Fees & Commissions': '',
          'Profit & Loss': '',
          'Avg Days': '',
          'Max Days': '',
          'Avg Days (Assigned)': '',
          'Max Days (Assigned)': '',
        }
      ];
    }

    const groupedTransactions = groupArrayByKey([...transactions], 'group_id');
    const groupWithTransactions = groups.map((item) => ({
      ...item,
      transactions: (groupedTransactions[item.id] ?? []) as TradingLogTransactionModel[],
    }));

    const decisionsDataMap = new Map<string, string>();
    const confidenceDataMap = new Map<string, string>();

    tradingLogFeelingsData.decisionOptions.forEach((type) => {
      if (type.items && type.items.length > 0) {
        type.items.forEach((subType) => {
          decisionsDataMap.set(subType.id, `${type.shortName} / ${subType.name}`);
        });

        return;
      }

      decisionsDataMap.set(type.id, type.shortName);
    });

    tradingLogFeelingsData.confidenceOptions.forEach((type) => {
      confidenceDataMap.set(type.id, type.shortName);
    });

    const resultTransactions = [];

    groupWithTransactions.forEach((group, groupIndex) => {
      // place child transactions after parent
      const parentTransactions = [...group.transactions].filter((item) => !item.parent_id);
      const childTransactions = [...group.transactions].filter((item) => item.parent_id);
      const groupedChildTransactions = groupArrayByKey(childTransactions, 'parent_id');
      const sortedTransactions = [];

      parentTransactions
      .sort(getDateComparer((o) => {

        const selectedDate = o.date ? moment(o.date, MomentDateTimeFormats.ServerDate).startOf('day') : moment().startOf('day');
        if (o.time) {
          const selectedTime = moment(o.time, MomentDateTimeFormats.FullTime);
          selectedDate.hours(selectedTime.hours());
          selectedDate.minutes(selectedTime.minutes());
        }
        return selectedDate;
      }, 'minute'))
      .forEach((item) => {
        sortedTransactions.push(item);

        if (groupedChildTransactions[item.id]) {
          sortedTransactions.push(...groupedChildTransactions[item.id]);
        }
      });

      sortedTransactions.forEach((transaction) => {
        const feesAndCommissions = transaction.fees === null && transaction.commission === null
          ? null
          : (transaction.fees ?? 0) + (transaction.commission ?? 0);
        const profitAndLoss = transaction.result === null && feesAndCommissions === null
          ? null
          : (transaction.result || 0) - (feesAndCommissions || 0);

        const date = transaction.date ? moment(transaction.date.split('T')[0]).format(MomentDateTimeFormats.ReadableDate) : '';
        const time = this.formatTime(transaction.time);

        let expiredDate = null;
        let expiredTime = null;

        if (transaction.expired_cnt && transaction.expired_cnt > 0) {
          expiredDate = transaction.close_date
            ? moment(transaction.close_date.split('T')[0]).format(MomentDateTimeFormats.ReadableDate)
            : null;
          expiredTime = transaction.close_time ? this.formatTime(transaction.close_time) : null;
        }

        resultTransactions.push({
          'Group ID': groupIndex + 1,
          Symbol: group.symbol,
          Account: accountsMap.get(group.account_id)?.name ?? '',
          Strategy: strategiesMap.get(group.strategy_id)?.name ?? '',
          Child: transaction.parent_id ? 'Yes' : 'No',
          Type: this.tradingLogLogicService.getTransactionDisplayName(transaction.type),
          Date: date,
          Time: time,
          Expiration: transaction.expiration ? moment(transaction.expiration.split('T')[0]).format(MomentDateTimeFormats.ReadableDate) : '',
          Strike: transaction.strike,
          'Shares/Contr': transaction.qty,
          'Price/Prem': transaction.price,
          Fees: transaction.fees,
          Commissions: transaction.commission,
          Reason: decisionsDataMap.get(transaction.decision) ?? '',
          Confidence: confidenceDataMap.get(transaction.confidence) ?? '',
          Notes: transaction.note,
          Action: this.tradingLogLogicService.getTransactionStatusText(transaction.status),
          'Expired Contracts': transaction.expired_cnt,
          'Expired Date': expiredDate ?? '',
          'Expired Time': expiredTime ?? '',
          'Fees & Commissions': feesAndCommissions ?? '',
          'Profit & Loss': profitAndLoss ?? '',
          'Avg Days': transaction.avg_days_in_trade ?? '',
          'Max Days': transaction.max_days_in_trade ?? '',
          'Avg Days (Assigned)': transaction.avg_days_in_trade_assignment ?? '',
          'Max Days (Assigned)': transaction.max_days_in_trade_assignment ?? '',
        });
      });
    });

    return resultTransactions;
  }

  public getFileName(): string {
    const currentDate = moment();

    const formattedDate = currentDate.format('YYYY-MM-DD');
    const formattedTime = currentDate.format('HH-mm-ss');

    return `Trading Log ${formattedDate}_${formattedTime}`;
  }

  // Example: "22:30:00" => "22:30"
  private formatTime(fullTimeValue: string): string {
    if (!fullTimeValue) {
      return '';
    }

    return fullTimeValue.split(':').slice(0, 2).join(':');
  }
}
