<div class="data-for-print">
  <img src="/assets/img/newImg/logo.svg" alt="logo" class="logo-for-print" title="logo"/>
  <ng-container *ngIf="!printOptions.includes(printOptionType.TradingPlan)">
    <app-search-bar [isPowerX]="true"></app-search-bar>

    <div class="page-break" *ngIf="printOptions.includes(printOptionType.Chart)">
      <p class="print-section-title">Chart</p>
      <div id="print-chart" class="print-chart-container page-break"></div>
    </div>

    <div class="page-break" *ngIf="printOptions.includes(printOptionType.DataWindow)">
      <p class="print-section-title">Data window</p>
      <app-data-window [isPrint]="true" class="print-data-window"></app-data-window>
    </div>

    <div class="page-break" *ngIf="printOptions.includes(printOptionType.TradingReport)">
      <app-trading-report
        [isPrint]="true"
        [isReportFitOnPrintScreen]="isReportFitOnScreen">
      </app-trading-report>
    </div>

    <div *ngIf="printOptions.includes(printOptionType.Options)">
      <p class="print-section-title">Options</p>
      <app-options [isPrint]="true"></app-options>
    </div>
  </ng-container>

  <ng-container *ngIf="printOptions.includes(printOptionType.TradingPlan)">
    <div>
      <app-print-trading-plan></app-print-trading-plan>
    </div>
  </ng-container>
</div>
