import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { v4 as uuidV4 } from 'uuid';

import { FiltersPresetsModel } from '@c/shared/filter-presets-menu/filters-presets.model';

@Component({
  selector: 'app-filters-presets-menu',
  templateUrl: './filters-presets-menu.component.html',
  styleUrls: ['./filters-presets-menu.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    DragDropModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatInputModule,
  ],
})
export class FiltersPresetsMenuComponent implements OnInit {
  protected selectedPreset: FiltersPresetsModel | null = null;

  protected readonly newPresetTempId = 'new-preset-temp-id';
  protected readonly newPresetDefaultName = 'My Preset';
  protected readonly maxPresetTitleLength = 25;
  protected readonly maxPresetsNumber = 42; // it's not defined in spec

  protected isMenuOpened = false;
  protected editedItemId: string | null = null;
  protected newFilterName = '';

  protected currentPredefinedFiltersList: FiltersPresetsModel[] = [];
  protected currentCustomFiltersList: FiltersPresetsModel[] = [];

  @Input() set selectedPresetId(value: string | null) {
    if (value === null) {
      this.selectedPreset = null;
    }

    if (this.selectedPreset && this.selectedPreset.id === value) {
      return;
    }

    // TODO: handle case when "currentPredefinedFiltersList" and "currentCustomFiltersList" (initial values) are not received yet
    const preset = [...this.currentPredefinedFiltersList, ...this.currentCustomFiltersList].find(
      (item) => item.id === value,
    );

    this.selectedPreset = preset ?? null;
    this.changeDetectorRef.markForCheck();
  }

  @Input() set predefinedFiltersList(value: FiltersPresetsModel[]) {
    this.currentPredefinedFiltersList = [...value];

    this.updateSelectedPreset();
    this.changeDetectorRef.markForCheck();
  }

  @Input() set customFiltersList(value: FiltersPresetsModel[]) {
    this.currentCustomFiltersList = [...value];

    this.updateSelectedPreset();
    this.changeDetectorRef.markForCheck();
  }

  // output
  @Output() presetSelected = new EventEmitter<FiltersPresetsModel | null>();
  @Output() presetSettingsSaved = new EventEmitter<FiltersPresetsModel | null>();

  @Output() predefinedPresetsUpdated = new EventEmitter<FiltersPresetsModel[]>();
  @Output() customPresetsUpdated = new EventEmitter<FiltersPresetsModel[]>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  protected selectPreset(preset: FiltersPresetsModel): void {
    this.selectedPreset = preset;
    this.presetSelected.emit(this.selectedPreset);
  }

  // partially done functionality - do not remove, it's for the next release
  protected savePresetSettings(): void {
    if (!this.selectedPreset) {
      return;
    }

    const isCurrentPresetCustom = this.currentCustomFiltersList.find((item) => item.id === this.selectedPreset.id);

    // apply "save-settings" action only for custom presets
    if (isCurrentPresetCustom) {
      this.presetSettingsSaved.emit(this.selectedPreset);
    }
  }

  protected savePresetTitle(): void {
    const newTitle = this.newFilterName.trim().slice(0, this.maxPresetTitleLength);

    // save new preset and set it as selected
    if (this.editedItemId === this.newPresetTempId) {
      const newPreset = {
        id: uuidV4(),
        title: newTitle || this.newPresetDefaultName,
        settings: {},
      };

      this.currentCustomFiltersList.push(newPreset);

      this.selectedPreset = newPreset;
      this.resetEditMode();

      this.customPresetsUpdated.emit(this.currentCustomFiltersList);
      this.presetSettingsSaved.emit(this.selectedPreset);
      this.presetSelected.emit(this.selectedPreset);
      this.isMenuOpened = false;

      this.changeDetectorRef.markForCheck();
      return;
    }

    if (this.editedItemId) {
      if (newTitle.length > 0) {
        this.currentCustomFiltersList = this.currentCustomFiltersList.map((item) => {
          if (item.id === this.editedItemId) {
            return { ...item, title: newTitle };
          }

          return item;
        });
      }
    } else {
      this.currentCustomFiltersList.push({ id: uuidV4(), title: newTitle, settings: {} });
    }

    this.customPresetsUpdated.emit(this.currentCustomFiltersList);

    this.updateSelectedPreset();
    this.resetEditMode();
  }

  protected setEditMode(item: FiltersPresetsModel): void {
    this.editedItemId = item.id;
    this.newFilterName = item.title;

    this.setFocusForInput();
    this.changeDetectorRef.markForCheck();
  }

  protected setEditModeForNewPreset(): void {
    this.editedItemId = this.newPresetTempId;
    this.newFilterName = '';

    this.setFocusForInput();
    this.changeDetectorRef.markForCheck();
  }

  protected resetEditMode(): void {
    this.editedItemId = null;
    this.newFilterName = '';

    this.changeDetectorRef.markForCheck();
  }

  protected setFocusForInput(): void {
    setTimeout(() => {
      const element = document.getElementById('edit-preset-name-input') as HTMLInputElement | null;

      if (element) {
        element.focus();
        element.select();

        this.changeDetectorRef.markForCheck();
      }
    }, 0);
  }

  protected removeItem(id: string): void {
    this.currentCustomFiltersList = [...this.currentCustomFiltersList].filter((item) => item.id !== id);
    this.updateSelectedPreset();

    this.customPresetsUpdated.emit(this.currentCustomFiltersList);
    this.presetSelected.emit(this.selectedPreset);

    this.changeDetectorRef.markForCheck();
  }

  protected onDropPredefined(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.currentPredefinedFiltersList, event.previousIndex, event.currentIndex);

    this.predefinedPresetsUpdated.emit(this.currentPredefinedFiltersList);
    this.changeDetectorRef.markForCheck();
  }

  protected onDropCustom(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.currentCustomFiltersList, event.previousIndex, event.currentIndex);

    this.customPresetsUpdated.emit(this.currentCustomFiltersList);
    this.changeDetectorRef.markForCheck();
  }

  // update selected preset after rename (or other actions) to have relevant dat a there
  private updateSelectedPreset(): void {
    if (!this.selectedPreset) {
      return;
    }

    const updatedSelectedPreset = [...this.currentPredefinedFiltersList, ...this.currentCustomFiltersList].find(
      (item) => item.id === this.selectedPreset.id,
    );

    this.selectedPreset = updatedSelectedPreset ?? null;
    this.changeDetectorRef.markForCheck();
  }
}
