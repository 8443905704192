import { NumberUnits } from '@const';

export const getNumberUnit = (number: number, round: number = 10) => {
  if (isNaN(number) || number === null) return null;

  const absoluteValue = Math.abs(number);
  if (absoluteValue < 1000) return number;

  const rounder = round;
  const { key, value } =
    NumberUnits.find((power) => absoluteValue / power.value >= 1) || {};

  if (!(key && value)) return number;

  const reduced = Math.round((absoluteValue / value) * rounder) / rounder;
  const isNegative = number < 0;
  return (isNegative ? '-' : '') + reduced + key;
};
