import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { VideoHubApiKeys, VideoHubWordPressUrls } from '@const';
import { videoHubPlayerUrl } from '@constants/video-hub.contants';
import { Response } from '@core1/interface';
import { BackgroundVideoModalComponent } from '@mdl/background-video-modal/background-video-modal.component';
import { BackgroundVideoModalModel } from '@mdl/background-video-modal/background-video-modal.model';
import { VideoWpResponseModel } from '@mod/video-hub/acf-api-response.model';
import { GoogleResponseModel, GoogleValidateResponseModel } from '@mod/video-hub/google-response.model';
import { CategoryModel, TagModel } from '@mod/video-hub/video-hub.model';
import { VimeoResponseModel } from '@mod/video-hub/vimeo-response.model';
import {
  WpApiGetCategoriesRequiredRequestModel,
  WpApiGetVideosByCategoryRequiredRequestModel,
  WpApiGetVideosByTagRequiredRequestModel,
} from '@mod/video-hub/wp-api-request.model';
import { EditionsService } from '@s/editions.service';
import { LocalStorageService } from '@s/local-storage.service';
import { RestRequestorService } from '@s/rest-requestor.service';
import { toInt } from '@u/utils';
import { Observable, catchError, filter, firstValueFrom, from, map, of, switchMap, take, tap } from 'rxjs';
import { VideoHubCacheService } from './video-hub-cache.service';
import { VideoHubHttpClient } from './video-hub-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class VideoHubService {
  public readonly BackgroundVideoLocalStorageKey = 'background-video';
  public readonly BackgroundVideoInitialHeight = 230;
  public readonly BackgroundVideoInitialWidth = Math.trunc(this.BackgroundVideoInitialHeight * (16 / 9));

  public onMainContentScroll = new EventEmitter<Event>();

  private readonly userAccessLevel = this.editionsService.getUserAccessLevel();
  private overlayRef: OverlayRef;

  constructor(
    private restRequesterService: RestRequestorService,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private overlay: Overlay,
    private videoHubCacheService: VideoHubCacheService,
    private localStorageService: LocalStorageService,
    private location: Location,
    private editionsService: EditionsService,
    private httpClient: VideoHubHttpClient,
  ) {}

  public getCategories(params: WpApiGetCategoriesRequiredRequestModel): Observable<CategoryModel[]> {
    const url =
      VideoHubWordPressUrls.categories
        .replace('${parent}', `${params.parent}`)
        .replace('${per_page}', `${params.per_page}`)
        .replace('${nopaging}', `${+params.disablePagination}`) + `&page=${params.page}`;
    return this.fetchCachedOrRequest<CategoryModel[]>(url);
  }

  public getCategoryById(id: number): Observable<CategoryModel> {
    const url = VideoHubWordPressUrls.categoryById.replace('${id}', `${id}`);
    return this.fetchCachedOrRequest<CategoryModel>(url);
  }

  public getTagVideos(params: WpApiGetVideosByTagRequiredRequestModel): Observable<VideoWpResponseModel[]> {
    const url =
      VideoHubWordPressUrls.videosByTag
        .replace('${tagId}', `${params.tagId}`)
        .replace('${per_page}', `${params.per_page}`)
        .replace('${nopaging}', `${+params.disablePagination}`) + `&page=${params.page}`;
    return this.fetchCachedOrRequest<VideoWpResponseModel[]>(url);
  }

  public getTagById(id: number): Observable<TagModel> {
    const url = VideoHubWordPressUrls.tagById.replace('${id}', `${id}`);
    return this.fetchCachedOrRequest<TagModel>(url);
  }

  public getVideoById(id: number): Observable<VideoWpResponseModel> {
    const url = VideoHubWordPressUrls.videoById.replace('${id}', `${id}`);
    return this.fetchCachedOrRequest<VideoWpResponseModel>(url);
  }

  public getVideosByCategory(params: WpApiGetVideosByCategoryRequiredRequestModel): Observable<VideoWpResponseModel[]> {
    const url =
      (VideoHubWordPressUrls.allVideos + `&pxo-video-category=${params.categoryId}`)
        .replace('${orderby}', params.orderby)
        .replace('${per_page}', `${params.per_page}`)
        .replace('${order}', params.order)
        .replace('${nopaging}', `${+params.disablePagination}`)
        .replace('${ignore-child}', `${+params.ignoreChild}`) + `&page=${+params.page}`;

    return this.fetchCachedOrRequest<VideoWpResponseModel[]>(url);
  }

  public validateWordPressVideo(id: number): Observable<boolean> {
    return this.getVideoById(id).pipe(
      map((data) => !!data),
      catchError(() => {
        return of(false);
      }),
    );
  }

  public validateYoutubeVideoId(videoId: string): Observable<boolean> {
    const url = 'https://www.googleapis.com/youtube/v3/videos';
    const params = {
      id: videoId,
      key: VideoHubApiKeys.youtube,
      part: 'snippet,contentDetails',
    };

    return from(
      this.restRequesterService.makeRequest(
        'yt-validate-' + videoId,
        () =>
          firstValueFrom(
            this.httpClient.get<Response<GoogleValidateResponseModel>>(url, {
              params,
            }),
          ),
        true,
      ),
    ).pipe(
      map((response) => response as unknown as GoogleValidateResponseModel),
      map((response) => !!response.pageInfo.totalResults),
    );
  }

  public validateVimeoVideoId(videoId: string): Observable<boolean> {
    const url = 'https://api.vimeo.com/videos/' + videoId;
    const headers = new HttpHeaders().append('Authorization', `bearer ${VideoHubApiKeys.vimeo}`);

    return from(
      this.restRequesterService.makeRequest(
        'vimeo-validate-' + videoId,
        () =>
          firstValueFrom(
            this.httpClient.get<Response<VimeoResponseModel>>(url, {
              headers,
            }),
          ),
        true,
      ),
    ).pipe(
      map((response) => !!response),
      catchError(() => of(false)),
    );
  }

  public openBackgroundVideoFromStorage(): void {
    try {
      const backgroundVideoJson = this.localStorageService.get(this.BackgroundVideoLocalStorageKey);
      const currentLocation = this.location.path(false);

      if (backgroundVideoJson) {
        const backgroundVideoData = JSON.parse(backgroundVideoJson) as BackgroundVideoModalModel;

        if (currentLocation.includes(videoHubPlayerUrl)) {
          this.localStorageService.removeByFullKey('v2/background-video');
          return;
        }

        if (backgroundVideoData.video && backgroundVideoData.position && backgroundVideoData.size) {
          const isXYAvailable =
            window.innerWidth - backgroundVideoData.position.x - backgroundVideoData.size.width >= 0 &&
            window.innerHeight - backgroundVideoData.position.y - backgroundVideoData.size.height >= 0;

          this.localStorageService.removeByFullKey('v2/background-video');

          this.showBackgroundVideo(
            {
              ...backgroundVideoData,
              position: {
                x: isXYAvailable ? backgroundVideoData.position.x : 50,
                y: isXYAvailable
                  ? backgroundVideoData.position.y
                  : window.innerHeight - backgroundVideoData.size.height - 50,
              },
            },
            true,
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  public showBackgroundVideo(data: BackgroundVideoModalModel, isRestore = false): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    let componentRef;

    const positionStrategy = this.overlayPositionBuilder.global();

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'background-video-backdrop',
      panelClass: ['cdk-overlay-pane', 'background-video-pane'],
    });

    if (this.overlayRef && !this.overlayRef.hasAttached() && !isRestore) {
      componentRef = this.overlayRef.attach(new ComponentPortal(BackgroundVideoModalComponent));
      componentRef.setInput('videoMetadata', {
        ...data,
        video: null,
        timestamp: null,
        position: null,
      });
    }

    of(data)
      .pipe(
        switchMap((data) => this.validateWordPressVideo(data.video.id)),
        filter((wordPressResponse) => !!wordPressResponse),
        switchMap(() =>
          data.video.source === 'youtube'
            ? this.validateYoutubeVideoId(data.video.videoId)
            : this.validateVimeoVideoId(data.video.videoId),
        ),
        map((data) => !!data && window.innerWidth > 991),
        filter((isValid) => !!isValid),
        catchError(() => of(false)),
        take(1),
      )
      .subscribe(() => {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
          componentRef.setInput('videoMetadata', data);
        } else {
          const componentRef = this.overlayRef.attach(new ComponentPortal(BackgroundVideoModalComponent));
          componentRef.setInput('videoMetadata', data);
        }
      });
  }

  public closeBackgroundVideo(): void {
    this.overlayRef?.dispose();
  }

  public getYoutubeVideoMetadata(videoId: string): Observable<GoogleResponseModel | null> {
    const url = `https://www.googleapis.com/youtube/v3/videos`;
    const params = {
      id: videoId,
      key: VideoHubApiKeys.youtube,
      part: 'snippet,contentDetails,liveStreamingDetails',
    };

    return from(
      this.restRequesterService.makeRequest('yt-meta-' + videoId, () =>
        firstValueFrom(
          this.httpClient.get<Response<GoogleResponseModel>>(url, {
            params,
          }),
        ),
      ),
    ).pipe(
      map((response) => response as unknown as GoogleResponseModel),
      catchError(() => of(null)),
    );
  }

  public getVimeoVideoMetadata(videoId: string): Observable<VimeoResponseModel | null> {
    const url = 'https://api.vimeo.com/videos/' + videoId;
    const headers = new HttpHeaders().append('Authorization', `bearer ${VideoHubApiKeys.vimeo}`);

    return from(
      this.restRequesterService.makeRequest('vimeo-meta-' + videoId, () =>
        firstValueFrom(
          this.httpClient.get<Response<VimeoResponseModel>>(url, {
            headers,
          }),
        ),
      ),
    ).pipe(
      map((response) => response as unknown as VimeoResponseModel),
      catchError(() => of(null)),
    );
  }

  public getTotalCategories(parent: number): Observable<number> {
    const url = VideoHubWordPressUrls['categories']
      .replace('${parent}', `${parent}`)
      .replace('${per_page}', '1')
      .replace('${nopaging}', '0');
    const cacheKey = `categories-count-${parent}`;

    const cachedData = this.videoHubCacheService.get(cacheKey);
    return cachedData
      ? of(cachedData)
      : this.httpClient.getResponse<CategoryModel[]>(url).pipe(
          map((data) => toInt(data.headers.get('x-wp-total')) ?? 1),
          tap((total) => this.videoHubCacheService.set(cacheKey, total)),
        );
  }

  public getTotalVideosByTag(tagId: number): Observable<number> {
    const url = VideoHubWordPressUrls.videosByTag.replace('${tagId}', `${tagId}`).replace('${per_page}', '1');
    const cacheKey = `tag-${tagId}-videos-count`;

    const cachedData = this.videoHubCacheService.get(cacheKey);

    return cachedData
      ? of(cachedData)
      : this.httpClient.getResponse<VideoWpResponseModel[]>(url).pipe(
          map((data) => toInt(data.headers.get('x-wp-total')) ?? 1),
          tap((total) => this.videoHubCacheService.set(cacheKey, total)),
        );
  }

  public getTotalVideosByCategoryId(categoryId: number): Observable<number> {
    const url = (VideoHubWordPressUrls.allVideos + `&pxo-video-category=${categoryId}`)
      .replace('${orderby}', 'menu_order')
      .replace('${per_page}', `1`)
      .replace('${order}', 'asc')
      .replace('${nopaging}', '0')
      .replace('${ignore-child}', '1');

    const cacheKey = `category-${categoryId}-videos-count`;

    const cachedData = this.videoHubCacheService.get(cacheKey);

    return cachedData
      ? of(cachedData)
      : this.httpClient.getResponse<VideoWpResponseModel[]>(url).pipe(
          map((data) => toInt(data.headers.get('x-wp-total')) ?? 1),
          tap((total) => this.videoHubCacheService.set(cacheKey, total)),
        );
  }

  private fetchCachedOrRequest<T>(url: string): Observable<T> {
    const cachedData = this.videoHubCacheService.get(url);
    return cachedData
      ? of(cachedData)
      : this.httpClient.get<T>(url).pipe(tap((data) => this.videoHubCacheService.set(url, data)));
  }
}
