import { QuickLinksRecordSettings, QuickLinksRecordType } from '@mod/link-organiser/link-organiser.model';

export const DEFAULT_QUICK_LINKS_SETTINGS: Record<QuickLinksRecordType, QuickLinksRecordSettings> = {
  [QuickLinksRecordType.PxoLink]: {
    recordType: QuickLinksRecordType.PxoLink,
    order: 0,
    isExpanded: true,
  },
  [QuickLinksRecordType.UserLink]: {
    recordType: QuickLinksRecordType.UserLink,
    order: 1,
    isExpanded: true,
  },
};
