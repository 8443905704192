<div mat-dialog-title>
  Add Earnings Date
  <button
    mat-button
    mat-dialog-close
    (click)="cancel()"
    [disabled]="addEarningsForm.disabled$ | async"
    class="popup-close-btn"
    tabindex="-1"
  >
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon">
    </mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <app-add-earnings-form #addEarningsForm></app-add-earnings-form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="addEarningsForm.disabled$ | async" (click)="addDate(addEarningsForm.value)">
    <mat-icon *ngIf="addEarningsForm.disabled$ | async">
      <mat-spinner class="white-spinner" diameter="25"></mat-spinner>
    </mat-icon>
    <ng-container *ngIf="!(addEarningsForm.disabled$ | async)">Add Date</ng-container>
  </button>
  <button mat-raised-button (click)="cancel()" [disabled]="addEarningsForm.disabled$ | async">Cancel</button>
</div>
