import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { Response } from '../core/interface/response';
import { ScannerResultTypes } from '@const';

@Injectable({
  providedIn: 'root'
})
export class ScannerResultsService {

  constructor(private http: HttpClient) { }

  insert = async (security_ids: Array<number>, strategy_id: number, type: ScannerResultTypes) => {
    const { result } = await firstValueFrom(this.http.post<Response>('/v2/scannerResults', {
      security_ids, 
      strategy_id,
      type
    }));

    return result;
  };

  get = async (type: ScannerResultTypes) => {
    const { result } = await firstValueFrom(this.http.get<Response>(`/v2/scannerResults?type=${type}`));

    return result;
  };

  remove = async (id: number) => {
    const { result } = await firstValueFrom(this.http.delete<Response>(`/v2/scannerResults/${id}`));

    return result;
  };

  removeAll = async (type: ScannerResultTypes) => {
    const { result } = await firstValueFrom(this.http.post<Response>(`/v2/scannerResults/remove_all`, {
      type
    }));

    return result;
  };

  run = async (settings) => {
    const date = moment().utc().format('YYYY-MM-DD');

    const { result } = await firstValueFrom(this.http.post<Response>("/v2/scannerResults/run", {
      ...settings,
      date
    }));

    return result;
  };
}
