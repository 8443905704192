import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradingHubModalComponent } from './trading-hub-modal.component';
import { TradingHubModule } from '@c/trading-hub/trading-hub.module';

@NgModule({
  declarations: [
    TradingHubModalComponent,
  ],
  imports: [
    CommonModule,
    TradingHubModule,
  ],
})
export class TradingHubModalModule { }
