import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, firstValueFrom, interval } from 'rxjs';
import { Broker, CookieExpireTimeInMinutes } from '@const';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';
import { ObservableService } from './observable.service';
import { RestRequestorService } from './rest-requestor.service';
import {
  BrokerAuthenticationResponse,
  TradierAuthenticationData,
  TradierAccessDataModel,
  TradierAccessDataResponse
} from '@mod/trading-panel/broker-authentication.model';
import { TradingPanelClientEvent } from '@mod/trading-panel/trading-panel.model';

@Injectable({
  providedIn: 'root'
})
export class BrokerAuthenticationService {
  static tradierLocalStorageKey: string = 'tradierBrokerAPIAccessData';
  static tradierStateCookieName: string = 'tradierState';
  static tradierLoginHintCookieName: string = 'tradierLoginHint';
  static tradierAccessTokenCookieName: string = 'tradierAccessToken';
  static tradierRefreshTokenCookieName: string = 'tradierRefreshToken';
  static tradierRefreshAtCookieName: string = 'tradierRefreshAt';
  static refreshTokenIntervalMin: number = 5 * 60 * 1000; // 5 minutes

  public TradierAuthenticationData = new BehaviorSubject<number>(null);

  private _refreshTokenInterval: Subscription = null;

  constructor(
    private _http: HttpClient,
    private _cookieService: CookieService,
    private _localStorageService: LocalStorageService,
    private _observableService: ObservableService,
    private _restRequestorService: RestRequestorService,
  ) { }

  async _getAccessData(): Promise<TradierAccessDataModel> {
    let accessData = this._localStorageService.get(BrokerAuthenticationService.tradierLocalStorageKey);
    if (!accessData) {
      const { result } = await this._restRequestorService.makeRequest(BrokerAuthenticationService.tradierLocalStorageKey,
        () => firstValueFrom(this._http.get<TradierAccessDataResponse>(`/v2/trading/tradier_access_data`)));

      accessData = result[0];
      this._localStorageService.set(BrokerAuthenticationService.tradierLocalStorageKey, accessData);
    }
    return accessData;
  }

  async initialize(): Promise<void> {
    this.TradierAuthenticationData.next(moment().unix());

    const { refreshToken, refreshTokenAt } = this.getTradierAuthenticationData();

    let setAuthenticationData = true;

    if (refreshToken) {
      if (refreshTokenAt?.isValid() && refreshTokenAt?.diff(moment(), 'hours', true) > 1) {
        if (this._refreshTokenInterval) {
          this._refreshTokenInterval.unsubscribe();
        }

        this._refreshTokenInterval = interval(BrokerAuthenticationService.refreshTokenIntervalMin)
          .subscribe(async () => await this._checkRefreshToken());
      } else {
        setAuthenticationData = false;
        await this._checkRefreshToken({
          refreshToken,
          refreshTokenAt
        });
      }
    }

    if (!setAuthenticationData) {
      return;
    }

    this.TradierAuthenticationData.next(moment().unix());
  }

  async _checkRefreshToken(data = null): Promise<void> {
    const { refreshToken, refreshTokenAt } = data || this.getTradierAuthenticationData();

    if (!refreshToken) {
      return;
    }

    const shouldRefreshAccessToken = refreshTokenAt && refreshTokenAt.isValid()
      ? refreshTokenAt.diff(moment(), 'hours', true) < 1
      : true;

    if (!shouldRefreshAccessToken) {
      return;
    }

    try {
      const refreshTokenResponse = await firstValueFrom(this._http.post<BrokerAuthenticationResponse>(`/v2/trading/refresh_token`, {
        refresh_token: refreshToken
      }));

      await this._handleAccessTokenResponse(refreshTokenResponse);
    } catch (err) {
      throw new Error(`Failed to refresh access token`);
    }
  }

  async _handleAccessTokenResponse(authResponse: BrokerAuthenticationResponse): Promise<void> {
    const { type: error, description, result } = authResponse;

    if (error) {
      throw new Error(`Failed to mint access token - ${description}`);
    }

    const { access_token, refresh_token, refresh_token_at } = result;
    const refreshAt = moment(refresh_token_at);
    const exDays = refreshAt.isValid()
      ? refreshAt.diff(moment(), 'days', true)
      : 1;

    this._cookieService.set(BrokerAuthenticationService.tradierAccessTokenCookieName, access_token, exDays);

    if (result.refresh_token) {
      this._cookieService.set(BrokerAuthenticationService.tradierRefreshTokenCookieName, refresh_token, exDays);
    }

    if (refreshAt.isValid()) {
      this._cookieService.set(BrokerAuthenticationService.tradierRefreshAtCookieName, `${refreshAt.format()}`, exDays);
    }

    await this.initialize();
  }

  async login(broker: Broker) {
    if (broker !== Broker.Tradier) {
      throw new Error('Not supported broker type');
    }

    const state = Date.now();
    this._cookieService.set(BrokerAuthenticationService.tradierStateCookieName, `${state}`, CookieExpireTimeInMinutes[15]);

    const accessData = await this._getAccessData();
    window.open(`https://api.tradier.com/v1/oauth/authorize?client_id=${accessData.clientId}&scope=read,trade&state=${state}`, '_self');
  }

  logout() {
    this._cookieService.set(BrokerAuthenticationService.tradierAccessTokenCookieName, '', -1);
    this._cookieService.set(BrokerAuthenticationService.tradierRefreshTokenCookieName, '', -1);
    this._cookieService.set(BrokerAuthenticationService.tradierRefreshAtCookieName, '', -1);

    // Close trading popup/panel
    this._observableService.tradingPanelOrderInputState.next(null);
    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.BrokerLogout);

    this.TradierAuthenticationData.next(moment().unix());

    if (this._refreshTokenInterval) {
      this._refreshTokenInterval.unsubscribe();
    }
  }

  async mintAccessToken(state: string, code: string) {
    const savedState = this._cookieService.get(BrokerAuthenticationService.tradierStateCookieName);
    if (savedState !== state) {
      // TODO - think of UI for handling errors
      throw new Error('Minting Tradier access token failed - state mismatch');
    }

    this._cookieService.set(BrokerAuthenticationService.tradierStateCookieName, '', -1);

    const login_hint = this._cookieService.get(BrokerAuthenticationService.tradierLoginHintCookieName);

    const authResponse = await firstValueFrom(this._http.post<BrokerAuthenticationResponse>(`/v2/trading/access_token`, {
      authorization_code: code,
      login_hint
    }));

    await this._handleAccessTokenResponse(authResponse);
  }

  getTradierAuthenticationData(): TradierAuthenticationData {
    const accessToken = this._cookieService.get(BrokerAuthenticationService.tradierAccessTokenCookieName);
    const refreshToken = this._cookieService.get(BrokerAuthenticationService.tradierRefreshTokenCookieName);
    const refreshAt = this._cookieService.get(BrokerAuthenticationService.tradierRefreshAtCookieName);
    const refreshTokenAt = refreshAt
      ? moment(refreshAt)
      : null;

    return {
      accessToken,
      refreshToken,
      refreshTokenAt: refreshTokenAt?.isValid() ? refreshTokenAt : null
    };
  }
}
