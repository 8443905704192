import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { RunScannerButtonComponent } from './run-scanner-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [RunScannerButtonComponent],
  declarations: [RunScannerButtonComponent],
  providers: [],
})
export class RunScannerButtonModule {
}
