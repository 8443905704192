<h2 mat-dialog-title>
  Print Wheel Calculator
  <button mat-button mat-dialog-close class="popup-close-btn" tabindex="-1">
    <img class="light-theme-img" src="assets/img/newImg/Close-p.svg">
    <img class="black-theme-img" src="assets/img/newImg/Close-p-white.svg">
  </button>
</h2>

<form class="form-design wheel-print">
  <mat-dialog-content align="start" class="mat-typography">

    <mat-radio-group class="o-radio" aria-label="Select an option" name="signal">
      <mat-radio-button [value]="0" class="custom-radio">Print All Stocks</mat-radio-button>
      <mat-radio-button [value]="1" class="custom-radio">Select Specific Stocks</mat-radio-button>
    </mat-radio-group>
    <div class="inner-checkbox">
        <mat-checkbox class="print-ck">
          1. AAPL
        </mat-checkbox>
        <mat-checkbox class="print-ck">
          2. PUPL
        </mat-checkbox>
        <mat-checkbox class="print-ck">
          3. PRPL
        </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
      <button mat-button class="fill-btn small-btn">Print</button>
      <button mat-button class="border-btn small-btn" tabindex="-1">Cancel</button>
  </mat-dialog-actions>
</form>

