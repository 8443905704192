import { FiltersPresetsModel } from '@c/shared/filter-presets-menu/filters-presets.model';
import {
  StockScreenerFilterModel,
  StockScreenerSymbolsOptions,
  StockScreenerWeeklyOptions,
  StockScreenerWeeklyValueModel,
} from '@c/stock-screener-content/stock-screener-filter/stock-screener-filter.model';
import { IFlagListItem } from '@mod/filters/flags-filter.model';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';

export const EMIT_STOCK_SCREENER_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;

export const DEFAULT_IS_FILTERS_HIDDEN = false;

export const weeklyOptions: Record<StockScreenerWeeklyOptions, StockScreenerWeeklyValueModel> = {
  [StockScreenerWeeklyOptions.Any]: {
    filterValue: false,
    displayValue: 'Any',
  },
  [StockScreenerWeeklyOptions.Weekly]: {
    filterValue: true,
    displayValue: 'Weekly',
  },
};

export const DEFAULT_STOCK_SCREENER_FILTERS_STATE: StockScreenerFilterModel = {
  flags: [Flags.No, Flags.Maybe, Flags.Yes, Flags.Never, Flags.None],
  symbolOptions: [
    StockScreenerSymbolsOptions.SP100,
    StockScreenerSymbolsOptions.SP500,
    StockScreenerSymbolsOptions.Nasdaq,
    StockScreenerSymbolsOptions.DowJones,
    StockScreenerSymbolsOptions.Other,
  ],
  changePercentFrom: '',
  changePercentTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  volumeFrom: {
    filterValue: '',
    visibleValue: '',
  },
  volumeTo: {
    filterValue: '',
    visibleValue: '',
  },
  weeklyOption: weeklyOptions[StockScreenerWeeklyOptions.Any],
};

export const defaultPredefinedSSPresets: Array<FiltersPresetsModel<StockScreenerFilterModel>> = [
  {
    id: 'default-wheel-preset-1',
    title: 'Default',
    settings: {
      flags: [Flags.No, Flags.Maybe, Flags.Yes, Flags.Never, Flags.None],
      symbolOptions: [StockScreenerSymbolsOptions.Nasdaq, StockScreenerSymbolsOptions.SP100],
    },
  },
];

export const stockScreenerSymbolsOptions = [
  StockScreenerSymbolsOptions.SP100,
  StockScreenerSymbolsOptions.SP500,
  StockScreenerSymbolsOptions.Nasdaq,
  StockScreenerSymbolsOptions.DowJones,
  StockScreenerSymbolsOptions.Other,
];

export const symbolsDisplayValues = {
  [StockScreenerSymbolsOptions.SP100]: 'S&P 100',
  [StockScreenerSymbolsOptions.SP500]: 'S&P 500',
  [StockScreenerSymbolsOptions.Nasdaq]: 'NASDAQ 100',
  [StockScreenerSymbolsOptions.DowJones]: 'DOW 30',
  [StockScreenerSymbolsOptions.Other]: 'Other Stocks',
};

export const flagList: IFlagListItem[] = [
  {
    name: 'No',
    value: Flags.No,
    img: 'assets/img/RedSmile.svg',
  },
  {
    name: 'Maybe',
    value: Flags.Maybe,
    img: 'assets/img/YellowSmile.svg',
  },
  {
    name: 'Yes',
    value: Flags.Yes,
    img: 'assets/img/GreenSmile.svg',
  },
  {
    name: 'Never',
    value: Flags.Never,
    img: 'assets/img/NeverSmile.svg',
  },
  {
    name: 'None',
    value: Flags.None,
    img: 'assets/img/GreySmile.svg',
  },
];
