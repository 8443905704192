import { NodeSpec, DOMOutputSpec } from 'prosemirror-model';
import { Toolbar } from 'ngx-editor';
import { toStyleString } from 'ngx-editor/utils';
import {
  nodes as basicNodes,
  marks as basicMarks,
} from 'ngx-editor/schema';

// use paragraph with removed "align" attribute
// return original one (from basicNodes) in case of adding text-align control into toolbar
const customParagraph: NodeSpec = {
  content: 'inline*',
  group: 'block',
  whitespace: 'pre',
  attrs: {
    align: {
      default: null,
    },
  },
  parseDOM: [
    {
      tag: 'p',
      getAttrs(dom: HTMLElement): Record<string, any> {
        const { textAlign } = dom.style;
        const align = dom.getAttribute('align') || textAlign || null;

        return {
          align,
        };
      },
    },
  ],
  toDOM(node): DOMOutputSpec {
    const { align } = node.attrs;

    const styles: Partial<CSSStyleDeclaration> = {
      textAlign: align !== 'left' ? align : null,
    };
    const style = toStyleString(styles) || null;

    return ['p', { style }, 0];
  },
};

// use only part of available functionality
export const nodes = {
  doc: basicNodes.doc,
  text: basicNodes.text,
  hard_break: basicNodes.hard_break,
  paragraph: customParagraph,
  blockquote: basicNodes.blockquote,
  horizontal_rule: basicNodes.horizontal_rule,
  code_block: basicNodes.code_block,
  bullet_list: basicNodes.bullet_list,
  ordered_list: basicNodes.ordered_list,
  list_item: basicNodes.list_item,
  heading: basicNodes.heading,
};

export const marks = {
  em: basicMarks.em,
  strong: basicMarks.strong,
  u: basicMarks.u,
  s: basicMarks.s,
  text_color: basicMarks.text_color,
  text_background_color: basicMarks.text_background_color,
};

export const toolbar: Toolbar = [
  [
    'bold',
    'italic',
    'underline',
    'strike',
    { heading: ['h1', 'h2', 'h3'] },
    'ordered_list',
    'bullet_list',
    'text_color',
    'background_color',
  ],
];

export const locals = {
  // menu
  bold: 'Bold',
  italic: 'Italic',
  code: 'Code',
  underline: 'Underline',
  strike: 'Strike',
  blockquote: 'Blockquote',
  bullet_list: 'Bullet List',
  ordered_list: 'Ordered List',
  heading: 'Heading',
  h1: 'Header 1',
  h2: 'Header 2',
  h3: 'Header 3',
  h4: 'Header 4',
  h5: 'Header 5',
  h6: 'Header 6',
  align_left: 'Left Align',
  align_center: 'Center Align',
  align_right: 'Right Align',
  align_justify: 'Justify',
  text_color: 'Text Color',
  background_color: 'Background Color',
  insertLink: 'Insert Link',
  removeLink: 'Remove Link',
  insertImage: 'Insert Image',

  // pupups, forms, others...
  url: 'URL',
  text: 'Text',
  openInNewTab: 'Open in new tab',
  insert: 'Insert',
  altText: 'Alt Text',
  title: 'Title',
  remove: 'Reset',
};
