import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { RestRequestorService } from '@s/rest-requestor.service';
import { StorageKeys } from '@const';
import { IIncomeStatementItem, IIncomeStatementsResponse } from '@mod/data/income-statement.model';

@Injectable({
  providedIn: 'root'
})
export class IncomeStatementService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService
  ) { }

  public async get(securityId: number): Promise<IIncomeStatementItem[]> {
    const storageKey = `${StorageKeys.IncomeStatement}_${securityId}`;

    try {
      const { result } = await this.restRequesterService.makeRequest(
        storageKey,
        () => firstValueFrom(this.http.get<IIncomeStatementsResponse>(`/v2/incomeStatements`, { params: { security_id: securityId } }))
      );

      return result;
    } catch (error) {
      return [];
    }
  }
}
