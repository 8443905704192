import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Response } from '../core/interface/response';
import { LocalStorageService } from './local-storage.service';
import { PastPerformanceService } from './past-performance.service';
import { StorageKeys } from '../constants';
import { RestRequestorService } from './rest-requestor.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HistoricalDataService {

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private pastPerformanceService: PastPerformanceService,
    private restRequestorService: RestRequestorService) { }

  get = async (security_id) => {
    const storageKey = `${StorageKeys.HistoricalData}_${security_id}`;
    let historicalData = this.localStorageService.getFromMemory(storageKey);

    if (!historicalData) {
      const pastPerformance = await this.pastPerformanceService.compareStrategies(security_id);

      const date = pastPerformance && pastPerformance.length
        ? moment.utc(pastPerformance[0].date).format('YYYY-MM-DD')
        : moment().utc().format('YYYY-MM-DD');

      const { result } = await this.restRequestorService.makeRequest(storageKey,
        () => firstValueFrom(this.http.get<Response>(`/v2/historicalData?security_id=${security_id}&date=${date}`)));

      historicalData = result.map((r) => ({
          ...r,
          time: moment.utc(r.date).unix() * 1000
      }));

      this.localStorageService.setToMemory(storageKey, historicalData);
    }

    return historicalData;
  };

  getFirstAndLastBars = async (security_id) => {
    const historicalData = await this.get(security_id);

    if (!historicalData.length) {
      return {
        firstBar: null,
        lastBar: null
      };
    }

    return {
      firstBar: historicalData[0],
      lastBar: historicalData[historicalData.length - 1]
    };
  };
}
