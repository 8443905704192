<div class="doubl-btn run-scanner">
  <div class="run-scanner-only-btn" (click)="scannerSetting()">Run Scanner</div>
  <div class="right-part">
    <button mat-icon-button (click)="dropDownControl($event)" class="icon-button run-drop" >
      <mat-icon
      [svgIcon]="'arrow'"
    ></mat-icon>
    </button>
    <div (click)="getAllSignals()" class="scanner-dropdown" [class]="[showDropDown ? 'showMenu' : 'hideMenu']">
      <span>Show all Buy & Sell signals</span>
    </div>
  </div>
</div>
