<div class="conversation-container" *ngIf="!(isLoading | async); else: circularProgress">
  @if (groupedByDateMessages.length === 0) {
    <div class="start-conversation-section">
      <div class="chat-heading">
        <h2 class="chat-title">Start your chat</h2>
        <p class="chat-description">Ask your first question and get answers quickly</p>
      </div>

      <div class="question-example-block">
        <p class="question-example-title">Try examples</p>
        <div class="question-examples">
          <div
            class="question-example"
            *ngFor="let questionExample of questionExamples"
            (click)="sendPrompt(questionExample)"
          >
            &ldquo;{{ questionExample }}&rdquo;
            <mat-icon [svgIcon]="'reply-icon'" class="extra-small-icon"></mat-icon>
          </div>
        </div>

        <div class="limitations">
          <div class="limitations-title">
            <mat-icon [svgIcon]="'limit-icon'" class="small-icon"></mat-icon>
            <p>Limitations:</p>
          </div>
          <ul class="limitations-list">
            <li class="limitations-list-item">Limited knowledge of PowerX Optimizer</li>
          </ul>
        </div>
      </div>
    </div>
  }

  <div id="messages-container" class="messages-container scroll-style">
    @for (group of groupedByDateMessages; track group.date; let isLastDate = $last) {
      <div class="date-group-label">
        {{ group.date | date: 'EEEE, MMM d, y' }}
      </div>

      @for (message of group.messages; track message.id; let isLastMessage = $last) {
        <div
          [id]="'message_' + message.id"
          class="message-box"
        >
          <div class="question" *ngIf="!message.machine">
            <div class="message">
              <p [innerHTML]="message.content"></p>
            </div>
          </div>

          <div class="answer" *ngIf="message.machine">
            <div class="first-row">
              <div class="answer-inner">
                <div class="icon">
                  <mat-icon [svgIcon]="'cody-chat'" class="chat-gpt-icon"></mat-icon>
                </div>

                <div class="text">
                  <p class="success" [innerHTML]="message.content | sanitizeHtml"></p>

                  @if (isLastDate && isLastMessage && waitingForResponse) {
                    <p class="pending blinking-cursor"></p>
                  }
                </div>
              </div>

              <div class="right-section">
                <div class="feedback-actions">
                  <button
                    mat-icon-button
                    [disableRipple]="message.feedback === true"
                    class="feedback-button positive"
                    (click)="handleClickFeedback(message, true)"
                    *ngIf="message.feedback !== false"
                    [ngClass]="{ 'selected': message.feedback === true }"
                  >
                    <mat-icon class="icon" [svgIcon]="'like'"></mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    [disableRipple]="message.feedback === false"
                    class="feedback-button negative"
                    (click)="handleClickFeedback(message, false)"
                    *ngIf="message.feedback !== true"
                    [ngClass]="{ 'selected': message.feedback === false }"
                  >
                    <mat-icon class="icon" [svgIcon]="'dislike'"></mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }

    <!--  Do not remove  -->
    <!--<div class="message-box" *ngIf="waitingForResponse">
      <div class="answer">
        <div class="first-row">
          <div class="answer-inner">
            <div class="icon">
              <mat-icon [svgIcon]="'cody-chat'" class="chat-gpt-icon"></mat-icon>
            </div>

            <div class="text">
              <p class="pending blinking-cursor"></p>
            </div>
          </div>

          <div class="right-section"></div>
        </div>
      </div>
    </div> -->

    <div class="message-box" *ngIf="errorMessage">
      <div class="error-container">
        <div class="error-icon">
          <mat-icon [svgIcon]="'alert-error'" class="middle-icon"></mat-icon>
        </div>

        <p class="text">
          {{ errorMessage }}
        </p>
      </div>
    </div>

    <!--  keep it here, it's used by scroll-to-bottom function  -->
    <div id="bottom-scroll-anchor" class="bottom-scroll-anchor"></div>
  </div>

  <div class="chat-controls">
    <button
      mat-icon-button
      *ngIf="messages.length > 0"
      class="chat-menu-btn-trigger"
      (menuOpened)="isChatMenuOpened = true"
      (menuClosed)="isChatMenuOpened = false"
      [matMenuTriggerFor]="chatMenu"
    >
      <mat-icon
        [svgIcon]="'dashes-icon'"
        [ngClass]="{ 'active': isChatMenuOpened }"
      ></mat-icon>
    </button>

    <mat-menu #chatMenu="matMenu" class="chat-menu">
      <div class="limitations">
        <div class="limitations-title">
          <mat-icon [svgIcon]="'limit-icon'" class="small-icon"></mat-icon>
          <p>Limitations:</p>
        </div>
        <ul class="limitations-list">
          <li class="limitations-list-item">Limited knowledge of PowerX Optimizer</li>
        </ul>
      </div>

      <mat-divider></mat-divider>

      <button
        mat-button
        class="small-btn secondary-btn secondary-bg chat-menu-btn"
        (click)="clearConversation()"
        *ngIf="messages.length > 0"
      >
        <mat-icon [svgIcon]="'trash_icon'" class="small-icon"></mat-icon>
        <span class="label right-side-title">Clear Conversation</span>
      </button>
    </mat-menu>

    <div class="input-wrapper">
      <mat-form-field class="chat-input" floatLabel="never">
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="12"
          type="text"
          autocomplete="off"
          placeholder="Type your message here"
          class="pxo-style question-input scroll-style"
          [maxlength]="maxQuestionTextLength"
          [formControl]="messageFormControl"
          (input)="onChangeAnswerInput()"
          (keydown.enter)="$event.preventDefault(); sendPrompt()"
        ></textarea>
      </mat-form-field>

      <div class="actions">
        <button
          mat-icon-button
          class="small-btn submit"
          *ngIf="!waitingForResponse"
          (click)="sendPrompt()"
          [disabled]="!isAbleToSubmitOrRegenerate"
        >
          <mat-icon [svgIcon]="'send-icon'"></mat-icon>
        </button>

        <div class="typing-dots" *ngIf="waitingForResponse">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #circularProgress>
  <div class="spinner-container">
    <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
  </div>
</ng-template>
