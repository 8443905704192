export enum TradingLogStreamingServiceCommandType {
  RequestGroups = 'list_groups',
  UpdateGroup = 'update_group',
  DeleteGroup = 'delete_group',
  RequestTransactions = 'list_transactions',
  BathTransactions = 'batch_transactions',
  UpdateTransaction = 'update_transaction',
  DeleteTransaction = 'delete_transaction',
  RequestStrategies = 'list_strategies',
  UpdateStrategy = 'update_strategy',
  DeleteStrategy = 'delete_strategy',
  RequestAccounts = 'list_accounts',
  UpdateAccount = 'update_account',
  DeleteAccount = 'delete_account',
  Import = 'import'
}
