import { DEFAULT_HEATMAP_FILTERS, DataChannelCommands } from '@const';
import { IHeatmapSettings } from '@c/stock-heatmap/stock-heatmap.model';

export const indexOptions = {
  Sp500: 'Sp500',
  DowJones: 'DowJones',
  Nasdaq: 'Nasdaq',
  CompositeIndex: 'CompositeIndex',
} as const;

export const marketTimeOptions = {
  isPreMarket: 'isPreMarket',
  isMarket: 'isMarket',
  isPostMarket: 'isPostMarket',
} as const;

export const MARKET_PERFORMANCE_OPTIONS = [
  { id: marketTimeOptions.isMarket, name: 'Day Performance' },
  { id: marketTimeOptions.isPreMarket, name: 'Pre-market' },
  { id: marketTimeOptions.isPostMarket, name: 'Post-market' },
];

export const SAVE_SETTINGS_KEY = 'heatmapPageSettings';
export const DEFAULT_DATA_CHANNEL_COMMAND = DataChannelCommands.Sp500Heatmap;
export const DEFAULT_MARKET_PERFORMANCE_OPTION = MARKET_PERFORMANCE_OPTIONS[0];

export const MIN_CHART_WIDTH_PX = 400;
export const MIN_CHART_HEIGHT_PX = 240;
export const RECALCULATE_CHART_SIZE_DEBOUNCE_TIME = 100;

export const DEFAULT_ONLY_WITH_WEEKLY_OPTIONS = false;

export const DEFAULT_HEATMAP_SETTINGS: IHeatmapSettings = {
  dataChannelCommand: DEFAULT_DATA_CHANNEL_COMMAND,
  filters: DEFAULT_HEATMAP_FILTERS,
  index: indexOptions.CompositeIndex,
  onlyWithWeeklyOptions: DEFAULT_ONLY_WITH_WEEKLY_OPTIONS,
};
