<h2 mat-dialog-title>
  Buying Power & Positions
  <mat-icon
    [svgIcon]="'hint_icon'"
    [matTooltip]="buyingPositionTooltip"
    class="header-help-icon small-icon"
    [matTooltipPosition]="'below'"
  ></mat-icon>
  <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon"></mat-icon>
  </button>
</h2>

<form [formGroup]="positionSettingForm" (ngSubmit)="updateSetting()" class="form-design">
  <mat-dialog-content class="mat-typography">
    <div class="risk-based-position-sizing">
      <div class="risk-input">

        <div class="per-input">
          <label>Buying Power</label>
          <div class="input-box w-100">
            <div class="percentage">$</div>
            <input
              type="text"
              tabindex="1"
              [inputMask]="buyingPowerInputMask"
              formControlName="buyingPower"
              min="1"
              step="1"
              maxlength="11"
              (focus)="$event.target.select()"
              (blur)="inputBlur('buyingPower')"
              required
            >
          </div>
        </div>

        <div class="icon-input">
          <label>Positions</label>
          <div class="input-box w-100">
            <input
              type="text"
              [inputMask]="positionInputMask"
              formControlName="position"
              maxlength="2"
              tabindex="2"
              step="1"
              required
              min="1"
              max="99"
              (focus)="$event.target.select()"
              (blur)="inputBlur('position')"
            >
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-flat-button color="primary" class="fill-btn small-btn">Set</button>
  </mat-dialog-actions>
</form>
