<app-simple-modal-header
  titleText="Risk Based Position Sizing"
  [tooltipText]="riskPositionTooltip"
></app-simple-modal-header>

<form [formGroup]="positionSettingForm" (ngSubmit)="updateSetting()" class="form-design">
  <div mat-dialog-content class="mat-typography">
    <div class="risk-based-position-sizing">
      <div class="risk-input">
        <div class="dollar-input">
          <div class="v-center">
            <label>Account Size</label>
          </div>
          <div class="input-box w-100">
            <div class="dollar-sign">$</div>
            <input
              id="account-size-input"
              type="text"
              placeholder="0"
              autocomplete="off"
              formControlName="accountSize"
              mask="separator.0"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100000000"
              (focus)="$event.target.select()"
              (input)="updateRiskAmountPerTrade()"
              (click)="$event.target.select()"
              (blur)="inputBlur('accountSize')"
            >
          </div>
        </div>
        <div class="amount-of-accountrisk">
          <div class="v-center">
            <span class="title">Amount of Account Risk</span>
          </div>
          <div class="title light">Add percentage or amount.</div>
          <div class="row">
            <div class="w-50-per">
              <div class="per-input w-100">
                <div class="input-box w-100 full-width">
                  <div class="percentage">%</div>
                  <input
                    id="percentInput"
                    type="text"
                    autocomplete="off"
                    formControlName="accountRiskPercent"
                    mask="separator.1"
                    thousandSeparator=","
                    decimalMarker="."
                    [leadZero]="true"
                    (keyup)="updateRiskAmountPerTrade($event)"
                    (click)="$event.target.select()"
                    (blur)="inputBlur('accountRiskPercent')"
                    oninput="javascript: if (this.value > 100) this.value = 100"
                  >
                </div>
              </div>
            </div>
            <div class="w-50-per">
              <div class="dollar-input w-100">
                <div class="input-box w-100 full-width">
                  <div class="dollar-sign">$</div>
                  <input
                    type="text"
                    formControlName="riskAmountPerTrade"
                    placeholder="0"
                    autocomplete="off"
                    mask="separator.0"
                    thousandSeparator=","
                    decimalMarker="."
                    separatorLimit="100000000"
                    (input)="updateRiskPercent()"
                    (click)="$event.target.select()"
                    (blur)="inputBlur('riskAmountPerTrade')"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-container" *ngIf="showCanadaWarning">
        <mat-icon [svgIcon]="'warning'" class="icon xl-icon"></mat-icon>
        <div class="text">
          <p>If you are trading CAD stocks, we are currently assuming an exchange rate 1:1.</p>
          <p>This means that for Canadian stocks we assume a risk of 200 CAD if you enter 200.</p>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <button mat-flat-button color="primary" class="action-btn">Set</button>
  </div>
</form>
