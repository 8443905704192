import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CircularProgressModalModel } from '@mod/common/circular-progress-modal.model';

@Component({
  selector: 'app-print-data-modal',
  templateUrl: './circular-progress-modal.component.html',
  styleUrls: ['./circular-progress-modal.component.scss']
})
export class CircularProgressModalComponent {
  constructor(
    private dialogRef: MatDialogRef<CircularProgressModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: Partial<CircularProgressModalModel>,
  ) {
  }

  protected close(): void {
    this.dialogRef.close();
  }
}
