import { DividendsPaymentPeriods } from '@mod/dividends-strategy/dividends-strategy.model';
import { IMarketCapValue } from '@mod/filters/market-cap-filter.model';
import { IVolumeValue } from '@mod/filters/volume-filter.model';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';

export enum ExDatePeriodOptions {
  NextDay = 'NextDay',
  Next5Days = 'Next5Days',
  Next10Days = 'Next10Days',
  Next30Days = 'Next30Days',
  ThisWeek = 'ThisWeek',
  NextWeek = 'NextWeek',
  ThisMonth = 'ThisMonth',
  // NextMonth = 'NextMonth',
  // Custom = 'Custom', // postponed, do not remove
}

export interface ExDatePeriodModel {
  value: ExDatePeriodOptions;
  // for custom period
  details: { from: string; to: string };
}

export interface DividendsStrategyScannerFilterModel {
  flags: Flags[];
  divPerShareFrom: string;
  divPerShareTo: string;
  divYieldPercentFrom: string;
  divYieldPercentTo: string;
  stockPriceFrom: string;
  stockPriceTo: string;
  breakEvenPeriodYears: number;
  avgBreakEvenDaysFrom: string;
  avgBreakEvenDaysTo: string;
  roiFrom: string;
  roiTo: string;
  volumeFrom: IVolumeValue;
  volumeTo: IVolumeValue;
  marketCapFrom: IMarketCapValue;
  marketCapTo: IMarketCapValue;
  dividendPaymentPeriodOptions: DividendsPaymentPeriods[];
  exDatePeriod: ExDatePeriodModel;
}

export enum DividendsStrategyFiltersTextInputKeys {
  DivPerShareFrom = 'divPerShareFrom',
  DivPerShareTo = 'divPerShareTo',
  DivYieldPercentFrom = 'divYieldPercentFrom',
  DivYieldPercentTo = 'divYieldPercentTo',
  StockPriceFrom = 'stockPriceFrom',
  StockPriceTo = 'stockPriceTo',
  AvgBreakEvenDaysFrom = 'avgBreakEvenDaysFrom',
  AvgBreakEvenDaysTo = 'avgBreakEvenDaysTo',
  RoiFrom = 'roiFrom',
  RoiTo = 'roiTo',
  VolumeFrom = 'volumeFrom',
  VolumeTo = 'volumeTo',
  MarketCapFrom = 'marketCapFrom',
  MarketCapTo = 'marketCapTo',
}

export interface IDividendsStrategyDisplayedFilterValues {
  flags: string;
  divPerShare: string;
  stockPrice: string;
  breakEvenPeriodYears: string;
  divYieldPercent: string;
  avgBreakEvenDays: string;
  roi: string;
  volume: string;
  marketCap: string;
  dividendPaymentPeriodOptions: string;
  dividendPaymentPeriodOptionsShort: string;
  exDatePeriod: string;
}

export interface DividendsPaymentPeriodFilterModel {
  id: number;
  title: string;
  value: DividendsPaymentPeriods;
}

export interface ExDatePeriodFilterModel {
  id: number;
  title: string;
  value: ExDatePeriodOptions;
}
