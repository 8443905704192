<div class="new-quick-link-wrapper">
  <div class="new-quick-link-inputs">
    <div class="form-group">
      <mat-form-field appearance="outline" class="new-link-name-field">
        <input
          matInput
          type="text"
          placeholder="Headline"
          maxlength="100"
          (keydown.enter)="$event.preventDefault(); linkTextarea.focus()"
          (keyup)="$event.stopPropagation()"
          [(ngModel)]="newLink.title"
          #headlineInput/>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline" class="new-link-url-field">
        <textarea
          #linkTextarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="15"
          type="text"
          placeholder="Link"
          autocomplete="off"
          spellcheck="false"
          class="pxo-style scroll-style"
          maxLength="2000"
          [(ngModel)]="newLink.url"
          (keydown.shift.enter)="$event.preventDefault()"
          (keydown.enter)="$event.preventDefault(); submit()"
          (keyup)="$event.stopPropagation()"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="new-quick-link-actions">
    <button mat-icon-button class="successful-bg btn-standard " aria-label="add" (click)="submit()">
      <mat-icon class="middle-icon successful icon-add" [svgIcon]="'select_icon'"></mat-icon>
    </button>

    <button mat-icon-button class="danger-bg btn-standard primary-bg" aria-label="cancel" (click)="cancel.emit()">
      <mat-icon class="middle-icon danger icon-cancel" [svgIcon]="'plus'"></mat-icon>
    </button>
  </div>
</div>
