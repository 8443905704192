import { Component, OnInit } from '@angular/core';
import { ChartTabs, getChartTabName, TabletMinWidth, UserSettings } from '../../../constants';
import { UserDataService } from '../../../services/user-data.service';
import { ObservableService } from '../../../services/observable.service';

@Component({
  selector: 'app-chart-menu',
  templateUrl: './chart-menu.component.html',
  styleUrls: [
    './chart-menu.component.scss'
  ],
  host: {
    '(window:resize)': 'onWindowResize($event)'
  },
})
export class ChartMenuComponent implements OnInit {
  chartTab: any = '';
  ChartTabs = ChartTabs;
  getChartTabName = getChartTabName;

  constructor(private userDataService: UserDataService,
    private observableService: ObservableService) { }

  ngOnInit(): void {
    this.chartTab = this.observableService.chartTab.getValue();
    this.onWindowResize();

    this.observableService.chartTab.subscribe(chartTab => this.chartTab = chartTab);
  }

  async changeChartTab(chartTab) {
    await this.userDataService.set(UserSettings.ChartTab, chartTab);
  }

  async onWindowResize(event = null) {
    const newWidth = event ? event.target.innerWidth : window.innerWidth;
    if (newWidth > TabletMinWidth && this.chartTab === ChartTabs.Signal) {
      await this.userDataService.set(UserSettings.ChartTab, ChartTabs.Chart);
    }
  }
}
