import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '@env/environment';
import { Command, CommandResult } from '@mod/trading-panel/trading-panel.model';
import { UserDataService } from './user-data.service';
import { BrokerAuthenticationService } from './broker-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TradingCommandService {
  private _socket: Socket;
  private _socketConnected: boolean = false;
  private _commands: Map<string, Command> = new Map<string, Command>();

  constructor(
    private _userDataService: UserDataService,
    private _brokerAuthenticationService: BrokerAuthenticationService,
  ) {
    const token = this._userDataService.getAuthToken();
    this._socket = io(
      environment.WsBaseUrl,
      { transports: ['websocket'], auth: { token } },
    );

    this._socket.on('connect', () => {
      this._socketConnected = true;

      const commands = new Array<Command>();
      for (const command of Object.values(this._commands)) {
        commands.push({ ...command });
      }

      this._commands = new Map<string, Command>();

      for (const command of commands) {
        this.run(command);
      }
    });

    this._socket.on('pxo_trading_command_result', (result: CommandResult) => {
      const command: Command = this._commands[result.id];
      if (!command) {
        return;
      }

      if (!result.success && !command.handleError) {
        const accessToken = this._brokerAuthenticationService.getTradierAuthenticationData().accessToken;
        
        // If access tokens are the same - fatal error, logout
        if (!result.accessToken || result.accessToken === accessToken) {
          this._brokerAuthenticationService.logout();
        } else {
          // Try to re-subscribe with a new access token
          this.run({
            ...command,
            accessToken,
          });
        }

        return;
      }

      if (command.handler) {
        command.handler(result);
      }

      if (!command.updateIntervalMs) {
        delete this._commands[result.id];
      }
    });
  }

  run(command: Command) {
    this._commands[command.id] = { ...command };

    if (this._socketConnected) {
      const accessToken = command.accessToken ?? this._brokerAuthenticationService.getTradierAuthenticationData().accessToken;

      this._socket.emit('pxo_trading_command', {
        ...this._commands[command.id],
        accessToken
      });
    }
  }

  unsubscribe(commandId: string) {
    if (!commandId) {
      return;
    }

    const command = this._commands[commandId];

    if (!command) {
      return;
    }

    this._socket.emit('pxo_trading_command_unsubscribe', command);
    delete this._commands[commandId];
  }
}
