import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { formatDecimal, formatPrice, MobileWidth, TradePositions, UserSettings, isNullOrUndefined } from '@const';
import { ITradeStrategyReport } from '@core/types';
import { ExchangeModel } from '@mod/data/exchange.model';
import { ExchangesService } from '@s/exchanges.service';
import { ObservableService } from '@s/observable.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradeReportService } from '@s/trade-report.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-trading-report',
  templateUrl: './trading-report.component.html',
  styleUrls: ['./trading-report.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)',
  },
  encapsulation: ViewEncapsulation.None,
})
export class TradingReportComponent implements OnInit {
  @Input() isPrint: boolean;
  @Input() isReportFitOnPrintScreen: boolean;
  symbol: ISymbol;
  exchange: ExchangeModel;

  firstTradingStrategy: any = { id: 0 };
  secondTradingStrategy: any = { id: 0 };
  compareProfit: any = { id: -1 };
  tradeReportTradingStrategyId: any = null;
  tradePosition: any = null;
  firstStrategyTrades;
  secondStrategyTrades;

  tableData;
  maxDays;
  minProfit;
  maxProfit;

  onScrollTopValueFirst;
  onScrollLeftValueFirst;
  onScrollTopValueSecond;
  onScrollLeftValueSecond;
  onScrollTopValueThird;
  onScrollLeftValueThird;

  public width: number = window.innerWidth;
  public isMobile = this.width < MobileWidth;
  public isNullOrUndefined = isNullOrUndefined;

  constructor(
    public userDataService: UserDataService,
    public observableService: ObservableService,
    public tradingStrategiesService: TradingStrategiesService,
    public tradeReportService: TradeReportService,
    private _exchangesService: ExchangesService,
    private _symbolsService: SymbolsService
  ) {
  }

  async ngOnInit() {
    if (this.isPrint) {
      await this.ngInitParent();
    }
  }

  async ngInitParent(): Promise<void> {
    this.isMobile = this.width < MobileWidth;
    const security_id = this.observableService.symbol.getValue();
    await this.setSymbolAndExchange(security_id);

    this.tradeReportTradingStrategyId = this.observableService.tradeReportTradingStrategyId.getValue();
    this.tradePosition = this.observableService.tradePosition.getValue();

    const [firstTradingStrategy, secondTradingStrategy] = await Promise.all([
      this.tradingStrategiesService.getById(this.observableService.firstTradingStrategyId.getValue()),
      this.tradingStrategiesService.getById(this.observableService.secondTradingStrategyId.getValue()),
    ]);

    this.firstTradingStrategy = firstTradingStrategy;
    this.secondTradingStrategy = secondTradingStrategy;

    this.observableService.symbol.subscribe(async (security_id) => {
      await this.setSymbolAndExchange(security_id);
      await this.renderTab();
    });

    this.observableService.firstTradingStrategyId.subscribe(async (firstTradingStrategyId) => {
      [this.firstTradingStrategy] = await Promise.all([this.tradingStrategiesService.getById(firstTradingStrategyId)]);
      await this.checkStrategyName();
    });

    this.observableService.secondTradingStrategyId.subscribe(async (secondTradingStrategyId) => {
      [this.secondTradingStrategy] = await Promise.all([this.tradingStrategiesService.getById(secondTradingStrategyId)]);
      await this.checkStrategyName();
    });

    this.observableService.tradePosition.subscribe(async (tradePosition) => {
      this.tradePosition = tradePosition;
      await this.renderTab();
    });

    await this.renderTab();
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < MobileWidth;
  }

  async changeTradeReportTradingStrategy(tradingStrategyId) {
    await this.userDataService.set(UserSettings.TradeReportTradingStrategyId, tradingStrategyId);
    this.observableService.tradeReportTradingStrategyId.next(tradingStrategyId);
    this.tradeReportTradingStrategyId = tradingStrategyId;
    if (this.tradeReportTradingStrategyId === this.firstTradingStrategy.id) {
      document.getElementById('tradReportHtml').scrollTo(this.onScrollLeftValueFirst, this.onScrollTopValueFirst);
    }
    if (this.tradeReportTradingStrategyId === this.secondTradingStrategy.id) {
      document.getElementById('tradReportHtml').scrollTo(this.onScrollLeftValueSecond, this.onScrollTopValueSecond);
    }
    if (this.tradeReportTradingStrategyId === this.compareProfit.id) {
      document.getElementById('tradReportHtml').scrollTo(this.onScrollLeftValueThird, this.onScrollTopValueThird);
    }
    await this.renderTab();
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.tradeReportTradingStrategyId === this.firstTradingStrategy.id) {
      this.onScrollTopValueFirst = document.getElementById('tradReportHtml').scrollTop;
      this.onScrollLeftValueFirst = document.getElementById('tradReportHtml').scrollLeft;
    }
    if (this.tradeReportTradingStrategyId === this.secondTradingStrategy.id) {
      this.onScrollTopValueSecond = document.getElementById('tradReportHtml').scrollTop;
      this.onScrollLeftValueSecond = document.getElementById('tradReportHtml').scrollLeft;
    }
    if (this.tradeReportTradingStrategyId === this.compareProfit.id) {
      this.onScrollTopValueThird = document.getElementById('tradReportHtml').scrollTop;
      this.onScrollLeftValueThird = document.getElementById('tradReportHtml').scrollLeft;
    }
  }

  async loadReport(strategyId) {
    const tradesObject = await this.tradeReportService.get(this.symbol?.security_id, strategyId);
    let maxDays = 0;

    const trades = Object.values(tradesObject).filter(({ position, duration_days }) => {
      const isMatch = this.tradePosition === TradePositions.LongAndShort || this.tradePosition === position;

      if (isMatch && maxDays < duration_days - 1) maxDays = duration_days - 1;

      return isMatch;
    });

    this.tableData = trades.map((trade: ITradeStrategyReport) => {
      trade.display_entry_date = moment.utc(trade.entry_date).format('YYYY-MM-DD');
      trade.display_exit_date = trade.exit_date ? moment.utc(trade.exit_date).format('YYYY-MM-DD') : '-';
      trade.display_duration =
        trade.duration_days === 0 ? 0 : trade.duration_days || moment.utc().diff(moment.utc(trade.entry_date), 'days');

      let progressBarPercentage = maxDays ? (trade.display_duration * 100) / maxDays : 0;
      progressBarPercentage = progressBarPercentage > 100 ? 100 : progressBarPercentage;
      trade.display_duration_progress = formatDecimal(progressBarPercentage, 2);

      trade.display_shares_count = formatDecimal(trade.shares_count, this.exchange.share_fraction);
      trade.display_position_class = trade.position === TradePositions.LongOnly ? 'long' : 'short';
      trade.display_position = trade.position === TradePositions.LongOnly ? 'Long' : 'Short';
      trade.display_entry_price = formatPrice(trade.entry_price, this.exchange.profit_scale);
      trade.display_stop_loss = formatPrice(trade.stop_loss, this.exchange.profit_scale);
      trade.display_target_profit = formatPrice(trade.target_profit, this.exchange.profit_scale);
      trade.display_exit_price = !isNullOrUndefined(trade.exit_price) ? formatPrice(trade.exit_price, this.exchange.profit_scale) : '-';
      trade.display_total_profit_class = trade.total_profit >= 0 ? 'green' : 'red';
      trade.display_total_profit = trade.total_profit !== null ? formatPrice(trade.total_profit, this.exchange.profit_scale) : '-';
      trade.display_total_profit_progress =
        trade.total_profit < 0
          ? Math.abs((trade.exit_price - trade.entry_price) / (trade.stop_loss - trade.entry_price)) * 100
          : ((trade.exit_price - trade.entry_price) / (trade.target_profit - trade.entry_price)) * 100;
      return trade;
    });
    if (this.isPrint) {
      return trades;
    }
    const tradesDictionary = {};
    trades.map((o: ITradeStrategyReport) => {
      tradesDictionary[o.display_entry_date] = o;
    });
    return tradesDictionary;
  }

  async renderTab(): Promise<void> {
    if (this.tradeReportTradingStrategyId === this.compareProfit.id || this.isPrint) {
      [this.firstStrategyTrades, this.secondStrategyTrades] = await Promise.all([
        this.loadReport(this.firstTradingStrategy.id),
        this.loadReport(this.secondTradingStrategy.id),
      ]);
    } else {
      await this.loadReport(this.tradeReportTradingStrategyId);
    }
  }

  async checkStrategyName(): Promise<void> {
    if (
      this.tradeReportTradingStrategyId !== this.firstTradingStrategy.id &&
      this.tradeReportTradingStrategyId !== this.secondTradingStrategy.id &&
      this.tradeReportTradingStrategyId !== this.compareProfit.id
    ) {
      this.tradeReportTradingStrategyId = this.firstTradingStrategy.id;
    }
    await this.renderTab();
  }

  private async setSymbolAndExchange(security_id: number): Promise<void> {
    this.symbol = await this._symbolsService.getById(security_id);
    this.exchange = await this._exchangesService.getById(this.symbol?.exchange_id);
  }
}

@Component({
  selector: 'app-trading-report-web',
  templateUrl: './trading-report-web.component.html',
  styleUrls: ['./trading-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebTradingReportComponent extends TradingReportComponent implements OnInit {
  tableData;

  constructor(
    userDataService: UserDataService,
    observableService: ObservableService,
    tradingStrategiesService: TradingStrategiesService,
    tradeReportService: TradeReportService,
    exchangesService: ExchangesService,
    symbolsService: SymbolsService
  ) {
    super(userDataService, observableService, tradingStrategiesService, tradeReportService, exchangesService, symbolsService);
  }

  async ngOnInit() {
    await super.ngInitParent();
  }
}

@Component({
  selector: 'app-trading-report-mobile',
  templateUrl: './trading-report-mobile.component.html',
  styleUrls: ['./trading-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileTradingReportComponent extends TradingReportComponent implements OnInit {
  tableData;

  constructor(
    userDataService: UserDataService,
    observableService: ObservableService,
    tradingStrategiesService: TradingStrategiesService,
    tradeReportService: TradeReportService,
    exchangesService: ExchangesService,
    symbolsService: SymbolsService
  ) {
    super(userDataService, observableService, tradingStrategiesService, tradeReportService, exchangesService, symbolsService);
  }

  async ngOnInit() {
    await super.ngInitParent();
  }
}
