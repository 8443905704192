import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, from, of, ReplaySubject, Subject, timer } from 'rxjs';
import { delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as moment from 'moment';

import { DialogsService } from '@s/common';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { MomentDateTimeFormats, tradingHubModalID, TradingHubTabs, UserSettings } from '@const';
import { calculateProgress } from '@c/trading-hub/trading-hub.helpers';
import { ITradingHubEvent, TaskStatuses } from '@c/trading-hub/trading-hub.model';

// specific for trading-hub module, do not reuse in other modules
@Injectable({ providedIn: 'root' })
export class TradingHubService {
  public preTradingProgress$ = new ReplaySubject<number>(1);
  public preTradingChecklistEvents$ = new Subject<ITradingHubEvent>();

  constructor(
    private dialog: MatDialog,
    private observableService: ObservableService,
    private dialogsService: DialogsService,
    private userDataService: UserDataService,
  ) {
    this.preTradingProgress$.next(0); // set initial value in any case
    this.updatePreTradingProgress();

    timer(1000, 1000)
      .pipe(
        withLatestFrom(
          this.observableService.preTradingChecklistOpenOnAppStart,
          this.observableService.preTradingChecklistTasks,
        ),
        filter(([t, isOpen]) => isOpen),
        filter(() => moment().format(MomentDateTimeFormats.FullTime) === '00:00:00'),
        delay(1000),
        switchMap(([t, isOpen, tasks]) => {
          const dialogRef = this.dialog.getDialogById(tradingHubModalID);

          // do not open modal and update tasks if it's already opened
          if (dialogRef) {
            // if modal is already opened - set lastOpenDate to new day to avoid auto-opening after next page-reload
            const newLastOpenDate = Date.now();
            this.observableService.tradingHubLastOpenDate.next(newLastOpenDate);

            return this.userDataService.set(UserSettings.TradingHubLastOpenDate, newLastOpenDate);
          }

          // can be null, ts-issue
          const updatedTasks = tasks
            ? tasks.map((task) => ({ ...task, status: TaskStatuses.notCompleted }))
            : null;

          this.preTradingProgress$.next(0);
          this.observableService.tradingHubTab.next(TradingHubTabs.Checklist);
          this.observableService.preTradingChecklistTasks.next(updatedTasks);

          return Promise.all([
            this.dialogsService.openTradingHubModal(),
            this.userDataService.set(UserSettings.PreTradingChecklistTasks, updatedTasks),
          ]);
        })
      )
      .subscribe();
  }

  public updatePreTradingProgress(): void {
    this.observableService.preTradingChecklistTasks
      .pipe(take(1))
      .subscribe((tasks) => {
        this.preTradingProgress$.next(calculateProgress(tasks));
      });
  }

  public checkAndOpenTradingHubModal(): void {
    // TODO: take into account maintenance and cron pages
    combineLatest([
      this.observableService.preTradingChecklistOpenOnAppStart,
      this.observableService.tradingHubLastOpenDate,
      this.observableService.preTradingChecklistTasks,
    ])
      .pipe(
        take(1),
        map(([isOpen, lastOpenDate, tasks]) => {
          if (isOpen === false || !lastOpenDate) {
            return false;
          }

          const current = moment();
          const lastOpen = moment(lastOpenDate);
          const isNextDay = lastOpen.clone()
            .startOf('day')
            .isBefore(current.clone().startOf('day'));

          // set tasks as not-completed if it's next day after last-open-time day
          if (isNextDay) {
            const updatedTasks = tasks.map((task) => ({ ...task, status: TaskStatuses.notCompleted }));

            this.observableService.preTradingChecklistTasks.next(updatedTasks);
            this.preTradingProgress$.next(0);

            // ignore returned from Promise value
            this.userDataService.set(UserSettings.PreTradingChecklistTasks, updatedTasks);
          }

          // return true if it's a new day and isOpen === true
          return isNextDay && isOpen;
        }),
        filter((isOpen) => isOpen),
        tap(() => {
          this.observableService.tradingHubTab.next(TradingHubTabs.Checklist);
          this.userDataService.set(UserSettings.TradingHubTab, 0);
        }),
        delay(3000),
        switchMap(() => {
          if (this.dialog.getDialogById(tradingHubModalID)) {
            return of (null);
          }

          return from(this.dialogsService.openTradingHubModal());
        })
      )
      .subscribe();
  }
}
