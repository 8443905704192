import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LocalStorageService } from '@s/local-storage.service';
import { LowestHighestCloseLineIndicatorOptions } from '@const';
import { DeepWriteable, RawStudyMetaInfoId, StudyPlotInformation } from '@chart/charting_library';

const BlueIndex = 0;
const OrangeIndex = 1;
const PurpleIndex = 2;

@Injectable()
export class JanIndicator {
  constructor(
    private getLowestHighestOption: Function,
    private localStorageService: LocalStorageService
  ) {
  }

  ploat(PineJS, getHistoricalDataStorageKey) {
    const getLowestHighestOption = this.getLowestHighestOption;
    const localStorageService = this.localStorageService;

    return {
      name: 'Jan\'s indicator',
      metainfo: {
        _metainfoVersion: 40,
        id: 'JanIndicator@tv-basicstudies-1' as RawStudyMetaInfoId,
        scriptIdPart: '',
        name: 'Jan\'s indicator',
        format: {
          type: 'price' as DeepWriteable<'price'>,
          precision: 2
        },

        // This description will be displayed in the Indicators window
        // It is also used as a "name" argument when calling the createStudy method
        description: 'Jan\'s indicator',

        // This description will be displayed on the chart
        shortDescription: 'Lowest / Highest Close Line',

        is_hidden_study: true,
        is_price_study: true,
        isCustomIndicator: true,

        plots: [{
          id: 'plot_0',
          type: 'line'
        }, {
          id: 'plot_1',
          type: 'colorer',
          target: 'plot_0',
          palette: 'paletteId1',
        }, {
          id: 'plot_2',
          type: 'line'
        }, {
          id: 'plot_3',
          type: 'colorer',
          target: 'plot_2',
          palette: 'paletteId1',
        }] as DeepWriteable<Readonly<StudyPlotInformation>>[],
        palettes: {
          paletteId1: {
            colors: {
              0: {
                name: 'Blue line',
              },
              1: {
                name: 'Orange line',
              },
              2: {
                name: 'Purple line',
              },
            },
          },
        },
        defaults: {
          palettes: {
            paletteId1: {
              colors: {
                0: {
                  color: '#0E74DC',
                  width: 2,
                  style: 0,
                },
                1: {
                  color: '#FF9800',
                  width: 2,
                  style: 0,
                },
                2: {
                  color: '#9C27B0',
                  width: 2,
                  style: 0,
                },
              },
            },
          },
          styles: {
            plot_0: {
              linestyle: 0,
              visible: true,
              linewidth: 2,
              plottype: 2,
              // Show price line?
              trackPrice: false,
              // Plot transparency, in percent.
              transparency: 40
            }, plot_2: {
              linestyle: 0,
              visible: true,
              linewidth: 2,
              plottype: 2,
              // Show price line?
              trackPrice: false,
              // Plot transparency, in percent.
              transparency: 40
            }
          },

          // Precision of the study's output values
          // (quantity of digits after the decimal separator).
          precision: 2,

          inputs: {},
        },
        styles: {
          plot_0: {
            // Output name will be displayed in the Style window
            title: '-- output name --',
            histogramBase: 0,
          }
        },
        inputs: [],
      },

      constructor: function () {
        this.init = function (context, inputCallback) {
          this._input = inputCallback;

          const historicalData = localStorageService.getFromMemory(getHistoricalDataStorageKey());

          if (!historicalData) {
            return;
          }

          const highLength = 20;
          const lowLength = 40;

          const highestRateOfChangeHistoryIndex = Math.round(highLength / 2) + 1;
          const lowestRateOfChangeHistoryIndex = Math.round(lowLength / 2) + 1;

          const closes = [];
          const highestCloses = [];
          const lowestCloses = [];
          const roundedHighestCloses = [];
          const roundedLowestCloses = [];

          const getNormalizedClose = (close: number) => {
            const rounding = close < 100
              ? 0.5
              : close < 150
                ? 1.0
                : close < 3000
                  ? 2.5
                  : 5.0

            return Math.round(close / rounding) * rounding;
          };

          const getHigestClose = (close: number) => {
            highestCloses.push(getNormalizedClose(close));

            while (highestCloses.length > highLength) {
              highestCloses.shift();
            }

            return Math.max(...highestCloses);
          };

          const getLowsetClose = (close: number) => {
            lowestCloses.push(getNormalizedClose(close));

            while (lowestCloses.length > lowLength) {
              lowestCloses.shift();
            }

            return Math.min(...lowestCloses);
          };

          const getHighestColor = () => {
            if (roundedHighestCloses.length > 1
              && roundedHighestCloses[roundedHighestCloses.length - 1] > roundedHighestCloses[roundedHighestCloses.length - 2]) {
              return PurpleIndex;
            }

            const index = closes.length - highestRateOfChangeHistoryIndex;

            if (index >= 0 && index < closes.length) {
              const rateOfChange = closes[closes.length - 1] - closes[index];

              return rateOfChange > 0
                ? BlueIndex
                : OrangeIndex;
            }

            return null;
          };

          const getLowestColor = () => {
            if (roundedLowestCloses.length > 1
              && roundedLowestCloses[roundedLowestCloses.length - 1] < roundedLowestCloses[roundedLowestCloses.length - 2]) {
              return PurpleIndex;
            }

            const index = closes.length - lowestRateOfChangeHistoryIndex;

            if (index >= 0 && index < closes.length) {
              const rateOfChange = closes[closes.length - 1] - closes[index];

              return rateOfChange >= 0
                ? BlueIndex
                : OrangeIndex;
            }

            return null;
          };

          this.processedData = {};

          for (const item of historicalData) {
            const { date, close } = item;

            closes.push(close);

            const highestClose = getHigestClose(close);
            const lowestClose = getLowsetClose(close);

            roundedHighestCloses.push(highestClose);
            roundedLowestCloses.push(lowestClose);

            const highestColor = getHighestColor();
            const lowestColor = getLowestColor();

            this.processedData[moment.utc(date).format('YYYY-MM-DD')] = {
              ...item,
              highestClose,
              lowestClose,
              highestColor,
              lowestColor
            };
          }
        };

        this.main = function (context, inputCallback) {
          const barDate = moment(PineJS.Std.time(context)).tz('America/New_York');

          if (!barDate.isValid() || !this.processedData) {
            return;
          }

          const item = this.processedData[barDate.format('YYYY-MM-DD')];
          const selectedOption = getLowestHighestOption();

          if (item) {
            const { highestClose, lowestClose, highestColor, lowestColor } = item;

            if (selectedOption === LowestHighestCloseLineIndicatorOptions.Both) {
              return [lowestClose, lowestColor, highestClose, highestColor];
            } else if (selectedOption === LowestHighestCloseLineIndicatorOptions.LowestLine) {
              return [lowestClose, lowestColor, null, null];
            } else if (selectedOption === LowestHighestCloseLineIndicatorOptions.HighestLine) {
              return [null, null, highestClose, highestColor];
            } else if (selectedOption === LowestHighestCloseLineIndicatorOptions.None) {
              return [null, null, null, null];
            }
          }

          return [];
        }
      }
    }
  }
}
