export class CompareDataWindow {

  selectOptionData = async (value, data) => {
    const temp: any = {};
    if (value.first === 'exit1530' && Object.keys(data).length > 0 && 'exit1530' in data) {
      temp.first = data.exit1530;
      temp.first.type = 'Conservative';
      temp.first.risk = '1.5';
      temp.first.reward = '3.0';
    } else if (value.first === 'exit1545' && Object.keys(data).length > 0 && 'exit1545' in data) {
      temp.first = data.exit1545;
      temp.first.type = 'Quick Trades';
      temp.first.risk = '1.5';
      temp.first.reward = '4.5';
    } else if (value.first === 'exit1575' && Object.keys(data).length > 0 && 'exit1575' in data) {
      temp.first = data.exit1575;
      temp.first.type = 'Aggressive';
      temp.first.risk = '1.5';
      temp.first.reward = '7.5';
    } else if (value.first === 'exit2525' && Object.keys(data).length > 0 && 'exit2525' in data) {
      temp.first = data.exit2525;
      temp.first.type = 'Balanced';
      temp.first.risk = '2.5';
      temp.first.reward = '2.5';
    } else if (value.first === 'exit2550' && Object.keys(data).length > 0 && 'exit2550' in data) {
      temp.first = data.exit2550;
      temp.first.type = 'M&M Favorite';
      temp.first.risk = '2.5';
      temp.first.reward = '5.0';
    } else if (value.first === 'exit2575' && Object.keys(data).length > 0 && 'exit2575' in data) {
      temp.first = data.exit2575;
      temp.first.type = 'Position Trader';
      temp.first.risk = '1.5';
      temp.first.reward = '7.5';
    }

    if (value.second === 'exit1530' && Object.keys(data).length > 0 && 'exit1530' in data) {
      temp.second = data.exit1530;
      temp.second.type = 'Conservative';
      temp.second.risk = '1.5';
      temp.second.reward = '3.0';
    } else if (value.second === 'exit1545' && Object.keys(data).length > 0 && 'exit1545' in data) {
      temp.second = data.exit1545;
      temp.second.type = 'Quick Trades';
      temp.second.risk = '1.5';
      temp.second.reward = '4.5';
    } else if (value.second === 'exit1575' && Object.keys(data).length > 0 && 'exit1575' in data) {
      temp.second = data.exit1575;
      temp.second.type = 'Aggressive';
      temp.second.risk = '1.5';
      temp.second.reward = '7.5';
    } else if (value.second === 'exit2525' && Object.keys(data).length > 0 && 'exit2525' in data) {
      temp.second = data.exit2525;
      temp.second.type = 'Balanced';
      temp.second.risk = '2.5';
      temp.second.reward = '2.5';
    } else if (value.second === 'exit2550' && Object.keys(data).length > 0 && 'exit2550' in data) {
      temp.second = data.exit2550;
      temp.second.type = 'M&M Favorite';
      temp.second.risk = '2.5';
      temp.second.reward = '5.0';
    } else if (value.second === 'exit2575' && Object.keys(data).length > 0 && 'exit2575' in data) {
      temp.second = data.exit2575;
      temp.second.type = 'Position Trader';
      temp.second.risk = '1.5';
      temp.second.reward = '7.5';
    }
    return temp;
  }

  getSelectedOptionNameValue = () => {
    const selectedOption = JSON.parse(localStorage.getItem('comparePosition'));
    const temp: any = {
      first: {},
      second: {}
    };
    if (selectedOption.first === 'exit1530') {
      temp.first.name = 'Conservative';
      temp.first.keyValue = 'exit1530';
      temp.first.indexPosition = 1530;
      temp.first.value = '1.5 / 3.0';
      temp.first.dbTableName = 'exit_1530';
    } else if (selectedOption.first === 'exit1545') {
      temp.first.name = 'Quick Trades';
      temp.first.keyValue = 'exit1545';
      temp.first.value = '1.5 / 4.5';
      temp.first.indexPosition = 1545;
      temp.first.dbTableName = 'exit_1545';
    } else if (selectedOption.first === 'exit1575') {
      temp.first.name = 'Aggressive';
      temp.first.keyValue = 'exit1575';
      temp.first.value = '1.5 / 7.5';
      temp.first.indexPosition = 1575;
      temp.first.dbTableName = 'exit_1575';
    } else if (selectedOption.first === 'exit2525') {
      temp.first.name = 'Balanced';
      temp.first.value = '2.5 / 2.5';
      temp.first.keyValue = 'exit2525';
      temp.first.indexPosition = 2525;
      temp.first.dbTableName = 'exit_2525';
    } else if (selectedOption.first === 'exit2550') {
      temp.first.name = 'M&M Favorite';
      temp.first.keyValue = 'exit2550';
      temp.first.value = '2.5 / 5.0';
      temp.first.indexPosition = 2550;
      temp.first.dbTableName = 'exit_2550';
    } else if (selectedOption.first === 'exit2575') {
      temp.first.name = 'Position Trader';
      temp.first.keyValue = 'exit2575';
      temp.first.value = '2.5 / 7.5';
      temp.first.indexPosition = 2575;
      temp.first.dbTableName = 'exit_2575';
    }

    if (selectedOption.second === 'exit1530') {
      temp.second.name = 'Conservative';
      temp.second.keyValue = 'exit1530';
      temp.second.indexPosition = 1530;
      temp.second.value = '1.5 / 3.0';
      temp.second.dbTableName = 'exit_1530';
    } else if (selectedOption.second === 'exit1545') {
      temp.second.name = 'Quick Trades';
      temp.second.keyValue = 'exit1545';
      temp.second.value = '1.5 / 4.5';
      temp.second.indexPosition = 1545;
      temp.second.dbTableName = 'exit_1545';
    } else if (selectedOption.second === 'exit1575') {
      temp.second.name = 'Aggressive';
      temp.second.keyValue = 'exit1575';
      temp.second.value = '1.5 / 7.5';
      temp.second.indexPosition = 1575;
      temp.second.dbTableName = 'exit_1575';
    } else if (selectedOption.second === 'exit2525') {
      temp.second.name = 'Balanced';
      temp.second.value = '2.5 / 2.5';
      temp.second.keyValue = 'exit2525';
      temp.second.indexPosition = 2525;
      temp.second.dbTableName = 'exit_2525';
    } else if (selectedOption.second === 'exit2550') {
      temp.second.name = 'M&M Favorite';
      temp.second.keyValue = 'exit2550';
      temp.second.value = '2.5 / 5.0';
      temp.second.indexPosition = 2550;
      temp.second.dbTableName = 'exit_2550';
    } else if (selectedOption.second === 'exit2575') {
      temp.second.name = 'Position Trader';
      temp.second.keyValue = 'exit2575';
      temp.second.value = '2.5 / 7.5';
      temp.second.indexPosition = 2575;
      temp.second.dbTableName = 'exit_2575';
    }
    return temp;
  }

  setProfitLossValue = (variant) => {
    const temp: any = {};

    if (variant === 'exit1530') {
      temp.schema = 'exit_1530';
      temp.schemaName = 'Conservative';
    } else if (variant === 'exit1545') {
      temp.schema = 'exit_1545';
      temp.schemaName = 'Quick Trades';
    } else if (variant === 'exit1575') {
      temp.schema = 'exit_1575';
      temp.schemaName = 'Aggressive';
    } else if (variant === 'exit2525') {
      temp.schema = 'exit_2525';
      temp.schemaName = 'Balanced';
    } else if (variant === 'exit2550') {
      temp.schema = 'exit_2550';
      temp.schemaName = 'M&M Favorite';
    } else if (variant === 'exit2575') {
      temp.schema = 'exit_2575';
      temp.schemaName = 'Position Trader';
    }

    return temp;
  }
}
