import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  formatDecimal,
  formatNumber,
  formatPrice,
  getDecimalSharesCount,
  getPastPerformancePropertyName,
  getSharesCount,
  Signals,
  TradePositions,
} from '@const';
import { ExchangeModel } from '@mod/data/exchange.model';
import { ExchangesService } from '@s/exchanges.service';
import { ObservableService } from '@s/observable.service';
import { IPastPerformance, PastPerformanceService } from '@s/past-performance.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradeReportService } from '@s/trade-report.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ColumnElement {
  strategy_name: any;
  risk_ratio: any;
  reward_ratio: any;
}

export interface Element {
  highlighted?: boolean;
}

type ICompareData = IPastPerformance;

interface ICompareDataToDisplay extends ICompareData {
  display_roi?: any;
  display_wins_percent?: any;
  display_profit_factor?: any;
  display_avg_trade_days?: any;
  display_longest_trade_days?: any;
  display_position?: string;
  display_shares?: string;
  display_stop_loss?: string;
  display_stop_loss_description?: string;
  display_target_profit?: string;
  display_total_profit?: string;
  display_target_profit_description?: string;
  display_buying_power?: string;
  current_signal?: string;
  display_wins?: number;
  display_losses?: number;
  profits$?: Observable<ReadonlyArray<number>>;
}

@Component({
  selector: 'app-position-compare-popup',
  templateUrl: './position-compare-popup.component.html',
  styleUrls: ['./position-compare-popup.component.scss'],
})
export class PositionComparePopupComponent implements OnInit, OnDestroy {
  formatDecimal = formatDecimal;
  displayedColumns = [
    'select',
    'type',
    'signal',
    'shares',
    'stopLoss',
    'profitTarget',
    'profitAndLoss',
    'total_profit',
    'roi',
    'lossesWins',
    'winPercent',
    'profitFactor',
    'longest',
  ];

  symbol: ISymbol = null;
  exchange: ExchangeModel = null;

  firstTradingStrategyId: any = null;
  secondTradingStrategyId: any = null;
  accountRiskAmount: any = null;

  selection = new SelectionModel<ColumnElement>(true, []);
  selectedPositions = [];
  dataSource: MatTableDataSource<ICompareDataToDisplay>;
  maxTotalProfit;
  maxRoi;
  maxWinPercent;
  maxProfitFactor;
  maxLongestTrade;

  tradePosition: TradePositions;

  totalProfitKey = '';
  roiKey = '';
  winPercentKey = '';
  profitFactorKey = '';

  constructor(
    private dialogRef: MatDialogRef<PositionComparePopupComponent>,
    private pastPerformanceService: PastPerformanceService,
    private tradingStrategiesService: TradingStrategiesService,
    private observableService: ObservableService,
    private _exchangesService: ExchangesService,
    private _symbolsService: SymbolsService,
    private _tradeReportService: TradeReportService
  ) {}

  async ngOnInit(): Promise<void> {
    this.symbol = await this._symbolsService.getById(this.observableService.symbol.getValue());
    this.firstTradingStrategyId = this.observableService.firstTradingStrategyId.getValue();
    this.secondTradingStrategyId = this.observableService.secondTradingStrategyId.getValue();
    this.accountRiskAmount = this.observableService.accountRiskAmount.getValue();
    this.tradePosition = this.observableService.tradePosition.getValue();

    this.exchange = await this._exchangesService.getById(this.symbol?.exchange_id);
    await this.loadData();
    this.observableService.tradePosition.subscribe(this.onTradePositionUpdate);
  }

  ngOnDestroy() {}

  getProgressColor(num: number) {
    return `var(--${num > 0 ? 'green' : 'red'}-light-bg)`;
  }

  async loadData() {
    const pastPerformance = await this.pastPerformanceService.compareStrategies(this.symbol.security_id);

    this.dataSource = new MatTableDataSource(pastPerformance as ICompareDataToDisplay[]);
    this.selectedPositions = [];

    let maxTotalProfit = 0;
    let maxRoi = 0;
    let maxWinPercent = 0;
    let maxProfitFactor = 0;
    let maxLongestTrade = 0;

    this.dataSource.data.forEach((value: ICompareDataToDisplay) => {
      if (value.strategy_id === this.firstTradingStrategyId || value.strategy_id === this.secondTradingStrategyId) {
        this.selection.select(value);
        this.selectedPositions.push(value);
      }

      this.updateCurrentSignal(value);
      this.updateDisplayPosition(value);
      this.updateDisplayPastPerformance(value);

      const { longest_trade_days } = value;

      const [win_percent, roi, profit_factor] = this.getProperties(['win_percent', 'roi', 'profit_factor'], value) as number[];

      if (maxTotalProfit < Math.abs(value[this.totalProfitKey])) maxTotalProfit = Math.abs(value[this.totalProfitKey]);
      if (maxRoi < Math.abs(roi)) maxRoi = Math.abs(roi);
      if (maxWinPercent < Math.abs(win_percent)) maxWinPercent = Math.abs(win_percent);
      if (maxProfitFactor < Math.abs(profit_factor)) maxProfitFactor = Math.abs(profit_factor);
      if (maxLongestTrade < Math.abs(longest_trade_days)) maxLongestTrade = Math.abs(longest_trade_days);

      value.profits$ = from(this._tradeReportService.get(this.symbol.security_id, value.strategy_id)).pipe(
        map((tradesObject: Object) =>
          Object.values(tradesObject)
            .filter(({ position }) => this.tradePosition === TradePositions.LongAndShort || this.tradePosition === position)
            .map((value) => value?.total_profit || 0)
        )
      );
    });

    this.maxTotalProfit = maxTotalProfit;
    this.maxRoi = maxRoi;
    this.maxWinPercent = maxWinPercent;
    this.maxProfitFactor = maxProfitFactor;
    this.maxLongestTrade = maxLongestTrade;
  }

  updateCurrentSignal(value: ICompareDataToDisplay) {
    const { signal, signal_entry_price } = value;
    value.current_signal = 'NONE';
    if (signal === Signals.BTO) {
      value.current_signal = 'BUY to OPEN';
    } else if (signal === Signals.STO) {
      value.current_signal = 'SELL to OPEN';
    } else if (signal === Signals.BTC) {
      value.current_signal = 'BUY to CLOSE';
    } else if (signal === Signals.STC) {
      value.current_signal = 'SELL to CLOSE';
    }

    if (signal === Signals.BTO || signal === Signals.STO) {
      value.current_signal += ' ' + formatPrice(signal_entry_price, this.exchange.profit_scale);
    }
  }

  updateDisplayPosition(value: ICompareDataToDisplay) {
    const {
      risk_ratio,
      reward_ratio,
      target_profit,
      stop_loss,
      target_profit_amount,
      profit_loss_multiplier,
      signal_stop_loss,
      signal_profit_loss_multiplier,
      signal_target_profit,
      signal_target_profit_amount,
      position,
      entry_price,
      stop_loss_amount,
      signal,
      signal_stop_loss_amount,
      signal_entry_price,
    } = value;

    const stopLossValue = signal_stop_loss || stop_loss;
    const targetProfitValue = signal_target_profit || target_profit;

    const formattedProfitLossMultiplierValue = formatDecimal(
      signal_profit_loss_multiplier || profit_loss_multiplier,
      this.exchange.profit_scale
    );

    value.display_position = 'NONE';
    value.display_shares = '-';
    value.display_stop_loss = '-';
    value.display_stop_loss_description = '';
    value.display_target_profit = '-';
    value.display_target_profit_description = '';

    if (position || signal) {
      if (position) {
        value.display_position = position === TradePositions.LongOnly ? 'LONG' : 'SHORT';
        value.display_position += ` ${formatPrice(entry_price, this.exchange.profit_scale)}`;
      }
      if (![Signals.STC, Signals.BTC].includes(signal)) {
        value.display_shares =
          this.exchange.share_fraction !== null
            ? formatDecimal(
                getDecimalSharesCount(this.accountRiskAmount, signal_stop_loss_amount || stop_loss_amount),
                this.exchange.share_fraction
              )
            : formatNumber(getSharesCount(this.accountRiskAmount, signal_stop_loss_amount || stop_loss_amount));

        value.display_stop_loss = formatPrice(stopLossValue, this.exchange.profit_scale);
        value.display_stop_loss_description = `${formatDecimal(
          signal_stop_loss_amount || stop_loss_amount,
          this.exchange.profit_scale
        )} (${formatDecimal(risk_ratio, 1)} x ${formattedProfitLossMultiplierValue}) = ${formatDecimal(
          stopLossValue,
          this.exchange.profit_scale
        )}`;

        value.display_target_profit = formatPrice(targetProfitValue, this.exchange.profit_scale);
        value.display_target_profit_description = `${formatDecimal(
          signal_target_profit_amount || target_profit_amount,
          this.exchange.profit_scale
        )} (${formatDecimal(reward_ratio, 1)} x ${formattedProfitLossMultiplierValue}) = ${formatDecimal(
          targetProfitValue,
          this.exchange.profit_scale
        )}`;
      }

      const entryPrice = signal_entry_price || entry_price;
      const stopLossAmount = signal_stop_loss_amount || stop_loss_amount;

      const sharesCount = this.exchange?.share_fraction
        ? getDecimalSharesCount(this.accountRiskAmount, stopLossAmount)
        : getSharesCount(this.accountRiskAmount, stopLossAmount);

      value.display_buying_power = formatPrice(entryPrice * sharesCount, this.exchange.profit_scale);
    }
  }

  updateDisplayPastPerformance = (value: ICompareDataToDisplay) => {
    const [total_profit, roi, win_percent, profit_factor, avg_trade_days, longest_trade_days] = this.getProperties(
      ['total_profit', 'roi', 'win_percent', 'profit_factor', 'avg_trade_days', 'longest_trade_days'],
      value
    );
    value.display_total_profit = this.getDisplayProperty(total_profit, formatPrice(total_profit, 2));
    value.display_roi = this.getDisplayProperty(roi, roi + '%');
    value.display_wins_percent = this.getDisplayProperty(win_percent, formatDecimal(win_percent) + '%');
    value.display_profit_factor = this.getDisplayProperty(profit_factor, formatDecimal(profit_factor));
    value.display_avg_trade_days = this.getDisplayProperty(avg_trade_days, formatDecimal(avg_trade_days, 0));
    value.display_longest_trade_days = this.getDisplayProperty(longest_trade_days, formatDecimal(longest_trade_days, 0));

    const winsKey = getPastPerformancePropertyName('wins', this.tradePosition);
    const lossesKey = getPastPerformancePropertyName('losses', this.tradePosition);

    this.totalProfitKey = getPastPerformancePropertyName('total_profit', this.tradePosition);
    this.roiKey = getPastPerformancePropertyName('roi', this.tradePosition);
    this.winPercentKey = getPastPerformancePropertyName('win_percent', this.tradePosition);
    this.profitFactorKey = getPastPerformancePropertyName('profit_factor', this.tradePosition);

    value.display_wins = value[winsKey];
    value.display_losses = value[lossesKey];
  };

  private getProperties(keys: string[], object: ICompareDataToDisplay): Array<any> {
    return keys.map((keyBase) => object[getPastPerformancePropertyName(keyBase, this.tradePosition)]);
  }

  private onTradePositionUpdate = (position: TradePositions) => {
    this.tradePosition = position;
    this.dataSource.data.forEach(this.updateDisplayPastPerformance);
  };

  progressWidth(value: number, maxValue: number) {
    return (Math.abs(value) * 100) / Math.abs(maxValue);
  }

  private getDisplayProperty(value, counted = value) {
    return value === null ? null : counted;
  }

  closeModalNoChange() {
    this.dialogRef.close(null);
  }

  selectionChange(row): void {
    const isRowSelected = this.selectedPositions.some((item) => item.id === row.id);

    if (isRowSelected) {
      return;
    }

    this.selectedPositions.push(row);

    if (this.selectedPositions.length === 3) {
      this.selectedPositions.shift();

      this.dataSource.data.forEach((element) => {
        this.selection.deselect(element);
      });

      this.selection.select(this.selectedPositions[0]);
      this.selection.select(this.selectedPositions[1]);
    }
  }

  async closeModal() {
    const tradingStrategies = await this.tradingStrategiesService.getAll();
    const first = tradingStrategies.find((s) => s.id === this.selectedPositions[0].strategy_id);
    const second = tradingStrategies.find((s) => s.id === this.selectedPositions[1].strategy_id);

    let firstTradingStrategyId = first.id;
    let secondTradingStrategyId = second.id;
    if (first.risk_ratio * first.reward_ratio > second.risk_ratio * second.reward_ratio) {
      firstTradingStrategyId = second.id;
      secondTradingStrategyId = first.id;
    }

    this.dialogRef.close({
      firstTradingStrategyId,
      secondTradingStrategyId,
    });
  }
}
