<app-search-bar #appSearchBar [isPowerX]="false">
  <app-trading-log-symbol-summary-panel
    *appHideForUnavailableFeature="features.TradingLog"
    [state]="observableService.tradingLogSymbolSummaryPanelWheelState.value"
    (selected)="summaryPanelValueSelected($event)"
    (statechanged)="summaryPanelStateUpdates($event)"
    [symbolName]="appSearchBar.selectedSymbol?.symbol"
  ></app-trading-log-symbol-summary-panel>

  <app-wheel-chart-legend
    *appHideForUnavailableFeature="features.LowHighIndicator; else lowHighIndicatorPlaceholder"
  ></app-wheel-chart-legend>

  <ng-template #lowHighIndicatorPlaceholder>
    <div class="low-high-indicator-placeholder">
      <button
        mat-button
        class="mat-button-link link-button locked-for-current-edition"
        (click)="$event.preventDefault(); redirectToLowHighIndicatorDemoPage()"
      >
        Lowest / Highest Close
      </button>
    </div>
  </ng-template>
</app-search-bar>

<div class="app-tv-chart-wheel-container" id="tv_chart_container_wheel"></div>
