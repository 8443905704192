import { StringifiedGuid } from '@t/common/stringified-guid.type';
import { TradingLogStreamingServiceCommandType } from '@t/trading-log/trading-log-streaming-service-command-type.enum';
import { Guid } from 'guid-ts';

import { TradingLogAccountModel } from './trading-log-account.model';
import { TradingLogGroupModel } from './trading-log-group.model';
import { TradingLogStrategyModel } from './trading-log-strategy.model';
import { TradingLogTransactionModel } from './trading-log-transaction.model';

// tslint:disable: variable-name
export interface ITradingLogStreamingServiceCommand {
  readonly type: TradingLogStreamingServiceCommandType;
  readonly command_id: StringifiedGuid;
}

export abstract class TLRBaseCommand implements ITradingLogStreamingServiceCommand {
  command_id = Guid.newGuid().toString();
  constructor(public type: TradingLogStreamingServiceCommandType) { }
}

export class TLRequestGroupsCommand extends TLRBaseCommand {
  constructor() {
    super(TradingLogStreamingServiceCommandType.RequestGroups);
  }
}

export abstract class TLRBaseGroupCommand extends TLRBaseCommand {
  constructor(public type: TradingLogStreamingServiceCommandType, public data: Partial<TradingLogGroupModel>) {
    super(type);
  }
}

export class TLUpdateGroupCommand extends TLRBaseGroupCommand {
  constructor(public data: Partial<TradingLogGroupModel>) {
    super(TradingLogStreamingServiceCommandType.UpdateGroup, data);
  }
}

export class TLDeleteGroupCommand extends TLRBaseGroupCommand {
  constructor(public data: Partial<TradingLogGroupModel>) {
    super(TradingLogStreamingServiceCommandType.DeleteGroup, data);
  }
}

export class TLRequestTransactionsCommand extends TLRBaseCommand {
  constructor(public group_id?: StringifiedGuid) {
    super(TradingLogStreamingServiceCommandType.RequestTransactions);
  }
}

export interface BathTransactionItem {
  type: TradingLogStreamingServiceCommandType;
  data: Partial<TradingLogTransactionModel>;
}

export class TLBathTransactionsCommand extends TLRBaseCommand {
  data: {
    transactions: ReadonlyArray<BathTransactionItem>
  } = { transactions: [] };

  constructor() {
    super(TradingLogStreamingServiceCommandType.BathTransactions);
  }

  public appendTransaction(transaction: Partial<TradingLogTransactionModel>, isDelete = false) {
    this.data.transactions = [...this.data.transactions, {
      data: transaction,
      type: isDelete ? TradingLogStreamingServiceCommandType.DeleteTransaction : TradingLogStreamingServiceCommandType.UpdateTransaction
    }];
  }
}

export class TLDeleteTransactionCommand extends TLRBaseCommand {
  constructor(public data: Partial<TradingLogTransactionModel>) {
    super(TradingLogStreamingServiceCommandType.DeleteTransaction);
  }
}
export class TLRequestStrategiesCommand extends TLRBaseCommand {
  constructor() {
    super(TradingLogStreamingServiceCommandType.RequestStrategies);
  }
}

export class TLUpdateStrategyCommand extends TLRBaseCommand {
  constructor(public data: Partial<TradingLogStrategyModel>) {
    super(TradingLogStreamingServiceCommandType.UpdateStrategy);
  }
}

export class TLDeleteStrategyCommand extends TLRBaseCommand {
  constructor(public data: Partial<TradingLogStrategyModel>) {
    super(TradingLogStreamingServiceCommandType.DeleteStrategy);
  }
}

export class TLRequestAccountsCommand extends TLRBaseCommand {
  constructor() {
    super(TradingLogStreamingServiceCommandType.RequestAccounts);
  }
}

export class TLUpdateAccountCommand extends TLRBaseCommand {
  constructor(public data: Partial<TradingLogAccountModel>) {
    super(TradingLogStreamingServiceCommandType.UpdateAccount);
  }
}

export class TLDeleteAccountCommand extends TLRBaseCommand {
  constructor(public data: Partial<TradingLogAccountModel>) {
    super(TradingLogStreamingServiceCommandType.DeleteAccount);
  }
}

export interface ImportDataModel {
  accounts: ReadonlyArray<TradingLogAccountModel>;
  strategies: ReadonlyArray<TradingLogStrategyModel>;
  groups: ReadonlyArray<TradingLogGroupModel>;
  transactions: ReadonlyArray<Partial<TradingLogTransactionModel>>;
}

export class TLImportCommand extends TLRBaseCommand {
  public readonly data: ImportDataModel = {
    accounts: [],
    strategies: [],
    transactions: [],
    groups: [],
  };

  constructor(
    groups?: ReadonlyArray<TradingLogGroupModel>,
    transactions?: ReadonlyArray<Partial<TradingLogTransactionModel>>
  ) {
    super(TradingLogStreamingServiceCommandType.Import);
    this.data.transactions = [...(transactions || [])];
    this.data.groups = [...(groups || [])];
  }

  public appendTransaction(transaction: Partial<TradingLogTransactionModel>): void {
    this.data.transactions = [...this.data.transactions, transaction];
  }

  public appendGroup(group: TradingLogGroupModel) {
    this.data.groups = [...this.data.groups, group];
  }
}
// tslint:enable: variable-name
