import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { PxoOrderTag, TradingCommandType, formatNumber, formatPrice, hasValue, TabNames } from '@const';
import { Command, CommandResult, TradierAccount, TradierOrderSide, TradingPanelClientEvent } from '@mod/trading-panel/trading-panel.model';
import { OptionDetailsComponent } from './option-details';
import { PriceTickerComponent } from './price-ticker';
import { TradingOrderContentStatus, TradingOrderDuration, TradingOrderInput, TradingOrderClass, TradingOrderType } from '@mod/trading-panel/trading-panel-order.model';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { ObservableService } from '@s/observable.service';
import { TradierService } from '@s/tradier.service';
import { TradingCommandService } from '@s/trading-command.service';
import { TradingService } from '@s/trading.service';
import { ErrorMessageComponent } from '@m/common/error-message/error-message.component';
import { NavigationService } from '@s/navigation.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

interface TradingPanelOrderForm {
  accountNumber: FormControl<string>;
  duration?: FormControl<TradingOrderDuration>;
  limitPrice?: FormControl<number>;
  stopLoss?: FormControl<number>;
  stopPrice?: FormControl<number>;
  takeProfit?: FormControl<number>;
  quantity: FormControl<number>;
}

@Component({
  standalone: true,
  selector: 'app-trading-panel-order',
  templateUrl: './trading-panel-order.component.html',
  styleUrls: ['./trading-panel-order.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    OptionDetailsComponent,
    PriceTickerComponent,
    ErrorMessageComponent,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class TradingPanelOrderComponent implements OnInit, OnDestroy {

  protected formatNumber = formatNumber;
  protected formatPrice = formatPrice;

  protected OrderDuration = TradingOrderDuration;
  protected TradingOrderContentStatus = TradingOrderContentStatus;
  protected TradierOrderSide = TradierOrderSide;

  @Input() orderInput: TradingOrderInput;

  protected isAccountsLoading = true;
  protected isProcessing = false;

  protected showMarketTab: boolean;
  protected showLimitTab: boolean;
  protected showStopTab: boolean;
  protected showOcoTab: boolean;

  private readonly MarketTabIndex = 0;
  private readonly LimitTabIndex = 1;
  private readonly StopTabIndex = 2;
  private readonly OcoTabIndex = 0;

  protected tabIndex = 0;
  protected contentStatus: TradingOrderContentStatus;
  protected operationStatus: string;
  protected operationError: string;
  protected orderStatus: string;
  protected orderStatusCssClass: string;

  protected accounts: TradierAccount[] = [];

  protected isBuy: boolean;
  protected havePricesLoaded = false;
  protected hasPrices = true;

  protected marketOrderForm: FormGroup<TradingPanelOrderForm>;
  protected limitOrderForm: FormGroup<TradingPanelOrderForm>;
  protected stopOrderForm: FormGroup<TradingPanelOrderForm>;
  protected ocoOrderForm: FormGroup<TradingPanelOrderForm>;

  private _getAccountsCommand: Command;
  private _subscriptions = new Subscription();

  constructor(
    private _brokerAuthenticationService: BrokerAuthenticationService,
    private _observableService: ObservableService,
    private _tradierService: TradierService,
    private _tradingCommandService: TradingCommandService,
    private _tradingService: TradingService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
    const orderInputState = this._observableService.tradingPanelOrderInputState.getValue();
    this.initializeFormData(orderInputState);

    this._subscriptions.add(
      this._observableService.tradingPanelClientEvent.subscribe((clientEvent: TradingPanelClientEvent) => {
        if (clientEvent === TradingPanelClientEvent.ResetOrderToDefault) {
          this.initializeFormData();
        } else if (clientEvent === TradingPanelClientEvent.OrderInputDataUpdated) {
          this.orderInput = this._observableService.tradingPanelOrderInput.getValue();
          this.initializeFormData();
        } else if (clientEvent === TradingPanelClientEvent.OrderPanelPinChanging) {
          this.saveState();
        } else if (clientEvent === TradingPanelClientEvent.OrderPanelClosing) {
          this._observableService.tradingPanelOrderInputState.next(null);
        }
      })
    );

    this._subscriptions.add(
      this._observableService.tradingPanelPricesLoaded.subscribe((pricesLoaded) => this.havePricesLoaded = pricesLoaded)
    );

    this._subscriptions.add(
      this._observableService.tradingPanelHasPrices.subscribe((hasPrices) => this.hasPrices = hasPrices)
    );

    const tradierAuthentication = this._brokerAuthenticationService.getTradierAuthenticationData();
    if (tradierAuthentication.accessToken) {
      this.subscribeTradierAccounts();
    } else {
      this.isAccountsLoading = false;
    }
  }

  ngOnDestroy(): void {
    this._tradingCommandService.unsubscribe(this._getAccountsCommand?.id);
    this._subscriptions.unsubscribe();
  }

  private initializeFormData(orderInputState?: TradingOrderInput) {
    if (!orderInputState && !this.orderInput) {
      return;
    }

    const orderInputToUse = orderInputState || this.orderInput;

    const tradierAuthentication = this._brokerAuthenticationService.getTradierAuthenticationData();
    this.contentStatus = tradierAuthentication.accessToken
      ? TradingOrderContentStatus.Trading
      : TradingOrderContentStatus.BrokerConnectionMissing;
    this.operationStatus = null;
    this.operationError = null;

    this.isBuy = orderInputToUse.side === TradierOrderSide.Buy
      || orderInputToUse.side === TradierOrderSide.BuyToClose
      || orderInputToUse.side === TradierOrderSide.BuyToCover
      || orderInputToUse.side === TradierOrderSide.BuyToOpen;

    this.showMarketTab = orderInputToUse.orderClass !== TradingOrderClass.OCO;
    this.showLimitTab = orderInputToUse.orderClass !== TradingOrderClass.OCO;
    this.showStopTab = orderInputToUse.orderClass !== TradingOrderClass.OCO
      && !orderInputToUse.option;
    this.showOcoTab = orderInputToUse.orderClass === TradingOrderClass.OCO;
    this.tabIndex = this.getOrderPanelTabIndex(orderInputToUse);

    const selectedAccount = hasValue(orderInputToUse.id)
      ? orderInputToUse.accountNumber
      : this._observableService.tradierSelectedAccount.getValue();

    const quantity = {
      value: orderInputToUse.quantity === undefined ? null : orderInputToUse.quantity,
      disabled: hasValue(orderInputToUse.id)
    };

    const account = {
      value: selectedAccount,
      disabled: hasValue(orderInputToUse.id)
    };

    this.marketOrderForm = new FormGroup<TradingPanelOrderForm>({
      accountNumber: new FormControl(account, { validators: [Validators.required] }),
      quantity: new FormControl(quantity, { validators: [Validators.required, Validators.min(1), Validators.max(999999)] })
    });

    this.limitOrderForm = new FormGroup<TradingPanelOrderForm>({
      accountNumber: new FormControl(account, { validators: [Validators.required] }),
      duration: new FormControl(orderInputToUse.duration || TradingOrderDuration.Day, { validators: [Validators.required] }),
      limitPrice: new FormControl(orderInputToUse.id ? orderInputToUse.limitPrice : orderInputToUse.price, { validators: [Validators.required, Validators.min(0.01), Validators.max(999999.99)] }),
      quantity: new FormControl(quantity, { validators: [Validators.required, Validators.min(1), Validators.max(999999)] })
    });

    this.stopOrderForm = new FormGroup<TradingPanelOrderForm>({
      accountNumber: new FormControl(account, { validators: [Validators.required] }),
      duration: new FormControl(orderInputToUse.duration || TradingOrderDuration.Day, { validators: [Validators.required] }),
      stopPrice: new FormControl(orderInputToUse.id ? orderInputToUse.stopPrice : orderInputToUse.price, { validators: [Validators.required, Validators.min(0.01), Validators.max(999999.99)] }),
      quantity: new FormControl(quantity, { validators: [Validators.required, Validators.min(1), Validators.max(999999)] })
    });

    this.ocoOrderForm = new FormGroup<TradingPanelOrderForm>({
      accountNumber: new FormControl(account, { validators: [Validators.required] }),
      duration: new FormControl(orderInputToUse.duration || TradingOrderDuration.GTC, { validators: [Validators.required] }),
      takeProfit: new FormControl(orderInputToUse.takeProfit, { validators: [Validators.required, Validators.min(0.01), Validators.max(999999.99)] }),
      stopLoss: new FormControl(orderInputToUse.stopLoss, { validators: [Validators.required, Validators.min(0.01), Validators.max(999999.99)] }),
      quantity: new FormControl(quantity, { validators: [Validators.required, Validators.min(1), Validators.max(999999)] })
    });

    // validate all forms to show errors with initial values on start
    this.marketOrderForm.markAllAsTouched();
    this.limitOrderForm.markAllAsTouched();
    this.stopOrderForm.markAllAsTouched();
    this.ocoOrderForm.markAllAsTouched();
  }

  private getForm(): FormGroup<TradingPanelOrderForm> {
    let form = this.marketOrderForm;

    if (this.tabIndex === this.LimitTabIndex) {
      form = this.limitOrderForm;
    } else if (this.tabIndex === this.StopTabIndex) {
      form = this.stopOrderForm;
    } else if (this.showOcoTab && this.tabIndex === this.OcoTabIndex) {
      form = this.ocoOrderForm;
    }

    return form;
  }

  private getOrderPanelTabIndex(orderInput: TradingOrderInput): number {
    if (hasValue(orderInput.orderPanelTabIndex)) {
      return orderInput.orderPanelTabIndex;
    }

    let tabIndex = 0;

    if (orderInput.orderClass === TradingOrderClass.OCO) {
      tabIndex = this.OcoTabIndex;
    } else {
      switch (orderInput.orderType) {
        case TradingOrderType.Market: tabIndex = this.MarketTabIndex; break;
        case TradingOrderType.Limit: tabIndex = this.LimitTabIndex; break;
        case TradingOrderType.Stop: tabIndex = this.StopTabIndex; break;
      }
    }

    return tabIndex;
  }

  private preProcessOrder(order: any) {
    if (!this.orderInput.id) {
      return {
        ...order,
        tag: PxoOrderTag
      };
    }

    const modifiedOrder = {};

    if (order.type !== this.orderInput.orderType) {
      modifiedOrder['type'] = order.type;
    }

    if (order.duration !== this.orderInput.duration) {
      modifiedOrder['duration'] = order.duration;
    }

    if (order.price && order.price !== this.orderInput.price) {
      modifiedOrder['price'] = order.price;
    }

    if (order.stop && order.stop !== this.orderInput.stopLoss) {
      modifiedOrder['stop'] = order.stop;
    }

    return modifiedOrder;
  }

  private async sendOrder(accountNumber: string, order: any) {
    this.isProcessing = true;

    const accessToken = this._brokerAuthenticationService.getTradierAuthenticationData().accessToken;
    const preProcessedOrder = this.preProcessOrder(order);
    const { id, status, error, order: orderResult, success } = this.orderInput.id
      ? await this._tradingService.modifyOrder(accessToken, accountNumber, this.orderInput.id, preProcessedOrder)
      : await this._tradingService.createOrder(accessToken, accountNumber, preProcessedOrder);

    this.operationError = error;

    if (id) {
      this.contentStatus = TradingOrderContentStatus.OperationStatus;
      this.operationStatus = status;

      const { status: orderStatus } = orderResult || {};
      this.orderStatus = this.orderInput.id && success
        ? 'Order has been modified successfully'
        : orderStatus?.toUpperCase() ?? 'Error';
      this.orderStatusCssClass = orderStatus ? `status-${orderStatus.toLowerCase()}` : 'text-error';

      this._observableService.tradingPanelOrderModified.next(id);
      this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderCreated);
    }

    this.isProcessing = false;
  }

  private subscribeTradierAccounts() {
    this._getAccountsCommand = {
      id: uuidv4(),
      type: TradingCommandType.GetUserProfile,
      updateIntervalMs: 10 * 1000,
      handleError: true,
      handler: (result: CommandResult) => {
        if (result.success) {
          const rawAccounts = result.data?.profile?.account
            ? Array.isArray(result.data?.profile?.account)
              ? result.data?.profile?.account
              : [result.data?.profile?.account]
            : [];

          const accounts = rawAccounts.map((account) => ({
            ...account,
            display_name: this._tradierService.getAccountDisplayName(account)
          }));
          accounts.sort((a, b) => (a.display_name > b.display_name) ? 1 : ((b.display_name > a.display_name) ? -1 : 0));

          this.accounts = accounts;
        } else {
          this.contentStatus = TradingOrderContentStatus.BrokerConnectionMissing;

          // Not able to get accounts, logout to prompt user to login again
          this._brokerAuthenticationService.logout();
        }

        this.isAccountsLoading = false;
      }
    };

    this._tradingCommandService.run(this._getAccountsCommand);
  }

  protected get formClass(): string {
    const form = this.getForm();

    return `${this.isBuy ? 'buy' : 'sell'} ${((form.valid || form.disabled) && this.havePricesLoaded && this.hasPrices) ? '' : 'invalid'}`;
  }

  async submitMarketOrder(): Promise<void> {
    const isValid = this.marketOrderForm.disabled
      ? true
      : this.marketOrderForm.valid;

    if (!isValid) {
      return;
    }

    const symbol = this.orderInput.symbol?.symbol
      || this.orderInput.option?.underlyingSymbol;

    const { accountNumber, quantity } = this.marketOrderForm.getRawValue();

    const order = {
      class: this.orderInput.orderClass,
      symbol,
      side: this.orderInput.side,
      quantity: quantity,
      type: TradingOrderType.Market,
      duration: this.orderInput.duration ?? TradingOrderDuration.Day
    };

    if (this.orderInput.option?.symbol) {
      order['option_symbol'] = this.orderInput.option.symbol;
    }

    await this.sendOrder(accountNumber, order);
  }

  async submitLimitOrder(): Promise<void> {
    const isValid = this.limitOrderForm.disabled
      ? true
      : this.limitOrderForm.valid;

    if (!isValid) {
      return;
    }

    const symbol = this.orderInput.symbol?.symbol
      || this.orderInput.option?.underlyingSymbol;

    const { accountNumber, duration, limitPrice, quantity } = this.limitOrderForm.getRawValue();

    const order = {
      class: this.orderInput.orderClass,
      symbol,
      side: this.orderInput.side,
      quantity: quantity,
      type: TradingOrderType.Limit,
      duration: duration,
      price: limitPrice
    };

    if (this.orderInput.option?.symbol) {
      order['option_symbol'] = this.orderInput.option.symbol;
    }

    await this.sendOrder(accountNumber, order);
  }

  async submitStopOrder(): Promise<void> {
    const isValid = this.stopOrderForm.disabled
      ? true
      : this.stopOrderForm.valid;

    if (!isValid) {
      return;
    }

    const { accountNumber, duration, stopPrice, quantity } = this.stopOrderForm.getRawValue();

    const order = {
      class: this.orderInput.orderClass,
      symbol: this.orderInput.symbol.symbol,
      side: this.orderInput.side,
      quantity,
      type: TradingOrderType.Stop,
      duration,
      stop: stopPrice
    };

    await this.sendOrder(accountNumber, order);
  }

  async submitOcoOrder(): Promise<void> {
    const isValid = this.ocoOrderForm.disabled
      ? true
      : this.ocoOrderForm.valid;

    if (!isValid) {
      return;
    }

    const symbol = this.orderInput.symbol?.symbol
      || this.orderInput.option?.underlyingSymbol;

    const { accountNumber, stopLoss, takeProfit, quantity } = this.ocoOrderForm.getRawValue();

    const order = {
      class: this.orderInput.orderClass,
      duration: this.ocoOrderForm.value.duration,
      'symbol[0]': symbol,
      'symbol[1]': symbol,
      'quantity[0]': quantity,
      'quantity[1]': quantity,
      'type[0]': TradingOrderType.Limit,
      'type[1]': TradingOrderType.Stop,
      'side[0]': this.orderInput.side,
      'side[1]': this.orderInput.side,
      'price[0]': takeProfit,
      'stop[1]': stopLoss,
    };

    if (this.orderInput.option?.symbol) {
      order['option_symbol[0]'] = this.orderInput.option.symbol;
      order['option_symbol[1]'] = this.orderInput.option.symbol;
    }

    await this.sendOrder(accountNumber, order);
  }

  saveState() {
    const orderInputState = {
      ...this.orderInput,
      orderPanelTabIndex: this.tabIndex
    };

    if (!this.showOcoTab && this.tabIndex === this.MarketTabIndex) {
      orderInputState.quantity = this.orderInput.id
        ? this.orderInput.quantity
        : this.marketOrderForm.value.quantity;
    } else if (this.tabIndex === this.LimitTabIndex) {
      orderInputState.quantity = this.orderInput.id
        ? this.orderInput.quantity
        : this.limitOrderForm.value.quantity;
      orderInputState.duration = this.limitOrderForm.value.duration;
      orderInputState.limitPrice = orderInputState.id
        ? this.limitOrderForm.value.limitPrice
        : null;
      orderInputState.price = !orderInputState.id
        ? this.limitOrderForm.value.limitPrice
        : null;
    } else if (this.tabIndex === this.StopTabIndex) {
      orderInputState.quantity = this.orderInput.id
        ? this.orderInput.quantity
        : this.stopOrderForm.value.quantity;
      orderInputState.duration = this.stopOrderForm.value.duration;
      orderInputState.stopPrice = orderInputState.id
        ? this.stopOrderForm.value.stopPrice
        : null;
      orderInputState.price = !orderInputState.id
        ? this.stopOrderForm.value.stopPrice
        : null;
    } else if (this.showOcoTab && this.tabIndex === this.OcoTabIndex) {
      orderInputState.quantity = this.orderInput.id
        ? this.orderInput.quantity
        : this.ocoOrderForm.value.quantity;
      orderInputState.duration = this.ocoOrderForm.value.duration;
      orderInputState.takeProfit = this.ocoOrderForm.value.takeProfit;
      orderInputState.stopLoss = this.ocoOrderForm.value.stopLoss;
    }

    this._observableService.tradingPanelOrderInputState.next(orderInputState);
  }

  openTradingPanel() {
    const { onTradingPanelNavigateHandler } = this.orderInput;

    if (onTradingPanelNavigateHandler) {
      onTradingPanelNavigateHandler();
    }

    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderPanelClosing);
    this._observableService.tradingPanelOrderInput.next(null);
    this.navigationService.redirectToTab(TabNames.TradingPanel);
  }

  closeModal() {
    const { onTradingPanelNavigateHandler } = this.orderInput;

    if (onTradingPanelNavigateHandler) {
      onTradingPanelNavigateHandler();
    }

    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderPanelClosing);
    this._observableService.tradingPanelOrderInput.next(null);
  }

  onFocus() {
    this.operationError = null;
  }
}
