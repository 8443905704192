import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/services/observable.service';
import { merge, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SleeknoteService {
  private _subscription: Subscription;

  constructor(
    private observableService: ObservableService,
    private router: Router) {}

  trackingActivityForSleeknote(): void {
    this._subscription = merge(
      this.observableService.chartTab,
      this.observableService.symbol,
      this.observableService.tradePosition,
      this.observableService.isWheelCalculator,
      this.observableService.wheelSymbol,
      this.observableService.wheelScannerTab,
      this.observableService.tlTradesOpened,
      this.observableService.tlTradesDashboardOpened,
      this.observableService.tlTradesActiveOpened,
      this.observableService.tlTradesArchivedOpened,
      this.observableService.tlTradesDashboardOpenPositionsOpened,
      this.observableService.tlTradesDashboardSummaryOpened,
      this.observableService.tlTradesDashboardOptionsOpened,
      this.observableService.tlTradesDashboardStocksOpened
    ).subscribe(() => {
      this.changeUrlQueryForSleeknote();
    });
  }

  unsubscribe(): void {
    this._subscription.unsubscribe();
  }

  changeUrlQueryForSleeknote(): void {
    const timestamp = Date.now().toString();
    const activeTab = this.observableService.activeTab.getValue();
    this.router.navigate([`/${activeTab}`], { queryParams: { t: timestamp } });
  }
}
