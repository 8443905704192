<div class="page-wrapper" [class.page-while-print]="isNeedPrint$ | async">
  <div *ngIf="showImpersonateBar" class="impersonate-bar">
    <div class="info-pannel">
      <div class="main-label">
        You are logged in as
      </div>
      <div class="info-container">
        <mat-icon class="person-impersonate" [svgIcon]="'person'"></mat-icon>
        <div class="user-info">
          <div class="user-name-container">{{impersonateUserName}}</div>
          <div class="user-email-container">{{impersonateUserEmail}}</div>
        </div>
      </div>
    </div>
    <button (click)="logout()" class="logout-button">Finish Session</button>
  </div>
  <input *ngIf="tags" type="hidden" id="ifs-tags-id" value="{{tags}}"/>
  <router-outlet></router-outlet>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" [fullScreen]="false" color="#fff" type="ball-atom">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<app-print-data *ngIf="isNeedPrint$ | async"></app-print-data>
