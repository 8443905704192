import * as _ from 'lodash';

import { round } from '@u/utils';
import { ISymbol } from '@s/symbols.service';
import { TradingLogAccountModel, TradingLogGroupModel, TradingLogStrategyModel } from '@mod/trading-log';
import { ExchangeCountriesCodes } from '@const';
import { MorningStarIndustryMap, MorningStarSectorMap } from '@mod/data/morning-star.model';
import { IPortfolioSymbol } from '@c/portfolio/portfolio-symbols-list/portfolio-symbols-list.model';
import { IPortfolioFiltersState } from '@c/portfolio/portfolio-filters-form/portfolio-filters-form.model';
import { IStatisticDetails, IPortfolioTrade } from '@c/portfolio/portfolio.model';
import { barColors, colorsRowsMap, colorsColumnsMap } from '@c/portfolio/portfolio.data';

export function transformTradingLogGroups(
  groups: Partial<TradingLogGroupModel>[],
  symbolsRecord: Record<string, ISymbol>,
  sectorsMap: MorningStarSectorMap,
  industriesMap: MorningStarIndustryMap,
): IPortfolioTrade[] {
  return groups.map((item) => {
    // priorities: US -> CA -> CC
    const keyUS = `${item.symbol}-${ExchangeCountriesCodes.US}`;
    const keyCA = `${item.symbol}-${ExchangeCountriesCodes.CA}`;
    const keyCC = `${item.symbol}-${ExchangeCountriesCodes.CC}`;
    const symbolDetails = symbolsRecord[keyUS] ?? symbolsRecord[keyCA] ?? symbolsRecord[keyCC] ?? null;

    const sector = symbolDetails?.sector_code && sectorsMap.has(symbolDetails?.sector_code)
      ? sectorsMap.get(symbolDetails?.sector_code)
      : null;
    const industry = symbolDetails?.industry_code && industriesMap.has(symbolDetails?.industry_code)
      ? industriesMap.get(symbolDetails?.industry_code)
      : null;

    return {
      id: item.id,
      accountId: item.account_id,
      strategyId: item.strategy_id,
      symbol: item.symbol ?? null,
      type: item.type,
      sector,
      sectorCode: symbolDetails?.sector_code ?? null,
      industry,
      industryCode: symbolDetails?.industry_code ?? null,
      securityId: symbolDetails?.security_id ?? null,
      company: symbolDetails?.description,
    };
  });
}

export function transformTradingLogGroupsForSymbolsList(
  groups: Partial<TradingLogGroupModel>[],
  symbolsRecord: Record<string, ISymbol>,
  sectorsMap: MorningStarSectorMap,
  industriesMap: MorningStarIndustryMap,
): IPortfolioSymbol[] {
  return groups.map((item) => {
    // priorities: US -> CC -> CA
    const keyUS = `${item.symbol}-${ExchangeCountriesCodes.US}`;
    const keyCC = `${item.symbol}-${ExchangeCountriesCodes.CC}`;
    const keyCA = `${item.symbol}-${ExchangeCountriesCodes.CA}`;
    const symbolDetails = symbolsRecord[keyUS] ?? symbolsRecord[keyCC] ?? symbolsRecord[keyCA] ?? null;

    const sector = symbolDetails?.sector_code && sectorsMap.has(symbolDetails?.sector_code)
      ? sectorsMap.get(symbolDetails?.sector_code)
      : null;
    const industry = symbolDetails?.industry_code && industriesMap.has(symbolDetails?.industry_code)
      ? industriesMap.get(symbolDetails?.industry_code)
      : null;

    return {
      id: item.id,
      accountId: item.account_id,
      strategyId: item.strategy_id,
      symbol: item.symbol ?? null,
      type: item.type,
      sector,
      sectorCode: symbolDetails?.sector_code ?? null,
      industry,
      industryCode: symbolDetails?.industry_code ?? null,
      securityId: symbolDetails?.security_id ?? null,
      company: symbolDetails?.description,
      countryCode: (symbolDetails?.country_code as ExchangeCountriesCodes) ?? null,
    };
  });
}

export function getUniqueSymbols<T extends IPortfolioTrade | IPortfolioSymbol>(
  trades: T[]
): T[] {
  const itemsMap = new Map<string, T>();

  trades.forEach((item) => {
    if (!itemsMap.has(item.symbol)) {
      itemsMap.set(item.symbol, item);
    }
  });

  return Array.from(itemsMap.values());
}

export function filterTrades<T extends IPortfolioTrade | IPortfolioSymbol>(
  trades: T[],
  filtersState: IPortfolioFiltersState,
): T[] {
  return [...trades].filter((item) => (
    (filtersState.accounts.length === 0 || filtersState.accounts.includes(item.accountId))
    && (filtersState.strategies.length === 0 || filtersState.strategies.includes(item.strategyId))
  ));
}

export function createTradesGroupTitle(
  accountId: string | null,
  strategyId: string | null,
  accountsRecord: Record<string, TradingLogAccountModel>,
  strategiesRecord: Record<string, TradingLogStrategyModel>,
): string {
  // null is used as id for (not defined) option for both cases: account and strategy
  const account = accountId === null ? null : accountsRecord[accountId];
  const strategy = strategyId === null ? null : strategiesRecord[strategyId];

  if (account && strategy) {
    return `${account.name} - ${strategy.name}`;
  }

  if (account && !strategy) {
    return `${account.name} - (not defined)`;
  }

  if (!account && strategy) {
    return `(not defined) - ${strategy.name}`;
  }

  return '(not defined)';
}

export function getStatistic(
  values: Array<string | null>,
  indexesMap: Map<string, number>,
): IStatisticDetails[] {
  const validValues = values.filter((item) => Boolean(item));

  const usageSummary = validValues.reduce((acc, item) => {
    if (!item) {
      return acc;
    }

    if (acc[item]) {
      acc[item] = acc[item] + 1;
    } else {
      acc[item] = 1;
    }

    return acc;
  }, {} as Record<string, number>);

  const unsortedValues = Object.keys(usageSummary).map((item) => ({
    id: item,
    title: item,
    color: getBarColor(indexesMap.get(item) ?? 0),
    total: usageSummary[item],
    percentage: round((usageSummary[item] / validValues.length) * 100, 2),
  }));

  return _.orderBy(unsortedValues, ['total', 'title'], ['desc', 'asc']);
}

export function getBarColor(index: number, colors: string[][] = barColors): string {
  const colorsNumber = colors.length * colors[0].length;
  const indexInColorsArray = index % colorsNumber;

  const columnIndex = indexInColorsArray % colors[0].length;
  const rowIndex = Math.floor(indexInColorsArray / colors[0].length);

  return colors[colorsRowsMap[rowIndex]][colorsColumnsMap[columnIndex]];
}
