import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SelectFeelingsFormComponent } from '@c/trading-log/shared';

import { SelectFeelingsModalInputModel, SelectFeelingsModalOutputModel } from './models';


@Component({
  standalone: true,
  selector: 'app-select-feelings-modal',
  templateUrl: './select-feelings-modal.component.html',
  styleUrls: ['./select-feelings-modal.component.scss'],
  imports: [SelectFeelingsFormComponent, MatIconModule, MatButtonModule, MatDialogModule]
})
export class SelectFeelingsModalComponent implements OnInit, AfterViewInit {

  @ViewChild(SelectFeelingsFormComponent) form: SelectFeelingsFormComponent;
  constructor(
    private _cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SelectFeelingsModalComponent, SelectFeelingsModalOutputModel>,
    @Inject(MAT_DIALOG_DATA) private _data: SelectFeelingsModalInputModel,
  ) { }

  ngAfterViewInit(): void {
    this.form.selectedConfidenceId = this._data.confidenceId;
    this.form.selectedDecisionId = this._data.decisionId;
    this._cdr.detectChanges();
  }

  ngOnInit() { }

  public closeModal(): void {
    this.dialogRef.close();
  }

  protected confidenceIdSelected(selectedId: string): void {
    this._data.confidenceIdChanged(selectedId);
  }

  protected decisionIdSelected(selectedId: string): void {
    this._data.decisionIdChanged(selectedId);
  }
}
