<button
  type="button"
  aria-label="Toggle sidenav"
  mat-button
  class="root"
  (click)="openTradingHubModal()"
>
  <div class="content">
    <div class="progress-container">
      <app-checklist-progress
        [value]="progress$ | async"
        [tasksLength]="tasksLength$ | async"
        [isFirstTimeOpening]="isFirstTimeOpening$ | async"
        size="small"
      ></app-checklist-progress>
    </div>
    <span class="title">
      YOUR TRADING HUB
    </span>
  </div>
</button>
