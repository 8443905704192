<div *ngIf="!(showMaintenance$ | async)" class="result-as-of">
  <div class="container">
    <div class="row ml-0-1366">
      <div id="circle-timer-svg" *ngIf="showTimer">
        <svg viewBox="-10 -10 120 120">
          <path
            d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96"
            stroke-width="17"
            fill-opacity="0"
            [style.stroke]="!showTimer ? innerStrokeGrey : innerStroke"
          ></path>
          <path
            d="M 50,50 m 0,-48 a 48,48 0 1 1 0,96 a 48,48 0 1 1 0,-96"
            stroke-width="18"
            fill-opacity="0"
            [style.stroke]="outerStroke"
            [style.stroke-dashoffset]="strokeOffset"
            style="stroke-dasharray: 301.635, 301.635;"
          ></path>
        </svg>

        <mat-spinner
          *ngIf="isWheelScannerLoading$ | async"
          class="blue-spinner additional-wheel-timer-progress mobile"
          diameter="32"
        ></mat-spinner>

        <mat-spinner
          *ngIf="isWheelScannerLoading$ | async"
          class="blue-spinner additional-wheel-timer-progress desktop"
          diameter="26"
        ></mat-spinner>

        <span id="per-number">{{ showTimer ? timerFor2minutes : "-" }}</span>
      </div>

      <div class="progress-detail-txt">
        <span *ngIf="lastUpdatedDate" [ngStyle]="{ 'display': showTimer ? 'block' : 'none' }">
          Result as of<br />
          {{ lastUpdatedDate }} &nbsp; • &nbsp; {{ lastUpdatedTime }} ET
        </span>
        <div
          *ngIf="showTimer === true || showTimer === false"
          class="market-closed-txt"
          [ngStyle]="{ 'display': !showTimer ? 'block' : 'none' }"
        >
          <div class="lightRedBg redText">
            <span>MARKET CLOSED</span>
            <ng-container *ngIf="lastUpdatedDate">
              &nbsp; • &nbsp;
              <b>Last Update &nbsp;</b>
              <span>{{ lastUpdatedDateTime }} ET</span>
            </ng-container>
          </div>
          <p><b>Next Run: </b> {{ nextDayDate }} at {{ nextDayTime }} ET</p>
        </div>
      </div>
    </div>
  </div>
</div>
