import { Component, ElementRef, forwardRef, HostListener, Input, NgZone, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { ScrollDispatcher } from '@angular/cdk/scrolling';

import { ResizeDirectiveModule } from '@core/directives/resize-directive/resize-directive.module';
import { CloseClippedRepositionScrollStrategy } from '@c/shared/close-clipped-reposition-strategy';

@Component({
  selector: 'app-input-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    OverlayModule,
    ResizeDirectiveModule,
  ],
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMultiSelectComponent),
      multi: true
    }
  ]
})
export class InputMultiSelectComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() emptyOptionsDropdownMessage: string;
  @Input() emptyFilteredOptionsDropdownMessage: string;
  @Input() isUpperCase = true;
  @Input() maxLength = 100;

  @Input() set options(options: string[]) {
    const uniqueOptions = Array.from(new Set(options));

    this._options = [...uniqueOptions];
    this.optionsToShow = [...uniqueOptions];
  }

  get options() {
    return this._options;
  }

  private _options: string[];

  @ViewChild('symbolInput', { read: ElementRef }) symbolInput: ElementRef;

  // Attention: scrollStrategy will work only if scrolling element has cdkScrollable directive
  protected scrollStrategy = new CloseClippedRepositionScrollStrategy(
    this.scrollDispatcher,
    this.ngZone,
    this.elementRef,
    { scrollThrottle: 10, autoClose: true }
  );

  protected value: string;
  protected isShowOptions = false;
  protected optionsToShow: string[] = [];

  private _lastCursorPosition = 0;

  protected onChange(newValue: string) { }
  protected onTouched() { }

  constructor(
    private elementRef: ElementRef,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
  ) { }

  public writeValue(value: string) {
    this.value = value;
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  @HostListener('click')
  public showOptions() {
    this.isShowOptions = true;
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownEnterHandler(event: KeyboardEvent) {
    if (this.isShowOptions && this.optionsToShow.length) {
      event.preventDefault();
      this.selectOption(this.optionsToShow[0], this.symbolInput.nativeElement);
    }
  }

  protected hideOptions(e: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(e.target as HTMLElement)) {
      this.isShowOptions = false;
      this.onTouched();
    }
  }

  protected selectOption(option: string, inputEl: HTMLInputElement): void {
    const leftSide = this.getWordLeftSideAt(this.value, this._lastCursorPosition);
    const rightSide = this.getWordRightSideAt(this.value, this._lastCursorPosition);
    const newLeftSide = this.value.slice(0, this._lastCursorPosition - leftSide.length);
    const newRightSide = this.value.slice(this._lastCursorPosition + rightSide.length);

    let cursorIndent = 1;
    let optionToAdd: string;

    if (this.value.trim().split(/[,;\s]/).length > 1) {
      if (newLeftSide.length && !newLeftSide.endsWith(' ') && !newRightSide.startsWith('  ')) {
        optionToAdd = ` ${option} `;
        cursorIndent = 2;
      } else if (newLeftSide.length && !newLeftSide.endsWith(' ') && newRightSide.startsWith('  ')) {
        optionToAdd = ` ${option}`;
        cursorIndent = 2;
      } else if (!newRightSide.startsWith('  ')) {
        optionToAdd = `${option} `;
      } else {
        optionToAdd = option;
      }
    } else if (!newRightSide.startsWith('  ')) {
      optionToAdd = `${option} `;
    }

    const res = optionToAdd && optionToAdd.length
      ? newLeftSide + optionToAdd + newRightSide
      : null;

    if (res && res.length < this.maxLength) {
      this.value = res;
    }

    inputEl.focus();
    const newCursorPosition = this._lastCursorPosition - option.slice(0, leftSide.length).length + option.length + cursorIndent;
    setTimeout(() => inputEl.setSelectionRange(newCursorPosition, newCursorPosition));
    this._lastCursorPosition = newCursorPosition;
    this.optionsToShow = this.options.slice();

    this.onChange(this.value);
  }

  protected clear(): void {
    this.value = '';
    this._lastCursorPosition = 0;
    this.optionsToShow = this.options.slice();
    this.onChange(this.value);
  }

  protected onInput(e: Event): void {
    this.value = (e.target as HTMLInputElement).value;
    this.onChange(this.value);
  }

  protected filterOptions(e: Event): void {
    this.isShowOptions = true;

    if (this.options?.length) {
      this._lastCursorPosition = (e.target as HTMLInputElement).selectionStart;
      const searchedString = this.getWordAt(this.value, this._lastCursorPosition);
      this.optionsToShow = this.options.filter((option) => option.toUpperCase().startsWith(searchedString.toUpperCase()));
    }
  }

  protected calculateOverlayWidth(overlayOriginRef: CdkOverlayOrigin): void {
    if (this.isShowOptions) {
      const paneEl = document.querySelector('.input-multi-select-overlay-pane') as HTMLDivElement;
      paneEl.style.width = overlayOriginRef.elementRef.nativeElement.offsetWidth + 'px';
    }
  }

  private getWordAt(text: string, position: number): string {
    const leftSide = this.getWordLeftSideAt(text, position);
    const rightSide = this.getWordRightSideAt(text, position);

    return leftSide + rightSide;
  }

  private getWordLeftSideAt(text: string, position: number): string {
    return text.substring(0, position).replace(/^.*[,;\s]/g, '');
  }

  private getWordRightSideAt(text: string, position: number): string {
    const match = text.substring(position).match(/^(\S+?)\b/);
    return (match && match[0]) || '';
  }
}
