<div *ngIf="!isLoading">
  <div mat-dialog-title class="print-dialog-header">
    Print Options

    <button mat-icon-button mat-dialog-close (click)="cancel()" class="popup-close-btn " tabindex="-1">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon"></mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div class="print-data-options" *ngFor="let option of printOptions">
      <mat-checkbox
        color="primary"
        class="pxo-checkbox small-checkbox"
        (change)="setPrintOption(option)"
        [checked]="currentPrintOptions.includes(option)"
      >
        {{ option }}
      </mat-checkbox>
    </div>
  </div>
  <div class="warning-container">
    <mat-icon [svgIcon]="'warning'" class="xl-icon"></mat-icon>
    <div class="message">Optimized for Portrait layout and Letter/A4 paper size.</div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!currentPrintOptions || !currentPrintOptions.length"
      (click)="checkIsChartReady()"
    >
      Print
    </button>
    <button mat-stroked-button class="border-btn" (click)="cancel()">Cancel</button>
  </div>
</div>
<div *ngIf="isLoading" class="print-modal-loading">
  <mat-spinner class="blue-spinner" diameter="60"></mat-spinner>
</div>
