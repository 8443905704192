<!--  it's only for sort-header, no table-body  -->

<!--  to add sort-header  -->
<div class="table-sort-header" [class.hidden]="!dataSource?.data?.length">
  <table
    class="table-sort-header"
    mat-table
    matSort
    [dataSource]="dataSource"
    [matSortActive]="sortState.column"
    [matSortDirection]="sortState.direction"
    [hidden]="!dataSource?.data?.length"
    (matSortChange)="onSortChange($event)"
  >
    <ng-container *ngFor="let column of allDisplayedColumns" [matColumnDef]="column">
      <th
        class="table-sort-header-cell"
        [ngClass]="column"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        disableClear
      >
        {{ basicSortHeaderTitles[column] ?? sortHeaderTitles[column] ?? column }}
      </th>
      <mat-cell mat-cell *matCellDef="let element; let i = index"></mat-cell>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="allDisplayedColumns" class="table-header-row"></tr>
  </table>
</div>

<cdk-virtual-scroll-viewport
  tvsItemSize="29"
  headerHeight="0"
  bufferMultiplier="1"
  class="scroll-style wtf-scanner-virtual-scroll scanner-symbols-list-virtual-scroll"
  appResizeDirective
  (resize)="onResize()"
>
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    [matSortActive]="sortState.column"
    [matSortDirection]="sortState.direction"
    [matSortStart]="defaultSortState.direction"
    [hidden]="!dataSource?.data?.length"
    [trackBy]="trackByFn"
    (matSortChange)="onSortChange($event)"
    class="wheel-conservative-aggressive-table wtf-scanner-table scanner-symbol-table"
  >
    <ng-container matColumnDef="flag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Flag
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="borderSelection">
          <app-symbol-flag
            [flag]="element.flag"
            [securityId]="element.security_id"
            [smileyListType]="smileyListType"
            [showNoneFlagOnlyOnHover]="true"
            [sendUpdateSignalOnChange]="false"
            [showStatisticsWhenVisible]="true"
            [rootClass]="'scanner-flag'"
            (menuOpened)="isSmileyMenuOpened = true"
            (flagChanged)="onSelectSmiley(element.security_id, $event)"
            (menuClosed)="isSmileyMenuOpened = false; onSmileyMenuClosed()"
            (click)="$event.stopPropagation()"
          ></app-symbol-flag>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th *matHeaderCellDef mat-sort-header disableClear>
        Symbol
      </th>
      <td mat-cell *matCellDef="let element" (click)="onSelectSymbol(element)">
        <div class="borderSelection">
          {{ element.symbol | uppercase }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Company
      </th>
      <td mat-cell *matCellDef="let element" (click)="onSelectSymbol(element)">
        <div class="relative borderSelection company-cell-content with-flags-statistic">
          <div class="company-title company-eclips">
            {{ element.company }}
          </div>

          <div *ngIf="showSmileyStatistic" class="flag-statistics-widget">
            <app-symbol-flag-statistic-widget
              [securityId]="element.security_id"
              [listType]="smileyListType"
            ></app-symbol-flag-statistic-widget>
          </div>

          <div class="ie-btns remove-btn" [class.hidden]="hideRemoveButton">
            <img
              src="assets/img/close-red.svg"
              (click)="$event.preventDefault(); $event.stopPropagation(); onRemove(element)"
              [matTooltip]="'Delete symbol from the list'"
              [matTooltipPosition]="'above'"
            >
          </div>
        </div>
      </td>
    </ng-container>

    <!--  map other columns - additional to default columns  -->
    <ng-container
      *ngFor="let column of additionalDisplayedColumns"
      [matColumnDef]="column"
    >
      <th *matHeaderCellDef mat-sort-header disableClear [ngClass]="column">
        {{ sortHeaderTitles[column] }}
      </th>
      <td mat-cell *matCellDef="let element" (click)="onSelectSymbol(element)" [ngClass]="column">
        <div class="cell-content borderSelection">
          {{ element[column] }}
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="allDisplayedColumns; sticky: true"
      class="tableHeaderRow table-header-row hidden"
    ></tr>
    <tr
      [id]="'scanner-tr-' + row.security_id"
      mat-row
      *matRowDef="let row; columns: allDisplayedColumns;"
      (click)="selectedRow = row;"
      [ngClass]="{ 'selected': row.id === selectedRow?.id }"
    ></tr>
  </table>
</cdk-virtual-scroll-viewport>
