import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ShowProgressModalInputModel, ShowProgressModalOutputModel } from './models';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { LoadingAnimationComponent } from '@c/shared/loading-animation/loading-animation.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-show-progress-modal',
  templateUrl: './show-progress-modal.component.html',
  styleUrls: ['./show-progress-modal.component.scss'],
  imports: [MatDialogModule, LoadingAnimationComponent, CommonModule]
})
export class ShowProgressModalComponent implements OnInit, OnDestroy {

  // TODO: use it to have a markup for progress;
  protected maxValue = 0;
  protected currentValue = 0;

  private _startupDate: Moment = moment();
  private _minimumTimeToStayMS = 1000;
  private _destroy$ = new Subject();
  constructor(
    private _dialogRef: MatDialogRef<ShowProgressModalComponent, ShowProgressModalOutputModel>,
    @Inject(MAT_DIALOG_DATA) protected data: ShowProgressModalInputModel,
  ) {

    if (data?.maximumValue) {
      this.maxValue = data.maximumValue;
    }

    if (data?.minimumTimeToStayMS || data?.minimumTimeToStayMS === 0) {
      this._minimumTimeToStayMS = data.minimumTimeToStayMS;
    }

    // if no progress observable but the min stay added - hide after the min stay
    if (!data?.process$ && data?.minimumTimeToStayMS) {
      setTimeout(() => {
        this.close();
      }, this._minimumTimeToStayMS);
    }

    if (data?.process$) {
      data.process$.pipe(takeUntil(this._destroy$)).subscribe(
        {
          next: (value) => this.currentValue = value,
          complete: () => this.close()
        }
      )
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this._startupDate = moment();
  }

  protected close(): void {

    const diff = moment().diff(this._startupDate, 'milliseconds', true);

    if (diff >= this._minimumTimeToStayMS) {
      this._dialogRef.close();
    }
    else {
      setTimeout(() => {
        this._dialogRef.close();

      }, this._minimumTimeToStayMS - diff);
    }
  }
}
