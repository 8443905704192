import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';

import { SnackBarComponent } from '@c/snack-bar/snack-bar.component';
import { VersionChangePopupComponent } from '@c/version-change-popup/version-change-popup.component';
import { HighlightSearchPipe } from '@core/pipes/highlight-text.pipe';
import { PercentOfNumberPipe } from '@core/pipes/percent-of-number.pipe';
import { SearchBarComponent } from '@m/common/search-bar/search-bar.component';
import { SymbolSearchModalComponent } from '@m/common/search-bar/symbol-search-modal/symbol-search-modal.component';
import { EconomicCalendarModalModule } from '@mdl/economic-calendar-modal/economic-calendar-modal.module';
import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';
import { MySettingsModalModule } from '@c/my-settings-modal/my-settings-modal.module';

@NgModule({
  declarations: [
    HighlightSearchPipe,
    PercentOfNumberPipe,
    SearchBarComponent,
    SymbolSearchModalComponent,
    SnackBarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    EconomicCalendarModalModule,
    FormDefaultsModule,
    MySettingsModalModule,
    MatRippleModule,
    VersionChangePopupComponent,
  ],
  exports: [
    HighlightSearchPipe,
    PercentOfNumberPipe,
    SearchBarComponent,
    SymbolSearchModalComponent,
    SnackBarComponent,
    VersionChangePopupComponent,
  ],
})
export class SharedModule {
}
