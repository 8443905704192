import { Injectable } from '@angular/core';
import { TLImportTimeoutMs, TLSendCommandTimeout, socketTimeoutMs, tradingLogDefaultTransactionType } from '@const';
import { environment } from '@env/environment';
import { TradingLogTransactionInfoFieldsModel } from '@mod/trading-log';
import { TradingLogAccountModel } from '@mod/trading-log/trading-log-account.model';
import {
  TLCommandResultModel,
  TLRequestAccountsCommandResultDataModel,
  TLRequestGroupsCommandResultDataModel,
  TLRequestStrategiesCommandResultDataModel,
  TLRequestTransactionsCommandResultDataModel,
} from '@mod/trading-log/trading-log-command-result.model';
import {
  ITradingLogStreamingServiceCommand,
  TLBathTransactionsCommand,
  TLDeleteAccountCommand,
  TLDeleteGroupCommand,
  TLDeleteStrategyCommand,
  TLRequestAccountsCommand,
  TLRequestGroupsCommand,
  TLRequestStrategiesCommand,
  TLUpdateAccountCommand,
  TLUpdateGroupCommand,
  TLUpdateStrategyCommand,
} from '@mod/trading-log/trading-log-commands.model';
import { TradingLogGroupSummaryModel } from '@mod/trading-log/trading-log-group-summary.model';
import { TradingLogGroupModel } from '@mod/trading-log/trading-log-group.model';
import { TradingLogStrategyModel } from '@mod/trading-log/trading-log-strategy.model';
import {
  TLStreamingServiceeventModel,
  TLStreamingServiceGroupSummaryUpdatedEventModel,
  TLStreamingServiceGroupsValueUpdatedEventModel,
  TLStreamingServiceTransactionDetailsEventModel,
  TLStreamingServiceTransactionsUpdatedEventModel,
} from '@mod/trading-log/trading-log-streaming-service-events.models';
import { TradingLogTransactionModel } from '@mod/trading-log/trading-log-transaction.model';
import { DialogsService } from '@s/common';
import { UserDataService } from '@s/user-data.service';
import { Writable } from '@t/common';
import { StringifiedGuid } from '@t/common/stringified-guid.type';
import { TradingLogTransactionStatus } from '@t/trading-log';
import { TradingLogGroupType } from '@t/trading-log/trading-log-group-type.enum';
import { TradingLogStreamingServiceCommandType } from '@t/trading-log/trading-log-streaming-service-command-type.enum';
import { TradingLogStreamingServiceCommand } from '@t/trading-log/trading-log-streaming-service-command.enum';
import { TradingLogStreamingServiceEvent } from '@t/trading-log/trading-log-streaming-service-event.enum';
import { getDateComparer } from '@u/comparers/date.comparer';
import { Guid } from 'guid-ts';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, timeout } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { groupArrayByKey } from '@u/utils';



@Injectable({ providedIn: 'root' })
export class TradingLogStreamingService {
  public groups$: BehaviorSubject<ReadonlyArray<Partial<TradingLogGroupModel>>> = new BehaviorSubject(null);
  public accounts$: BehaviorSubject<ReadonlyArray<TradingLogAccountModel>> = new BehaviorSubject([]);
  public strategies$: BehaviorSubject<ReadonlyArray<TradingLogStrategyModel>> = new BehaviorSubject([]);

  public groupsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public accountsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public strategiesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public summaryMap$: BehaviorSubject<Map<StringifiedGuid, TradingLogGroupSummaryModel>> =
    new BehaviorSubject(new Map<StringifiedGuid, TradingLogGroupSummaryModel>());

  public transactionsMap$: BehaviorSubject<Map<StringifiedGuid, ReadonlyArray<Partial<TradingLogTransactionModel>>>> =
    new BehaviorSubject(new Map<StringifiedGuid, ReadonlyArray<TradingLogTransactionModel>>());

  public transactionsInfoFieldsMap$: BehaviorSubject<Map<StringifiedGuid, ReadonlyArray<TradingLogTransactionInfoFieldsModel>>> =
    new BehaviorSubject(new Map<StringifiedGuid, ReadonlyArray<TradingLogTransactionInfoFieldsModel>>());
  public groupSymbols: ReadonlyArray<string> = [];

  public connectionError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private groups: ReadonlyArray<Partial<TradingLogGroupModel>> = [];
  private summaryData: Map<StringifiedGuid, TradingLogGroupSummaryModel> = new Map<StringifiedGuid, TradingLogGroupSummaryModel>();
  private transactionsData: Map<StringifiedGuid, ReadonlyArray<Partial<TradingLogTransactionModel>>>
    = new Map<StringifiedGuid, ReadonlyArray<TradingLogTransactionModel>>();

  private hasConnectedOnce = false;
  private socket: Socket = null;
  private commandHandlers: {
    [key: string]:
    {
      subject: Subject<any>;
      command: ITradingLogStreamingServiceCommand
    }
  } = {};

  public groupValueUpdateEvents$: Subject<TLStreamingServiceGroupsValueUpdatedEventModel> = new Subject();
  public transactionsValueUpdateEvents$: Subject<TLStreamingServiceTransactionsUpdatedEventModel> = new Subject();

  private subscriptionId = Guid.newGuid().toString();


  constructor(private userDataService: UserDataService,
  ) {
    this.subscribe(this.subscriptionId);
  }

  private unsubscribe(): void {
    if (!this.socket) {
      return;
    }

    this.socket.emit(TradingLogStreamingServiceCommand.Unsubscribe, this.subscriptionId);
    this.socket.close();
  }

  private subscribe(subscriptionId: StringifiedGuid): void {
    const token = this.userDataService.getAuthToken();
    this.socket = io(environment.WsBaseUrl, {
      transports: ['websocket'],
      auth: { token },
      timeout: socketTimeoutMs,
    });

    this.socket.on(TradingLogStreamingServiceEvent.Connect, () => {
      this.hasConnectedOnce = true;

      this.socket.emit(TradingLogStreamingServiceCommand.Subscribe, subscriptionId);

      combineLatest([
        this.sendCommand(new TLRequestGroupsCommand()),
        this.sendCommand(new TLRequestAccountsCommand()),
        this.sendCommand(new TLRequestStrategiesCommand()),
      ]).pipe(
        take(1),
      )
        .subscribe({
          error: (err) => this.connectionError$.next(true),
          next: () => this.connectionError$.next(false)
        })
    });

    this.socket.on(TradingLogStreamingServiceEvent.ConnectError, async (error) => {
      // If the first connect did not succeed, the error should be flagged.
      if (!this.hasConnectedOnce) {
        //TODO: add mechanism to re-connect and show error toast with timer. Also keep commands local and store to local storage to resend when connection appeared.
        throwError(error).subscribe();
        this.connectionError$.next(true)
      }
    });


    this.socket.on(TradingLogStreamingServiceEvent.GroupsUpdated, (event: TLStreamingServiceeventModel<TLStreamingServiceGroupsValueUpdatedEventModel>) => {
      if (event.success) {
        this.groupValueUpdateEvents$.next(event.data);
      }
    });

    this.socket.on(TradingLogStreamingServiceEvent.InfoFieldsUpdated, (event: TLStreamingServiceeventModel<TLStreamingServiceTransactionDetailsEventModel>) => {
      if (event.success) {
        const infoFieldsMap = this.transactionsInfoFieldsMap$.value;
        infoFieldsMap.set(event.data.group_id, event.data.transactionDetails);
        this.transactionsInfoFieldsMap$.next(infoFieldsMap);
      }
    });

    this.socket.on(TradingLogStreamingServiceEvent.AccountsUpdated, (event: TLStreamingServiceeventModel<TLRequestAccountsCommandResultDataModel>) => {
      if (event.success) {
        this.accounts$.next(event.data.accounts || []);
        let isGroupsShouldBeUpdated = false;
        this.groups.forEach(group => {
          if (!this.accounts$.value.find((existAccount) => existAccount.id === group.account_id)) {
            group.account_id = null;
            isGroupsShouldBeUpdated = true;
          }
        });

        if (isGroupsShouldBeUpdated) {
          this.groups$.next(this.groups);
        }
      }
    });

    this.socket.on(TradingLogStreamingServiceEvent.StrategiesUpdated, (event: TLStreamingServiceeventModel<TLRequestStrategiesCommandResultDataModel>) => {
      if (event.success) {
        this.strategies$.next(event.data.strategies || []);

        let isGroupsShouldBeUpdated = false;
        this.groups.forEach(group => {
          if (!this.strategies$.value.find((existStrategy) => existStrategy.id === group.strategy_id)) {
            group.strategy_id = null;
            isGroupsShouldBeUpdated = true;
          }
        });

        if (isGroupsShouldBeUpdated) {
          this.groups$.next(this.groups);
        }
      }
    });

    this.socket.on(TradingLogStreamingServiceEvent.TransactionsUpdated, (data: TLStreamingServiceTransactionsUpdatedEventModel) => {
      this.transactionsValueUpdateEvents$.next(data);
    });

    this.socket.on(TradingLogStreamingServiceEvent.SummaryUpdated,
      (model: TLStreamingServiceeventModel<TLStreamingServiceGroupSummaryUpdatedEventModel>) => {
        this.summaryData.set(model.data.group_id, model.data.summary);
        this.summaryMap$.next(this.summaryData);
      });

    this.socket.on(TradingLogStreamingServiceEvent.CommandResult, (result: TLCommandResultModel) => {
      if (result?.command?.command_id) {
        // if command id exist in service storage - then it's regular command
        if (this.commandHandlers[result.command.command_id]) {
          const commandHandler = this.commandHandlers[result.command.command_id];
          if (result.success) {
            commandHandler.subject.next(result.data);
            commandHandler.subject.complete();

            switch (commandHandler.command.type) {
              case TradingLogStreamingServiceCommandType.RequestGroups:
                const groupsData = result.data as TLRequestGroupsCommandResultDataModel;
                this.groups = this.processServerGroups(groupsData.groups || []);
                this.updateSymbols();
                this.updateGroupsStream();
                this.summaryData.clear();
                (groupsData.summary || []).forEach((summary) => this.summaryData.set(summary.group_id, summary));
                this.summaryMap$.next(this.summaryData);
                this.groupsLoaded$.next(true);
                break;
              case TradingLogStreamingServiceCommandType.UpdateGroup:
                break;
              case TradingLogStreamingServiceCommandType.DeleteGroup:
                break;
              case TradingLogStreamingServiceCommandType.RequestTransactions:
                const commandsData = result.data as TLRequestTransactionsCommandResultDataModel;

                if (commandsData.group_id) {
                  const sorted = [...commandsData.transactions]
                    .sort(getDateComparer((tr) => tr.created_date, 'millisecond'));
                  this.transactionsData.set(
                    commandsData.group_id,
                    [
                      ...sorted,
                      ...(this.transactionsData.get(commandsData.group_id) || [])
                        .filter((tr) => !sorted.find((savedTr) => savedTr.id === tr.id))
                    ]
                  );
                } else {
                  const groupedTransactions = groupArrayByKey([...commandsData.transactions], 'group_id');
                  this.transactionsData.clear();

                  Object.keys(groupedTransactions).forEach((item) => {
                    this.transactionsData.set(
                      item,
                      groupedTransactions[item].sort(getDateComparer((tr) => tr.created_date, 'millisecond'))
                    );
                  });
                }

                this.transactionsMap$.next(this.transactionsData);
                break;
              case TradingLogStreamingServiceCommandType.RequestAccounts:
                const accountsData = result.data as TLRequestAccountsCommandResultDataModel;
                this.accounts$.next(accountsData.accounts || []);
                this.accountsLoaded$.next(true);
                break;
              case TradingLogStreamingServiceCommandType.RequestStrategies:
                const strategiesData = result.data as TLRequestStrategiesCommandResultDataModel;
                this.strategies$.next(strategiesData.strategies || []);
                this.strategiesLoaded$.next(true);

                break;

              case TradingLogStreamingServiceCommandType.UpdateAccount:
                const updateAccountCommand = commandHandler.command as TLUpdateAccountCommand;
                const updatedAccount = this.accounts$.value.find((account) => account.id === updateAccountCommand.data.id);
                if (updatedAccount) {
                  updatedAccount.name = updateAccountCommand.data.name;
                  this.accounts$.next([...this.accounts$.value]);
                }
                else {
                  this.accounts$.next([...this.accounts$.value, {
                    id: updateAccountCommand.data.id,
                    name: updateAccountCommand.data.name,
                    metadata: updateAccountCommand.data.metadata,
                    calculation_method: updateAccountCommand.data.calculation_method
                  }]);
                }
                break;
              case TradingLogStreamingServiceCommandType.DeleteAccount:
                const deleteAccountCommand = commandHandler.command as TLDeleteAccountCommand;
                this.accounts$.next(this.accounts$.value.filter((acc) => acc.id !== deleteAccountCommand.data.id));
                let isGroupsWithRemovedAccountExist = false;
                this.groups.forEach(group => {
                  if (group.account_id === deleteAccountCommand.data.id) {
                    group.account_id = null;
                    isGroupsWithRemovedAccountExist = true;
                  }
                });

                if (isGroupsWithRemovedAccountExist) {
                  this.groups$.next(this.groups);
                }
                break;
              case TradingLogStreamingServiceCommandType.UpdateStrategy:
                const updateStrategyCommand = commandHandler.command as TLUpdateStrategyCommand;
                const updatedStrategy = this.strategies$.value.find((strategy) => strategy.id === updateStrategyCommand.data.id);
                if (updatedStrategy) {
                  updatedStrategy.name = updateStrategyCommand.data.name;
                  this.strategies$.next([...this.strategies$.value]);
                }
                else {
                  this.strategies$.next([...this.strategies$.value, {
                    id: updateStrategyCommand.data.id,
                    name: updateStrategyCommand.data.name,
                    is_default: false,
                    metadata: updateStrategyCommand.data.metadata,
                  }]);
                }
                break;
              case TradingLogStreamingServiceCommandType.DeleteStrategy:
                const deleteStrategyCommand = commandHandler.command as TLDeleteStrategyCommand;
                this.strategies$.next(this.strategies$.value.filter((str) => str.id !== deleteStrategyCommand.data.id));
                let isGroupsWithRemovedStrategyExist = false;
                this.groups.forEach(group => {
                  if (group.strategy_id === deleteStrategyCommand.data.id) {
                    group.strategy_id = null;
                    isGroupsWithRemovedStrategyExist = true;
                  }
                });

                if (isGroupsWithRemovedStrategyExist) {
                  this.groups$.next(this.groups);
                }
                break;
              default:
                break;

            }
          } else {
            commandHandler.subject.error({ message: result.description, command: commandHandler.command });
            commandHandler.subject.complete();
          }
          delete this.commandHandlers[result.command.command_id];
        }
        else { // if command id is not in storage - this should be a broadcasted event.

        }
      }
    });
  }

  public sendCommand(command: ITradingLogStreamingServiceCommand): Observable<any> {
    if (!this.socket.connected || this.connectionError$.value) {

      //TODO: don't throw error but keep the commant in local Storage to re-send it once connection appeared.

      return throwError(() => ({
        data: command,
        name: 'NotConnected',
        message: 'Socket is not connected'
      }));
    }

    this.socket.emit(TradingLogStreamingServiceCommand.SendCommand, command);
    const commandResult$: Subject<any> = new Subject();
    this.commandHandlers[command.command_id.toString()] = {
      command,
      subject: commandResult$
    };

    return commandResult$.pipe(timeout(TLSendCommandTimeout), catchError((error) => {
      error = error || {};
      error.data = command;
      if (error?.name === 'TimeoutError') {
        error.message = 'Trading Log command TimeOut error';
        // this.showErrorModal();
      }
      else {
        error.message = `Trading Log command error: ${error.message}`;
      }
      return throwError(() => error);
    }));
  }

  public addEmptyGroup(group: TradingLogGroupModel, type: TradingLogGroupType): Observable<StringifiedGuid> {
    this.groups = [group, ...this.groups$.value];
    const emptyTransaction = this.addEmptyTransaction(group.id);

    this.transactionsMap$.next(this.transactionsData);
    this.updateGroupsStream();

    return this.updateGroup(group).pipe(
      switchMap(() => {
        const command = new TLBathTransactionsCommand();
        command.appendTransaction(emptyTransaction);
        return this.sendBunchTransactionCommand(command).pipe(
          switchMap(() => of(group.id))
        );
      },
      )
    );
  }

  public deleteGroup(group: TradingLogGroupModel): Observable<any> {
    const result = this.sendCommand(new TLDeleteGroupCommand(group));
    this.groups = this.groups.filter((gr) =>
      gr.id !== group.id);
    this.updateSymbols();
    this.updateGroupsStream();

    this.transactionsData.delete(group.id);
    this.transactionsMap$.next(this.transactionsData);
    return result;
  }

  public updateGroup(group: TradingLogGroupModel): Observable<any> {
    const fixedGroup = {
      ...group,
      account_id: (this.accounts$.value.find((acc) => acc.id === group.account_id) ? group.account_id : null),
      strategy_id: (this.strategies$.value.find((str) => str.id === group.strategy_id) ? group.strategy_id : null),
    };
    let isStreamShouldBeUpdated = false;
    this.groups = this.groups.map((gr) => {
      if (gr.id === group.id) {
        isStreamShouldBeUpdated = gr.type !== group.type;
        const grWritable = gr as Writable<TradingLogGroupModel>;
        grWritable.account_id = fixedGroup.account_id;
        grWritable.has_error = fixedGroup.has_error;
        grWritable.isEmpty = fixedGroup.isEmpty;
        grWritable.isLocalError = fixedGroup.isLocalError;
        grWritable.is_expanded = fixedGroup.is_expanded;
        grWritable.order = fixedGroup.order;
        grWritable.strategy_id = fixedGroup.strategy_id;
        grWritable.symbol = fixedGroup.symbol;
        grWritable.type = fixedGroup.type;
        return grWritable;
      }
      return gr;
    });
    this.updateSymbols();
    if (isStreamShouldBeUpdated) {
      this.updateGroupsStream();
    }
    return this.sendCommand(new TLUpdateGroupCommand(fixedGroup));
  }

  public applyGroupChangesToStream(): void {
    this.updateGroupsStream();
  }

  public addEmptyTransaction(groupId: StringifiedGuid): Partial<TradingLogTransactionModel> {

    const newTransaction: Partial<TradingLogTransactionModel> = {
      id: Guid.newGuid().toString(),
      group_id: groupId,
      type: tradingLogDefaultTransactionType,
      has_error: false,
      status: TradingLogTransactionStatus.None
    };

    const transactions = this.transactionsData.has(groupId) ?
      [...this.transactionsData.get(groupId), newTransaction] :
      [newTransaction];

    this.transactionsData.set(groupId, transactions);
    return newTransaction;
  }

  public sendBunchTransactionCommand(command: TLBathTransactionsCommand): Observable<any> {

    command.data.transactions.forEach((transactionToChange) => {
      if (transactionToChange.type === TradingLogStreamingServiceCommandType.DeleteTransaction) {
        const existTransactions = this.transactionsData.get(transactionToChange.data.group_id);
        const updatedTransactions = existTransactions.filter((tr) =>
          tr.id !== transactionToChange.data.id && tr.parent_id !== transactionToChange.data.id);

        this.transactionsData.set(transactionToChange.data.group_id, updatedTransactions);
      }
      else if (transactionToChange.type === TradingLogStreamingServiceCommandType.UpdateTransaction) {
        const existTransactions = this.transactionsData.get(transactionToChange.data.group_id);
        if (existTransactions.find((tr) => tr.id === transactionToChange.data.id)) {
          const updatedTransactions = existTransactions.map((tr) => (tr.id !== transactionToChange.data.id ? tr :
            { ...transactionToChange.data }));

          this.transactionsData.set(transactionToChange.data.group_id, updatedTransactions);

        }
        else {
          this.transactionsData.set(transactionToChange.data.group_id, [...existTransactions, transactionToChange.data]);
        }
      }
    });

    return this.sendCommand(command);
  }

  private processServerGroups(groups: ReadonlyArray<TradingLogGroupModel>): ReadonlyArray<TradingLogGroupModel> {
    return groups.map((gr) => ({
      ...gr,
      is_expanded: !!gr.is_expanded
    }));
  }

  private updateGroupsStream(): void {
    this.groups$.next(this.groups);
  }

  private updateSymbols(): void {
    this.groupSymbols = this.groups
      .reduce((acc: string[], currentGroup: Partial<TradingLogGroupModel>): string[] => {
        if (currentGroup.symbol && currentGroup.symbol.trim()) {
          acc.push(currentGroup.symbol?.trim() ?? '');
        }
        return acc;
      }, []).sort();
  }
}
