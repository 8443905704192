import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, from, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RestRequestorService } from '@s/rest-requestor.service';
import { StorageKeys, WatchlistType } from '@const';
import { Response } from '@core1/interface';
import { IWatchlistItem } from '@mod/watchlist/watchlist.model';

@Injectable({ providedIn: 'root' })
export class WatchlistService {
  public updateWatchlistSignal$ = new Subject<null>();

  private storageKeys: Record<WatchlistType, string> = {
    [WatchlistType.Wheel]: StorageKeys.WheelWatchlistData,
    [WatchlistType.Wtf]: StorageKeys.WtfWatchlistData,
    [WatchlistType.PowerX]: StorageKeys.PowerXWatchlistData,
    [WatchlistType.Dividends]: StorageKeys.DividendsWatchlistData,
    [WatchlistType.StockScreener]: StorageKeys.StockScreenerWatchlistData,
    [WatchlistType.ShortSellingStocks]: StorageKeys.ShortSellingStocksWatchlistData,
    [WatchlistType.ShortingStocksScanner]: StorageKeys.ShortingStocksScannerWatchlistData,
    [WatchlistType.DividendsStrategy]: StorageKeys.DividendsStrategyWatchlistData,
  };

  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService
  ) {
    this.clearWatchlistCache();
  }

  public insert = (
    securityId: number,
    watchlistType: WatchlistType
  ): Observable<Response<{ insertId: number }>> => {
    return this.http.post<Response<{ insertId: number }>>('/v2/watchlistData', { security_id: securityId, type: watchlistType })
      .pipe(
        tap(() => {
          this.clearWatchlistCache(watchlistType);
          this.updateWatchlistSignal$.next(null);
        }),
        catchError(this.handleError)
      );
  }

  public batchInsert = (
    securityIDs: number[],
    watchlistType: WatchlistType
  ): Observable<Response<void>> => {
    return this.http.post<Response>('/v2/watchlistData/batch_insert', { security_ids: securityIDs, type: watchlistType })
      .pipe(
        tap(() => {
          this.clearWatchlistCache(watchlistType);
          this.updateWatchlistSignal$.next(null);
        }),
        catchError(this.handleError)
      );
  }

  public get = (
    watchlistType: WatchlistType,
    ignoreCache: boolean = false
  ): Observable<IWatchlistItem[]> => {
    return from(
      this.restRequesterService.makeRequest(
        this.storageKeys[watchlistType],
        () => firstValueFrom(this.http.get<Response<IWatchlistItem[]>>(`/v2/watchlistData/${watchlistType}`)),
        ignoreCache
      )
    )
      .pipe(
        map((response) => response.result),
        catchError(this.handleError)
      );
  }

  public remove = (watchlistId: number, watchlistType?: WatchlistType): Observable<Response<void>> => {
    return this.http.delete<Response>(`/v2/watchlistData/${watchlistId}`)
      .pipe(
        tap(() => {
          this.clearWatchlistCache(watchlistType);
          this.updateWatchlistSignal$.next(null);
        }),
        catchError(this.handleError)
      );
  }

  private clearWatchlistCache(watchlistType?: WatchlistType): void {
    if (watchlistType) {
      this.restRequesterService.clear(this.storageKeys[watchlistType]);

      return;
    }

    this.restRequesterService.clear(this.storageKeys[WatchlistType.Wheel]);
    this.restRequesterService.clear(this.storageKeys[WatchlistType.Wtf]);
    this.restRequesterService.clear(this.storageKeys[WatchlistType.PowerX]);
  }

  private handleError(error: any) {
    if (error.status === 400) {
      return throwError(error.error);
    } else {
      return throwError(error || 'Server error');
    }
  }
}
