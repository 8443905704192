import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TabletMinWidth, allExchangeCountryCodes } from '@const';
import { DialogsService } from '@s/common';
import { ObservableService } from '@s/observable.service';
import { SearchPopupService } from '@s/search-popup.service';

@Component({
  selector: 'app-powerx',
  templateUrl: './powerx.component.html',
  styleUrls: ['./powerx.component.scss'],
})
export class PowerxComponent implements OnInit, OnDestroy {
  public showMaintenance = false;
  public width: number = window.innerWidth;

  protected showOrderPanel = false;

  private subscriptions = new Subscription();

  constructor(
    private dialogsService: DialogsService,
    private searchPopupService: SearchPopupService,
    private observableService: ObservableService,
  ) {}

  @HostListener('window:keyup', ['$event'])
  onCharsInput(e: KeyboardEvent) {
    const element = e.target as HTMLElement;
    if (element && element.hasAttribute('data-ignore-search-popup')) {
      return;
    }

    const isSymbol = e.key?.length === 1 && !!e.key?.match(/[a-z]/i);
    if (isSymbol) {
      this.searchPopupService.openPopup(e.key, true, false, false, allExchangeCountryCodes);
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.width = event.target.innerWidth;
    this.handlePinnedOrderModal();
  }

  async ngOnInit(): Promise<void> {
    this.showMaintenance = this.observableService.showMaintenance.getValue();
    this.handleShowOrderPanel();

    this.subscriptions.add(
      this.observableService.showMaintenance.subscribe((showMaintenance) =>
        this.showMaintenance = showMaintenance)
    );

    this.subscriptions.add(
      this.observableService.tradingPanelOrderModalPinned.subscribe((_) =>
        this.handleShowOrderPanel())
    );

    this.subscriptions.add(
      this.observableService.tradingPanelOrderInput.subscribe((_) =>
        this.handleShowOrderPanel())
    );
  }

  ngOnDestroy() {
    this.observableService.tradeReportTradingStrategyId.next(null);
    this.subscriptions.unsubscribe();
  }

  handleShowOrderPanel() {
    const isPinned = this.observableService.tradingPanelOrderModalPinned.getValue();
    const orderInput = this.observableService.tradingPanelOrderInput.getValue();

    this.showOrderPanel = isPinned && !!orderInput;
  }

  private handlePinnedOrderModal() {
    if (!this.showOrderPanel || this.width > TabletMinWidth) {
      return;
    }

    this.observableService.tradingPanelOrderModalPinned.next(false);

    const orderInput = this.observableService.tradingPanelOrderInput.getValue();
    this.dialogsService.openTradingPanelOrderDialog(orderInput);
  }
}
