import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';
import { TradingLogTradesFilterFormComponent } from './trading-log-trades-filter-form.component';
import { InputMultiSelectComponent } from '@c/shared/input-multi-select/input-multi-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormDefaultsModule,
    MatTooltipModule,
    MatMenuModule,
    InputMultiSelectComponent,
  ],
  exports: [TradingLogTradesFilterFormComponent],
  declarations: [TradingLogTradesFilterFormComponent]
})
export class TradingLogTradesFilterFormModule { }
