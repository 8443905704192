<mat-form-field
  appearance="outline"
  subscriptSizing="dynamic"
  floatLabel="auto"
  class="wheel-summary-panel-wrapper wheel-summary-panel field-container"
  [class.hide-always]="(!isLoaded && !isError) || itemsLength === 0"
>
  <mat-label class="wheel-summary-panel-label">
    Select symbol trades
    <button
      mat-icon-button
      class="small-btn primary-bg toggle-square-shape close-open-item-btn component-btn wheel-summary-panel-badge"
      [matBadge]="itemsLength"
      matBadgeColor="primary"
      matBadgePosition="above after"
      matBadgeSize="small"
    >
      <span class="arrow-wrapper">
        <div class="close-open-arrow"></div>
      </span>
    </button>
  </mat-label>

  <mat-select
    hideSingleSelectionIndicator
    remove-aria-owns
    [formControl]="itemControl"
    panelClass="wheel-summary-panel-overlay scroll-style"
  >
    <mat-select-trigger>
      <div class="option-container hide-trades" *ngIf="itemControl.value?.isDoNotShowTrades">
        <div>Do not show trades</div>
        <button
          mat-icon-button
          class="small-btn primary-bg toggle-square-shape close-open-item-btn component-btn wheel-summary-panel-badge"
          [matBadge]="itemsLength"
          matBadgeColor="primary"
          matBadgePosition="above after"
          matBadgeSize="small"
        >
          <span class="arrow-wrapper">
            <div class="close-open-arrow"></div>
          </span>
        </button>
      </div>
      <div class="option-container" *ngIf="!itemControl.value?.isDoNotShowTrades">
        <app-trading-log-group-summary-info
          [allowButtonsEvents]="true"
          [data]="itemControl.value"
          [groupTypeDisplay]="'Trades'"
          [breakEvenButtonState]="stateItem()?.showBreakEven"
          [costBasisButtonState]="stateItem()?.showCostBasis"
          (breakEvenStateChanged)="breakEvenStateChanged($event)"
          (costBasisStateChanged)="costBasisStateChanged($event)"
        >
        </app-trading-log-group-summary-info>
        <button
          mat-icon-button
          class="small-btn primary-bg toggle-square-shape close-open-item-btn component-btn wheel-summary-panel-badge"
          [matBadge]="itemsLength"
          matBadgeColor="primary"
          matBadgePosition="above after"
          matBadgeSize="small"
        >
          <span class="arrow-wrapper">
            <div class="close-open-arrow"></div>
          </span>
        </button>
      </div>
    </mat-select-trigger>
    <mat-option [value]="doNotShowTradesValue">
      <div class="option-container hide-trades">
        <div>Do not show trades</div>
      </div>
    </mat-option>
    <mat-option
      *ngFor="let item of items$ | async; trackBy: trackItems"
      [value]="item"
      [ngClass]="item.groupTypeDisplay"
    >
      <div class="option-container">
        <app-trading-log-group-summary-info [data]="item" [groupTypeDisplay]="item.groupTypeDisplay">
        </app-trading-log-group-summary-info>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="loading-container" *ngIf="!isLoaded && !isError">
  <mat-spinner class="blue-spinner" [diameter]="30"></mat-spinner>
</div>

<div class="error-container" *ngIf="isError">
  <mat-icon [svgIcon]="'warning'" class="icon danger xl-icon"></mat-icon>
  <div class="text">Trading Log integration failed by unknown reason. <a (click)="reloadPage()">Reload page</a></div>
</div>
