import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAccessType, UserSettingsLocal } from '@const';
import { CompareDataWindow } from '@core1/business/compareDatawinow';
import { ObservableService } from '@core1/directives/observable.service';
import { environment } from '@env/environment';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService as ObservableService2 } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Response } from 'src/app/core/interface/response';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  apiUrl = `/users`;
  constructor(private http: HttpClient,
    private _userDataService: UserDataService,
    private _observableService: ObservableService,
    private _observableService2: ObservableService2,
    private _localStorageService: LocalStorageService,
  ) {
  }

  // method to check the emailid and password verification for login
  async login(email: string, password: string, loginId: string): Promise<any> {
    const response = await fetch(`${environment.BaseUrl}/v2/users/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        loginId
      })
    });

    return await response.json();
  }

  // method to check the Licence Agreement
  addLicenceAgreementTag(userId: number) {
    return this.http.post(`${this.apiUrl}/add-licence-agreement-tag`, {
      userId
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }

  verifyLicenceAgreementTag(email: string) {
    return this.http.post(`${this.apiUrl}/verify-licence-agreement-tag`, {
      email
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }

  getUserAccessType() {
    return this.http.get(`${this.apiUrl}/get-user-access-type`
    ).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }

  getUserMMTradesFlag() {
    return this.http.get(`${this.apiUrl}/mm-trades-flag`
    ).pipe(
      map((res: any) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }

  public impersonate(id: number): void {
    const loginId = uuidv4();
    this.http.post<any>(`/v2/users/impersonate`, {
      loginId,
      user_id: id
    }).subscribe(async (res) => {
      localStorage.clear();

      const user = res.result;
      this._localStorageService.set(UserSettingsLocal.LoginId, loginId);

      this._userDataService.setAuthToken(user.token);
      this._userDataService.setAuthTokenExpirationDate(user.tokenExpirationDate || '');

      delete user.accessType; // To prevent access type in local storage

      user.tradingLogTradesInfoColumnsShowDevFieldsValue = this._observableService2.tradingLogTradesInfoColumnsShowDevFieldsValue.value;
      localStorage.setItem('user', JSON.stringify(user));
      this._observableService.userSetting.next(user);

      const plChartOption: any = {
        firstSchema: new CompareDataWindow().setProfitLossValue(user.leftVariant).schema,
        firstSchemaName: new CompareDataWindow().setProfitLossValue(user.leftVariant).schemaName,
        secondSchema: new CompareDataWindow().setProfitLossValue(user.rightVariant).schema,
        secondSchemaName: new CompareDataWindow().setProfitLossValue(user.rightVariant).schemaName,
      };

      localStorage.setItem('theme', user.theme == 0 ? 'dark' : 'light');
      this._observableService.setTheme(user.theme == 0 ? 'dark' : 'light');
      this._observableService.theme.next(user.theme == 0 ? 'dark' : 'light');
      localStorage.setItem('exchange', 'us');
      localStorage.setItem('profitloss', JSON.stringify(plChartOption));
      localStorage.setItem(
        'comparePosition',
        JSON.stringify({
          first: user.leftVariant,
          second: user.rightVariant,
        })
      );

      this._observableService.comparePostion.next({
        first: user.leftVariant,
        second: user.rightVariant,
      });
      localStorage.setItem('selectedTrade', 'none');
      localStorage.setItem('selectedEntryExit', 'none');

      window.location.href = '';
    });
  }

  public getImpersonateDetails(): null | { userName: string; email: string, showDevInfoFieldsValue: boolean } {
    try {

      const userStr = localStorage.getItem('user');
      const user = JSON.parse(userStr);

      return !user?.isImpersonated ? null : {
        email: user.email,
        userName: `${(user?.firstName || '')}${(user?.firstName ? ' ' : '')}${user?.lastName || ''}`,
        showDevInfoFieldsValue: user.tradingLogTradesInfoColumnsShowDevFieldsValue
      };
    } catch (error) {
      return null;
    }
  }

  updateUserDetailsByAdmin(user_id: string, firstName: string, lastName: string, status: number, access_type: UserAccessType, email: string) {
    return this.http.put(`${this.apiUrl}/update-userDetails-by-admin`, {
      user_id, firstName, lastName, status, access_type, email
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }), catchError(this.handleError));
  }

  updateHoliday(id, name, observed_date) {
    // console.log("observed_date: ",observed_date)
    return this.http.put(`${this.apiUrl}/holiday`, {
      id, name, observed_date
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }), catchError(this.handleError));
  }

  // method to send the email of current password to user's email
  forgotPassword(email) {
    return this.http.post(`${this.apiUrl}/forgotpassword?email=${email}`, {
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }), catchError(this.handleError));
  }

  private handleError(error: any) {
    if (error.status === 400) {
      return throwError(error.error);
    } else {
      return throwError(error || 'Server error');
    }
  }

  getTradierFlag() {
    return this.http.get(`${this.apiUrl}/getTradierFlag`
    ).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }

  updateTradierFlag(userId: string, tradier_flag_pxo: number) {
    return this.http.put(`${this.apiUrl}/updateTradierFlag`, {
      userId, tradier_flag_pxo
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }

  updateThemeSetting(theme: number) {
    return this.http.put(`${this.apiUrl}/theme-setting`, {
      theme
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }));
  }
}
