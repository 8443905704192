import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TradingLogImportTransactionModel } from '@mod/trading-log/import';
import { Observable, map, of } from 'rxjs';
import { Response } from '@core1/interface';

@Injectable({
  providedIn: 'root'
})
export class UploadTradesFileService {

  constructor(private _http: HttpClient) { }

  postFile(fileToUpload: File): Observable<Array<TradingLogImportTransactionModel>> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this._http.post<Response<Array<TradingLogImportTransactionModel>>>('/v2/tradingLog/readFromFile', formData)
      .pipe(
        map((response) => response?.result ?? [])
      )
  }
}
