import { Features, TabNames } from '@const';
import { LoginNavigationControlValueEnum, LoginRedirectPageModel } from '@c/my-settings-modal/my-settings-modal.model';

export const LoginRedirectPageList: LoginRedirectPageModel[] = [
  {
    feature: Features.PowerX,
    displayName: 'PowerX Analyzer',
    tabName: TabNames.PowerX,
    displayOption: true
  },
  {
    feature: Features.Wheel,
    displayName: 'The Wheel Analyzer',
    tabName: TabNames.Wheel,
    displayOption: true
  },
  {
    feature: Features.BullCallSpread,
    displayName: 'Bull Call Spread Analyzer',
    tabName: TabNames.BullCallSpread,
    displayOption: true
  },
  {
    feature: Features.Wtf,
    displayName: 'WTF Analyzer',
    tabName: TabNames.Wtf,
    displayOption: true
  },
  {
    feature: Features.TradingLog,
    displayName: 'Trading Log',
    tabName: TabNames.TradingLog,
    displayOption: true
  },
  {
    feature: Features.AssetCorrelation,
    displayName: 'Asset Correlation',
    tabName: TabNames.AssetCorrelation,
    displayOption: false
  },
  {
    feature: Features.MMTrades,
    displayName: 'M&M Trades',
    tabName: TabNames.MMTrades,
    displayOption: false
  },
  {
    feature: Features.EarningsAnalysis,
    displayName: 'Earnings Analysis',
    tabName: TabNames.EarningsAnalysis,
    displayOption: false
  },
  {
    feature: Features.Heatmap,
    displayName: 'Heatmap',
    tabName: TabNames.Heatmap,
    displayOption: false
  },
  {
    feature: Features.TradingPanel,
    displayName: 'Trading Panel',
    tabName: TabNames.TradingPanel,
    displayOption: false
  },
  {
    feature: Features.DividendsStrategy,
    displayName: 'Dividends Analyzer',
    tabName: TabNames.DividendsStrategy,
    displayOption: false
  },
  {
    feature: Features.StockScreeners,
    displayName: 'Stock Screener',
    tabName: TabNames.StockScreeners,
    displayOption: false
  },
  {
    feature: Features.ShortSellingStocks,
    displayName: 'SOL Analyzer',
    tabName: TabNames.ShortSellingStocks,
    displayOption: false
  },
  {
    feature: Features.ShortingStocksScanner,
    displayName: 'Shorting Scanner',
    tabName: TabNames.ShortingScanner,
    displayOption: false
  },
  {
    feature: Features.VideoHub,
    displayName: 'Video Hub',
    tabName: TabNames.VideoHub,
    displayOption: false
  },
  {
    feature: Features.MasterCalendar,
    displayName: 'Master Calendar',
    tabName: TabNames.MasterCalendar,
    displayOption: false
  },
  {
    feature: Features.HolidaysCalendar,
    displayName: 'Holidays Calendar',
    tabName: TabNames.HolidaysCalendar,
    displayOption: false
  },
  {
    feature: Features.EconomicCalendar,
    displayName: 'Economic Calendar',
    tabName: TabNames.EconomicCalendar,
    displayOption: false
  },
  {
    feature: Features.EarningsCalendar,
    displayName: 'Earnings Calendar',
    tabName: TabNames.EarningsCalendar,
    displayOption: false
  },
  {
    feature: Features.DividendsCalendar,
    displayName: 'Dividends Calendar',
    tabName: TabNames.DividendsCalendar,
    displayOption: false
  }
];

export const DefaultLoginNavigationControlValue = LoginNavigationControlValueEnum.LastPage;
