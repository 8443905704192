import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../core/interface/response';
import { LocalStorageService } from './local-storage.service';
import { PastPerformanceService } from './past-performance.service';
import { StorageKeys } from '../constants';
import { RestRequestorService } from './rest-requestor.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProcessedDataService {

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private pastPerformanceService: PastPerformanceService,
    private restRequestorService: RestRequestorService) { }

  get = async (security_id) => {
    const storageKey = `${StorageKeys.ProcessedData}_${security_id}`;
    let processedData = this.localStorageService.getFromMemory(storageKey);

    if (!processedData) {
      const pastPerformance = await this.pastPerformanceService.compareStrategies(security_id);

      const date = pastPerformance && pastPerformance.length
        ? moment.utc(pastPerformance[0].date).format('YYYY-MM-DD')
        : moment().utc().format('YYYY-MM-DD');
      
        const { result } = await this.restRequestorService.makeRequest(storageKey, 
          () => this.http.get<Response>(`/v2/processedData?security_id=${security_id}&date=${date}`).toPromise());

      processedData = {};
      for (let i = 0; i < result.length; i++) {
        processedData[moment.utc(result[i].date).format('YYYY-MM-DD')] = result[i];
      }
      this.localStorageService.setToMemory(storageKey, processedData);
    }

    return processedData;
  }
}
