import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ObservableService } from '@s/observable.service';
import { EasternTimeZoneName, Themes } from '@const';
import * as moment from 'moment';
import { DeepWriteable, RawStudyMetaInfoId, StudyPlotInformation, StudyPlotType } from '@chart/charting_library';

@Injectable()
export class BarColor {
  constructor(
    private observableService: ObservableService,
  ) {
  }

  ploatBarColor(PineJS, name: string,
                description: string,
                getProcessedDataStorageKey,
                localStorageService: LocalStorageService
  ) {
    const observableService = this.observableService;
    return {
      name,
      metainfo: {
        isTVScript: false,
        isTVScriptStub: false,
        scriptIdPart: '',
        _metainfoVersion: 42,
        id: 'BarColoring@tv-basicstudies-1' as RawStudyMetaInfoId,
        name,
        description: name,
        shortDescription: description,
        is_price_study: true,
        is_hidden_study: true,
        isCustomIndicator: true,
        format: {
          type: 'price' as DeepWriteable<'price'>,
          precision: 4
        },


        defaults: {
          precision: 4,
          palettes: {
            palette_0: {
              // palette colors
              // change it to the default colors that you prefer,
              // but note that the user can change them in the Style tab
              // of indicator properties
              colors: [
                { color: '#131722' }, // black
                { color: '#33A69A' }, // green
                { color: '#EF534F' }, // red
                { color: '#FFFFFF' }, // white
                { color: '#2090EA' }, // Blue
              ]
            }
          }
        },
        inputs: [],
        plots: [{
          id: 'plot_0',

          // plot type should be set to 'bar_colorer'
          type: StudyPlotType.BarColorer,

          // this is the name of the palette that is defined
          // in 'palettes' and 'defaults.palettes' sections
          palette: 'palette_0'
        }] as DeepWriteable<Readonly<StudyPlotInformation>>[],
        palettes: {
          palette_0: {
            colors: [
              { name: 'Color 0' },
              { name: 'Color 1' },
              { name: 'Color 2' },
              { name: 'Color 3' },
              { name: 'Color 4' },
            ],

            // the mapping between the values that
            // are returned by the script and palette colors
            valToIndex: {
              0: 0,
              1: 1,
              2: 2,
              3: 3,
              4: 4,
            }
          }
        }
      },
      constructor: function () {
        this.init = async function (context, inputCallback) {
          this.processedData = localStorageService.getFromMemory(getProcessedDataStorageKey());
        },

          this.main = function (context, input) {
            const barDate = moment(PineJS.Std.time(context)).tz(EasternTimeZoneName);

            if (!barDate.isValid() || !this.processedData) {
              return;
            }

            const item = this.processedData[barDate.format('YYYY-MM-DD')];

            // unknow bar - Blue color
            if (!item) {
              return [4];
            }

            const theme: Themes = observableService.theme.value;
            const blackBarCondition = item.bar_color === 'B' || !item.bar_color || !item.macd_color || !item.rsi_color || !item.stochastic_color;
            let color: number = 2;
            switch (true) {
              case blackBarCondition && theme === Themes.Light:
                color = 0;
                break;
              case blackBarCondition && theme === Themes.Dark:
                color = 3;
                break;
              case item.bar_color === 'G':
                color = 1;
                break;
            }
            return [color];
          }
      }
    };
  }
}
