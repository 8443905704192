<div
  *ngIf="operationStatus.showStatus && !operationStatus.isFailed"
  class="alert-container success-container">
  <mat-icon [svgIcon]="'success'" class="alert-icon middle-icon"></mat-icon>
  <p>Password has been changed.</p>
</div>
<div
  *ngIf="operationStatus.showStatus && operationStatus.isFailed"
  class="alert-container">
  <mat-icon [svgIcon]="'alert-error'" class="middle-icon"></mat-icon>
  <p>Operation failed. Please try again later.</p>
</div>
<div class="change-password-container">
  <div *ngIf="!isChangePasswordVisible;else changePasswordFields" class="change-password-item-single">
    <div class="change-password-title">
      <span class="hidden-passwords-title">PASSWORD</span>
      <span class="change-password-field-text hidden-password">........</span>
    </div>
    <button mat-flat-button color="primary" class="fill-btn large-btn change-password-btn" (click)="changePasswordFieldsVisibility(true)">Change
    </button>
  </div>
</div>

<ng-template #changePasswordFields>
  <form [formGroup]="form">
    <div *ngFor="let field of passwordFields"
      class="password-input-container">
      <div class="change-password-item">
        <p class="change-password-field-text">{{changePasswordLabels[field]}}</p>
        <div
          [ngClass]="{
        'invalid-input': passwordsStates[field].showError,
        'show-password': !passwordsStates[field].isHidden}"
          class="change-password-input">
          <input
            formControlName="{{field}}"
            maxlength="50"
            [type]="passwordsStates[field].isHidden ? 'password' : 'text'"
            (input)="hideError(field)"
            (keydown.space)="$event.preventDefault()">
          <mat-icon
            [ngClass]="{'show-password-icon': !passwordsStates[field].isHidden}"
            (click)="changePasswordValueVisibility(passwordsStates[field])">{{passwordsStates[field].isHidden ? 'visibility' : 'visibility_off'}}</mat-icon>
        </div>
      </div>
      <mat-error>
        <span *ngIf="passwordsStates[field].showError">{{changePasswordErrors[field]}}</span>
      </mat-error>
    </div>
    <div class="change-password-item actions-section">
      <p class="change-password-field-text">
        <mat-icon [svgIcon]="'attention_icon'" class="middle-icon"></mat-icon>
        <span>Passwords must be at least 6 characters</span>
      </p>
      <button
        mat-button
        [disabled]="isLoading"
        class="border-btn large-btn change-password-btn"
        (click)="changePasswordFieldsVisibility(false)">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="fill-btn large-btn change-password-btn"
        (click)="changePassword()">
        <mat-spinner *ngIf="isLoading" class="white-spinner" diameter="25"></mat-spinner>
        <ng-container *ngIf="!isLoading">Save</ng-container>
      </button>
    </div>
  </form>
</ng-template>
