import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { UserAccessType } from '@const';
import { ObservableService } from '@s/observable.service';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelGuard  {
  constructor(
    private _observableService: ObservableService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { access_type } = this._observableService.mySettings.getValue() || {};

    return access_type === UserAccessType.Admin || access_type === UserAccessType.Support;
  }

}
