  <youtube-player
    class="youtube-player"
    [videoId]="videoId()"
    [loadApi]="true"
    (stateChange)="onStateChange($event)"
    [width]="'320'"
    [playerVars]="youtubePlayerVars"
    [height]="'220'"
    #player
  ></youtube-player>
