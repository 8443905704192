import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CircularProgressModalComponent } from '@mdl/common/circular-progress-modal/circular-progress-modal.component';
import { LoadingAnimationComponent } from '@c/shared/loading-animation/loading-animation.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, LoadingAnimationComponent],
  exports: [ConfirmDialogComponent, CircularProgressModalComponent],
  declarations: [ConfirmDialogComponent, CircularProgressModalComponent],
  providers: [],
})
export class CommonModalsModule {}
