<div id="data-window-container" class="wheel-table-right data-window-content scroll-style">
  <div
    class="top-indicator"
    inViewport
    [inViewportOptions]="{ threshold: [0], partial: true }"
    (inViewportAction)="onChangeVisibility($event, 'top')"
  ></div>

  <app-symbol-details-panel
    [symbol$]="currentSymbol$"
    [watchlistType]="watchlistTypes.Wheel"
    [smileyListType]="smileyListType.Wheel"
    [securityDetailsMap]="securityDetailsMap$ | async"
    [elementsVisibilityConfig]="{ showExpectedMove: true }"
    (symbolSelected)="onSelectSymbolFromDataWindow($event)"
  >
    <ng-container>
      <app-expected-move
        *appHideForUnavailableFeature="features.ExpectedMove; else: expectedMovePlaceholder"
        [securityId]="currentSymbol$ | async"
        [showWheelChartSettings]="true"
        [liveDataIntervalSeconds]="30"
        class="expected-move-container"
      ></app-expected-move>

      <ng-template #expectedMovePlaceholder>
        <div class="expected-move-placeholder">
          <button
            mat-button
            (click)="redirectToExpectedMoveDemoPage()"
            class="expected-move-demo-button locked-for-current-edition"
          >
            Expected Move
          </button>
        </div>
      </ng-template>
    </ng-container>
  </app-symbol-details-panel>

  <app-income-statement [securityId]="currentSymbol$ | async"></app-income-statement>

  <div class="list-table weel-tables">
    <div class="tableScroll scroll-data visible-scroll" id="standardScroll" *ngIf="strikePrice.length > 0">
      <table>
        <colgroup>
          <col span="1">
          <col *ngFor="let group of colGroupsByDate" [span]="group">
        </colgroup>

        <tbody>
        <tr>
          <th>Strike Price, $</th>
          <td *ngFor="let strike of strikePrice; index as i" class="strike">
            <div class="strike-inner">
              <span>{{ strike.value }}</span>
              <div
                class="option-source"
                *ngIf="sources[i]"
                [matTooltip]="sourcesTooltipText[sources[i]]"
                [ngClass]="{ 'conservative': sources[i] === 'C', 'aggressive': sources[i] === 'A' }"
              >
                {{ sources[i] }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div>
              Premium, $
              <div class="center-in-tbl">
                <span class="redText">Min</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  [matTooltip]="'Minimum required premium to get 30% ROI'"
                  viewBox="0 0 16 16"
                  [matTooltipPosition]="'below'"
                  [matTooltipHideDelay]="0"
                  class="help-icon-tbl"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
                  />
                </svg>
              </div>
            </div>
          </th>
          <td class="font-RB" *ngFor="let premium of premium">
            {{ premium.value | number: '1.2-2' }}
            <p class="redText smallFont">
              {{ premium.value1 ? (premium.value1 | number: '1.2-2') : 0.00 }}
            </p>
          </td>
        </tr>
        <tr>
          <th>Expiration</th>
          <td *ngFor="let exp of expiration">{{ exp.value }}</td>
        </tr>
        <tr>
          <th>Drop in, %</th>
          <td class="font-blur" *ngFor="let dr of drop">
            {{ dr.value | number: '1.0-0' }}
          </td>
        </tr>
        <!--  commented code, do not remove, can be returned  -->
<!--        <tr>-->
<!--          <th>-->
<!--            <div class="center-in-tbl">-->
<!--              DTE, Days-->
<!--              <svg-->
<!--                xmlns="http://www.w3.org/2000/svg"-->
<!--                [matTooltip]="'DTE - Days to expiration. The number of days until an option expires.'"-->
<!--                viewBox="0 0 16 16"-->
<!--                [matTooltipPosition]="'below'"-->
<!--                [matTooltipHideDelay]="0"-->
<!--                class="help-icon-tbl"-->
<!--              >-->
<!--                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />-->
<!--                <path-->
<!--                  d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"-->
<!--                />-->
<!--              </svg>-->
<!--            </div>-->
<!--          </th>-->
<!--          <td class="font-blur" *ngFor="let dt of dte">-->
<!--            {{ dt.value | number: '1.0-0' }}-->
<!--          </td>-->
<!--        </tr>-->
        <tr>
          <th>Buying Power, $</th>
          <td class="font-blur" *ngFor="let bp of buyingPower">
            {{ bp.value | number: '1.0-0' }}
          </td>
        </tr>
        <tr>
          <th>Delta</th>
          <td class="font-blur" *ngFor="let bp of delta">
            {{ bp.value ? (bp.value | number: '1.2-2') : "n/a" }}
          </td>
        </tr>
        <tr>
          <th>
            <div class="center-in-tbl">ROI, %
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                   [matTooltip]="roiLegend"
                   [matTooltipPosition]="'below'"
                   [matTooltipHideDelay]="0"
                   class="help-icon-tbl">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
            </div>
          </th>
          <td *ngFor="let ann of annularized">
            <div
              [ngClass]="{
                'meet-box': ann.value >= roiLowerBound && ann.value <= roiUpperBound,
                'does-not': !ann.value || ann.value < roiLowerBound,
                'warning': ann.value > roiUpperBound
              }"
            >
              {{ ann.value | number: '1.0-0' }}
            </div>
          </td>
        </tr>
        <tr *ngIf="strikePrice.length > 0">
          <th></th>
          <td class="plus-box" *ngFor="let stock of strikePrice; let i = index" (click)="onSelectSymbol(i)">
            <div class="center-plus">
              <button mat-button class="calculator-plus-btn">Add</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="strikePrice.length === 0" class="no-options-wrapper">
      <div class="no-options">
        <p class="title">No options found because</p>
        <ul class="details list">
          <li>Symbol does not have weekly options or</li>
          <li>The earnings date is before the expiration date or</li>
          <li>The annualized ROI for the options is less than 5% or</li>
          <li>Any of your filter selection are filtering out the options</li>
        </ul>
      </div>
    </div>

    <div
      class="bottom-indicator"
      inViewport
      [inViewportOptions]="{ threshold: [0], partial: true }"
      (inViewportAction)="onChangeVisibility($event, 'bottom')"
    ></div>
  </div>
</div>
