import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MultiSelectComponent } from '@c/shared/multi-select/multi-select.component';
import { TradingLogStreamingService } from '@s/trading-log/trading-log-streaming.service';
import * as _ from 'lodash';
import { Subscriber } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TradingLogNotDefinedAccountId } from '@const';

import { IPortfolioFiltersState } from './portfolio-filters-form.model';

const NOT_DEFINED_TITLE = '(not defined)';
const NOT_DEFINED_ID = 'null';

@Component({
  selector: 'app-portfolio-filters-form',
  templateUrl: './portfolio-filters-form.component.html',
  styleUrls: ['./portfolio-filters-form.component.scss'],
})
export class PortfolioFiltersFormComponent implements OnDestroy, AfterViewInit {
  @Input() set filtersState(state: IPortfolioFiltersState) {
    this.currentFiltersState = {
      accounts: state.accounts.map((item) => item !== null ? item : NOT_DEFINED_ID),
      strategies: state.strategies.map((item) => item !== null ? item : NOT_DEFINED_ID),
    };

    if (!_.isEqual(this.currentFiltersState.accounts, this.accountsFormControl.value)) {
      this.accountsFormControl.setValue(this.currentFiltersState.accounts);
    }

    if (!_.isEqual(this.currentFiltersState.strategies, this.strategiesFormControl.value)) {
      this.strategiesFormControl.setValue(this.currentFiltersState.strategies);
    }
  }

  @Output() filtersChanged = new EventEmitter<IPortfolioFiltersState>();

  @ViewChild('accountsSelect') accountsSelect: MultiSelectComponent<string>;
  @ViewChild('strategiesSelect') strategiesSelect: MultiSelectComponent<string>;

  protected currentFiltersState: IPortfolioFiltersState = {
    accounts: [],
    strategies: [],
  };

  protected accountsFormControl = new FormControl<IPortfolioFiltersState['accounts']>([]);
  protected strategiesFormControl = new FormControl<IPortfolioFiltersState['strategies']>([]);

  private subscriptions = new Subscriber();

  protected accountOptions$ = this.tradingLogStreamingService.accounts$
    .pipe(
      map((accounts) => {
        const notDefinedAccountOption = { title: NOT_DEFINED_TITLE, value: NOT_DEFINED_ID };

        return [
          notDefinedAccountOption,
          ...[...accounts]
            .filter((a) => a.id !== TradingLogNotDefinedAccountId)
            .sort((a, b) => a.name.localeCompare(b.name)) // ASC or DESC
            .map((strategy) => ({ title: strategy.name, value: strategy.id })),
        ];
      }),
      tap((availableAccountOptions) => {
        const availableAccountOptionsIDs = availableAccountOptions.map((item) => item.value);
        const availableSelectedAccountOptions = this.currentFiltersState.accounts.filter((item) => {
          return availableAccountOptionsIDs.includes(item);
        });

        this.accountsFormControl.setValue(availableSelectedAccountOptions);
        if (this.accountsSelect) {
          // set options manually to avoid form-control reset in built-in method
          setTimeout(() => {
            // @ts-ignore
            this.accountsSelect._data = [...availableAccountOptions];
          }, 0);
        }
      }),
    );

  protected strategyOptions$ = this.tradingLogStreamingService.strategies$
    .pipe(
      map((strategies) => {
        const notDefinedStrategyOption = { title: NOT_DEFINED_TITLE, value: NOT_DEFINED_ID };

        return [
          notDefinedStrategyOption,
          ...[...strategies]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((strategy) => ({ title: strategy.name, value: strategy.id })),
        ];
      }),
      tap((availableStrategyOptions) => {
        const availableStrategyOptionsIDs = availableStrategyOptions.map((item) => item.value);
        const availableSelectedAccountOptions = this.currentFiltersState.strategies.filter((item) => {
          return availableStrategyOptionsIDs.includes(item);
        });
        this.strategiesFormControl.setValue(availableSelectedAccountOptions);

        if (this.strategiesSelect) {
          // set options manually to avoid form-control reset in input/setter
          setTimeout(() => {
            // @ts-ignore
            this.strategiesSelect._data = [...availableStrategyOptions];
          }, 0);
        }
      }),
    );

  constructor(
    private tradingLogStreamingService: TradingLogStreamingService,
  ) {
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.accountOptions$.subscribe());
    this.subscriptions.add(this.strategyOptions$.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected onChange(): void {
    this.filtersChanged.emit({
      accounts: this.accountsFormControl.value.map((item) => item !== NOT_DEFINED_ID ? item : null),
      strategies: this.strategiesFormControl.value.map((item) => item !== NOT_DEFINED_ID ? item : null),
    });
  }
}
