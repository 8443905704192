import {
  DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE
} from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.data';
import {
  DividendsStrategyScannerFilterModel,
  ExDatePeriodOptions,
} from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.model';
import {
  MAX_AVG_BREAK_EVEN_DAYS_VALUE,
  MAX_DIV_PER_SHAPE_VALUE,
  MAX_DIV_YIELD_PERCENT_VALUE,
  MAX_MARKET_CAP_VALUE, MAX_ROI_VALUE,
  MAX_STOCK_PRICE_VALUE,
  MAX_VOLUME_VALUE,
  MIN_AVG_BREAK_EVEN_DAYS_VALUE,
  MIN_DIV_PER_SHAPE_VALUE,
  MIN_DIV_YIELD_PERCENT_VALUE,
  MIN_MARKET_CAP_VALUE,
  MIN_ROI_VALUE,
  MIN_STOCK_PRICE_VALUE,
  MIN_VOLUME_VALUE,
} from '@constants/scanner-filters';
import { DividendsPaymentPeriods } from '@mod/dividends-strategy/dividends-strategy.model';
import { isValidNumberLikeString, isValidShortNumberLikeString } from '@u/filters';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';

export function validateAndFillDividendsStrategyFiltersState(
  restoredState: Partial<DividendsStrategyScannerFilterModel>,
  defaultState: DividendsStrategyScannerFilterModel = DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE,
): DividendsStrategyScannerFilterModel {
  const availableFlags = [
    Flags.Yes,
    Flags.No,
    Flags.Maybe,
    Flags.None,
    Flags.Never,
  ];
  const availableBreakEvenOptions = [1, 2, 3, 4, 5];
  const availableExDatePeriodOptions = [
    ExDatePeriodOptions.NextDay,
    ExDatePeriodOptions.Next5Days,
    ExDatePeriodOptions.Next10Days,
    ExDatePeriodOptions.Next30Days,
    ExDatePeriodOptions.ThisWeek,
    ExDatePeriodOptions.NextWeek,
    ExDatePeriodOptions.ThisMonth,
  ];
  const availableDivFrequencyOptions = [
    DividendsPaymentPeriods.Weekly,
    DividendsPaymentPeriods.Monthly,
    DividendsPaymentPeriods.Quarterly,
    DividendsPaymentPeriods.SemiAnnual,
    DividendsPaymentPeriods.Annual,
    DividendsPaymentPeriods.Other,
  ];

  const flags = 'flags' in restoredState && Array.isArray(restoredState.flags)
    ? restoredState.flags.filter((flag) => availableFlags.includes(flag))
    : [...defaultState.flags];

  const breakEvenPeriodYears =
    'breakEvenPeriodYears' in restoredState && availableBreakEvenOptions.includes(restoredState.breakEvenPeriodYears)
      ? restoredState.breakEvenPeriodYears
      : defaultState.breakEvenPeriodYears;

  const isExDatePeriodValid =
    'exDatePeriod' in restoredState && availableExDatePeriodOptions.includes(restoredState.exDatePeriod?.value);
  // to validate custom period details - from/to, when it's added for user
  const exDatePeriod = isExDatePeriodValid
    ? { ...restoredState.exDatePeriod, details: { ...restoredState.exDatePeriod.details } }
    : { ...defaultState.exDatePeriod, details: { ...defaultState.exDatePeriod.details } };

  const divPerShareFrom =
    'divPerShareFrom' in restoredState &&
    isValidNumberLikeString(restoredState.divPerShareFrom, MIN_DIV_PER_SHAPE_VALUE, MAX_DIV_PER_SHAPE_VALUE, false)
      ? restoredState.divPerShareFrom
      : defaultState.divPerShareFrom;
  const divPerShareTo =
    'divPerShareTo' in restoredState &&
    isValidNumberLikeString(restoredState.divPerShareTo, MIN_DIV_PER_SHAPE_VALUE, MAX_DIV_PER_SHAPE_VALUE, false)
      ? restoredState.divPerShareTo
      : defaultState.divPerShareTo;

  const divYieldPercentFrom =
    'divYieldPercentFrom' in restoredState &&
    isValidNumberLikeString(restoredState.divYieldPercentFrom, MIN_DIV_YIELD_PERCENT_VALUE, MAX_DIV_YIELD_PERCENT_VALUE, false)
      ? restoredState.divYieldPercentFrom
      : defaultState.divYieldPercentFrom;
  const divYieldPercentTo =
    'divYieldPercentTo' in restoredState &&
    isValidNumberLikeString(restoredState.divYieldPercentTo, MIN_DIV_YIELD_PERCENT_VALUE, MAX_DIV_YIELD_PERCENT_VALUE, false)
      ? restoredState.divYieldPercentTo
      : defaultState.divYieldPercentTo;

  const dividendPaymentPeriodOptions =
    'dividendPaymentPeriodOptions' in restoredState && Array.isArray(restoredState.dividendPaymentPeriodOptions)
      ? restoredState.dividendPaymentPeriodOptions.filter((symbol) => availableDivFrequencyOptions.includes(symbol))
      : [...defaultState.dividendPaymentPeriodOptions];

  const stockPriceFrom =
    'stockPriceFrom' in restoredState &&
    isValidNumberLikeString(restoredState.stockPriceFrom, MIN_STOCK_PRICE_VALUE, MAX_STOCK_PRICE_VALUE, false)
      ? restoredState.stockPriceFrom
      : defaultState.stockPriceFrom;

  const stockPriceTo =
    'stockPriceTo' in restoredState &&
    isValidNumberLikeString(restoredState.stockPriceTo, MIN_STOCK_PRICE_VALUE, MAX_STOCK_PRICE_VALUE, false)
      ? restoredState.stockPriceTo
      : defaultState.stockPriceTo;

  const avgBreakEvenDaysFrom =
    'avgBreakEvenDaysFrom' in restoredState &&
    isValidNumberLikeString(restoredState.avgBreakEvenDaysFrom, MIN_AVG_BREAK_EVEN_DAYS_VALUE, MAX_AVG_BREAK_EVEN_DAYS_VALUE, true)
      ? restoredState.avgBreakEvenDaysFrom
      : defaultState.avgBreakEvenDaysFrom;
  const avgBreakEvenDaysTo =
    'avgBreakEvenDaysTo' in restoredState &&
    isValidNumberLikeString(restoredState.avgBreakEvenDaysTo, MIN_AVG_BREAK_EVEN_DAYS_VALUE, MAX_AVG_BREAK_EVEN_DAYS_VALUE, true)
      ? restoredState.avgBreakEvenDaysTo
      : defaultState.avgBreakEvenDaysTo;

  const roiFrom =
    'roiFrom' in restoredState && isValidNumberLikeString(restoredState.roiFrom, MIN_ROI_VALUE, MAX_ROI_VALUE, false)
      ? restoredState.roiFrom
      : defaultState.roiFrom;
  const roiTo =
    'roiTo' in restoredState && isValidNumberLikeString(restoredState.roiTo, MIN_ROI_VALUE, MAX_ROI_VALUE, false)
      ? restoredState.roiTo
      : defaultState.roiTo;

  const isVolumeFromValid =
    'volumeFrom' in restoredState &&
    isValidShortNumberLikeString(restoredState.volumeFrom.visibleValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false) &&
    isValidNumberLikeString(restoredState.volumeFrom.filterValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false);
  const isVolumeToValid =
    'volumeTo' in restoredState &&
    isValidShortNumberLikeString(restoredState.volumeTo.visibleValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false) &&
    isValidNumberLikeString(restoredState.volumeTo.filterValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false);

  const isMarketCapFromValid =
    'marketCapFrom' in restoredState &&
    isValidShortNumberLikeString(
      restoredState.marketCapFrom.visibleValue,
      MIN_MARKET_CAP_VALUE,
      MAX_MARKET_CAP_VALUE,
      false,
    ) &&
    isValidNumberLikeString(restoredState.marketCapFrom.filterValue, MIN_MARKET_CAP_VALUE, MAX_MARKET_CAP_VALUE, false);
  const isMarketCapToValid =
    'marketCapTo' in restoredState &&
    isValidShortNumberLikeString(
      restoredState.marketCapTo.visibleValue,
      MIN_MARKET_CAP_VALUE,
      MAX_MARKET_CAP_VALUE,
      false,
    ) &&
    isValidNumberLikeString(restoredState.marketCapTo.filterValue, MIN_MARKET_CAP_VALUE, MAX_MARKET_CAP_VALUE, false);

  return {
    ...defaultState,
    flags,
    breakEvenPeriodYears,
    exDatePeriod,
    divPerShareFrom,
    divPerShareTo,
    divYieldPercentFrom,
    divYieldPercentTo,
    dividendPaymentPeriodOptions,
    stockPriceFrom,
    stockPriceTo,
    avgBreakEvenDaysFrom,
    avgBreakEvenDaysTo,
    roiFrom,
    roiTo,
    volumeFrom: isVolumeFromValid ? { ...restoredState.volumeFrom } : { ...defaultState.volumeFrom },
    volumeTo: isVolumeToValid ? { ...restoredState.volumeTo } : { ...defaultState.volumeTo },
    marketCapFrom: isMarketCapFromValid ? { ...restoredState.marketCapFrom } : { ...defaultState.marketCapFrom },
    marketCapTo: isMarketCapToValid ? { ...restoredState.marketCapTo } : { ...defaultState.marketCapTo },
  };
}
