import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { Response } from '@core1/interface';
import { ServerNotificationsSettingsModel, ServerNotificationTypes } from '@mod/server-notifications';

@Injectable({
  providedIn: 'root'
})
export class ServerNotificationsSettingsService {
  public currentServerNotificationsSettings$ = new BehaviorSubject<Array<ServerNotificationsSettingsModel>>([]);

  constructor(
    private http: HttpClient,
  ) { }

  public async getServerNotificationsSettings(): Promise<ServerNotificationsSettingsModel[]> {
    const settings = await firstValueFrom(
      this.http.get<Response<ServerNotificationsSettingsModel[]>>('/v2/userNotificationSettings'),
    );

    this.currentServerNotificationsSettings$.next(settings.result);

    return settings.result;
  }

  public async setServerNotificationsSetting(
    type: ServerNotificationTypes,
    isActive: boolean,
  ): Promise<void> {
    const currentSettings = this.currentServerNotificationsSettings$.getValue();
    const isExist = currentSettings.some((setting) => setting.type === type);

    let updatedSettings: ServerNotificationsSettingsModel[] = [...currentSettings];

    if (isExist) {
      updatedSettings = currentSettings.map((setting) => {
        return setting.type === type
          ? { ...setting, is_active: isActive ? 1 : 0 }
          : setting;
      });
    } else {
      updatedSettings = [
        ...currentSettings,
        { id: 0, type, is_active: isActive ? 1 : 0, user_id: 0, created_date: '', updated_date: '' },
      ];
    }

    this.currentServerNotificationsSettings$.next(updatedSettings);

    await firstValueFrom(
      this.http.post<Response>('/v2/userNotificationSettings', { type, is_active: isActive ? 1 : 0 }),
    );
  }
}
