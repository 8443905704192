export interface IWheelStateFromRedirect {
  symbol: {
    symbol: string;
    security_id: number;
  };
}

export enum WheelTabs {
  Chart = 1,
  Calculator = 2,
}
