import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { formatDecimalOrZero, formatPrice, MomentDateTimeFormats } from '@const';

import { TradingLogGroupSummaryInfoInputModel, TradingLogGroupSummaryInfoModel } from './trading-log-group-summary-info.model';

@Component({
  standalone: true,
  selector: 'app-trading-log-group-summary-info',
  templateUrl: './trading-log-group-summary-info.component.html',
  styleUrls: ['./trading-log-group-summary-info.component.scss'],
  imports: [CommonModule, MatIconModule, MatCheckboxModule]
})
export class TradingLogGroupSummaryInfoComponent implements OnInit {

  @Input() set data(val: TradingLogGroupSummaryInfoInputModel) {

    const feesAndComm = (val?.fees || 0) + (val?.commission || 0);
    const minDateDisplay = val?.minDate?.format(MomentDateTimeFormats.ReadableDate) ?? '';
    const maxDateDisplay = val?.maxDate?.format(MomentDateTimeFormats.ReadableDate) ?? '';
    this.model = {
      accountName: val?.accountName,
      strategyName: val?.strategyName,
      minDateDisplay,
      maxDateDisplay: minDateDisplay !== maxDateDisplay ? maxDateDisplay : '',
      costAvg: val?.cost_average ? (Math.round(val.cost_average) === val.cost_average ? formatPrice(val?.cost_average, 0) : formatPrice(val?.cost_average, 2)) : '-',
      costAvgClass: this.getClassByValue(val?.cost_average),
      breakEven: val?.break_even ? (Math.round(val.break_even) === val.break_even ? formatPrice(val?.break_even, 0) : formatPrice(val?.break_even, 2)) : '-',
      breakEvenClass: this.getClassByValue(val?.break_even),
      shares: val?.shares ? formatDecimalOrZero(val?.shares, 0, 6) : '0',
      sharesClass: this.getClassByValue(val?.shares),
      buyContracts: val?.buy_contracts ? formatDecimalOrZero(val?.buy_contracts, 0, 6) : '-',
      buyContractsClass: this.getClassByValue(val?.buy_contracts),
      sellContracts: val?.sell_contracts ? formatDecimalOrZero(val?.sell_contracts, 0, 6) : '-',
      sellContractsClass: this.getClassByValue(val?.sell_contracts),
      feesAndComm: feesAndComm ? formatPrice(feesAndComm, 0) : '0',
      feesAndCommClass: this.getClassByValue(feesAndComm * -1),
      premium: val?.premium ? formatPrice(val?.premium, 0) : '0',
      premiumClass: this.getClassByValue(val?.premium),
      dividends: val?.dividends ? formatPrice(val?.dividends, 0) : '0',
      dividendsClass: this.getClassByValue(val?.dividends),
      buyingPower: val?.buying_power ? formatPrice(val?.buying_power, 0) : '0',
      profit: val?.profit ? formatPrice(val?.profit, 0) : '0',
      profitClass: this.getClassByValue(val?.profit),
    };
  }

  @Input() groupTypeDisplay = '';
  @Input() allowButtonsEvents = false;
  @Input() costBasisButtonState = true;
  @Input() breakEvenButtonState = true;

  @Output() costBasisStateChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() breakEvenStateChanged: EventEmitter<boolean> = new EventEmitter();
  protected model: TradingLogGroupSummaryInfoModel = null;

  constructor() { this.data = null; }

  ngOnInit(): void { }

  protected costBasisButtonClick(event: MouseEvent): void {
    if (!this.allowButtonsEvents) {
      return;
    }

    event.stopPropagation();
    this.costBasisButtonState = !this.costBasisButtonState;
    this.costBasisStateChanged.emit(this.costBasisButtonState);
  }

  protected breakEvenButtonClick(event: MouseEvent): void {
    if (!this.allowButtonsEvents) {
      return;
    }

    event.stopPropagation();
    this.breakEvenButtonState = !this.breakEvenButtonState;
    this.breakEvenStateChanged.emit(this.breakEvenButtonState);
  }

  private getClassByValue(value: number | undefined): string {
    return !value ? '' : value > 0 ? 'profit' : 'lose';
  }
}
