import { Injectable } from '@angular/core';
import { TradingLogSymbolSummaryPanelStateType } from '@c/trading-log/shared';
import { ExchangeCountriesCodes } from '@const';
import { WheelService } from '@m1/wheel/wheel.service';
import { TradingLogGroupModel, tradingLogSymbolSummaryPanelStateDataItemDefaultValue } from '@mod/trading-log';
import { SymbolsService } from '@s/symbols.service';
import { TradingLogShowOnTheWheelChartResultType } from '@t/trading-log';

@Injectable()
export class TradingLogSummaryPanelService {
  constructor(
    private _symbolsService: SymbolsService,
    private _wheelService: WheelService,
  ) {}

  public async showGroupOnTheWeelChart(
    group: TradingLogGroupModel,
    state: TradingLogSymbolSummaryPanelStateType,
  ): Promise<TradingLogShowOnTheWheelChartResultType> {
    if (!group.symbol) {
      return 'EmptySymbol';
    }

    const symbolObj = await this._symbolsService.getBySymbol(group.symbol, ExchangeCountriesCodes.US);
    if (!symbolObj?.symbol) {
      return 'NoChartData';
    }

    const stateItem = state[symbolObj.symbol] ?? { ...tradingLogSymbolSummaryPanelStateDataItemDefaultValue };
    stateItem.selectedGroupId = group.id;
    if (stateItem.selectedGroupId) {
      stateItem.isDoNotShowTrades = false;
    }

    await this._wheelService.setSymbolForWheelPage({ symbol: symbolObj.symbol, security_id: symbolObj.security_id });

    return 'Ok';
  }
}
