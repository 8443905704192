import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { NgxEditorModule } from 'ngx-editor';

import { IconsModule } from '@core/icons.module';
import { TextareaFitContentWidthDirective } from '@core/directives/textarea-fit-content-width/textarea-fit-content-width.directive';
import { ClickOutsideDirective } from '@core/directives/click-outside/click-outside.directive';
import { TradingHubComponent } from '@c/trading-hub/trading-hub.component';
import { ToDoListComponent } from '@c/trading-hub/to-do-list/to-do-list.component';
import { TradingPlanComponent } from '@c/trading-hub/trading-plan/trading-plan.component';
import { TradingPlanTabContentComponent } from '@c/trading-hub/trading-plan-tab-content/trading-plan-tab-content.component';
import { PreTradingChecklistComponent } from '@c/trading-hub/pre-trading-checklist/pre-trading-checklist.component';
import { NoDataContentComponent } from '@c/trading-hub/no-data-content/no-data-content.component';
import { ChatComponent } from '@c/trading-hub/chat/chat.component';
import { ChatConversationContentComponent } from '@c/trading-hub/chat-conversation-content/chat-conversation-content.component';
import { ChatCodyComponent } from '@c/trading-hub/chat-cody/chat-cody.component';
import { ChatAnswerFeedbackModalComponent } from '@mdl/chat-answer-feedback-modal/chat-answer-feedback-modal.component';
import { ChatCodySecondComponent } from '@c/trading-hub/chat-cody-second/chat-cody-second.component';
import { ChatCodySecondConversationComponent } from '@c/trading-hub/chat-cody-second-conversation/chat-cody-second-conversation.component';
import { MagicBallComponent } from '@c/trading-hub/magic-ball';
import { TradingHubSharedModule } from '@c/trading-hub/trading-hub-shared.module';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import { ResizeDirectiveModule } from '@core/directives/resize-directive/resize-directive.module';
import { locals } from '@c/trading-hub/trading-plan-tab-content/ngx-editor.data';
import { QuoteOfDayComponent } from '@c/trading-hub/quote-of-day/quote-of-day.component';

@NgModule({
  declarations: [
    TradingHubComponent,
    ToDoListComponent,
    TradingPlanComponent,
    TradingPlanTabContentComponent,
    PreTradingChecklistComponent,
    NoDataContentComponent,
    ChatComponent,
    ChatCodyComponent,
    ChatConversationContentComponent,
    ChatAnswerFeedbackModalComponent,
    ChatCodySecondComponent,
    ChatCodySecondConversationComponent,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    IconsModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    DragDropModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgxEditorModule.forRoot({ locals }),
    TradingHubSharedModule,
    MagicBallComponent,
    ReactiveFormsModule,
    MatDividerModule,
    EditionsSharedModule,
    MatDialogModule,
    MatRadioModule,
    ResizeDirectiveModule,
    QuoteOfDayComponent,
    TextareaFitContentWidthDirective,
  ],
  exports: [
    TradingHubComponent
  ]
})
export class TradingHubModule { }
