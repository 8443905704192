import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { TradingLogConfidenceModel, TradingLogDecisionModel } from '@mod/trading-log/feelings';
import { ObservableService } from '@s/observable.service';
import { getNumberComparer } from '@u/comparers';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-select-feelings-form',
  templateUrl: './select-feelings-form.component.html',
  styleUrls: ['./select-feelings-form.component.scss'],
  imports: [CommonModule, MatRadioModule, MatIconModule, FormsModule, ReactiveFormsModule]
})
export class SelectFeelingsFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() set selectedConfidenceId(value: string) {
    this._confidenceId$.next(value);
  }

  @Input() set selectedDecisionId(value: string) {
    this._decisionId$.next(value);
  }

  @Output() decisionIdSelected: EventEmitter<string> = new EventEmitter();
  @Output() confidenceIdSelected: EventEmitter<string> = new EventEmitter();

  protected isLoaded = false;
  protected confidenceOptions: ReadonlyArray<TradingLogConfidenceModel> = [];
  protected decisionOptions: ReadonlyArray<TradingLogDecisionModel> = [];

  protected decisionIdFormControl: FormControl<string> = new FormControl<string>(null);
  protected confidenceIdFormControl: FormControl<string> = new FormControl<string>(null);
  protected decisionSubItemsFormControl: FormControl<string> = new FormControl<string>(null);

  private _destroy$: Subject<void> = new Subject();
  private _confidenceId$: Subject<string> = new Subject();
  private _decisionId$: Subject<string> = new Subject();

  constructor(
    private _observableService: ObservableService,
    private _cdr: ChangeDetectorRef
  ) {
    this.confidenceIdFormControl.valueChanges.pipe(
      takeUntil(this._destroy$)
    ).subscribe((confidenceId) => this.confidenceIdSelected.emit(confidenceId));

    this.decisionIdFormControl.valueChanges.pipe(
      takeUntil(this._destroy$)
    ).subscribe((decisionId) => this.selectDecisionOption(decisionId));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    this._confidenceId$.complete();
    this._decisionId$.complete();
  }

  ngAfterViewInit(): void {
    combineLatest([this._observableService.tradingLogFeelingsData.pipe(
      filter((result) => !!result),
      takeUntil(this._destroy$)
    ),
    this._confidenceId$, this._decisionId$])
      .subscribe(([data, confidenceId, decisionId]) => {
        // filter incoming data to show isDisabled only if user selected disaled option
        // and sort by order with sub objects
        this.confidenceOptions = [...data.confidenceOptions.filter((conf) => (!conf.isDisabled || conf.id === confidenceId))]
          .sort(getNumberComparer((item) => item.order));
        this.decisionOptions = data.decisionOptions
          .filter((des) => (!des.isDisabled || des.id === decisionId))
          // sort sub collections first
          .map((item) => ({
            ...item, items: (item.items
              ? [...item.items.filter((des) => (!des.isDisabled || des.id === decisionId))]
                .sort(getNumberComparer((subItem) => subItem.order))
              : null)
          }))
          .sort(getNumberComparer((item) => item.order));

        if (confidenceId) {
          const existConfidenceId = this.confidenceOptions.find((item) => item.id === confidenceId)?.id ?? null;
          this.confidenceIdFormControl.setValue(existConfidenceId, { emitEvent: existConfidenceId === null });
        }

        if (decisionId) {
          const existDecision = this.decisionOptions.find((item) => item.id === decisionId);
          // if first-level decision found
          if (existDecision) {
            this.decisionIdFormControl.setValue(decisionId, { emitEvent: false });

            if (existDecision.items?.length) {
              // If there is new default value appeared the select it and send the event about
              const defaultItem = existDecision.items.find(({ isDefault }) => !!isDefault);
              if (defaultItem?.id) {
                this.decisionSubItemsFormControl.setValue(defaultItem.id, { emitEvent: false });
                this.decisionIdSelected.emit(defaultItem.id);
              }
            }
          }
          else {
            // .. if not - try to find second level decission
            let existDecisionId = '';
            let existSubDecisionId = '';

            this.decisionOptions.forEach((decision) => {
              if (decision.items?.length) {
                const existSubDecision = decision.items.find((item) => item.id === decisionId);
                if (existSubDecision) {
                  existDecisionId = decision.id;
                  existSubDecisionId = existSubDecision.id;
                }
              }
            });
            if (existDecisionId && existSubDecisionId) {
              this.decisionSubItemsFormControl.setValue(existSubDecisionId, { emitEvent: false });
              this.decisionIdFormControl.setValue(existDecisionId, { emitEvent: false });
            }
            else {
              // if the id was not found - set the null and send the event about
              this.decisionSubItemsFormControl.setValue(null, { emitEvent: false });
              this.decisionIdFormControl.setValue(null, { emitEvent: false });
              this.decisionIdSelected.emit(null);
            }
          }
        }
        this.isLoaded = true;
      });
    this._cdr.detectChanges();
  }

  ngOnInit() {

  }

  protected selectDecisionOption(decisionId: string, parentId?: string): void {
    if (parentId) {
      const selectedParent = this.decisionOptions.find(({ id }) => id === parentId);
      this.decisionIdSelected.emit(decisionId);

      if (selectedParent) {
        this.decisionIdFormControl.setValue(parentId, { emitEvent: false });
        this.decisionSubItemsFormControl.setValue(decisionId, { emitEvent: false });
      }
    }
    else {
      const selectedParent = this.decisionOptions.find(({ id }) => id === decisionId);
      if (!selectedParent) {
        this.decisionIdSelected.emit(null);
        this.decisionSubItemsFormControl.setValue(null, { emitEvent: false });
        this.decisionIdFormControl.setValue(null, { emitEvent: false });
      }
      else {
        if (selectedParent.items?.length) {
          const defaultSubItem = selectedParent.items.find(({ isDefault }) => !!isDefault);
          if (defaultSubItem?.id) {
            this.decisionSubItemsFormControl.setValue(defaultSubItem.id, { emitEvent: false });
            this.decisionIdSelected.emit(defaultSubItem.id);
          }
          else {
            this.decisionSubItemsFormControl.setValue(null, { emitEvent: false });
            this.decisionIdSelected.emit(decisionId);
          }
        }
        else {
          this.decisionSubItemsFormControl.setValue(null, { emitEvent: false });
          this.decisionIdSelected.emit(decisionId);
        }
      }
    }
  }
}
