<section class="login--page">
  <div class="form-section">
    <div class="content-wrapper">
      <span class="large-shape"></span>
      <span class="small-shape"></span>
      <div class="login-form">
        <div class="logo-wrapper">
          <img src="assets/img/newImg/logo.svg" class="logo" title="Logo" />
        </div>
        <div class="form-wrapper">
          <h2>
            <span class="wellcome">Welcome to</span>
            <span class="project-name">PowerX Optimizer</span>
          </h2>
          <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
            <div class="error-container upper">
              <mat-error  *ngIf="(loginForm.pristine || isDelay) && isSessionExpired">
                <span  class="show-error"> Your session has expired. Please log in. <br><br></span>
              </mat-error>

              <mat-error *ngIf="(loginForm.pristine || isDelay) && isConcurrentLogin">
                <span  class="show-error"> You have been logged out, because your account has been logged in on another device. <br><br></span>
              </mat-error>

              <mat-error *ngIf="serverError && !showIfNoTag && showError">
                <span class="show-error"> {{ serverError }} <br> <br> </span>
              </mat-error>

              <mat-error *ngIf="showIfNoTag && showError">
                <span class="show-error" [innerHTML]="showNoTagMsg"></span>
              </mat-error>
            </div>
            <div class="form-group"
              [ngClass]="{ invalid: loginForm.controls.email.touched && loginForm.controls.email.invalid }">
              <mat-form-field class="login-form-field" [hideRequiredMarker]="true" appearance="outline" floatLabel="auto" subscriptSizing="fixed">
                <mat-label>Email Address</mat-label>
                <input matInput type="text" name="email" class="login-form-input" formControlName="email" />
                <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
                  <span *ngIf="loginForm.controls.email.errors.required"> Please enter an email address </span>
                  <span
                    *ngIf="loginForm.controls.email.errors.pattern || loginForm.controls.email.errors.minlength">Please
                    enter a valid email address</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group"
              [ngClass]="{ invalid: loginForm.controls.password.touched && loginForm.controls.password.invalid }">
              <mat-form-field class="login-form-field" [hideRequiredMarker]="true" appearance="outline" floatLabel="auto" subscriptSizing="fixed">
                <mat-label>Password</mat-label>
                <input matInput type="password" name="password" class="login-form-input" formControlName="password" />
                <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
                  <span *ngIf="loginForm.controls.password.errors.required">Please enter a password</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="additional-links">
              <a href="https://www.rockwelltrading.com/password-lost/" class="forgot-psw">Forgot Password?</a>
            </div>

            <button mat-flat-button type="submit" [disabled]="isLoading" color="primary" class="w-100 medium">
              <mat-spinner class="white-spinner" *ngIf="isLoading" diameter="18"></mat-spinner>
              <p *ngIf="!isLoading">Log In</p>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="img-section hide-on-tablet-and-less">
    <div class="img-wrapper"></div>
  </div>
</section>
