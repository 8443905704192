import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Response } from 'src/app/core/interface/response';
import { ObservableService } from 'src/app/core/directives/observable.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  apiUrl = `/users`;


  constructor(private http: HttpClient,private observableService: ObservableService) {
  }

  // method to update chart settings of user
  updateChartSetting(trade_position, user_id) {
    
    return this.http.put(`${this.apiUrl}/chart-setting`, {
      trade_position, user_id
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }), catchError(this.handleError));
  }

  public updateWheelUserSetting(uId: number, bPower: number, position: number, cSize: number) {
    return this.http.put('/users/update-wheel-info',
      { userId: uId, buyingPower: bPower, maxPosition: position, noOfColumns: cSize })
      .pipe(
        map((res: Response) => {
          if (res) {
            if (res.type === false) {
              return res;
            }
          }
        }), catchError(this.handleError));
  }

  // method to update trading account settings of user
  updateTradingAccountSetting(position_size: number, account_risk: number, user_id: number) {
    return this.http.put(`${this.apiUrl}/trading-account-setting`, {
      position_size, account_risk, user_id
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }), catchError(this.handleError));
  }

  // method to update Indicators settings of user
  updateIndicatorSetting(show_ind_status: number) {
    return this.http.put(`${this.apiUrl}/technical-indicator-setting`, {
      show_ind_status,
      user_id: JSON.parse(localStorage.getItem('user')).userId
    }).pipe(
      map((res: Response) => {
        if (res) {
          if (res.type === false) {
            return res;
          }
        }
      }), catchError(this.handleError));
  }

  updateLastSelectedSymbol(symbol) {
    this.observableService.isAbletoUpDown.next(true);
    this.observableService.isLoading.next(false);
    try {
      return this.http.put(`${this.apiUrl}/update-symbol-setting`,
        symbol
      ).pipe(
        map((res: Response) => {
          if (res) {
            if (res.type === false) {
              return res;
            }
          }
        }), catchError(this.handleError));
    } catch (error) {
      console.log(error);
    }
  }

  private handleError(error: any) {
    if (error.status === 400) {
      return throwError(error.error);
    } else {
      return throwError(error || 'Server error');
    }
  }
}
