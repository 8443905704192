import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { from, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { ITopMenuItem } from '@c/top-menu/top-menu.model';
import { Features, TabNames, TopMenuItems, TradingHubTabs, UserSettings } from '@const';
import { DialogsService } from '@s/common/dialogs.service';
import { InfusionSoftService } from '@s/infusion-soft.service';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { UsersService } from '@s/users.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  protected mainMenu: ITopMenuItem[] = [];
  protected hiddenMenuItems: ITopMenuItem[] = [];
  protected showMenu = false;

  protected isShowHelp = false;
  protected isTrialUser: boolean;
  protected joinCommunityBtnVisible: boolean;
  protected watchPxoTutorialsBtnVisible: boolean;
  protected userSettings = UserSettings;
  protected features = Features;

  protected widthOfHelpButtonPx = 100;
  protected widthOfMoreDropDownButtonPx = 85;
  protected widthOfMoreDropDownButtonWithPaddingPx = 90;
  protected widthOfTradingPanelButtonPx = 135;

  private subscriptions = new Subscription();

  @ViewChild('menuWrapper') menuWrapper: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dialogsService: DialogsService,
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private usersService: UsersService,
    private infusionSoftService: InfusionSoftService,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('window:resize') resize(): void {
    this.onResize();
  }

  ngOnInit(): void {
    this.isTrialUser = this.userDataService.getUser().isTrialUser || false;
    this.joinCommunityBtnVisible = Boolean(this.observableService.joinCommunityBtnVisible.getValue());
    this.watchPxoTutorialsBtnVisible = Boolean(this.observableService.watchPxoTutorialsBtnVisible.getValue());

    const showStockScreener = Boolean(this.observableService.showStockScreener.getValue());

    this.mainMenu = [
      { title: TopMenuItems.MasterCalendar, showCondition: true, width: 0, feature: Features.MasterCalendar },
      { title: TopMenuItems.AssetCorrelation, showCondition: true, width: 0, feature: Features.AssetCorrelation },
      { title: TopMenuItems.MMTrades, showCondition: true, width: 0, feature: Features.MMTrades },
      { title: TopMenuItems.TradingLog, showCondition: true, width: 0, feature: Features.TradingLog },
      { title: TopMenuItems.StockScreener, showCondition: true, width: 0, feature: Features.StockScreeners },
      { title: TopMenuItems.EarningsAnalysis, showCondition: true, width: 0, feature: Features.EarningsAnalysis },
      { title: TopMenuItems.Heatmap, showCondition: true, width: 0, feature: Features.Heatmap },
      {
        title: TopMenuItems.TradingPanel,
        showCondition: true,
        width: this.widthOfTradingPanelButtonPx,
        feature: Features.TradingPanel,
      },
    ];

    if (!showStockScreener) {
      this.mainMenu = [...this.mainMenu].filter((item) => item.title !== TopMenuItems.StockScreener);
    }

    const { tradier_flag_ifs, tradier_flag_pxo } = this.observableService.mySettings.getValue();
    if (!tradier_flag_ifs || !tradier_flag_pxo) {
      this.mainMenu = [...this.mainMenu].filter((item) => item.title !== TopMenuItems.TradingPanel);
    }

    this.mainMenu = this.mainMenu.filter((menuItem) => menuItem.showCondition);

    this.subscriptions.add(
      this.observableService.mySettings.pipe(skip(1)).subscribe(({ tradier_flag_ifs, tradier_flag_pxo }) => {
        const mainMenuCopy = [...this.mainMenu, ...this.hiddenMenuItems];
        let mainMenuCopyUpdated = [...mainMenuCopy];

        const isTradierIntegrationEnabled = !!tradier_flag_ifs && !!tradier_flag_pxo;
        const hasTradingPanelMenu = mainMenuCopy.some(({ title }) => title === TopMenuItems.TradingPanel);

        if (hasTradingPanelMenu && !isTradierIntegrationEnabled) {
          mainMenuCopyUpdated = mainMenuCopy.filter(({ title }) => title !== TopMenuItems.TradingPanel);
        } else if (!hasTradingPanelMenu && isTradierIntegrationEnabled) {
          // important: ShortingStocksScanner button should be the last one, see initial order
          // return TradingPanel button in position before ShortSellingStocks button
          const tradingPanelMenuItem = {
            title: TopMenuItems.TradingPanel,
            showCondition: true,
            width: this.widthOfTradingPanelButtonPx,
            feature: Features.TradingPanel,
          };
          mainMenuCopyUpdated.splice(-1, 0, tradingPanelMenuItem);
        }

        this.showMenu = false;

        this.mainMenu = [...mainMenuCopyUpdated];
        this.hiddenMenuItems = [];

        const htmlMenuElements = this.document.getElementsByClassName('top-menu-item');
        this.mainMenu = this.mainMenu.map((item, index) => ({
          ...item,
          width: item.width || (htmlMenuElements[index]?.clientWidth ?? 0),
        }));

        this.onResize();
      }),
    );
  }

  ngAfterViewInit(): void {
    const htmlMenuElements = this.document.getElementsByClassName('top-menu-item');
    this.mainMenu = this.mainMenu.map((item, index) => ({
      ...item,
      width: htmlMenuElements[index]?.clientWidth ?? 0,
    }));

    // it's called 2 times intentionally, do not remove
    setTimeout(() => this.onResize(), 0);
    setTimeout(() => this.onResize(), 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async hideJoinButtons(buttonFlag: string): Promise<void> {
    const addFlagFunc =
      buttonFlag === UserSettings.JoinCommunityBtnVisible
        ? this.usersService.addJoinCommunity
        : this.usersService.addWatchPxoTutorials;

    await Promise.all([
      this.userDataService.set(buttonFlag, 0),
      this.infusionSoftService.set(buttonFlag),
      addFlagFunc(),
    ]);
  }

  public openItem(item: ITopMenuItem): void {
    switch (item.title.toUpperCase()) {
      case TopMenuItems.MasterCalendar:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.MasterCalendar);
        break;

      case TopMenuItems.MMTrades:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.MMTrades);
        break;

      case TopMenuItems.AssetCorrelation:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.AssetCorrelation);
        break;

      case TopMenuItems.TradingLog:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.TradingLog);
        break;

      case TopMenuItems.TradingPanel:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.TradingPanel);
        break;

      case TopMenuItems.StockScreener:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.StockScreeners);
        break;

      case TopMenuItems.Heatmap:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.Heatmap);
        break;

      case TopMenuItems.ShortingStocksScanner:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.ShortingScanner);
        break;

      case TopMenuItems.EarningsAnalysis:
        this.resetStateOnPageSwitch();
        this.navigationService.redirectToTab(TabNames.EarningsAnalysis);
        break;

      default:
        break;
    }
  }

  protected navigateToLicenseAgreementPage(): Promise<void> {
    return this.navigationService.redirectToTab(TabNames.LicenseAgreement);
  }

  protected navigateToDisclaimerPage(): Promise<void> {
    return this.navigationService.redirectToTab(TabNames.Disclaimer);
  }

  public openTradingHubModal(): void {
    this.userDataService.set(UserSettings.TradingHubTab, TradingHubTabs.MagicBall);
    // set into observableService without waiting for userDataService result
    this.observableService.tradingHubTab.next(TradingHubTabs.MagicBall);
    this.dialogsService.openTradingHubModal();
  }

  private onResize(): void {
    if (!this.menuWrapper?.nativeElement) {
      return;
    }

    this.updateHiddenMenuItems();
    this.showMenu = true;

    this.changeDetectorRef.detectChanges();
  }

  private resetStateOnPageSwitch(): void {
    // Cancel any pending order when switching between tabs
    this.observableService.tradingPanelOrderInput.next(null);
  }

  private updateHiddenMenuItems(): void {
    const { visible, hidden } = this.getVisibleAndHiddenMenuItems([...this.mainMenu, ...this.hiddenMenuItems]);

    this.mainMenu = visible;
    this.hiddenMenuItems = hidden;
  }

  private getVisibleAndHiddenMenuItems(menuItems: ITopMenuItem[]): { visible: ITopMenuItem[]; hidden: ITopMenuItem[] } {
    const availableSpace = this.menuWrapper.nativeElement.offsetWidth;
    const spaceForMenu = this.neededSpaceForMenuItems(menuItems);

    if (spaceForMenu <= availableSpace) {
      return { visible: menuItems, hidden: [] };
    }

    const widthOfMoreDropDownButton = this.widthOfMoreDropDownButtonWithPaddingPx;
    const spaceForVisibleMenu = availableSpace - widthOfMoreDropDownButton;

    const visible: ITopMenuItem[] = [];
    const hidden: ITopMenuItem[] = [];

    let usedSpace = 0;
    let addItemsToVisible = true;

    menuItems.forEach((item) => {
      if (addItemsToVisible && usedSpace + item.width < spaceForVisibleMenu) {
        visible.push(item);
        usedSpace = usedSpace + item.width;

        return;
      }

      addItemsToVisible = false;
      hidden.push(item);
    });

    return { visible, hidden };
  }

  private neededSpaceForMenuItems(menuItems: ITopMenuItem[]): number {
    let totalNeededSpace = 0;
    menuItems.forEach((item) => (totalNeededSpace += item.width));

    return totalNeededSpace;
  }

  protected openTradingHubRockyModal(): void {
    this.userDataService.set(UserSettings.TradingHubTab, TradingHubTabs.IBot3);
    this.observableService.tradingHubTab.next(TradingHubTabs.IBot3);
    this.dialogsService.openTradingHubModal();
  }
}
