export enum DateRangeOptionType {
  Lifetime = 'Lifetime',
  ThisMonth = 'ThisMonth',
  PreviousMonth = 'PreviousMonth',
  ThisYear = 'ThisYear',
  PreviousYear = 'PreviousYear',
  ThisQuarter = 'ThisQuarter',
  PreviousQuarter = 'PreviousQuarter',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last3Months = 'Last3Months',
  Last6Months = 'Last6Months',
  Custom = 'Custom',
}
