<div class="report-con-tbl simpleViewTbl">
  <p class="print-section-title">Trading report</p>
    <p class="strategy-name">{{tradingStrategy.name}}</p>
    <table
      id="tradeReportPrint"
      [ngClass]="{
      'trade-report-small' : !isReportFitOnPrintScreen,
      'trade-report-normal': isReportFitOnPrintScreen}">
      <thead>
      <tr>
        <th class="text-center">#</th>
        <th>
          <div class="start-end-date-name">
            <p>Entry Date</p>
            <p>Exit Date</p>
          </div>
        </th>
        <th class="text-center">Size</th>
        <th class="text-center">Position</th>
        <th class="text-right">Entry Price</th>
        <th class="text-right">Stop Loss</th>
        <th class="text-right">Profit Target</th>
        <th class="text-right">Exit Price</th>
        <th>
          <div class="profit-center">Profit</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let trade of strategyTrades; let i =index;">
        <td class="text-center">{{i + 1}}</td>
        <td>
          <div class="start-end-date-value">
            <p>{{trade.display_entry_date}}</p>
            <div class="date-progress-bar" *ngIf="trade.exit_date">
              <span>{{trade.display_duration}}d</span>
              <div class="progress-line" [style.width.%]="trade.display_duration_progress"></div>
            </div>
            <p *ngIf="trade.exit_date">{{trade.display_exit_date}}</p>
          </div>
        </td>
        <td class="text-right">{{trade.display_shares_count}}</td>
        <td class="text-center">
          <div [ngClass]="trade.display_position_class">
            {{trade.display_position}}
          </div>
        </td>
        <td class="fonts-14px text-right">{{trade.display_entry_price}}</td>
        <td class="text-right">{{trade.display_stop_loss}}</td>
        <td class="text-right">{{trade.display_target_profit}}</td>
        <td class="text-right">
          <div class="d-space-between" *ngIf="!isNullOrUndefined(trade.exit_price)">
            <span>{{trade.display_exit_price}}</span>
          </div>
        </td>
        <td *ngIf="trade.total_profit !== null && trade.total_profit !== undefined">
          <div class="profit-bar" [class]="trade.display_total_profit_class">
            <p>{{trade.display_total_profit}}</p>
            <div class="progress-space">
              <div class="profit-progress">
                <div class="profit-line" [style.width.%]="trade.display_total_profit_progress"></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
</div>

