<div class="trading-group-details">
  <div class="details-summary">
    <div class="symbol-container">
      <span class="symbol" [matTooltip]="model?.symbol" [matTooltipDisabled]="model?.symbol.length <= 23">{{ model?.symbol }}</span>
    </div>
    <div class="date-container">
      <span class="date date-start">{{model.minDateDisplay}}</span>
      <span class="date date-end">{{model.maxDateDisplay}}</span>
    </div>
    <div class="user-info-wrapper">
      <span class="user-info">
        {{model.accountName}}
      </span>
      <span class="user-info">
        {{model.strategyName}}
      </span>
    </div>
  </div>
  <div class="metric-container">
    <div class="metric-item">
      <div class="metric-title">COST BASIS</div>
      <div class="metric-sum" [class]="model.costAvgClass">{{model.costAvg}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">BREAK EVEN</div>
      <div class="metric-sum" [class]="model.breakEvenClass">{{model.breakEven}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">SHARES</div>
      <div class="metric-sum" [class]="model.sharesClass">{{model.shares}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">CONTRACTS</div>
      <div class="metric-sum">
        <span class="sum-part" [class]="model.sellContractsClass">{{model.sellContracts}}</span>
        <span> / </span>
        <span class="sum-part" [class]="model.buyContractsClass">{{model.buyContracts}}</span>
      </div>
    </div>
    <div class="metric-item">
      <div class="metric-title">FEES</div>
      <div class="metric-sum" [class]="model.feesAndCommClass">{{model.feesAndComm}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">PREMIUM</div>
      <div class="metric-sum" [class]="model.premiumClass">{{model.premium}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">DIVIDENDS</div>
      <div class="metric-sum" [class]="model.dividendsClass">{{model.dividends}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">BUYING POWER</div>
      <div class="metric-sum">{{model.buyingPower}}</div>
    </div>
    <div class="metric-item">
      <div class="metric-title">REALIZED P&L</div>
      <div class="metric-sum" [class]="model.profitClass">{{model.profit}}</div>
    </div>
  </div>
</div>
