import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SortDirection } from '@core/types';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { Subscriber } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { DEFAULT_SCANNER_TABLE_SORT } from '@c/shared/scanner-symbols-list/scanner-symbols-list.data';
import { SortState } from '@c/shared/scanner-symbols-list/scanner-symbols-list.model';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { SnackBarComponent } from '@c/snack-bar/snack-bar.component';
import {
  DEFAULT_UPDATE_FLAGS_DELAY_MS,
  ExchangeCountries,
  Keys,
  ScannerResultTypes,
  TabNames,
  UserSettings,
  WatchlistType,
} from '@const';
import { ResizeDirectiveModule } from '@core/directives/resize-directive/resize-directive.module';
import { Flags, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { ScannerResultsService } from '@s/scanner-results.service';
import { SmileyDataService } from '@s/smiley-data.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import { WatchlistDataService } from '@s/watchlist-data.service';

interface IScannerResultData {
  id: number;
  security_id: number;
  symbol: string;
  company_name: string;
  signal: string;
  strategy_id: number;
  smiley_id: number;
  flag: Flags;
  country_code: string;
}

@Component({
  selector: 'app-scanner-v2',
  templateUrl: './scanner-v2.component.html',
  styleUrls: ['./scanner-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableVirtualScrollModule,
    ScrollingModule,
    ResizeDirectiveModule,
    MatTableModule,
    MatSortModule,
    SymbolFlagModule,
    UpperCasePipe,
    NgClass,
    NgIf,
    MatTooltipModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    NgFor,
  ],
})
export class ScannerV2Component implements OnInit, AfterViewInit, OnDestroy {
  @Input() protected isActive = false;

  @ViewChild(MatSort) sort: MatSort;

  protected tabIndex = 0;

  protected symbol: number | null = null;
  protected selectedRow: IScannerResultData | null = null;
  protected renderedData: IScannerResultData[] = [];

  protected sortState: SortState = { ...DEFAULT_SCANNER_TABLE_SORT };
  protected dataSource = new TableVirtualScrollDataSource<IScannerResultData>([]);
  protected scannerSettingType = '-';
  protected isAbleToUpDown = true;
  protected showSmileyStatistic = false;

  protected readonly exchangeCountries = ExchangeCountries;
  protected readonly flags = Flags;
  protected readonly smileyListTypes = SmileyListType;
  protected readonly displayedColumns = ['flag', 'signal', 'symbol', 'country_code', 'company_name'];
  protected readonly displayedColumnsTitles = {
    flag: 'Flag',
    signal: 'Signal',
    symbol: 'Symbol',
    country_code: 'Country',
    company_name: 'Company',
  };

  private readonly flagAscSortOrder = [Flags.Never, Flags.No, Flags.Maybe, Flags.Yes, Flags.None];
  private readonly exchangeSortOrder = ['CC', 'CA', 'US'];

  private timeoutUpDown: NodeJS.Timeout;
  private subscriber = new Subscriber();

  constructor(
    private scannerResultsService: ScannerResultsService,
    private watchlistDataService: WatchlistDataService,
    private smileyDataService: SmileyDataService,
    private observableService: ObservableService,
    private tradingStrategiesService: TradingStrategiesService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = (
      data: IScannerResultData,
      headerId: keyof IScannerResultData,
    ): string | number => {
      switch (headerId) {
        case 'flag':
          return this.flagAscSortOrder.indexOf(data.flag);
        case 'country_code':
          return this.exchangeSortOrder.indexOf(data.country_code);
        case 'company_name':
          return data.company_name?.toLowerCase();
        default:
          return data[headerId];
      }
    };

    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.symbol = this.observableService.symbol.getValue();

    const savesSort = this.observableService.scannerResultSort.getValue();
    if (savesSort && savesSort.active && savesSort.direction) {
      this.sortState = { column: savesSort.active, direction: savesSort.direction };
    }

    this.loadScannerResults();

    this.observableService.scannerResultUpdated.subscribe(() => this.loadScannerResults());

    this.observableService.symbol.subscribe((symbol) => {
      this.symbol = symbol;

      if (!this.isActive) {
        this.selectedRow = null;
      }
    });

    this.subscriber.add(
      this.dataSource.connect().subscribe((data) => {
        this.renderedData = data;
      }),
    );

    this.subscriber.add(
      this.smileyDataService.showStatisticSettingAndHasAccess$.pipe(distinctUntilChanged()).subscribe((value) => {
        this.showSmileyStatistic = value;
      }),
    );
  }

  async goToWheelTrades(): Promise<void> {
    await this.navigationService.redirectToTab(TabNames.Wheel);
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  async loadScannerResults(): Promise<void> {
    const results: IScannerResultData[] = await this.scannerResultsService.get(ScannerResultTypes.Pxo);

    this.dataSource.data = results.map((item) => ({ ...item, flag: item.flag || Flags.None })); // Be careful, the data is unsorted!

    if (results.length) {
      const strategy = await this.tradingStrategiesService.getById(results[0].strategy_id);

      if (strategy) {
        this.scannerSettingType = `Signal is based on ${strategy.name}`;
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  protected async onSortChange(sortState: { active: string; direction: SortDirection }): Promise<void> {
    this.sortState = { column: sortState.active, direction: sortState.direction };
    this.changeDetectorRef.detectChanges();

    if (this.selectedRow) {
      setTimeout(() => this.scrollToSelectedRow());
      this.changeDetectorRef.detectChanges();
    }

    await this.userDataService.set(UserSettings.ScannerResultSort, {
      active: sortState.active,
      direction: sortState.direction,
    });
  }

  protected onSelectSymbol(element: IScannerResultData): void {
    this.selectedRow = element;

    clearTimeout(this.timeoutUpDown);
    this.timeoutUpDown = setTimeout(async () => {
      this.observableService.symbol.next(element.security_id);
      await this.userDataService.set(UserSettings.Symbol, element.security_id);
    }, 200);
  }

  async onSelectSmiley(securityId: number, flag: Flags): Promise<void> {
    setTimeout(() => {
      const { data } = this.dataSource;
      const index = data.findIndex((el) => el.security_id === securityId);

      if (index === -1) {
        console.error('Element is not found');
        return;
      }

      if (data[index].flag === flag) {
        return;
      }

      if (flag) {
        data[index] = { ...data[index], flag, smiley_id: data[index].smiley_id ?? null };
      } else if (data[index].smiley_id) {
        data[index] = { ...data[index], flag: Flags.None, smiley_id: null };
      }

      // update sort after flag change
      this.dataSource.data = data;

      // important: do not remove, temp testing on DEV - turn-off update request on each change
      // this.smileyDataService.updateSymbolsSmileySignal$.next(SmileyListType.PowerX);
    }, DEFAULT_UPDATE_FLAGS_DELAY_MS);
  }

  showPopUp(symbol: string, isMoved: boolean): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: isMoved ? `Symbol ${symbol} was moved into watchlist` : `Symbol ${symbol} was added into watchlist`,
        snackbar: this.snackBar,
      },
      duration: 3000,
      horizontalPosition: 'left',
    });
  }

  async addToWatchlist(scannerResult: IScannerResultData): Promise<void> {
    await this.watchlistDataService.insert(scannerResult.security_id, WatchlistType.PowerX);
    this.observableService.watchlistUpdated.next(true);
    this.showPopUp(scannerResult.symbol, false);
  }

  async moveToWatchlist(element: IScannerResultData): Promise<void> {
    await Promise.all([
      this.watchlistDataService.insert(element.security_id, WatchlistType.PowerX),
      this.removeFromList(element),
    ]);
    this.observableService.watchlistUpdated.next(true);
    this.showPopUp(element.symbol, true);
  }

  async removeFromList(element: IScannerResultData): Promise<void> {
    const data = this.dataSource.sortData(this.dataSource.data, this.sort);
    await this.scannerResultsService.remove(element.id);
    this.dataSource.data = data.filter((item) => item.id !== element.id);

    if (this.selectedRow && this.selectedRow.security_id === element.security_id) {
      this.selectedRow = null;
    }
  }

  public onResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  protected trackByFn(index: number, item: { id?: string | number }): string | number {
    return item.id ?? index;
  }

  private scrollToSelectedRow(): void {
    if (this.selectedRow && document.getElementById(`scanner-tr-${this.selectedRow.security_id}`)) {
      this.renderer
        .selectRootElement('#scanner-tr-' + this.selectedRow.security_id, true)
        .scrollIntoView({ behavior: 'smooth', block: 'nearest' });

      this.changeDetectorRef.markForCheck();
    }
  }

  private arrowDownEvent(): void {
    const data = this.renderedData;
    const index = data.findIndex((el) => el.security_id === this.selectedRow.security_id);

    if (index < data.length - 1) {
      this.selectedRow = data[index + 1];
      this.changeDetectorRef.markForCheck();

      this.onSelectSymbol(data[index + 1]);
      this.scrollToSelectedRow();
    }
  }

  private arrowUpEvent(): void {
    const data = this.renderedData;
    const index = data.findIndex((el) => el.security_id === this.selectedRow.security_id);

    if (index > 0) {
      this.selectedRow = data[index - 1];
      this.onSelectSymbol(data[index - 1]);
      this.scrollToSelectedRow();
    }
  }

  // TODO: implement on-key-hold functionality: when user hold arrow key move selection to next symbol every [DEFINED_TIME_INTERVAL]
  @HostListener('window:keydown', ['$event'])
  onHostKeyDown(event: KeyboardEvent): void {
    const { code } = event;
    const isDialogOpen = this.dialog.openDialogs.length > 0;

    if (this.isActive && this.isAbleToUpDown && !isDialogOpen && ['ArrowUp', 'ArrowDown'].includes(code)) {
      event.preventDefault();

      if (!this.selectedRow) {
        const firstEl = this.renderedData[0];
        this.onSelectSymbol(firstEl);
        this.selectedRow = firstEl;
        this.scrollToSelectedRow();
        return;
      }

      if (code === 'ArrowUp') {
        this.arrowUpEvent();
      } else if (code === 'ArrowDown') {
        this.arrowDownEvent();
      }
    }

    if (!isDialogOpen && event.key === Keys.DELETE && this.selectedRow) {
      event.preventDefault();
      this.removeFromList(this.selectedRow);
    }

    this.changeDetectorRef.detectChanges();
  }
}
