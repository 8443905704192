<app-maintenance></app-maintenance>

<!--------------------------------------------------------------
This is separate fragment for only web UI
--------------------------------------------------------------->
<div
  class="wheel-section"
  id="wheel-section"
  [ngClass]="{ 'with-banner': isBanner, 'hidden': isWheelScannerLoadingFirstTime && selectedTab === tabsEnum.Chart }"
  *ngIf="!isMobile && !showMaintenance"
>
  <as-split
    direction="horizontal"
    class="all-section"
    gutterSize="6"
    unit="percent"
    (dragStart)="onDragStart()"
    (dragEnd)="saveWheelFiltersWidth($event); onDragEnd()"
  >
    <as-split-area
      [size]="showOrderPanel ? leftPartWidthWithPinnedOrderPanelInPercents : leftPartWidthInPercents"
      class="left-section"
    >
      <div class="chart-wheel" *ngIf="(viewMode$ | async) === viewModes.Split">
        <as-split
          direction="vertical"
          gutterSize="6"
          unit="percent"
          (dragStart)="onDragStart()"
          (dragEnd)="saveWheelChartHeight($event); onDragEnd()"
        >
          <as-split-area
            [minSize]="wheelChartSectionMinHeightInPercents"
            [size]="wheelChartSectionHeightInPercents"
          >
            <div class="chart-back-layout">
              <div class="chart-inner-layout">
                <app-wheel-chart class="h-less-991"></app-wheel-chart>
                <div *ngIf="isShowChartOverlay" class="chart-overlay"></div>
              </div>
            </div>
          </as-split-area>

          <as-split-area
            [minSize]="wheelCalcSectionMinHeightInPercents"
            [size]="wheelCalcSectionHeightInPercents"
          >
            <app-wheel-calculator></app-wheel-calculator>
          </as-split-area>
        </as-split>
      </div>

      <div class="chart-wheel" *ngIf="(viewMode$ | async) === viewModes.Tabs">
        <div class="left-side-button">
          <mat-button-toggle-group
            hideSingleSelectionIndicator="true"
            name="fontStyle"
            #group="matButtonToggleGroup"
            [value]="selectedTab"
            (change)="onCalculatorToggle($event.value)"
          >
            <mat-button-toggle [value]="tabsEnum.Chart">Chart</mat-button-toggle>
            <mat-button-toggle [value]="tabsEnum.Calculator">Calculator</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="right-side-content">
          <ng-template [ngIf]="selectedTab !== tabsEnum.Calculator">
            <div class="chart-back-layout">
              <div class="chart-inner-layout">
                <app-wheel-chart class="h-less-991"></app-wheel-chart>
              </div>
            </div>
            <ng-content select="wheelChart"></ng-content>
          </ng-template>
          <ng-template [ngIf]="selectedTab === tabsEnum.Calculator">
            <app-wheel-calculator></app-wheel-calculator>
          </ng-template>
        </div>
      </div>
    </as-split-area>

    <as-split-area
      [size]="showOrderPanel ? rightPartWidthWithPinnedOrderPanelInPercents : rightPartWidthInPercents"
      [minSize]="showOrderPanel ? rightPartWidthWithPinnedOrderPanelInPercents : rightPartMinWidthInPercents"
      [maxSize]="showOrderPanel ? rightPartMaxWidthWithPinnedOrderPanelInPercents : rightPartMaxWidthInPercents"
      class="right-section pinned-order"
      [ngClass]="{'pinned-order' : showOrderPanel}"
    >
      <app-trading-panel-order-panel *ngIf="showOrderPanel"></app-trading-panel-order-panel>
      <as-split
        direction="vertical"
        gutterSize="6"
        (dragStart)="onDragStart()"
        (dragEnd)="saveWheelFilterHeight($event); onDragEnd()"
      >
        <as-split-area [size]="wheelFilterHeight" minSize="40" class="right-one">
          <app-wheel-scanner (changeCurrentSymbol)="onSetCurrentSymbol($event)"></app-wheel-scanner>
        </as-split-area>

        <as-split-area
          [size]="wheelDataWindowHeight"
          [minSize]="dataWindowMinHeightInPercents"
          class="right-two"
          gutterSize="11"
        >
          <app-wheel-datawindow></app-wheel-datawindow>
        </as-split-area>
      </as-split>
    </as-split-area>
  </as-split>
</div>

<!--------------------------------------------------------------
This is separate fragment for only mobile UI
--------------------------------------------------------------->
<div
  class="wheel-section"
  [ngClass]="{ 'hidden': isWheelScannerLoadingFirstTime && selectedTab === tabsEnum.Chart }"
  *ngIf="isMobile && !showMaintenance"
>
  <div class="chart-wheel">
    <div class="left-side-button">
      <mat-button-toggle-group
        hideSingleSelectionIndicator="true"
        name="fontStyle" #group="matButtonToggleGroup"
        [(value)]="selectedTab"
        (change)="onCalculatorToggle($event.value)"
      >
        <mat-button-toggle [value]="tabsEnum.Chart">Symbols</mat-button-toggle>
        <mat-button-toggle [value]="tabsEnum.Calculator">Calculator</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="right-side-content scroll-style">
      <app-wheel-calculator *ngIf="selectedTab === tabsEnum.Calculator"></app-wheel-calculator>

      <div *ngIf="selectedTab !== tabsEnum.Calculator" class="wheel-scanner-part">
        <div class="consAndAgg">
          <app-wheel-scanner (changeCurrentSymbol)="onSetCurrentSymbol($event)"></app-wheel-scanner>
        </div>
        <div class="premiums-chart-tabbing">
          <div class="left-side-button">
            <mat-button-toggle-group
              hideSingleSelectionIndicator="true"
              name="fontStyle"
              #group="matButtonToggleGroup"
              [value]="premiumsValue"
              (change)="onToggleSubTabbing($event.value)"
            >
              <mat-button-toggle [value]="true">Premiums</mat-button-toggle>
              <mat-button-toggle [value]="false">Chart</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="premiums-section" [ngClass]="{ 'hidden': !premiumsValue }">
            <app-wheel-datawindow></app-wheel-datawindow>
          </div>
          <div class="chart-back-layout" [ngClass]="{ 'hidden': premiumsValue }">
            <div class="chart-inner-layout">
              <app-wheel-chart></app-wheel-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinner-container" *ngIf="isWheelScannerLoadingFirstTime && !showMaintenance && selectedTab === tabsEnum.Chart">
  <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
</div>
