import { Component, Input, OnInit } from '@angular/core';
import { ObservableService } from '../../../../services/observable.service';
import { IOption, OptionDataService } from '../../../../services/option-data.service';
import { TradingStrategiesService } from '../../../../services/trading-strategies.service';
import { notEnoughDataText, Signals } from '../../../../constants';
import { UserSettingsService } from '../../../../services/user-settings.service';
import { WithTradierIntegrationAbstract } from '../../../../coreV2/abstract/with-tradier-integration';
import { SymbolsService } from '../../../../services/symbols.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent extends WithTradierIntegrationAbstract implements OnInit {
  public isLoading: boolean = true;
  public hasData: boolean;
  public isOptionGreeks: boolean;

  symbolId: number = null;
  symbol: string = '';
  firstOption: any = null;
  secondOption: any = null;
  firstTradingStrategy: any = null;
  secondTradingStrategy: any = null;
  accountRiskAmount: any = null;

  options$: BehaviorSubject<[IOption, IOption]> = new BehaviorSubject([null, null]);

  @Input() placeOrderCanBeShown: boolean = null;  // should be recieved from cache service
  @Input() isPrint: boolean;

  warnings: string[] = [];

  constructor(public observableService: ObservableService,
              private optionDataService: OptionDataService,
              public userSettingsService: UserSettingsService,
              private tradingStrategiesService: TradingStrategiesService,
              private symbolsService: SymbolsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.isOptionGreeks = this.isPrint;
    await this.initTradierIntegration();
    this.options$.subscribe(this.recountWarnings);

    this.symbolId = this.observableService.symbol.getValue();
    const firstTradingStrategyId = this.observableService.firstTradingStrategyId.getValue();
    const secondTradingStrategyId = this.observableService.secondTradingStrategyId.getValue();
    this.accountRiskAmount = this.observableService.accountRiskAmount.getValue();

    const [symbol, firstOption, secondOption, firstTradingStrategy, secondTradingStrategy] = await Promise.all([
      this.symbolsService.getById(this.symbolId),
      this.optionDataService.get(this.symbolId, firstTradingStrategyId),
      this.optionDataService.get(this.symbolId, secondTradingStrategyId),
      this.tradingStrategiesService.getById(firstTradingStrategyId),
      this.tradingStrategiesService.getById(secondTradingStrategyId)
    ]);

    this.symbol = symbol.symbol;
    this.firstOption = firstOption;
    this.secondOption = secondOption;
    this.firstTradingStrategy = firstTradingStrategy;
    this.secondTradingStrategy = secondTradingStrategy;

    this.updateOptions();
    this.updateHasData();

    this.observableService.symbol.subscribe(async (symbolId) => {
      this.isLoading = true;
      this.symbolId = symbolId;

      await this.loadData();

      this.isLoading = false;
    });

    this.observableService.firstTradingStrategyId.subscribe(async (firstTradingStrategyId) => {
      this.isLoading = true;
      this.firstTradingStrategy = await this.tradingStrategiesService.getById(firstTradingStrategyId);
      this.firstOption = await this.optionDataService.get(this.symbolId, this.firstTradingStrategy.id);

      this.updateOptions();
      this.updateHasData();

      this.isLoading = false;
    });

    this.observableService.secondTradingStrategyId.subscribe(async (secondTradingStrategyId) => {
      this.isLoading = true;
      this.secondTradingStrategy = await this.tradingStrategiesService.getById(secondTradingStrategyId);
      this.secondOption = await this.optionDataService.get(this.symbolId, this.secondTradingStrategy.id);

      this.updateOptions();
      this.updateHasData();

      this.isLoading = false;
    });

    this.observableService.accountRiskAmount.subscribe(accountRiskAmount => this.accountRiskAmount = accountRiskAmount);
  }

  async loadData() {
    const [symbol, firstOption, secondOption] = await Promise.all([
      this.symbolsService.getById(this.symbolId),
      this.optionDataService.get(this.symbolId, this.firstTradingStrategy.id),
      this.optionDataService.get(this.symbolId, this.secondTradingStrategy.id)
    ]);

    this.symbol = symbol.symbol;
    this.firstOption = firstOption;
    this.secondOption = secondOption;
    this.updateOptions();
    this.updateHasData();
  }

  updateOptions() {
    // second options DTE should be equal to first option DTE - this is to avoid confusion as long as DTE for second option is not displayed on UI
    const secondOption = this.secondOption && (!this.firstOption.dte || this.firstOption.dte === this.secondOption.dte)
      ? this.secondOption
      : null;

    this.options$.next([this.firstOption, secondOption]);
  }

  recountWarnings = () => {
    const newVal = [];
    const [firstOption, secondOption] = this.options$.value;
    if (!(firstOption || secondOption)) this.warnings = [];
    const permittedSignals = [Signals.STO, Signals.BTO];
    if (!(firstOption?.has_enough_data || secondOption?.has_enough_data)) {
      newVal.push(notEnoughDataText);
    }
    if (permittedSignals.includes(firstOption?.signal) || permittedSignals.includes(secondOption?.signal)) {
      newVal.push('Only consider an entry if the Signal has triggered');
    }
    this.warnings = newVal;
  }

  updateHasData() {
    const [firstOption, secondOption] = this.options$.value;
    this.hasData = !!(firstOption?.itm || firstOption?.atm
      || secondOption?.itm || secondOption?.atm);
  }

  toggleOptionGreeks() {
    this.isOptionGreeks = !this.isOptionGreeks;
  }
}
