@if (securityDetails && securityDetails() && securityDetails().sector && securityDetails().industry) {
  <div
    #wrapper
    class="sector-indu"
    (click)="onSectorLinkClick(wrapper)"
  >
    @if (isDataLoading()) {
      <mat-spinner class="custom-spinner" diameter="16"></mat-spinner>
    } @else {
      <mat-icon class="l-icon" [svgIcon]="'data-window-icon'"></mat-icon>
    }
    @if (securityDetails().sector) {
      <span class="sector-value">
        {{ securityDetails().sector }}
      </span>
    }
    @if (securityDetails().sector && securityDetails().industry) {
      <span class="industry-value">
        {{ securityDetails().sector && securityDetails().industry ? "-" : "" }}
      </span>
    }
    @if (securityDetails().industry) {
      <span class="industry-value">
        {{ securityDetails().industry }}
      </span>
    }
  </div>
}
