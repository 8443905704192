<div class="terms-conditions-detail">
  <div class="center m-b-12">
    <span class="h2 text-center">ROCKWELL TRADING SERVICES LLC</span><br />
    <span class="h2 text-center">POWER X OPTIMIZER</span><br />
    <span class="h2 text-center">SOFTWARE LICENSE AGREEMENT</span><br />
  </div>
  <p>
    This Rockwell Trading Services LLC Power X Optimizer Software License Agreement (the “Agreement” is made
    between you (the "Licensee ", hereinafter referred to as “you”) land Rockwell Trading Services LLC (the
    "Licensor", hereinafter referred to as “we”, “us” or “our”, both exclusive of Licensee) with a principal place
    of business at 401 Congress Ave #1540, Austin, Texas 78701. If you are agreeing to this Agreement not as an
    individual but on behalf of your company, or other entity for which you are acting, the “you” means the entity
    you represent and are binding your entity to this Agreement. We may modify the Agreement from time to time,
    subject to the terms herein regarding changes to the agreement below.<br />
  </p>
  <span class="h2 text-left">1. SCOPE</span><br />
  <p>
    1.1 This Agreement governs your initial purchase of Rockwell Trading Services LLC’s Software, Support and
    Maintenance for the Software, and any Additional Services, as well as any future purchases made by you that
    reference this Agreement. The Software and its permitted use are further described in the Agreement. The term
    “Software” includes all documentation regarding the software unless otherwise specified.<br />
  </p>
  <p>1.2 Definitions:</p>

  <p>1.2.1 "Install" means, in the case of a downloadable service, placing the Software on a
    computer's hard disk,
    CD-ROM or other secondary storage device.</p>
  <p>1.2.2 "Use" means (i) executing or loading the Software into computer RAM, Web Browser,
    or other memory, and
    (ii) copying the Software for archival or emergency restart purposes.</p>
  <p>1.2.3 “Software” means PowerX Optimizer.</p>
  <p>1.3 Title To Software. We retain title to and ownership of the Software and all
    enhancements, modifications
    and updates of the Software.</p>


  <span class="h2 text-left">2. GRANT OF RIGHTS</span><br>
  <p>2.1 We hereby grant you a nonexclusive, non-sublicensable, and non-transferable license
    to install and use the
    Software on one single user computer in your possession. We shall own and retain all rights, title and
    interest in and to (a) Software, all improvements, enhancements or modifications thereto, (b) any software,
    applications, inventions or other technology developed in connection with Implementation Services or
    support,
    and (c) all intellectual property rights related to any of the foregoing.</p>
  <p>2.2. Account Registration. You must register for an account with us in order to access or
    receive the
    Software. The registration information must be accurate, current and complete. You must keep their
    registration current so that we may send notices, statements and other information to you by email or
    through
    your account. You are responsible for all actions taken through your account, including purchases and
    payment
    plans (which may incur fees). If you order Software through a Reseller (defined herein), then you are solely
    responsible for (i) any access by Reseller to your account and (ii) any related rights or obligations in
    your
    applicable agreement with the Reseller.</p>
  <p>2.3. Authorized Users. Only Authorized Users may access and use the Software. An
    authorized user is the person
    or entity who purchased the Software. You are responsible for compliance with this Agreement by all
    Authorized
    Users, including what Authorized Users do with your data, and for all fees incurred by Authorized Users (or
    from adding Authorized Users). All use of Software must be solely for the benefit of you.</p>

  <span class="h2 text-left">3. COMPLIANCE</span><br />

  <p>3.1 Product Integrity. You will not, directly or indirectly: reverse engineer, decompile,
    disassemble or
    otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how,
    algorithms, documentation or data related to the Software; modify, translate, or create derivative works
    based
    on the Software or related Services (except to the extent expressly permitted by us or authorized within the
    Services); use the Services or any Software for time sharing or service bureau purposes or otherwise for the
    benefit of a third party; or remove any proprietary notices or labels. With respect to any Software that is
    distributed or provided to your for use on your premises or devices, we hereby grant you a non-exclusive,
    non-transferable, non-sublicensable license to use such Software in the use permitted herein.</p>
  <p>3.2 Compliance Statement. Licensee represents, covenants, and warrants that Licensee will
    use the Services
    only in compliance with Licensor’s standard published policies then in effect (the “Policy”) and all
    applicable laws and regulations. Licensee hereby agrees to indemnify and hold harmless Licensor against any
    damages, losses, liabilities, settlements and expenses (including without limitation costs and attorneys’
    fees) in connection with any claim or action that arises from an alleged violation of the foregoing or
    otherwise from Licensee’s use of Services. Although Licensor has no obligation to monitor Licensee’s use of
    the Services, Licensor may do so and may prohibit any use of the Services it believes may be (or alleged to
    be) in violation of the foregoing.</p>
  <p>3.3 No Personal Advice. Rockwell Trading Services, LLC and/or any of its representatives
    cannot make any
    guarantees or promises about the outcome of your use of the Software and associated Software adjacent
    programs, such as training and online education. Rockwell Trading Services, LLC is an independent, software
    provider and educational site. Rockwell Trading Services, LLC is not a financial broker, nor "signal
    services", advising it's participants when to purchase or sell securities. Trades are based on inpidual
    risk
    assessment and skill. TRADING IN ANY FINANCIAL MARKET INVOLVES SUBSTANTIAL FINANCIAL RISK EXPOSURE. Although
    Rockwell Trading Services, LLC believes firmly in the results of our customers, all users have the
    responsibility to carefully consider their financial condition before trading in these markets, and only
    non-essential capital should be used. There are no guarantees or certainties in trading. Reliability of
    trading signals from strategies and systems is in probabilities only. By entering into this agreement you
    certify that you or any of your representatives and/or heirs agree to hold Rockwell Trading Services, LLC
    harmless and without liability for any losses you may incur as a result of using the software,
    informational,
    educational, or data oriented products or services.</p>
  <p>3.4 License Term. This License is effective when executed by both parties and the license
    granted to the
    Software remains in force until you stop using the Software or until we terminate this License because of
    your
    failure to comply with any of its terms and conditions.</p>

  <span class="h2 text-left">4. TERMINATION</span><br />

  <p>
    4.1 Termination. We shall have the right to immediately terminate this License if you fail to perform any
    obligation required of Licensee under this License or if Licensee becomes bankrupt or insolvent.<br />
  </p>
  <p>
    4.2 Return Or Destruction Of Software Upon Termination. Upon termination of this License, you shall return to
    us or destroy the original and all copies of the Software including partial copies and modifications. We shall
    have a reasonable opportunity to conduct an inspection of your place of business to assure compliance with
    this provision.<br />
  </p>
  <span class="h2 text-left">5. MODIFICATIONS AND ENHANCEMENTS</span><br />

  <p>

    5.1 Modifications Prohibited. You will make no efforts to reverse engineer the Software, or make any
    modifications or enhancements without our express written consent.<br />
  </p>
  <span class="h2 text-left">6. WARRANTY LIMITATIONS</span><br />
  <p>
    6.1 THE SOFTWARE IS PROVIDED "AS IS." LICENSOR DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO, ALL
    EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SOME STATES DO NOT
    ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.<br />
  </p>
  <span class="h2 text-left">7. REMEDY LIMITATIONS</span><br />
  <p>
    7.1 Limitations. Licensor's entire liability and Licensor's sole and exclusive remedy for breach of the
    foregoing warranty shall be Licensor's option to either:
    - Return to Licensee the license fee for the period in which the Software did not perform according to this
    warranty, or
    - Repair the defects or replace the Software.
  </p>


  <span class="h2 text-left">8. DAMAGE LIMITATIONS</span><br />

  <p>8.1 NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR INDIRECT, SPECIAL, CONSEQUENTIAL OR
    INCIDENTAL DAMAGES,
    INCLUDING LOSS OF PROFITS, AND LICENSOR'S LIABILITY TO LICENSEE FOR ANY OTHER DAMAGES RELATING TO OR ARISING
    OUT OF THIS AGREEMENT WHETHER IN CONTRACT, TORT, OR OTHERWISE WILL BE LIMITED TO THE AMOUNT RECEIVED BY
    LICENSOR FROM LICENSEE AS COMPENSATION FOR THE SOFTWARE DURING THE 3 MONTH PERIOD IMMEDIATELY PRIOR TO THE
    TIME SUCH CLAIM AROSE.
  </p>
  <p> 8.2. GENERAL LIABILITY STATEMENT. NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR
    BODILY INJURY OF A
    PERSON, LICENSOR AND ITS AFFILIATES (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS),
    OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH
    RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT,
    NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY
    OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF
    BUSINESS;
    (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND
    LICENSOR’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER
    CLAIMS, EXCEED THE FEES PAID BY LICENSEE TO LICENSOR FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS
    PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT LICENSOR HAS BEEN ADVISED OF
    THE POSSIBILITY OF SUCH DAMAGES.
  </p>
  <p>8.3 CONFIDENTIALITY. Licensee will treat the Software as a trade secret and proprietary
    know-how belonging to
    Licensor that is being made available to Licensee in confidence. Licensee agrees to treat the Software with
    at
    least the same care as it treats its own confidential or proprietary information.
  </p>
  <p> 8.4. ARBITRATION. The parties agree to submit any dispute under this License to binding
    arbitration under the
    rules of the American Arbitration Association in the following location: Austin, Texas. Judgment upon the
    award rendered by the arbitrator may be entered in any court with jurisdiction to do so.
  </p>
  <p>8.5. ATTORNEY FEES. If any legal action is necessary to enforce this License, the
    prevailing party shall be
    entitled to reasonable attorney fees, costs and expenses in addition to any other relief to which it may be
    entitled.
  </p>
  <p>8.6. Consequential Damages Waiver. EXCEPT FOR EXCLUDED CLAIMS (DEFINED BELOW), NEITHER
    PARTY WILL HAVE ANY
    LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOSS OF USE, LOST OR INACCURATE DATA, LOST
    PROFITS, FAILURE OF SECURITY MECHANISMS, INTERRUPTION OF BUSINESS, COSTS OF DELAY OR ANY INDIRECT, SPECIAL,
    INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH
    DAMAGES
    IN ADVANCE.
  </p>
  <p>8.7. Liability Cap. EXCEPT FOR EXCLUDED CLAIMS, EACH PARTY’S AGGREGATE LIABILITY TO THE
    OTHER ARISING OUT OF
    OR RELATED TO THIS AGREEMENT WILL NOT EXCEED THE AMOUNT ACTUALLY PAID OR PAYABLE BY YOU TO US UNDER THIS
    AGREEMENT IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE CLAIM.
  </p>
  <p>8.8. Nature of Claims and Failure of Essential Purpose. The parties agree that the
    waivers and limitations
    specified in this section (Limitations of Liability) apply regardless of the form of action, whether in
    contract, tort (including negligence), strict liability or otherwise and will survive and apply even if any
    limited remedy specified in this Agreement is found to have failed of its essential purpose.
  </p>

  <span class="h2 text-left">9. GENERAL PROVISIONS</span><br />

  <p>
    9.1. Complete Agreement: This License Agreement together with all schedules referred to
    in this Agreement, all
    of which are incorporated herein by reference, constitutes the sole and entire Agreement between the
    parties.
    This Agreement supersedes all prior understandings, agreements, representations and documentation relating
    to
    the subject matter of this Agreement.
  </p>
  <p>
    9.2 Modifications: Modifications and amendments to this Agreement, including any exhibit,
    schedule or
    attachment hereto, shall be enforceable only if in writing and signed by authorized representatives of both
    parties.
  </p>
  <p>
    9.3 Applicable law: This License will be governed by the laws of the State of Texas.
  </p>
  <p>
    9.4 Notices: All notices and other communications given in connection with this License
    shall be in writing
    and shall be deemed given as follows:<br />
    - When delivered personally to the recipient's address as appearing in the introductory paragraph to this
    License.<br />
    - Three days after being deposited in the United States mail, postage prepaid to the recipient's address as
    appearing in the introductory paragraph to this License; or<br />
    -When sent by fax or telex to the last fax or telex number of the recipient known to the party giving notice.
    Notice is effective upon receipt provided that a duplicate copy of the notice is promptly given by
    first-class
    or certified mail or the recipient delivers a written confirmation of receipt.<br />
    - Any party may change its address appearing in the introductory paragraph to this License by given notice of
    the change in accordance with this paragraph.<br />
  </p>
  <p>
    9.5 No Agency. Nothing contained herein will be construed as creating any agency,
    partnership, joint venture
    or other form of joint enterprise between the parties.
  </p>
  <p>
    9.6 Severability. If any provision of this Agreement is found to be unenforceable or
    invalid, that provision
    will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain
    in
    full force and effect and enforceable.
  </p>
  <p>
    9.7 Assignment: The rights conferred by this License shall not be assignable by the
    Licensee without
    Licensor's prior written consent, Licensor may impose a reasonable license fee on any such assignment.
    Licensor may transfer and assign any of its rights and obligations under this Agreement without consent.
  </p>
  <p>
    9.8 Complete Agreement. This Agreement is the complete and exclusive statement of the
    mutual understanding of
    the parties and supersedes and cancels all previous written and oral agreements, communications and other
    understandings relating to the subject matter of this Agreement, and that all waivers and modifications must
    be in writing and signed by both parties, except as otherwise provided herein.
  </p>
  <p>
    9.9 No Agency. No agency, partnership, joint venture, or employment is created as a
    result of this Agreement
    and Licensee does not have any authority of any kind to bind Licensor in any respect whatsoever.
  </p>
  <p>
    10. In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled
    to recover costs and attorneys’ fees. All notices under this Agreement will be in writing and will be deemed
    to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if
    transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized
    overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt
    requested.</p>

  <span class="h2 text-left">11. SIGNATURES</span><br />
  <p>
    This Agreement is deemed accepted once you click on the “I Accept” prompt when installing
    the software, when
    you first install the software, or when you purchase the software, whichever prompt with the Agreement you
    received.
  </p>



</div>