import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { round } from '@u/utils';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogsService } from '@s/common';
import { AppVersionBannerService } from '@s/app-version-banner.service';
import { ConcurrentLoginService } from '@s/concurrent-login.service';
import { ObservableService } from 'src/app/core/directives/observable.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { SymbolsService } from '@s/symbols.service';
import { WheelService } from '@m1/wheel/wheel.service';
import { WheelScannerService } from './wheel-scanner-tab/wheel-scanner.service';
import { WheelViewModes } from '@t/wheel/wheel.types';
import { WheelTabs, IWheelStateFromRedirect } from '@m1/wheel/wheel.model';
import {
  UserSettings,
  WheelData,
  wheelDataWindowMinHeight,
  wheelRightPartDefaultWidth,
  wheelRightPartMinWidth,
  wheelRightPartMaxWidthPx,
  wheelChartSectionMinHeight,
  wheelCalcSectionMinHeight,
  wheelFilterDefaultHeight,
  PinnedOrderPanelMinWidth,
  ExchangeCountriesCodes
} from '@const';

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss'],
})
export class WheelComponent implements OnInit, AfterViewInit, OnDestroy {
  public selectedSymbol: any[];
  public premiumsValue = true;
  public isBanner = true;
  public showMaintenance = false;
  public isWheelScannerLoadingFirstTime = true;

  public selectedTab = WheelTabs.Chart;
  public tabsEnum = WheelTabs;

  public viewMode$ = this.observableServiceV2.wheelViewMode;
  public readonly viewModes = WheelViewModes;

  private savedWheelFiltersWidth;
  private subscriptions = new Subscription();
  public isMobile = false;
  public width: number = window.innerWidth;
  public height: number = window.innerHeight;
  public pinnedOrderPanel = 370;
  public rightPartWidthInPercents: number;
  public rightPartMinWidthInPercents: number;
  public rightPartMaxWidthInPercents: number;
  public rightPartWidthWithPinnedOrderPanelInPercents: number;
  public rightPartMaxWidthWithPinnedOrderPanelInPercents: number;
  public leftPartWidthInPercents: number;
  public leftPartWidthWithPinnedOrderPanelInPercents: number;
  public savedChartWheelChartSectionHeight: number;
  public wheelChartSectionHeightInPercents: number;
  public wheelCalcSectionHeightInPercents: number;
  public wheelChartSectionMinHeightInPercents: number;
  public wheelCalcSectionMinHeightInPercents: number;
  public dataWindowMinHeightInPercents: number;
  public wheelFilterHeight: number;
  public wheelDataWindowHeight: number;

  public isShowChartOverlay = false;
  public mobileWidth = 992;

  public currentSymbol$ = this.observableServiceV2.wheelSymbol;
  protected showOrderPanel = false;

  constructor(
    private dialogsService: DialogsService,
    private scannerService: WheelScannerService,
    public observableService: ObservableService,
    private observableServiceV2: ObservableServiceV2,
    private userDataService: UserDataService,
    private appVersionBannerService: AppVersionBannerService,
    private concurrentLoginService: ConcurrentLoginService,
    private symbolsService: SymbolsService,
    private wheelService: WheelService,
    private location: Location,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.onWindowResize(event);
  }

  async ngOnInit() {
    this.showMaintenance = this.observableServiceV2.showMaintenance.getValue();
    this.handleShowOrderPanel();

    const stateFromRedirect: IWheelStateFromRedirect = this.location.getState() as IWheelStateFromRedirect;

    if (stateFromRedirect && stateFromRedirect.symbol) {
      const symbol = await this.symbolsService.getBySymbol(stateFromRedirect.symbol.symbol, ExchangeCountriesCodes.US);

      if (!symbol) {
        return;
      }

      await this.wheelService.setSymbolForWheelPage(stateFromRedirect.symbol);
    }

    this.savedWheelFiltersWidth = this.observableServiceV2.wheelFiltersWidth.getValue();

    this.subscriptions.add(
      this.observableServiceV2.wheelFiltersHeight
        .pipe(take(1))
        .subscribe((filtersHeight) => {
          this.wheelFilterHeight = filtersHeight;
        })
    );
    this.subscriptions.add(
      this.observableServiceV2.showMaintenance.subscribe((showMaintenance) => this.showMaintenance = showMaintenance)
    );
    this.calculateWidthForSplitAreas();
    this.isMobile = this.width < this.mobileWidth;
    this.subscriptions.add(
      this.observableService.isBanner.subscribe((res) => {
        this.isBanner = res;
      }));

    if (this.getCookie('isBanner') == '') {
      this.isBanner = false;
    } else if (this.getCookie('isBanner') != '' && this.getCookie('isBanner') == 'true') {
      if (this.getCookie('bannerStatus') != '' && this.getCookie('bannerStatus') == '1') {
        this.isBanner = true;
      } else {
        this.isBanner = false;
      }
    } else if (this.getCookie('isBanner') != '' && this.getCookie('isBanner') == 'false') {
      this.isBanner = false;
    }

    this.selectedTab = this.observableServiceV2.isWheelCalculator.getValue() ? WheelTabs.Calculator : WheelTabs.Chart;
    this.premiumsValue = this.observableServiceV2.isWheelPremiums.getValue();

    this.subscriptions.add(
      this.observableServiceV2.isWheelCalculator.subscribe(
        (isWheelCalculator) => (this.selectedTab = isWheelCalculator ? WheelTabs.Calculator : WheelTabs.Chart)
      )
    );

    this.subscriptions.add(
      this.observableServiceV2.wheelChartSectionHeight
        .pipe(take(1))
        .subscribe((wheelChartHeight) => {
          this.wheelChartSectionHeightInPercents = wheelChartHeight;
          this.wheelCalcSectionHeightInPercents = 100 - this.wheelChartSectionHeightInPercents;
        })
    );

    this.subscriptions.add(
      this.observableServiceV2.isWheelPremiums.subscribe((isWheelPremiums) => (this.premiumsValue = isWheelPremiums))
    );

    this.subscriptions.add(
      this.observableServiceV2.tradingPanelOrderModalPinned.subscribe(() => this.handleShowOrderPanel())
    );

    this.subscriptions.add(
      this.observableServiceV2.tradingPanelOrderInput.subscribe(() => this.handleShowOrderPanel())
    );

    this.appVersionBannerService.subscribe();
    this.concurrentLoginService.subscribe();
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.observableServiceV2.isWheelScannerLoadingFirstTime$
        .subscribe((isWheelScannerLoadingFirstTime) => {
          this.isWheelScannerLoadingFirstTime = isWheelScannerLoadingFirstTime;

          // trigger resize for chart container to recalculate chart size inside it
          setTimeout(() => {
            this.leftPartWidthInPercents = this.leftPartWidthInPercents + 1;
            this.rightPartWidthInPercents = 100 - this.leftPartWidthInPercents;
            this.changeDetectorRef.markForCheck();
          }, 200);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.appVersionBannerService.unsubscribe();
    this.concurrentLoginService.unsubscribe();
  }

  public onDragStart(): void {
    this.isShowChartOverlay = true;
  }

  public onDragEnd(): void {
    this.isShowChartOverlay = false;
  }

  async onSetCurrentSymbol(event) {
    if (event) {
      this.selectedSymbol = event;

      if (this.selectedSymbol.length > 0) {
        this.scannerService.setSymbolChart$(this.selectedSymbol);

        if (this.observableServiceV2.isWheelCalculator.getValue()) {
          await this.userDataService.set(UserSettings.IsWheelCalculator, false);
        }
      } else {
        this.selectedSymbol = null;
      }
    }
  }

  getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  async onCalculatorToggle(value: WheelTabs) {
    await this.userDataService.set(UserSettings.IsWheelCalculator, value === WheelTabs.Calculator);
  }

  async onToggleSubTabbing(value) {
    await this.userDataService.set(UserSettings.IsWheelPremiums, value);
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.isMobile = this.width < this.mobileWidth;
    this.calculateWidthForSplitAreas();
    this.handlePinnedOrderModal();
  }

  calculateWidthForSplitAreas(): void {
    const onePercentFromCurrentWidth = this.width / 100;
    const onePercentFromCurrentHeight = this.height / 100;

    this.rightPartMinWidthInPercents = wheelRightPartMinWidth / onePercentFromCurrentWidth;
    this.rightPartMaxWidthInPercents = wheelRightPartMaxWidthPx / onePercentFromCurrentWidth;

    this.rightPartWidthWithPinnedOrderPanelInPercents = this.rightPartMinWidthInPercents + (this.pinnedOrderPanel / onePercentFromCurrentWidth);
    this.rightPartWidthInPercents = this.savedWheelFiltersWidth || wheelRightPartDefaultWidth / onePercentFromCurrentWidth;
    this.rightPartMaxWidthWithPinnedOrderPanelInPercents = this.rightPartMaxWidthInPercents + (this.pinnedOrderPanel / onePercentFromCurrentWidth);

    if (this.rightPartWidthInPercents < this.rightPartMinWidthInPercents) {
      this.rightPartWidthInPercents = this.rightPartMinWidthInPercents;
    }

    if (this.rightPartWidthInPercents > this.rightPartMaxWidthInPercents) {
      this.rightPartWidthInPercents = this.rightPartMaxWidthInPercents;
    }

    this.wheelChartSectionMinHeightInPercents = wheelChartSectionMinHeight / onePercentFromCurrentWidth;
    this.wheelCalcSectionMinHeightInPercents = wheelCalcSectionMinHeight / onePercentFromCurrentWidth;
    this.leftPartWidthInPercents = 100 - this.rightPartWidthInPercents;
    this.leftPartWidthWithPinnedOrderPanelInPercents = 100 - this.rightPartWidthInPercents - (this.pinnedOrderPanel / onePercentFromCurrentWidth);
    this.dataWindowMinHeightInPercents = wheelDataWindowMinHeight / onePercentFromCurrentHeight;
    this.wheelFilterHeight = this.wheelFilterHeight ? this.wheelFilterHeight : wheelFilterDefaultHeight;
    this.wheelDataWindowHeight = 100 - this.wheelFilterHeight;
  }

  async saveWheelFiltersWidth({ sizes }): Promise<void> {
    await this.userDataService.set(WheelData.FiltersContainerWidth, sizes[1]);
  }

  async saveWheelChartHeight({ sizes }): Promise<void> {
    const chartSectionHeight = round(sizes[0], 2);
    await this.userDataService.set(UserSettings.WheelChartSectionHeight, chartSectionHeight);
  }

  async saveWheelFilterHeight({ sizes }): Promise<void> {
    const wheelFilterHeight = round(sizes[0], 2);
    await this.userDataService.set(UserSettings.WheelFiltersHeight, wheelFilterHeight);
  }

  private handleShowOrderPanel() {
    const isPinned = this.observableServiceV2.tradingPanelOrderModalPinned.getValue();
    const orderInput = this.observableServiceV2.tradingPanelOrderInput.getValue();

    this.showOrderPanel = isPinned && !!orderInput;
  }

  private handlePinnedOrderModal() {
    if (!this.showOrderPanel || this.width > PinnedOrderPanelMinWidth) {
      return;
    }

    this.observableServiceV2.tradingPanelOrderModalPinned.next(false);

    const orderInput = this.observableServiceV2.tradingPanelOrderInput.getValue();
    this.dialogsService.openTradingPanelOrderDialog(orderInput);
  }
}
