import { StringifiedGuid } from '@t/common';

export interface TradingLogSymbolSummaryPanelStateDataItem {
  showBreakEven: boolean;
  showCostBasis: boolean;
  isDoNotShowTrades: boolean;
  selectedGroupId: StringifiedGuid;
}

export const tradingLogSymbolSummaryPanelStateDataItemDefaultValue: TradingLogSymbolSummaryPanelStateDataItem = {
  isDoNotShowTrades: false,
  selectedGroupId: null,
  showBreakEven: true,
  showCostBasis: true
};
