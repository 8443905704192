import { IMagicBallOption } from '@c/trading-hub/magic-ball/magic-ball.model';

export const magicBallOptions: IMagicBallOption[] = [
  { title: 'Don’t do it!', class: 'danger' },
  { title: 'No!', class: 'danger' },
  { title: 'Chip says "No"', class: 'danger' },
  { title: 'Very doubtful', class: 'danger' },
  { title: 'You should wait', class: 'danger' },
  { title: 'Mark looks shocked', class: 'danger' },
  { title: 'Markus looks shocked', class: 'danger' },
  { title: 'Follow your plan', class: 'info' },
  { title: 'Trade what you see', class: 'info' },
  { title: 'Have a coffee first', class: 'info' },
  { title: 'Reply hazy, try again', class: 'info' },
  { title: 'Concentrate and ask again', class: 'info' },
  { title: 'Ask again later', class: 'info' },
  { title: 'Without a doubt', class: 'success' },
  { title: 'Yes definitely', class: 'success' },
  { title: 'Absolutely', class: 'success' },
  { title: 'Yes.', class: 'success' },
  { title: 'Chip says "Yes"', class: 'success' },
  { title: 'It is certain', class: 'success' },
  { title: 'Mark is nodding', class: 'success' },
  { title: 'Markus is nodding', class: 'success' },
  { title: 'It\'s Good, Good, Good', class: 'success' },
];
