import { Component, Input } from '@angular/core';
import { TradePositions } from '../../../../constants';
import { PastPerformanceBlock } from '../past-performance-block.abstract';

@Component({
  selector: 'app-longest-trade-length',
  templateUrl: './longest-trade-length.component.html',
  styleUrls: ['./longest-trade-length.component.scss']
})
export class LongestTradeLengthComponent extends PastPerformanceBlock {
  @Input() pastPerformance = null;
  @Input() pastPerformanceToCompare = null;
  @Input() tradePosition = TradePositions.LongAndShort;

  get averageTradeProgressBarWidth() {
    const { pastPerformance } = this;

    return (pastPerformance
      ? (pastPerformance.avg_trade_days * 100) / pastPerformance.longest_trade_days
      : '') + '%';
  }

  get longestTradeProgressBarWidth() {
    const { pastPerformance, pastPerformanceToCompare } = this;

    if(!pastPerformance || !pastPerformanceToCompare) {
      return '0%';
    }

    if(pastPerformanceToCompare.longest_trade_days > pastPerformance.longest_trade_days) {
      return ((pastPerformance.longest_trade_days * 100) / pastPerformanceToCompare.longest_trade_days)+'%';
    }

    return '100%';
  }

  constructor() {
    super();
   }
}
