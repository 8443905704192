<div
  class="chat-container"
  [class.help-mode]="isHelpMode"
>
  <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon small-icon">
    </mat-icon>
  </button>

  @if (!isLoading) {
    @if (isHelpMode) {
      <div class="chat-header">
        <div class="chat-header-icon">
          <mat-icon [svgIcon]="'cody-chat'" class="cody-chat-icon"></mat-icon>
        </div>

        <div class="chat-header-content">
          <h2 class="chat-header-title">This answer is generated with Rocky </h2>
          <p class="chat-header-description">If you have additional questions, you can ask Rocky</p>
        </div>
      </div>
    }

    @for (conversation of conversations; track conversation.id || $index) {
      <div class="chat-container-inner">
      <app-chat-cody-second-conversation
        [conversation]="conversation"
        [conversationData]="conversationData"
        [isActive]="isActive"
        (conversationDeleted)="onDeleteConversation($event)"
        (conversationUpdated)="onUpdateConversation($event)"
      ></app-chat-cody-second-conversation>
      </div>
    }
  } @else {
    <div class="spinner-container">
      <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
    </div>
  }
</div>
