export enum MarketTimeState {
  Weekend = 'weekend',
  Holiday = 'holiday',
  PreMarket = 'pre-market',
  MarketOpened = 'market-opened',
  PostMarket = 'post-market',
  MarketClosed = 'market-closed'
}

export interface MarketState {
  isWeekend: boolean;
  isHoliday: boolean;
  isPreMarket: boolean;
  isMarketOpened: boolean;
  isPostMarket: boolean;
}

export interface IMarketStateChangedEvent {
  prevMarketState: MarketState;
  newMarketState: MarketState;
}
