/**
 * Starts an automatic scroll to the bottom for a specified container element at a given interval.
 * The scrolling will stop if the user scrolls up.
 *
 * @param containerId - The ID of the container element to scroll.
 * @param intervalMs - The interval in milliseconds at which to scroll the container to the bottom.
 * @returns () => void - A function to stop the automatic scrolling.
 *
 * @example
 * const stopAutoScroll = startAutoScrollToBottom('chatContainer', 1000);
 *
 * // To stop the auto-scrolling:
 * stopAutoScroll();
 */
export function startAutoScrollToBottom(
  containerId: string,
  intervalMs: number = 500
): () => void {
  const container = document.getElementById(containerId);

  if (!container) {
    return () => null;
  }

  let scrollInterval: NodeJS.Timeout | null = null;
  let lastScrollTop = container.scrollTop;

  function scrollToBottom() {
    container.scrollTop = container.scrollHeight;
    container.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
  }

  function startScrolling() {
    clearInterval(scrollInterval);

    scrollInterval = setInterval(() => {
      scrollToBottom();
    }, intervalMs);
  }

  function stopScrolling() {
    clearInterval(scrollInterval);
    container.removeEventListener('scroll', handleScroll);
  }

  function handleScroll() {
    const currentScrollTop = container.scrollTop;

    if (currentScrollTop < lastScrollTop) {
      stopScrolling();
    } else {
      startScrolling();
    }

    lastScrollTop = currentScrollTop;
  }

  container.addEventListener('scroll', handleScroll);

  return stopScrolling;
}
