import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AppVersionBannerService } from '@s/app-version-banner.service';
import { ConcurrentLoginService } from '@s/concurrent-login.service';
import { SleeknoteService } from '@s/sleeknote.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    private appVersionBannerService: AppVersionBannerService,
    private concurrentLoginService: ConcurrentLoginService,
    private dialog: MatDialog,
    private sleeknoteService: SleeknoteService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.sleeknoteService.trackingActivityForSleeknote();
    this.appVersionBannerService.subscribe();
    this.concurrentLoginService.subscribe();
  }

  ngOnDestroy(): void {
    this.sleeknoteService.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();

    this.appVersionBannerService.unsubscribe();
    this.concurrentLoginService.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 27) {
      this.dialog.closeAll();
    }
  }
}
