import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
  @Input() text?: string;
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() iconName: string = null;
  @Input() appendClass: 'primary' | 'danger' | 'success' | 'purple' | 'light-success' | 'light-danger' = 'primary';

  constructor() { }

  ngOnInit(): void { }
}
