import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NavigationService } from '@s/navigation.service';
import { UserDataService } from '@s/user-data.service';
import { TradingLogGroupType } from '@t/trading-log';
import { TabNames, UserSettings } from '@const';
import { TradingLogContainerTab } from '@c/trading-log/trading-log-container/trading-log-container.model';
import { IPlaceTradeStatusModalData } from '@mdl/place-trade-status-modal/place-trade-status-modal.model';

@Component({
  selector: 'app-place-trade-status-modal',
  templateUrl: './place-trade-status-modal.component.html',
  styleUrls: ['./place-trade-status-modal.component.scss']
})
export class PlaceTradeStatusModalComponent implements OnInit {
  protected currentStatus: IPlaceTradeStatusModalData['status'] = 'progress';

  constructor(
    private dialogRef: MatDialogRef<PlaceTradeStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected config: IPlaceTradeStatusModalData,
    private navigationService: NavigationService,
    private userDataService: UserDataService,
  ) {}

  ngOnInit(): void {
    this.currentStatus = this.config.status;
  }

  public set status(value: IPlaceTradeStatusModalData['status']) {
    this.currentStatus = value;
  }

  public get status(): IPlaceTradeStatusModalData['status'] {
    return this.currentStatus;
  }

  protected onClose(): void {
    this.dialogRef.close();
  }

  protected async openTradingLogPage(): Promise<void> {
    this.onClose();

    await this.userDataService.set(UserSettings.TradingLogContainerTab, TradingLogContainerTab.Trades);
    await this.userDataService.set(UserSettings.TradingLogTradesTab, TradingLogGroupType.Active);
    await this.navigationService.redirectToTab(TabNames.TradingLog);
  }
}
