import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TradesGroupModalInputModel, TradesGroupModalOutputModel, TradesGroupModalSelectedItemModel } from './models';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GroupInfoPanelComponent } from './group-info-panel';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupsListFilterFormComponent, GroupsListFilterFormModel } from './groups-list-filter-form';
import { BehaviorSubject, Subject, startWith, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { MomentDateTimeFormats } from '@const';
import { GroupInfoPanelInputModel } from './group-info-panel/models';
import { TradingLogGroupType } from '@t/trading-log';
import { StringifiedGuid } from '@t/common';
import { DialogsService } from '@s/common';
import { InViewportModule } from "ng-in-viewport";

export interface TradesGroupModalComponentFormModel {
  symbol: string;
  showArchivedTrades: boolean;
}
@Component({
  selector: 'app-trades-group-modal',
  templateUrl: './trades-group-modal.component.html',
  styleUrls: ['./trades-group-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatFormFieldModule, MatRadioModule, MatButtonModule, MatIconModule, MatSlideToggleModule, FormsModule, ReactiveFormsModule, GroupInfoPanelComponent, GroupsListFilterFormComponent, MatDialogModule, InViewportModule],
})
export class TradesGroupModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GroupsListFilterFormComponent) formComponent: GroupsListFilterFormComponent;

  protected selectedGroupId = null;
  protected displayedColumns: string[] = ['trade', 'symbol', 'side', 'price', 'qty', 'commission'];
  protected dataSource: ReadonlyArray<TradesGroupModalSelectedItemModel> = [];
  protected isError = false;
  protected activeGroups$: BehaviorSubject<ReadonlyArray<GroupInfoPanelInputModel>> = new BehaviorSubject([]);
  protected archivedGroups$: BehaviorSubject<ReadonlyArray<GroupInfoPanelInputModel>> = new BehaviorSubject([]);
  protected isActiveOpened = true;
  protected isArchivedOpened = true;

  protected isGroupsListTopVisible = true;
  protected isGroupsListBottomVisible = true;

  private _symbolFilter$: BehaviorSubject<string> = new BehaviorSubject('');
  private _destroy$: Subject<void> = new Subject();

  constructor(
    private _dialogsService: DialogsService,
    private _dialogRef: MatDialogRef<TradesGroupModalComponent, TradesGroupModalOutputModel>,
    @Inject(MAT_DIALOG_DATA) private _data: TradesGroupModalInputModel,
    private _cdr: ChangeDetectorRef
  ) {

    this._symbolFilter$.pipe(startWith((this._data?.selectedItem?.symbol || '')))
      .subscribe({
        next: (symbolFilter) => {

          const groups = _data.groups;
          const summaryMap = _data.summaryMap;
          const accounts = _data.accounts;
          const strategies = _data.strategies;

          let activeGroups: ReadonlyArray<GroupInfoPanelInputModel> = [];
          let archivedGroups: ReadonlyArray<GroupInfoPanelInputModel> = [];

          groups.filter((group) => {
            if (!symbolFilter) {
              return true;
            }

            // Only groups with selected symbol
            return (group.symbol || '')?.toLowerCase() === (symbolFilter || '')?.toLowerCase();
          })
            .map((group) => {
              const summary = summaryMap.get(group.id);
              const accountName = accounts.find((account) => account.id === group.account_id)?.name ?? '';
              const strategyName = strategies.find((strategy) => strategy.id === group.strategy_id)?.name ?? '';
              const minDate = summary?.min_date ? moment(summary?.min_date, MomentDateTimeFormats.ServerDate) : null;
              const maxDate = summary?.max_date ? moment(summary?.max_date, MomentDateTimeFormats.ServerDate) : null;
              const item: GroupInfoPanelInputModel = {
                symbol: group.symbol,
                group_id: group.id,
                minDate,
                maxDate,
                accountName: accountName || 'Account',
                strategyName: strategyName || 'Strategy',
                ...summary
              };
              return { item, type: group?.type };
            })
            .forEach(({ item, type }) => {
              switch (type) {
                case TradingLogGroupType.Active:
                  activeGroups = [...activeGroups, item];
                  break;
                case TradingLogGroupType.Archived:
                  archivedGroups = [...archivedGroups, item];
                  break;
                default:
                  break;
              }

              if (item.group_id === _data.selectedGroupId) {
                if (type === TradingLogGroupType.Active) {
                  this.isActiveOpened = true;
                }
                else if (type === TradingLogGroupType.Archived) {
                  this.isArchivedOpened = true;
                }
              }
            });
          this.activeGroups$.next(activeGroups);
          this.archivedGroups$.next(archivedGroups);
        }
      });

    if (_data.selectedItem) {
      this.dataSource = [_data.selectedItem];
    }
    this.selectedGroupId = _data.selectedGroupId ?? null;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.formComponent.formModelChanged.pipe(takeUntil(this._destroy$)).subscribe(formValue => {
      this.applyFilterChnages(formValue)
    });

    this.formComponent.value = {
      symbol: (this._data.selectedItem?.symbol ?? '')
    }

    this.applyFilterChnages(this.formComponent.value)
    this._cdr.detectChanges();
    if (this._data.selectedGroupId) {
      this.scrollToPanel(this._data.selectedGroupId);
    }
  }

  ngOnInit(): void {
  }

  protected close(groupId?: StringifiedGuid): void {
    this._dialogRef.close(groupId ? {
      selectedGroupId: groupId
    } : null);
  }

  protected async select(): Promise<void> {
    if (this.selectedGroupId) {
      this.close(this.selectedGroupId);
    } else {
      await this._dialogsService.info('Please select a group and try again.', 'Importing trades');
    }
  }

  private applyFilterChnages(model: GroupsListFilterFormModel): void {
    this._symbolFilter$.next(model.symbol);
  }

  protected onChangeVisibility(
    { target, visible }: { target: Element; visible: boolean },
    type: 'top' | 'bottom',
  ): void {
    const className = type === 'top' ? 'shadow-top' : 'shadow-bottom';
    if (type === 'top') {
      this.isGroupsListTopVisible = visible;
    }

    if (type === 'bottom') {
      this.isGroupsListBottomVisible = visible;
    }
  }

  private scrollToPanel(groupId: string): void {
    setTimeout(() => {
      const elem = document.getElementById(`group-${groupId}`);
      elem?.scrollIntoView({ block: 'center' });
    }, 0);
  }
}
