<div *ngIf="hasData else no_data" class="risk-reward">
    <div class="risk-reward-top row">
        <div class="risk-reward-top-left">
          <div class="buy-put-text">BUY
            <div [ngClass]="options$.value[0]?.type ? options$.value[0]?.type : options$.value[1]?.type">
                {{options$.value[0]?.type ? options$.value[0]?.type.toUpperCase() : options$.value[1]?.type.toUpperCase()}}
            </div>
          </div>
          <div class="expiry"><small>Expiration:</small>
            {{options$.value[0]?.expiration_date ? options$.value[0]?.expiration_date : options$.value[1]?.expiration_date}}
          </div>
          <div class="dte">
              DTE: {{options$.value[0]?.dte ? options$.value[0]?.dte : options$.value[1]?.dte}} days
          </div>
        </div>
        <div class="warning-message" *ngIf="warnings.length">
          <ng-container *ngIf="warnings.length === 1 else warningList">
            {{ warnings[0] }}
          </ng-container>
          <ng-template #warningList>
            <ul>
              <li *ngFor="let warning of warnings">{{ warning }}</li>
            </ul>
          </ng-template>
        </div>
    </div>
    <div class="row">
        <app-option-record
            [option]="options$.value[0]"
            [otherOption]="options$.value[1]"
            [tradingStrategy]="firstTradingStrategy"
            [accountRiskAmount]="accountRiskAmount"
            [isOptionGreeks]="isOptionGreeks"
            [ngStyle]="{'flex': !options$.value[0]?.itm || !options$.value[0]?.atm  ? '0 0 25%' : '0 0 50%'}"
            [placeOrderCanBeShown]="showPlaceOrderButtons"
            [symbol]="symbol"
            [isPrint]="isPrint">
        </app-option-record>
        <app-option-record
            [option]="options$.value[1]"
            [otherOption]="options$.value[0]"
            [tradingStrategy]="secondTradingStrategy"
            [accountRiskAmount]="accountRiskAmount"
            [isOptionGreeks]="isOptionGreeks"
            [ngStyle]="{'flex': !options$.value[1]?.itm || !options$.value[1]?.atm  ? '0 0 25%' : '0 0 50%'}"
            [placeOrderCanBeShown]="showPlaceOrderButtons"
            [symbol]="symbol"
            [isPrint]="isPrint">
        </app-option-record>
    </div>
    <div (click)="toggleOptionGreeks()" class="hide-option-greeks">
        <span *ngIf="isOptionGreeks">Hide Option Greeks</span>
        <span *ngIf="!isOptionGreeks">Show Option Greeks</span>
    </div>
</div>
<ng-template #no_data>
    <div *ngIf="!isLoading" class="no-data s2">
        No options fit our trading criteria for this stock.
    </div>
</ng-template>
