import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateFunction {
  countTotalDays = (startDate, endDate) => {
    let start = moment.unix(startDate);
    let end = moment.unix(endDate);
    return moment.duration(end.diff(start)).asDays();
  }
}
