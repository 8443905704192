import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, from, Subject, Subscriber, switchMap } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { UserDataService } from '@s/user-data.service';
import { ChatCodyService } from '@s/chat-cody.service';
import { IMessage } from '@c/trading-hub/chat/chat.model';
import { ICodyBot, ICodyConversation } from '@mod/data/chat-cody.model';
import { ICodyConversationData, ICodyConversationUpdated } from '@c/trading-hub/chat-cody-second/cody-chat.model';

const DEFAULT_BOT_NAME = 'PXO Rocky';
const DEFAULT_CONVERSATION_NAME = 'PXO Rocky Chat';
const SAVE_CONVERSATION_DATA_KEY = 'codyChatConversationData';
const SAVE_CONVERSATION_DATA_DEBOUNCE_TIME = 500;

@Component({
  selector: 'app-chat-cody-second',
  templateUrl: './chat-cody-second.component.html',
  styleUrls: ['./chat-cody-second.component.scss'],
})
export class ChatCodySecondComponent implements OnInit, OnDestroy {
  @Input() isActive: boolean;

  @Input()
  protected isHelpMode: boolean = false;

  protected currentBot: ICodyBot | null = null;
  protected conversations: ICodyConversation[] = [];
  protected isLoading = true;

  // there is only one conversation for now
  protected conversationData: ICodyConversationData = { conversationId: null, feedbacks: {} };

  private saveConversationData$ = new Subject<ICodyConversationData>();
  private subscriber = new Subscriber();

  constructor(
    private chatCodyService: ChatCodyService,
    private userDataService: UserDataService,
  ) { }

  ngOnInit(): void {
    this.subscriber.add(
      this.saveConversationData$
        .pipe(
          debounceTime(SAVE_CONVERSATION_DATA_DEBOUNCE_TIME),
          distinctUntilChanged(_.isEqual),
          switchMap((data) => this.userDataService.set(SAVE_CONVERSATION_DATA_KEY, data)),
        )
        .subscribe()
    );

    from(this.chatCodyService.getBots())
      .pipe(
        tap((botsResponse) => {
          this.currentBot = botsResponse.result.data.find((item) => item.name === DEFAULT_BOT_NAME);
        }),
        switchMap(() => from(this.userDataService.getAsJSON(SAVE_CONVERSATION_DATA_KEY))),
        switchMap((conversationData) => {
          if (!conversationData || !conversationData.conversationId) {
            return this.chatCodyService.createConversation(this.currentBot.id, DEFAULT_CONVERSATION_NAME);
          }

          this.conversationData = conversationData;

          // create new conversation in case of error for an existing one
          return this.chatCodyService.getConversationById(conversationData.conversationId)
            .pipe(catchError(() => this.chatCodyService.createConversation(this.currentBot.id, DEFAULT_CONVERSATION_NAME)));
        }),
        tap((conversationResponse) => {
          this.conversations = [conversationResponse.result.data];

          if (!this.conversationData || !this.conversationData.conversationId) {
            this.conversationData = { conversationId: conversationResponse.result.data.id, feedbacks: {} };
          }

          this.isLoading = false;
        }),
        switchMap((conversationResponse) => {
          return from(this.userDataService.set(SAVE_CONVERSATION_DATA_KEY, this.conversationData));
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  protected async onDeleteConversation(conversation: ICodyConversation): Promise<void> {
    let updatedConversations = this.conversations.filter((item) => item.id !== conversation.id);

    await firstValueFrom(this.chatCodyService.deleteConversationById(conversation.id));

    if (updatedConversations.length === 0) {
      const newConversationResponse = await firstValueFrom(
        this.chatCodyService.createConversation(this.currentBot.id, DEFAULT_CONVERSATION_NAME)
      );

      const conversationId = newConversationResponse.result.data.id;
      const conversationData: ICodyConversationData = {
        conversationId,
        feedbacks: {},
      };

      await this.userDataService.set(SAVE_CONVERSATION_DATA_KEY, conversationData);

      updatedConversations = [newConversationResponse.result.data];
    }

    this.conversations = updatedConversations;
  }

  protected onUpdateConversation({ data }: ICodyConversationUpdated): void {
    this.saveConversationData$.next(data);
  }

  protected trackByFn(index: number, item: IMessage): string | number {
    return item.id ?? index;
  }
}
