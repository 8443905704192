import { Injectable } from '@angular/core';

import { Response } from '../core/interface/response';

@Injectable({
  providedIn: 'root',
})
export class RestRequestorService {
  private _requests = {};

  constructor() {
  }

  public makeRequest<T = any>(key: string, fn: () => Promise<Response<T>>, ignoreCache: boolean = false): Promise<Response<T>> {
    if (this._requests[key] && !ignoreCache) {
      return this._requests[key];
    }

    this._requests[key] = fn();

    return this._requests[key];
  }

  public clear(key: string = null): void {
    if (key) {
      delete this._requests[key];
    } else {
      this._requests = {};
    }
  }
}
