import { ComparerFunctionType } from '@t/common/comparer-function.type';
import * as moment from 'moment';
import { Moment, unitOfTime } from 'moment';

export function getDateComparer<T>(
  getField: (o: T) => Moment | string,
  granularity: unitOfTime.StartOf = 'day'
): ComparerFunctionType<T> {
  return (a: T, b: T) => {
    const fieldA = getField(a);
    const fieldB = getField(b);
    if (!fieldA && !fieldB) {
      return 0;
    }

    if (!fieldA) {
      return -1;
    }

    if (!fieldB) {
      return 1;
    }

    return moment(fieldA).isBefore(moment(fieldB), granularity) ? -1 : 1;
  };
}

export function getDateComparerDesc<T>(
  getField: (o: T) => Moment | string,
  granularity: unitOfTime.StartOf = 'day'
): ComparerFunctionType<T> {
  return (a: T, b: T) => {
    const fieldA = getField(a);
    const fieldB = getField(b);
    if (!fieldA && !fieldB) {
      return 0;
    }

    if (!fieldA) {
      return 1;
    }

    if (!fieldB) {
      return -1;
    }

    return moment(fieldA).isSameOrAfter(moment(fieldB), granularity) ? -1 : 1;
  };
}
