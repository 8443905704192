import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';

import { RestRequestorService } from '@s/rest-requestor.service';
import { MomentDateTimeFormats } from '@const';
import { RequestResponseModel } from '@mod/common/request-response.model';
import {
  EconomicCalendarModel,
  EconomicEventByDateModel,
  EconomicEventByDateModelExtended,
  EconomicEventByTimeModel
} from '@mod/calendars/economic-calendar.model';

@Injectable({
  providedIn: 'root'
})
export class EconomicCalendarService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService,
  ) {}

  public async get(currency?: string, from?: string, to?: string): Promise<EconomicCalendarModel[]> {
    const { result } = await this.restRequesterService.makeRequest(
      `economic_calendar_${currency ?? 'N/A'}_${from ?? 'N/A'}_${to ?? 'N/A'}`,
      () => firstValueFrom(
        this.http.get<RequestResponseModel<EconomicCalendarModel[]>>(
          `/v2/econCalendar`,
          { params: { currency, from, to } })
      )
    );

    return result;
  }

  public groupEventsByDay(
    economicEvents: EconomicCalendarModel[],
    minDate: string,
    maxDate: string,
  ): EconomicEventByDateModelExtended[] {
    const minDateMoment = moment(minDate);
    const maxDateMoment = moment(maxDate);

    // create dates array between min and max date
    const dates: string[] = [];
    if (minDateMoment.isValid() && maxDateMoment.isValid()
      && minDateMoment.isSameOrBefore(maxDateMoment)
    ) {
      let currentDate = minDateMoment.clone();

      while (currentDate.isSameOrBefore(maxDateMoment)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'days');
      }
    }

    const eventsGroupedByDay = dates.reduce((acc, date) => {
      acc[date] = [];
      return acc;
    }, {});

    economicEvents.forEach((event) => {
      const date = event.date.split('T')[0];

      if (!eventsGroupedByDay[date]) {
        eventsGroupedByDay[date] = [];
      }

      eventsGroupedByDay[date].push(event);
    });

    const eventsGroupedDayAndTime = {};

    for (const day in eventsGroupedByDay) {
      if (day) {
        eventsGroupedDayAndTime[day] = eventsGroupedByDay[day].reduce((eventPerTime, event) => {
          const displayEvent = { time_raw: event.time_raw };
          Object.assign(displayEvent, event);

          if (!eventPerTime[displayEvent.time_raw]) {
            eventPerTime[displayEvent.time_raw] = [];
          }

          eventPerTime[displayEvent.time_raw].push(displayEvent);

          return eventPerTime;
        }, {});
      }
    }

    const economicEventsGroupedByDayAndTime = Object.keys(eventsGroupedDayAndTime)
      .map((date) => {
        const timeObjectWithSpecialStrings: EconomicEventByTimeModel[] = [];
        const dateObject: EconomicEventByDateModel  = {
          date,
          times: Object.keys(eventsGroupedDayAndTime[date])
            .map((time) => {
              const timeObject = {
                time: this.formatEconomicEventTime(time),
                events: eventsGroupedDayAndTime[date][time],
              };

              if (!timeObject.time) {
                timeObjectWithSpecialStrings.push(timeObject);
                return {} as EconomicEventByTimeModel;
              }

              return timeObject;
            })
            .sort(this.compareTime)
        };

        return {
          ...dateObject,
          urlData: null,
          date,
          times: [
            ...timeObjectWithSpecialStrings,
            ...[...dateObject.times].filter((time) => time?.time)
          ],
        };
      });

    return economicEventsGroupedByDayAndTime.map((event) => {
      return {
        ...event,
        count: event.times.reduce((acc, time) => acc + time.events.length, 0),
        urlData: null,
      };
    });
  }

  private formatEconomicEventTime(time: string): string | null {
    const formattedTime = moment(time, ['h:mma']).format(MomentDateTimeFormats.TimeAmPm);

    if (formattedTime === 'Invalid date') {
      return time;
    }

    return formattedTime;
  }

  private compareTime(a: { time: string }, b: { time: string }): -1 | 1 | 0 {
    const firstTime = moment(a.time, ['h:mma']).format(MomentDateTimeFormats.ReadableTime);
    const secondTime = moment(b.time, ['h:mma']).format(MomentDateTimeFormats.ReadableTime);

    // set special time strings (Tentative, All Day) to the top
    if (firstTime === 'Invalid date' && secondTime === 'Invalid date') {
      return 0;
    }

    if (firstTime === 'Invalid date' && secondTime !== 'Invalid date') {
      return -1;
    }

    if (firstTime !== 'Invalid date' && secondTime === 'Invalid date') {
      return 1;
    }

    if (firstTime < secondTime) {
      return -1;
    }

    if (firstTime > secondTime) {
      return 1;
    }

    return 0;
  }
}
