import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { MomentDateTimeFormats, PxoOrderTag, formatDecimalExt } from '@const';
import { OrderFilterStatus, OrderViewModel, TradierAccount, TradierPosition, TradierOrder, TradierOrderClass, TradierOrderStatus, TradingSymbolDetails, TradierHistoryTransaction } from '@mod/trading-panel/trading-panel.model';

@Injectable({
  providedIn: 'root'
})
export class TradierService {

  public accounts$ = new BehaviorSubject<TradierAccount[]>([]);
  public positions$ = new BehaviorSubject<TradierPosition[]>([]);
  public activeOrders$ = new BehaviorSubject<TradierOrder[]>([]);
  public historyOrders$ = new BehaviorSubject<TradierOrder[]>([]);
  public accountHistoryTransactions$ = new BehaviorSubject<ReadonlyArray<TradierHistoryTransaction>>([]);

  private ocoOrderClasses = ['oco', 'oto', 'otoco'];

  constructor() { }

  private _canBeCanceled(order: TradierOrder, isOption: boolean, isLeg: boolean): boolean {
    const isPxoOrder = `${order.tag || ''}`.startsWith(PxoOrderTag);
    const isMatchingStatus = order.status === TradierOrderStatus.Open
      || order.status === TradierOrderStatus.PartiallyFilled
      || order.status === TradierOrderStatus.Pending;

    return isPxoOrder && isMatchingStatus;
  }

  private _canBeModified(order: TradierOrder, isOption: boolean, isLeg: boolean): boolean {
    if (isLeg) {
      return false;
    }

    if (order.leg?.length) {
      return false;
    }

    const isPxoOrder = `${order.tag || ''}`.startsWith(PxoOrderTag);
    const isMatchingStatus = order.status === TradierOrderStatus.Open;

    return isPxoOrder && isMatchingStatus;
  }

  private _getOrderDurationName(duration: string): string {
    if (!duration) {
      return '';
    }

    let durationName = '';

    if (duration === 'day') {
      durationName = 'Day';
    } else if (duration === 'pre') {
      durationName = 'Pre-Market';
    } else if (duration === 'post') {
      durationName = 'Post-Market';
    } else if (duration === 'gtc') {
      durationName = 'GTC';
    }

    return durationName;
  }

  private _getOrderNote(order: TradierOrder, isOption: boolean, isLeg: boolean): string {
    let note = '';

    if (isOption) {
      note = order.strategy;
    } else if (!isLeg && order.class !== TradierOrderClass.Equity) {
      note = order.strategy && order.strategy !== 'unknown'
        ? order.strategy
        : order.class;
    }

    return note;
  }

  private _firstCharUpperCase(input: string): string {
    return input?.length ? `${input.charAt(0).toUpperCase()}${input.slice(1)}` : '';
  }

  private _isDisplaySymbol(order: TradierOrder): boolean {
    if (!order.leg) {
      return true;
    }

    const symbols = {};

    for (const legOrder of order.leg || []) {
      const { symbol } = this.getSymbolDetails(legOrder.option_symbol || legOrder.symbol);
      symbols[symbol] = true;
    }

    return Object.keys(symbols).length == 1;
  }

  private _isDisplayType(order: TradierOrder, parentOrder: OrderViewModel) {
    return !parentOrder || this.ocoOrderClasses.some((c) => c === parentOrder.class);
  }

  private _isDisplayPrice(order: TradierOrder, parentOrder: OrderViewModel) {
    return !parentOrder || this.ocoOrderClasses.some((c) => c === parentOrder.class);
  }

  private _isDisplayStopPrice(order: TradierOrder, parentOrder: OrderViewModel) {
    return !parentOrder || this.ocoOrderClasses.some((c) => c === parentOrder.class);
  }

  private _isDisplayDuration(order: TradierOrder, parentOrder: OrderViewModel) {
    return !parentOrder || this.ocoOrderClasses.some((c) => c === parentOrder.class);
  }

  private _toPascalCase(input: string, splitSeparator: string = ' '): string {
    return input.split(splitSeparator).map(x => this._firstCharUpperCase(x.trim())).join(' ');
  }

  public filterOrdersByStatusFilter(filterStatus: OrderFilterStatus, orders: Array<TradierOrder>, orderFilterToStatusMap: Map<OrderFilterStatus, Array<TradierOrderStatus>>) {
    const matchingStatuses: Array<TradierOrderStatus> = orderFilterToStatusMap.get(filterStatus);
    return orders.filter(({ status }) => !matchingStatuses.length || matchingStatuses.some((x: TradierOrderStatus) => x === status));
  }

  getAccountDisplayName(account: TradierAccount): string {
    return account.nickname
      ? `${account.nickname} - ${account.account_number}`
      : `${this._firstCharUpperCase(account.type)} - ${account.account_number}`;
  }

  public getSymbolDetails(rawSymbol: string): TradingSymbolDetails {
    let symbol = null;
    let description = null;
    let isOption = false;
    let optionExpirationDate = null;
    let optionType = null;
    let optionStrike = null;

    // special format for options 'AAPL220916C00750000'
    const symbolData = /(\D+)(\d{6})([P|C])(\d{8})/g.exec(rawSymbol);
    if (symbolData?.length === 5) {
      symbol = symbolData[1];
      optionExpirationDate = moment(symbolData[2], 'YYMMDD');
      optionType = symbolData[3] === 'C'
        ? 'CALL'
        : 'PUT';
      optionStrike = Number(symbolData[4]) / 1000;

      description = `${optionExpirationDate.format(MomentDateTimeFormats.ReadableDate)} $${formatDecimalExt(optionStrike, 2, 8)} ${optionType}`;
      isOption = true;
    } else {
      symbol = rawSymbol;
    }

    return {
      symbol,
      description,
      isOption,
      optionExpirationDate,
      optionType,
      optionStrike
    };
  }

  public getOrderViewModel(order: TradierOrder, parentOrder?: OrderViewModel): OrderViewModel {
    let { symbol, description, isOption } = this.getSymbolDetails(order.option_symbol || order.symbol);

    // Take the first leg to get the symbol
    if (!symbol && order.leg?.length && this._isDisplaySymbol(order)) {
      symbol = order.leg[0].symbol;
    }

    const isLeg = !!parentOrder;
    const symbolClass = isLeg ? 'symbol-content sub-symbol-row' : 'symbol-content';

    const note = this._getOrderNote(order, isOption, isLeg);
    const side = order.side && !order.leg
      ? this._firstCharUpperCase(order.side.replace(/_/g, ' '))
      : '';
    const sideClass = order.side
      ? order.side.startsWith('buy')
        ? 'succsesfull table-small-symbol text-left side'
        : 'danger table-small-symbol text-left side'
      : 'table-small-symbol text-left side';

    const type = this._isDisplayType(order, parentOrder) && order.type
      ? this._toPascalCase(order.type.replace(/_/g, ' '))
      : '';
    const filled = order.exec_quantity !== undefined
      ? formatDecimalExt(order.exec_quantity, 0, 8)
      : null;
    const quantity = order.quantity !== undefined
      ? formatDecimalExt(order.quantity, 0, 8)
      : null;
    const filledQty = filled !== null && quantity !== null
      ? `${filled} / ${quantity}`
      : '';
    const price = this._isDisplayPrice(order, parentOrder) && order.price
      ? formatDecimalExt(order.price, 2, 8)
      : '';
    const stopPrice = this._isDisplayStopPrice(order, parentOrder) && order.stop_price
      ? formatDecimalExt(order.stop_price, 2, 8)
      : '';
    const fillPrice = order.avg_fill_price
      ? formatDecimalExt(order.avg_fill_price, 2, 8)
      : '';
    const duration = this._isDisplayDuration(order, parentOrder)
      ? this._getOrderDurationName(order.duration)
      : '';
    const status = order.status !== undefined ? order.status.toUpperCase() : '';
    const statusClass = order.status !== undefined ? `status-${order.status.toLowerCase()}` : '';
    const placingTime = (order.transaction_date || order.create_date) !== undefined
      ? moment(order.transaction_date || order.create_date).format(MomentDateTimeFormats.ReadableFullTimeWithYear)
      : '';
    const placingTimeUnix = (order.transaction_date || order.create_date) !== undefined
      ? moment(order.transaction_date || order.create_date).unix()
      : 0;
    const leg = order.leg;
    const canBeModified = this._canBeModified(order, isOption, isLeg);
    const canBeCanceled = this._canBeCanceled(order, isOption, isLeg);

    return {
      id: order.id,
      class: order.class,
      symbol,
      symbolClass,
      description,
      note: note?.toUpperCase(),
      side,
      sideClass,
      type,
      filledQty,
      price,
      stopPrice,
      fillPrice,
      duration,
      status,
      statusClass,
      placingTime,
      placingTimeUnix,
      isLeg,
      leg,
      canBeModified,
      canBeCanceled
    };
  }
}
