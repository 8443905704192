import { CdkDrag, CdkDragDrop, CdkDragStart, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as _ from 'lodash';
import { Subject, Subscription, from, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import {
  DEFAULT_COMPONENT_STATE,
  DEFAULT_GROUPED_WATCHLIST_SORT,
  DEFAULT_NEW_GROUP_NAME,
  HIDDEN_GROUP,
  MAX_GROUPS_NUMBER,
} from '@c/grouped-watchlist/grouped-watchlist.data';
import {
  BaseWatchlistItemModel,
  GroupedWatchlistGroupModel,
  GroupedWatchlistRowItemModel,
  GroupedWatchlistRowSubHeaderModel,
  GroupsStateModel,
  IWatchlistItem,
} from '@c/grouped-watchlist/grouped-watchlist.model';
import { EditableTitleComponent } from '@c/shared/editable-title/editable-title.component';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { ExchangeCountries, ExchangeCountriesCodes, WatchlistType } from '@const';
import { Flags, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { DialogsService } from '@s/common';
import { SearchPopupService } from '@s/search-popup.service';
import { ISymbol } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { WatchlistService } from '@s/watchlist.service';
import { ComponentSettingsSaver } from '@u/component-settings-saver';
import { isOverlayOpen } from '@u/ui-utils';
import { convertArrayToRecord } from '@u/utils';

// WARNING: be careful, component have too complicated logic (change sort, reorder, add/remove groups, items, etc.)
// check SPEC and current behaviour (as non-functional requirements) before any changes
@Component({
  selector: 'app-grouped-watchlist',
  templateUrl: './grouped-watchlist.component.html',
  styleUrls: ['./grouped-watchlist.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DragDropModule,
    MatButtonModule,
    NgForOf,
    NgClass,
    DatePipe,
    MatProgressSpinnerModule,
    NgIf,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    SymbolFlagModule,
    MatMenuModule,
    MatIconModule,
    EditableTitleComponent,
  ],
})
// T is type of "watchlistTableItems" element received in Input ("IWatchlistItem" is default type / fallback)
export class GroupedWatchlistComponent<T extends BaseWatchlistItemModel = IWatchlistItem>
  implements OnInit, AfterViewInit, OnDestroy
{
  protected watchlistItems: T[] = [];
  protected groups: Array<GroupedWatchlistGroupModel<T>> = [];
  protected rows: Array<GroupedWatchlistRowSubHeaderModel<T> | GroupedWatchlistRowItemModel<T>> =
    this.transformGroupsIntoRows<T>(this.groups);
  protected selectedRow: GroupedWatchlistRowItemModel<T> | null = null;

  protected settingsLoadedAndApplied = false;
  protected contextMenuPosition = { x: '0px', y: '0px' };
  protected editSubHeaderId: string | number | null = null;
  protected maxSubHeaderTitleLength = 50;
  protected currentWatchlistType: WatchlistType | null = null;

  protected navigateActiveTimeout = null;
  protected navigateActive = false;
  protected isSmileyMenuOpened = false;

  protected isTouchscreen = this.detectTouchDevice();
  protected touchscreenDragStartDelayMs = 800;
  protected highlightRowReadyToDragTimeout = null;
  protected rowReadyToDrag: string | number = null;

  protected exchangeCountries = ExchangeCountries;
  protected positionClasses = {
    ['SHORT']: 'light-red',
    ['LONG']: 'light-green',
    ['NONE']: 'light-gray',
  };

  private groupsState: GroupsStateModel = { sortState: { ...DEFAULT_GROUPED_WATCHLIST_SORT }, groups: [] };
  private allowedCollapsedSubHeaderDragEndPositions: number[] = [];

  private saveState$ = new Subject<void>();
  private subscriptions = new Subscription();
  private componentSettingsSaver: ComponentSettingsSaver<GroupsStateModel> = null;

  // for sort-header, table body is below in groups as separate tables
  @ViewChild(MatSort) sort: MatSort;
  protected dataSource = new MatTableDataSource<T>([]);
  protected sortState = { ...DEFAULT_GROUPED_WATCHLIST_SORT };
  protected readonly sortHeaderTitles = {
    left_trade_position: 'Pos. #1',
    right_trade_position: 'Pos. #2',
    symbol: 'Symbol',
    country_code: 'Exchange',
    company_name: 'Company',
    created_date: 'Added',
    company: 'Company',
    flag: 'Flag',
  };

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  @Input() isActive = false;
  @Input() smileyListType: SmileyListType;

  @Input() columns = ['flag', 'symbol', 'company'];
  // optional, key is column (from columns), value is hint (string)
  @Input() columnHints: Record<string, string> = {};

  // TODO: refactor: fetch watchlist inside this component
  // - subscribe to watchlist update events
  // - get smiley as input ??

  // TODO: use input.required() for type ??
  @Input() set watchlistType(value: WatchlistType) {
    this.currentWatchlistType = value;

    // init saver when watchlistType is received
    if (value && !this.componentSettingsSaver) {
      this.componentSettingsSaver = new ComponentSettingsSaver<GroupsStateModel>(
        DEFAULT_COMPONENT_STATE,
        this.getSaveSettingsKey(this.currentWatchlistType),
        this.userDataService,
      );
    }
  }

  @Input() set currentSymbol(value: number) {
    if (!this.isActive) {
      this.selectedRow = null;
    }

    if (!this.navigateActive && this.selectedRow && this.selectedRow.data.security_id !== value) {
      this.selectedRow = null;
    }
  }

  @Input() set watchlistTableItems(value: T[] | null) {
    if (!value) {
      return;
    }

    this.watchlistItems = value;

    // do not update watchlist items when smiley-menu is opened
    if (this.isSmileyMenuOpened) {
      return;
    }

    this.updateStateAfterChangeWatchlistItems();
  }

  @Output() symbolSelected = new EventEmitter<T>();
  @Output() symbolAdded = new EventEmitter<number>();
  @Output() symbolRemoved = new EventEmitter<T>();
  @Output() smileyUpdated = new EventEmitter<{ flag: Flags; data: T }>();

  constructor(
    private dialog: MatDialog,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogsService: DialogsService,
    private searchPopupService: SearchPopupService,
    private watchlistService: WatchlistService,
    private userDataService: UserDataService,
  ) {}

  ngOnInit(): void {
    if (this.componentSettingsSaver) {
      this.componentSettingsSaver.savedSettings$.pipe(take(1)).subscribe((settings) => {
        this.groupsState = { ...settings };
        this.sortState = { ...this.groupsState.sortState };

        this.groups = this.transformWatchlistItemsIntoGroups(this.watchlistItems, this.groupsState);
        this.onSortChange(this.sortState, false);
        this.rows = this.transformGroupsIntoRows(this.groups);

        this.settingsLoadedAndApplied = true;
        this.changeDetectorRef.detectChanges();
      });
    }

    this.subscriptions.add(
      fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
        const { code } = event;
        const dialogOpen = this.dialog.openDialogs.length;
        const isOverlayOpened = isOverlayOpen();

        if (this.isActive && dialogOpen === 0 && !isOverlayOpened && ['ArrowUp', 'ArrowDown'].includes(code)) {
          event.preventDefault();

          this.navigateActive = true;
          if (this.navigateActiveTimeout) {
            clearTimeout(this.navigateActiveTimeout);
          }
          this.navigateActiveTimeout = setTimeout(() => {
            this.navigateActive = false;
            this.navigateActiveTimeout = null;
          }, 3200);

          if (!this.selectedRow) {
            const firstEl = this.rows.find(
              (item) => item.isSubHeader === false && item.visible,
            ) as GroupedWatchlistRowItemModel<T>;
            this.selectSymbol(firstEl);

            return;
          }

          if (code === 'ArrowUp' && this.selectedRow.prev) {
            this.selectSymbol(this.selectedRow.prev);
          } else if (code === 'ArrowDown' && this.selectedRow.next) {
            this.selectSymbol(this.selectedRow.next);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.saveState$.subscribe(() => {
        this.updateComponentState();
        this.saveComponentSettings();
      }),
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.componentSettingsSaver) {
      this.componentSettingsSaver.destroy();
    }

    this.subscriptions.unsubscribe();
  }

  public removeAllGroups(): void {
    // remove all groups except hidden, all symbols will be added into hidden group
    this.groupsState = {
      ...this.groupsState,
      groups: this.groupsState.groups.filter((group) => group.id === HIDDEN_GROUP.id),
    };
    this.groups = [...this.groups].filter((group) => group.id === HIDDEN_GROUP.id);

    // move all symbols into the first (hidden) section
    this.updateStateAfterChangeWatchlistItems();
  }

  public addNewGroup(groupName: string = DEFAULT_NEW_GROUP_NAME): void {
    if (this.groups.length >= MAX_GROUPS_NUMBER) {
      this.showMaxAllowedGroupsInfoMessage();
      return;
    }

    const newGroup = { id: uuidV4(), isExpanded: true, isHeaderVisible: true, name: groupName, items: [] };

    this.groups = [...this.groups, newGroup];
    this.rows = this.transformGroupsIntoRows(this.groups);

    setTimeout(() => {
      this.scrollToRow(newGroup.id);
    }, 200);

    this.saveState$.next();
    this.changeDetectorRef.markForCheck();
  }

  public resetSymbolSelection(): void {
    this.selectedRow = null;
  }

  protected selectSymbol(item: GroupedWatchlistRowItemModel<T>): void {
    this.selectedRow = item;
    this.symbolSelected.emit(item.data);

    this.scrollToRow(item.id);
  }

  protected onSelectSmiley(flag: Flags, row: GroupedWatchlistRowItemModel<T>): void {
    this.smileyUpdated.emit({ flag, data: row.data });
    this.changeDetectorRef.markForCheck();
  }

  protected onSmileyMenuClosed(flag: Flags, row: GroupedWatchlistRowItemModel<T>): void {
    this.watchlistItems = [...this.watchlistItems].map((item) => {
      if (item.id === row.data.id) {
        return { ...item, flag };
      }

      return item;
    });

    this.updateStateAfterChangeWatchlistItems();
  }

  protected openSubHeaderContextMenu(
    event: MouseEvent,
    row: GroupedWatchlistRowSubHeaderModel<T>,
    type: 'contextmenu' | 'click',
  ): void {
    if ((this.isTouchscreen && type === 'contextmenu') || (!this.isTouchscreen && type === 'click')) {
      return;
    }

    if (row.id === this.editSubHeaderId) {
      return;
    }

    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: row };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  protected resetSort(triggerSaveSettings: boolean = true): void {
    this.onSortChange({ ...this.sortState, column: '' }, triggerSaveSettings);
  }

  protected onSortChange(
    sortState: { column: string; direction: SortDirection },
    triggerSaveSettings: boolean = true,
  ): void {
    this.sortState = { column: sortState.column, direction: sortState.direction };

    if (sortState.column !== '') {
      this.groups = this.groups.map((group) => {
        if (sortState.column === 'company_name' || sortState.column === 'company') {
          return {
            ...group,
            items: _.orderBy(
              group.items,
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              [(item) => item?.data[sortState.column]?.toLowerCase() ?? ''],
              [sortState.direction],
            ),
          };
        }

        if (sortState.column === 'country_code') {
          const countryCodesAscSortOrder = {
            [ExchangeCountriesCodes.CC]: 0,
            [ExchangeCountriesCodes.CA]: 1,
            [ExchangeCountriesCodes.US]: 2,
          };

          return {
            ...group,
            items: _.orderBy(
              group.items,
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              [(item) => countryCodesAscSortOrder[item.data[sortState.column]]],
              [sortState.direction],
            ),
          };
        }

        if (sortState.column === 'flag') {
          const flagAscSortOrder = {
            [Flags.Never]: 0,
            [Flags.No]: 1,
            [Flags.Maybe]: 2,
            [Flags.Yes]: 3,
            [Flags.None]: 4,
          };

          return {
            ...group,
            items: _.orderBy(
              group.items,
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              [(item) => flagAscSortOrder[item.data[sortState.column]]],
              [sortState.direction],
            ),
          };
        }

        return {
          ...group,
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          items: _.orderBy(group.items, [(item) => item.data[sortState.column]], [sortState.direction]),
        };
      });

      this.rows = this.transformGroupsIntoRows(this.groups);
    }

    if (triggerSaveSettings) {
      this.saveState$.next();
    }
  }

  protected addSymbolIntoGroup(group: GroupedWatchlistRowSubHeaderModel<T>): void {
    // can be received as input
    const symbolSources = {
      [WatchlistType.Wtf]: [ExchangeCountriesCodes.US],
      [WatchlistType.Wheel]: [ExchangeCountriesCodes.US],
      [WatchlistType.StockScreener]: [ExchangeCountriesCodes.US],
      [WatchlistType.ShortSellingStocks]: [ExchangeCountriesCodes.US],
      [WatchlistType.ShortingStocksScanner]: [ExchangeCountriesCodes.US],
      [WatchlistType.DividendsStrategy]: [ExchangeCountriesCodes.US],
      [WatchlistType.PowerX]: [ExchangeCountriesCodes.US, ExchangeCountriesCodes.CA, ExchangeCountriesCodes.CC],
    };

    const allSymbolSources = [ExchangeCountriesCodes.US, ExchangeCountriesCodes.CA, ExchangeCountriesCodes.CC];

    this.searchPopupService.openPopup(
      '',
      false,
      true,
      false,
      symbolSources[this.currentWatchlistType] ?? allSymbolSources,
      async (selectedSymbol: ISymbol) => {
        if (selectedSymbol && group.isSubHeader === true) {
          if (this.watchlistItems.some((item) => item.security_id === selectedSymbol.security_id)) {
            return;
          }

          this.watchlistService.insert(selectedSymbol.security_id, this.currentWatchlistType).subscribe((result) => {
            // when new symbol is added (and this component receive updated list), it will be placed into this group in predefined position
            if (result.result.insertId) {
              this.groups = this.groups.map((groupItem) => {
                if (group.id === groupItem.id) {
                  return {
                    ...groupItem,
                    items: [
                      ...groupItem.items,
                      {
                        id: result.result.insertId,
                        data: {
                          id: result.result.insertId,
                          security_id: selectedSymbol.security_id,
                          symbol: selectedSymbol.symbol,
                          company: selectedSymbol.description,
                          flag: Flags.None,
                        } as unknown as T,
                      },
                    ],
                  };
                }

                return groupItem;
              });

              this.groupsState = {
                ...this.groupsState,
                groups: this.groupsState.groups.map((groupItem) => {
                  return groupItem.id === group.id
                    ? { ...groupItem, items: [...groupItem.items, { id: result.result.insertId }] }
                    : groupItem;
                }),
              };
            }

            this.resetSort(true);
            this.symbolAdded.emit(selectedSymbol.security_id);
          });
        }
      },
      false,
    );
  }

  protected onRemoveGroup(group: GroupedWatchlistRowSubHeaderModel<T>): void {
    const groupToRemove = this.groups.find((item) => item.id === group.id);

    if (!group.isExpanded && groupToRemove && groupToRemove.items.length > 0) {
      groupToRemove.items.forEach((item) => {
        this.watchlistService.remove(item.id, this.currentWatchlistType).subscribe(() => {
          this.symbolRemoved.emit(item.data);
        });
      });

      const rowsIdToRemove: Array<string | number> = groupToRemove.items.map((item) => item.id);
      this.rows = [...this.rows].filter((row) => !rowsIdToRemove.includes(row.id));
    }

    this.rows = [...this.rows].filter((row) => row.id !== group.id);
    this.groups = this.transformRowsIntoGroups<T>(this.rows);

    this.validateAndFixGroupsState();

    if (this.watchlistItems.length > 0) {
      this.resetSort();
    }

    this.saveState$.next();
    this.changeDetectorRef.markForCheck();
  }

  protected onRemoveItem(rowToRemove: GroupedWatchlistRowItemModel<T>): void {
    this.groups = this.groups.map((group) => {
      return {
        ...group,
        items: group.items.filter((item) => item.id !== rowToRemove.id),
      };
    });

    this.validateAndFixGroupsState();
    this.rows = this.transformGroupsIntoRows(this.groups);

    if (this.selectedRow && this.selectedRow.id === rowToRemove.id) {
      this.selectedRow = null;
    }

    this.watchlistService.remove(rowToRemove.id, this.currentWatchlistType).subscribe(() => {
      this.symbolRemoved.emit(rowToRemove.data);
    });

    this.saveState$.next();
    this.changeDetectorRef.markForCheck();
  }

  protected setEditModeForSubHeader(group: GroupedWatchlistRowSubHeaderModel<T>): void {
    this.editSubHeaderId = group.id;
  }

  protected onRenameGroup(group: GroupedWatchlistRowSubHeaderModel<T>, newName: string): void {
    group.name = newName;

    this.changeDetectorRef.markForCheck();
  }

  protected onRenameGroupFinished(row: GroupedWatchlistRowSubHeaderModel<T>, newName: string): void {
    if (this.editSubHeaderId === row.id) {
      this.editSubHeaderId = null;
    }

    row.name = newName;
    this.groups = this.groups.map((group) => {
      if (group.id === row.id) {
        return { ...group, name: newName };
      }

      return group;
    });

    this.saveState$.next();
    this.changeDetectorRef.markForCheck();
  }

  protected onTouchStart(
    event: TouchEvent,
    row: GroupedWatchlistRowItemModel<T> | GroupedWatchlistRowSubHeaderModel<T>,
  ): void {
    if (!this.isTouchscreen) {
      return;
    }

    if (this.highlightRowReadyToDragTimeout) {
      this.cancelHighlightRowReadyToDragTimeout();
    }

    this.highlightRowReadyToDragTimeout = setTimeout(() => {
      this.rowReadyToDrag = row.id;
      this.changeDetectorRef.detectChanges();
    }, this.touchscreenDragStartDelayMs);
  }

  protected cancelHighlightRowReadyToDragTimeout(): void {
    clearTimeout(this.highlightRowReadyToDragTimeout);
    this.highlightRowReadyToDragTimeout = null;
    this.rowReadyToDrag = null;
    this.changeDetectorRef.detectChanges();
  }

  protected onDragStarted(
    dragStart: CdkDragStart<GroupedWatchlistRowSubHeaderModel<T> | GroupedWatchlistRowItemModel<T>>,
  ): void {
    // reminder: there is always hidden subheader at this.rows[0]
    const dragStartElement = dragStart.source.data;
    const currentPosition = this.rows.findIndex((item) => item.id === dragStartElement.id) + 1;

    this.allowedCollapsedSubHeaderDragEndPositions = [1];

    this.rows.forEach((row, index) => {
      if (row.isSubHeader && index !== 0) {
        this.allowedCollapsedSubHeaderDragEndPositions.push(index <= currentPosition ? index : index - 1);
      }

      // if this group is collapsed and not draggable - then draggable sub-header can be placed after it
      if (row.isSubHeader && !row.isExpanded && index !== 0) {
        this.allowedCollapsedSubHeaderDragEndPositions.push(index <= currentPosition ? index + 1 : index);
      }
    });

    // it can be placed after the last element also
    this.allowedCollapsedSubHeaderDragEndPositions.push(this.rows.length - 1);
  }

  protected dropListSortPredicate(
    index: number,
    drag: CdkDrag<GroupedWatchlistRowSubHeaderModel<T> | GroupedWatchlistRowItemModel<T>>,
  ): boolean {
    const elementData = drag.data;

    if (elementData.isSubHeader && !elementData.isExpanded) {
      return this.allowedCollapsedSubHeaderDragEndPositions.includes(index);
    }

    return true;
  }

  protected onDrop(
    event: CdkDragDrop<Array<GroupedWatchlistRowSubHeaderModel<T> | GroupedWatchlistRowItemModel<T>>>,
  ): void {
    // important: hidden elements are still presented in the rows
    // if element is dropped after collapsed header -> move it under its (hidden) items

    const element = this.rows[event.previousIndex];

    if (event.previousIndex === event.currentIndex) {
      return;
    }

    if (element.isSubHeader && element.isExpanded) {
      const group = this.groups.find((item) => item.id === element.id);

      const newPosPrevElement =
        event.previousIndex < event.currentIndex ? this.rows[event.currentIndex] : this.rows[event.currentIndex - 1];

      let newPosition = event.currentIndex;

      if (newPosPrevElement.isSubHeader && !newPosPrevElement.isExpanded && newPosPrevElement.id !== HIDDEN_GROUP.id) {
        const newPosPrevGroup = this.groups.find((item) => item.id === newPosPrevElement.id);
        newPosition = newPosition + newPosPrevGroup?.items.length;
      }

      if (event.previousIndex === newPosition) {
        return;
      }

      moveItemInArray(this.rows, event.previousIndex, newPosition);
      this.groups = this.transformRowsIntoGroups<T>(this.rows);

      this.validateAndFixGroupsState();

      // decide reset sort or not, add more cases - if necessary
      let resetSort = true;

      // if expanded header is moved at the top of the list
      if (newPosPrevElement.id === HIDDEN_GROUP.id) {
        resetSort = false;
      }

      // if expanded header of an empty group is moved at the bottom of the list
      if (group.items.length === 0 && newPosition === this.rows.length - 1) {
        resetSort = false;
      }

      if (resetSort) {
        this.resetSort();
      }

      this.saveState$.next();
      this.changeDetectorRef.markForCheck();

      return;
    }

    // special case - collapsed sub-header should be moved with its items
    if (element.isSubHeader && !element.isExpanded) {
      const group = this.groups.find((item) => item.id === element.id);
      const groupItemsIDs: Array<string | number> = group.items.map((item) => item.id);
      const newPosPrevElement =
        event.previousIndex < event.currentIndex ? this.rows[event.currentIndex] : this.rows[event.currentIndex - 1];

      let newPosition = event.currentIndex;

      if (newPosPrevElement.isSubHeader && newPosPrevElement.id !== HIDDEN_GROUP.id) {
        const newPosPrevGroup = this.groups.find((item) => item.id === newPosPrevElement.id);
        newPosition = newPosition + newPosPrevGroup?.items.length;
      }

      moveItemInArray(this.rows, event.previousIndex, newPosition);
      const itemsToMove: Array<GroupedWatchlistRowItemModel<T>> = group.items.map((item) => ({
        id: item.id,
        isSubHeader: false,
        data: item.data,
        visible: group.isExpanded,
        prev: null,
        next: null,
      }));

      const updatedRows = [...this.rows].filter((item) => !groupItemsIDs.includes(item.id));
      const newIndex = updatedRows.findIndex((item) => item.id === group.id);
      updatedRows.splice(newIndex + 1, 0, ...itemsToMove);

      this.groups = this.transformRowsIntoGroups<T>(updatedRows);
      this.validateAndFixGroupsState();

      if (newPosPrevElement.id === HIDDEN_GROUP.id && this.groups[0].items.length > 0) {
        this.resetSort();
      }

      // rows should be always generated using transformGroupsIntoRows to keep valid prev/next navigation
      this.rows = this.transformGroupsIntoRows(this.groups);

      this.saveState$.next();
      this.changeDetectorRef.markForCheck();

      return;
    }

    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    this.groups = this.transformRowsIntoGroups<T>(this.rows);

    this.validateAndFixGroupsState();
    this.resetSort();

    this.saveState$.next();
    this.changeDetectorRef.markForCheck();
  }

  protected onChangeIsSectionExpanded(id: string | number): void {
    this.groups = this.groups.map((item) => {
      if (item.id === id) {
        return { ...item, isExpanded: !item.isExpanded };
      }

      return item;
    });

    this.rows = this.transformGroupsIntoRows<T>(this.groups);

    if (this.selectedRow) {
      const lastSelectedRow = this.rows.find((row) => {
        return row.isSubHeader === false && row.id === this.selectedRow.id;
      });

      if (!lastSelectedRow || lastSelectedRow.isSubHeader === true || !lastSelectedRow.visible) {
        this.selectedRow = null;
      }
    }

    this.saveState$.next();
    this.changeDetectorRef.markForCheck();
  }

  protected trackByFn(index: number, item: { id: number | string }): number | string {
    return item.id ?? index;
  }

  private updateStateAfterChangeWatchlistItems(): void {
    if (this.selectedRow && !this.watchlistItems.find((item) => item.id === this.selectedRow.id)) {
      this.selectedRow = null;
    }

    this.groups = this.transformWatchlistItemsIntoGroups<T>(this.watchlistItems, this.groupsState);
    this.rows = this.transformGroupsIntoRows<T>(this.groups);

    // apply current sort for updated items
    this.onSortChange(this.sortState, false);

    // save component state, if new elements were added
    this.saveState$.next();

    this.changeDetectorRef.detectChanges();
  }

  private transformGroupsIntoRows<R extends BaseWatchlistItemModel>(
    groups: Array<GroupedWatchlistGroupModel<R>>,
  ): Array<GroupedWatchlistRowSubHeaderModel<R> | GroupedWatchlistRowItemModel<R>> {
    const rows: Array<GroupedWatchlistRowSubHeaderModel<R> | GroupedWatchlistRowItemModel<R>> = [];
    let prevVisibleItem: GroupedWatchlistRowItemModel<R> | null = null;

    groups.forEach((group) => {
      rows.push({
        id: group.id,
        isSubHeader: true,
        isExpanded: group.isExpanded,
        name: group.name,
        isHeaderVisible: group.isHeaderVisible,
      });

      const groupItems: Array<GroupedWatchlistRowItemModel<R>> = group.items.map((item) => {
        const row: GroupedWatchlistRowItemModel<R> = {
          id: item.id,
          isSubHeader: false,
          data: item.data,
          visible: group.isExpanded,
          prev: prevVisibleItem,
          next: null,
        };

        // use navigation only for visible items
        if (row.visible) {
          if (prevVisibleItem) {
            prevVisibleItem.next = row;
          }

          prevVisibleItem = row;
        }

        return row;
      });

      rows.push(...groupItems);
    });

    if (this.selectedRow) {
      // update link to row (with relevant prev/next items)
      // @ts-expect-error, TS issue, "find" doesn't narrow type
      this.selectedRow = rows.find((row) => !row.isSubHeader && row.id === this.selectedRow.id) ?? null;
    }

    return rows;
  }

  private transformRowsIntoGroups<R extends BaseWatchlistItemModel>(
    rows: Array<GroupedWatchlistRowSubHeaderModel<R> | GroupedWatchlistRowItemModel<R>>,
  ): Array<GroupedWatchlistGroupModel<R>> {
    const groups: Array<GroupedWatchlistGroupModel<R>> = [];

    rows.forEach((row) => {
      if (row.isSubHeader) {
        groups.push({
          id: row.id,
          isExpanded: row.isExpanded,
          isHeaderVisible: row.isHeaderVisible,
          name: row.name,
          items: [],
        });
      }

      if (row.isSubHeader === false) {
        if (groups.length === 0) {
          groups.push({
            id: HIDDEN_GROUP.id,
            isExpanded: HIDDEN_GROUP.isExpanded,
            isHeaderVisible: HIDDEN_GROUP.isHeaderVisible,
            name: HIDDEN_GROUP.name,
            items: [],
          });
        }

        groups[groups.length - 1].items.push({
          id: row.id,
          data: row.data,
        });
      }
    });

    return groups;
  }

  private validateAndFixGroupsState(): void {
    // detect if there are visible elements after collapsed sub-header - expand that sub-header
    const groupsState: Record<string, { isExpanded: boolean; toExpand: boolean }> = {};
    let currentGroup = { id: this.groups[0].id, isExpanded: this.groups[0].isExpanded };
    let updateRowsState = false;

    this.rows.forEach((row) => {
      if (row.isSubHeader) {
        currentGroup = { id: row.id, isExpanded: row.isExpanded };
        groupsState[row.id] = { isExpanded: row.isExpanded, toExpand: false };

        return;
      }

      if (!currentGroup.isExpanded && row.isSubHeader === false && row.visible) {
        groupsState[currentGroup.id] = { ...groupsState[currentGroup.id], toExpand: true };
        updateRowsState = true;
      }

      if (currentGroup.isExpanded && row.isSubHeader === false && !row.visible) {
        updateRowsState = true;
      }
    });

    if (updateRowsState) {
      this.groups = this.groups.map((group) => {
        if (groupsState[group.id].toExpand) {
          return { ...group, isExpanded: true };
        }

        return group;
      });

      this.rows = this.transformGroupsIntoRows(this.groups);
    }
  }

  // take saved groups state as a parameter - to restore full state (groups + items)
  private transformWatchlistItemsIntoGroups<R extends BaseWatchlistItemModel>(
    watchlistItems: R[],
    groupsState: GroupsStateModel,
  ): Array<GroupedWatchlistGroupModel<R>> {
    // @ts-expect-error, TS issue
    const watchlistTableItemsRecord: Record<string, R> = convertArrayToRecord(watchlistItems, 'id');
    const usedItems = [];

    // use symbol - if no id
    if (groupsState.groups.length === 0) {
      return [
        {
          ...HIDDEN_GROUP,
          items: watchlistItems.map((item) => ({ id: item.id, data: item })),
        },
      ];
    }

    const newState = groupsState.groups.map((group) => {
      const newItems = [];
      group.items.forEach((item) => {
        if (watchlistTableItemsRecord[item.id]) {
          newItems.push({ ...item, data: watchlistTableItemsRecord[item.id] });
          usedItems.push(item.id);
        }
      });

      return { ...group, items: newItems };
    });

    // add not-used items into hidden groups (can be replaced with the last one)
    const notUsedItems = [...watchlistItems].filter((item) => !usedItems.includes(item.id));
    newState[newState.length - 1].items.push(...notUsedItems.map((item) => ({ id: item.id, data: item })));

    return newState;
  }

  private updateComponentState(): void {
    // keep locally (for groups re-rendering)
    this.groupsState = {
      sortState: { ...this.sortState },
      groups: this.groups.map((group) => {
        return {
          id: group.id,
          isExpanded: group.isExpanded,
          isHeaderVisible: group.isHeaderVisible,
          name: group.name,
          items: group.items.map(({ id }) => ({ id })),
        };
      }),
    };
  }

  private saveComponentSettings(): void {
    const settings = { ...this.groupsState };

    if (this.componentSettingsSaver && this.settingsLoadedAndApplied) {
      this.componentSettingsSaver.saveSettings(settings);
    }
  }

  private showMaxAllowedGroupsInfoMessage(maxGroupsNumber = MAX_GROUPS_NUMBER - 1): void {
    from(
      this.dialogsService.customConfirm({
        showCancel: false,
        header: 'Add a new section',
        confirmationText: `Maximum allowed sections is ${maxGroupsNumber}.`,
        subText: '',
        icon: 'info',
      }),
    ).subscribe();
  }

  private scrollToRow(id: string | number): void {
    const rowElementIdPrefix = 'grouped-watchlist-row_';

    if (document.getElementById(rowElementIdPrefix + id)) {
      this.renderer
        .selectRootElement('#' + rowElementIdPrefix + id, true)
        .scrollIntoView({ behavior: 'smooth', block: 'nearest' });

      this.changeDetectorRef.markForCheck();
    }
  }

  private getSaveSettingsKey(prefix: string): string {
    return `${prefix}_grouped_watchlist-settings`;
  }

  private detectTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
}
