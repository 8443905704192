<div class="trade-length card">
  <div *ngIf="getPropertyValue('avg_trade_days') !== null && getPropertyValue('longest_trade_days') !== null else noData"
      class="date-progress full-height-progress"
      [style.width]="longestTradeProgressBarWidth">
      <div class="date-value">
          <p class="font-12">
              {{getPastPerformanceMetric('avg_trade_days', 0)}}d
          </p>
      </div>
      <div class="pogress-value" [style.width]="averageTradeProgressBarWidth">
      </div>
      <div class="date-value-end">
          <p class="font-12">
              {{getPastPerformanceMetric('longest_trade_days', 0)}}d
          </p>
      </div>
  </div>
</div>

<ng-template #noData>
  <div class="no-data">
    -
  </div>
</ng-template>
