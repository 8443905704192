import { BaseWatchlistItemModel, GroupedWatchlistGroupModel, GroupsStateModel, ISortState } from './grouped-watchlist.model';

export const MAX_GROUPS_NUMBER = 101; // max = 100 + 1 hidden group

export const DEFAULT_NEW_GROUP_NAME = 'New Section';

export const DEFAULT_GROUPED_WATCHLIST_SORT: ISortState = {
  column: 'symbol',
  direction: 'asc',
};

export const HIDDEN_GROUP: GroupedWatchlistGroupModel<BaseWatchlistItemModel> = {
  id: '0',
  isExpanded: true,
  isHeaderVisible: false,
  name: 'Hidden sub-header',
  items: [],
};

export const DEFAULT_COMPONENT_STATE: GroupsStateModel = {
  sortState: { ...DEFAULT_GROUPED_WATCHLIST_SORT },
  groups: [],
};
