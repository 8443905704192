import { HttpBackend, HttpClient, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VideoHubGetRequestOptions } from '@mod/video-hub/video-hub.model';
import { Observable } from 'rxjs';
import { VideoHubInterceptor } from './video-hub-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class VideoHubHttpClient {
  private http: HttpClient;

  constructor(
    private handler: HttpBackend,
    private videoHubInterceptor: VideoHubInterceptor,
  ) {
    const interceptorHandler = {
      handle: (req: HttpRequest<unknown>) => {
        return this.videoHubInterceptor.intercept(req, handler);
      },
    } as HttpHandler;

    this.http = new HttpClient(interceptorHandler);
  }

  public get<T>(url: string, options?: VideoHubGetRequestOptions): Observable<T> {
    return this.http.get<T>(url, { ...options });
  }

  public getResponse<T>(url: string, options?: VideoHubGetRequestOptions): Observable<HttpResponse<T>> {
    return this.http.get<T>(url, { observe: 'response', ...options });
  }
}
