import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { EditionsService } from '@s/editions.service';
import { Features } from '@const';

/**
 * Hides or shows element or component depending on feature availability for user.
 * If `else` (ref to ng-template) provided it show it instead of content if feature is unavailable for user.
 *
 * ### Simple example:
 * ```
 *   <some-element *appHideForUnavailableFeature="features.ExpectedMove">...</some-element>
 * ```
 *
 * ### Example with `else` template:
 * ```
 *   <some-element
 *     *appHideForUnavailableFeature="features.ExpectedMove; else: alternativeContent"
 *   >
 *     ...
 *   </some-element>
 *   <ng-template #alternativeContent> ... </ng-template>
 * ```
 *
 * @param appHideForUnavailableFeature Feature's title (from enum).
 * Can include optional `else` - Ref to ng-template to show it instead of content if feature is unavailable for user (see usage example).
 *
 * @ngModule EditionsSharedModule
 */
@Directive({
  selector: '[appHideForUnavailableFeature]'
})
export class HideForUnavailableFeatureDirective implements OnInit, OnChanges {
  @Input() appHideForUnavailableFeature: Features = null;
  @Input() appHideForUnavailableFeatureElse: TemplateRef<HTMLElement> = null;

  constructor(
    private editionsService: EditionsService,
    private templateRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.updateState();
  }

  ngOnInit() {
    this.updateState();
  }

  private updateState(): void {
    const isAvailable = this.editionsService.isFeatureAvailable(this.appHideForUnavailableFeature);

    this.viewContainer.clear();

    if (isAvailable) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.appHideForUnavailableFeatureElse) {
      this.viewContainer.createEmbeddedView(this.appHideForUnavailableFeatureElse);
    }
  }
}
