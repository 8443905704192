<div class="search-section" [ngClass]="{'wheel-search-section': !isPowerX}">
  <div class="search-section-content" [ngClass]="{'wheel-search-section-content': !isPowerX}">
    <div class="search-box-with-add">
      <div class="fill-btn search-bar-btn" [matTooltip]="'Symbol Search'" [matTooltipHideDelay]="0"
        [matTooltipPosition]="'below'" (click)="openSymbolSearch()">
        {{selectedSymbol?.symbol}}
      </div>
      <div *ngIf="selectedSymbol && isPowerX" class="add-to-watchlist-btn" [matTooltip]="'Add to Watchlist'"
        [matTooltipPosition]="'below'" (click)="addToWatchlist()">
        <img [src]="addSvg" alt="">
      </div>
    </div>

    <div *ngIf="!isPowerX" class="company-name">
      <div class="name-time">
        <p class="s2 boldText">
          {{selectedSymbol?.description}}
        </p>
      </div>
    </div>

    <div *ngIf="isPowerX" class="company-name powerx-section overflow-ellipsis">
      <div class="name-time">
        <span class="name-container overflow-ellipsis">
          <p [class.dot-bottom-border]="!!(selectedSymbol && selectedSymbolDetails?.long_description)"
            (mouseover)="securityDetailsTooltip(true)" (mouseout)="securityDetailsTooltip(false)"
            class="s2 boldText overflow-ellipsis">
            {{selectedSymbol?.description}}
          </p>
          <div [class]="selectedSymbol?.country_code + '-icon country_code'"
            [matTooltip]="ExchangeCountries[selectedSymbol?.country_code] + ' Exchange'">
          </div>
        </span>

        <span class="cap" *ngIf="selectedSymbol?.symbol">
          <span *ngIf="isPowerX">Updated: {{updateTime}}</span>
          <div class="control-container">
            <div *ngIf="nextEarnings" [matTooltip]="nextEarningsDate" [matTooltipHideDelay]="100"
              [matTooltipPosition]="'below'" [class.red]="nextEarningsInDays >= 0 && nextEarningsInDays <= 7"
              class="updateNumber">
              {{nextEarningsInDays}}
            </div>
            <button mat-icon-button color="primary"
              *ngIf="selectedSymbol?.country_code == 'US' || selectedSymbol?.country_code == 'CA'" class="icon-button"
              (click)="setEarningsCalendarSymbol(selectedSymbol)" [matTooltip]="'Analyze earnings'"
              [matTooltipHideDelay]="0">
              <mat-icon [svgIcon]="'analyze-earning-icon'" class="analyze-earning-icon middle-icon"></mat-icon>
            </button>
            <button mat-icon-button color="primary" *ngIf="isShowNewsButton" class="icon-button" (click)="openNews()"
              [matTooltip]="'Check fundamentals'" [matTooltipPosition]="'right'" [matTooltipHideDelay]="0">
              <mat-icon [svgIcon]="'news-icon'" class="news-icon middle-icon"></mat-icon>
            </button>
          </div>
        </span>
      </div>
      <div class="sector-indu" *ngIf="selectedSymbolDetails">
        <span class="sector-value">{{selectedSymbolDetails.sector_name}}</span>
        <ng-container *ngIf="selectedSymbolDetails.industry_name">
          <span class="industry-value" style="padding: 0px 6px;">-</span>
          <span class="industry-value">{{selectedSymbolDetails.industry_name}}</span>
        </ng-container>
      </div>
    </div>

    <div *ngIf="isPowerX" class="long-position">
      <button [ngClass]="tradePosition === TradePositions.LongAndShort ? 'grey' : ''" mat-button
        [matMenuTriggerFor]="menu" class="long-position-button"
        [class]="tradePosition">{{getTradePositionName(tradePosition)}}</button>
      <mat-menu #menu="matMenu" class="position-dropdown">
        <ng-container
          *ngFor="let tPosition of [TradePositions.LongOnly, TradePositions.ShortOnly, TradePositions.LongAndShort]">
          <button [ngStyle]="{'background-color': (tradePosition === tPosition) ? 'var(--selected-item-bg)' : ''}"
            mat-menu-item (click)="updatePosition(tPosition)">
            {{getTradePositionName(tPosition)}}
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
  <ng-content></ng-content>

  <div *ngIf="showSecurityDetailsTooltip && selectedSymbolDetails && selectedSymbolDetails.long_description"
    class="company-info">
    {{selectedSymbolDetails.long_description}}
  </div>
</div>