import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, from, Subject, Subscriber } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { Moment } from 'moment';

import { WheelService } from '@m1/wheel/wheel.service';
import { HolidaysService } from '@s/holidays.service';
import { WorkingHoursService } from '@s/working-hours.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { Countries, EasternTimeZoneName, daysNumberToCheckInTimer, MomentDateTimeFormats } from '@const';
import { IWorkingHours } from '@mod/data/working-hours.model';
import { INonStandardWorkingDay, INonStandardWorkingHours, IWorkingHoursTime } from './wheel-timer.model';

const CHECK_TIME_INTERVAL_MS = 1000;
const EXTENDED_UPDATE_TIME_IN_MINUTES = 5;

@Component({
  selector: 'app-wheel-timer',
  templateUrl: './wheel-timer.component.html',
  styleUrls: ['./wheel-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WheelTimerComponent implements OnInit, OnDestroy {
  public nextDayDate: string | null = null;
  public nextDayTime: string | null = null;

  public showTimer: boolean;
  public doCountdown;

  public holidayList: string[] = [];
  public workingDays: string[] = [];
  public nonStandardWorkingDays: Record<string, INonStandardWorkingDay> = {};

  public defaultOpenCloseTime: INonStandardWorkingHours = {
    open: { hours: 9, minutes: 30, seconds: 0 },
    close: { hours: 16, minutes: 0, seconds: 0 },
  };

  public currentOpenTime: IWorkingHoursTime = this.defaultOpenCloseTime.open;
  public currentCloseTime: IWorkingHoursTime = this.defaultOpenCloseTime.close;

  public lastUpdatedTime: string | null = null;
  public lastUpdatedDate: string | null = null;
  public lastUpdatedDateTime: string | null = null;

  public timerFor2minutes = 120;
  public totalOffset = 301.635;
  public offsetForSecond = this.totalOffset / this.timerFor2minutes;
  public strokeOffset = 301.635;
  public innerStroke = '#a3d193';
  public innerStrokeGrey = '#B2B5BA';
  public outerStroke = '#5B9BD5';

  public showMaintenance$ = this.observableServiceV2.showMaintenance;
  public isWheelScannerLoading$ = this.observableServiceV2.isWheelScannerLoading;

  private twoMinutesTimer;
  private checkWorkingTimeInterval: NodeJS.Timeout;
  private nextDay$ = new BehaviorSubject<Moment>(moment());
  private updateHolidays$ = new Subject<void>();
  private subscriber = new Subscriber();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private observableServiceV2: ObservableServiceV2,
    private wheelService: WheelService,
    private holidaysService: HolidaysService,
    private workingHoursService: WorkingHoursService,
  ) {}

  ngOnInit() {
    // TODO: move timer (countdown mechanism) into service and use data/updates from it for scanner and timers
    // to fix properly issue - update signal from hidden timer component

    this.subscriber.add(
      this.nextDay$.subscribe(this.updateNextRun)
    );

    this.subscriber.add(
      this.wheelService.restartTimer$.subscribe(() => {
        this.restartTimer();
      })
    );

    from(this.workingHoursService.get())
      .pipe(
        map((rawNonStandardDays) => this.normalizeNonStandardDays(rawNonStandardDays)),
        tap((nonStandardDays) => {
          this.nonStandardWorkingDays = nonStandardDays;
          this.updateCurrentOpenCloseTime();
        }),
      )
      .subscribe();

    this.subscriber.add(
      this.wheelService.lastScannerUpdateDate$.subscribe((newLastUpdatedDate) => {
        // to avoid "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked." error
        setTimeout(() => {
          if (!newLastUpdatedDate) {
            this.lastUpdatedTime = null;
            this.lastUpdatedDate = null;
            this.lastUpdatedDateTime = null;

            return;
          }

          const lastUpdated = moment.utc(newLastUpdatedDate).tz(EasternTimeZoneName);

          this.lastUpdatedTime = lastUpdated.format('h:mm:ss A');
          this.lastUpdatedDate = lastUpdated.format('MMM D');
          this.lastUpdatedDateTime = lastUpdated.format('MMM D, h:mm A');
        }, 0);
      })
    );

    this.subscriber.add(
      this.updateHolidays$.subscribe(() => {
        const currentDateEST = this.getCurrentESTDate();

        this.holidaysService
          .get({
            from: currentDateEST.clone().subtract(1, 'day').format('YYYY-MM-DD'),
            to: currentDateEST.clone().add(daysNumberToCheckInTimer, 'day').format('YYYY-MM-DD'),
          })
          .toPromise().then((allHolidays) => {
            this.holidayList = allHolidays
              .filter((holiday) => holiday.country === Countries.USA)
              .map((re) => re.date.split('T')[0]);

            this.workingDays = this.getWorkingDays(currentDateEST, this.holidayList, daysNumberToCheckInTimer);
          });
      })
    );

    const date = this.getCurrentESTDate();
    const dateFrom = date.clone().subtract(1, 'day').format('YYYY-MM-DD');
    const dateTo = date.clone().add(daysNumberToCheckInTimer, 'day').format('YYYY-MM-DD');

    this.holidaysService.get({ from: dateFrom, to: dateTo }).toPromise().then((allHolidays) => {
      this.holidayList = allHolidays
        .filter((holiday) => holiday.country === Countries.USA)
        .map((re) => re.date.split('T')[0]);

      const currentDateEST = this.getCurrentESTDate();
      this.workingDays = this.getWorkingDays(currentDateEST, this.holidayList, daysNumberToCheckInTimer);

      this.checkWorkingTimeInterval = setInterval(() => {
        const currentDate = this.getCurrentESTDate();
        const currentESTTime = moment(this.getCurrentESTDate(), 'YYYY-MM-DD HH:mm:ss'); // convert it to moment

        // set start-end here
        const startEstTime = currentDate
          .clone()
          .set('h', this.currentOpenTime.hours)
          .set('m', this.currentOpenTime.minutes)
          .set('s', this.currentOpenTime.seconds);
        const endEstTime = currentDate
          .clone()
          .set('h', this.currentCloseTime.hours)
          .set('m', this.currentCloseTime.minutes)
          .set('s', this.currentCloseTime.seconds);
        const extendedEndTime = endEstTime
          .clone()
          .add(EXTENDED_UPDATE_TIME_IN_MINUTES, 'm');

        if (!this.showTimer && currentESTTime.isBefore(startEstTime)) {
          const nextWorkingDayOpenTime = this.nonStandardWorkingDays[this.workingDays[0]]?.workingHours.open
            ?? this.defaultOpenCloseTime.open;

          this.nextDay$.next(
            moment(this.workingDays[0])
              .clone()
              .set('h', nextWorkingDayOpenTime.hours)
              .set('m', nextWorkingDayOpenTime.minutes)
              .set('s', nextWorkingDayOpenTime.seconds)
          );
        }

        if (!this.showTimer && currentESTTime.isAfter(startEstTime)) {
          // skip current day and use next one
          const isFirstWorkingDayToday = moment(this.workingDays[0]).isSame(endEstTime, 'day');
          const nextDay = isFirstWorkingDayToday ? this.workingDays[1] : this.workingDays[0];

          const nextWorkingDayOpenTime = this.nonStandardWorkingDays[nextDay]?.workingHours.open
            ?? this.defaultOpenCloseTime.open;

          this.nextDay$.next(
            moment(nextDay)
              .set('h', nextWorkingDayOpenTime.hours)
              .set('m', nextWorkingDayOpenTime.minutes)
              .set('s', nextWorkingDayOpenTime.seconds)
          );
        }

        this.showTimer = this.workingDays.includes(currentDate.format('YYYY-MM-DD'))
          && (currentESTTime.isBetween(startEstTime, endEstTime));
        this.doCountdown = this.workingDays.includes(currentDate.format('YYYY-MM-DD'))
          && (currentESTTime.isBetween(startEstTime, extendedEndTime));

        this.wheelService.isTimerActive$.next(this.showTimer);

        const refreshStocksTime = currentDate.clone().set('h', 9).set('m', 26).set('s', 0);
        const updateHolidaysAndWorkingDaysTime = currentDate.clone().set('h', 0).set('m', 0).set('s', 0);

        if (refreshStocksTime.isSame(currentDate, 'seconds')) {
          this.updateStocks();
        }

        if (updateHolidaysAndWorkingDaysTime.isSame(currentDate, 'seconds')) {
          this.updateHolidays$.next();
          this.updateCurrentOpenCloseTime();
        }

        if (this.doCountdown) {
          if (this.timerFor2minutes <= 0) {
            this.updateStocks();
          }

          this.updateCountdown();
          return;
        }

        this.timerFor2minutes = 120;
        this.strokeOffset = 301.635;
      }, CHECK_TIME_INTERVAL_MS);
    });
  }

  ngOnDestroy() {
    clearInterval(this.twoMinutesTimer);
    clearInterval(this.checkWorkingTimeInterval);

    this.subscriber.unsubscribe();
  }

  public updateCurrentOpenCloseTime(): void {
    const currentDate = this.getCurrentESTDate().clone().format(MomentDateTimeFormats.ServerDate);
    const currentOpenCloseTime = this.nonStandardWorkingDays[currentDate]?.workingHours ?? this.defaultOpenCloseTime;

    this.currentOpenTime = currentOpenCloseTime.open;
    this.currentCloseTime = currentOpenCloseTime.close;
  }

  public updateNextRun = (date: Moment): void => {
    this.nextDayDate = date.format('ddd, MMM D');
    this.nextDayTime = date.format('h:mm a');
  }

  public updateCountdown() {
    if (this.timerFor2minutes <= 0) {
      this.timerFor2minutes = 120;
      this.strokeOffset = 301.635;

      return;
    }

    this.timerFor2minutes--;
    this.strokeOffset -= this.offsetForSecond;
  }

  public updateStocks(): void {
    this.wheelService.updateStockList$.next();
  }

  private getWorkingDays(
    currentDateEST: Moment,
    holidayList: string[],
    daysNumberToCheck: number,
  ): string[] {
    // create array of days from current day to [+ days-number-to-check] and filter by [not-weekend | not-holiday]
    const allDays = [{ momentDate: currentDateEST, formattedDate: currentDateEST.format('YYYY-MM-DD') }];

    for (let n = 1; n < daysNumberToCheck; n++) {
      const momentDate = currentDateEST.clone().add(n, 'day');
      allDays.push({ momentDate, formattedDate: momentDate.format('YYYY-MM-DD') });
    }

    const workingDays = allDays
      .filter(({ momentDate, formattedDate }) => {
        const dayOfWeek = momentDate.isoWeekday();
        const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;
        const isHoliday = holidayList.includes(formattedDate);

        return !isHoliday && !isWeekend;
      })
      .map(({ formattedDate }) => formattedDate);

    return workingDays;
  }

  private restartTimer(): void {
    this.timerFor2minutes = 120;
    this.strokeOffset = 301.635;
  }

  private normalizeNonStandardDays(
    rawNonStandardDays: IWorkingHours[],
  ): Record<string, INonStandardWorkingDay> {
    const normalizedDays: Record<string, INonStandardWorkingDay> = rawNonStandardDays.reduce((acc, item) => {
      const open = item.startTime.split(':'); // Example: '09:30:00' => ['09', '30', '00']
      const close = item.endTime.split(':');

      acc[item.date] = {
        ...item,
        workingHours: {
          open: {
            hours: Number(open[0]),
            minutes: Number(open[1]),
            seconds: Number(open[2]),
          },
          close: {
            hours: Number(close[0]),
            minutes: Number(close[1]),
            seconds: Number(close[2]),
          },
        }
      };

      return acc;
    }, {} as Record<string, INonStandardWorkingDay>);

    return normalizedDays;
  }

  private getCurrentESTDate(): moment.Moment {
    const da = new Date().toLocaleString('en-US', { timeZone: EasternTimeZoneName });
    return moment(new Date(da));
  }
}
