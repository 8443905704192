import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { from, Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import {
  Features,
  formatDecimal,
  formatNumber,
  MobileWidth,
  saveViewModeDebounceTime,
  TabNames,
  Themes,
  UserSettings,
} from '@const';
import { PositionSizingPopupComponent } from '@m/common/position-sizing-popup/position-sizing-popup.component';
import { WheelHeaderPopupComponent } from '@m1/common/wheel-header-popup/wheel-header-popup.component';
import { WheelCalculatorService } from '@m1/wheel/wheel-calculator/wheel-calculator.service';
import { PositionSizingModel } from '@mod/data/position-sizing.model';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { AuthService } from '@s/common/auth.service';
import { DialogsService } from '@s/common/dialogs.service';
import { NavigationService } from '@s/navigation.service';
import { ObservableService, ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { ThemeService } from '@s/theme.service';
import { UserDataService } from '@s/user-data.service';
import { WheelViewModes } from '@t/wheel/wheel.types';
import { getRiskAmountPerTrade as getRiskAmountPerTradeImported } from '@u/business-logic-utils';
import { ObservableService as ObservableServiceV1 } from 'src/app/core/directives/observable.service';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const version = require('../../../../../package.json').version;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)',
  },
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  public readonly tabNames = TabNames;
  public UserSettings = UserSettings;
  public features = Features;
  public formatNumber = formatNumber;
  public formatDecimal = formatDecimal;
  public activeTab: string;
  public accountSize: number;
  public accountRiskPercent: number;
  public showMaintenance = false;

  public menuValue = null;
  public helpValue = false;
  public isBanner = false;
  public user = { first_name: '', last_name: 'undefined', theme: 1, show_mm_trades: 0, access_type: 1 };
  public isTrialUser;

  public width: number = window.innerWidth;
  public isMobile = this.width <= MobileWidth;
  public isMobileOS;

  public isHelpMenuOpened = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public userSetting: any = {};
  public wheelViewMode$ = this.observableServiceV2.wheelViewMode;
  public readonly wheelViewModes = WheelViewModes;

  protected showVideoHub = this.observableService.showVideoHub.value;

  private wheelViewModeState$ = new Subject<WheelViewModes>();

  public isTradierIntegrationEnabled$ = this.observableService.mySettings.pipe(
    map(({ tradier_flag_ifs, tradier_flag_pxo }) => !!tradier_flag_ifs && !!tradier_flag_pxo),
  );

  get popupButton(): string {
    const theme = this.observableService.theme.value;
    return `assets/img/newImg/drop-${this.menuValue ? 'up' : 'down'}-blc${theme === Themes.Dark ? '-white' : ''}.svg`;
  }

  public version = version;

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private observableServiceV1: ObservableServiceV1,
    private observableServiceV2: ObservableServiceV2,
    private themeService: ThemeService,
    private navigationService: NavigationService,
    private dialogService: DialogsService,
    private authService: AuthService,
    private brokerAuthenticationService: BrokerAuthenticationService,
    private platform: Platform,
    private wheelCalculatorService: WheelCalculatorService,
  ) {}

  ngInitParent(): void {
    this.checkIsMobileOS();
    this.activeTab = this.observableService.activeTab.getValue();
    this.accountSize = this.observableService.accountSize.getValue();
    this.accountRiskPercent = this.observableService.accountRiskPercent.getValue();
    this.user = this.observableService.mySettings.getValue();
    this.showMaintenance = this.observableService.showMaintenance.getValue();
    this.isTrialUser = this.userDataService.getUser().isTrialUser || false;

    this.subscriptions.add(this.observableService.mySettings.subscribe((userSettings) => (this.user = userSettings)));
    this.subscriptions.add(this.observableService.activeTab.subscribe((activeTab) => (this.activeTab = activeTab)));
    this.subscriptions.add(
      this.observableService.accountSize.subscribe((accountSize) => (this.accountSize = accountSize)),
    );
    this.subscriptions.add(
      this.observableService.accountRiskPercent.subscribe(
        (accountRiskPercent) => (this.accountRiskPercent = accountRiskPercent),
      ),
    );
    this.subscriptions.add(
      this.observableService.showMaintenance.subscribe((showMaintenance) => (this.showMaintenance = showMaintenance)),
    );

    // from header V1 for wheel
    this.subscriptions.add(
      this.observableServiceV1.userSetting.subscribe((userSetting) => {
        this.userSetting = userSetting;
      }),
    );

    // from header V1 for wheel
    this.subscriptions.add(
      this.wheelCalculatorService.updateUserSetting$.subscribe((res) => {
        if (res) {
          this.userSetting = res;
        }
      }),
    );

    // from header V1 for wheel
    if (!Object.keys(this.userSetting).length) {
      this.userSetting = JSON.parse(localStorage.getItem('user'));
    }

    this.wheelViewModeState$.pipe(debounceTime(saveViewModeDebounceTime)).subscribe(async (viewMode) => {
      this.observableServiceV2.wheelViewMode.next(viewMode);
      await this.userDataService.set(UserSettings.WheelViewMode, viewMode);
    });
  }

  ngDestroyParent(): void {
    this.subscriptions.unsubscribe();
  }

  public toggleHelpMenu(): void {
    this.isHelpMenuOpened = !this.isHelpMenuOpened;
  }

  public checkIsMobileOS(): void {
    const isAndroid = this.platform.ANDROID;
    const isIOS = this.platform.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    this.isMobileOS = isAndroid || isIOS;
  }

  public openAssetCorrelation(): void {
    this.closeMenu();
    this.navigationService.redirectToTab(TabNames.AssetCorrelation);
  }

  public openTradingLog(): void {
    this.closeMenu();
    this.navigationService.redirectToTab(TabNames.TradingLog);
  }

  public openVideoHub(): void {
    this.closeMenu();
    this.navigationService.redirectToTab(TabNames.VideoHub);
  }

  public openTradingPanel(): void {
    this.closeMenu();
    this.navigationService.redirectToTab(TabNames.TradingPanel);
  }

  public onWindowResize(event): void {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < MobileWidth;
  }

  public async changeTab(value): Promise<void> {
    this.closeMenu();

    if (this.activeTab === value) {
      return;
    }

    this.activeTab = value;
    await this.navigationService.redirectToTab(value);
  }

  public getRiskAmountPerTrade(): string {
    return formatNumber(getRiskAmountPerTradeImported(this.accountSize, this.accountRiskPercent));
  }

  public positionSizing(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['position-sizing', 'modals'];
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      accountSizeSettingName: UserSettings.AccountSize,
      accountRiskPercentSettingName: UserSettings.AccountRiskPercent,
      accountRiskAmountSettingName: UserSettings.AccountRiskAmount,
      showCanadaWarning: true,
    } as Partial<PositionSizingModel>;

    this.dialog.open(PositionSizingPopupComponent, dialogConfig);

    document.getElementsByClassName('modals')[0].scrollTo(0, 0);
  }

  public wheelPositionSizing(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['position-sizing', 'buying-power', 'modals'];
    dialogConfig.disableClose = true;
    this.dialog.open(WheelHeaderPopupComponent, dialogConfig);
    document.getElementsByClassName('modals')[0].scrollTo(0, 0);
  }

  public openHelpModal(): void {
    this.helpValue = !this.helpValue;
  }

  public openMMTradesTable(): void {
    this.closeMenu();
    this.navigationService.redirectToTab(TabNames.MMTrades);
  }

  public changeViewMode(viewMode: WheelViewModes): void {
    this.wheelViewModeState$.next(viewMode);
  }

  public async navigateToEarningsAnalysisPage(): Promise<void | void[]> {
    this.closeMenu();

    return this.navigationService.redirectToTab(TabNames.EarningsAnalysis);
  }

  public async navigateToEarningsCalendarPage(): Promise<void | void[]> {
    this.closeMenu();

    return this.navigationService.redirectToTab(TabNames.EarningsCalendar);
  }

  public navigateToDividendsCalendarPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.DividendsCalendar);
  }

  public navigateToShortingStocksScannerPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.ShortingScanner);
  }

  public navigateToHeatmapPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.Heatmap);
  }

  public navigateToTradingPanelPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.TradingPanel);
  }

  public navigateToStockScreenersPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.StockScreeners);
  }

  protected navigateToLicenseAgreementPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.LicenseAgreement);
  }

  protected navigateToDisclaimerPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.Disclaimer);
  }

  protected navigateToHomePage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.Home);
  }

  public navigateToConfigurationPage(): Promise<void> {
    this.closeMenu();
    return this.navigationService.redirectToTab(TabNames.AdminConfiguration);
  }

  public async openMenuModal(activeTab): Promise<void> {
    this.menuValue = !this.menuValue;

    if (activeTab) {
      await this.changeTab(activeTab);
    }
  }

  public async openPrintModal(): Promise<void> {
    this.closeMenu();

    await this.dialogService.openPrintDataDialog((afterClosedData) => {
      if (afterClosedData) {
        this.observableService.isNeedPrint$.next(true);
      }
    });
  }

  protected async navigateToCalendarPage(calendarTab: string): Promise<void> {
    this.closeMenu();

    const availableCalendars = [
      TabNames.Calendars,
      TabNames.MasterCalendar,
      TabNames.EconomicCalendar,
      TabNames.DividendsCalendar,
      TabNames.EarningsCalendar,
      TabNames.HolidaysCalendar,
    ];

    if (availableCalendars.includes(calendarTab)) {
      return this.navigationService.redirectToTab(calendarTab);
    }

    return this.navigationService.redirectToTab(TabNames.Calendars);
  }

  public async mySettingsModal(): Promise<void> {
    this.closeMenu();
    await this.dialogsService.openMySettingsModal();
  }

  public async openHolidaysModal(): Promise<void> {
    this.closeMenu();
    await this.dialogsService.openHolidaysDialog();
  }

  public openTradingHubModal(): void {
    this.closeMenu();

    from(this.dialogsService.openTradingHubModal()).subscribe();
  }

  public async openLinksOrganiserModal(): Promise<void> {
    await this.dialogsService.openLinksOrganiserModal();
  }

  // toggle is off = white theme, else dark
  async toggleTheme(theme: boolean): Promise<void> {
    this.menuValue = !this.menuValue;
    const newTheme = theme ? 0 : 1;
    await this.themeService.toggleTheme(newTheme, false);
  }

  public goToLogout(): void {
    this.brokerAuthenticationService.logout();
    this.authService.logout();
  }

  public closeMenu(): void {
    this.menuValue = false;
    this.helpValue = false;
  }

  // should be removed after tested on dev!
  protected sentryTest(): void {
    throw new Error('Sentry test error');
  }
}

@Component({
  selector: 'app-mobile-header',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileHeaderComponent extends HeaderComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    super.ngInitParent();
  }

  ngOnDestroy(): void {
    super.ngDestroyParent();
  }
}

@Component({
  selector: 'app-web-header',
  templateUrl: './header-web.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebHeaderComponent extends HeaderComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    super.ngInitParent();
  }

  ngOnDestroy(): void {
    super.ngDestroyParent();
  }
}
