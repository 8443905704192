<div
  appClickOutside
  (outsideClick)="turnOffEditModeForContent($event)"
  (mousedown)="turnOffEditModeForContent($event); onHoverSectionTitle(null)"
  class="plan-tab-content"
  (mouseover)="onHoverSectionTitle(null)"
  (mouseleave)="onHoverSectionTitle(null)"
>
  <div class="sections-menu scroll-style">
    <div class="plan-mode">
      <button
        mat-button
        *ngIf="plan.sections.length > 0"
        (click)="onChangeIsPlanLocked()"
        class="extra-small-icon-btn label-btn"
      >
        <div
          *ngIf="plan.mode === planModes.Write"
          class="plan-mode-button-content"
          [matTooltip]="'Switch to read only mode'"
          [matTooltipPosition]="'right'"
          [matTooltipHideDelay]="0"
        >
          <mat-icon class="lock-icon xl-icon" [svgIcon]="'lock-opened'"></mat-icon>
          <span class="plan-mode-title">EDIT MODE</span>
        </div>

        <div
          *ngIf="plan.mode === planModes.Read"
          class="plan-mode-button-content"
          [matTooltip]="'Switch to edit mode'"
          [matTooltipPosition]="'right'"
          [matTooltipHideDelay]="0"
        >
          <mat-icon class="lock-icon xl-icon" [svgIcon]="'lock-closed'"></mat-icon>
          <span class="plan-mode-title">READ MODE</span>
        </div>
      </button>
      <mat-icon
        [matTooltip]="'All changes have been saved'"
        [svgIcon]="syncIcons.success"
        class="sync-icon"
        [class.visible]="plan.mode === planModes.Write && plan.sections.length > 0 && syncIcon === syncIcons.success"
      >
      </mat-icon>
      <mat-icon
        [matTooltip]="'Saving changes...'"
        [svgIcon]="syncIcons.inProgress"
        class="sync-icon"
        [class.visible]="plan.mode === planModes.Write && plan.sections.length > 0 && syncIcon === syncIcons.inProgress"
      >
      </mat-icon>
      <mat-icon
        [matTooltip]="'Something went wrong. Please check your connection and try again'"
        [svgIcon]="syncIcons.error"
        class="sync-icon"
        [class.visible]="plan.mode === planModes.Write && plan.sections.length > 0 && syncIcon === syncIcons.error"
      >
      </mat-icon>
    </div>

    <div class="dates-container">
      <div class="date-wrapper">
        <div class="title">
          <mat-icon [svgIcon]="'time-icon'" class="extra-small-icon time-icon"></mat-icon>
          Created:
        </div>
        <div class="value">
          {{ plan.createdDate | date: 'MMM d, y' }}
        </div>
      </div>
      <div class="date-wrapper" *ngIf="plan.updatedDate !== null && plan.sections.length > 0">
        <div class="title">
          <mat-icon [svgIcon]="'edit_icon'" class="extra-small-icon edit-icon"></mat-icon>
          Last Edit:
        </div>
        <div class="value">
          {{ plan.updatedDate | date: 'MMM d, y' }}
        </div>
      </div>
    </div>
    <div
      *ngIf="plan.sections.length > 0"
      cdkDropList
      [cdkDropListData]="plan.sections"
      (cdkDropListDropped)="onSectionDrop($event)"
      class="navigation-container"
    >
      <span class="sub-header">Plan</span>

      <div
        cdkDrag
        cdkDragLockAxis="y"
        class="title-container"
        *ngFor="let section of plan.sections; trackBy: trackByFn"
        [ngClass]="{ 'active': activeSectionTitleID === section.id || activeSectionContentID === section.id }"
      >
        <!-- keep space for drag-handler (hidden if plan contains only one section) -->
        <mat-icon
          cdkDragHandle
          *ngIf="plan.sections.length > 1"
          class="drag-icon extra-small-icon"
          svgIcon="drag-icon"
        ></mat-icon>
        <div class="dot"></div>
        <div
          class="title"
          (click)="onClickSectionNavItem(section)"
        >
          {{ section.title }}
        </div>
        <div class="close-btn-container">
          <button
            mat-icon-button
            (click)="onDeleteSection(section)"
            class="close-btn"
            tabindex="-1"
          >
            <mat-icon [svgIcon]="'close'" class="l-icon"></mat-icon>
          </button>
        </div>
        <div *cdkDragPreview>
          {{ section.title }}
        </div>
      </div>
    </div>

    <button
      mat-button
      *ngIf="plan.sections.length > 0"
      class="add-section-button"
      (click)="onAddSection()"
    >
      <mat-icon [svgIcon]="'plus_large'" class="middle-icon"></mat-icon>
      Add New Section
    </button>
  </div>

  <div class="sections-list scroll-style">
    <div
      class="sections-list-content"
      *ngIf="plan.sections.length > 0; else: noSection"
    >
      <div
        [id]="'section-content_' + section.id"
        class="section"
        [ngClass]="{ 'edit-mode': plan.mode === planModes.Write }"
        *ngFor="let section of plan.sections; trackBy: trackByFn"
      >
        <div class="section-header">
          <div class="action">
            <button
              mat-icon-button
              class="extra-small-icon-btn default-bg"
              (click)="onChangeIsSectionExpanded(section)"
            >
              <div
                class="select-arrow"
                [ngClass]="{ 'close-item': section.isExpanded, 'right-arrow-mode': plan.mode === planModes.Read }"
              ></div>
            </button>
          </div>

          <div
            class="title-container"
            (mouseover)="$event.stopPropagation(); onHoverSectionTitle(section)"
            (click)="$event.stopPropagation(); onClickSectionTitle(section)"
            (mousedown)="$event.stopPropagation()"
            (dblclick)="onDoubleClickSectionTitle(section)"
          >
            <p
              class="title-header"
              *ngIf="plan.mode === planModes.Read"
            >{{ section.title }}</p>

            <!-- container is added for border with padding around cdkTextareaAutosize textarea -->
            <!-- cdkTextareaAutosize doesn't use padding in process of height calculations -->
            <div
              class="section-title-form-field-container"
              [ngClass]="{ 'border': activeSectionTitleID === section.id || hoveredSectionTitleID === section.id || selectionStartedSectionContentID === section.id }"
              *ngIf="plan.mode === planModes.Write"
            >
              <textarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="4"
                [id]="'section-title-textarea_' + section.id"
                (input)="onChangeSectionTitle(section, $event)"
                (keydown.enter)="$event.preventDefault(); onTitleTextAreaFocusOut(section, $event); setEditModeForSectionContent(section)"
                (keydown.shift.enter)="$event.preventDefault()"
                (paste)="onPasteSectionTitleInput(section, $event)"
                (focusout)="onTitleTextAreaFocusOut(section, $event)"
                (mousedown)="onSelectStartSectionTitle(section)"
                (select)="onSelectSectionTitle(section)"
                type="text"
                [maxLength]="maxSectionTitleLength"
                class="form-field"
                [value]="section.title"
              ></textarea>
            </div>
          </div>
        </div>

        <div
          class="section-content"
          [ngClass]="{ 'hidden': !section.isExpanded }"
        >
          <!-- content: '<p></p>' - when it's empty after edit -->
          <!-- todo: (if it makes sense) update "section.content.length > 0 && section.content !== '<p></p>'" checking -->
          <div
            class="static-section-content ngx-content-content scroll-style"
            [ngClass]="{ 'placeholder': section.content.length === 0 || section.content === '<p></p>' }"
            *ngIf="activeSectionContentID !== section.id"
            (mousedown)="$event.stopPropagation(); onClickSectionContent(section)"
            (dblclick)="onDoubleClickSectionContent(section)"
            [innerHtml]="(section.content.length > 0 && section.content !== '<p></p>') || (plan.mode === planModes.Read) ? (section.content | sanitizeHtml) : 'Click here to add content'"
          ></div>

          <div
            class="NgxEditor__Wrapper"
            *ngIf="activeSectionContentID === section.id"
            id="ngx-editor-wrapper"
          >
            <ngx-editor
              [editor]="currentEditor"
              [ngModel]="html"
              [disabled]="false"
              [placeholder]="'Click here to add content'"
              [outputFormat]="'html'"
              (keyup)="refreshComponentView()"
              (keydown)="refreshComponentView()"
              (focusOut)="onContentTextAreaFocusOut(section)"
              class="ngx-editor-content"
            ></ngx-editor>
            <ngx-editor-menu
              [editor]="currentEditor"
              [dropdownPlacement]="'bottom'"
              [toolbar]="toolbar"
            ></ngx-editor-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noSection>
  <div class="no-sections">
    <p class="details">
      No Data for now
    </p>
    <button mat-button class="add-section-button actions" (click)="onAddSection()">
      <mat-icon svgIcon="plus"></mat-icon>
      Add New Section
    </button>
  </div>
</ng-template>

<!-- code for collapse button, for the next release -->
<!--  <button-->
<!--    mat-icon-button-->
<!--    (click)="isNavHidden = !isNavHidden"-->
<!--    [ngClass]="{ 'collapsed': isNavHidden }"-->
<!--    class="extra-small-icon-btn label-btn hide-nav-button"-->
<!--  >-->
<!--    <mat-icon class="collapse-icon" [svgIcon]="'arrow-right'"></mat-icon>-->
<!--  </button>-->
