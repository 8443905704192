<table mat-table [dataSource]="data" class="">

    <ng-container matColumnDef="strike">
      <th mat-header-cell *matHeaderCellDef> strike </th>
      <td mat-cell *matCellDef="let element"> {{ element.strike }} </td>
    </ng-container>

    <ng-container matColumnDef="bid">
      <th mat-header-cell *matHeaderCellDef> bid </th>
      <td mat-cell *matCellDef="let element"> {{ element.bid |  number: '1.2-2' }} </td>
    </ng-container>

    <ng-container matColumnDef="ask">
      <th mat-header-cell *matHeaderCellDef> ask </th>
      <td mat-cell *matCellDef="let element"> {{ element.ask |  number: '1.2-2' }} </td>
    </ng-container>

    <ng-container matColumnDef="delta">
      <th mat-header-cell *matHeaderCellDef> delta </th>
      <td mat-cell *matCellDef="let element">{{ element.delta ? ( element.delta | number: '1.2-2') : 'n/a' }} </td>

    </ng-container>

    <ng-container matColumnDef="roi">
      <th mat-header-cell *matHeaderCellDef> roi </th>
      <td mat-cell *matCellDef="let element">
        <span class="progress-line-bar">

            <span class="progress-bar-value">{{ element.roi !== null ? (element.roi | number:'1.0-0') + '%' : '-' }} </span>
            <div [style.width.%]="getRoiWidth(element.roi)"
              class="progress"
              [ngClass]="getRoiClass(element.roi)"></div>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></tr>

    <tr
      [ngClass]="{
        'successful-row': (subscription.isPut && subscription.stockPrice !== null && row.strike <= subscription.stockPrice)
          || (!subscription.isPut && subscription.stockPrice !== null && row.strike >= subscription.stockPrice)
      }"
     mat-row *matRowDef="let row; columns: displayedColumns();" (click)="rowClick(row)"></tr>
</table>
