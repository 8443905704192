import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-request-result-message',
  templateUrl: './request-result-message.component.html',
  styleUrls: ['./request-result-message.component.scss']
})
export class RequestResultMessageComponent implements OnInit {
  @Input() message = 'Operation failed.';
  @Output() retryRequest = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

  submit(event: Event) {
    this.retryRequest.emit(event)
  }
}
