<div class="chart-trading-option" id="tabb">
    <div class="left-side-button">
        <mat-button-toggle-group hideSingleSelectionIndicator="true" name="fontStyle" #group="matButtonToggleGroup" [value]="chartTab" (change)="changeChartTab(group.value)">
            <mat-button-toggle value="{{ChartTabs.Signal}}" class="s-less-991">{{getChartTabName(ChartTabs.Signal)}}</mat-button-toggle>
            <mat-button-toggle value="{{ChartTabs.Chart}}">{{getChartTabName(ChartTabs.Chart)}}</mat-button-toggle>
            <mat-button-toggle value="{{ChartTabs.TradingReport}}">{{getChartTabName(ChartTabs.TradingReport)}}</mat-button-toggle>
            <mat-button-toggle value="{{ChartTabs.Options}}">{{getChartTabName(ChartTabs.Options)}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

  <div class="right-side-content">
    <app-data-window [isPrint]="false" *ngIf="chartTab === ChartTabs.Signal"></app-data-window>
    <app-trading-chart *ngIf="chartTab === ChartTabs.Chart"></app-trading-chart>
    <app-trading-report [isPrint]="false" *ngIf="chartTab === ChartTabs.TradingReport"></app-trading-report>
    <app-options [isPrint]="false" *ngIf="chartTab == ChartTabs.Options"></app-options>
  </div>
</div>
