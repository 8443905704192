<div class="sol-alert-container">
  <div class="sol-alert-content-container">
    <div class="header">
      <div class="sol-alert-icon-container">
        <!-- bell, up or down icon -->
        <mat-icon [svgIcon]="'trend-alert'" class="big-icon trend-icon"></mat-icon>
      </div>

      @if (data.notifications.length > 1) {
        <p class="sol-alert-title">{{ data.notifications.length }} SOL Alerts</p>
      } @else {
        <p class="sol-alert-title">SOL Alert</p>
      }

      <button mat-icon-button (click)="close()" class="popup-close-btn close-icon">
        <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon"></mat-icon>
      </button>
    </div>

    <div class="notifications-container">
      <div class="nav-btn-container">
        <button
          mat-icon-button
          (click)="onClickPrev()"
          class="nav-btn prev-btn"
          [class.hidden]="data.notifications.length === 1"
          [disabled]="currentNotificationIndex === 0"
        >
          <mat-icon [svgIcon]="'arrow-left_icon'" class="arrow-icon prev"></mat-icon>
        </button>
      </div>

      <div class="notification-content">
        <div class="section-content message">
          <button
            mat-button
            class="symbol-btn"
            (click)="onClickSymbol(data.notifications[currentNotificationIndex])"
          >
            <span class="label">{{ data.notifications[currentNotificationIndex].symbol }}</span>
            <mat-icon iconPositionEnd [svgIcon]="'outer-link'" class="outer-link"></mat-icon>
          </button>

          {{ data.notifications[currentNotificationIndex].message }}
        </div>

        <div class="section-content time">
          <p class="time">{{ data.notifications[currentNotificationIndex].time }}</p>
        </div>
      </div>

      <div class="nav-btn-container">
        <button
          mat-icon-button
          (click)="onClickNext()"
          class="nav-btn next-btn"
          [class.hidden]="data.notifications.length === 1"
          [disabled]="currentNotificationIndex === data.notifications.length - 1"
        >
          <mat-icon [svgIcon]="'arrow-left_icon'" class="arrow-icon next"></mat-icon>
        </button>
      </div>
    </div>

    <div class="section-content warning">
      <p class="warning-content">
        <span class="warning-section-title">IMPORTANT:</span>
        If you can't find this symbol in the list, please adjust your filter criteria accordingly.
      </p>
    </div>
  </div>
</div>
