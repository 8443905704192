export interface IWheelCalculator {
    id?: number,
    user_id?: number
    header_id?: number,
    put_symbol?: string,
    put_stock_price?: any,
    put_strike_price?: any,
    put_option_premium?: any,
    put_expiration?: number,
    call_symbol?: string,
    call_stock_price?: any,
    call_strike_price?: any,
    call_option_premium?: any,
    call_expiration?: number
    call_no_of_shares?: any
    created_on?: number,
    updated_on?: number,
    date?: string
}

export class WheelCalculator implements IWheelCalculator {
    id?: number;
    user_id?: number;
    header_id?: number;
    put_symbol?: string;
    put_stock_price?: number;
    put_strike_price?: number;
    put_option_premium?: number;
    put_expiration?: number;
    call_symbol?: string;
    call_stock_price?: number;
    call_strike_price?: number;
    call_option_premium?: number;
    call_expiration?: number;
    call_no_of_shares?: number;
    created_on?: number;
    updated_on?: number;
    constructor(id?: number,
        user_id?: number,
        header_id?: number,
        put_symbol?: string,
        put_stock_price?: number,
        put_strike_price?: number,
        put_option_premium?: number,
        put_expiration?: number,
        call_symbol?: string,
        call_stock_price?: number,
        call_strike_price?: number,
        call_option_premium?: number,
        call_expiration?: number,
        call_no_of_shares?: number,
        created_on?: number,
        updated_on?: number) {
            this.id = id;
            this.user_id = user_id;
            this.header_id = header_id;
            this.updated_on = updated_on;
            this.put_symbol = put_symbol;
            this.call_symbol = call_symbol;
            this.put_stock_price = put_stock_price;
            this.put_strike_price = put_strike_price;
            this.put_option_premium = put_option_premium;
            this.put_expiration = put_expiration;
            this.created_on = created_on;
            this.updated_on = updated_on;
            this.call_stock_price = call_stock_price;
            this.call_strike_price = call_strike_price;
            this.call_no_of_shares = call_no_of_shares;
            this.call_option_premium = call_option_premium;
            this.call_expiration = call_expiration;
        }
}
