import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';

import { RestRequestorService } from '@s/rest-requestor.service';
import { Response } from '@core1/interface';
import { IEarningsCalendarResponse, IEarningsDetailsApiResponse } from '@t/earning-calendar/earnings-calendar.types';
import { EasternTimeZoneName, MomentDateTimeFormats, StorageKeys } from '@const';

@Injectable()
export class EarningsCalendarService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService
  ) {
  }

  public getCalendarEarnings(
    dateFrom: string,
    dateTo: string,
  ): Observable<IEarningsCalendarResponse> {
    const storageKey = `${StorageKeys.EarningsCalendar}_${dateFrom}-${dateTo}`;
    const params = { from: dateFrom, to: dateTo };

    return from(
      this.restRequesterService.makeRequest(
        storageKey,
        () => firstValueFrom(this.http.post<Response<IEarningsCalendarResponse>>(`/v2/earnings/calendar`, { ...params })),
        false
      )
    ).pipe(
      map((response) => response.result),
      catchError(this.handleError)
    );
  }

  public getEarningsDataForSymbol(securityId: number): Observable<IEarningsDetailsApiResponse[]> {
    const date = moment().tz(EasternTimeZoneName).startOf('day').format(MomentDateTimeFormats.ServerDate);
    const storageKey = `${StorageKeys.EarningsCalendar}_${date}_${securityId}`;
    const params = { security_id: String(securityId) };

    return from(
      this.restRequesterService.makeRequest(
        storageKey,
        () => this.http.get<Response<IEarningsDetailsApiResponse[]>>(`/v2/earnings/details`, { params }).toPromise(),
        false
      )
    ).pipe(
      map((response) => response.result),
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    if (error.status === 400) {
      return throwError(error.error);
    } else {
      return throwError(error || 'Server error');
    }
  }
}
