import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Response } from '@core1/interface';
import {
  TradingLogAccountModel,
  TradingLogDashboardDateInfoModel,
  TradingLogDashboardMetricDetailsGroupModel,
  TradingLogDashboardOpenPositionsInfoModel,
  TradingLogDashboardOpenPositionsRequestModel,
  TradingLogDashboardReportInfoModel,
  TradingLogDashboardReportRequestModel,
  TradingLogStrategyModel
} from '@mod/trading-log';


@Injectable()
export class TradingLogDashboardService {

  constructor(private http: HttpClient) {
  }

  public getLifetime(): Observable<ReadonlyArray<TradingLogDashboardDateInfoModel>> {
    return this.http.post<Response<Array<TradingLogDashboardDateInfoModel>>>('/v2/tradingLog/lifetime', {}).pipe(
      map((response) => response?.result ?? [])
    );
  }

  public getMetricDetailsGroups(): Observable<TradingLogDashboardMetricDetailsGroupModel[]> {
    return this.http.post<Response<TradingLogDashboardMetricDetailsGroupModel[]>>('/v2/tradingLog/groups', {}).pipe(
      map((response) => response?.result ?? [])
    );
  }

  public getReport(model: Partial<TradingLogDashboardReportRequestModel>): Observable<ReadonlyArray<TradingLogDashboardReportInfoModel>> {
    return this.http.post<Response<TradingLogDashboardReportInfoModel[]>>('/v2/tradingLog/report', model).pipe(
      map((response) => response?.result ?? [])
    );
  }

  public getOpenPositions(model: Partial<TradingLogDashboardOpenPositionsRequestModel>)
    : Observable<ReadonlyArray<TradingLogDashboardOpenPositionsInfoModel>> {
    return this.http.post<Response<TradingLogDashboardOpenPositionsInfoModel[]>>('/v2/tradingLog/openPositions', model).pipe(
      map((response) => response?.result ?? [])
    );
  }

  public getAccounts(): Observable<ReadonlyArray<TradingLogAccountModel>> {
    return this.http.post<Response<TradingLogAccountModel[]>>('/v2/tradingLog/accounts', {}).pipe(
      map((response) => response?.result ?? [])
    );
  }

  public getStrategies(): Observable<ReadonlyArray<TradingLogStrategyModel>> {
    return this.http.post<Response<TradingLogStrategyModel[]>>('/v2/tradingLog/strategies', {}).pipe(
      map((response) => response?.result ?? [])
    );
  }
}
