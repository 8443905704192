import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormDefaultsModule } from '@c/shared/forms';
import { TradingLogInputDateFormats, UserSettings } from '@const';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-select-trading-log-input-date-format-form',
  templateUrl: './select-trading-log-input-date-format-form.component.html',
  styleUrls: ['./select-trading-log-input-date-format-form.component.scss'],
  imports: [CommonModule, FormDefaultsModule]
})
export class SelectTradingLogInputDateFormatFormComponent implements OnInit, OnDestroy {

  protected items: ReadonlyArray<TradingLogInputDateFormats> = [
    TradingLogInputDateFormats.MonthDayYear,
    TradingLogInputDateFormats.DayMonthYear,
    TradingLogInputDateFormats.FullYearMonthDay,
    TradingLogInputDateFormats.FullYearDayMonth
  ];

  protected selectedControl: FormControl<TradingLogInputDateFormats>
    = new FormControl(this._observableService.tradingLogSelectedInputDateFormat.value);

  private _destroy$: Subject<void> = new Subject();
  constructor(
    private _observableService: ObservableService,
    userDataService: UserDataService,
  ) {

    this.selectedControl.valueChanges.pipe(
      takeUntil(this._destroy$)
    ).subscribe((value) => userDataService.set(UserSettings.TradingLogSelectedInputDateFormat, value));

    this._observableService.tradingLogSelectedInputDateFormat.pipe(takeUntil(this._destroy$))
      .subscribe((value) => this.selectedControl.setValue(value, { emitEvent: false }));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit() {
  }
}
