import { ElementRef, Input, ViewChild, OnChanges, Component, AfterViewInit } from '@angular/core';
import { ObservableService } from '../../../../services/observable.service';
import { TradePositions } from '../../../../constants';
import { PastPerformanceBlock } from '../past-performance-block.abstract';

@Component({
  selector: 'app-roi-total-profit',
  templateUrl: './roi-total-profit.component.html',
  styleUrls: ['./roi-total-profit.component.scss']
})
export class RoiTotalProfitComponent extends PastPerformanceBlock implements OnChanges, AfterViewInit {
  @Input() pastPerformance = null;
  @Input() pastPerformanceToCompare = null;
  @Input() tradePosition = TradePositions.LongAndShort;
  @ViewChild('totalProfit') totalProfitEl: ElementRef;

  totalProfitProgressBarWidth: string = '';

  getTotalProfitProgressBarWidth() {
    if(!this.pastPerformance || !this.pastPerformanceToCompare) {
      return '';
    }
    const { pastPerformance } = this;
    const propertyName = this.getPropertyName('total_profit');

    const value = pastPerformance[propertyName];
    const valueToCompare = this.pastPerformanceToCompare[propertyName];

    const maxTotalProfit = Math.max(Math.abs(value), Math.abs(valueToCompare));
    const widthTotalProfit = this.totalProfitEl?.nativeElement?.offsetWidth;

    const percents = (Math.abs(value) * 100) / Math.abs(maxTotalProfit);
    if(percents >= 100) {
      return `calc(100% - ${percents * widthTotalProfit / 100}px)`;
    }

    return `calc(${percents}% - ${percents * widthTotalProfit / 100}px)`;
  }

  constructor() {
    super();
  }

  ngOnChanges() {
    // Timeout is neccesery, because when we change position with total_profit = null to
    // position with total_profit <> null it takes time to render it.
    setTimeout(() => {
      if (this.totalProfitEl?.nativeElement?.offsetWidth) {
        this.totalProfitProgressBarWidth = this.getTotalProfitProgressBarWidth();
      }
    }, 0);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.totalProfitProgressBarWidth = this.getTotalProfitProgressBarWidth();
    }, 0);
  }

  getPastPerformanceBackgroundClass(propertyName) {
    const pastPerformance = this.pastPerformance;

    const res = pastPerformance
      ? pastPerformance[this.getPropertyName(propertyName)] >= 0 ? 'bg-green' : 'bg-red'
      : '';

    return 'progress-bar rounded-progress ' + res;
  }

}
