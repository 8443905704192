import { Component, Input, OnInit, Output, EventEmitter, input } from '@angular/core';
import { tradingLogDefaultLiveDataColumns } from '@constants/trading-log.constants';
import { ILiveSubscription, IOption } from '@mod/live-data/live-data.model';

@Component({
  selector: 'app-table',
  templateUrl: './live-data-table.component.html',
  styleUrls: ['./live-data-table.component.scss']
})
export class LiveDataTableComponent implements OnInit {

  @Input() subscription: ILiveSubscription;
  @Input() roiLowerBound: number;
  @Input() roiUpperBound: number;
  @Input() data: IOption[];
  @Input() maxRoi: number;
  protected displayedColumns = input(tradingLogDefaultLiveDataColumns);
  @Output() itemClickEvent = new EventEmitter<IOption>();

  constructor() { }

  ngOnInit(): void {
  }

  getRoiClass(roi: number) {
    let className = '';

    if (roi === null) {
      return className;
    }

    if (roi > this.roiUpperBound) {
      className = 'warning';
    } else if (roi < this.roiLowerBound) {
      className = 'danger';
    } else if (roi >= this.roiLowerBound && roi <= this.roiUpperBound) {
      className = 'successful';
    }

    return className;
  }

  getRoiWidth(roi: number) {
    return this.maxRoi
      ? (roi * 100) / this.maxRoi
      : 0;
  }

  rowClick(row: IOption) {
    this.itemClickEvent.emit(row);
  }
}
