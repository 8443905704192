import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable, Subject, tap, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ObservableService } from '@s/observable.service';
import { EditionsService } from '@s/editions.service';
import { InsertResponse, Response } from '@core1/interface';
import { Features } from '@const';
import { convertArrayToRecord } from '@u/utils';
import { Flags, ISymbolSmiley, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { ISymbolStatistic } from '@c/shared/symbol-flag/symbol-flag.model';

@Injectable({
  providedIn: 'root',
})
export class SmileyDataService {
  // SmileyListType value can be used to trigger update for specific listType
  public updateSymbolsSmileySignal$ = new Subject<SmileyListType | null>();

  // here can be added temp changes from UI update events to use while server response is not received yet
  // Record: security_id -> flag
  public lastRelevantFlags$: BehaviorSubject<Record<SmileyListType, Record<number, Flags>>> = new BehaviorSubject(
    {
      [SmileyListType.PowerX]: {},
      [SmileyListType.Wheel]: {},
      [SmileyListType.Wtf]: {},
      [SmileyListType.StockScreener]: {},
      [SmileyListType.ShortSellingStocks]: {},
      [SmileyListType.ShortingStocksScanner]: {},
      [SmileyListType.DividendsStrategy]: {},
    }
  );

  public lastReceivedSmileyStatistics$: BehaviorSubject<Record<SmileyListType, Record<number, ISymbolStatistic>>> = new BehaviorSubject(
    {
      [SmileyListType.PowerX]: {},
      [SmileyListType.Wheel]: {},
      [SmileyListType.Wtf]: {},
      [SmileyListType.StockScreener]: {},
      [SmileyListType.ShortSellingStocks]: {},
      [SmileyListType.ShortingStocksScanner]: {},
      [SmileyListType.DividendsStrategy]: {},
    }
  );

  public showStatisticSettingAndHasAccess$ = combineLatest([
    of(this.editionsService.isFeatureAvailable(Features.SmileyStatistics)),
    this.observableService.showSmileyStatistics
  ])
    .pipe(
      map(([isAvailable, showStatisticSetting]) => {
        return isAvailable && showStatisticSetting;
      })
    );

  constructor(
    private http: HttpClient,
    private observableService: ObservableService,
    private editionsService: EditionsService,
  ) {
  }

  public updateLastReceivedSmileyStatistics(
    listType: SmileyListType,
    securityId: number,
    statistic: ISymbolStatistic,
  ): void {
    const currentValue = this.lastReceivedSmileyStatistics$.getValue();

    this.lastReceivedSmileyStatistics$.next({
      ...currentValue,
      [listType]: {
        ...currentValue[listType],
        [securityId]: statistic,
      }
    });
  }

  public updateLastRelevantFlags(
    listType: SmileyListType,
    updatedFlags: Record<number, Flags>
  ): void {
    const currentValue = this.lastRelevantFlags$.getValue();

    this.lastRelevantFlags$.next({
      ...currentValue,
      [listType]: {
        ...currentValue[listType],
        ...updatedFlags,
      }
    });
  }

  public resetFlagsState(listType: SmileyListType): void {
    this.lastRelevantFlags$.next({
      ...this.lastRelevantFlags$.getValue(),
      [listType]: {},
    });
    this.lastReceivedSmileyStatistics$.next({
      ...this.lastReceivedSmileyStatistics$.getValue(),
      [listType]: {},
    });
  }

  public updateCurrentFlagsWithLastRelevantFlags(
    list: ISymbolSmiley[],
    relevantFlags: Record<number, Flags>,
  ): ISymbolSmiley[] {
    const flagsRecord = convertArrayToRecord(list, 'security_id');
    const allKeys = [
      ...Object.keys(flagsRecord).map((key) => Number(key)),
      ...Object.keys(relevantFlags).map((key) => Number(key)),
    ];
    const uniqueKeys = Array.from(new Set(allKeys));

    uniqueKeys.forEach((key) => {
      if (!relevantFlags[key]) {
        flagsRecord[key] = null;

        return;
      }

      if (flagsRecord[key]) {
        flagsRecord[key] = { ...flagsRecord[key], flag: relevantFlags[key] };
      } else {
        flagsRecord[key] = { id: null, security_id: key, flag: relevantFlags[key] };
      }
    });

    return Object.values(flagsRecord).filter((item) => item !== null);
  }

  public get = (list: SmileyListType): Observable<ISymbolSmiley[]> => {
    return this.http.get<Response<ISymbolSmiley[]>>(`/v2/smileyData/${list}`)
      .pipe(
        tap((response) => {
          const updatedFlagsRecord = {};

          response.result.forEach((item) => {
            updatedFlagsRecord[item.security_id] = item.flag;
          });

          this.updateLastRelevantFlags(list, updatedFlagsRecord);
        }),
        map((response) => response.result),
      );
  };

  // warning: use insert/remove/removeAll methods only for special cases
  // smiley should be updated using data-channel service to trigger statistics updating for all subscribed users

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public insert = async (security_id: number, flag: Flags, list: SmileyListType) => {
    const { result } = await firstValueFrom(
      this.http.post<InsertResponse>('/v2/smileyData', { security_id, flag, list })
        .pipe(
          tap(() => {
            this.updateSymbolsSmileySignal$.next(list);
          }),
        )
    );

    return result;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public remove = async (id: number, list: SmileyListType) => {
    const { result } = await firstValueFrom(
      this.http.delete<Response>(`/v2/smileyData/${id}`)
        .pipe(
          tap(() => {
            this.updateSymbolsSmileySignal$.next(list);
          }),
        )
    );

    return result;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeAll = async (list: SmileyListType): Promise<any> => {
    const { result } = await firstValueFrom(
      this.http.post<Response>('/v2/smileyData/removeAll', { list })
        .pipe(
          tap(() => {
            this.updateSymbolsSmileySignal$.next(list);
          }),
        )
    );

    return result;
  };
}
