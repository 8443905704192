@if (lastReceivedStatisticAndFlag$ | async; as lastReceivedStatisticAndFlag) {
  @if (lastReceivedStatisticAndFlag.showStatistic) {
    <div class="symbol-flag-statistic-widget root">
      @for (flagMenuItem of symbolFlags; track flagMenuItem.id) {
        <div class="flag-item">
          <mat-icon
            [svgIcon]="flagMenuItem.icon.name"
            [ngClass]="flagMenuItem.icon.class"
            class="icon"
          ></mat-icon>

          @if (lastReceivedStatisticAndFlag.statistic[flagMenuItem.flag].count) {
            <div
              class="count-badge"
              [class.selected]="flagMenuItem.flag === lastReceivedStatisticAndFlag.currentFlag"
              [ngClass]="[flagIcons[flagMenuItem.flag].class]"
              [matTooltip]="lastReceivedStatisticAndFlag.statistic[flagMenuItem.flag].formattedCount + ' vote(s)'"
              [matTooltipHideDelay]="0"
              [matTooltipDisabled]="lastReceivedStatisticAndFlag.statistic[flagMenuItem.flag].count < 1000"
            >
              {{ lastReceivedStatisticAndFlag.statistic[flagMenuItem.flag].formattedShortCount }}
            </div>
          }
        </div>
      }
    </div>
  }
}
