import { round } from '@u/utils';
import { IElementsVisibilityConfig, IPanelSymbolDetails } from './symbol-details-panel.model';
import { PromptConfigurationModel } from './models/prompt-configuration.model';

export const formatMarketCap = (marketCapRawValue: number): string | null => {
  if (marketCapRawValue === null
    || marketCapRawValue === 0
    || Number.isNaN(Number(marketCapRawValue))
  ) {
    return null;
  }

  const marketCapNumberLength = Math.round(marketCapRawValue).toString().length;

  if (marketCapNumberLength > 12) {
    return Number(marketCapRawValue / Math.pow(10, 12)).toFixed(3) + 'T';
  }

  if (marketCapNumberLength > 9) {
    return Number(marketCapRawValue / Math.pow(10, 9)).toFixed(3) + 'B';
  }

  if (marketCapNumberLength > 6) {
    return Number(marketCapRawValue / Math.pow(10, 6)).toFixed(3) + 'M';
  }

  if (marketCapNumberLength > 3) {
    return Number(marketCapRawValue / Math.pow(10, 3)).toFixed(3) + 'K';
  }

  return round(marketCapRawValue, 3).toString();
};

export const getPromptConfiguration = (
  data: IPanelSymbolDetails,
  visibilityConfig: IElementsVisibilityConfig,
  divPerShare: number,
  earningReportDateShort: string,
  upcomingDividendDateShort: string
): PromptConfigurationModel[] => {
  return [
    {
      name: 'EPS',
      value: data.eps !== null ? data.eps : null,
      visible: visibilityConfig.showEps,
    },
    {
      name: 'Market Cap',
      value: data.formattedMarketCap !== null ? data.formattedMarketCap : null,
      visible: visibilityConfig.showMarketCap,
    },
    {
      name: 'DIV Yield',
      value: data.dividendYield !== null ? `${data.dividendYield}%` : null,
      visible: visibilityConfig.showDividendYield,
    },
    {
      name: 'P/E',
      value:
        data.priceEarningsRatio !== null
          ? data.priceEarningsRatio === 0
            ? 0
            : data.priceEarningsRatio.toFixed(2)
          : null,
      visible: visibilityConfig.showPriceEarningsRatio,
    },
    {
      name: 'Upcoming Earnings',
      value: earningReportDateShort,
      visible: visibilityConfig.showUpcomingEarnings,
    },
    {
      name: 'DIV Per Share',
      value: divPerShare !== null ? `$${divPerShare.toFixed(2)}` : divPerShare,
      visible: visibilityConfig.showDivPerShare,
    },
    {
      name: 'Upcoming Dividends',
      value: upcomingDividendDateShort,
      visible: visibilityConfig.showUpcomingDividends,
    },
    {
      name: 'Held by Insiders',
      value: data.percentInsiders
        ? `${data.percentInsiders.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}%`
        : null,
      visible: visibilityConfig.showSharesHoldings,
    },
    {
      name: 'Held by Institutions',
      value: data.percentInstitutions
        ? `${data.percentInstitutions.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}%`
        : null,
      visible: visibilityConfig.showSharesHoldings,
    },
  ];
};
