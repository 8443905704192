<div class="yt-input-container">
  <mat-icon [svgIcon]="'yt_logo'"></mat-icon>
  <div class="form-group w-full">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <input
        #input
        matInput
        class="yt-input"
        [formControl]="inputControl"
        [maxlength]="maxInputLength"
        autocomplete="off"
        type="text"
        placeholder="Paste your YouTube URL link"
      />
    </mat-form-field>
  </div>
</div>
