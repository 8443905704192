import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IWheelCalculator } from 'src/app/core/model/wheel-calc.model';

export class Response {
  id: number;
  stock_header_id: number;
}

export interface ICalculatorHoliday {
  created_on: string;
  id: number;
  name: string;
  observed_date: string;
  updated_on: string;
  year: number;
}

@Injectable()
export class WheelCalculatorService {

  updateUserSetting$: BehaviorSubject<any> = new BehaviorSubject(null);

  save(data: IWheelCalculator[]): Observable<string> {
    const dat = data.map(d => {
      return {
        "id": d.id,
        "userId": d.user_id,
        "headerId": d.header_id,
        "putSymbol": d.put_symbol,
        "putStockPrice": d.put_stock_price,
        "putStrikePrice": d.put_strike_price,
        "putOptionPremium": d.put_option_premium,
        "putExpiration": d.put_expiration,
        "callSymbol": d.call_symbol,
        "callStockPrice": d.call_stock_price,
        "callStrikePrice": d.call_strike_price,
        "callOptionPremium": d.call_option_premium,
        "callExpiration": d.call_expiration,
        "callNoOfShares": d.call_no_of_shares
      }
    });
    return this.httpClient.put('/wheel', dat).pipe(map(res => <string>res));
  }

  public _refreshStock$: BehaviorSubject<number> = new BehaviorSubject(null);

  public get refreshStock$(): Observable<number> {
    return this._refreshStock$.asObservable();
  }

  public setRefreshStock$(value: number) {
    this._refreshStock$.next(value);
  }

  private readonly httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private httpClient: HttpClient) { }

  public saveStocksTODB(data: any): Observable<any> {
    if (data) {
      const obj = {
        "userId": data.userId,
        "putSymbol": data.symbol,
        "putStockPrice": data.last_traded_price,
        "putStrikePrice": data.strike_price,
        "putOptionPremium": data.option_premium,
        "putExpiration": data.expiration
      }
      return this.httpClient.post('/wheel', obj, this.httpOptions)
        .pipe(map(res => (res)));
    }
  }

  public getWheelCalData(): Promise<IWheelCalculator[]> {
    return this.httpClient.get('/wheel/stock-data').pipe(map(res => <IWheelCalculator[]>res['result'])).toPromise();
  }

  public updateUserSetting(userSetting: any) {
    return this.httpClient.put('/users/update-wheel-info', userSetting).pipe(map(res => res));
  }

  public getUserSetting(): Promise<any> {
    return this.httpClient.get('/users/get-setting').pipe(map(res => res['result'][0])).toPromise();
  }

  public deleteWheelCalculatorStocksByIds(payload: any): Observable<any> {
    if (payload.data) {
      payload.data = payload.data.map(d => {
        return {
          "id": d.id,
          "userId": d.user_id,
          "headerId": d.header_id,
          "putSymbol": d.put_symbol,
          "putStockPrice": d.put_stock_price,
          "putStrikePrice": d.put_strike_price,
          "putOptionPremium": d.put_option_premium,
          "putExpiration": d.put_expiration,
          "callSymbol": d.call_symbol,
          "callStockPrice": d.call_stock_price,
          "callStrikePrice": d.call_strike_price,
          "callOptionPremium": d.call_option_premium,
          "callExpiration": d.call_expiration,
          "callNoOfShares": d.call_no_of_shares
        }
      });
    }
    console.log(payload)
    const httOptions = {
      ...this.httpOptions,
      body: payload // {ids: number[], user_id: number, is_clear: boolean, data}
    };
    return this.httpClient.delete('/wheel', httOptions);
  }

  public deleteWheelCalculatorStocksByCallOrPut(payload: any): Observable<any> {
    const httOptions = {
      ...this.httpOptions,
      body: payload
    };
    return this.httpClient.delete('/wheel', httOptions);
  }

  public getHolidays(): Promise<ICalculatorHoliday[]> {
    return this.httpClient.get<{ result: ICalculatorHoliday[] }>('/users/holiday')
      .pipe(map(res => res.result))
      .toPromise();
  }
}
