import { UserSettingsService } from '../../services/user-settings.service';
import { ObservableService } from '../../services/observable.service';

export abstract class WithTradierIntegrationAbstract {
  abstract observableService: ObservableService;
  abstract userSettingsService: UserSettingsService;

  showPlaceOrderButtons: boolean = false;

  async initTradierIntegration() {
    const mySettings = this.observableService.mySettings.value;
    if (!mySettings) {
      const [mySettings] = await Promise.all([
        this.userSettingsService.get()
      ]);

      const { tradier_flag_pxo, tradier_flag_ifs } = mySettings;
      this.showPlaceOrderButtons = !!(tradier_flag_pxo && tradier_flag_ifs);
      this.observableService.mySettings.next(mySettings);
    }
    this.observableService.mySettings.subscribe(({ tradier_flag_pxo, tradier_flag_ifs }) => {
      this.showPlaceOrderButtons = !!(tradier_flag_pxo && tradier_flag_ifs);
    });
  }
}
