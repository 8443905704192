import { Injectable } from '@angular/core';
import cache from 'memory-cache';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  _setWithExpiry(key, value, ttl?) {
    const expiry = ttl
      ? new Date().getTime() + ttl
      : null;

    localStorage.setItem(key, JSON.stringify({
      value,
      expiry
    }));
  }

  _getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    let item = {
      value: null,
      expiry: new Date().getTime() - 1
    };

    try {
      item = JSON.parse(itemStr);
    } catch {
      console.error(`Unable to JSON.parse(${itemStr})`);
    }

    if (item.expiry && item.expiry <= new Date().getTime()) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  get = (key) => this._getWithExpiry(`v2/${key}`);
  set = (key, value, ttl?) => this._setWithExpiry(`v2/${key}`, value, ttl);

  getFromMemory = (key) => cache.get(`v2/${key}`);
  setToMemory = (key, value, ttl?) => cache.put(`v2/${key}`, value, ttl);

  clear = () => localStorage.clear();

  getFullKeys = (): ReadonlyArray<string> => {
    const keys = [];
    for (let keyIndex = 0; keyIndex < localStorage.length; keyIndex++) {
      keys.push(localStorage.key(keyIndex));
    }
    return keys;
  }

  removeByFullKey = (key: string): void => {
    localStorage.removeItem(key);
  }
}
