import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-video-player-skeleton',
  standalone: true,
  imports: [
    MatProgressSpinnerModule
  ],
  templateUrl: './video-player-skeleton.component.html',
  styleUrl: './video-player-skeleton.component.scss'
})
export class VideoPlayerSkeletonComponent {

}
