import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
  ) { }

  set(cname: string, cvalue: string, exdays: number): void {
    const d = new Date();
    d.setTime(d.getTime() + Math.round(exdays * 24 * 60 * 60 * 1000));
    document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=/`;
  }

  get(cname: string): string | null {

    const cookieData = document.cookie.split('; ')
      .map((cookie) => {
        const equalsIndex = cookie.indexOf('=');
        return equalsIndex === -1 ? [cookie] : [cookie.substring(0, equalsIndex), cookie.substring(equalsIndex + 1)];
      })
      .find((splittedValue) => splittedValue.length === 2 && splittedValue[0] === cname);

    return cookieData ? cookieData[1] : null;
  }

  public remove(name: string): void {
      this.set(name, '', -1);
  }

  public getNames(): ReadonlyArray<string> {
    return document.cookie.split('; ')
      .map((cookie) => cookie.split('='))
      .filter((splittedValue) => splittedValue.length >= 2)
      .map((splittedValue) => splittedValue[0]);
  }
}
