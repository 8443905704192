export enum TradingLogStreamingServiceEvent {
  Connect = 'connect',
  ConnectError = 'connect_error',
  GroupsUpdated = 'pxo_trading_log_groups',
  InfoFieldsUpdated = 'pxo_trading_log_transaction_details',
  TransactionsUpdated = 'pxo_trading_log_transactions',
  SummaryUpdated = 'pxo_trading_log_summary',
  CommandResult = 'pxo_trading_log_command_result',
  AccountsUpdated = 'pxo_trading_log_accounts',
  StrategiesUpdated = 'pxo_trading_log_strategies',
}
