import { transformShortNumberToFullNumber } from '@u/utils';

// example: '2.34M'
export const isValidShortNumberLikeString = (
  value: string,
  minValue: number,
  maxValue: number,
  shouldBeInteger: boolean,
): boolean => {
  if (value === null || value === undefined) {
    return false;
  }

  const isEmptyString = value === '';

  if (isEmptyString) {
    return true;
  }

  const fullValue = transformShortNumberToFullNumber(value);

  const isNumber = !isNaN(fullValue);
  const isFinite = Number.isFinite(fullValue);
  const isMoreThanMinValue = fullValue >= minValue;
  const isLessThanMaxValue = fullValue <= maxValue;
  const isInteger = Number.isInteger(fullValue);
  const isNumberTypeCorrect = shouldBeInteger ? isInteger : isNumber;
  const isValidNumberLike = isNumber && isFinite && isMoreThanMinValue && isLessThanMaxValue;

  return isValidNumberLike && isNumberTypeCorrect;
};
