import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@s/common/auth.service';
import { UserDataService } from '@s/user-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _userDataService: UserDataService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `${this._userDataService.getAuthToken()}`,
      },
    });

    // Update auth token only if it is POST request (otherwise the request/response might be cached)
    if (req.method === 'POST') {
      return next.handle(req).pipe(
        map<HttpEvent<any>, any>((httpEvent: HttpEvent<any>) => {
          if (httpEvent instanceof HttpResponse) {
            if (httpEvent.headers.has('Authorization')) {
              this._userDataService.setAuthToken(httpEvent.headers.get('Authorization'));
            }
  
            if (httpEvent.headers.has('AuthorizationExpires') 
              && this._authService.isTokenValid(httpEvent.headers.get('AuthorizationExpires'))) {
              this._userDataService.setAuthTokenExpirationDate(httpEvent.headers.get('AuthorizationExpires'));
            }
          }
          return httpEvent;
        })
      );
    }

    return next.handle(req);    
  }
}
