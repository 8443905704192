import { ApexAxisChartSeries, ApexXAxis, ApexYAxis } from 'ng-apexcharts/lib/model/apex-types';

export enum IncomeStatementType {
  Quarterly = 'Q',
  Annual = 'Y',
}

export enum IncomeStatementCurrency {
  USD = 'USD',
  CAD = 'CAD',
}

export interface IIncomeStatementChartDataSourceItem {
  date: string;
  type: IncomeStatementType;
  xAxisLabel: string;
  netIncome: number;
  totalRevenue: number;
}

export interface IIncomeStatementChartData {
  dataSource: IIncomeStatementChartDataSourceItem[];
  series: ApexAxisChartSeries;
  xAxis: ApexXAxis;
  yAxis: ApexYAxis;
  isShowZeroLine: boolean;
}

export interface ISymbolDescription {
  symbol: string;
  description: string;
}
