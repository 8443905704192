import { Injectable } from '@angular/core';

import {
  MetricDetailsByGroupsTarget,
  MetricDetailsModalData,
  MetricDetailsSymbolTarget,
  MetricDetailsTarget,
  MetricDetailsTradeTypeTargetWithName
} from '@mdl/metric-details-modal/metric-details-modal.model';
import { TradingLogDashboardMetricDetailsGroupModel, TradingLogDashboardReportInfoModel } from '@mod/trading-log';

@Injectable({ providedIn: 'root' })
export class TradingLogMetricDetailsService {

  constructor() { }

  public getMetricDetailsModalData(
    metric: MetricDetailsTarget | MetricDetailsSymbolTarget | MetricDetailsTradeTypeTargetWithName | MetricDetailsByGroupsTarget,
    groups: TradingLogDashboardMetricDetailsGroupModel[],
    isStocks = false
  ): MetricDetailsModalData {
    let name;
    if ('name' in metric) {
      name = metric.name;
    } else {
      name = metric.symbol;
    }

    if ('usedGroups' in metric) {
      const transactions = metric.usedGroups
        .reduce((acc, currentGroup) => {
          acc = acc.concat(currentGroup.usedTransactions);
          return acc;
        }, []);
      const sortedTransactions = this.sortTransactions(transactions, groups);
      const sortedGroups = metric.usedGroups.sort(this.groupsComparator.bind(this));

      return {
        name,
        sortedTransactions,
        sortedGroups,
        isStocks
      };
    } else {
      const sortedTransactions = this.sortTransactions(metric.usedTransactions, groups);
      const sortedGroups = groups.sort(this.groupsComparator.bind(this));

      return {
        name,
        sortedTransactions,
        sortedGroups,
        isStocks
      };
    }
  }

  /*
  * Sorting by group_id is needed for consistency between transactions and groups
  * (sorting by min_date is not enough since min_date can be the same between two different groups and transactions)
  */
  private sortTransactions(
    transactions: TradingLogDashboardReportInfoModel[],
    groups: TradingLogDashboardMetricDetailsGroupModel[]
  ): TradingLogDashboardReportInfoModel[] {
    return transactions.sort(
      (a, b) => {
        const groupA = groups.find((gr) => a.group_id === gr.id);
        const groupB = groups.find((gr) => b.group_id === gr.id);

        return ((a.group_type || '').localeCompare((b.group_type || ''))
          || this.datesComparator(new Date(groupA.min_date), new Date(groupB.min_date))
          || (a.group_id || '').localeCompare((b.group_id || '')));
      }
    );
  }

  /*
  * Sorting by id is needed for consistency between transactions and groups
  * (sorting by min_date is not enough since min_date can be the same between two different groups and transactions)
  */
  private groupsComparator(a: TradingLogDashboardMetricDetailsGroupModel, b: TradingLogDashboardMetricDetailsGroupModel) {
    return this.datesComparator(new Date(a.min_date), new Date(b.min_date)) || (a.id || '').localeCompare((b.id || ''));
  }

  private datesComparator(a: Date, b: Date): number {
    return a.getTime() - b.getTime();
  }
}
