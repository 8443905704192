import { TradingLogDashboardReportInfoModel } from '@mod/trading-log';

export interface TradingLogDashboardChartMonthDataModel {
  optionsRecordsCount: number;
  stocksRecordsCount: number;
  dividendsRecordsCount: number;
  optionsClosedProfitSum: number;
  stocksClosedProfitSum: number;
  dividendsClosedProfitSum: number;
  optionsCommissionsSum: number;
  stocksCommissionsSum: number;
  dividendsCommissionsSum: number;
}

export const emptyChartMonthData: TradingLogDashboardChartMonthDataModel = {
  optionsRecordsCount: 0,
  stocksRecordsCount: 0,
  dividendsRecordsCount: 0,
  optionsClosedProfitSum: 0,
  stocksClosedProfitSum: 0,
  dividendsClosedProfitSum: 0,
  optionsCommissionsSum: 0,
  stocksCommissionsSum: 0,
  dividendsCommissionsSum: 0,
};

export interface TradingLogDashboardSymbolDataModel {
  optionTradesCount: number;
  optionsClosedProfitPutSum: number;
  optionsClosedProfitCallSum: number;
  optionsCommissionsPutSum: number;
  optionsCommissionsCallSum: number;
  optionsAssignedCount: number;
  optionPutSellTradesCount: number;
  stockTradesCount: number;
  stockTradesWithProfitCount: number;
  stockWinTradesCount: number;
  stocksClosedProfitSum: number;
  stocksCommissionsSum: number;
  stockSharesSoldCount: number;
  stockSharesBoughtCount: number;
  dividendsTradesCount: number;
  dividendsCommissionsSum: number;
  dividendsClosedProfitSum: number;
  usedTransactions: TradingLogDashboardReportInfoModel[];
}

export const emptyTableSymbolData: TradingLogDashboardSymbolDataModel = {
  optionsAssignedCount: 0,
  optionsClosedProfitCallSum: 0,
  optionsClosedProfitPutSum: 0,
  optionsCommissionsPutSum: 0,
  optionsCommissionsCallSum: 0,
  optionTradesCount: 0,
  optionPutSellTradesCount: 0,
  stockSharesBoughtCount: 0,
  stockSharesSoldCount: 0,
  stocksClosedProfitSum: 0,
  stocksCommissionsSum: 0,
  stockTradesCount: 0,
  stockTradesWithProfitCount: 0,
  stockWinTradesCount: 0,
  dividendsClosedProfitSum: 0,
  dividendsTradesCount: 0,
  dividendsCommissionsSum: 0,
  usedTransactions: []
};

export type FormattedDay = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export interface OptionsPutCallDayModel {
  call: number;
  put: number;
  all: number;
}

export type OptionsPutCallByDayDataModel = Record<FormattedDay, OptionsPutCallDayModel>;

export interface StockSymbolsTableItemDataModel {
  symbol: string;
  profitLoss: string;
  sharesSold: string;
  sharesBought: string;
  trades: string;
}

export interface OpenPositionSymbolsTableItemDataModel {
  symbol: string;
  sharesSold: string;
  sharesBought: string;
  sellContracts: string;
  buyContracts: string;
}

export enum TradingLogDashboardTabs {
  Position = 'position',
  Summary = 'summary',
  Options = 'options',
  Stocks = 'stocks'
}

export const tradingLogDashboardDisabledEvaluationParentMap = {
  BS: false,
  SS: false,
  BC: true,
  SC: true,
  BP: true,
  SP: true,
  DI: false,
};
