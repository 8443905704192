import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { TradingPanelClientEvent } from '@mod/trading-panel/trading-panel.model';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';
import { ObservableService } from '@s/observable.service';

@Component({
  standalone: true,
  selector: 'app-option-details',
  templateUrl: './option-details.component.html',
  styleUrls: ['./option-details.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule
  ]
})
export class OptionDetailsComponent implements OnInit, OnDestroy {
  @Input() orderInput: TradingOrderInput;

  protected isOption: boolean;

  private _subscriptions = new Subscription();

  constructor(
    private _observableService: ObservableService,
  ) { }

  ngOnInit(): void {
    this.isOption = !!this.orderInput.option;

    this._subscriptions.add(
      this._observableService.tradingPanelClientEvent.subscribe(async (clientEvent: TradingPanelClientEvent) => {
        if (clientEvent === TradingPanelClientEvent.OrderInputDataUpdated) {
          this.orderInput = this._observableService.tradingPanelOrderInput.getValue();
          this.isOption = !!this.orderInput.option;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
