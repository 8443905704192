import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TradingLogNotDefinedAccountId } from '@const';
import { FormBaseComponent } from '@c/shared/forms/form-base.component';
import { MultiSelectDataItemModel } from '@c/shared/multi-select/multi-select.model';
import { TradingLogStreamingService } from '@s/trading-log/trading-log-streaming.service';
import { StringifiedGuid } from '@t/common/stringified-guid.type';
import { getStringComparer } from '@u/comparers/string.comparer';
import { getGroup } from '@u/forms/form-generics';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { TradingLogTradesFilterFormModel } from './models';
@Component({
  selector: 'app-trading-log-trades-filter-form',
  templateUrl: './trading-log-trades-filter-form.component.html',
  styleUrls: ['./trading-log-trades-filter-form.component.scss']
})
export class TradingLogTradesFilterFormComponent extends FormBaseComponent<TradingLogTradesFilterFormModel> implements OnInit {
  @Input() symbolOptions: string[];
  @Input() public override set value(v: TradingLogTradesFilterFormModel) {
    if (v) {

      const filteredAccountIds = v.accounts.filter((selectedAccountId) => this._tlStreamigService.accounts$.value?.find((account) => account.id === selectedAccountId || selectedAccountId === 'null'));
      if (filteredAccountIds.length != v.accounts.length) {
        v = { ...v, accounts: filteredAccountIds };
      }

      const filteredStrategyIds = v.strategies.filter((selectedStrategyId) => this._tlStreamigService.strategies$.value?.find((strategy) => strategy.id === selectedStrategyId || selectedStrategyId === 'null'));
      if (filteredStrategyIds.length != v.strategies.length) {
        v = { ...v, strategies: filteredStrategyIds };
      }

      this.startValue = v;
      //TODO: this code need to fix wrong user settings object issue. Remove if all errors cleared
      if (v['account']) {
        delete v['account'];
      }

      if (v['strategy']) {
        delete v['strategy'];
      }
      // End of code to remove

      this.form.setValue(v, { emitEvent: false });
    }
  }

  @Output() public valueChanges: EventEmitter<TradingLogTradesFilterFormModel> = new EventEmitter<TradingLogTradesFilterFormModel>();

  public override get value(): TradingLogTradesFilterFormModel | null {
    return super.value;
  }

  protected accountSelectData$: Observable<ReadonlyArray<MultiSelectDataItemModel<StringifiedGuid>>>;
  protected strategySelectData$: Observable<ReadonlyArray<MultiSelectDataItemModel<StringifiedGuid>>>;

  constructor(
    private _tlStreamigService: TradingLogStreamingService,
  ) {
    super(getGroup({
      accounts: { v: [], },
      strategies: { v: [] },
      symbol: { v: '' }
    }));

    this.accountSelectData$ = _tlStreamigService.accounts$.pipe(
      map((accounts) => ([{ title: '(not defined)', value: 'null' },
      ...[...accounts].filter((a) => a.id !== TradingLogNotDefinedAccountId).sort(getStringComparer((a) => a.name)).map((account) => ({ title: account.name, value: account.id }))
      ])),
      takeUntil(this.destroy$));

    this.strategySelectData$ = _tlStreamigService.strategies$.pipe(
      map((strategies) => ([{ title: '(not defined)', value: 'null', isDefault: true },
      ...[...strategies].sort(getStringComparer((a) => a.name)).map((strategy) =>
              ({ title: strategy.name, value: strategy.id, isDefault: !!strategy.is_default }))
      ])),
      takeUntil(this.destroy$));

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (value) => {
        if (!this.form.invalid) {
          this.valueChanges.emit(value);
        }
      },
    })
  }

  ngOnInit(): void {
    this._tlStreamigService.accounts$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.value = this.value;
        }
      });

    this._tlStreamigService.strategies$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.value = this.value;
        }
      });
  }

  public clearForm(): void {
    super.clearForm({
      symbol: '',
      accounts: [],
      strategies: []
    });
  }
}
