<app-simple-modal-header [titleText]="modalTitle"></app-simple-modal-header>

<div
    *ngIf="isImport && !fileError.symbolLength && !fileError.noSymbol && !fileError.validFile && !fileError.inValidSymbolsError && !fileError.confirmSubmittion">
    <form [formGroup]="importFileForm" (ngSubmit)="importFileSubmit()" class="form-design">
        <mat-dialog-content class="mat-typography">
            <div class="full-input">
                <label>File name to import</label>
                <div class="choose-file-div">
                    <input type="file" #chooseFile name="chooseFile" id="fileOpen" class="input" accept='.txt'
                        (change)="openFile($event)" formControlName="symbolFile" required>
                    <button mat-button class="choose-file-btn border-btn" (click)="chooseFile.click()" tabindex="-1"
                        for="fileOpen">Choose File</button>
                    <p id="file-value">{{fileValue}}</p>
                </div>
                <label class="import-label blurText">Symbols from imported file will replace the existing
                    symbols.</label>
                <mat-error *ngIf="submitted && importFileForm.controls.symbolFile.invalid">
                    <span *ngIf="importFileForm.controls.symbolFile.errors.required">
                        You must select a symbols File
                    </span>
                </mat-error>
                <div class="sample-file">
                    <label> <a href="assets/sample.txt" download="sample.txt">Download a sample of import file
                        </a></label>
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="center">
            <button mat-flat-button color="primary" class="fill-btn small-btn">Import</button>
            <button mat-button mat-dialog-close class="border-btn small-btn">Cancel</button>
        </mat-dialog-actions>
    </form>
</div>

<div class="import-error" *ngIf="isImport && (fileError.symbolLength || fileError.noSymbol || fileError.validFile)">
    <mat-dialog-content class="mat-typography">
        <mat-error>
            <span>Importing failed.</span>
            <span *ngIf="fileError.symbolLength">
                The file contains more then 100 symbol.
                Please select a file with 100 or less symbols and try again.
            </span>
            <span *ngIf="fileError.noSymbol">
                There are no symbol in your file.
            </span>
            <span *ngIf="fileError.validFile">
                Possible file is invalid or not a text format.
            </span>
            <span>
                Please select another file and try again.
            </span>
        </mat-error>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-flat-button color="primary" mat-dialog-close class="fill-btn small-btn">OK</button>
    </mat-dialog-actions>
</div>
<div class="import-error" *ngIf="isImport && fileError.inValidSymbolsError">
    <mat-dialog-content class="mat-typography">
        <mat-error>
            <span>({{fileError.inValidSymbols.length}}) symbol(s) will not be imported, because they are absent in our
                database.</span>
            <div style="max-height:100px; overflow-y: auto;">
                <span class="mb-0" *ngFor="let sym of fileError.inValidSymbols; let index = index">- {{sym.substring(0,
                    5)}}</span>
            </div>
        </mat-error>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button class="fill-btn small-btn" (click)="displayConfirmBox()">Continue</button>
    </mat-dialog-actions>
</div>

<div class="import-error" *ngIf="isImport && fileError.confirmSubmittion">
    <mat-dialog-content class="mat-typography">
        <mat-error>
            <span>Your current list is not empty.</span>
            <span>Do you want to save your list before importing a new one from your file?</span>
            <span>Otherwise the current list removed.</span>
        </mat-error>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-flat-button class="fill-btn small-btn" color="primary" (click)="importSymbols('yes')">Yes</button>
        <button mat-stroked-button class="border-btn small-btn" (click)="importSymbols('no')">No</button>
    </mat-dialog-actions>
</div>

<div *ngIf="!isImport">
    <form [formGroup]="exportFileForm" (ngSubmit)="exportFileSubmit()" class="form-design">
        <mat-dialog-content class="mat-typography">
            <div class="full-input">
                <label>File name to export</label>
                <input type="text" name="" spellcheck="false" class="input mb8" formControlName="fileName"
                    (input)="exportFileInputPress()" (blur)="setDefaultName('fileName')" required>
                <label class="import-label blurText">File will be saved in your Download folder</label>
                <mat-error *ngIf="submitted && exportFileForm.controls.fileName.invalid">
                    <span>{{ filenameErrorText }}</span>
                </mat-error>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="center">
            <button mat-flat-button color="primary" class="fill-btn small-btn">Export</button>
            <button mat-button mat-dialog-close class="border-btn small-btn">Cancel</button>
        </mat-dialog-actions>
    </form>
</div>
