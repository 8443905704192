<div class="pnl-chart-container" [style.height.px]="chartHeightPx">
  <div class="loader-container" *ngIf="!(chartBars$ | async)">
    <mat-icon>
      <mat-spinner class="chart-loading-spinner" diameter="25"></mat-spinner>
    </mat-icon>
  </div>
  <div class="pnl-chart-bar" *ngFor="let bar of chartBars$ | async">
    <div [style.height.%]="bar?.negativeEmptySpaceBarSizePercent || 0"></div>
    <div [style.height.%]="bar?.profitBarSizePercent || 0" class="profit" [ngClass]="{ negative: bar.profit < 0 }"></div>
    <div [style.height.%]="bar?.positiveEmptySpaceBarSizePercent || 0"></div>
  </div>
</div>
