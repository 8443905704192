<div class="editable-title root">
  <p
    class="title-header"
    *ngIf="!isEditModeActive"
  >{{ initialValue }}</p>

  <!-- container is added for border with padding around cdkTextareaAutosize textarea -->
  <!-- cdkTextareaAutosize doesn't use padding in process of height calculations -->
  <div
    class="section-title-form-field-container"
    [class.border]="isEditModeActive"
    *ngIf="isEditModeActive"
  >
    <textarea
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="2"
      [id]="'title-textarea_' + id"
      [value]="editedValue"
      [maxLength]="maxLength"
      (input)="onChangeSectionTitle($event.target.value)"
      (keydown.enter)="$event.preventDefault(); exitEditMode(true)"
      (keydown.shift.enter)="$event.preventDefault(); exitEditMode(true)"
      (keydown.escape)="$event.preventDefault(); exitEditMode(false)"
      (focusout)="onFocusOut()"
      type="text"
      class="form-field"
      data-ignore-search-popup
    ></textarea>
  </div>
</div>
