import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  @Input() title = '';
  @Input() text = 'Please wait, it may take couple minutes';
  @Input() showUpdatingOverlay = false;

  constructor() { }

  ngOnInit(): void {
  }

}
