import { Injectable } from '@angular/core';
import { VideoHubExternalUserData } from '@mod/video-hub/video-hub.model';
import { UserDataService } from '@s/user-data.service';
import { VideoHubCacheService } from '@s/video-hub/video-hub-cache.service';
import { convertArrayToRecord } from '@u/utils';

@Injectable({
  providedIn: 'root',
})
export class VideoHubExternalUserDataService {
  public readonly sendUpdateUserStopTimeSeconds = 15;
  private readonly userDataKey = 'video-hub-user-external-data';

  constructor(
    private userDataService: UserDataService,
    private videoHubCacheService: VideoHubCacheService,
  ) {
    this.downloadExternalData();
  }

  public async getVideosExternalData(): Promise<Record<string, VideoHubExternalUserData>> {
    const cachedData = this.videoHubCacheService.get(this.userDataKey) as VideoHubExternalUserData[];

    if (cachedData) {
      return this.convertArrayToRecordByVideoId(cachedData);
    }

    return await this.downloadExternalData();
  }

  public async updateVideosExternalData(newData: VideoHubExternalUserData): Promise<void> {
    const videosExternalData = await this.getVideosExternalData();

    const payload = {
      ...videosExternalData,
      [newData.videoId]: videosExternalData[newData.videoId]
        ? { ...videosExternalData[newData.videoId], ...newData }
        : { ...newData },
    };

    this.videoHubCacheService.set(this.userDataKey, this.convertRecordToArray(payload));
    await this.userDataService.set(this.userDataKey, this.convertRecordToArray(payload));
  }

  private async downloadExternalData(): Promise<Record<string, VideoHubExternalUserData>> {
    const allVideoStopPositions = (await this.userDataService.getAsJSON(
      this.userDataKey,
    )) as VideoHubExternalUserData[];

    if (allVideoStopPositions && Array.isArray(allVideoStopPositions)) {
      const externalUserVideoDataRecord = this.convertArrayToRecordByVideoId(allVideoStopPositions);
      this.videoHubCacheService.set(this.userDataKey, allVideoStopPositions);
      return externalUserVideoDataRecord;
    }

    return {};
  }

  private convertArrayToRecordByVideoId(items: VideoHubExternalUserData[]): Record<string, VideoHubExternalUserData> {
    return convertArrayToRecord(items, 'videoId');
  }

  private convertRecordToArray(record: Record<string, VideoHubExternalUserData>): VideoHubExternalUserData[] {
    return Object.values(record);
  }
}
