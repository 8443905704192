import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { RestRequestorService } from '@s/rest-requestor.service';
import { convertArrayToMap } from '@u/utils';
import { StorageKeys } from '@const';
import { Response } from '@core1/interface/response';
import { ExchangeModel } from '@mod/data/exchange.model';

@Injectable({
  providedIn: 'root',
})
export class ExchangesService {
  constructor(
    private _http: HttpClient,
    private _restRequesterService: RestRequestorService
  ) {
  }

  public getAll: () => Promise<ExchangeModel[]> = async () => {
    const { result } = await this._restRequesterService.makeRequest(
      StorageKeys.Exchanges,
      () => firstValueFrom(this._http.get<Response<ExchangeModel[]>>(`/v2/exchanges`))
    );

    return result;
  };

  public getById: (id: number) => Promise<ExchangeModel> = async (id) => {
    const exchanges = await this.getAll();

    return exchanges.find((e) => e.id === id);
  };

  public getByCode: (code: string) => Promise<ExchangeModel | undefined> = async (code) => {
    const exchanges = await this.getAll();

    return exchanges.find((e) => e.code === code);
  };

  public getCodeToExchangeMap: () => Promise<Map<string, ExchangeModel>> = async () => {
    const exchanges = await this.getAll();

    return convertArrayToMap(exchanges, 'code');
  };
}
