import { allExchangeCountryCodes } from '@const';
import { ISearchDialogData } from '@mod/search-bar/search-bar.model';

export const DEFAULT_SEARCH_BAR_SETTINGS: ISearchDialogData = {
  searchString: '',
  isPowerX: false,
  selectInput: false,
  showAddWatchlistButton: false,
  symbolSources: allExchangeCountryCodes,
  clearSearchStringAfterSelection: false,
};
