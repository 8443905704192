import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { TradingHubDialogModel } from './models/trading-hub-dialog.model';
import { ChatCodyService } from '@s/chat-cody.service';

@Component({
  selector: 'app-trading-hub-modal',
  templateUrl: './trading-hub-modal.component.html',
  styleUrls: ['./trading-hub-modal.component.scss'],
})
export class TradingHubModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TradingHubDialogModel,
    private dialogRef: MatDialogRef<TradingHubModalComponent>,
    private chatCodyService: ChatCodyService
  ) { }

  ngOnInit() {
    this.chatCodyService.rockyPrompt$.next(this.data.initialPrompt);
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
