import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IWheelFilter, IWheelROIRange } from '@t/wheel/wheel.types';

@Injectable({
  providedIn: 'root'
})
export class ObservableService {
  public symbol = new Subject<any>();
  public userSetting = new BehaviorSubject<any>({});
  public scannerSetting = new Subject();
  public headerActiveTab = new Subject();
  public scannerCurrentSignal = new Subject();
  public updateWatchlist = new BehaviorSubject<any>(String);
  public moveToWatchlist = new BehaviorSubject<any>(String);
  public successValue = new Subject<any>();
  public symbolPosition = new BehaviorSubject<any>(Number);
  public symbolStrategy = new Subject<any>();
  public showTrade = new Subject<any>();
  public showIndicator = new Subject<any>();
  public dataWindowOption = new Subject<any>();
  public chartOption = new BehaviorSubject<any>({});
  public printHtml = new Subject<any>();
  public comparePostion = new BehaviorSubject<any>({});
  public showLineOnChart = new BehaviorSubject<any>({});
  public selectedTab = new Subject<any>();
  public chartCommanObs = new BehaviorSubject<any>({});
  public currentScannerSymbols = new BehaviorSubject<any>([]);
  public scannerStrategy = new Subject<any>();
  public isLoading = new BehaviorSubject(false);
  public selectScannerTab = new Subject<any>();
  public scannerResult = new Subject<any>();
  public defaultScannerType = new BehaviorSubject('exit_2550');
  public isBanner = new Subject<any>();
  public scannerResultSelectedRow = new Subject<any>();
  public serverName$ = new BehaviorSubject<string>(null);
  public readonly isCheck24MonthData = new BehaviorSubject<any>([]);
  readonly isCheck24MonthData$ = this.isCheck24MonthData.asObservable();
  public theme = new BehaviorSubject<any>("light");
  readonly theme$ = this.theme.asObservable();
  public tradierFlags = new Subject<any>();
  public earningDays = new BehaviorSubject<any>("na");
  readonly earningDays$ = this.earningDays.asObservable();
  public watchListData = new BehaviorSubject<any>([]);
  readonly watchListData$ = this.watchListData.asObservable();
  public isAbletoUpDown = new BehaviorSubject(true);
  public signalAndPosition = new BehaviorSubject([]);
  public selectedTabMySettings = new BehaviorSubject<any>(0);

  public wheelFilters = new BehaviorSubject<IWheelFilter>(null);
  public wheelRoiRange = new Subject<IWheelROIRange>();

  constructor() { }

  setSymbolValue(symbol: any) {
    this.symbol.next(symbol);
  }
  getSymbolValue(): Observable<any> {
    return this.symbol.asObservable();
  }

  get24MonthData() {
    return this.isCheck24MonthData.getValue();
  }

  set24MonthData(data): void {
    this.isCheck24MonthData.next(data);
  }

  getTheme() {
    return this.theme.getValue();
  }

  setTheme(data): void {
    this.theme.next(data);
  }

  getEarningDays() {
    return this.earningDays.getValue();
  }

  setEarningDays(data): void {
    this.earningDays.next(data);
  }

  getWatchListData() {
    return this.watchListData.getValue();
  }

  setWatchListData(data): void {
    this.watchListData.next(data);
  }
}
