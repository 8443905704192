<div
  class="grouped-watchlist-root"
  *ngIf="settingsLoadedAndApplied; else: circularProgress"
>
  <div class="no-data-msg" [class.hidden]="rows.length > 1">
    <div>No symbols saved in watchlist.</div>
  </div>

  <!--  it's only for sort-header, no table-body  -->
  <div class="table-sort-header" [class.hidden]="rows.length <= 1">
    <table
      class="table-sort-header"
      mat-table
      matSort
      [dataSource]="dataSource"
      [matSortActive]="sortState.column"
      [matSortDirection]="sortState.direction"
      (matSortChange)="onSortChange({ column: $event.active, direction: $event.direction })"
    >
      <ng-container *ngFor="let column of columns" [matColumnDef]="column">
        <th
          class="table-sort-header-cell"
          [ngClass]="column"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          disableClear
          [matTooltipDisabled]="!columnHints[column]"
          [matTooltip]="columnHints[column]"
          [matTooltipHideDelay]="0"
          [matTooltipPosition]="'below'"
        >
          {{ sortHeaderTitles[column] ?? column }}
        </th>
        <mat-cell mat-cell *matCellDef="let element; let i = index"></mat-cell>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns" class="table-header-row"></tr>
    </table>
  </div>

  <!--  add table body - using list of div blocks  -->
  <div
    cdkDropList
    [cdkDropListData]="rows"
    [cdkDropListSortPredicate]="dropListSortPredicate.bind(this)"
    (cdkDropListDropped)="onDrop($event)"
    class="list scroll-style"
  >
    <div
      cdkDrag
      *ngFor="let row of rows; let i = index; trackBy: trackByFn"
      [cdkDragData]="row"
      [cdkDragStartDelay]="isTouchscreen ? touchscreenDragStartDelayMs : 0"
      [cdkDragPreviewClass]="isTouchscreen ? 'mobile' : ''"
      [cdkDragDisabled]="editSubHeaderId === row.id"
      (cdkDragStarted)="cancelHighlightRowReadyToDragTimeout(); onDragStarted($event)"
      (contextmenu)="$event.preventDefault()"
      (touchstart)="onTouchStart($event, row)"
      (touchcancel)="cancelHighlightRowReadyToDragTimeout()"
      (touchend)="cancelHighlightRowReadyToDragTimeout()"
      [class.touchscreen]="isTouchscreen"
      [class.ready-to-drag-on-touchscreen]="rowReadyToDrag === row.id"
      class="item"
    >
      <ng-container *ngIf="row.isSubHeader && row.isHeaderVisible">
        <div
          class="sub-header"
          [id]="'grouped-watchlist-row_' + row.id"
          [class.edit]="editSubHeaderId === row.id"
        >
          <button
            mat-icon-button
            class="extra-small-icon-btn default-bg sub-header-collapse-btn"
            (click)="$event.stopPropagation(); onChangeIsSectionExpanded(row.id)"
          >
            <mat-icon
              svgIcon="arrow-down_icon"
              class="arrow-icon"
              [class.opened]="row.isExpanded"
            ></mat-icon>
          </button>

          <div
            class="cell group-name"
            (contextmenu)="$event.stopPropagation(); openSubHeaderContextMenu($event, row, 'contextmenu')"
            (click)="$event.stopPropagation(); openSubHeaderContextMenu($event, row, 'click')"
          >
            <app-editable-title
              [id]="row.id"
              [maxLength]="maxSubHeaderTitleLength"
              [editMode]="editSubHeaderId === row.id"
              [value]="row.name"
              (valueChanged)="onRenameGroup(row, $event)"
              (editFinished)="onRenameGroupFinished(row, $event)"
              (dblclick)="setEditModeForSubHeader(row)"
            ></app-editable-title>
          </div>

          <div *ngIf="editSubHeaderId !== row.id" class="remove-btn">
            <img
              class="remove-img"
              src="assets/img/close-red.svg"
              (click)="$event.preventDefault(); $event.stopPropagation(); onRemoveGroup(row)"
              [matTooltip]="'Delete section from the list'"
              [matTooltipPosition]="'above'"
              alt="Delete section from the list"
            >
          </div>
        </div>

        <div *cdkDragPreview>
          {{ row.name }}
        </div>
      </ng-container>

      <ng-container *ngIf="!row.isSubHeader && row.visible">
        <div
          [id]="'grouped-watchlist-row_' + row.id"
          [class.selected]="selectedRow && selectedRow.id === row.id"
          (click)="selectSymbol(row)"
          class="row"
        >
          <ng-container *ngFor="let column of columns">
            <div class="cell" [ngClass]="column" *ngIf="column === 'flag'">
              <app-symbol-flag
                [flag]="row.data.flag"
                [securityId]="row.data.security_id"
                [smileyListType]="smileyListType"
                [showNoneFlagOnlyOnHover]="true"
                [sendUpdateSignalOnChange]="false"
                [showStatisticsWhenVisible]="true"
                [rootClass]="'scanner-flag'"
                (menuOpened)="isSmileyMenuOpened = true"
                (flagChanged)="onSelectSmiley($event, row)"
                (menuClosed)="isSmileyMenuOpened = false; onSmileyMenuClosed($event, row)"
                (click)="$event.stopPropagation()"
                class="grouped-watchlist-flag"
              ></app-symbol-flag>
            </div>

            <div class="cell" [ngClass]="column" *ngIf="['right_trade_position', 'left_trade_position'].includes(column)">
              <div class="position" [ngClass]="positionClasses[row.data[column]]">
                {{ row.data[column] }}
              </div>
            </div>

            <div class="cell" [ngClass]="column" *ngIf="column === 'country_code'">
              <div
                class="{{ row.data[column] }}-icon"
                [matTooltip]="exchangeCountries[row.data[column]] + ' Exchange'"
                [matTooltipPosition]="'above'"
              ></div>
            </div>

            <div class="cell" [ngClass]="column" *ngIf="column === 'created_date'">
              {{ row.data[column] | date: 'MMM d, yyyy' }}
            </div>

            <div
              class="cell company-cell-content"
              [ngClass]="column"
              [class.with-flags-statistic]="columns.includes('flag')"
              *ngIf="column === 'company'"
            >
              <ng-container *ngIf="!columns.includes('flag')">
                {{ row.data[column] }}
              </ng-container>

              <ng-container *ngIf="columns.includes('flag')">
                <div class="company-title company-eclips">
                  {{ row.data[column] }}
                </div>

                <div class="flag-statistics-widget">
                  <app-symbol-flag-statistic-widget
                    [securityId]="row.data.security_id"
                    [listType]="smileyListType"
                  ></app-symbol-flag-statistic-widget>
                </div>
              </ng-container>
            </div>

            <div
              *ngIf="!['flag', 'right_trade_position', 'left_trade_position', 'country_code', 'company', 'created_date'].includes(column)"
              [ngClass]="column"
              class="cell"
            >
              {{ row.data[column] }}
            </div>
          </ng-container>

          <div class="remove-btn">
            <img
              class="remove-img"
              src="assets/img/close-red.svg"
              (click)="$event.preventDefault(); $event.stopPropagation(); onRemoveItem(row)"
              [matTooltip]="'Delete symbol from the list'"
              [matTooltipPosition]="'above'"
              alt="Delete symbol from the list"
            >
          </div>
        </div>

        <div *cdkDragPreview>
          {{ row.data.symbol }}
        </div>
      </ng-container>
    </div>
  </div>

  <!-- START: sub-header context menu -->
  <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="subHeaderContextMenu"
  ></div>
  <mat-menu #subHeaderContextMenu="matMenu" class="ws-menu sub-header-context-menu">
    <ng-template matMenuContent let-item="item">
      <button mat-menu-item (click)="setEditModeForSubHeader(item)" class="no-border-bottom">
        <mat-icon [svgIcon]="'edit_icon'" class="middle-icon"></mat-icon>
        <span>Rename</span>
      </button>
      <button mat-menu-item (click)="onRemoveGroup(item)">
        <mat-icon [svgIcon]="'close'" class="middle-icon"></mat-icon>
        <span>Remove section</span>
      </button>
      <button mat-menu-item (click)="addSymbolIntoGroup(item)">
        <mat-icon [svgIcon]="'search-icon'" class="middle-icon not-standard-search-icon"></mat-icon>
        <span>Add symbol</span>
      </button>
    </ng-template>
  </mat-menu>
  <!-- END: sub-header context menu -->
</div>

<ng-template #circularProgress>
  <div class="spinner-container">
    <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
  </div>
</ng-template>
