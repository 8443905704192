import { DEFAULT_WTF_FILTERS_STATE } from '@c/wtf-content/wtf-scanner-filter/wtf-scanner-filter.data';
import {
  IWtfScannerFilter,
  Wtf200MAOptionsWtf200MAOptions,
  WtfSymbolsOptions,
} from '@c/wtf-content/wtf-scanner-filter/wtf-scanner-filter.model';
import {
  MAX_PROFIT_FACTOR_VALUE,
  MAX_STOCK_PRICE_VALUE,
  MAX_TRADES_COUNT_VALUE,
  MAX_VOLUME_VALUE,
  MAX_WIN_PERCENT_VALUE,
  MIN_PROFIT_FACTOR_VALUE,
  MIN_STOCK_PRICE_VALUE,
  MIN_TRADES_COUNT_VALUE,
  MIN_VOLUME_VALUE,
  MIN_WIN_PERCENT_VALUE,
} from '@constants/scanner-filters';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';
import { isValidNumberLikeString, isValidShortNumberLikeString } from '@u/filters';

export function validateAndFillWtfFiltersState(
  restoredState: Partial<IWtfScannerFilter>,
  defaultState: IWtfScannerFilter = DEFAULT_WTF_FILTERS_STATE,
): IWtfScannerFilter {
  const availableFlags = [Flags.Maybe, Flags.Never, Flags.No, Flags.None, Flags.Yes];

  const availableSymbols = [
    WtfSymbolsOptions.SP100,
    WtfSymbolsOptions.SP500,
    WtfSymbolsOptions.Nasdaq,
    WtfSymbolsOptions.DowJones,
    WtfSymbolsOptions.Other,
  ];

  const availableMa200Options = [
    Wtf200MAOptionsWtf200MAOptions.above200MADay,
    Wtf200MAOptionsWtf200MAOptions.below200MADay,
    Wtf200MAOptionsWtf200MAOptions.ignore200MADay,
  ];

  const flags =
    'flags' in restoredState && Array.isArray(restoredState.flags)
      ? restoredState.flags.filter((flag) => availableFlags.includes(flag))
      : [...defaultState.flags];

  const symbolOptions =
    'symbolOptions' in restoredState && Array.isArray(restoredState.symbolOptions)
      ? restoredState.symbolOptions.filter((symbol) => availableSymbols.includes(symbol))
      : [...defaultState.symbolOptions];

  const ma200 =
    'ma200' in restoredState && availableMa200Options.includes(restoredState.ma200)
      ? restoredState.ma200
      : defaultState.ma200;

  const stockPriceFrom =
    'stockPriceFrom' in restoredState &&
    isValidNumberLikeString(restoredState.stockPriceFrom, MIN_STOCK_PRICE_VALUE, MAX_STOCK_PRICE_VALUE, false)
      ? restoredState.stockPriceFrom
      : defaultState.stockPriceFrom;

  const stockPriceTo =
    'stockPriceTo' in restoredState &&
    isValidNumberLikeString(restoredState.stockPriceTo, MIN_STOCK_PRICE_VALUE, MAX_STOCK_PRICE_VALUE, false)
      ? restoredState.stockPriceTo
      : defaultState.stockPriceTo;

  const profitFactorFrom =
    'profitFactorFrom' in restoredState &&
    isValidNumberLikeString(restoredState.profitFactorFrom, MIN_PROFIT_FACTOR_VALUE, MAX_PROFIT_FACTOR_VALUE, false)
      ? restoredState.profitFactorFrom
      : defaultState.profitFactorFrom;

  const profitFactorTo =
    'profitFactorTo' in restoredState &&
    isValidNumberLikeString(restoredState.profitFactorTo, MIN_PROFIT_FACTOR_VALUE, MAX_PROFIT_FACTOR_VALUE, false)
      ? restoredState.profitFactorTo
      : defaultState.profitFactorTo;

  const winPercentFrom =
    'winPercentFrom' in restoredState &&
    isValidNumberLikeString(restoredState.winPercentFrom, MIN_WIN_PERCENT_VALUE, MAX_WIN_PERCENT_VALUE, false)
      ? restoredState.winPercentFrom
      : defaultState.winPercentFrom;

  const winPercentTo =
    'winPercentTo' in restoredState &&
    isValidNumberLikeString(restoredState.winPercentTo, MIN_WIN_PERCENT_VALUE, MAX_WIN_PERCENT_VALUE, false)
      ? restoredState.winPercentTo
      : defaultState.winPercentTo;

  const isVolumeFromValid =
    'volumeFrom' in restoredState &&
    isValidShortNumberLikeString(restoredState.volumeFrom.visibleValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false) &&
    isValidNumberLikeString(restoredState.volumeFrom.filterValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false);

  const isVolumeToValid =
    'volumeTo' in restoredState &&
    isValidShortNumberLikeString(restoredState.volumeTo.visibleValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false) &&
    isValidNumberLikeString(restoredState.volumeTo.filterValue, MIN_VOLUME_VALUE, MAX_VOLUME_VALUE, false);

  const tradesCountFrom =
    'tradesCountFrom' in restoredState &&
    isValidNumberLikeString(restoredState.tradesCountFrom, MIN_TRADES_COUNT_VALUE, MAX_TRADES_COUNT_VALUE, true)
      ? restoredState.tradesCountFrom
      : defaultState.tradesCountFrom;

  const tradesCountTo =
    'tradesCountTo' in restoredState &&
    isValidNumberLikeString(restoredState.tradesCountTo, MIN_TRADES_COUNT_VALUE, MAX_TRADES_COUNT_VALUE, true)
      ? restoredState.tradesCountTo
      : defaultState.tradesCountTo;

  return {
    ...DEFAULT_WTF_FILTERS_STATE,
    flags,
    symbolOptions,
    ma200,
    stockPriceFrom,
    stockPriceTo,
    profitFactorFrom,
    profitFactorTo,
    winPercentFrom,
    winPercentTo,
    volumeFrom: isVolumeFromValid ? { ...restoredState.volumeFrom } : { ...defaultState.volumeFrom },
    volumeTo: isVolumeToValid ? { ...restoredState.volumeTo } : { ...defaultState.volumeTo },
    tradesCountFrom,
    tradesCountTo,
  };
}
