import { GlobalIdentificator } from '@t/common/global-identificator.type';
import { Moment } from 'moment';

export enum RelativelyToMarketTimeOptions {
  BeforeMarket = 'BeforeMarket',
  DuringMarket = 'DuringMarket',
  AfterMarket = 'AfterMarket',
}

export interface EarningModel {
  readonly security_id: GlobalIdentificator;
  readonly symbol: string;
  readonly description: string;
  readonly exchange_id: GlobalIdentificator;
  readonly exchange_name: string;
  readonly exchange_code: string;
  readonly original_report_date: Moment;
  readonly report_date: Moment;
  readonly before_after_market: string;
  readonly actual?: number;
  readonly estimate?: number;
  readonly percent?: number;
  readonly revenue?: number;
  readonly revenue_est?: number;
  readonly revenue_surprise?: number;
  readonly revenue_surprise_percent?: number;
}

export interface AddEarningServiceModel {
  readonly security_id: GlobalIdentificator;
  readonly date: string;
  readonly before_after_market: string;
}
export interface UpdateEarningServiceModel {
  readonly security_id: GlobalIdentificator;
  readonly original_date: string;
  readonly modified_date: string;
  readonly before_after_market: string;
}

export interface DeleteEarningServiceModel {
  readonly security_id: GlobalIdentificator;
  readonly report_date: string;
}
