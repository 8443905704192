import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Response } from '@core1/interface';
import { StorageKeys } from '@const';
import { RestRequestorService } from '@s/rest-requestor.service';
import { IRawHoliday } from '@mod/data/holidays.model';

@Injectable({ providedIn: 'root' })
export class HolidaysService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService
  ) {
  }

  public get(params: { from: string, to: string }): Observable<IRawHoliday[]> {
    const storageKey = `${StorageKeys.Holidays}-${params.from}-${params.to}`;

    return from(
      this.restRequesterService.makeRequest(
        storageKey,
        () => firstValueFrom(this.http.get<Response<IRawHoliday[]>>(`/v2/holidays`, { params })),
        false,
      )
    ).pipe(
      map((response) => response.result),
      catchError(this.handleError)
    );
  }

  public async getAsync(params: { from: string, to: string }): Promise<IRawHoliday[]> {
    const { result } = await this.restRequesterService.makeRequest(
      `${StorageKeys.Holidays}-${params.from}-${params.to}`,
      () => firstValueFrom(this.http.get<Response<IRawHoliday[]>>(`/v2/holidays`, { params }))
    );

    return result;
  }

  public getWithWorkingHours(params: { from: string, to: string }): Observable<IRawHoliday[]> {
    const storageKey = `HolidaysWithWorkingHours-${params.from}-${params.to}`;

    return from(
      this.restRequesterService.makeRequest(
        storageKey,
        () => firstValueFrom(this.http.get<Response<IRawHoliday[]>>(`/v2/holidays/with_working_hours`, { params })),
        false,
      )
    ).pipe(
      map((response) => response.result),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    if (error.status === 400) {
      return throwError(error.error);
    } else {
      return throwError(error || 'Server error');
    }
  }
}
