
<div class="trading-log-quotes-wrapper">
  <app-window
    [mode]="'trading-log'"
    [displayedColumns]="tradingLogCallLiveDataColumns"
    [roiLowerBound]="roiLowerBound()"
    [roiUpperBound]="roiUpperBound()"
    [putSubscription]="putSubscription"
    [putOptionsChain]="putOptionsChain()"
    [callSubscription]="callSubscription"
    [callOptionsChain]="callOptionsChain()"
    [collapsedCallRow]="!showCallRow"
    [collapsedPutRow]="!showPutRow"
    [tradierFlags]="tradierFlags"
    [isPutTimeout]="isPutSubscriptionTimeoutTriggered()"
    [isCallTimeout]="isCallSubscriptionTimeoutTriggered()"
    [isPutUpdateOverlay]="isPutUpdateOverlay"
    [isCallUpdateOverlay]="isCallUpdateOverlay"
    (putRetryEvent)="liveDataPutRetry()"
    (callRetryEvent)="liveDataCallRetry()"
    (closeClickEvent)="onClose()"
  >
  </app-window>
</div>
