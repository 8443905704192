import {
  Directive,
  EventEmitter,
  Output,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() outsideClick = new EventEmitter<MouseEvent>();

  private cancelListener: () => void | undefined;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.cancelListener = this.renderer.listen(
      document,
      'mousedown',
      (event: MouseEvent) => this.handleDocumentClick(event),
    );
  }

  ngOnDestroy() {
    if (this.cancelListener) {
      this.cancelListener();
    }
  }

  private handleDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.outsideClick.emit(event);
    }
  }
}
