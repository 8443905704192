import { Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TradePositions, UserSettings, ScannerResultTypes } from '@const';
import { ScannerSettingPopupComponent } from 'src/app/main-v2/common/scanner-setting-popup/scanner-setting-popup.component';
import { ObservableService } from 'src/app/services/observable.service';
import { ScannerResultsService } from 'src/app/services/scanner-results.service';
import { TradingStrategiesService } from 'src/app/services/trading-strategies.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-run-scanner-button',
  templateUrl: './run-scanner-button.component.html',
  styleUrls: ['./run-scanner-button.component.scss']
})
export class RunScannerButtonComponent {

  showDropDown: boolean = false;

  constructor(
    private userDataService: UserDataService,
    private tradingStrategiesService: TradingStrategiesService,
    private dialog: MatDialog,
    private scannerResultsService: ScannerResultsService,
    private observableService: ObservableService,
  ) { }

  @HostListener('document:click', ['$event'])
  hideDropDown(event: MouseEvent) {
    const element = event.target as HTMLImageElement;
    if (this.showDropDown && (element.src === undefined || !element.src.includes('downWhite'))) {
      this.showDropDown = false;
    }
  }

  async scannerSetting() {
    const tradingStrategies = await this.tradingStrategiesService.getAll();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['scanner-setting', 'modals'];
    dialogConfig.data = {
      runScannerTradingStrategyId: this.observableService.runScannerTradingStrategyId.getValue(),
      tradingStrategies
    };
    dialogConfig.disableClose = true;
    this.dialog.open(ScannerSettingPopupComponent, dialogConfig);
    document.getElementsByClassName('modals')[0].scrollTo(0, 0);
  }

  dropDownControl(event: Event) {
    event.stopImmediatePropagation();
    this.showDropDown = !this.showDropDown;
  }

  async getAllSignals() {

    this.showDropDown = !this.showDropDown;

    const defaultStrategy = await this.tradingStrategiesService.getDefault();

    if (!defaultStrategy) {
      return;
    }

    const [,, scannerResults] = await Promise.all([
      this.userDataService.set(UserSettings.TradePosition, TradePositions.LongAndShort),
      this.scannerResultsService.removeAll(ScannerResultTypes.Pxo),
      this.scannerResultsService.run({
        signal_type: TradePositions.LongAndShort,
        strategy_id: defaultStrategy.id
      })
    ]);

    if (scannerResults.length) {
      await this.scannerResultsService.insert(scannerResults.map(r => r.security_id), defaultStrategy.id, ScannerResultTypes.Pxo);
    }

    this.observableService.scannerResultUpdated.next(true);
    await this.userDataService.set(UserSettings.SelectScannerTab, 0);
  }
}
