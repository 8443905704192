import { TradingLogDashboardReportInfoModel } from '@mod/trading-log';

interface TradesDuration {
  avg: number;
  max: number;
}

export interface TradesDurationTableModel {
  withAssignments: TradesDuration | null;
  withAssignmentMaxUsedTransactions: TradingLogDashboardReportInfoModel[];
  withoutAssignments: TradesDuration | null;
  withoutAssignmentMaxUsedTransactions: TradingLogDashboardReportInfoModel[];
  all: TradesDuration | null;
  allMaxUsedTransactions: TradingLogDashboardReportInfoModel[];
}

export const emptyTradesDurationTableModel: TradesDurationTableModel = {
  withAssignments: { avg: 0, max: 0 },
  withAssignmentMaxUsedTransactions: [],
  withoutAssignments: { avg: 0, max: 0 },
  withoutAssignmentMaxUsedTransactions: [],
  all: { avg: 0, max: 0 },
  allMaxUsedTransactions: []
};
