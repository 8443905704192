import { StockScreenerTableTitleEnum } from '@c/stock-screener-content/stock-screener-item-list/stock-screener-item-list.model';
import { SymbolScreenerTableSourceType } from '@c/stock-screener-content/stock-screener-details-panel/stock-screener-details-panel.model';

export const DEFAULT_STOCK_SCREENER_DETAILS_TAB_INDEX = 0;

export const SAVE_STOCK_SCREENER_UI_SETTINGS_DEBOUNCE_TIME_MS = 500;

export const symbolScreenerTableSources = {
  dailyGainers: '0',
  dailyLosers: '1',
  weeklyGainers: '2',
  weeklyLosers: '3',
  gapGainers: '4',
  gapLosers: '5',
} as const;

export type DataSourceType = 'dailyGainers'
  | 'dailyLosers'
  | 'weeklyGainers'
  | 'weeklyLosers'
  | 'gapGainers'
  | 'gapLosers';

export interface SymbolScreenerTableSourceOptionModel {
  id: SymbolScreenerTableSourceType;
  title: StockScreenerTableTitleEnum;
  dataSource: DataSourceType; // property name in tablesData object
}
