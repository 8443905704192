import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from '@c1/error/error.component';
import { LoginComponent } from '@c1/login/login.component';
import { UserAggrementComponent } from '@c1/user-aggrement/user-aggrement.component';

import { AdminPanelGuard } from '@g/admin-panel.guard';
import { TradingPanelGuard } from '@g/trading-panel.guard';
import { EditionGuard } from '@g/edition.guard';

import { Features } from '@const';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'user-agreement', component: UserAggrementComponent },

  {
    path: 'home',
    loadComponent: () => import('./containers/home-page-root/home-page-root.component').then((m) => m.HomePageRootComponent),
  },
  {
    path: 'license-agreement',
    loadChildren: () => import('./containers/license-agreement/license-agreement.module').then((m) => m.LicenseAgreementModule),
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./containers/disclaimer/disclaimer-page.module').then((m) => m.DisclaimerPageModule),
    canActivate: [EditionGuard],
    data: { feature: Features.Disclaimer }
  },
  {
    path: 'bull-call-spread',
    loadChildren: () => import('./containers/bull-call-spread/bull-call-spread.module').then((m) => m.BullCallSpreadModule),
    canActivate: [EditionGuard],
    data: { feature: Features.BullCallSpread }
  },
  {
    path: 'wheel',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [EditionGuard],
    data: { feature: Features.Wheel }
  },
  {
    path: 'powerx',
    loadChildren: () => import('./main-v2/main-v2.module').then((m) => m.MainV2Module),
    canActivate: [EditionGuard],
    data: { feature: Features.PowerX }
  },
  {
    path: 'ui-templates',
    loadChildren: () => import('./containers/components-docs/components-docs.module').then((m) => m.ComponentsDocsModule),
  },
  {
    path: 'mm-trades',
    loadComponent: () => import('./containers/spreadsheet-v2/spreadsheet-v2.component').then((m) => m.SpreadsheetV2Component),
    canActivate: [EditionGuard],
    data: { feature: Features.MMTrades },
  },
  {
    path: 'asset-correlation',
    loadChildren: () => import('./containers/asset-correlation/asset-correlation.module').then((m) => m.AssetCorrelationModule),
    canActivate: [EditionGuard],
    data: { feature: Features.AssetCorrelation },
  },
  {
    path: 'earnings-analysis',
    loadChildren: () => import('./containers/earnings-analysis/earnings-analysis.module').then((m) => m.EarningsAnalysisModule),
    canActivate: [EditionGuard],
    data: { feature: Features.EarningsAnalysis },
  },
  {
    path: 'calendars',
    loadChildren: () => import('@containers/combined-calendars/combined-calendars.module').then((m) => m.CombinedCalendarsModule),
    canActivate: [EditionGuard],
    data: { feature: null },
  },
  {
    path: 'heatmap',
    loadChildren: () => import('./containers/heatmap/heatmap.module').then((m) => m.HeatmapModule),
    canActivate: [EditionGuard],
    data: { feature: Features.Heatmap },
  },
  {
    path: 'admin-configuration',
    canActivate: [AdminPanelGuard],
    loadChildren: () => import('./containers/admin-configuration/admin-configuration.module').then((m) => m.AdminConfigurationModule),
  },
  {
    path: 'asset-correlation-static',
    loadChildren: () => import('./containers/asset-correlation-static/asset-correlation.module').then((m) => m.AssetCorrelationModule),
    canActivate: [EditionGuard],
    data: { feature: Features.AssetCorrelation },
  },
  {
    path: 'trading-log',
    loadChildren: () => import('./containers/trading-log/trading-log.module').then((m) => m.TradingLogModule),
    canActivate: [EditionGuard],
    data: { feature: Features.TradingLog },
  },
  {
    path: 'trading-panel',
    canActivate: [EditionGuard, TradingPanelGuard],
    loadChildren: () => import('./containers/trading-panel/trading-panel.module').then((m) => m.TradingPanelModule),
    data: { feature: Features.TradingPanel },
  },
  {
    path: 'import-trades',
    loadChildren: () => import('./containers/import-trades/import-trades.module').then((m) => m.ImportTradesModule),
    canActivate: [EditionGuard],
    data: { feature: Features.ImportTrades },
  },
  {
    path: 'wtf',
    canActivate: [EditionGuard],
    loadChildren: () => import('./containers/wtf/wtf.module').then((m) => m.WtfModule),
    data: { feature: Features.Wtf },
  },
  {
    path: 'editions',
    loadChildren: () => import('./containers/editions-demo/demo-pages.module').then((m) => m.DemoPagesModule),
  },
  {
    path: 'stock-screeners',
    loadChildren: () => import('./containers/stock-screener/stock-screener.module').then((m) => m.StockScreenerModule),
    canActivate: [EditionGuard],
    data: { feature: Features.StockScreeners },
  },
  {
    path: 'shorting-stocks',
    canActivate: [EditionGuard],
    loadChildren: () => import('./containers/short-selling-stocks/short-selling-stocks.module').then((m) => m.ShortSellingStocksModule),
    data: { feature: Features.ShortSellingStocks },
  },
  {
    path: 'shorting-stocks-scanner',
    canActivate: [EditionGuard],
    loadChildren: () => import('./containers/shorting-stocks-scanner/shorting-stocks-scanner.module')
      .then((m) => m.ShortingStocksScannerModule),
    data: { feature: Features.ShortingStocksScanner },
  },
  {
    path: 'video-hub',
    loadComponent: () => import('./containers/video-hub/video-hub.component').then((m) => m.VideoHubComponent),
    loadChildren: () => import('./containers/video-hub/video-hub.routes').then((m) => m.routes)
  },
  {
    path: 'dividends-strategy',
    canActivate: [EditionGuard],
    loadComponent: () => import('./containers/dividends-strategy/dividends-strategy-root.component')
      .then((m) => m.DividendsStrategyRootComponent),
    data: { feature: Features.DividendsStrategy },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
