import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { ObservableService } from '@s/observable.service';

@Injectable({
  providedIn: 'root'
})
export class TradingPanelGuard  {
  constructor(
    private _observableService: ObservableService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { tradier_flag_ifs, tradier_flag_pxo } = this._observableService.mySettings.getValue();
    return !!tradier_flag_ifs && !!tradier_flag_pxo;
  }
  
}
