<div class="pxo-main-div">
  <app-version-change-popup></app-version-change-popup>

  <app-header>
    <div
      rightNavContent
      class="hide-on-tablet-and-less"
    >
      <app-wheel-timer></app-wheel-timer>
    </div>
  </app-header>

  <app-wheel></app-wheel>
</div>
