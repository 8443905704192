import { TradingLogGroupType } from '@t/trading-log';
import { SortDirection } from '@angular/material/sort';

export enum PortfolioViewModes {
  Groups = 0,
  List = 1,
}

export interface IPortfolioTrade {
  id: string;
  accountId: string | null;
  strategyId: string | null;
  symbol: string | null;
  type: TradingLogGroupType;
  securityId: number | null;
  sector: string | null;
  sectorCode: number | null;
  industry: string | null;
  industryCode: number | null;
  company: string | null;
}

export interface IStatisticDetails {
  id: string;
  title: string;
  color: string;
  total: number;
  percentage: number;
}

export interface ITradesGroupStatistic {
  sectors: IStatisticDetails[];
  industries: IStatisticDetails[];
}

export interface IPortfolioTradesGroup {
  id: string;
  title: string;
  statistic: ITradesGroupStatistic;
  items: IPortfolioTrade[];
  viewState: {
    isGroupExpanded: boolean;
    isStatisticExpanded: boolean;
  };
}

export interface IPortfolioSettings {
  filters: {
    accounts: Array<string | null>;
    strategies: Array<string | null>;
  };
  sort: {
    column: string;
    direction: SortDirection;
  };
  viewMode: PortfolioViewModes;
  groupedTrades: Array<Pick<IPortfolioTradesGroup, 'id' | 'viewState'>>;
}
