import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import {
  VideoHubTabNames,
  VideoHubUrlKey,
  videoHubCategoryUrl,
  videoHubHomeUrl,
  videoHubPlayerUrl,
  videoHubTagUrl,
} from '@constants/video-hub.contants';
import { LocalStorageService } from '@s/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class VideoHubNavigationService {
  constructor(
    private location: Location,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  public async redirectToVideoHubTab(
    tabName: string,
    params?: string[],
    queryParams?: Params
  ): Promise<void> {
    const previousUrl = this.location.path(false);
    let navigatedUrl: UrlTree;

    if (tabName === VideoHubTabNames.VideoHubHome) {
      navigatedUrl = this.router.createUrlTree([videoHubHomeUrl]);
    }

    if (tabName === VideoHubTabNames.VideoHubPlayer) {
      navigatedUrl = this.router.createUrlTree([videoHubPlayerUrl, ...params], {
        queryParams,
      });
    }

    if (tabName === VideoHubTabNames.VideoHubCategory) {
      navigatedUrl = this.router.createUrlTree(
        [videoHubCategoryUrl, ...params],
        { queryParams }
      );
    }

    if (tabName === VideoHubTabNames.VideoHubTag) {
      navigatedUrl = this.router.createUrlTree([videoHubTagUrl, ...params], {
        queryParams,
      });
    }

    if (navigatedUrl) {
      await this.router.navigateByUrl(navigatedUrl);
      this.saveRouteState(previousUrl, navigatedUrl.toString());
    }
  }

  public async restoreTabFromStorage(): Promise<void> {
    const restoredUrl = this.localStorageService.get(VideoHubUrlKey);
    if (restoredUrl) {
      await this.router.navigateByUrl(restoredUrl);
    }
  }

  public setVideoHubTab(): void {
    const currentUrl = this.location.path(false);
    this.saveRouteState(null, currentUrl);
  }

  private saveRouteState(previousUrl: string, currentUrl: string): void {
    if (previousUrl !== currentUrl) {
      this.localStorageService.set(VideoHubUrlKey, currentUrl);
    }
  }
}
