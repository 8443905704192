<div class="quote-of-day">
  <mat-expansion-panel
    hideToggle
    [expanded]="isExpanded"
    (opened)="handleExpandedStateChange(true)"
    (closed)="handleExpandedStateChange(false)"
  >
    <mat-expansion-panel-header
      class="mat-expansion-panel-header"
      collapsedHeight="48px"
      expandedHeight="48px"
      (click)="areQuotesFresh = false"
    >
      <mat-panel-title class="quote-day-heading">
        <mat-icon [svgIcon]="'inspiration-icon'" [class.fresh-quotes]="areQuotesFresh"></mat-icon>
        <h3 class="inspiration-quote">Inspiration Quote</h3>
        <button mat-icon-button class="toggle-button close-open-item-btn component-btn secondary-bg">
          <span class="arrow-wrapper" [class.close-item]="isExpanded">
            <div class="close-open-arrow"></div>
          </span>
        </button>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="quote-day-body">
      <div class="inspiration-image-carousel">
        <div *ngFor="let quoteSrc of quoteOfDayImagesSrc;">
          <img src="{{ quoteSrc }}" class="inspiration-image" alt="Inspiration Quote" />
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
