import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, combineLatest, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { SmileyDataService } from '@s/smiley-data.service';
import { Flags, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { ISymbolFlagMenuItem } from '@c/shared/symbol-flag/symbol-flag.model';
import { defaultSymbolStatistic, flagIcons } from '@c/shared/symbol-flag/symbol-flag.data';

@Component({
  selector: 'app-symbol-flag-statistic-widget',
  templateUrl: './symbol-flag-statistic-widget.component.html',
  styleUrls: ['./symbol-flag-statistic-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolFlagStatisticWidgetComponent {
  @Input() public selectedFlag: Flags = Flags.None;

  @Input() public set securityId(value: number | null) {
    this.securityId$.next(value);
  }

  @Input() public set listType(value: SmileyListType | null) {
    this.listType$.next(value);
  }

  protected readonly securityId$ = new BehaviorSubject<number | null>(null);
  protected readonly listType$ = new BehaviorSubject<SmileyListType | null>(null);

  protected readonly flags = Flags;
  protected readonly flagIcons = flagIcons;

  protected readonly lastReceivedStatisticAndFlag$ = combineLatest([
    this.smileyDataService.showStatisticSettingAndHasAccess$.pipe(distinctUntilChanged()),
    this.smileyDataService.lastReceivedSmileyStatistics$.pipe(distinctUntilChanged(_.isEqual)),
    this.smileyDataService.lastRelevantFlags$.pipe(distinctUntilChanged(_.isEqual)),
    this.listType$.pipe(filter((value) => !!value)),
    this.securityId$.pipe(filter((value) => !!value)),
  ])
    .pipe(
      map(([showStatistic, statistic, lastRelevantFlags, listType, securityId]) => {
        if (!showStatistic || !listType || !securityId) {
          return {
            showStatistic: false,
            currentFlag: Flags.None,
            statistic: defaultSymbolStatistic,
          };
        }

        const currentFlag = lastRelevantFlags[listType][securityId] ?? Flags.None;

        return {
          showStatistic: showStatistic && currentFlag !== Flags.None,
          currentFlag,
          statistic: statistic[listType][securityId] ?? defaultSymbolStatistic,
        };
      }),
      startWith({
        showStatistic: false,
        currentFlag: Flags.None,
        statistic: defaultSymbolStatistic,
      })
    );

  // add/use specific for widget details
  protected readonly symbolFlags: ISymbolFlagMenuItem[] = [
    { id: Flags.No, flag: Flags.No, icon: this.flagIcons[Flags.No], label: 'No' },
    { id: Flags.Maybe, flag: Flags.Maybe, icon: this.flagIcons[Flags.Maybe], label: 'Maybe' },
    { id: Flags.Yes, flag: Flags.Yes, icon: this.flagIcons[Flags.Yes], label: 'Yes' },
    { id: Flags.Never, flag: Flags.Never, icon: this.flagIcons[Flags.Never], label: 'Never' },
  ];

  constructor(
    private smileyDataService: SmileyDataService,
  ) { }
}
