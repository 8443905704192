import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { UserDataService } from '@s/user-data.service';
import { ObservableService } from '@s/observable.service';
import { formatNumber } from '@const';
import { isNullOrUndefinedOrEmpty } from '@u/utils';
import { getRiskAmountPerTrade } from '@u/business-logic-utils';
import { PositionSizingModel } from '@mod/data/position-sizing.model';

@Component({
  selector: 'app-position-sizing-popup',
  templateUrl: './position-sizing-popup.component.html',
  styleUrls: ['./position-sizing-popup.component.scss']
})
export class PositionSizingPopupComponent implements OnInit {
  protected riskPositionTooltip = `
    Account Size - use cash (not margin available) for all account types, such as retirement, margin and others.\n
    Amount of Account Risk - You can use 2% rule by default or use Money Management to grow your account.
  `;

  protected showCanadaWarning = false;
  protected submitted = false;

  protected positionSettingForm = this.formBuilder.group({
    accountSize: [
      this.observableService[this.data.accountSizeSettingName]?.getValue() ?? '',
      [Validators.required, Validators.pattern('^[0-9,.]+'), Validators.minLength(4), Validators.maxLength(11)]
    ],
    accountRiskPercent: [
      this.observableService[this.data.accountRiskPercentSettingName]?.getValue() ?? '',
      [Validators.required, Validators.max(100)]
    ],
    riskAmountPerTrade: [
      this.observableService[this.data.accountRiskAmountSettingName]?.getValue() ?? '',
      [Validators.required, Validators.pattern('^[0-9,]+')]
    ]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: Partial<PositionSizingModel>,
    private dialogRef: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private userDataService: UserDataService,
    private observableService: ObservableService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.showCanadaWarning = this.data.showCanadaWarning;
    this.riskPositionTooltip = this.data.riskPositionTooltip ?? this.riskPositionTooltip;

    const riskAmountPerTradeInput = this.positionSettingForm.controls.riskAmountPerTrade;
    const accountSizeInput = this.positionSettingForm.controls.accountSize;

    riskAmountPerTradeInput.valueChanges.subscribe((newVal) => {
      const parsedVal = parseInt(newVal, 10);
      const accountSize = parseInt(accountSizeInput.value, 10);

      if (parsedVal > accountSize) {
        riskAmountPerTradeInput.setValue(accountSize);
      }
    });

    const [accountSize, accountRiskPercent] = await Promise.all([
      this.userDataService.getAsInt(this.data.accountSizeSettingName),
      this.userDataService.getAsFloat(this.data.accountRiskPercentSettingName)
    ]);

    this.positionSettingForm.controls.accountSize.setValue(accountSize);
    this.positionSettingForm.controls.accountRiskPercent.setValue(accountRiskPercent);

    this.setFocusAndSelectForInput('account-size-input');
    this.updateRiskAmountPerTrade();
  }

  protected async updateSetting(): Promise<void> {
    if (this.submitted) {
      return;
    }

    this.submitted = true;

    const accountSizeInput = this.positionSettingForm.controls.accountSize;
    const accountRiskPercentInput = this.positionSettingForm.controls.accountRiskPercent;
    const riskAmountPerTradeInput = this.positionSettingForm.controls.riskAmountPerTrade;

    if (accountSizeInput.invalid) {
      if (!!accountSizeInput.errors.minlength) {
        accountSizeInput.setValue(1000);
      } else {
        accountSizeInput.setValue(10000);
      }
    }

    if (accountRiskPercentInput.invalid || +accountRiskPercentInput.value < 0.1) {
      accountRiskPercentInput.setValue(2.0);
    }

    const accountSize = parseInt(accountSizeInput.value, 10);
    const accountRiskPercent = Math.trunc(parseFloat(accountRiskPercentInput.value) * 10) / 10;

    const accountRiskAmount = getRiskAmountPerTrade(accountSize, accountRiskPercent);

    if (riskAmountPerTradeInput.invalid) {
      riskAmountPerTradeInput.setValue(accountRiskAmount);
    }

    await this.userDataService.setMultiple([
      { key: this.data.accountSizeSettingName, value: accountSize },
      { key: this.data.accountRiskPercentSettingName, value: accountRiskPercent },
      { key: this.data.accountRiskAmountSettingName, value: accountRiskAmount }
    ]);

    this.dialogRef.closeAll();
  }

  protected updateRiskPercent(): void {
    const accountSize = parseInt(this.positionSettingForm.value.accountSize, 10);
    const riskAmountPerTrade = parseInt(this.positionSettingForm.value.riskAmountPerTrade, 10);
    let accountRiskPercent = Math.trunc(riskAmountPerTrade * 100.0 / accountSize * 10) / 10;

    if (isNaN(accountRiskPercent)) {
      accountRiskPercent = 0.0;
    }

    this.positionSettingForm.controls.accountRiskPercent.setValue(accountRiskPercent);
  }

  protected updateRiskAmountPerTrade(event: KeyboardEvent = null): void {
    if (event?.key === '.') {
      const el: HTMLInputElement = document.getElementById('percentInput') as HTMLInputElement;
      el.selectionStart = el.value.indexOf('.') + 1;
      el.selectionEnd = el.value.indexOf('.') + 1;
    }

    const accountSize = parseInt(this.positionSettingForm.value.accountSize, 10);
    const accountRiskPercent = parseFloat(this.positionSettingForm.value.accountRiskPercent);
    let riskAmountPerTrade = getRiskAmountPerTrade(accountRiskPercent, accountSize);

    if (isNaN(riskAmountPerTrade)) {
      riskAmountPerTrade = 0;
    }

    this.positionSettingForm.controls.riskAmountPerTrade.setValue(formatNumber(riskAmountPerTrade));
  }

  protected inputBlur(input: string): void {
    const inputValue = this.positionSettingForm.value[input];

    if (isNullOrUndefinedOrEmpty(inputValue)) {
      if (input === 'accountSize') {
        this.positionSettingForm.controls.accountSize.setValue(10000);
      }

      if (input === 'accountRiskPercent') {
        this.positionSettingForm.controls.accountRiskPercent.setValue(2.0);
        this.updateRiskAmountPerTrade();
      }

      if (input === 'riskAmountPerTrade') {
        this.positionSettingForm.controls.accountRiskPercent.setValue(2.0);
        this.updateRiskAmountPerTrade();
      }
    }
  }

  private setFocusAndSelectForInput(id: string): void {
    const element = document.getElementById(id) as HTMLInputElement;

    if (!element) {
      return;
    }

    element.focus();
    element.select();
  }
}
