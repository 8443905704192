import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { WithTradierIntegrationAbstract } from '@core/abstract/with-tradier-integration';
import { ExchangeModel } from '@mod/data/exchange.model';
import { ExchangesService } from '@s/exchanges.service';
import { ObservableService } from '@s/observable.service';
import { IPastPerformance, PastPerformanceService } from '@s/past-performance.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import { UserSettingsService } from '@s/user-settings.service';
import { formatPrice, getTradePositionSingularName, notEnoughDataText, TradePositions, UserSettings } from '@const';

import { PositionComparePopupComponent } from './position-compare-popup/position-compare-popup.component';

@Component({
  selector: 'app-data-window',
  templateUrl: './data-window.component.html',
  styleUrls: ['./data-window.component.scss'],
})
export class DataWindowComponent extends WithTradierIntegrationAbstract implements OnInit, OnDestroy {
  symbol: ISymbol;
  exchange: ExchangeModel;

  firstTradingStrategyId: number = null;
  secondTradingStrategyId: number = null;
  accountRiskAmount: any = null;

  firstTradingStrategy: any = {};
  secondTradingStrategy: any = {};

  firstTradingStrategyPastPerformance: IPastPerformance = null;
  secondTradingStrategyPastPerformance: IPastPerformance = null;

  tradePosition: TradePositions = TradePositions.LongAndShort;

  displayPositionClasses: [string, string] = [null, null];
  displayPosition: [string, string] = [null, null];

  forWidthSet;
  leftSideBg;
  rightSideBg;

  warningText = 'Please select a symbol.';

  hasHistoricalData = true;

  notEnoughDataText = notEnoughDataText;
  private _destroy$: Subject<boolean> = new Subject();
  private isChartInitialize;

  @Input() isPrint: boolean;

  constructor(
    public dialog: MatDialog,
    public observableService: ObservableService,
    private pastPerformanceService: PastPerformanceService,
    private userDataService: UserDataService,
    private tradingStrategiesService: TradingStrategiesService,
    public userSettingsService: UserSettingsService,
    private _exchangesService: ExchangesService,
    private _symbolService: SymbolsService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  async ngOnInit(): Promise<void> {
    await this.initTradierIntegration();

    this.observableService.isTradingChartInitialize$.subscribe((isInitialize) => this.isChartInitialize = isInitialize);
    this.tradePosition = this.observableService.tradePosition.getValue();
    const securityId = this.observableService.symbol.getValue();
    await this.setSymbolAndExchange(securityId);

    this.warningText = !this.symbol ? 'Please select a symbol.' : '';
    this.firstTradingStrategyId = this.observableService.firstTradingStrategyId.getValue();
    this.secondTradingStrategyId = this.observableService.secondTradingStrategyId.getValue();
    this.accountRiskAmount = this.observableService.accountRiskAmount.getValue();

    await Promise.all([this.loadTradingStrategyData(true), this.loadTradingStrategyData(false)]);

    this.observableService.symbol.subscribe(async (newSecurityId) => {
      await this.setSymbolAndExchange(newSecurityId);
      this.warningText = !newSecurityId ? 'Please select a symbol.' : '';

      await Promise.all([this.loadTradingStrategyData(true), this.loadTradingStrategyData(false)]);
    });

    this.observableService.firstTradingStrategyId.subscribe(async (firstTradingStrategyId) => {
      this.firstTradingStrategyId = firstTradingStrategyId;

      await this.loadTradingStrategyData(true);
    });

    this.observableService.secondTradingStrategyId.subscribe(async (secondTradingStrategyId) => {
      this.secondTradingStrategyId = secondTradingStrategyId;

      await this.loadTradingStrategyData(false);
    });

    this.observableService.accountRiskAmount.subscribe((accountRiskAmount) => (this.accountRiskAmount = accountRiskAmount));
    this.observableService.tradePosition.subscribe((tradePosition) => (this.tradePosition = tradePosition));
  }

  async loadTradingStrategyData(isFirst) {
    const tradingStrategyId = isFirst ? this.firstTradingStrategyId : this.secondTradingStrategyId;
    const [tradingStrategy, tradingStrategyPastPerformance] = await Promise.all([
      this.tradingStrategiesService.getById(tradingStrategyId),
      this.pastPerformanceService.get(this.symbol?.security_id, tradingStrategyId),
    ]);

    if (isFirst) {
      this.firstTradingStrategy = tradingStrategy;
      this.firstTradingStrategyPastPerformance = tradingStrategyPastPerformance;
      this.displayPositionClasses = [this.getDisplayPositionClass(true), this.displayPositionClasses[1]];
      this.displayPosition = [this.getDisplayPosition(true), this.displayPosition[1]];
    } else {
      this.secondTradingStrategy = tradingStrategy;
      this.secondTradingStrategyPastPerformance = tradingStrategyPastPerformance;
      this.displayPositionClasses = [this.displayPositionClasses[0], this.getDisplayPositionClass(false)];
      this.displayPosition = [this.displayPosition[0], this.getDisplayPosition(false)];
    }
    this.hasHistoricalData = !!tradingStrategyPastPerformance?.has_enough_data;
  }

  positionCompare() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['position-compare', 'modals'];
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PositionComparePopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (popupResult) => {
      if (popupResult) {
        const { firstTradingStrategyId, secondTradingStrategyId } = popupResult;

        const promises = [];
        if (this.firstTradingStrategyId !== firstTradingStrategyId) {
          promises.push(this.userDataService.set(UserSettings.FirstTradingStrategyId, firstTradingStrategyId));
        }

        if (this.secondTradingStrategyId !== secondTradingStrategyId) {
          promises.push(this.userDataService.set(UserSettings.SecondTradingStrategyId, secondTradingStrategyId));
        }

        if (this.firstTradingStrategyId !== firstTradingStrategyId && this.secondTradingStrategyId !== secondTradingStrategyId) {
          promises.push(this.userDataService.set(UserSettings.TradeReportTradingStrategyId, firstTradingStrategyId));
        }

        if (promises.length) {
          await Promise.all(promises);
        }
        if (!this.isChartInitialize) {
          await this.userDataService.set(UserSettings.ShowEntryAndExitOption, 'None');
          await this.userDataService.set(UserSettings.ShowTradeOption, 'None');
        }
      }
    });
  }

  getDisplayPositionClass(isFirst) {
    const pastPerformance = isFirst ? this.firstTradingStrategyPastPerformance : this.secondTradingStrategyPastPerformance;

    if (pastPerformance?.position) {
      return pastPerformance.position === TradePositions.LongOnly ? 'long' : 'short';
    }

    return 'none';
  }

  getDisplayPosition(isFirst) {
    const pastPerformance = isFirst ? this.firstTradingStrategyPastPerformance : this.secondTradingStrategyPastPerformance;

    if (pastPerformance?.position) {
      return `${getTradePositionSingularName(pastPerformance.position)} ${formatPrice(
        pastPerformance.entry_price,
        this.exchange.profit_scale
      )}`;
    }

    return 'NONE';
  }

  private async setSymbolAndExchange(securityId: number): Promise<void> {
    this.symbol = await this._symbolService.getById(securityId);
    this.exchange = await this._exchangesService.getById(this.symbol?.exchange_id);
  }
}
