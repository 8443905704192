import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

// can be used only for textarea with one row (or adapted for input)
@Directive({
  standalone: true,
  selector: '[appTextareaFitContentWidth]'
})
export class TextareaFitContentWidthDirective implements AfterViewInit, OnDestroy {
  private shadowCopy: HTMLTextAreaElement | null = null;

  private additionalSpaceForCalculationInPX = 2;
  private shadowCopyDefaultStyles = {
    position: 'absolute',
    top: '-420px',
    left: '-420px',
    visibility: 'hidden',
    width: '10px',
  };

  constructor(
    private element: ElementRef<HTMLTextAreaElement>,
  ) {
    element.nativeElement.style['white-space'] = 'nowrap';
    element.nativeElement.style.overflow = 'auto';

    element.nativeElement.oninput = () => {
      this.onChangeContent();
    };

    element.nativeElement.onpaste = () => {
      setTimeout(() => {
        this.onChangeContent();
      }, 100);
    };

    element.nativeElement.oncut = () => {
      this.onChangeContent();
    };

    element.nativeElement.onchange = () => {
      this.onChangeContent();
    };
  }

  ngAfterViewInit() {
    this.shadowCopy = this.element.nativeElement.cloneNode(true) as HTMLTextAreaElement;

    this.shadowCopy.id = null;
    this.shadowCopy.style.position = this.shadowCopyDefaultStyles.position;
    this.shadowCopy.style.top = this.shadowCopyDefaultStyles.top;
    this.shadowCopy.style.left = this.shadowCopyDefaultStyles.left;
    this.shadowCopy.style.visibility = this.shadowCopyDefaultStyles.visibility;
    this.shadowCopy.style.width = this.shadowCopyDefaultStyles.width;

    this.element.nativeElement.parentElement.appendChild(this.shadowCopy);

    const newTextareaWidthInPX = this.shadowCopy.scrollWidth + this.additionalSpaceForCalculationInPX;
    this.element.nativeElement.style.width = newTextareaWidthInPX + 'px';
  }

  ngOnDestroy() {
    if (this.shadowCopy) {
      this.element.nativeElement.parentElement.removeChild(this.shadowCopy);
    }
  }

  private onChangeContent(): void {
    if (!this.shadowCopy) {
      return;
    }

    this.shadowCopy.value = this.element.nativeElement.value;
    this.element.nativeElement.style.width = this.shadowCopy.scrollWidth + 'px';
  }
}
