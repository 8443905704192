import { ITask, TaskStatuses } from '@c/trading-hub/trading-hub.model';
import { round } from '@u/utils';

// If results < 1%, then show 1%;
// If 1% <=results <100% then take the smallest INT. Example: 1.99 => 1%; 20.45 => 20% 99.99 => 99%
export const calculateProgress = (tasks: ITask[] | null): number => {
  if (!tasks || tasks.length === 0) {
    return 0;
  }

  const completedTasks = tasks.filter((item) => item.status === TaskStatuses.completed);

  if (completedTasks.length === 0) {
    return 0;
  }

  if (completedTasks.length === tasks.length) {
    return 100;
  }

  const progress = round((completedTasks.length / tasks.length) * 100, 4);

  if (progress < 1) {
    return 1;
  }

  return Math.floor(progress);
};
