import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocusAndSelect]',
  standalone: true,
})
export class AutoFocusAndSelectDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;

      if (element && element instanceof HTMLInputElement) {
        element.focus();
        element.select();
      }
    });
  }
}
