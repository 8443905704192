import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ExchangeCountriesCodes, formatDecimalExt, isNullOrUndefined } from '@const';
import { TradierOrderSide, TradingPanelClientEvent } from '@mod/trading-panel/trading-panel.model';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';
import { ObservableService } from '@s/observable.service';
import { ProcessedDataService } from '@s/processed-data.service';
import { ISymbol } from '@s/symbols.service';
import { StreamingService } from '@s/streaming.service';

@Component({
  standalone: true,
  selector: 'app-price-ticker',
  templateUrl: './price-ticker.component.html',
  imports: [
    MatButtonToggleModule,
    NgIf,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  styleUrls: ['./price-ticker.component.scss']
})
export class PriceTickerComponent implements OnInit, OnDestroy {
  @Input() orderInput: TradingOrderInput;

  static EmptyPriceValue = '--';

  protected isLoading = false;
  protected ask: string = null;
  protected bid: string = null;
  protected last: string = null;
  protected isBuy: boolean;

  private _symbol: ISymbol;
  private _subscriptionId;
  private _updateLiveDataStatusTimeout = null;
  private _subscriptions = new Subscription();

  constructor(
    private _observableService: ObservableService,
    private _processedDataService: ProcessedDataService,
    private _streamingService: StreamingService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.initialize();

    this._subscriptions.add(
      this._observableService.tradingPanelClientEvent.subscribe(async (clientEvent: TradingPanelClientEvent) => {
        if (clientEvent === TradingPanelClientEvent.OrderInputDataUpdated) {
          this.orderInput = this._observableService.tradingPanelOrderInput.getValue();
          await this.initialize();
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this._subscriptionId) {
      this._streamingService.unsubscribe(this._subscriptionId, this._symbol);
    }

    if (this._updateLiveDataStatusTimeout) {
      clearTimeout(this._updateLiveDataStatusTimeout);
    }

    this._subscriptions.unsubscribe();

    this._observableService.tradingPanelPricesLoaded.next(false);
    this._observableService.tradingPanelHasPrices.next(true);
  }

  private async initialize() {
    if (!this.orderInput) {
      return;
    }

    if (this._updateLiveDataStatusTimeout) {
      clearTimeout(this._updateLiveDataStatusTimeout);
    }

    if (this._subscriptionId && this._symbol) {
      this._streamingService.unsubscribe(this._subscriptionId, this._symbol);
    }

    this.isLoading = true;
    this.ask = '';
    this.bid = '';
    this.last = '';
    this.isBuy = this.orderInput.side === TradierOrderSide.Buy
      || this.orderInput.side === TradierOrderSide.BuyToClose
      || this.orderInput.side === TradierOrderSide.BuyToCover
      || this.orderInput.side === TradierOrderSide.BuyToOpen;

    const processedData = this.orderInput.symbol?.security_id
      ? await this._processedDataService.get(this.orderInput.symbol?.security_id)
      : null;
    this._subscriptionId = uuidv4();
    this._symbol = this.orderInput.symbol
      ? this.orderInput.symbol
      : {
        description: null,
        exchange_code: null,
        exchange_id: null,
        exchange_name: null,
        last_updated: null,
        security_id: null,
        type: 'stock',
        symbol: this.orderInput.option.symbol,
        country_code: ExchangeCountriesCodes.US
      };

    this._observableService.tradingPanelPricesLoaded.next(false);
    this._observableService.tradingPanelHasPrices.next(true);

    this._streamingService.subscribe(
      this._subscriptionId,
      this._symbol,
      processedData,
      this.handleLiveDataCallback.bind(this),
      this.handleLiveDataCallback.bind(this)
    );

    this._updateLiveDataStatusTimeout = setTimeout(() => {
      this.isLoading = false;
      this._observableService.tradingPanelPricesLoaded.next(true);
      this.updateTradePriceStatus();
    }, 5 * 1000);
  }

  private handleLiveDataCallback(data): void {
    if (!data || data.symbol !== this._symbol?.symbol) {
      return;
    }

    const { ask, bid, close, isPreMarket, isPostMarket } = data;

    if (isNullOrUndefined(ask) && isNullOrUndefined(bid) && isNullOrUndefined(close)) {
      return;
    }

    this.isLoading = false;
    this._observableService.tradingPanelPricesLoaded.next(true);

    this.ask = !isNullOrUndefined(ask) && ask !== 0
      ? formatDecimalExt(ask, 0, 2)
      : PriceTickerComponent.EmptyPriceValue;
    this.bid = !isNullOrUndefined(bid) && bid !== 0
      ? formatDecimalExt(bid, 0, 2)
      : PriceTickerComponent.EmptyPriceValue;

    if (!isPreMarket && !isPostMarket) {
      this.last = !isNullOrUndefined(close) && close !== 0
        ? formatDecimalExt(close, 0, 3)
        : PriceTickerComponent.EmptyPriceValue;
    }

    if (this.getHasPrices()) {
      if (this._updateLiveDataStatusTimeout) {
        clearInterval(this._updateLiveDataStatusTimeout);
      }

      this.updateTradePriceStatus();
    }
  }

  private getHasPrices() {
    const hasPrices = !!this.ask?.replace(PriceTickerComponent.EmptyPriceValue, '')
      || !!this.bid?.replace(PriceTickerComponent.EmptyPriceValue, '')
      || !!this.last?.replace(PriceTickerComponent.EmptyPriceValue, '');

    return hasPrices;
  }

  private updateTradePriceStatus() {
    const hasPrices = this.getHasPrices();

    if (hasPrices !== this._observableService.tradingPanelHasPrices.getValue()) {
      this._observableService.tradingPanelHasPrices.next(hasPrices);
    }
  }
}
