import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-editable-title',
  templateUrl: './editable-title.component.html',
  styleUrls: ['./editable-title.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatInputModule,
    NgClass,
    NgIf
  ]
})
export class EditableTitleComponent {
  @Input() id: string | number;
  @Input() maxLength = 100;

  @Input() set value(value: string) {
    this.initialValue = value.trim();
    this.editedValue = value.trim();
  }

  @Input() set editMode(value: boolean) {
    this.isEditModeActive = value;

    if (value) {
      this.initEditProcess();
    }
  }

  @Output() valueChanged = new EventEmitter<string>();
  @Output() editFinished = new EventEmitter<string>();

  protected initialValue = '';
  protected editedValue = '';

  protected isEditModeActive = false;

  constructor() { }

  protected onFocusOut(): void {
    this.exitEditMode(true);
  }

  protected onChangeSectionTitle(value: string): void {
    this.editedValue = value;
  }

  protected exitEditMode(saveChanges: boolean = false): void {
    const newValue = this.editedValue.trim();

    if (saveChanges && newValue.length > 0) {
      this.initialValue = newValue;
      this.valueChanged.emit(newValue);
    }

    if (!saveChanges || newValue.length === 0) {
      this.editedValue = this.initialValue;
    }

    this.editedValue = this.editedValue.trim();
    this.editFinished.emit(this.editedValue.trim());
  }

  protected initEditProcess() {
    this.editedValue = this.initialValue.trim();

    setTimeout(() => {
      const element = document.getElementById(`title-textarea_${this.id}`) as HTMLTextAreaElement | null;

      if (element) {
        element.focus();
        element.setSelectionRange(0, this.editedValue.length);
      }
    }, 100);
  }
}
