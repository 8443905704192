import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseAgreementTextComponent } from './license-agreement-text.component';
@NgModule({
  declarations: [LicenseAgreementTextComponent],
  imports: [
    CommonModule
  ],
  exports: [LicenseAgreementTextComponent]
})
export class LicenseAgreementTextModule { }
