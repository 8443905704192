import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { IWatchlistItem } from '@mod/watchlist/watchlist.model';
import {
  DataWindowUpdateSource,
  IDataWindowUpdateCommand,
  ISymbolDetailsV2,
  IWheelStockOptionRaw,
} from '@t/wheel/wheel.types';
import { UserSettings } from '@const';

@Injectable()
export class WheelService {
  public lastReceivedWatchlist$ = new ReplaySubject<IWatchlistItem[]>(1);
  public updateWatchlist$ = new Subject<void>();
  public optionsForMinStrike$ = new BehaviorSubject<IWheelStockOptionRaw[]>([]);

  public updateStockList$ = new Subject<void>();
  public updateDataWindowFromScanner$ = new Subject<void>();

  public restartTimer$ = new Subject<void>();
  public isTimerActive$ = new ReplaySubject<boolean>(1);
  public lastScannerUpdateDate$ = new ReplaySubject<string | null>(1);
  public lastReceivedSecurityDetails$ = new ReplaySubject<Record<number, ISymbolDetailsV2> | null>(1);
  public updateDataWindowSymbol$ = new ReplaySubject<IDataWindowUpdateCommand>(1);

  private _triggerToSaveCalc$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private wheelCalcDataSub$: Subject<any> = new Subject();
  public wheelCalcData$: Observable<any>;

  private wheelDataWindSub$: Subject<any[]> = new Subject();
  public wheelDataWind$: Observable<any[]>;

  constructor(
    private userDataService: UserDataService,
    private observableServiceV2: ObservableServiceV2,
  ) {
    this.wheelCalcData$ = this.wheelCalcDataSub$.asObservable();
    this.wheelDataWind$ = this.wheelDataWindSub$.asObservable();
  }

  setWheelCalcData(data: any): void {
    this.wheelCalcDataSub$.next(data);
  }

  setWheelDataWindow(data: any[]): void {
    this.wheelDataWindSub$.next(data);
  }

  public getTriggerToSaveCalc$(): Observable<boolean> {
    return this._triggerToSaveCalc$.asObservable();
  }

  public onTriggerToSaveCalc$(value: boolean): void {
    this._triggerToSaveCalc$.next(value);
  }

  public async setSymbolForWheelPage({ symbol, security_id }: { symbol: string, security_id: number }): Promise<void> {
    this.optionsForMinStrike$.next([]);
    this.updateDataWindowSymbol$.next({
      symbol: { id: security_id, name: symbol },
      source: DataWindowUpdateSource.ChartSearchBar
    });

    this.observableServiceV2.wheelSymbol.next(security_id);
    this.observableServiceV2.wheelDataWindowUpdateSource.next(DataWindowUpdateSource.ChartSearchBar);
    this.observableServiceV2.isWheelCalculator.next(false);
    this.observableServiceV2.isWheelPremiums.next(false);

    await Promise.all([
      this.userDataService.set(UserSettings.WheelSymbol, security_id),
      this.userDataService.set(UserSettings.IsWheelCalculator, false),
      this.userDataService.set(UserSettings.IsWheelPremiums, false),
      this.userDataService.set(UserSettings.WheelDataWindowUpdateSource, DataWindowUpdateSource.ChartSearchBar),
    ]);
  }
}
