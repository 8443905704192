import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { SolSignalAlertDataModel, SolSignalNotificationContentModel } from '@c/sol-signal-alert/sol-signal-alert.model';
import { ExchangeCountriesCodes, TabNames, UserSettings } from '@const';
import { DialogsService } from '@s/common';
import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { isNullOrUndefinedOrEmpty } from '@u/utils';

@Component({
  selector: 'app-sol-signal-alert',
  templateUrl: './sol-signal-alert.component.html',
  styleUrl: './sol-signal-alert.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule],
})
export class SolSignalAlertComponent {
  protected currentNotificationIndex = 0;

  constructor(
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private dialogsService: DialogsService,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private ref: MatSnackBarRef<SolSignalAlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) protected data: SolSignalAlertDataModel,
  ) {}

  protected async onClickSymbol({ symbol, securityId }: SolSignalNotificationContentModel): Promise<void> {
    this.dialogsService.closeAll();

    const currentTab = this.observableService.activeTab.getValue();
    const currentSolSecurityId = this.observableService.shortSellingStocksSymbol.getValue();

    // check the selected symbol before applying it
    const newSymbol = !isNullOrUndefinedOrEmpty(securityId)
      ? await this.symbolsService.getById(securityId)
      : await this.symbolsService.getBySymbol(symbol, ExchangeCountriesCodes.US);
    const newSecurityId = newSymbol?.security_id ?? currentSolSecurityId;

    this.observableService.shortSellingStocksSymbol.next(newSecurityId);

    if (currentTab !== TabNames.ShortSellingStocks) {
      this.navigationService.redirectToTab(TabNames.ShortSellingStocks, {}, newSecurityId);
    } else {
      this.userDataService.set(UserSettings.ShortSellingStocksSymbol, newSecurityId);
    }

    if (this.data.notifications.length === 1) {
      this.ref.dismissWithAction();
    }
  }

  protected close(): void {
    this.ref.dismiss();
  }

  protected onClickNext(): void {
    this.currentNotificationIndex = this.currentNotificationIndex === this.data.notifications.length - 1
      ? this.data.notifications.length - 1
      : this.currentNotificationIndex + 1;

    this.changeDetectorRef.markForCheck();
  }

  protected onClickPrev(): void {
    this.currentNotificationIndex = this.currentNotificationIndex === 0
      ? 0
      : this.currentNotificationIndex - 1;

    this.changeDetectorRef.markForCheck();
  }
}
