import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PrintDataComponent } from '@c/print-data/print-data.component';
import { SharedModule } from '@sh/shared.module';
import { MainV2Module } from '@m/main-v2.module';
import { TradingHubSharedModule } from '@c/trading-hub/trading-hub-shared.module';

@NgModule({
  declarations: [PrintDataComponent],
  exports: [
    PrintDataComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SharedModule,
    MainV2Module,
    TradingHubSharedModule
  ]
})
export class PrintDataModule { }
