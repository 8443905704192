<div class="metric-details-modal" [class.isStocks]="this.data.isStocks">
  <div mat-dialog-title class="modal-header" [class.header-shadow]="isDisplayHeaderShadow">
    <div class="metric-details-modal-header">
      <span class="header-static-part">Trades for</span>
      <span class="header-particular-part">
        {{data.name}}
        </span>
      <div class="toggle-container">
        <span>Show all notes</span>
        <mat-slide-toggle [checked]="showAllNotesToggleState()" (change)="changeAllNotesToggleState()"></mat-slide-toggle>
      </div>
    </div>

    <button mat-icon-button mat-dialog-close class="popup-close-btn " tabindex="-1">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon"></mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div
      #topIndicator
      class="top-indicator"
      inViewport
      [inViewportOptions]="{ threshold: [0], partial: true }"
      (inViewportAction)="onChangeVisibility($event, 'top')"
    ></div>

    <div class="trade-groups-container">
      <ng-container *ngIf="currentPageTrades?.active?.length">
        <div class="trade-groups-label">Active</div>
        <ng-container *ngTemplateOutlet="groups; context: { $implicit: currentPageTrades.active }"></ng-container>
      </ng-container>

      <ng-container *ngIf="currentPageTrades?.archived?.length">
        <div class="trade-groups-label">Archived</div>
        <ng-container *ngTemplateOutlet="groups; context: { $implicit: currentPageTrades.archived }"></ng-container>
      </ng-container>
    </div>

    <div
      class="bottom-indicator"
      inViewport
      [inViewportOptions]="{ threshold: [0], partial: true }"
      (inViewportAction)="onChangeVisibility($event, 'bottom')"
    ></div>
  </div>

  <div class="modal-footer" [class.footer-shadow]="isDisplayFooterShadow">

  </div>
</div>

<ng-template #groups let-groups>
  @for (group of groups; track group.id) {
    @defer (on viewport) {
      <div  class="trade-group-item">

        <div class="trade-group-item-header">
          <div class="trade-group-item-title">{{group.symbol}}</div>
          <div class="trade-group-item-dates">
            <span class="date-start">{{getFormattedDate(group.min_date)}}<span *ngIf="group.max_date"> - </span></span>
            <span *ngIf="group.max_date" class="date-end">{{getFormattedDate(group.max_date)}}</span>
          </div>

          <div class="trade-group-item-info">
            <div class="info-item">{{group.account_name || 'Account'}}</div>
            <div class="info-item">{{group.strategy_name || 'Strategy'}}</div>
          </div>
        </div>

        <div class="trade-group-item-content scroll-style">
          <table class="table-styles table-main-style">
            <thead>
              <tr>
                <th class="type text-left">Type</th>
                <th class="date text-left">Open Date</th>
                <ng-container *ngIf="!this.data.isStocks">
                  <th class="expiration text-left">Expiration</th>
                  <th class="strike text-right">Strike</th>
                </ng-container>
                <th class="shares text-right">{{this.data.isStocks ? 'Shares' : 'Shares/Contr'}}</th>
                <th class="price text-right">{{this.data.isStocks ? 'Price' : 'Price/Prem'}}</th>
                <th class="fees text-right">Fees & Comm</th>
                <th class="date text-left">Close Date</th>
                <th class="realized-pl text-right" [matTooltip]="'Realized P&L without fees and commissions'">Trade P&L</th>
                <th class="realized-pl text-right" [matTooltip]="'Realized P&L with fees and commissions'">Total P&L</th>
                <th class="notes">Notes</th>
                <th class="status"><!--Actions--></th>
              </tr>
            </thead>
            <tbody>
              @for (transaction of group.usedTransactions; track transaction.transaction_id) {
                  <tr >
                    <td class="type text-left">
                      <ng-container *ngTemplateOutlet="transactionType; context: { $implicit: getTransactionDisplayType(transaction.transaction_type) }"></ng-container>
                    </td>
                    <td class="date text-left">
                      <span class="cell-content">{{getFormattedDateByTimestamp(transaction.timestamp) + ' ' + getFormattedTime(transaction.timestamp)}}</span>
                    </td>
                    <ng-container *ngIf="!data.isStocks">
                      <td class="expiration text-left">
                        <span class="cell-content">{{transaction.expiration ? getFormattedDateByTimestamp(transaction.expiration) : ''}}</span>
                      </td>
                      <td class="strike text-right">
                        <span class="cell-content">{{getFormattedDecimalNumber(transaction.strike, 2, 20)}}</span>
                      </td>
                    </ng-container>
                    <td class="shares text-right">
                      <span class="cell-content">{{getFormattedNumber(transaction.qty)}}</span>
                    </td>
                    <td class="price text-right">
                      <span class="cell-content">{{getFormattedDecimalNumber(transaction.price, 2, 20)}}</span>
                    </td>
                    <td class="fees text-right">
                      <span
                          class="cell-content"
                          [class.green]="transaction.commissions < 0"
                          [class.red]="transaction.commissions > 0"
                      >
                        {{getFormattedDecimalNumber(transaction.commissions, 2, 20)}}
                      </span>
                    </td>
                    <td class="date text-left">
                      <span *ngIf="transaction.close_timestamp" class="cell-content">{{getFormattedDateByTimestamp(transaction.close_timestamp) + ' ' + getFormattedTime(transaction.close_timestamp)}}</span>
                    </td>
                    <td class="realized-pl text-right">
                      <span
                          *ngIf="transaction.close_timestamp"
                          class="cell-content"
                          [class.green]="transaction.closed_profit > 0"
                          [class.red]="transaction.closed_profit < 0"
                      >
                        {{getFormattedDecimalNumber(transaction.closed_profit, 2, 20)}}
                      </span>
                    </td>
                    <td class="realized-pl text-right">
                      <span
                          *ngIf="transaction.close_timestamp || transaction.commissions"
                          class="cell-content"
                          [class.green]="(transaction.closed_profit - transaction.commissions) > 0"
                          [class.red]="(transaction.closed_profit - transaction.commissions) < 0"
                      >
                        {{getFormattedDecimalNumber(money.subtract(transaction.closed_profit, transaction.commissions), 2, 20)}}
                      </span>
                    </td>
                    <td class="notes text-left">
                      @if (isNotesCanBeShownForTransaction(transaction)){
                        <div class="notes-container" (click)="toggleDetailsRowVisibility(transaction.transaction_id)">
                          <mat-icon [svgIcon]="'notes-icon'"></mat-icon>
                        </div>
                      }
                      @else {
                        <div class="notes-container empty">
                          -
                        </div>
                      }
                    </td>
                    <td class="status text-left">
                      <div *ngIf="transaction.transaction_status" class="status-labels">
                        <span class="status-label">{{getTransactionStatusText(transaction.transaction_status)}}</span>
                      </div>
                    </td>
                  </tr>
                  <tr class="details-row">
                    <td class="text-left details" colspan="100%">
                      <div class="details-container" [class.open]="transactionDisplayNotesState()[transaction.transaction_id]">
                        <div class="details-container-item reason">
                          <div class="label">Reason/confidence</div>
                          <div class="text no-text-transform">
                            @if (transaction.confidence || transaction.decision){
                              <app-feelings-panel [showChangeIcon]="false"
                                [selectedDecisionId]="transaction.decision"
                                [selectedConfidenceId]="transaction.confidence">
                              </app-feelings-panel>
                            }
                            @else {
                              <span>-</span>
                            }
                          </div>
                        </div>
                        <div class="details-container-item notes">
                          <div class="label">Note</div>
                          <div class="text">{{transaction.note ? transaction.note : '-'}}</div>
                        </div>
                      </div>
                    </td>
                  </tr>

              }
            </tbody>
          </table>
        </div>
      </div>
    } @placeholder (minimum 500ms){
      <div class="trade-group-item loading" [style.height]="group.usedTransactions?.length ? (group.usedTransactions?.length * 30) + 76 + 'px' : '300px'">
        <div class="spinner-container">
          <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
        </div>
      </div>
    }
  }
</ng-template>

<ng-template #transactionType let-transactionDisplayType>
  <span *ngIf="transactionDisplayType.method" [ngClass]="transactionDisplayType.method">{{transactionDisplayType.method}} </span>{{transactionDisplayType.assetType}}
</ng-template>
