<div class="trading-report" id="tradeReport">
  <!-- for web view -->
  <app-trading-report-web *ngIf="!isMobile && !isPrint"></app-trading-report-web>
  <!-- for mobile view -->
  <app-trading-report-mobile *ngIf="isMobile && !isPrint"></app-trading-report-mobile>

  <div *ngIf="isPrint">
    <app-print-profits class="page-break"
      [strategyTrades]="firstStrategyTrades"
      [tradingStrategy]="firstTradingStrategy"
      [isReportFitOnPrintScreen]="isReportFitOnPrintScreen"
    ></app-print-profits>
    <app-print-profits class="page-break"
      [strategyTrades]="secondStrategyTrades"
      [tradingStrategy]="secondTradingStrategy"
      [isReportFitOnPrintScreen]="isReportFitOnPrintScreen"
    ></app-print-profits>
  </div>

</div>
