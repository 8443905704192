<h2 mat-dialog-title>
  {{ titleText }}
  <mat-icon *ngIf="tooltipText"
            [svgIcon]="'hint_icon'"
            [matTooltip]="tooltipText"
            class="header-help-icon small-icon"
            [matTooltipHideDelay]="0"
            [matTooltipPosition]="'below'">
  </mat-icon>
  <button mat-icon-button mat-dialog-close (click)="close.emit()" class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon">
    </mat-icon>
  </button>
</h2>
