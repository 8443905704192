import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { RestRequestorService } from './rest-requestor.service';
import { MorningStarSectorMap, MorningStarSectorResponse } from '@mod/data/morning-star.model';
import { map } from 'rxjs/operators';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class MorningStarSectorService {

  constructor(
    private _http: HttpClient,
    private _restRequestorService: RestRequestorService
  ) {
  }

  public get(): Observable<MorningStarSectorMap> {
    return from(this._restRequestorService.makeRequest(
      `morningStarSector-${moment().format('YYYY-MM-DD')}`,
      () => this._http.get<MorningStarSectorResponse>(`/v2/morningStarSector`).pipe(
        map((response) => {
          const sectorMap = new Map<number, string>();
          response.result.forEach(({ code, name }) => sectorMap.set(code, name));
          return { ...response, result: sectorMap };
        })
      ).toPromise()
    )).pipe(map(({ result }) => result));
  }
}
