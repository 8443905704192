import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EditionsService } from '@s/editions.service';
import { Features } from '@const';

/**
 * Adds class to element if feature is not available for user.
 *
 * ### Simple example:
 * ```
 *   <some-element [appFeatureAvailability]="features.ExpectedMove">...</some-element>
 * ```
 *
 * ### Example with custom class:
 * ```
 *   <some-element
 *     [appFeatureAvailability]="features.ExpectedMove"
 *     [unavailableFeatureClass]="'locked-for-current-edition'"
 *   >
 *     ...
 *   </some-element>
 * ```
 *
 * @param appFeatureAvailability Feature's title (from enum).
 * @param unavailableFeatureClass Class to add if feature is unavailable for user. Default value: "locked-for-current-edition"
 *
 * @ngModule EditionsSharedModule
 */
@Directive({
  selector: '[appFeatureAvailability]'
})
export class FeatureAvailabilityDirective implements OnInit, OnChanges {
  @Input() appFeatureAvailability: Features = null;
  @Input() unavailableFeatureClass = 'locked-for-current-edition';

  constructor(
    private editionsService: EditionsService,
    private element: ElementRef<HTMLElement>,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.updateState();
  }

  ngOnInit() {
    this.updateState();
  }

  private updateState(): void {
    const isAvailable = this.editionsService.isFeatureAvailable(this.appFeatureAvailability);
    const hasUnavailableFeatureClass = this.element.nativeElement.classList.contains(this.unavailableFeatureClass);

    if (isAvailable && hasUnavailableFeatureClass) {
      this.element.nativeElement.classList.remove(this.unavailableFeatureClass);
    }

    if (!isAvailable && !hasUnavailableFeatureClass) {
      this.element.nativeElement.classList.add(this.unavailableFeatureClass);
    }
  }
}
