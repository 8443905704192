<div *ngIf="warningText" [innerHTML]="warningText" class="data-warning"></div>
<div *ngIf="!warningText" class="data-window">
  <div class="warning-message" *ngIf="!hasHistoricalData" [innerHTML]="notEnoughDataText"></div>
  <div class="data-window-header">
    <div class="conservative-header">
      <div class="cos-name">
        <p>#1. {{ firstTradingStrategy?.name }}</p>
      </div>
      <div class="compare-btn" *ngIf="!isPrint">
        <button mat-button (click)="positionCompare()">Compare</button>
      </div>
      <div class="rr">
        <p>#2. {{ secondTradingStrategy?.name }}</p>
      </div>
    </div>
    <div class="position-header row">
      <div [ngClass]="displayPositionClasses[0]">
        {{ displayPosition[0] }}
      </div>
      <div class="position">POSITION</div>
      <div [ngClass]="displayPositionClasses[1]">
        {{ displayPosition[1] }}
      </div>
    </div>
  </div>
  <!-- 48px is a counted height of warning-message, 16px is a (margin-top (8) + margin-bottom (8)) -->
  <!-- 54px is a counted height of data-window-header -->
  <div class="scroll-data" [style.height]="!hasHistoricalData ? 'calc(100% - (48px + 54px + 16px))' : 'calc(100% - 54px)'">
    <div class="position-data row" id="position-data">
      <div class="col p-8_5 p-t-6">
        <app-current-position-symbol
          [signal]="firstTradingStrategyPastPerformance"
          [symbol]="symbol"
          [accountRiskAmount]="accountRiskAmount"
          [tradingStrategy]="firstTradingStrategy"
          [tradingStrategyToCompare]="secondTradingStrategy"
          [placeOrderCanBeShown]="showPlaceOrderButtons"
          [exchange]="exchange"
          [isPrint]="isPrint"
        >
        </app-current-position-symbol>
      </div>

      <div class="col p-8_5 p-t-6">
        <app-current-position-symbol
          [signal]="secondTradingStrategyPastPerformance"
          [symbol]="symbol"
          [accountRiskAmount]="accountRiskAmount"
          [tradingStrategy]="secondTradingStrategy"
          [tradingStrategyToCompare]="firstTradingStrategy"
          [placeOrderCanBeShown]="showPlaceOrderButtons"
          [exchange]="exchange"
          [isPrint]="isPrint"
        >
        </app-current-position-symbol>
      </div>
      <div class="card-title text-center w-100">ROI & TOTAL PROFIT</div>
      <div class="col p-8_5">
        <app-roi-total-profit
          [pastPerformance]="firstTradingStrategyPastPerformance"
          [pastPerformanceToCompare]="secondTradingStrategyPastPerformance"
          [tradePosition]="tradePosition"
        >
        </app-roi-total-profit>
      </div>
      <div class="col p-8_5">
        <app-roi-total-profit
          [pastPerformance]="secondTradingStrategyPastPerformance"
          [pastPerformanceToCompare]="firstTradingStrategyPastPerformance"
          [tradePosition]="tradePosition"
        >
        </app-roi-total-profit>
      </div>

      <div class="card-title text-center w-100">WIN % & PROFIT FACTOR</div>
      <div class="col p-8_5">
        <app-win-profit-factor
          [pastPerformance]="firstTradingStrategyPastPerformance"
          [pastPerformanceToCompare]="secondTradingStrategyPastPerformance"
          [tradePosition]="tradePosition"
        >
        </app-win-profit-factor>
      </div>

      <div class="col p-8_5">
        <app-win-profit-factor
          [pastPerformance]="secondTradingStrategyPastPerformance"
          [pastPerformanceToCompare]="firstTradingStrategyPastPerformance"
          [tradePosition]="tradePosition"
        >
        </app-win-profit-factor>
      </div>

      <div class="card-title text-center w-100">AVG & LONGEST TRADE LENGTH</div>

      <div class="col p-8_5">
        <app-longest-trade-length
          [pastPerformance]="firstTradingStrategyPastPerformance"
          [pastPerformanceToCompare]="secondTradingStrategyPastPerformance"
          [tradePosition]="tradePosition"
        >
        </app-longest-trade-length>
      </div>

      <div class="col p-8_5">
        <app-longest-trade-length
          [pastPerformance]="secondTradingStrategyPastPerformance"
          [pastPerformanceToCompare]="firstTradingStrategyPastPerformance"
          [tradePosition]="tradePosition"
        >
        </app-longest-trade-length>
      </div>
    </div>
  </div>
</div>
