import { ExchangeCountriesCodes } from '@const';

export class CustomTable {
  name: string;
  value: any;
  value1: any;

  constructor(value: number | string, value1?: number | string) {
    this.name = 'stock' + value;
    this.value = value;
    if (value1) {
      this.value1 = value1;
    }
  }
}

export interface IStockSubscription {
  subscriptionId: string;
  symbol: {
    symbol: string;
    country_code: ExchangeCountriesCodes;
  };
}
