import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, OnInit, Optional, Signal, computed } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ISelectedHeatmapElementsPopup
} from '@c/shared/sector-industy-heatmap-details/sector-industry-heatmap-details.models';
import { IPanelSymbolDetails } from '@c/shared/symbol-details-panel/symbol-details-panel.model';
import { tooltipColorSchema } from '@c/stock-heatmap/helpers';
import { D3ChartNode, HeatmapElement, HeatmapTooltipCloseModel, HeatmapTooltipVisibilityModel, TooltipSymbol } from '@c/stock-heatmap/stock-heatmap.model';
import { MobileWidth, formatDecimal } from '@const';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, fromEvent, map, startWith } from 'rxjs';

@Component({
  selector: 'app-heatmap-tooltip',
  templateUrl: './heatmap-tooltip.component.html',
  styleUrls: ['./heatmap-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeatmapTooltipComponent implements OnInit {
  public showWeeklyOptionsLegend = true;
  public selectedSymbol: string;
  public sector: string;
  public price: number | null = null;
  public industryNode: D3ChartNode<HeatmapElement>;
  public tickerNode: HeatmapElement | null = null;
  public hoveredTicker: IPanelSymbolDetails | null = null;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public heatmapVisibilityConfig$ = new BehaviorSubject<HeatmapTooltipVisibilityModel>({
    mode: 'Default',
  });
  public onClose = new EventEmitter<HeatmapTooltipCloseModel>();
  public compareFunction: (arr: TooltipSymbol[]) => TooltipSymbol[] | null = null;

  protected symbolsList: BehaviorSubject<TooltipSymbol[]> = new BehaviorSubject([]);
  protected hasSymbolsWithWeeklyOptions$ = this.symbolsList.pipe(
    map((symbols) => symbols.some((symbol) => symbol.has_weekly_options))
  );

  protected isMobile$: Observable<boolean>;
  protected industryNameItems: Signal<string[]>;
  private mobileWidth = MobileWidth;

  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownHandler() {
    this.close();
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) selectedHeatmapElements: ISelectedHeatmapElementsPopup,
    @Optional() private _dialogRef: MatDialogRef<HeatmapTooltipComponent>
  ) {
    if (selectedHeatmapElements) {
      this.selectedSymbol = selectedHeatmapElements.ticker?.symbol;
      this.price = selectedHeatmapElements.ticker?.price;
      this.sector = selectedHeatmapElements.sector?.name;
      this.industryNode = selectedHeatmapElements.industry;
      this.tickerNode = selectedHeatmapElements.ticker;
      this.hoveredTicker = selectedHeatmapElements.hoveredTicker;
      this.compareFunction = selectedHeatmapElements.compareSymbolsFn;
      this.heatmapVisibilityConfig$.next(selectedHeatmapElements.heatmapVisibilityModel);
    }
  }

  public ngOnInit(): void {
    this.isMobile$ = this.getIsMobile();
    if (this.industryNode) {
      this.updateSymbolsList();
    }

    this.industryNameItems = computed(() => {
      return this.industryNode.name.split(/\s|-/);
    });
  }

  protected updateSymbolsList(): void {
    if (!this.industryNode) {
      this.symbolsList.next([]);
      return;
    }

    const symbolsList =
      [...(this.industryNode.children as HeatmapElement[])]
        .sort((a, b) => this.selectedSymbolSort(a, b))
        .map((symbol) => ({
          ...symbol,
          color: tooltipColorSchema(symbol.diff ?? 0),
          diffStr: `${this.formatNumber(symbol.diff || 0)}%`,
          moveStr: `$${this.formatNumber(symbol.move || 0)}`,
        })) || [];

    if (this.compareFunction) {
      this.symbolsList.next(this.compareFunction(symbolsList));
    } else {
      this.symbolsList.next(symbolsList);
    }
  }

  protected close(isGoToHeatmap: boolean = false, newSymbol: TooltipSymbol = null): void {
    this.onClose.emit({
      isGoToHeatmap,
      newSymbol
    });
    this._dialogRef?.close({
      isGoToHeatmap,
      newSymbol
    });
  }

  private selectedSymbolSort(a: HeatmapElement, b: HeatmapElement): number {
    if (a.symbol === this.selectedSymbol) {
      return -1;
    }
    if (b.symbol === this.selectedSymbol) {
      return 1;
    }
    return b.market_cap - a.market_cap;
  }

  private formatNumber(value: number): string {
    return `${value > 0 ? '+' : ''}${formatDecimal(value)}`;
  }

  private getIsMobile(): Observable<boolean> {
    return fromEvent(window, 'resize').pipe(
      startWith(window.innerWidth < this.mobileWidth),
      debounceTime(500),
      map(() => window.innerWidth < this.mobileWidth),
      distinctUntilChanged()
    );
  }
}
