<app-simple-modal-header
  titleText="Scanner Settings"
  [tooltipText]="scannerSettingTooltip"
></app-simple-modal-header>

<form [formGroup]="scannerForm" (ngSubmit)="changeSetting()">
  <div mat-dialog-content class="mat-typography">
    <div class="run-scanner-for">
      <p class="scanner-settings-row-title">Run Scanner for</p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="run-scanner-form-field-option">
        <mat-select
          hideSingleSelectionIndicator
          formControlName="strategy_id"
          name="strategy"
          (selectionChange)="changeStrategy()"
          panelClass="run-scanner-option"
        >
          <mat-option *ngFor="let strategy of tradingStrategies" [value]="strategy.strategy_id">
            {{ formatDecimal(strategy.risk_ratio, 1) }} risk / {{ formatDecimal(strategy.reward_ratio, 1) }} reward ({{ strategy.name }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="scanner-exchanges">
      <p class="scanner-settings-row-title">Exchanges</p>
      <div class="exchanges-checkbox-list">
        <mat-checkbox
          class="exchange-checkbox small-checkbox"
          color="primary"
          *ngFor="let country of exchangeCountriesKeys"
          (change)="setCountryCode(country)"
          [checked]="scannerForm.value.country_codes?.includes(country)"
        >
          <div class="exchange-checkbox-label">
            {{ ExchangeCountries[country] }}
            <div class="scanner-exchanges-icon {{ country }}-icon"></div>
          </div>
        </mat-checkbox>
      </div>
    </div>

    <div class="signal-type">
      <p class="scanner-settings-row-title">Signal Type</p>
      <mat-radio-group
        color="primary"
        class="signal-type-list"
        aria-label="Select an option"
        formControlName="signal_type"
        name="signal_type"
      >
        <mat-radio-button [value]="TradePositions.LongAndShort" class="custom-radio">Long & Short</mat-radio-button>
        <mat-radio-button [value]="TradePositions.LongOnly" class="custom-radio">Long Only</mat-radio-button>
        <mat-radio-button [value]="TradePositions.ShortOnly" class="custom-radio">Short Only</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="scanner-inputs">
      <div class="w-267">
        <div class="per-input">
          <label>% Return on Investment</label>
          <div class="input-box">
            <div class="percentage">%</div>
            <input
              type="text"
              autocomplete="off"
              formControlName="min_roi"
              name="min_roi"
              placeholder="0.00"
              mask="separator.2"
              [leadZero]="true"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="1000"
              (click)="$event.target.select()"
              (blur)="inputBlur('min_roi')"
              required
              maxlength="8"
              dot
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.min_roi.touched && scannerForm.controls.min_roi.invalid">
          <span *ngIf="scannerForm.controls.min_roi.errors.required">Please provide a value</span>
        </mat-error>
      </div>

      <div class="w-267">
        <div class="per-input">
          <label>Min Win %</label>
          <div class="input-box">
            <div class="percentage">%</div>
            <input
              type="text"
              autocomplete="off"
              name="min_win_percent"
              placeholder="0.00"
              required
              formControlName="min_win_percent"
              mask="separator.2"
              [leadZero]="true"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100"
              dot
              (click)="$event.target.select()"
              (blur)="inputBlur('min_win_percent')"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.min_win_percent.touched && scannerForm.controls.min_win_percent.invalid">
          <span *ngIf="scannerForm.controls.min_win_percent.errors.required">Please provide a value</span>
        </mat-error>
      </div>

      <div class="w-267">
        <div class="dollar-input">
          <label>Close Price Higher Than</label>
          <div class="input-box">
            <div class="dollar-sign">$</div>
            <input
              type="text"
              autocomplete="off"
              name="min_close_price"
              placeholder="0.00"
              formControlName="min_close_price"
              mask="separator.2"
              [leadZero]="true"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="10000"
              dot
              (click)="$event.target.select()"
              (blur)="inputBlur('min_close_price')"
              required
              maxlength="9"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.min_close_price.touched && scannerForm.controls.min_close_price.invalid">
          <span *ngIf="scannerForm.controls.min_close_price.errors.required">Please provide a value</span>
        </mat-error>
      </div>

      <div class="w-267">
        <div class="dollar-input">
          <label>Close Price Lower Than</label>
          <div class="input-box">
            <div class="dollar-sign">$</div>
            <input
              type="text"
              autocomplete="off"
              name="max_close_price"
              formControlName="max_close_price"
              placeholder="0.00"
              mask="separator.2"
              [leadZero]="true"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="10000"
              dot
              (click)="$event.target.select()"
              (blur)="inputBlur('max_close_price')"
              required
              maxlength="9"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.max_close_price.touched && scannerForm.controls.max_close_price.invalid">
          <span *ngIf="scannerForm.controls.max_close_price.errors.required">Please provide a value</span>
        </mat-error>
      </div>

      <div class="w-267">
        <div class="only-input">
          <label>Profit Factor Higher Than</label>
          <div class="input-box">
            <input
              type="text"
              autocomplete="off"
              name="min_profit_factor"
              formControlName="min_profit_factor"
              placeholder="0.00"
              required
              maxlength="6"
              mask="separator.2"
              [leadZero]="true"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100"
              dot
              (click)="$event.target.select()"
              (blur)="inputBlur('min_profit_factor')"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.min_profit_factor.touched && scannerForm.controls.min_profit_factor.invalid">
          <span *ngIf="scannerForm.controls.min_profit_factor.errors.required">Please provide a value</span>
        </mat-error>
      </div>

      <div class="w-267">
        <div class="only-input">
          <label>Min # of Trades</label>
          <div class="input-box">
            <input
              type="text"
              autocomplete="off"
              name="min_trades_count"
              placeholder="0"
              formControlName="min_trades_count"
              required
              maxlength="3"
              mask="separator.0"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="100"
              (click)="$event.target.select()"
              (blur)="inputBlur('min_trades_count')"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.min_trades_count.touched && scannerForm.controls.min_trades_count.invalid">
          <span *ngIf="scannerForm.controls.min_trades_count.errors.required">Please provide a value</span>
        </mat-error>
      </div>

      <div class="w-267">
        <div class="only-input">
          <label>Min Volume</label>
          <div class="input-box">
            <input
              type="text"
              autocomplete="off"
              name="min_volume"
              placeholder="0"
              formControlName="min_volume"
              required
              maxlength="9"
              mask="separator.0"
              thousandSeparator=","
              decimalMarker="."
              separatorLimit="1000000"
              (click)="$event.target.select()"
              (blur)="inputBlur('min_volume')"
            />
          </div>
        </div>
        <mat-error *ngIf="scannerForm.controls.min_volume.touched && scannerForm.controls.min_volume.invalid">
          <span *ngIf="scannerForm.controls.min_volume.errors.required">Please provide a value</span>
        </mat-error>
      </div>
    </div>
    <div class="warning-container">
      <mat-icon [svgIcon]="'warning'" class="xl-icon"></mat-icon>
      <div class="message">NOTE: <b>'Close Price Higher/Lower Than'</b> are ignored for Cryptocurrency</div>
    </div>
  </div>

  <mat-dialog-actions>
    <button
      mat-stroked-button
      class="border-btn large-btn"
      type="button"
      (click)="resetSetting()"
      tabindex="-1"
    >
      Reset to Default
    </button>

    <button
      mat-flat-button
      color="primary"
      class="small-btn"
      [disabled]="showLoader"
      type="submit"
    >
      <mat-icon *ngIf="showLoader">
        <mat-spinner class="white-spinner" diameter="25"></mat-spinner>
      </mat-icon>
      <ng-container *ngIf="!showLoader">Scan</ng-container>
    </button>
  </mat-dialog-actions>
</form>
