import { formatDecimal, formatPrice, getPastPerformancePropertyName, TradePositions } from "../../../constants";

export abstract class PastPerformanceBlock {
  abstract pastPerformance;
  abstract pastPerformanceToCompare;
  abstract tradePosition: TradePositions;

  getPastPerformanceHighlightClass(propertyName: string, isBlackText: boolean = false) {
    propertyName = this.getPropertyName(propertyName);
    const pastPerformance = this.pastPerformance;
    const pastPerformanceToCompare = this.pastPerformanceToCompare;

    const metricValue = pastPerformance && pastPerformance[propertyName];

    if(!pastPerformance || !pastPerformanceToCompare || metricValue === null) return '';

    let className = !isBlackText
      ? metricValue >= 0 ? 't-green' : 't-red'
      : '';
    if(metricValue >= pastPerformanceToCompare[propertyName]) {
      className += ' yellow-box';
    }

    return className;
  }

  getPropertyName(propertyNameBase: string): string {
    return getPastPerformancePropertyName(propertyNameBase, this.tradePosition);
  }

  getPropertyValue(key: string) {
    return this.pastPerformance && this.pastPerformance[this.getPropertyName(key)];
  }

  getPastPerformanceMetric(propertyName: string, numDecimals: number) {
    const pastPerformance = this.pastPerformance;

    return pastPerformance
      ? formatDecimal(pastPerformance[this.getPropertyName(propertyName)], numDecimals)
      : '';
  }

  getPastPerformanceMetricMoney(propertyName) {
    const { pastPerformance } = this;

    return pastPerformance
      ? formatPrice(pastPerformance[this.getPropertyName(propertyName)], 2)
      : '';
  }
}
