import { getDefaultDateRangeFilterValue } from '@c/shared/date-contols/components/filters/daterange-filter/daterange-filter.model';
import { TradesListFilterFormModel } from './trades-list-filter-form.model';

export const tradesListFilterFormDefault: TradesListFilterFormModel = {
  showImportedTrades: false,
  symbol: '',
  dateRange: getDefaultDateRangeFilterValue()
}

export const tradesListFilterFormNoImportedTradesDefault: TradesListFilterFormModel = {
  showImportedTrades: true,
  symbol: '',
  dateRange: getDefaultDateRangeFilterValue()
}
