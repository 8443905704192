import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RangeSliderModule } from '@c/range-slider/range-slider.module';
import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';
import { SelectTradingLogInputDateFormatFormComponent } from '@c/trading-log/shared';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';

import { ChangePasswordFormModule } from './change-password-form/change-password-form.module';
import { OverlayModule } from '@c/common/overlay/overlay.module';

import { MySettingsModalComponent } from './my-settings-modal.component';

@NgModule({
  declarations: [MySettingsModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    FormDefaultsModule,
    ChangePasswordFormModule,
    RangeSliderModule,
    SelectTradingLogInputDateFormatFormComponent,
    OverlayModule,
    EditionsSharedModule
  ],
  exports: []
})
export class MySettingsModalModule { }
