import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { LicenseAgreementTextModule } from '@c/shared/license-agreement-text/license-agreement-text.module';
import { IconsModule } from '@core/icons.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { AppLoaderService } from '@s/app-loader.service';
import * as Sentry from '@sentry/angular-ivy';
import { GlobalErrorHandler } from '@u/errors/global.error-handler';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';

import { PrintDataModule } from '@c/print-data/print-data.module';
import { LoadingAnimationComponent } from '@c/shared/loading-animation/loading-animation.component';
import { CustomLoaderComponent } from '@c1/custom-loader/custom-loader.component';
import { ErrorComponent } from '@c1/error/error.component';
import { LoginComponent } from '@c1/login/login.component';
import { UserAggrementComponent } from '@c1/user-aggrement/user-aggrement.component';
import { MainV2Module } from '@m/main-v2.module';
import { MainModule } from '@m1/main.module';
import { CommonModalsModule } from '@mdl/common/common-modals.module';
import { ImportTradesStatusModalComponent } from '@mdl/import-trades-status-modal/import-trades-status-modal.component';
import { TradingHubModalModule } from '@mdl/trading-hub-modal/trading-hub-modal.module';
import { VideoHubHttpClient } from '@s/video-hub/video-hub-http-client.service';
import { VideoHubInterceptor } from '@s/video-hub/video-hub-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DateFunction } from './core/business/date-function';
import { AuthGuard } from './core/directives/auth-guard.service';
import { httpInterceptorProviders } from './core/interceptor';
import { PlaceTradeStatusModalComponent } from './modals/place-trade-status-modal/place-trade-status-modal.component';

function initializeApplication(appLoaderService: AppLoaderService) {
  return () => appLoaderService.initializeApplication();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    CustomLoaderComponent,
    UserAggrementComponent,
    PlaceTradeStatusModalComponent,
    ImportTradesStatusModalComponent,
  ],
  exports: [CustomLoaderComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MainModule,
    MainV2Module,
    BrowserAnimationsModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    NgxSpinnerModule,
    InputMaskModule,
    CommonModalsModule,
    IconsModule,

    LicenseAgreementTextModule,
    TradingHubModalModule,
    PrintDataModule,
    LoadingAnimationComponent,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    httpInterceptorProviders,
    AuthGuard,
    DateFunction,
    { provide: APP_INITIALIZER, useFactory: initializeApplication, deps: [AppLoaderService], multi: true },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideNgxMask(), // TODO: move to bootstrap function on refactor
    VideoHubInterceptor,
    VideoHubHttpClient,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
