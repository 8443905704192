<div mat-dialog-title>
  {{ data.header }}
  <button mat-icon-button mat-dialog-close (click)="cancel()" class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <mat-icon [svgIcon]="data.icon"></mat-icon>
  <div class="confirmation-text">
    <span>{{ data.confirmationText }}</span>
    <div *ngIf="data.subText" class="sub-text-container">
      <span>{{ data.subText }}</span>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="ok()">{{ data.okText }}</button>
  <button mat-raised-button (click)="cancel()" *ngIf="data.showCancel">{{ data.cancelText }}</button>
</mat-dialog-actions>
