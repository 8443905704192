import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChartTabs, DefaultPrintOptions, PrintOptions, Themes, UserSettings } from '@const';
import { UserDataService } from '@s/user-data.service';
import { ObservableService } from '@s/observable.service';
import * as _ from 'lodash';
import { Subscriber } from 'rxjs';
import { OptionDataService } from '@s/option-data.service';

@Component({
  selector: 'app-print-data-modal',
  templateUrl: './print-data-modal.component.html',
  styleUrls: ['./print-data-modal.component.scss']
})
export class PrintDataModalComponent implements OnInit, OnDestroy {
  public printOptions = DefaultPrintOptions;
  public currentPrintOptions = [];
  public isLoading = false;
  private chartTab;
  private subscription = new Subscriber();
  private symbolId;
  private firstTradingStrategyId;
  private secondTradingStrategyId;

  constructor(
    private dialogRef: MatDialogRef<PrintDataModalComponent>,
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private optionDataService: OptionDataService,
  ) {
  }

  async ngOnInit() {
    this.firstTradingStrategyId = this.observableService.firstTradingStrategyId.getValue();
    this.secondTradingStrategyId = this.observableService.secondTradingStrategyId.getValue();
    this.symbolId = this.observableService.symbol.getValue();
    this.currentPrintOptions = this.observableService.printOptions$.getValue();
    this.chartTab = await this.userDataService.get(UserSettings.ChartTab);
    this.subscription.add(
      this.observableService.isTradingChartInitialize$.subscribe(async (isChartInitialize) => {
        if (isChartInitialize && this.isLoading) {
          await this.prepareDataForPrint();
        }
      })
    );
  }

  setPrintOption(option): void {
    if (this.currentPrintOptions.includes(option)) {
      this.currentPrintOptions = _.without(this.currentPrintOptions, option);
    } else {
      this.currentPrintOptions.push(option);
    }
  }

  async checkIsChartReady(): Promise<void> {
    this.observableService.theme.next(Themes.Light);
    this.isLoading = true;
    if (this.chartTab !== ChartTabs.Chart) {
      this.observableService.chartTab.next(ChartTabs.Chart);
    } else {
      await this.prepareDataForPrint();
    }
  }

  async prepareDataForPrint(): Promise<void> {
    if (this.currentPrintOptions.includes(PrintOptions.Options)) {
      await Promise.all([
        this.optionDataService.get(this.symbolId, this.firstTradingStrategyId),
        this.optionDataService.get(this.symbolId, this.secondTradingStrategyId)]);
    }
    await this.userDataService.set(UserSettings.PrintOptions, this.currentPrintOptions);
    this.observableService.printOptions$.next(this.currentPrintOptions);
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
