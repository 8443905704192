import * as moment from 'moment';
import { convertToEasternTime } from '@u/utils';
import { daysNumberForRoiCalculations } from '@const';

export const getSellPutsOptionToTrade = (strike_price: any, buyingPower: any, position: any): any => {
  return Math.round(strike_price !== 0 && strike_price !== null ? buyingPower / position / (Number(strike_price) * 100) : null);
};

export const getNeedToBuyStocks = (strike_price: any, buyingPower: any, position: any): any => {
  return strike_price !== 0 && strike_price !== null
    ? Number(strike_price) * getSellPutsOptionToTrade(strike_price, buyingPower, position) * 100
    : null;
};

export const getSellPutsDTE = (expiration: string | number, date?: moment.Moment): any => {
  if (expiration === null) {
    return null;
  }

  let count = 0;
  const currentDate = date ?? moment().tz('America/New_York').startOf('day');
  if (typeof expiration === 'string') {
    count = moment.duration(convertToEasternTime(expiration).diff(currentDate)).asDays();
  } else {
    count = moment.duration(convertToEasternTime(expiration).diff(currentDate)).asDays();
  }
  count = Math.round(count);
  return count > 0 ? count : count === 0 ? 0 : null;
};
export const getSellPutsMinOptionPremium = (strike_price: any, buyingPower: any, position: any, expiration: string | number, date?: moment.Moment): any => {
  return (
    (((getNeedToBuyStocks(strike_price, buyingPower, position) * 0.3) / daysNumberForRoiCalculations) * (getSellPutsDTE(expiration, date) + 1)) /
    getSellPutsOptionToTrade(strike_price, buyingPower, position) /
    100
  );
};

export const getSellPutMaxOptionPremium = (strike_price: any, buyingPower: any, position: any, expiration: string | number): any => {
  return (
    (((getNeedToBuyStocks(strike_price, buyingPower, position) * 0.4) / daysNumberForRoiCalculations) * (getSellPutsDTE(expiration) + 1)) /
    getSellPutsOptionToTrade(strike_price, buyingPower, position) /
    100
  );
};

export const getDrop = (strike_price: any, stock_price: any): any => {
  return ((stock_price - strike_price) / stock_price) * 100;
};

export const getSellPutsPremiumCollected = (strike_price: any, buyingPower: any, position: any, option_premium: any): any => {
  return getSellPutsOptionToTrade(strike_price, buyingPower, position) * option_premium * 100;
};

export const getSellPutsPremiumPerDay = (
  option_premium: any,
  buyingPower: any,
  position: any,
  strike_price: any,
  expiration: string | number
) => {
  return getSellPutsPremiumCollected(strike_price, buyingPower, position, option_premium) / (getSellPutsDTE(expiration) + 1);
};

export const getSellPutsPremiumAnnualarized = (
  strike_price: any,
  expiration: string | number,
  buyingPower: any,
  position: any,
  option_premium: any
): any => {

  const a = getNeedToBuyStocks(strike_price, buyingPower, position);
  if (a !== 0 || a !== null) {
    return ((getSellPutsPremiumPerDay(option_premium, buyingPower, position, strike_price, expiration) * daysNumberForRoiCalculations) / a) * 100;
  } else {
    return 0;
  }
};

export const getSellPutsMaxProfitTaker = (option_premium: any): number => {
  return Number(option_premium) * 0.1;
};

export const getSellPutsMaxProfitValue = (options_trades: number, option_premium: number, profit_target: number): number => {
  return options_trades * (option_premium - Math.round(profit_target * 100) / 100) * 100;
};

export const getSellCallsOptionToTrade = (no_of_shares: any): any => {
  return no_of_shares ? Math.round(no_of_shares / 100) : null;
};

export const getSellCallsDTE = (expiration: number | string, date?: moment.Moment): any => {
  if (expiration === null) {
    return null;
  }

  let count = 0;
  const currentDate = date ?? moment().tz('America/New_York').startOf('day');
  if (typeof expiration === 'string') {
    count = moment.duration(convertToEasternTime(expiration).diff(currentDate)).asDays();
  } else {
    count = moment.duration(convertToEasternTime(expiration).diff(currentDate)).asDays();
  }
  count = Math.round(count);
  return count > 0 ? count : count === 0 ? 0 : null;
};

export const getSellCallsMinOptionPremium = (stock_price: any, expiration: number | string, date?: moment.Moment): any => {
  const value: number = ((getSellCallsDTE(expiration, date) + 1) / daysNumberForRoiCalculations) * stock_price * 0.3;
  return value.toFixed(2);
};

export const getSellCallsMaxProfitTaker = (option_premium: any): any => {
  return option_premium * 0.1;
};

export const getSellCallsPremiumCollected = (option_premium: any, number_of_shares: any): any => {
  return option_premium * 100 * getSellCallsOptionToTrade(number_of_shares);
};

export const getSellCallsPremiumPerDay = (option_premium: any, number_of_shares: any, expiration: number | string): any => {
  return getSellCallsPremiumCollected(option_premium, number_of_shares) / (getSellCallsDTE(expiration) + 1);
};

export const getPositionValue = (bought_at: any, number_of_shares: any): any => {
  return bought_at * number_of_shares;
};

export const getSellCallsOptionPremiumAnnularized = (
  bought_at: any,
  option_premium: any,
  number_of_shares: any,
  expiration: number | string
): any => {
  const premium_per_day = getSellCallsPremiumCollected(option_premium, number_of_shares) / (getSellCallsDTE(expiration) + 1);
  return ((premium_per_day * daysNumberForRoiCalculations) / (bought_at * number_of_shares)) * 100;
};

export const getSellCallsStockGain = (strike_price: any, bought_at: any, number_of_shares: any): any => {
  return (strike_price - bought_at) * number_of_shares;
};

export const getRise = (strike_price: number, bought_at: number) => {
  return ((strike_price - bought_at) / bought_at) * 100;
};

export const getSellCallsStockGainHoldingPeriod = (strike_price: any, bought_at: any) => {
  return getRise(strike_price, bought_at);
};

export const getSellCallsTotalGain = (option_premium: any, number_of_shares: any, strike_price: any, bought_at: any): any => {
  return getSellCallsPremiumCollected(option_premium, number_of_shares) + getSellCallsStockGain(strike_price, bought_at, number_of_shares);
};

export const getSellCallsMaxProfitTaker2 = (bought_at: any, option_premium: any, strike_price: any): any => {
  const value1: number = bought_at - option_premium;
  const value2: number = Number(strike_price - bought_at);
  const value3: number = value2 + Number(option_premium);
  return value1 + value3 * 0.9;
};

export const getSellCallsMaxProfitValue2 = (
  option_premium: number,
  number_of_shares: number,
  strike_price: number,
  bought_at: number
): number => {
  return getSellCallsTotalGain(option_premium, number_of_shares, strike_price, bought_at) * 0.9;
};

export const getSellCallsMaxProfitValue = (options_trades: number, option_premium: number, profit_target: number): number => {
  return options_trades * (option_premium - Math.round(profit_target * 100) / 100) * 100;
};
