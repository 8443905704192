export interface WeekdayTradesModel {
  weekday: string;
  calls: number;
  puts: number;
  all: number;
}

export enum WeekdayTradesTabs {
  Table = 'Table',
  Graph = 'Graph'
}
