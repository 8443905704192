import { VideoHubService } from '@s/video-hub/video-hub.service';
import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';

import { LoginService } from '@c1/login/login.service';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { AuthService } from '@s/common/auth.service';
import { JobDataService } from '@s/job-data.service';
import { ProcessingScreenConfiguration, ServerDataService } from '@s/server-data.service';
import { ObservableService as ObservableServiceV1 } from '../app/core/directives/observable.service';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { NavigationService } from '@s/navigation.service';
import {
  JobStatus,
  loginUrl,
  ServerSettings,
  TabNames,
  userAgreementUrl,
  videoHubUrl,
} from '@const';
import { environment } from '@env/environment';
import { NavigationEnd, Router } from '@angular/router';
import { getTabNameByRoute, getUrlSegmentsByRoute } from '@u/utils';

const HIDE_PRELOAD_IMAGE_DELAY_MS = 2000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  public title = 'angular-pxo';

  protected showImpersonateBar = false;
  protected impersonateUserName = '';
  protected impersonateUserEmail = '';

  protected isNeedPrint$ = this._observableService.isNeedPrint$;

  private subscriptions = new Subscription();

  private processingScreenConfigurations: Array<ProcessingScreenConfiguration> = [];
  private numberCrunchingStartSubscription: Subscription;
  private numberCrunchingSubscription: Subscription;

  protected tags;

  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    private _observableService: ObservableService,
    private _observableServiceV1: ObservableServiceV1,
    private _userDataService: UserDataService,
    private _authService: AuthService,
    private _jobDataService: JobDataService,
    private _serverDataService: ServerDataService,
    private _brokerAuthenticationService: BrokerAuthenticationService,
    private _loginService: LoginService,
    private navigationService: NavigationService,
    private location: Location,
    private videoHubService: VideoHubService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this._authService.clearStorageOnRefresh();

    const params = new URLSearchParams(window.location.search);
    const tradierState = params.get('state');
    const tradierCode = params.get('code');

    if (tradierCode && tradierState) {
      await this._brokerAuthenticationService.mintAccessToken(tradierState, tradierCode);
      await this.navigationService.redirectToTab(TabNames.TradingPanel);
    } else {
      const currentLocation = this.location.path(false).split('?')[0];

      if (
        !currentLocation.includes(loginUrl) &&
        !currentLocation.includes('ui-templates') &&
        !currentLocation.includes(videoHubUrl)
      ) {
        // sync activeTab and URL state (URL could be changed in app-loader)
        await this.navigationService.redirectToTab(
          this._observableService.activeTab.getValue()
        );
      }
    }

    interval(environment.TokenExpirationCheckIntervalMS)
      .pipe(
        takeUntil(this._destroy$),
        filter((_) => window.location.href.indexOf(loginUrl) === -1 && window.location.href.indexOf(userAgreementUrl) === -1),
        filter((_) => !this._authService.isTokenValid())
      )
      .subscribe(() => {
        this._authService.logout(true);
      });

    this.subscriptions.add(
      this._observableServiceV1.userSetting.subscribe(
        (settings) => (this.tags = settings.tags?.join(',') || this._userDataService.getUser()?.tags?.join(',') || '')
      )
    );

    this._authService.watchUserActivity(environment.UpdateTokenByUserActivityDelaySec);

    this.processingScreenConfigurations = await this._serverDataService.getAsObject(ServerSettings.DataProcessingScreenConfiguration);

    // Start polling server every 1 minute to see if the processing has already finished
    this.subscriptions.add(
      this._observableService.numberCrunchingCountDownTimerFinished.subscribe(async (_) => await this.getNumberCrunchingStatus())
    );

    await this.initializeNumberCrunching();

    const impersonateInfo = this._loginService.getImpersonateDetails();
    this.showImpersonateBar = impersonateInfo !== null;
    this.impersonateUserEmail = impersonateInfo?.email;
    this.impersonateUserName = impersonateInfo?.userName;

    this.videoHubService.openBackgroundVideoFromStorage();

    this.subscriptions.add(
      this.router.events.subscribe((event) =>  {
        if (event instanceof NavigationEnd) {
          const urlSegments = getUrlSegmentsByRoute(event.urlAfterRedirects)
          const tabName = getTabNameByRoute(urlSegments);
          this.navigationService.setActiveTab(tabName);
        }
       })
    );
  }

  ngAfterContentInit() {
    setTimeout(() => {
      const preloadImg = document.querySelector('.preload-img') as HTMLElement;
      preloadImg.style.opacity = '0';
      preloadImg.style.zIndex = '-10';
    }, HIDE_PRELOAD_IMAGE_DELAY_MS);
  }

  ngOnDestroy() {
    this.cleanupNumberCrunchingStartSubscription();
    this.cleanupNumberCrunchingSubscription();

    this.subscriptions.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  async initializeNumberCrunching() {
    const startNumberCrunching = await this.startNumberCrunching();
    // check overtime processing
    if (!startNumberCrunching) {
      await this.getNumberCrunchingStatus();
    }
  }

  async startNumberCrunching() {
    for (const processingConfiguration of this.processingScreenConfigurations) {
      const timeNow = moment.tz(processingConfiguration.timeZone);

      // check if server polling or processing day
      if (!processingConfiguration.processingDays.includes(timeNow.isoWeekday())) {
        continue;
      }

      const startTime = moment.tz(processingConfiguration.timeZone).startOf('day').add(processingConfiguration.startTime);
      const expectedFinishTime = startTime.clone().add(processingConfiguration.processingMinutes, 'minutes');

      if (timeNow.unix() >= startTime.unix() && timeNow.unix() < expectedFinishTime.unix()) {
        this.cleanupNumberCrunchingStartSubscription();

        // expected time hasn't passed - show countdown timer
        if (timeNow.unix() < expectedFinishTime.unix()) {
          this._observableService.showMaintenance.next(true);
          this._observableService.startNumberCrunchingTime.next(startTime.toDate().getTime());
          this._observableService.finishNumberCrunchingTime.next(expectedFinishTime.toDate().getTime());

          this._observableService.numberCrunchingStatus.next(JobStatus.InProgress);
        } else {
          await this.getNumberCrunchingStatus();
        }

        return true;
      }
    }

    return false;
  }

  async getNumberCrunchingStatus() {
    const schedulers = await this._jobDataService.getExchangesDailySchedulers([JobStatus.New, JobStatus.InProgress]);

    if (!schedulers.length) {
      if (this._observableService.showMaintenance.getValue()) {
        // this._observableService.showMaintenance.next(false);
        this._observableService.numberCrunchingStatus.next(JobStatus.Done);
      }
      this.cleanupNumberCrunchingSubscription();
      this.setupNumberCrunchingStartSubscription();
      return;
    }

    this._observableService.showMaintenance.next(true);
    this._observableService.numberCrunchingStatus.next(JobStatus.Overtime);

    // poll every minute to check for updates
    if (!this.numberCrunchingSubscription) {
      this.numberCrunchingSubscription = interval(1000 * 60).subscribe(async (_) => {
        await this.getNumberCrunchingStatus();
      });
    }
  }

  setupNumberCrunchingStartSubscription() {
    // Check every second if it is time to show maintenance page
    this.numberCrunchingStartSubscription = interval(1000).subscribe(async (_) => {
      await this.startNumberCrunching();
    });
  }

  cleanupNumberCrunchingStartSubscription() {
    if (this.numberCrunchingStartSubscription) {
      this.numberCrunchingStartSubscription.unsubscribe();
    }
  }

  cleanupNumberCrunchingSubscription() {
    if (this.numberCrunchingSubscription) {
      this.numberCrunchingSubscription.unsubscribe();
    }
  }

  protected logout(): void {
    this._authService.logout();
  }
}
