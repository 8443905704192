import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DragDropModule, CdkDragEnd } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';
import { TradingPanelOrderComponent } from '@c/trading-panel-order';
import { Subscription } from 'rxjs';
import { MobileWidth, PinnedOrderPanelMinWidth, UserSettings } from '@const';
import { TradingPanelClientEvent } from '@mod/trading-panel/trading-panel.model';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';

@Component({
  standalone: true,
  selector: 'app-trading-panel-order-modal',
  templateUrl: './trading-panel-order-modal.component.html',
  styleUrls: ['./trading-panel-order-modal.component.scss'],
  imports: [
    CommonModule,
    DragDropModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatDialogModule,
    TradingPanelOrderComponent,
    MatTooltipModule,
  ]
})
export class TradingPanelOrderModalComponent implements OnInit, OnDestroy {

  protected readonly orderInput: Partial<TradingOrderInput>;
  protected readonly DefaultDragPosition = { x: 0, y: 0 };

  protected showResetToDefault = true;
  protected dragPosition = { ...this.DefaultDragPosition };
  protected isDragEnabled = window.innerWidth > MobileWidth;
  protected isPinEnabled = window.innerWidth > PinnedOrderPanelMinWidth;
  protected isPanelOrderPinned = false;

  private _width = window.innerWidth;
  private _height = window.innerHeight;
  private _subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Partial<TradingOrderInput>,
    private _dialogRef: MatDialogRef<TradingPanelOrderModalComponent>,
    private _brokerAuthenticationService: BrokerAuthenticationService,
    private _localStorageService: LocalStorageService,
    private _observableService: ObservableService,
    private _userDataService: UserDataService,
  ) {
    this.orderInput = {
      ...data,
      onTradingPanelNavigateHandler: () => this.close()
    };
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this._width = event.target.innerWidth;
    this._height = event.target.innerHeight;

    this.handleWindowResize();
  }

  ngOnInit(): void {
    const dragPosition = this._localStorageService.get(UserSettings.TradingPanelOrderModalPagePosition);
    const tradierAuthentication = this._brokerAuthenticationService.getTradierAuthenticationData();

    if (dragPosition) {
      const isFitByWidth = (this._width / 2) > Math.abs(dragPosition.x);
      const isFitByHeight = (this._height / 2) > Math.abs(dragPosition.y);

      if (isFitByWidth && isFitByHeight) {
        this.dragPosition = dragPosition;
      }
    }

    this.showResetToDefault = !!tradierAuthentication.accessToken;

    this._subscriptions.add(
      this._observableService.tradingPanelClientEvent.subscribe((clientEvent: TradingPanelClientEvent) => {
        if (clientEvent === TradingPanelClientEvent.OrderCreated) {
          this.isPinEnabled = false;
          this.showResetToDefault = false;
        } else if (clientEvent === TradingPanelClientEvent.BrokerLogout) {
          this.close();
        }
      })
    );

    this._subscriptions.add(
      this._brokerAuthenticationService.TradierAuthenticationData.subscribe(() => {
        const authData = this._brokerAuthenticationService.getTradierAuthenticationData();
        this.showResetToDefault = !!authData.accessToken;
      }));

    this._subscriptions.add(
      this._observableService.showMaintenance.subscribe((showMaintenance) => {
        if (showMaintenance) {
          this.close();
        }
      })
    );

    this.handleWindowResize();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private handleWindowResize() {
    this.isDragEnabled = this._width > MobileWidth;
    this.isPinEnabled = this._width > PinnedOrderPanelMinWidth;
    this.dragPosition = { ...this.DefaultDragPosition };
  }

  protected async pinOrderModal(): Promise<void> {
    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderPanelPinChanging);

    const orderInput = {
      ...this.orderInput,
      onTradingPanelNavigateHandler: null
    } as TradingOrderInput;
    this._observableService.tradingPanelOrderInput.next(orderInput);

    await this._userDataService.set(UserSettings.TradingPanelOrderModalPinned, true);

    this.isPanelOrderPinned = true;
    this.close(true);
  }

  protected resetToDefault() {
    this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.ResetOrderToDefault);
  }

  protected close(isPinChange?: boolean) {
    if (!isPinChange) {
      this._observableService.tradingPanelClientEvent.next(TradingPanelClientEvent.OrderPanelClosing);
    }

    this._dialogRef.close({ isCanceled: true });
  }

  protected dragEnd(event: CdkDragEnd) {
    const element = event.source.getRootElement();
    const boundingClientRect = element.getBoundingClientRect();
    const parentPosition = this.getPosition(element);
    const newDragPosition = {
      x: boundingClientRect.x - parentPosition.left,
      y: boundingClientRect.y - parentPosition.top
    };
    this._localStorageService.set(UserSettings.TradingPanelOrderModalPagePosition, newDragPosition);
  }

  private getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }
}
