import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HolidaysModalComponent } from '@mdl/holidays-modal/holidays-modal.component';
import { MySettingsModalComponent } from '@c/my-settings-modal/my-settings-modal.component';
import { EconomicCalendarModalComponent } from '@mdl/economic-calendar-modal/economic-calendar-modal.component';
import { PrintDataModalComponent } from '@mdl/print-data-modal/print-data-modal.component';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Features, tradingHubModalID } from '@const';
import { ConfirmDialogComponent } from 'src/app/modals/common/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogInputDataModel, ConfirmDialogOutputDataModel } from 'src/app/modals/common/confirm-dialog/confirm-dialog.model';
import { AddEarningsModalComponent } from 'src/app/modals/earnings/add-earnings-modal/add-earnings-modal.component';
import { AddEarningsDialogInputDataModel } from 'src/app/modals/earnings/add-earnings-modal/add-earnings-modal.model';
import { EarningModel } from 'src/app/models/admin/earning.model';
import { EditModalResultModel } from 'src/app/models/common/edit-modal-result.model';
import { GlobalIdentificator } from 'src/app/types/common/global-identificator.type';
import { TradingHubModalComponent } from '@mdl/trading-hub-modal/trading-hub-modal.component';
import { UserListItemModel } from '@mod/admin';
import { SupportAccessModalComponent } from '@c/admin-config/support-access-modal/support-access-modal.component';
import { EditionsService } from '@s/editions.service';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';
import { TradingPanelOrderModalComponent } from '@c/trading-panel-order-modal/trading-panel-order-modal.component';
import { ChatAnswerFeedbackModalComponent } from '@mdl/chat-answer-feedback-modal/chat-answer-feedback-modal.component';
import { IChatAnswerFeedbackConfig, IChatAnswerFeedbackDetails } from '@mdl/chat-answer-feedback-modal/chat-answer-feedback-modal.model';
import { LinksOrganiserComponent } from '@m/common/links-organiser/components/links-organiser/links-organiser.component';
import { MetricDetailsModalComponent, MetricDetailsModalData } from 'src/app/modals/metric-details-modal';
import { CircularProgressModalComponent } from '@mdl/common/circular-progress-modal/circular-progress-modal.component';
import { CircularProgressModalModel } from '@mod/common/circular-progress-modal.model';
import { PlaceTradeStatusModalComponent } from '@mdl/place-trade-status-modal/place-trade-status-modal.component';
import { IPlaceTradeStatusModalData } from '@mdl/place-trade-status-modal/place-trade-status-modal.model';
import { TradesGroupModalComponent } from '@mdl/trades-group-modal/trades-group-modal.component';
import { ImportTradesStatusModalComponent } from '@mdl/import-trades-status-modal/import-trades-status-modal.component';
import { TradesGroupModalInputModel, TradesGroupModalOutputModel } from '@mdl/trades-group-modal';
import { ImportTradesStatusModalInputModel, ImportTradesStatusModalOutputModel } from '@mdl/import-trades-status-modal';
import { ShowProgressModalComponent, ShowProgressModalInputModel, ShowProgressModalOutputModel } from '@mdl/show-progress-modal';
import { TradingHubMode } from '@c/trading-hub/trading-hub.model';
import { HeatmapTooltipComponent } from '@c/stock-heatmap/heatmap-tooltip/heatmap-tooltip.component';
import { ISelectedHeatmapElements, ISelectedHeatmapElementsPopup } from '@c/shared/sector-industy-heatmap-details/sector-industry-heatmap-details.models';
import { HeatmapTooltipCloseModel, TooltipSymbol } from '@c/stock-heatmap/stock-heatmap.model';

@Injectable({ providedIn: 'root' })
export class DialogsService {
  public get isLoginDialogShown(): boolean {
    return this._isLoginDialogShown;
  }

  private _isLoginDialogShown = false;
  private _isNetworkErrorShown = false;


  constructor(
    private _dialog: MatDialog,
    private editionsService: EditionsService,
  ) { }

  openDialog<TDialog, TDialogInput, TDialogOutput, TOutput>(
    componentOrTemplateRef: ComponentType<TDialog> | TemplateRef<TDialog>,
    panelClass: string | string[],
    data: TDialogInput,
    mapper: (value: TDialogOutput) => TOutput,
    minWidth = 400,
    minHeight?: number,
    maxWidth = '100vw',
    closeOnNavigation = true,
    disableClose = true,
    id?: string,
  ): Promise<TOutput> {
    return this._dialog
      .open<TDialog, TDialogInput, TDialogOutput>(componentOrTemplateRef, {
        data,
        closeOnNavigation,
        disableClose,
        minWidth,
        minHeight,
        maxWidth,
        // maxHeight: '100vh',
        panelClass,
        autoFocus: false,
        id,
      })
      .afterClosed()
      .pipe(map((value) => mapper(value)))
      .toPromise();
  }

  customConfirm(data?: Partial<ConfirmDialogInputDataModel>): Promise<boolean> {
    return this.openDialog<ConfirmDialogComponent, Partial<ConfirmDialogInputDataModel>, ConfirmDialogOutputDataModel, boolean>(
      ConfirmDialogComponent,
      'pxo-modal',
      data || {},
      (value) => value?.confirm,
      null,
      null,
      data?.maxWidthPx ? `${data.maxWidthPx}px` : null,
      true,
      false
    );
  }

  public customConfirm$(data?: Partial<ConfirmDialogInputDataModel>): Observable<boolean> {
    return from(this.customConfirm(data));
  }

  confirm(text?: string): Promise<boolean> {
    return this.customConfirm(text ? { confirmationText: text } : {});
  }

  info(text: string, header?: string): Promise<boolean> {
    return this.customConfirm({ confirmationText: text, icon: 'info', showCancel: false, header: header || 'Info' });
  }

  warning(text: string, header?: string): Promise<boolean> {
    return this.customConfirm({
      confirmationText: text,
      icon: 'report_problem',
      showCancel: false,
      header: header || 'Warning',
    });
  }

  openAddEarningsDialog(symbol?: string, securityId?: GlobalIdentificator) {
    return this.openDialog<AddEarningsModalComponent,
      AddEarningsDialogInputDataModel,
      EditModalResultModel<EarningModel>,
      EditModalResultModel<EarningModel>>(AddEarningsModalComponent, 'pxo-modal', { symbol, security_id: securityId }, (_) => _, 300);
  }

  public async openTradingHubModal(
    mode: TradingHubMode = TradingHubMode.Default,
    initialPrompt?: string
  ): Promise<unknown> {
    if (!this.editionsService.isFeatureAvailable(Features.TradingHub)) {
      await this.editionsService.redirectToDemoPage(Features.TradingHub);
      return null;
    }
    return this.openDialog(
      TradingHubModalComponent,
      ['right-modal', 'no-padding', 'full-height', 'wide-modal'],
      {
        mode,
        initialPrompt
      },
      (_) => _,
      400,
      undefined,
      undefined,
      true,
      false,
      tradingHubModalID,
    );
  }

  closeAll() {
    this._dialog.closeAll();
  }

  openHolidaysDialog() {
    if (!this.editionsService.isFeatureAvailable(Features.HolidaysCalendar)) {
      this.editionsService.redirectToDemoPage(Features.HolidaysCalendar);
      return;
    }

    return this.openDialog(
      HolidaysModalComponent,
      ['modal-holidays', 'modals'],
      {},
      (_) => _,
      300,
      undefined,
      '100vw',
      true,
      false,
    );
  }

  openPrintDataDialog(func: (afterClosedData) => void): Promise<any> {
    return this.openDialog(PrintDataModalComponent, 'pxo-modal', null, func, 450);
  }

  openEconCalendarDialog() {
    if (!this.editionsService.isFeatureAvailable(Features.EconomicCalendar)) {
      this.editionsService.redirectToDemoPage(Features.EconomicCalendar);
      return;
    }

    return this.openDialog(
      EconomicCalendarModalComponent,
      ['modal-calendar', 'pxo-modal'],
      {}, (_) => _,
      700,
      400,
      '700px',
      true,
      false,
    );
  }

  openMySettingsModal() {
    return this.openDialog(MySettingsModalComponent, ['my-settings'], {}, (_) => _, 600, 675);
  }

  showNetworkErrorMessage() {
    if (this._isNetworkErrorShown) {
      return;
    }

    this._isNetworkErrorShown = true;
    return this.openDialog<ConfirmDialogComponent, Partial<ConfirmDialogInputDataModel>, ConfirmDialogOutputDataModel, boolean>(
      ConfirmDialogComponent,
      'pxo-modal',
      {
        showCancel: false,
        header: 'Operation failed',
        confirmationText: `Something went wrong.

          Your changes could not be saved.

          Please click OK to refresh the page and try again.`,
        okText: 'OK',
      },
      (value) => value.confirm,
      null,
      null,
      null,
      false,
      true
    ).finally(() => this._isNetworkErrorShown = false);
  }

  openGrantAccessDialog(user: UserListItemModel) {
    return this.openDialog(SupportAccessModalComponent, 'pxo-modal', user, (_) => _, 300);
  }

  openTradingPanelOrderDialog(orderInput: TradingOrderInput) {
    return this.openDialog(TradingPanelOrderModalComponent, 'pxo-order-modal', orderInput, (_) => _, 300);
  }

  public openChatAnswerFeedbackModal(config: IChatAnswerFeedbackConfig) {
    return this.openDialog<
      ChatAnswerFeedbackModalComponent,
      IChatAnswerFeedbackConfig,
      IChatAnswerFeedbackDetails,
      IChatAnswerFeedbackDetails
    >(
      ChatAnswerFeedbackModalComponent,
      ['pxo-modal', 'chat-feedback-modal'],
      config,
      (value) => value,
      600,
      undefined,
      '550px',
      undefined,
      false,
    );
  }

  public openLinksOrganiserModal() {
    return this.openDialog(LinksOrganiserComponent, ['pxo-modal', 'links-organiser', 'modals'], {}, (_) => _, 360);
  }

  public openMetricDetailsModal(data: MetricDetailsModalData) {
    return this.openDialog(
      MetricDetailsModalComponent,
      ['pxo-modal', 'metric-details-modal', 'modals'],
      data,
      (_) => _,
      800,
      null,
      null,
      true,
      false
    );
  }

  public openPlaceTradeStatusModal(data: IPlaceTradeStatusModalData) {
    return this._dialog.open<PlaceTradeStatusModalComponent, IPlaceTradeStatusModalData>(
      PlaceTradeStatusModalComponent,
      {
        data,
        disableClose: true,
        panelClass: ['pxo-modal', 'place-trade-status-modal', 'modals'],
        minWidth: 400,
      },
    );
  }

  public showNoDataForSymbolMessage(symbolName: string): Promise<boolean> {
    return this.customConfirm({
      showCancel: false,
      header: 'Operation failed',
      confirmationText: `Data not found for '${symbolName}' symbol.`,
      subText: '',
      icon: 'info',
    });
  }

  public openCircularProgressModal(data: CircularProgressModalModel) {
    // return modal ref to be able to close it in method where it was opened
    return this._dialog.open<CircularProgressModalComponent, CircularProgressModalModel>(
      CircularProgressModalComponent,
      {
        data,
        disableClose: true,
        panelClass: [],
        minWidth: 450,
      },
    );
  }

  public openTradesGroupModal(model: TradesGroupModalInputModel) {
    return this.openDialog<TradesGroupModalComponent, TradesGroupModalInputModel, TradesGroupModalOutputModel, TradesGroupModalOutputModel>(
      TradesGroupModalComponent,
      ['pxo-modal', 'trades-group-modal', 'modals'],
      model,
      (_) => _,
      550
    );
  }

  public openImportTradesStatusModal(model: ImportTradesStatusModalInputModel) {
    return this.openDialog<
      ImportTradesStatusModalComponent,
      ImportTradesStatusModalInputModel,
      ImportTradesStatusModalOutputModel,
      ImportTradesStatusModalOutputModel
    >(
      ImportTradesStatusModalComponent,
      ['pxo-modal', 'import-trades-status-modal', 'modals'],
      model,
      (_) => _,
      400
    );
  }

  public openShowProgressModal(model: ShowProgressModalInputModel) {
    return this.openDialog<
      ShowProgressModalComponent,
      ShowProgressModalInputModel,
      ShowProgressModalOutputModel,
      ShowProgressModalOutputModel
    >(
      ShowProgressModalComponent,
      ['pxo-modal', 'import-trades-status-modal', 'modals'],
      model,
      (_) => _,
      400
    );
  }

  public openHeatmapDetailsModal(
    selectedHeatmapElements: ISelectedHeatmapElements,
    compareFunc: (arr: TooltipSymbol[]) => TooltipSymbol[],
  ) {
    return this.openDialog<HeatmapTooltipComponent, ISelectedHeatmapElementsPopup, HeatmapTooltipCloseModel, HeatmapTooltipCloseModel>(
      HeatmapTooltipComponent,
      'heatmap-details',
      {
        ...selectedHeatmapElements,
        heatmapVisibilityModel: {
          mode: 'Extended',
        },
        compareSymbolsFn: compareFunc,
      },
      (value) => ({ ...value })
    );
  }

}
