<h2 mat-dialog-title>
  Adding a stock in the calculator
  <button mat-button mat-dialog-close class="popup-close-btn" tabindex="-1">
    <img class="light-theme-img" src="assets/img/newImg/Close-p.svg">
    <img class="black-theme-img" src="assets/img/newImg/Close-p-white.svg">
  </button>
</h2>

<mat-dialog-content class="mat-typography">
  
  <p>Maximum allowed columns on The Wheel <br> calculator is 20.</p>
  <p>Please remove not used columns and try again.</p>

</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close class="fill-btn small-btn">Ok</button>
</mat-dialog-actions>
