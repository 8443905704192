import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ObservableService } from '@core1/directives/observable.service';
import { ObservableService as ObservableServiceV2 } from '../services/observable.service';
import { SleeknoteService } from '@s/sleeknote.service';
import { Users } from 'src/app/core/model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {
  public routeName = null;
  public selectedTab = 'watchlist';
  public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  public verticalPosition: MatSnackBarVerticalPosition = 'top';
  public user: Users;
  public searchDialogRef: any; // I know by default it's false

  private subscriptions = new Subscription();
  public isLoading = false;
  public isScannerLoading = false;

  constructor(
    public dialog: MatDialog,
    public observableService: ObservableService,
    private router: Router,
    private observableServiceV2: ObservableServiceV2,
    private sleeknoteService: SleeknoteService
  ) { }

  async ngOnInit() {
    this.sleeknoteService.trackingActivityForSleeknote();
    if (localStorage.getItem('theme') === 'light') {
      document.getElementsByTagName('body')[0].classList.remove('black-theme');
      this.observableService.theme.next('light');
    } else if (localStorage.getItem('theme') === 'dark') {
      document.getElementsByTagName('body')[0].classList.add('black-theme');
      this.observableService.theme.next('dark');
    }

    document.addEventListener('keydown', function (event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode == 38 || charCode == 40) {
        // event.preventDefault();
      }
    });

    this.observableService.selectScannerTab.subscribe((res) => {
      if (res == 0) {
        this.selectedTab = 'scanner_result';
      } else if (res == 1) {
        this.selectedTab = 'watchlist';
      }
    });

    this.routeName = this.gerEndUrl(this.router.url.toString());

    this.router.events.subscribe((value) => {
      this.routeName = this.gerEndUrl(this.router.url.toString());
    });

    this.subscriptions.add(this.observableServiceV2.isLoading.subscribe((isLoading) => this.isLoading = isLoading));
    this.subscriptions.add(
      this.observableServiceV2.isWheelScannerLoading.subscribe((isScannerLoading) => this.isScannerLoading = isScannerLoading)
    );
  }

  ngOnDestroy() {
    this.sleeknoteService.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  gerEndUrl(url) {
    const partNumber = url.split('/').length - 1;
    const urlArray = url.split('/');

    return urlArray[partNumber];
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 27) {
      this.dialog.closeAll();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    // if (event.target.className.includes('modals')) {
    //   this.dialog.closeAll();
    // }
  }

}
