import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import * as _ from 'lodash';

interface IDataServiceBase {
  get: (key: string) => Promise<any>;
  getAsJSON: (key: string) => Promise<any>;
  set: (key: string, value: any) => Promise<void>;
}

export class ComponentSettingsSaver <T extends Record<string, any>>{
  public readonly savedSettings$: Observable<T> = from(this.userDataService.getAsJSON(this.settingsKey))
    .pipe(
      catchError(() => of(this.defaultSettings)),
      take(1),
      map((savedSettings) => savedSettings || this.defaultSettings),
      map((savedSettings) => {
        // TODO: (improvement) add deep-merge with defaultSettings
        // for cases when settings object has new properties (that were not saved in previous version)
        return { ...this.defaultSettings, ...savedSettings };
      }),
      tap((settings) => {
        this.lastSavedSettings = { ...settings };
        this.lastSettingsToSave = { ...settings };
      })
    );

  public get savedSettings(): T {
    return this.lastSavedSettings;
  }

  private lastSavedSettings: T;
  private lastSettingsToSave: T;

  private readonly saveSettings$ = new Subject<T>();
  private readonly subscriptions = new Subscription();
  private readonly saveSettingsDebounceTime = 1000;

  // TODO: (improvement) use validation schema for settings (as optional parameter)
  constructor(
    private defaultSettings: T,
    private settingsKey: string,
    private userDataService: IDataServiceBase,
  ) {
    this.lastSavedSettings = defaultSettings;
    this.lastSettingsToSave = defaultSettings;

    this.init();
  }

  public saveSettings(settings: T): void {
    this.lastSettingsToSave = settings;
    this.saveSettings$.next(settings);
  }

  public destroy(): void {
    this.subscriptions.unsubscribe();

    // save settings if they were changed and are not saved (using saveSettings$) yet
    // can happen when user changes settings and immediately closes component (faster than debounceTime)
    if (!_.isEqual(this.lastSavedSettings, this.lastSettingsToSave)) {
      this.userDataService.set(this.settingsKey, this.lastSettingsToSave);
    }
  }

  private init(): void {
    this.subscriptions.add(
      this.saveSettings$
        .pipe(
          debounceTime(this.saveSettingsDebounceTime),
          switchMap((settings) => {
            if (_.isEqual(this.lastSavedSettings, settings)) {
              return of(null);
            }

            this.lastSavedSettings = settings;
            return this.userDataService.set(this.settingsKey, settings);
          })
        )
        .subscribe()
    );
  }
}
