import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DayLinkDataModel } from '@mdl/economic-calendar-modal/models/day-link-data.model';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs';

@Component({
  selector: 'app-link-input',
  templateUrl: './link-input.component.html',
  styleUrls: ['./link-input.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule],
})
export class LinkInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public urlData: DayLinkDataModel | undefined;

  @Input()
  public date: string | undefined;

  @Output()
  public onLinkEnter: EventEmitter<DayLinkDataModel>  = new EventEmitter();

  public inputControl: FormControl;

  protected maxInputLength: number = 2000;

  private subscription: Subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.inputControl = new FormControl(this.urlData?.url);
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.createInputObserveable().subscribe((inputValue) => this.emitEvent(inputValue))
    );
  }

  private emitEvent(inputValue: string) {
    this.onLinkEnter.emit({
      ...this.urlData,
      url: inputValue,
      date: this.date || ''
    })
  }

  private createInputObserveable(): Observable<string> {
    return this.inputControl.valueChanges.pipe(
      map((value: string) => value.trim()),
      debounceTime(200),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
