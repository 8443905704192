<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner class="blue-spinner" diameter="50"></mat-spinner>
</div>

<div class="app-trading-plan-container" *ngIf="tradingPlans.length > 0 && !isLoading">
  <div class="nav-header">
    <div class="add-plan-menu">
      <button
        mat-icon-button
        color="primary"
        class="btn-standard primary-bg"
        [matMenuTriggerFor]="addPlanMenu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon [svgIcon]="'plus_large'"></mat-icon>
      </button>

      <mat-menu #addPlanMenu="matMenu" class="mat-menu-default trading-plan-menu">
        <button
          class="low-type-button"
          mat-menu-item
          (click)="onAddPlan(blankTradingPlanTemplate)"
        >
          Blank Plan
        </button>
        <span class="separate-line"></span>
        <p
          *ngIf="availableTradingPlanTemplates.length > 0"
          class="add-plan-sub-title mat-menu-header"
        >
          Templates:
        </p>
        <button
          mat-menu-item
          class="low-type-button"
          *ngFor="let planTemplate of availableTradingPlanTemplates"
          (click)="onAddPlan(planTemplate)"
        >
          {{ planTemplate.title }}
        </button>
      </mat-menu>
    </div>

    <mat-tab-group
      class="tabs-group trading-plan-tabs"
      [selectedIndex]="selectedTabIndex"
      (selectedTabChange)="onChangePlanTab($event.index)"
      animationDuration="0"
      color="primary"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [disableRipple]="true"
      [cdkDropListData]="tradingPlans"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="onPlanTabDrop($event)"
    >
      <mat-tab
        [tabIndex]="plan.order"
        *ngFor="let plan of tradingPlans; trackBy: trackByFn"
      >
        <ng-template mat-tab-label>
          <div
            class="plan-title-container"
            [ngClass]="{ 'border': activePlanID === plan.id }"
            cdkDrag
            cdkDragLockAxis="x"
            [cdkDragDisabled]="activePlanID === plan.id"
            cdkDragRootElement=".mat-tab-label"
          >
            <p
              cdkDragHandle
              class="plan-tab-title"
              *ngIf="activePlanID !== plan.id"
              (dblclick)="onSetEditModeForPlanTitle(plan)"
            >{{ plan.title }}</p>
            <input
              matInput
              appTextareaFitContentWidth
              [id]="'plan-title-textarea_' + plan.id"
              *ngIf="activePlanID === plan.id"
              (input)="updatePlanTitle(plan, $event)"
              (keydown)="updatePlanTitle(plan, $event)"
              (keydown.enter)="$event.preventDefault(); onTextAreaFocusOut(plan, $event)"
              (keydown.shift.enter)="$event.preventDefault()"
              (paste)="onPaste(plan, $event)"
              autocomplete="off"
              type="text"
              [maxLength]="maxPlanTitleLength"
              class="form-field"
              (focusout)="onTextAreaFocusOut(plan, $event)"
              [value]="plan.title"
            />
          </div>
          <div *cdkDragPreview>
            {{ plan.title }}
          </div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="planMenu"
            (click)="$event.stopPropagation()"
            class="drop extra-small-icon-btn"
          >
            <mat-icon svgIcon="dashes-icon" class="small-icon"></mat-icon>
          </button>
          <mat-menu #planMenu="matMenu" class="mat-menu-default plan-menu">
            <button mat-menu-item class="low-type-button plan-menu-button" (click)="onSetEditModeForPlanTitle(plan)">
              <mat-icon [svgIcon]="'edit_icon'" class="plan-menu-button-icon"></mat-icon>
              Rename
            </button>
            <button *ngIf="!isMobileOS" mat-menu-item class="low-type-button plan-menu-button" (click)="onPrintPlan(plan)">
              <mat-icon [svgIcon]="'print-grey'" class="plan-menu-button-icon"></mat-icon>
              Print
            </button>
            <button mat-menu-item class="low-type-button" (click)="onDeletePlan(plan)">
              <mat-icon [svgIcon]="'close-thick'" class="plan-menu-button-icon delete-icon"></mat-icon>
              Delete
            </button>
          </mat-menu>
        </ng-template>
        <app-trading-plan-tab-content
          [plan]="plan"
          [saveStatus]="saveStatus$ | async"
          (updatePlan)="onUpdatePlan($event)"
        ></app-trading-plan-tab-content>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<app-no-data-content
  header="Add your first trading plan"
  subHeader="Create, edit notes and plans for each strategy"
  *ngIf="tradingPlans.length === 0 && !isLoading"
>
  <button
    mat-button
    class="main-bg button large-padding"
    [matMenuTriggerFor]="addPlanMenu"
    (click)="$event.stopPropagation()"
  >
    Add New Plan
  </button>
  <mat-menu #addPlanMenu="matMenu" class="mat-menu-default trading-plan-menu">
    <button class="low-type-button" mat-menu-item (click)="onAddPlan(blankTradingPlanTemplate)">
      Blank Plan
    </button>
    <span class="separate-line"></span>
    <p
      *ngIf="availableTradingPlanTemplates.length > 0"
      class="add-plan-sub-title mat-menu-header"
    >
      Templates:
    </p>
    <button
      mat-menu-item
      class="low-type-button"
      *ngFor="let planTemplate of availableTradingPlanTemplates"
      (click)="onAddPlan(planTemplate)"
    >
      {{ planTemplate.title }}
    </button>
  </mat-menu>
</app-no-data-content>
