import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SymbolFlagComponent } from './symbol-flag/symbol-flag.component';
import {
  SymbolFlagStatisticWidgetComponent
} from './symbol-flag-statistic-widget/symbol-flag-statistic-widget.component';
import { InViewportModule } from 'ng-in-viewport';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShortNumberPipe } from '@core/pipes/short-number.pipe';

@NgModule({
  declarations: [
    SymbolFlagComponent,
    SymbolFlagStatisticWidgetComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    InViewportModule,
    MatButtonModule,
    MatTooltipModule,
    ShortNumberPipe,
  ],
  exports: [
    SymbolFlagComponent,
    SymbolFlagStatisticWidgetComponent,
  ],
})
export class SymbolFlagModule { }
