<ng-container [formGroup]="searchForm">
  <div mat-dialog-title>
    <div class="modal-heading">
      {{ modalTitle() }}
      <div class="modal-right-icon">
        <mat-icon
          [svgIcon]="'close-modal-p'"
          mat-dialog-close
          class="close-button-icon"
        ></mat-icon>
      </div>
    </div>
    <div class="modal-search">
      <a
        class="modal-search-btn"
        href="javascript:void(0)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
        >
          <path
            stroke="#a3a6af"
            d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
          ></path>
        </svg>
      </a>
      <input
        [formControlName]="'searchValue'"
        (keydown.enter)="onEnterButton()"
        type="text"
        autocomplete="off"
        id="symbolSearch"
        #myinput
        spellcheck="false"
        placeholder="Search"
        name="searchString"
        maxlength="100"
      />
      <a
        (click)="clearSearchResult()"
        class="modal-close-btn"
        href="javascript:void(0)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          width="12"
          height="12"
          fill="none"
        >
          <path
            stroke="#a3a6af"
            stroke-width="1.2"
            d="M1 1l15 15m0-15L1 16"
          ></path>
        </svg>
      </a>
    </div>
  </div>

  <mat-dialog-content class="symbol-search-modal-content mat-typography">
    <div class="heatmap-weekly-options-toggle">
      <mat-slide-toggle
        formControlName="weeklyOptions"
        color="primary"
        hideIcon="true"
        class="large-slide-toggle"
      ></mat-slide-toggle>
      <div class="toggle-description">
        <p class="header">
          Show symbols with weekly options only
          <span class="weekly-options-icon">W</span>
        </p>
      </div>
    </div>

    <!--  TODO: replace with "@let" after upgrade angular to v18 to avoid nested "@if"  -->
    @if (findedSymbols(); as symbols) {
      @if (symbols.length > 0) {
        <div
          [class.with-watchlist]="showAddToWatchListButton()"
          [class.mobile-view]="isMobile()"
          class="symbol-search-wrapper"
        >
          @for (symbol of symbols; track symbol.security_id) {
            <div
              (click)="selectSymbol(symbol)"
              class="symbol-search-row"
            >
              <div class="symbol-search-row-cell">
                @if (!!symbol.has_weekly_options) {
                  <span class="weekly-options-icon">w</span>
                }
              </div>
              <div
                [innerHTML]="symbol?.symbol | highlight: searchForm.value['searchValue']"
                class="symbol-search-row-cell search-symbol-name"
              ></div>
              <div class="symbol-search-row-cell search-symbol-company">
                <div
                  [innerHTML]="
                    symbol?.description
                      | highlight: (searchForm.value['searchValue'].length > 1 ? searchForm.value['searchValue'] : null)
                  "
                ></div>
              </div>
              <div class="symbol-search-row-cell mobile-symbol-search-row-cell">
                <div [innerHTML]="symbol?.symbol | highlight: searchForm.value['searchValue']"></div>
                <div
                  [innerHTML]="
                    symbol?.description
                      | highlight: (searchForm.value['searchValue'].length > 1 ? searchForm.value['searchValue'] : null)
                  "
                  class="search-symbol-mobile-description"
                ></div>
              </div>
              <div class="symbol-search-row-cell search-symbol-country-code">
                {{ exchangeCountries[symbol?.country_code] }}
              </div>
              <div class="symbol-search-row-cell search-symbol-country-code-icon">
                <div class="{{ symbol?.country_code }}-icon"></div>
              </div>
              @if (showAddToWatchListButton()) {
                <div class="symbol-search-row-cell">
                  <ng-container>
                    @if (!symbol.isInWatchlist) {
                      <img
                        [matTooltip]="'Add to Watchlist'"
                        [src]="addToWatchlistSvg | async"
                        [matTooltipHideDelay]="100"
                        [matTooltipPosition]="'below'"
                        (click)="addToWatchlist(symbol)"
                        alt="plus-img"
                      />
                    } @else {
                      <img
                        [matTooltip]="'Delete from Watchlist'"
                        [matTooltipHideDelay]="100"
                        [matTooltipPosition]="'below'"
                        (click)="deleteFromWatchlist(symbol)"
                        src="../../../../../assets/img/close-red.svg"
                        class="cancle-image"
                        alt="cancle-img"
                      />
                    }
                  </ng-container>
                </div>
              }
            </div>
          }
        </div>
      } @else {
        <div class="no-data-wrapper">
          <span>No symbols match your criteria</span>
        </div>
      }
    }
  </mat-dialog-content>
</ng-container>
