<div class="multi-select-wrap">
  <mat-form-field
    appearance="outline"
    floatLabel="always"
    subscriptSizing="dynamic"
  >
    <mat-label>
      {{ title }}
      <mat-icon matPrefix [svgIcon]="'arrow-left_icon'"></mat-icon>
    </mat-label>
    <mat-select remove-aria-owns
      panelClass='multiselect-overlay scroll-style'
      multiple
      hideSingleSelectionIndicator
      [formControl]="formControlG"
    >
      <mat-select-trigger>
        {{ formControlG.value?.length }}
      </mat-select-trigger>
      <div class="scrollable-options scroll-style">
        <mat-option
          *ngFor="let item of data"
          [matTooltip]="item.title"
          [matTooltipShowDelay]="250"
          [matTooltipDisabled]="item.title.length <= 30"
          [matTooltipTouchGestures]="item.title.length > 30 ? 'auto' : 'off'"
          [value]="item.value"
        >{{ item.title }}
        </mat-option>
      </div>
      <div class="multi-select-btn-wrapper">
        <button mat-button (click)="reset()" class="custom-action-btn">
          <mat-icon [svgIcon]="'reset'"></mat-icon>
          <span class="custom-btn-title right-side-title">Reset</span>
        </button>
      </div>
    </mat-select>
  </mat-form-field>
</div>
