import { HttpClient, HttpParams  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Response } from '@core1/interface/response';

@Injectable({
  providedIn: 'root'
})
export class TradingService {

  constructor(
    private _http: HttpClient
  ) { }

  createOrder = async (accessToken: string, accountNumber: string, order: any) => {
    const { result } = await firstValueFrom(this._http.post<Response>('/v2/trading/order', {
      accessToken,
      accountNumber,
      order
    }));

    return result[0];
  }

  modifyOrder = async (accessToken: string, accountNumber: string, order_id: number, order: any) => {
    const { result } = await firstValueFrom(this._http.post<Response>('/v2/trading/order/modify', {
      accessToken,
      accountNumber,
      order_id,
      order
    }));

    return result[0];
  }

  cancelOrder = async (accessToken: string, accountNumber: string, order_id: number) => {
    const { result } = await firstValueFrom(this._http.post<Response>('/v2/trading/order/cancel', {
      accessToken,
      accountNumber,
      order_id
    }));

    return result[0];
  }
}
