import { FiltersPresetsModel } from '@c/shared/filter-presets-menu/filters-presets.model';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';
import { DividendsCount, EarningBeforeExpOptions, IWheelFilter, MinStrikeToLowestClose, SectorsFilter } from '@t/wheel/wheel.types';

export const defaultPredefinedWheelPresets: Array<FiltersPresetsModel<IWheelFilter>> = [
  {
    id: 'default-wheel-preset-1',
    title: 'Default',
    settings: {
      flags: [Flags.No, Flags.Maybe, Flags.Yes, Flags.Never, Flags.None],
      minStrike: MinStrikeToLowestClose.AllStrikes,
      dividends: DividendsCount.AtLeastOne,
      marketCapFrom: { filterValue: '5000000000', visibleValue: '5B' },
      marketCapTo: { filterValue: '', visibleValue: '' },
      peRatioFrom: '',
      peRatioTo: '50.00',
      changeInPercentFrom: '',
      changeInPercentTo: '-1',
      sectors: SectorsFilter.All,
      expiration: 'All',
      strikeFrom: '15',
      strikeTo: '',
      roiFrom: '30',
      roiTo: '',
      premiumFrom: '0.1',
      premiumTo: '',
      dropInPercentFrom: '',
      dropInPercentTo: '',
      earningBeforeExp: EarningBeforeExpOptions.Any,
    },
  },
];
