import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RestRequestorService } from '@s/rest-requestor.service';
import { convertToEasternTime } from '@u/utils';
import { ExchangeCountriesCodes, MomentDateTimeFormats } from '@const';
import { IWorkingHours, WorkingHoursModel } from '@mod/data/working-hours.model';

@Injectable({
  providedIn: 'root'
})
export class WorkingHoursService {
  constructor(
    private http: HttpClient,
    private restRequesterService: RestRequestorService,
  ) { }

  public async get(): Promise<IWorkingHours[]> {
    const { result } = await this.restRequesterService.makeRequest(
      `working_hours_${ExchangeCountriesCodes.US}`,
      () => firstValueFrom(this.http.get<WorkingHoursModel>(`/v2/workingHours`, { params: { country_code: ExchangeCountriesCodes.US } }))
    );

    const workingHours = result.map((item) => ({
      name: item.name,
      countryCode: item.country_code,
      date: convertToEasternTime(item.date).format(MomentDateTimeFormats.ServerDate),
      startTime: item.start_time,
      endTime: item.end_time
    }));

    return workingHours;
  }
}
