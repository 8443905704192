import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NoDataMessageComponent } from './no-data-message/no-data-message.component';
import { RequestResultMessageComponent } from './request-result-message/request-result-message.component';
import { LiveDataHeaderComponent } from './live-data-header/live-data-header.component';
import { LiveDataTableComponent } from './live-data-table/live-data-table.component';
import { LiveDataWindowComponent } from './live-data-window/live-data-window.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';

const COMPONENTS = [
  LiveDataWindowComponent,
  LiveDataTableComponent,
  RequestResultMessageComponent,
  NoDataMessageComponent,
  LiveDataHeaderComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatTableModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [],
})
export class LiveDataModule {}
