import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormDefaultsModule } from '@c/shared/forms/form-defaults.module';

import { ChangePasswordFormComponent } from '@c/my-settings-modal/change-password-form/change-password-form.component';

@NgModule({
  imports: [CommonModule, FormDefaultsModule],
  exports: [ChangePasswordFormComponent],
  declarations: [ChangePasswordFormComponent],
  providers: [],
})
export class ChangePasswordFormModule {}
