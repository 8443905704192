import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import DOMPurify from 'dompurify';
import { Editor, Toolbar, toHTML } from 'ngx-editor';
import { Schema } from 'prosemirror-model';
import { Subscriber, from } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';

import { marks, nodes, toolbar } from '@c/trading-hub/trading-plan-tab-content/ngx-editor.data';
import {
  ITradingPlan,
  ITradingPlanSection,
  PlanModes,
  SaveStatuses,
} from '@c/trading-hub/trading-plan/trading-plan.model';
import { DialogsService } from '@s/common';

@Component({
  selector: 'app-trading-plan-tab-content',
  templateUrl: './trading-plan-tab-content.component.html',
  styleUrls: ['./trading-plan-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradingPlanTabContentComponent implements OnInit, OnDestroy {
  @Input() plan: ITradingPlan = null;

  @Input() set saveStatus(value: SaveStatuses) {
    switch (value) {
      case SaveStatuses.Success:
        this.syncIcon = this.syncIcons.success;
        break;
      case SaveStatuses.InProgress:
        this.syncIcon = this.syncIcons.inProgress;
        break;
      case SaveStatuses.Error:
        this.syncIcon = this.syncIcons.error;
        break;
    }
  }

  @Output() updatePlan = new EventEmitter<ITradingPlan>();

  public planModes = PlanModes;
  public maxSectionsNumber = 10;
  public maxSectionTitleLength = 100;
  public maxSectionContentLength = 10000;

  public syncIcon: string;
  public syncIcons = {
    success: 'sync-ok',
    inProgress: 'sync-in-progress',
    error: 'sync-error',
  };

  public isPlanContentEditingBlocked = false;
  public currentEditor: Editor | null = null;
  private htmlValue = '';
  public htmlPrevContent = '';
  // important: check custom schema setting in case of adding tools
  public toolbar: Toolbar = toolbar;
  public newSchema = new Schema({ nodes, marks });

  public activeSectionTitleID: string = null;
  public hoveredSectionTitleID: string = null;
  public selectionStartedSectionContentID: string = null;
  public activeSectionContentID: string = null;
  public prevSectionState: ITradingPlanSection = null;

  private subscriber = new Subscriber();

  constructor(
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogsService: DialogsService,
  ) {}

  public get html(): string {
    return this.htmlValue;
  }

  public set html(value: string) {
    if (this.isPlanContentEditingBlocked) {
      return;
    }

    const sanitizedContent = DOMPurify.sanitize(value);
    const tempContentElement = document.createElement('div');
    tempContentElement.innerHTML = sanitizedContent;
    const textLength = tempContentElement.innerText.length;

    if (textLength <= this.maxSectionContentLength) {
      this.htmlValue = sanitizedContent;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  public turnOffEditModeForContent(event: MouseEvent): void {
    const editorWrapper = document.getElementById('ngx-editor-wrapper');
    const isEventInsideEditor = editorWrapper?.contains(event.target as Node);
    const ngxPopupElementClasses = ['NgxEditor__Dropdown--Item', 'NgxEditor__Color', 'NgxEditor__MenuItem--Button'];
    const classList = (event.target as HTMLElement)?.classList;
    const isEventInsidePopup = classList && Array.from(classList).some((item) => ngxPopupElementClasses.includes(item));

    // color-picker in ngx-editor is listening "document:mousedown"
    // so event shouldn't be prevented or stopped if it's inside editor
    // to prevent color and heading popups closing on-outside-click
    if (isEventInsideEditor || isEventInsidePopup) {
      return;
    }

    if (this.currentEditor) {
      this.currentEditor.destroy();
    }

    this.activeSectionContentID = null;
  }

  public onAddSection(): void {
    if (this.plan.sections.length >= this.maxSectionsNumber) {
      this.showMaxAllowedSectionsInfoMessage();
      return;
    }

    const newSectionOrder = Math.max(...this.plan.sections.map((item) => item.order)) + 1;

    const newSection: ITradingPlanSection = {
      id: uuidV4(),
      title: 'My Headline',
      isExpanded: true,
      order: newSectionOrder,
      content: '',
    };

    const updatedPlan = {
      ...this.plan,
      mode: PlanModes.Write,
      sections: [...this.plan.sections, newSection],
    };

    this.updatePlan.emit(updatedPlan);

    this.setEditModeForSectionTitle(newSection);
  }

  public onChangeIsPlanLocked(): void {
    const updatedPlan = {
      ...this.plan,
      mode: this.plan.mode === PlanModes.Read ? PlanModes.Write : PlanModes.Read,
    };

    // turn-off edit-mode for title and content
    this.activeSectionTitleID = null;
    this.activeSectionContentID = null;

    // update plan -> output
    this.updatePlan.emit(updatedPlan);
  }

  public onClickSectionNavItem(section: ITradingPlanSection): void {
    // set edit mode for title and content (if plan.editMode)

    if (!section.isExpanded) {
      this.onChangeIsSectionExpanded(section);
    }

    // scroll when content is already expanded
    setTimeout(() => {
      const sectionID = 'section-content_' + section.id;
      this.scrollToElement(sectionID);
    }, 100);
  }

  public onChangeIsSectionExpanded(section: ITradingPlanSection): void {
    const updatedPlan = {
      ...this.plan,
      sections: this.plan.sections.map((item) => {
        if (item.id === section.id) {
          return { ...section, isExpanded: !section.isExpanded };
        }

        return item;
      }),
    };

    // update plan -> output
    this.updatePlan.emit(updatedPlan);
  }

  public onSectionDrop(dropEvent: CdkDragDrop<ITradingPlanSection[]>): void {
    if (dropEvent.previousIndex === dropEvent.currentIndex) {
      return;
    }

    moveItemInArray(dropEvent.container.data, dropEvent.previousIndex, dropEvent.currentIndex);
    const updatedSections = dropEvent.container.data.map((item, index) => ({ ...item, order: index }));

    const updatedPlan = {
      ...this.plan,
      sections: updatedSections,
    };

    this.updatePlan.emit(updatedPlan);
  }

  public onClickSectionTitle(section: ITradingPlanSection): void {
    if (this.plan.mode === PlanModes.Write) {
      this.setEditModeForSectionTitle(section);
    }
  }

  public onSelectStartSectionTitle(section: ITradingPlanSection): void {
    if (this.plan.mode === PlanModes.Write) {
      this.selectionStartedSectionContentID = section.id;
    }
  }

  public onSelectSectionTitle(section: ITradingPlanSection): void {
    if (this.plan.mode === PlanModes.Write && this.selectionStartedSectionContentID) {
      this.selectionStartedSectionContentID = null;
      this.setEditModeForSectionTitle(section);
    }
  }

  public onDoubleClickSectionTitle(section: ITradingPlanSection): void {
    if (this.plan.mode !== PlanModes.Write) {
      this.onChangeIsPlanLocked(); // set to WRITE mode
    }

    this.setEditModeForSectionTitle(section);
  }

  public onHoverSectionTitle(section: ITradingPlanSection | null): void {
    if (this.plan.mode !== PlanModes.Write) {
      return;
    }

    if (this.hoveredSectionTitleID !== section?.id) {
      this.hoveredSectionTitleID = section?.id;
    }
  }

  public onClickSectionContent(section: ITradingPlanSection): void {
    if (this.plan.mode === PlanModes.Write) {
      this.setEditModeForSectionContent(section);
    }
  }

  public setEditModeForSectionTitle(section: ITradingPlanSection, cursorPosition?: number): void {
    this.activeSectionContentID = null;
    this.selectionStartedSectionContentID = null;
    this.activeSectionTitleID = section.id;
    this.hoveredSectionTitleID = null;
    this.prevSectionState = section;

    setTimeout(() => {
      const element = document.getElementById(`section-title-textarea_${section.id}`) as HTMLTextAreaElement | null;

      if (element) {
        element.focus();

        if (cursorPosition !== undefined && cursorPosition !== null) {
          element.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    }, 100);
  }

  public onDoubleClickSectionContent(section: ITradingPlanSection): void {
    if (this.plan.mode !== PlanModes.Write) {
      this.onChangeIsPlanLocked(); // set to WRITE mode
    }

    this.setEditModeForSectionContent(section);
  }

  public setEditModeForSectionContent(section: ITradingPlanSection): void {
    if (!section.isExpanded) {
      this.onChangeIsSectionExpanded(section);
    }

    this.activeSectionTitleID = null; // trigger focus-out ??
    this.selectionStartedSectionContentID = null;
    this.activeSectionContentID = section.id;
    this.html = section.content;

    // handle on-paste -> clean all tags !!
    this.currentEditor = new Editor({
      content: this.html,
      history: false,
      keyboardShortcuts: true,
      inputRules: true,
      plugins: [], // https://prosemirror.net/docs/guide/#state
      schema: this.newSchema, // https://prosemirror.net/examples/schema/
      nodeViews: {}, // https://prosemirror.net/docs/guide/#state,
      attributes: {}, // https://prosemirror.net/docs/ref/#view.EditorProps.attributes
      linkValidationPattern: '',
    });

    this.currentEditor.valueChanges.subscribe((v) => {
      if (this.isPlanContentEditingBlocked) {
        this.currentEditor.setContent(this.htmlPrevContent);
        return;
      }

      const content = toHTML(v, this.newSchema);

      const tempContentElement = document.createElement('div');
      tempContentElement.innerHTML = content;
      const textLength = tempContentElement.innerText.length;

      if (textLength > this.maxSectionContentLength) {
        this.isPlanContentEditingBlocked = true;
        this.currentEditor.setContent(this.htmlPrevContent);
        this.showMaxAllowedSectionContentLengthInfoMessage();

        return;
      }

      this.htmlPrevContent = content;
      this.html = content;

      const updatedPlan = {
        ...this.plan,
        sections: this.plan.sections.map((item) => {
          if (item.id === section.id) {
            return {
              ...item,
              content: this.html,
            };
          }

          return item;
        }),
      };

      this.updatePlan.emit(updatedPlan);

      const sectionID = 'section-content_' + section.id;
      this.scrollToElement(sectionID);
    });

    // set cursor position ?
    this.currentEditor.commands.focus().scrollIntoView().exec();

    this.currentEditor.view.dom.focus();

    // scroll into view with ngx-editor controls (at the bottom)
    setTimeout(() => {
      const sectionID = 'section-content_' + section.id;
      this.scrollToElement(sectionID);
    }, 100);

    setTimeout(() => {
      const range = document.createRange();
      const sel = window.getSelection();
      const contentElements = this.currentEditor.view.dom;

      let lastTextNode = contentElements.lastChild;

      while (lastTextNode.nodeType !== Node.TEXT_NODE && lastTextNode.lastChild) {
        lastTextNode = lastTextNode.lastChild;
      }

      range.setStart(lastTextNode, lastTextNode.textContent.length);
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
    }, 100);
  }

  public onPasteSectionTitleInput(section: ITradingPlanSection, event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');
    const textAreaElement = event.target as HTMLTextAreaElement;
    const currentTaskValue = textAreaElement.value;
    const selectionStart = textAreaElement.selectionStart;
    const selectionEnd = textAreaElement.selectionEnd;

    const newRawContent =
      currentTaskValue.slice(0, selectionStart) + clipboardData + currentTaskValue.slice(selectionEnd);

    const formattedContent = newRawContent
      .trim()
      // eslint-disable-next-line no-control-regex
      .replace(new RegExp('\r\n|\r|\n', 'g'), '')
      .slice(0, this.maxSectionTitleLength);

    const updatedPlan = {
      ...this.plan,
      sections: this.plan.sections.map((item) => {
        if (item.id === section.id) {
          return {
            ...item,
            title: formattedContent,
          };
        }

        return item;
      }),
    };

    const contentBeforeCursor = currentTaskValue.slice(0, selectionStart) + clipboardData;
    const cursorPosition = contentBeforeCursor.slice(0, this.maxSectionTitleLength).length;

    this.setEditModeForSectionTitle(section, cursorPosition);

    this.updatePlan.emit(updatedPlan);
  }

  public onTitleTextAreaFocusOut(section: ITradingPlanSection, event: FocusEvent): void {
    const element = event.currentTarget as HTMLTextAreaElement;
    const value = element.value.trim().length > 0 ? element.value.trim() : (this.prevSectionState?.title ?? '');

    const updatedPlan = {
      ...this.plan,
      sections: this.plan.sections.map((item) => {
        if (item.id === section.id) {
          return {
            ...item,
            title: value,
          };
        }

        return item;
      }),
    };

    this.updatePlan.emit(updatedPlan);

    this.activeSectionTitleID = null;
    this.prevSectionState = null;
  }

  public onContentTextAreaFocusOut(section: ITradingPlanSection): void {
    const updatedPlan = {
      ...this.plan,
      sections: this.plan.sections.map((item) => {
        if (item.id === section.id) {
          return {
            ...item,
            content: this.html,
          };
        }

        return item;
      }),
    };

    this.updatePlan.emit(updatedPlan);
  }

  public onChangeSectionTitle(section: ITradingPlanSection, event: KeyboardEvent): void {
    const element = event.currentTarget as HTMLTextAreaElement;

    if (!['Backspace', 'Delete'].includes(event.key) && element.value.length >= this.maxSectionTitleLength) {
      event.preventDefault();
    }

    const value = element.value ?? '';

    const updatedPlan = {
      ...this.plan,
      sections: this.plan.sections.map((item) => {
        if (item.id === section.id) {
          return {
            ...item,
            title: value,
          };
        }

        return item;
      }),
    };

    this.updatePlan.emit(updatedPlan);
  }

  private showMaxAllowedSectionsInfoMessage(): void {
    const message = `Maximum allowed sections in a trading plan is ${this.maxSectionsNumber}.`;

    from(
      this.dialogsService.customConfirm({
        showCancel: false,
        header: 'Adding a new section',
        confirmationText: message,
        subText: '',
        icon: 'info',
      }),
    ).subscribe();
  }

  private showMaxAllowedSectionContentLengthInfoMessage(): void {
    const message = `Please cut your message to ${this.maxSectionContentLength.toLocaleString('en-US')} chars and try again.`;

    this.dialogsService
      .customConfirm$({
        showCancel: false,
        header: 'Adding section content',
        confirmationText: message,
        subText: '',
        icon: 'info',
      })
      .subscribe(() => {
        this.isPlanContentEditingBlocked = false;
      });
  }

  public onDeleteSection(section: ITradingPlanSection): void {
    // Do not remove empty line, it's important for UI
    const message = `Do you really want to delete '${section.title}' section?

      This action can’t be undone.`;

    this.dialogsService
      .customConfirm$({
        header: 'Delete section',
        confirmationText: message,
        subText: '',
        icon: 'info',
        okText: 'Yes',
        cancelText: 'No',
        showCancel: true,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          const updatedPlan = {
            ...this.plan,
            sections: this.plan.sections
              .filter((item) => item.id !== section.id)
              .sort((a, b) => a.order - b.order)
              .map((item, index) => ({ ...item, order: index })),
          };

          this.updatePlan.emit(updatedPlan);
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private scrollToElement(id: string): void {
    const element = document.querySelector(`#${id}`);

    if (element) {
      this.renderer.selectRootElement(`#${id}`, true).scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }

  public refreshComponentView(): void {
    this.changeDetectorRef.markForCheck();
  }

  public trackByFn(index: number, item: { id?: string }): string | number {
    return item.id ?? index;
  }
}
