import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ExchangeCountriesCodes,
  formatDecimal,
  formatNumber,
  formatPrice,
  getDecimalSharesCount,
  getSharesCount,
  Signals,
  TradePositions,
} from '@const';
import { ExchangeModel } from '@mod/data/exchange.model';
import { TradierOrderSide } from '@mod/trading-panel/trading-panel.model';
import { TradingOrderClass, TradingOrderType } from '@mod/trading-panel/trading-panel-order.model';
import { TradingStrategySignal } from '@s/past-performance.service';
import { ISymbol } from '@s/symbols.service';
import { ITradingStrategy } from '@s/trading-strategies.service';
import { LocalStorageService } from '@s/local-storage.service';
import { CookieService } from '@s/cookie.service';
import { TradingPanelService } from '@s/trading-panel.service';

@Component({
  selector: 'app-current-position-symbol',
  templateUrl: './current-position-symbol.component.html',
  styleUrls: ['./current-position-symbol.component.scss'],
})
export class CurrentPositionSymbolComponent implements OnInit {
  @Input() signal: TradingStrategySignal = null;
  @Input() accountRiskAmount = null;
  @Input() tradingStrategy: ITradingStrategy = null;
  @Input() tradingStrategyToCompare: ITradingStrategy = null;
  @Input() placeOrderCanBeShown: boolean = null; // should be recieved from cache service
  @Input() symbol: ISymbol;
  @Input() exchange: ExchangeModel;
  @Input() isPrint: boolean;

  @ViewChild('totalProfit') totalProfitEl: ElementRef;

  hasAnySignalOrPosition: boolean = false;

  private get _hasAnySignalOrPosition() {
    const { signal } = this;

    return !!(signal?.position || signal?.signal);
  }

  get singalBoxColorClass() {
    const signalRecord = this.signal;
    const signal = signalRecord ? signalRecord.signal : null;

    let className = 'grey';
    if (signal === Signals.BTO || signal === Signals.STC) {
      className = 'green';
    } else if (signal === Signals.STO || signal === Signals.BTC) {
      className = 'red';
    }

    return className + ' price-range space-beetwen-row';
  }

  get signalName() {
    const signalRecord = this.signal;

    if (!signalRecord) {
      return '';
    }

    let signalName = 'N/A';
    if (signalRecord.signal === Signals.BTO || signalRecord.signal === Signals.BTC) {
      signalName = 'BUY';
    } else if (signalRecord.signal === Signals.STO || signalRecord.signal === Signals.STC) {
      signalName = 'SELL';
    }

    return signalName;
  }

  get signalComment() {
    const signalRecord = this.signal;

    if (!signalRecord) {
      return '';
    }

    let signalComment = 'N/A';
    if (signalRecord.signal === Signals.BTO || signalRecord.signal === Signals.STO) {
      signalComment = 'to open';
    } else if (signalRecord.signal === Signals.BTC || signalRecord.signal === Signals.STC) {
      signalComment = 'to close';
    }

    return signalComment;
  }

  get signalPrice() {
    const signalRecord = this.signal;

    return signalRecord?.signal_entry_price ? formatPrice(signalRecord.signal_entry_price, this.exchange?.profit_scale) : null;
  }

  get hasSignal() {
    const signalRecord = this.signal;

    return signalRecord?.signal ? true : false;
  }

  get signalSharesCount() {
    const signalRecord = this.signal;

    if (!signalRecord) {
      return '';
    }

    const stopLossAmountValue = signalRecord.signal ? signalRecord.signal_stop_loss_amount : signalRecord.stop_loss_amount;

    const shares = this.exchange?.share_fraction
      ? formatDecimal(getDecimalSharesCount(this.accountRiskAmount, stopLossAmountValue), this.exchange.share_fraction ?? 0)
      : formatNumber(getSharesCount(this.accountRiskAmount, stopLossAmountValue));

    return shares;
  }

  get signalSharesQuantity() {
    const signalRecord = this.signal;

    if (!this.hasAnySignalOrPosition) {
      return '';
    }

    const stopLossAmount = signalRecord?.signal ? signalRecord.signal_stop_loss_amount : signalRecord.stop_loss_amount;

    return getSharesCount(this.accountRiskAmount, stopLossAmount);
  }

  get buyingPower() {
    const signalRecord = this.signal;

    if (!this.hasAnySignalOrPosition) {
      return '';
    }

    const entryPrice = signalRecord?.signal ? signalRecord.signal_entry_price : signalRecord.entry_price;
    const stopLossAmount = signalRecord?.signal ? signalRecord.signal_stop_loss_amount : signalRecord.stop_loss_amount;

    const sharesCount = this.exchange?.share_fraction
      ? getDecimalSharesCount(this.accountRiskAmount, stopLossAmount)
      : getSharesCount(this.accountRiskAmount, stopLossAmount);

    return formatPrice(
      entryPrice * sharesCount,
      this.exchange?.profit_scale
    );
  }

  stopLoss = '';

  get stopLossToolTipFormula() {
    const signalRecord = this.signal;

    if (!this.hasAnySignalOrPosition) {
      return '';
    }

    const tradingStrategy = this.tradingStrategy;
    const risk = formatDecimal(tradingStrategy.risk_ratio, 1);

    if (signalRecord && signalRecord.signal) {
      const stopLossAmount = formatDecimal(
        signalRecord.signal_stop_loss_amount,
        this.exchange?.profit_scale
      );
      const multiplier = formatDecimal(
        signalRecord.signal_profit_loss_multiplier,
        this.exchange?.profit_scale
      );
      return `${stopLossAmount} (${risk} x ${multiplier})`;
    }

    const stopLossAmount = formatDecimal(
      signalRecord.stop_loss_amount,
      this.exchange?.profit_scale
    );
    const multiplier = formatDecimal(
      signalRecord.profit_loss_multiplier,
      this.exchange?.profit_scale
    );

    return `${stopLossAmount} (${risk} x ${multiplier})`;
  }

  get targetProfitToolTipFormula() {
    const signalRecord = this.signal;

    if (!this.hasAnySignalOrPosition) {
      return '';
    }

    const tradingStrategy = this.tradingStrategy;
    const reward = formatDecimal(tradingStrategy.reward_ratio, 1);

    if (signalRecord && signalRecord.signal) {
      const targetProfitAmount = formatDecimal(
        signalRecord.signal_target_profit_amount,
        this.exchange?.profit_scale
      );
      const multiplier = formatDecimal(
        signalRecord.signal_profit_loss_multiplier,
        this.exchange?.profit_scale
      );
      return `${targetProfitAmount} (${reward} x ${multiplier})`;
    }

    const targetProfitAmount = formatDecimal(
      signalRecord.target_profit_amount,
      this.exchange?.profit_scale
    );
    const multiplier = formatDecimal(
      signalRecord.profit_loss_multiplier,
      this.exchange?.profit_scale
    );

    return `${targetProfitAmount} (${reward} x ${multiplier})`;
  }

  targetProfit = '';

  get riskRewardClass() {
    const { tradingStrategy, tradingStrategyToCompare } = this;
    if (!tradingStrategy || !tradingStrategyToCompare) {
      return '';
    }

    const rewardRatioFirst = tradingStrategy.reward_ratio / tradingStrategy.risk_ratio;
    const rewardRatioSecond = tradingStrategyToCompare.reward_ratio / tradingStrategyToCompare.risk_ratio;

    let rewardClass = '';
    if (rewardRatioFirst > rewardRatioSecond) {
      rewardClass = 'l-yellow';
    } else if (rewardRatioFirst === rewardRatioSecond && tradingStrategy.reward_ratio >= tradingStrategyToCompare.reward_ratio) {
      rewardClass = 'l-yellow';
    }

    return rewardClass;
  }

  get riskRatio() {
    const tradingStrategy = this.tradingStrategy;

    return tradingStrategy ? formatDecimal(tradingStrategy.risk_ratio, 1) : '';
  }

  get rewardRatio() {
    const tradingStrategy = this.tradingStrategy;

    return tradingStrategy ? formatDecimal(tradingStrategy.reward_ratio, 1) : '';
  }

  get showProfitLossTooltip() {
    return this.stopLoss !== '-' && this.targetProfit !== '';
  }

  get showChangeDirection() {
    return this.signal.signal === Signals.BTC || this.signal.signal === Signals.STC;
  }

  get changeDirectionClass() {
    if (this.signal.signal === Signals.BTC) return 'green';
    if (this.signal.signal === Signals.STC) return 'red';
    return '';
  }

  get changeDirectionTextArr() {
    if (this.signal.signal === Signals.BTC) return ['BUY', 'to close'];
    if (this.signal.signal === Signals.STC) return ['SELL', 'to close'];
  }

  get showPlaceOrder() {
    return this.placeOrderCanBeShown && this.hasSignal && this.exchange?.country_code === ExchangeCountriesCodes.US;
  }

  get tradeTypeAndStopParameters(): { type: string; stop: string } {
    const value = { type: null, stop: null };
    switch (this.signal?.signal) {
      case Signals.STO:
      case Signals.BTO: {
        value.type = 'stop';
        value.stop = `&stop=${this.signal?.signal_entry_price}`;
        break;
      }
      case Signals.STC:
      case Signals.BTC: {
        value.type = 'market';
        break;
      }
    }
    return value;
  }

  get tradeLink() {
    if (!this.hasSignal) return '';
    let quantity: number | string = 0;
    if (!this.showChangeDirection) {
      quantity = this.signalSharesQuantity;
    }
    const typeAndStop = this.tradeTypeAndStopParameters;
    return `https://dash.tradier.com/tradelink?class=equity&symbol=${this.signal.symbol
      }&quantity=${quantity}&side=${this.signalName.toLowerCase()}&type=${typeAndStop.type}&duration=day${typeAndStop.stop ? typeAndStop.stop : ''
      }`;
  }

  get tradeLinkTooltip() {
    if (this.hasSignal && this.showChangeDirection) {
      return `Clicking this will open the Tradier platform and pre-fill an order.
        \n <b>ATTENTION! Please adjust order quantity based on your initial entry order.</b>
        You MUST have an account with Tradier in order to use this functionality.
        \n If you want to open an account with Tradier please visit their website: <b>https://rockwelltrading.com/broker</b>`;
    } else {
      return `Clicking this will open the Tradier platform and pre-fill an order.
        \n You MUST have an account with Tradier in order to use this functionality.
        \n If you want to open an account with Tradier please visit their website: <b>https://rockwelltrading.com/broker</b>`;
    }
  }

  protected tProfitLoss(): string {
    return `Stop Loss calculation
    ${this.stopLossToolTipFormula}

    Profit Target calculation
    ${this.targetProfitToolTipFormula}`
  }

  constructor(private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private tradingPanelService: TradingPanelService) {
  }

  get showOCOOrderButton() {
    return this.exchange?.country_code === ExchangeCountriesCodes.US
      && this.signalName === 'N/A'
      && this.stopLoss !== '-'
      && this.placeOrderCanBeShown;
  }

  async onPlaceOrderClick() {
    let side: TradierOrderSide = null;
    switch (this.signal.signal) {
      case Signals.BTO: side = TradierOrderSide.Buy; break;
      case Signals.BTC: side = TradierOrderSide.BuyToCover; break;
      case Signals.STO: side = TradierOrderSide.SellShort; break;
      case Signals.STC: side = TradierOrderSide.Sell; break;
    }

    const orderType = this.signal.signal === Signals.BTO || this.signal.signal === Signals.STO
      ? TradingOrderType.Stop
      : TradingOrderType.Market;
    const quantity = this.signal.signal === Signals.BTO || this.signal.signal === Signals.STO
      ? parseInt(this.signalSharesCount.replace(/\D/g, ''))
      : null;
    const isQtyNote = this.signal.signal === Signals.BTC || this.signal.signal === Signals.STC;

    this.tradingPanelService.showOrderModal({
      symbol: this.symbol,
      orderClass: TradingOrderClass.Equity,
      orderType,
      side,
      quantity,
      price: this.signal.signal_entry_price,
      isQtyNote,
      onTradingPanelNavigateHandler: null
    });
  }

  async connectToTradier() {
    const dataForOCOOrder = {
      stopLoss: this.stopLoss.replace(/[^0-9.]/g, ''),
      targetProfit: this.targetProfit.replace(/[^0-9.]/g, ''),
      symbol: this.symbol,
      position: this.signal.position,
      share: this.signalSharesCount.replace(/\D/g, '')
    };

    const side = this.signal.position === TradePositions.LongOnly
      ? TradierOrderSide.Sell
      : TradierOrderSide.BuyToCover;

    this.tradingPanelService.showOrderModal({
      symbol: dataForOCOOrder.symbol,
      orderClass: TradingOrderClass.OCO,
      orderType: TradingOrderType.Stop,
      side,
      stopLoss: parseFloat(this.stopLoss.replace(/[^0-9.]/g, '')),
      takeProfit: parseFloat(this.targetProfit.replace(/[^0-9.]/g, '')),
      onTradingPanelNavigateHandler: null
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['signal']) {
      [this.stopLoss, this.targetProfit, this.hasAnySignalOrPosition] = [
        this._getStopLossOrTargetProfit(true),
        this._getStopLossOrTargetProfit(false),
        this._hasAnySignalOrPosition,
      ];
    }
  }

  private _getStopLossOrTargetProfit(isStopLoss: boolean) {
    const signalRecord = this.signal;
    const propertyNameBase = isStopLoss ? 'stop_loss' : 'target_profit';

    if ([Signals.BTC, Signals.STC].includes(signalRecord?.signal) || (!signalRecord?.position && !signalRecord?.signal)) return '-';
    if (signalRecord.signal === null && signalRecord.position !== null)
      return formatPrice(signalRecord[propertyNameBase], this.exchange?.profit_scale);
    if (!!signalRecord?.signal) return formatPrice(signalRecord['signal_' + propertyNameBase], this.exchange?.profit_scale);
    return '';
  }
}
