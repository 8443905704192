<div class="scanner-table scanner-table-v2">
  <div class="table-sort-header">
    <table
      [dataSource]="dataSource"
      [matSortActive]="sortState.column"
      [matSortDirection]="sortState.direction"
      [class.hidden]="!dataSource?.data?.length"
      (matSortChange)="onSortChange($event)"
      mat-table
      matSort
    >
      <ng-container
        [matColumnDef]="column"
        *ngFor="let column of displayedColumns"
      >
        <th
          [ngClass]="column"
          *matHeaderCellDef
          class="table-sort-header-cell"
          mat-header-cell
          mat-sort-header
          disableClear
        >
          {{ displayedColumnsTitles[column] ?? column }}
        </th>
        <mat-cell
          *matCellDef="let element; let i = index"
          mat-cell
        ></mat-cell>
      </ng-container>
      <tr
        *matHeaderRowDef="displayedColumns"
        mat-header-row
        class="table-header-row"
      ></tr>
    </table>
  </div>

  @if (dataSource?.data?.length) {
    <cdk-virtual-scroll-viewport
      [class.hidden]="!dataSource?.data?.length"
      (resize)="onResize()"
      tvsItemSize="29"
      headerHeight="0"
      bufferMultiplier="1"
      class="scroll-style wtf-scanner-virtual-scroll scanner-symbols-list-virtual-scroll"
      appResizeDirective
    >
      <table
        [dataSource]="dataSource"
        [matSortActive]="sortState.column"
        [matSortDirection]="sortState.direction"
        [trackBy]="trackByFn"
        (matSortChange)="onSortChange($event)"
        mat-table
        matSort
        class="wheel-conservative-aggressive-table wtf-scanner-table scanner-symbol-table"
      >
        <ng-container matColumnDef="flag">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            disableClear
          >
            Flag
          </th>
          <td
            *matCellDef="let element; let i = index"
            class="scanner-emoji"
            mat-cell
          >
            <div class="borderSelection borderSelection-emoji">
              <app-symbol-flag
                [flag]="element.flag"
                [securityId]="element.security_id"
                [smileyListType]="smileyListTypes.PowerX"
                [showNoneFlagOnlyOnHover]="true"
                [sendUpdateSignalOnChange]="false"
                [showStatisticsWhenVisible]="true"
                [rootClass]="'scanner-flag'"
                (menuClosed)="onSelectSmiley(element.security_id, $event)"
                (click)="$event.stopPropagation()"
              ></app-symbol-flag>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="signal">
          <th
            [matTooltip]="scannerSettingType"
            [matTooltipHideDelay]="0"
            [matTooltipPosition]="'below'"
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            disableClear
          >
            Signal
          </th>
          <td
            *matCellDef="let element; let i = index"
            mat-cell
          >
            <div class="borderSelection">
              <div [class]="element.signal === 'STO' ? 'lightRed' : 'lightGreen'">
                {{ element.signal === 'STO' ? 'SELL' : 'BUY' }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="symbol">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            disableClear
          >
            Symbol
          </th>
          <td
            *matCellDef="let element; let i = index"
            mat-cell
          >
            <div class="borderSelection">
              {{ element.symbol }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="country_code">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            disableClear
          >
            Exchange
          </th>
          <td
            *matCellDef="let element; let i = index"
            mat-cell
          >
            <div class="borderSelection country-code">
              <div
                [class]="element.country_code + '-icon'"
                [matTooltip]="exchangeCountries[element.country_code] + ' Exchange'"
              ></div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="company_name">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            disableClear
          >
            Company
          </th>
          <td
            *matCellDef="let element; let i = index"
            mat-cell
          >
            <div class="relative borderSelection">
              <div class="company-eclips">
                {{ element.company_name }}
              </div>

              <div
                *ngIf="showSmileyStatistic"
                class="flag-statistics-widget"
              >
                <app-symbol-flag-statistic-widget
                  [securityId]="element.security_id"
                  [listType]="smileyListTypes.PowerX"
                ></app-symbol-flag-statistic-widget>
              </div>

              <div class="ie-btns">
                <img
                  [matTooltip]="'Add to watchlist'"
                  [matTooltipPosition]="'above'"
                  (click)="$event.preventDefault(); $event.stopPropagation(); addToWatchlist(element)"
                  class="light-theme-img"
                  src="assets/img/add-s.svg"
                  alt="Add to watchlist"
                />
                <img
                  [matTooltip]="'Add to watchlist'"
                  [matTooltipPosition]="'above'"
                  (click)="$event.preventDefault(); $event.stopPropagation(); addToWatchlist(element)"
                  class="black-theme-img"
                  src="assets/img/add-s-white.svg"
                  alt="Add to watchlist"
                />
                <img
                  [matTooltip]="'Move to watchlist'"
                  [matTooltipPosition]="'above'"
                  (click)="$event.preventDefault(); $event.stopPropagation(); moveToWatchlist(element)"
                  src="assets/img/Go.svg"
                  alt="Move to watchlist"
                />
                <img
                  [matTooltip]="'Remove from scanner'"
                  [matTooltipPosition]="'above'"
                  (click)="$event.preventDefault(); $event.stopPropagation(); removeFromList(element)"
                  src="assets/img/close-red.svg"
                  alt="Remove from scanner"
                />
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          *matHeaderRowDef="displayedColumns; sticky: true"
          mat-header-row
          class="tableHeaderRow table-header-row hidden"
        ></tr>
        <tr
          [id]="'scanner-tr-' + row.security_id"
          [ngClass]="{ selected: row.id === selectedRow?.id }"
          (click)="onSelectSymbol(row)"
          *matRowDef="let row; columns: displayedColumns"
          mat-row
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  }

  @if (!dataSource?.data?.length) {
    <div class="no-data-msg">
      <div class="message-heading">
        <mat-icon
          svgIcon="warning-icon"
          class="icon-lg message-icon"
        ></mat-icon>
        <p class="text">The Scanner found no matching symbols</p>
      </div>
      <div class="message-description">
        <p class="text">
          Consider
          <span
            (click)="goToWheelTrades()"
            class="redirect-text"
            >"Wheel Trades"</span
          >
        </p>
        <span class="text-divider vertical"></span>
        <p class="text">Change the scanner criteria</p>
      </div>
    </div>
  }
</div>
