import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2, input } from '@angular/core';
import { ElementResizeOptions } from '@mod/video-hub/video-hub-resize-options.model';

// this directive created for background video and need modify for other elements
@Directive({
  selector: '[appElementResizable]',
  standalone: true
})
export class BackgroundVideoResizableDirective {
  public elementResizeOptions = input.required<ElementResizeOptions>();

  private x: number;
  private y: number;
  private isResizing: boolean = false;
  private handles: HTMLElement[] = [];
  private handleSize: number = 10;
  private width: number;
  private height: number;
  private activeCorner: HTMLElement;
  @Output()
  private onResizing = new EventEmitter<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>();
  private parentPaddingsX: number = 0;
  private parentPaddingsY: number = 0;

  @Output()
  public onResizeStart = new EventEmitter<void>();
  @Output()
  public onResizeEnd = new EventEmitter<void>();

  private px = 0;
  private py = 0;


  constructor(private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.createResizeHandles();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (this.handles.includes(target)) {
      const parent = (this.renderer.selectRootElement(this.elementResizeOptions().parentClass, true) as HTMLElement)
      const parentRect = parent.getBoundingClientRect();
      const resizeChild = target.parentNode.querySelector('.resize') ?? parent;
      this.x = parentRect.x;
      this.y = parentRect.y;
      this.px = event.clientX;
      this.py = event.clientY;
      this.parentPaddingsX = parentRect.width - resizeChild.getBoundingClientRect().width;
      this.parentPaddingsY = parentRect.height - resizeChild.getBoundingClientRect().height;
      this.width = resizeChild.getBoundingClientRect().width;
      this.height = resizeChild.getBoundingClientRect().height;
      this.isResizing = true;
      this.activeCorner = this.handles.find((handle) => handle === target);
      this.onResizeStart.emit();
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isResizing) {
      // tslint:disable-next-line:max-line-length
      const parentRect = (this.renderer.selectRootElement(this.elementResizeOptions().parentClass, true) as HTMLElement).getBoundingClientRect();
      const offsetY = event.clientY - this.py;

      if (this.activeCorner.offsetTop === 0 && this.activeCorner.offsetLeft === 0) {
        this.onTopLeftResize(offsetY, parentRect);
      }

      if (this.activeCorner.offsetTop === 0 && this.activeCorner.offsetLeft !== 0) {
        this.onTopRightResize(offsetY, parentRect);
      }

      if (this.activeCorner.offsetTop !== 0 && this.activeCorner.offsetLeft === 0) {
        this.onBottomLeftResize(offsetY, parentRect);
      }

      if (this.activeCorner.offsetTop !== 0 && this.activeCorner.offsetLeft !== 0) {
        this.onBottomRightResize(offsetY, parentRect);
      }

      this.onResizing.emit({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height
      });

      this.py = event.clientY;
      this.px = event.clientX;
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isResizing = false;
    this.onResizeEnd.emit();
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (this.width >= window.innerWidth) {
      const newWidth = window.innerWidth;
      const newHeight = (newWidth - this.parentPaddingsX) / this.elementResizeOptions().aspectRatio;

      this.width = newWidth;
      this.height = newHeight;

      this.onResizing.emit({
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height
      });
    }
  }

  private createResizeHandles() {
    const element = this.elementRef.nativeElement;
    const handleOptions = [
      { top: '0px', left: '0px', cursor: 'nwse-resize' },
      { top: '0px', right: '0px', cursor: 'nesw-resize' },
      { bottom: '0px', left: '0px', cursor: 'nesw-resize' },
      { bottom: '0px', right: '0px', cursor: 'nwse-resize'  }
    ];

    handleOptions.forEach(position => {
      const handle = this.createResizeHandle();
      Object.keys(position).forEach(key => {
        this.renderer.setStyle(handle, key, position[key]);
        this.renderer.setStyle(handle, key, position[key]);
      });
      this.handles.push(handle);
      this.renderer.appendChild(element, handle);
    });
  }

  private createResizeHandle(): HTMLElement {
    const handle = this.renderer.createElement('div');
    this.renderer.setStyle(handle, 'position', 'absolute');
    this.renderer.setStyle(handle, 'width', `${this.handleSize}px`);
    this.renderer.setStyle(handle, 'height', `${this.handleSize}px`);
    this.renderer.setStyle(handle, 'z-index', `10`);
    this.renderer.setStyle(handle, 'background-color', 'transparent');
    return handle;
  }

  private onTopLeftResize(offsetY: number, parentRect: DOMRect): void {

    if (this.height - offsetY < this.elementResizeOptions().minHeight || this.height - offsetY > this.elementResizeOptions().maxHeight) {
      return;
    }

    const newHeight = this.height - offsetY;
    const newWidth = (newHeight) * this.elementResizeOptions().aspectRatio;

    const nY = this.y + offsetY;
    const nX = this.x + this.width - newWidth;

    if (nX < 0 || nY < 0) {
      return;
    }

    this.y = nY;
    this.x = nX;
    this.height = newHeight;
    this.width = newWidth;
  }

  private onTopRightResize(offsetY: number, parentRect: DOMRect): void {
    if (this.height - offsetY < this.elementResizeOptions().minHeight || this.height - offsetY > this.elementResizeOptions().maxHeight) {
      return;
    }


    const newHeight = this.height - offsetY;
    const newWidth = newHeight * this.elementResizeOptions().aspectRatio + this.parentPaddingsX;

    const nY = this.y + offsetY;

    if (window.innerWidth - parentRect.left - newWidth < 0 || nY < 0) {
      return;
    }

    this.y = nY;
    this.height = newHeight;
    this.width = newWidth;
  }

  private onBottomLeftResize(offsetY: number, parentRect: DOMRect): void {
    if (this.height + offsetY < this.elementResizeOptions().minHeight || this.height + offsetY > this.elementResizeOptions().maxHeight) {
      return;
    }

    const newHeight = this.height + offsetY;
    const newWidth = (newHeight) * this.elementResizeOptions().aspectRatio;

    const nX = this.x + this.width - newWidth;

    if (nX < 0 || window.innerHeight - parentRect.top - newHeight - this.parentPaddingsY < 0) {
      return;
    }

    this.height = newHeight;
    this.width = newWidth;
    this.x = nX;
  }

  private onBottomRightResize(offsetY: number, parentRect: DOMRect): void {
    if (this.height + offsetY < this.elementResizeOptions().minHeight || this.height + offsetY > this.elementResizeOptions().maxHeight) {
      return;
    }

    const newHeight = this.height + offsetY;
    const newWidth = (newHeight) * this.elementResizeOptions().aspectRatio + this.parentPaddingsX;

    if (window.innerWidth - parentRect.x - newWidth < 0 || window.innerHeight - parentRect.y - newHeight - this.parentPaddingsY < 0) {
      return;
    }

    this.width = newWidth;
    this.height = newHeight;
  }
}
