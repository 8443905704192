import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ObservableService } from '../../../../services/observable.service';
import { TradePositions } from '../../../../constants';
import { PastPerformanceBlock } from '../past-performance-block.abstract';

@Component({
  selector: 'app-win-profit-factor',
  templateUrl: './win-profit-factor.component.html',
  styleUrls: ['./win-profit-factor.component.scss']
})
export class WinProfitFactorComponent extends PastPerformanceBlock implements OnInit, OnChanges {
  @Input() pastPerformance = null;
  @Input() pastPerformanceToCompare = null;
  @Input() tradePosition = TradePositions.LongAndShort;

  public winsLabel = '';

  private get _winsLabel() {
    const value = this.getPastPerformanceMetric('wins', 0);
    return `${value} win${+value === 1 ? '' : 's'}`;
  }

  public lossesLabel = '';

  private get _lossesLabel() {
    const value = this.getPastPerformanceMetric('losses', 0);
    return `${value} loss${+value === 1 ? '' : 'es'}`;
  }

  constructor(
    private observableService: ObservableService,
  ) {
    super();
   }

  ngOnInit(): void {
    this.observableService.tradePosition.subscribe(() => {
      [this.winsLabel, this.lossesLabel] = [this._winsLabel, this._lossesLabel];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      [this.winsLabel, this.lossesLabel] = [this._winsLabel, this._lossesLabel];
    }
  }

  getWinLossProgressBarWidth(property: string, labelWidth: number): string {
    const propertyName = this.getPropertyName(property);
    const { pastPerformance, pastPerformanceToCompare } = this;

    if (!pastPerformance || !pastPerformanceToCompare) {
      return '';
    }

    const winsPropertyName = this.getPropertyName('wins');
    const lossPropertyName = this.getPropertyName('losses');

    const maxWinLossArray = [
      pastPerformance[winsPropertyName],
      pastPerformance[lossPropertyName],
      pastPerformanceToCompare[winsPropertyName],
      pastPerformanceToCompare[lossPropertyName],
    ];

    const maxWinLoss = Math.max(...maxWinLossArray);
    const optionWidthRatio = pastPerformance[propertyName] / maxWinLoss;

    return `calc(${optionWidthRatio} * (100% - ${labelWidth}px))`;
  }

}
