import { Component, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from '@const';

@Component({
  selector: 'app-print-profits',
  templateUrl: './print-profits.component.html',
  styleUrls: ['./print-profits.component.scss']
})
export class PrintProfitsComponent implements OnInit {

  protected isNullOrUndefined = isNullOrUndefined;

  constructor() {
  }

  @Input() strategyTrades;
  @Input() tradingStrategy;
  @Input() isReportFitOnPrintScreen;

  ngOnInit(): void {
  }

}
