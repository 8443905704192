import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChecklistProgressComponent } from '@c/trading-hub/checklist-progress/checklist-progress.component';
import {
  TradingHubTopMenuButtonComponent,
} from '@c/trading-hub/trading-hub-top-menu-button/trading-hub-top-menu-button.component';
import {
  TradingHubHeaderMenuButtonComponent,
} from '@c/trading-hub/trading-hub-header-menu-button/trading-hub-header-menu-button.component';
import { PrintTradingPlanComponent } from '@c/trading-hub/print-trading-plan/print-trading-plan.component';
import { SanitizeHtmlPipe } from '@c/trading-hub/pipes/sanitize-html.pipe';

@NgModule({
  declarations: [
    ChecklistProgressComponent,
    TradingHubTopMenuButtonComponent,
    TradingHubHeaderMenuButtonComponent,
    PrintTradingPlanComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    SanitizeHtmlPipe,
  ],
  exports: [
    ChecklistProgressComponent,
    TradingHubTopMenuButtonComponent,
    TradingHubHeaderMenuButtonComponent,
    PrintTradingPlanComponent,
    SanitizeHtmlPipe
  ],
})
export class TradingHubSharedModule { }
