<div class="profit-factor card">
  <div class="win-profit-factor row">
      <div class="align-c w-100 font-12">
          <div class="align-c w-100 font-12">
              <span class="font-12 ptb-3 {{ getPastPerformanceHighlightClass('win_percent', true) }}">
                  {{ getPropertyValue('win_percent') !== null ? (getPastPerformanceMetric('win_percent', 2) + '%') : '-' }}
              </span>
              <div class="circle-dot"></div>
              <span class="font-12 ptb-3 {{getPastPerformanceHighlightClass('profit_factor', true)}}">
                {{ getPropertyValue('profit_factor') !== null ? getPastPerformanceMetric('profit_factor', 2) : '-' }}
              </span>
          </div>
      </div>
  </div>
  <div class="avg-row light font-10">Avg.</div>
  <table class="win-loss-tbl">
      <tr>
          <td>
              <div class="progress-bar-with-txt row progress-bar-container">
                  <ng-container *ngIf="getPropertyValue('wins') === null else winsData">
                    -
                  </ng-container>
                  <ng-template #winsData>
                    <div
                      *ngIf="winsLabel !== '0 wins'"
                      [style.width]="getWinLossProgressBarWidth('wins', oneProgress.offsetWidth)"
                    >
                      <div class="progress-bar rounded-progress bg-green"></div>
                    </div>
                    <div class="txt p-l-6 font-12 t-green font-12 progress-label" #oneProgress>
                        {{ winsLabel }}
                    </div>
                  </ng-template>
              </div>
          </td>
          <td>
              <div class="t-green font-12 font-12">
                {{ getPropertyValue('win_avg') ? getPastPerformanceMetricMoney('win_avg') : '-'}}
              </div>
          </td>
      </tr>
      <tr>
          <td>
              <div class="progress-bar-with-txt row progress-bar-container">
                <ng-container *ngIf="getPropertyValue('losses') === null else lossesData">
                  -
                </ng-container>
                <ng-template #lossesData>
                  <div
                    *ngIf="lossesLabel !== '0 losses'"
                    [style.width]="getWinLossProgressBarWidth('losses', twoProgress.offsetWidth)"
                  >
                      <div class="progress-bar rounded-progress bg-red"></div>
                  </div>
                  <div class="txt p-l-6 font-12 t-red font-12 progress-label" #twoProgress>
                      {{ lossesLabel }}
                  </div>
                </ng-template>
              </div>
          </td>
          <td>
              <div class="t-red font-12 font-12">
                {{ getPropertyValue('loss_avg') ? getPastPerformanceMetricMoney('loss_avg') : '-'}}
              </div>
          </td>
      </tr>
  </table>
</div>
