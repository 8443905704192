import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  ExchangeCountriesCodes,
  TabNames,
  UserSettings,
  adminConfigurationUrl,
  assetCorrelationUrl,
  bullCallSpreadUrl,
  disclaimerUrl,
  dividendsCalendarUrl,
  dividendsStrategyUrl,
  earningsAnalysisUrl,
  earningsCalendarUrl,
  economicCalendarUrl,
  heatmapUrl,
  holidaysCalendarUrl,
  homeUrl,
  importTradesUrl,
  licenseAgreementUrl,
  masterCalendarUrl,
  mmTradesUrl,
  powerXUrl,
  shortSellingStocksUrl,
  shortingStocksScannerUrl,
  stockScreenerUrl,
  theWheelUrl,
  tradingLogUrl,
  tradingPanelUrl,
  videoHubUrl,
  wtfUrl,
} from '@const';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private observableServiceV2: ObservableServiceV2,
    private symbolsService: SymbolsService,
  ) {}

  public async redirectToTab(
    tabName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state?: Record<string, any>,
    symbolIdForNewTab?: number,
  ): Promise<void> {
    // if previous tab is [PowerX, Wheel, BCS, WTF] -> set symbol from it for EA
    // if that symbol should be checked -> pass it as redirect-state and check in EA component
    const previousTab = this.observableServiceV2.activeTab.getValue();
    this.saveRouteState(tabName, previousTab);

    if (tabName === TabNames.Wheel) {
      if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.wheelSymbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.WheelSymbol, earningsAnalysisSymbol);
        }
      }

      if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.wheelSymbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.WheelSymbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([theWheelUrl], { state });
    }

    if (tabName === TabNames.PowerX) {
      if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol) {
          this.observableServiceV2.symbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.Symbol, earningsAnalysisSymbol);
        }
      }

      if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol) {
          this.observableServiceV2.symbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.Symbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([powerXUrl]);
    }

    if (tabName === TabNames.AssetCorrelation) {
      await this.router.navigate([assetCorrelationUrl], { state });
    }

    if (tabName === TabNames.TradingLog) {
      await this.router.navigate([tradingLogUrl]);
    }

    if (tabName === TabNames.TradingPanel) {
      await this.router.navigate([tradingPanelUrl]);
    }

    if (tabName === TabNames.StockScreeners) {
      await this.router.navigate([stockScreenerUrl]);
    }

    if (tabName === TabNames.EarningsAnalysis) {
      const earningsAnalysisCountryCodes = [ExchangeCountriesCodes.US, ExchangeCountriesCodes.CA];
      let newSymbol = null;

      if (symbolIdForNewTab) {
        newSymbol = await this.symbolsService.getById(symbolIdForNewTab);
      }

      if (!symbolIdForNewTab && previousTab === TabNames.BullCallSpread) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.bullCallSpreadSymbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.Wheel) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.wheelSymbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.PowerX) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.symbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.Wtf) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.wtfSymbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.StockScreeners) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.stockScreenerSymbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.ShortSellingStocks) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.shortSellingStocksSymbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.ShortingScanner) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.shortingScannerSymbol.getValue());
      }

      if (!symbolIdForNewTab && previousTab === TabNames.DividendsStrategy) {
        newSymbol = await this.symbolsService.getById(this.observableServiceV2.dividendsStrategySymbol.getValue());
      }

      if (newSymbol && earningsAnalysisCountryCodes.includes(newSymbol.country_code)) {
        this.observableServiceV2.earningsCalendarSymbol.next(newSymbol.security_id);
        this.userDataService.set(UserSettings.EarningsCalendarSymbol, newSymbol.security_id);
      }

      await this.router.navigate([earningsAnalysisUrl]);
    }

    if (tabName === TabNames.AdminConfiguration) {
      await this.router.navigate([adminConfigurationUrl]);
    }

    if (tabName === TabNames.BullCallSpread) {
      if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.bullCallSpreadSymbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.BullCallSpreadSymbol, earningsAnalysisSymbol);
        }
      }

      if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.bullCallSpreadSymbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.BullCallSpreadSymbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([bullCallSpreadUrl]);
    }

    if (tabName === TabNames.Heatmap) {
      await this.router.navigate([heatmapUrl], {
        state: {
          heatmapSettings: { ...state },
        },
      });
    }

    if (tabName === TabNames.Disclaimer) {
      await this.router.navigate([disclaimerUrl]);
    }

    if (tabName === TabNames.LicenseAgreement) {
      await this.router.navigate([licenseAgreementUrl]);
    }

    if (tabName === TabNames.Wtf) {
      if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.wtfSymbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.WtfSymbol, earningsAnalysisSymbol);
        }
      }

      if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.wtfSymbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.WtfSymbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([wtfUrl]);
    }

    if (tabName === TabNames.MMTrades) {
      await this.router.navigate([mmTradesUrl]);
    }

    if (tabName === TabNames.ImportTrades) {
      await this.router.navigate([importTradesUrl]);
    }

    if (tabName === TabNames.ShortSellingStocks) {
      const newSymbol = symbolIdForNewTab ? await this.symbolsService.getById(symbolIdForNewTab) : null;

      if (symbolIdForNewTab && newSymbol && newSymbol.country_code === ExchangeCountriesCodes.US) {
        this.observableServiceV2.shortSellingStocksSymbol.next(symbolIdForNewTab);
        this.userDataService.set(UserSettings.ShortSellingStocksSymbol, symbolIdForNewTab);
      } else if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.shortSellingStocksSymbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.ShortSellingStocksSymbol, earningsAnalysisSymbol);
        }
      } else if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.shortSellingStocksSymbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.ShortSellingStocksSymbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([shortSellingStocksUrl]);
    }

    if (tabName === TabNames.ShortingScanner) {
      if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.shortingScannerSymbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.ShortingScannerSymbol, earningsAnalysisSymbol);
        }
      }

      if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.shortingScannerSymbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.ShortingScannerSymbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([shortingStocksScannerUrl]);
    }

    // CALENDARS
    if (tabName === TabNames.Calendars || tabName === TabNames.MasterCalendar) {
      await this.router.navigate([masterCalendarUrl]);
    }

    if (tabName === TabNames.EconomicCalendar) {
      await this.router.navigate([economicCalendarUrl]);
    }

    if (tabName === TabNames.DividendsCalendar) {
      await this.router.navigate([dividendsCalendarUrl]);
    }

    if (tabName === TabNames.EarningsCalendar) {
      await this.router.navigate([earningsCalendarUrl]);
    }

    if (tabName === TabNames.HolidaysCalendar) {
      await this.router.navigate([holidaysCalendarUrl]);
    }

    if (tabName === TabNames.VideoHub) {
      await this.router.navigate([videoHubUrl]);
    }

    if (tabName === TabNames.DividendsStrategy) {
      if (previousTab === TabNames.EarningsAnalysis) {
        const earningsAnalysisSymbol = this.observableServiceV2.earningsCalendarSymbol.getValue();
        const symbol = await this.symbolsService.getById(earningsAnalysisSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.dividendsStrategySymbol.next(earningsAnalysisSymbol);
          this.userDataService.set(UserSettings.DividendsStrategySymbol, earningsAnalysisSymbol);
        }
      }

      if (previousTab === TabNames.StockScreeners) {
        const stockScreenerSymbol = this.observableServiceV2.stockScreenerSymbol.getValue();
        const symbol = await this.symbolsService.getById(stockScreenerSymbol);

        if (symbol && symbol.country_code === ExchangeCountriesCodes.US) {
          this.observableServiceV2.dividendsStrategySymbol.next(stockScreenerSymbol);
          this.userDataService.set(UserSettings.DividendsStrategySymbol, stockScreenerSymbol);
        }
      }

      await this.router.navigate([dividendsStrategyUrl]);
    }

    if (tabName === TabNames.Home) {
      await this.router.navigate([homeUrl]);
    }
  }

  // this function is used to set active tab when user navigate by direct URL
  public setActiveTab(tabName: string): void {
    const previousTab = this.observableServiceV2.activeTab.getValue();
    this.saveRouteState(tabName, previousTab);
  }

  private saveRouteState(tabName: string, previousTab: string): void {
    // Cancel any pending order when switching between tabs
    this.observableServiceV2.tradingPanelOrderInput.next(null);

    this.observableServiceV2.activeTab.next(tabName);

    if (previousTab !== tabName) {
      localStorage.setItem('activeTab', tabName);
      this.userDataService.set(UserSettings.ActiveTab, tabName);
    }
  }
}
