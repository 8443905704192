import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  template: `<div class="container all-center">
                <mat-icon class="popup-icon" [svgIcon]="data?.icon || 'alert-success'"></mat-icon>
                <span class="popup-text user-list">{{ data?.message }}</span>
                <img alt="close" src="../../../assets/img/Icons/close-popup.svg" class="popup-close-icon" (click)="data.snackbar.dismiss()" />
            </div>`,
})
export class SnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
