export enum TextInputKeys {
  StrikeFrom = 'strikeFrom',
  StrikeTo = 'strikeTo',
  RoiFrom = 'roiFrom',
  RoiTo = 'roiTo',
  PremiumFrom = 'premiumFrom',
  PremiumTo = 'premiumTo',
  MarketCapFrom = 'marketCapFrom',
  MarketCapTo = 'marketCapTo',
  PERatioFrom = 'peRatioFrom',
  PERatioTo = 'peRatioTo',
  Dividends = 'dividends',
  ChangeInPercentFrom = 'changeInPercentFrom',
  ChangeInPercentTo = 'changeInPercentTo',
  DropInPercentFrom = 'dropInPercentFrom',
  DropInPercentTo = 'dropInPercentTo',
}

export interface IDisplayedFilterValues {
  strike: string;
  roi: string;
  premium: string;
  marketCap: string;
  PERatio: string;
  changeInPercent: string;
  dropInPercent: string;
}
