import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { ColorClasses, formatPrice } from '@const';

@Component({
  selector: 'app-compare-profits',
  templateUrl: './compare-profits.component.html',
  styleUrls: ['./compare-profits.component.scss']
})
export class CompareProfitsComponent implements OnChanges {
  compareProfitsEntry = [];
  totalProfits;
  ColorClasses = ColorClasses;
  @Input() firstTradingStrategy: any = {id: 0};
  @Input() secondTradingStrategy: any = {id: 0};
  @Input() firstStrategyTrades: any;
  @Input() secondStrategyTrades: any;
  @Input() exchange;
  constructor() { }

  async ngOnChanges() {
    this.compareReports();
  }

  compareReports(): void {
    if (!this.firstStrategyTrades || !this.secondStrategyTrades) {return; }
    this.totalProfits = [this.getTotalProfit(this.firstStrategyTrades), this.getTotalProfit(this.secondStrategyTrades)];
    this.compareProfitsEntry = _.union(Object.keys(this.firstStrategyTrades), Object.keys(this.secondStrategyTrades));
    this.compareProfitsEntry = this.compareProfitsEntry.filter(key => {
      if (this.firstStrategyTrades[key]?.total_profit
        || this.secondStrategyTrades[key]?.total_profit
        || this.firstStrategyTrades[key]?.total_profit === 0
        || this.secondStrategyTrades[key]?.total_profit === 0) {
        return key;
      }
    }).sort();
  }

  getTotalProfit(tradesObject) {
    tradesObject = Object.values(tradesObject)
      .map((trade: any) => trade.total_profit).reduce((a, b) => a + b, 0);
    return tradesObject ? formatPrice(tradesObject, this.exchange.profit_scale) : '-';
  }

  get showTotalProfit(): number {
    return Object.keys(this.firstStrategyTrades).length || Object.keys(this.secondStrategyTrades).length;
  }
}
