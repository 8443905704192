import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfirmDialogInputDataModel, ConfirmDialogOutputDataModel } from './confirm-dialog.model';

const defaultConfirmData: ConfirmDialogInputDataModel = {
  header: 'Confirm',
  confirmationText: 'Are You sure?',
  okText: 'OK',
  cancelText: 'Cancel',
  icon: 'contact_support',
  showCancel: true,
};

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./styles/confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  readonly data: ConfirmDialogInputDataModel;

  constructor(
    private _dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogOutputDataModel>,
    @Inject(MAT_DIALOG_DATA) data: Partial<ConfirmDialogInputDataModel>
  ) {
    this.data = { ...defaultConfirmData, ...data };
  }

  ngOnInit() {}

  ok() {
    this._dialogRef.close({ confirm: true });
  }

  cancel() {
    this._dialogRef.close({ confirm: false });
  }
}
