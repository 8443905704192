import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MatExpansionModule } from '@angular/material/expansion';
import { FormDefaultsModule } from '@c/shared/forms';
import { LinksOrganiserComponent } from '@m/common/links-organiser/components/links-organiser/links-organiser.component';
import { LinksOrganiserEditPaneComponent } from './components/links-organiser-edit-pane/links-organiser-edit-pane.component';

@NgModule({
  declarations: [LinksOrganiserComponent, LinksOrganiserEditPaneComponent],
  imports: [CommonModule, DragDropModule, FormDefaultsModule, MatDialogModule, MatExpansionModule],
  exports: [LinksOrganiserComponent],
})
export class LinksOrganiserModule {}
